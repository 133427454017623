import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
//import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Typography, Button } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import { TextField, Select } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import { Formik, Form, Field } from 'formik';
import useStyles from './styles';
import { MessageZone } from '../promotions/MessageZone';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    borderRadius: 50,
  },
}))(MuiDialogContent);

export default function EditEventPromotionForm(props) {
  var classes = useStyles();
  const { onClose, open } = props;
  const [canSave, setCanSave] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [PromoData, setPromoData] = React.useState(null);
  const [msg, setMsg] = React.useState('');

  useEffect(() => {
    if (props.id != null && props.id != '') {
      (async () => {
        const url =
          `${process.env.REACT_APP_API_URL}admin-user/PromoInfo/` + props.id;
        const response = await fetch(url);
        const data = await response.json();
        setPromoData(data);
        setMsg(data.Message);
      })();
    }
  }, [props.id]);

  const isNotEmpty = (currentValue) => currentValue != '';
  if (!loading) {
    if (PromoData == null) {
      return <></>;
    } else {
      console.log('PromoData', PromoData);
      return (
        <>
          <Dialog
            onClose={() => onClose()}
            aria-labelledby='customized-dialog-title'
            open={open}
          >
            <Typography
              style={{
                alignSelf: 'center',
                paddingTop: 30,
                padding: 20,
                fontFamily: 'SF-Pro-Display-Bold',
              }}
            >
              Modifier une promotion
            </Typography>
            <DialogContent>
              <Formik
                initialValues={{
                  name: PromoData.name,
                }}
                enableReinitialize
                validate={(values) => {
                  setCanSave([values.name, values.Message].some(isNotEmpty));
                  const errors = {};
                  return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  let dtoTOTransfer = {};
                  dtoTOTransfer.id = props.id;
                  dtoTOTransfer.name = values.name;
                  dtoTOTransfer.Message = msg;
                  dtoTOTransfer.Type = 'Event';

                  //setLoading(true);
                  const url = `${process.env.REACT_APP_API_URL}admin-user/Promo/update`;
                  const response = await fetch(url, {
                    method: 'PUT',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(dtoTOTransfer),
                  });
                  setPromoData(dtoTOTransfer);
                  onClose();
                }}
              >
                {({ errors, isSubmitting, setValues }) => (
                  <Form style={{ width: 400 }}>
                    <Grid container>
                      <Grid item xs={12} sm={12} spacing={4}>
                        <text className={classes.title}>
                          Nom de la promotion
                        </text>
                        <Field
                          component={TextField}
                          name='name'
                          type='name'
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} style={{ marginTop: 15 }}>
                        <text className={classes.title}>Message</text>
                        <MessageZone msg={msg} setMsg={setMsg} />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginTop: 30,
                          justifyContent: 'center',
                        }}
                      >
                        <Button
                          variant='outlined'
                          color='error'
                          onClick={() => {
                            onClose();
                          }}
                        >
                          Annuler
                        </Button>
                        <div style={{ width: 20 }} />
                        <Button
                          className={classes.submit}
                          type='submit'
                          // disabled={!canSave}
                          variant='outlined'
                          color='primary'
                        >
                          {loading ? 'patientez svp' : 'Valider'}
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </DialogContent>
          </Dialog>
        </>
      );
    }
  }
}
