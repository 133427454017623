import * as React from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';
import useStyles from './styles';
import SubMenuP from '../../components/SubMenuP';
import { Link } from 'react-router-dom';
import { Typography, Button, Avatar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PromotionFormCode from './PromotionFormCode';
import { IconButton } from '@material-ui/core';
import parse from 'html-react-parser';
import DeletePromoModal from './DeletePromoModal';
import EditCodePromotion from './EditCodePromotion';

export default function CodePromotions() {
  var classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [refresh, setrefresh] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState([]);
  const [openDelMod, setOpenDelMod] = React.useState({
    open: false,
    text: '',
    hndlDel: '',
    id: '',
  });
  const [openEdit, setEditOpen] = React.useState(false);
  const [idPromo, setIdPromo] = React.useState('');

  const handleCloseEdit = () => {
    setEditOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const DeletePromo = async (id) => {
    const url =
      `${process.env.REACT_APP_API_URL}admin-user/DeletePromo?PromoId=` + id;
    const response = await fetch(url, { method: 'DELETE' });
    setrefresh(!refresh);
  };
  React.useEffect(() => {
    (async () => {
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}admin-user/GetPromo/Code`;
      const response = await fetch(url);
      const data = await response.json();
      setData(data.data);
      setLoading(false);
    })();
  }, [refresh, open, openEdit]);

  return (
    <div style={{ height: 500, width: '100%', backgroundColor: 'white' }}>
      <SubMenuP active='3' />
      <PageTitle
        title='MISE EN PLACE DE CODE PROMOTIONEL'
        button='Add'
        OnOpen={handleClickOpen}
      />
      {openDelMod.open ? (
        <DeletePromoModal inf={openDelMod} setInf={setOpenDelMod} />
      ) : (
        ''
      )}
      {data.length > 0 ? (
        data.map((item) => {
          return (
            <div className={classes.box}>
              <div
                style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
              >
                <div
                  style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
                >
                  <div style={{ display: 'flex' }}>
                    <Typography className={classes.header}>Code</Typography>
                    <div style={{ width: 10 }} />
                    <Typography className={classes.text}>
                      {item.CouponCode}
                    </Typography>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <Typography className={classes.header}>
                      Promotion
                    </Typography>
                    <div style={{ width: 10 }} />
                    <Typography className={classes.text}>
                      {item.name}
                    </Typography>
                  </div>
                </div>
                <div style={{ display: 'flex', flex: 1 }}>
                  <Typography className={classes.header}>Du</Typography>
                  <div style={{ width: 10 }} />
                  <Typography className={classes.text}>
                    {new Date(item.start_date)
                      .toLocaleDateString()
                      .replace(/\//g, '-')}
                  </Typography>
                  <Typography
                    className={classes.header}
                    style={{ marginLeft: 20 }}
                  >
                    Au
                  </Typography>
                  <div style={{ width: 10 }} />
                  <Typography className={classes.text}>
                    {new Date(item.end_date)
                      .toLocaleDateString()
                      .replace(/\//g, '-')}
                  </Typography>
                </div>
              </div>
              <div
                style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
              >
                <Typography className={classes.header}>Message</Typography>
                <Typography className={classes.text}>
                  {parse(item.Message)}
                </Typography>
              </div>
              <div
                style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
              >
                <div style={{ display: 'flex', flex: 1, marginTop: 10 }}>
                  <Typography className={classes.header}>Regles</Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    marginTop: 10,
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography className={classes.rule}>
                      24 heures ={' '}
                    </Typography>
                    <div style={{ width: 10 }} />
                    <Typography className={classes.text}>
                      {item.Promo24 ? item.Promo24 : 0}
                    </Typography>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography className={classes.rule}>1 mois = </Typography>
                    <div style={{ width: 10 }} />
                    <Typography className={classes.text}>
                      {item.Prom01 ? item.Prom01 : 0}
                    </Typography>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography className={classes.rule}>3 mois = </Typography>
                    <div style={{ width: 10 }} />
                    <Typography className={classes.text}>
                      {item.Promo3 ? item.Promo3 : 0}
                    </Typography>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography className={classes.rule}>6 mois = </Typography>
                    <div style={{ width: 10 }} />
                    <Typography className={classes.text}>
                      {item.Promo6 ? item.Promo6 : 0}
                    </Typography>
                  </div>
                </div>
              </div>
              <div
                style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
              >
                <div style={{ display: 'flex', flex: 1 }}>
                  <Typography className={classes.header}>Actions</Typography>
                </div>
                <div style={{ display: 'flex', flex: 1 }}>
                  <IconButton
                    className={classes.button}
                    //onClick={() => props.OnOpen()}
                  >
                    <VisibilityIcon
                      style={{ color: '#2ecc71', fontSize: 30 }}
                    />
                  </IconButton>
                  <IconButton
                    className={classes.button}
                    onClick={() => {
                      setIdPromo(item._id);
                      setEditOpen(true);
                    }}
                  >
                    <EditIcon style={{ color: '#47a1de', fontSize: 30 }} />
                  </IconButton>
                  <IconButton
                    className={classes.button}
                    onClick={() =>
                      setOpenDelMod({
                        open: true,
                        text: 'Code promotionnel',
                        id: item._id,
                        hndlDel: DeletePromo,
                      })
                    }
                  >
                    <CloseIcon style={{ color: 'red', fontSize: 30 }} />
                  </IconButton>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <text />
      )}
      <PromotionFormCode
        open={open}
        onClose={handleClose}
        //setPromotionInfo={setPromotionInfo}
      />

      <EditCodePromotion
        open={openEdit}
        onClose={handleCloseEdit}
        id={idPromo}
      />
    </div>
  );
}
