import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


 export const initialState = {
    admins: [],
    status: 'idle',
    error: null,
  };
// function thunk 
export const fetchAdmins =createAsyncThunk('admins/fetchAdmins', async () => {
  const url=`${process.env.REACT_APP_API_URL}admin-user/all?pageSize=100&pageNumber=1`;
  console.log(url);
  const response = await fetch(url);
  const data = await response.json();
  console.log(data);
  return data.data
})
export const addNewAdmin = createAsyncThunk(
  'admins/addNewAdmin',
  async (adminValue) => {
    console.log("hello wordl")
   console.log(adminValue);
    const url =`${process.env.REACT_APP_API_URL}admin-user`
    const response = await  fetch( url,{
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(adminValue),
  })
 
  const data= await response.json();
  console.log(data);
    return data
  }
)
  const adminsSlice = createSlice({
     name:'admins',
     initialState,
     reducers:{
     },
     extraReducers: {
      [fetchAdmins.pending]: (state, action) => {
        state.status = 'loading'
      },
      [fetchAdmins.fulfilled]: (state, action) => {
        state.status = 'succeeded'
        
        state.admins = state.admins.concat(action.payload)
      },
      [fetchAdmins.rejected]: (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      },
      [addNewAdmin.fulfilled]: (state, action) => {
        //console.log('im here')
        console.log(action.payload);
        state.admins.push(action.payload)
      },
    },
  });

  export default  adminsSlice.reducer;
  export const { adminAdded }= adminsSlice.actions

  // A selector 
  export const adminsSelector = state => state.admins

  