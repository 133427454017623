import * as React from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';
import useStyles from './styles';
import SubMenu from '../../components/SubMenu/index';
import { Avatar, Typography, Button } from '@material-ui/core';
import AbbonementsW from '../../assets/icone/AbbonementsW.png';
import AvatarHomme from '../../assets/AvatarHomme.png';
import AvatarFemme from '../../assets/AvatarFemme.png';
import { Link } from 'react-router-dom';

export default function Signalements() {
  var classes = useStyles();
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [refresh, setrefresh] = React.useState(false);

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const Bann = async (id) => {
    console.log(id);
    const url = `${process.env.REACT_APP_API_URL}admin-user/Bann/` + id;
    const response = await fetch(url);
    setrefresh(!refresh);
  };
  const Ignore = async (id) => {
    console.log(id);
    const url = `${process.env.REACT_APP_API_URL}admin-user/Ignore/` + id;
    const response = await fetch(url);
    setrefresh(!refresh);
  };
  React.useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}admin-user/ReportedUsers?pageSize=7&pageNumber=${page}`;
      console.log(url);
      const response = await fetch(url);
      const data = await response.json();
      setPageCount(data.pageCount);
      setData(data.data);
      if (!active) {
        return;
      }
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [refresh]);

  return (
    <div style={{ height: 500, width: '100%', backgroundColor: 'white' }}>
      <SubMenu active='4' />
      <div style={{ justifyContent: 'center', display: 'flex' }}>
        <Link
          to='/loveattraction/users/signalements'
          className={classes.MenuButton}
          style={{ textDecoration: 'underline' }}
        >
          Signalements
        </Link>
        <Link
          to='/loveattraction/users/banned'
          className={classes.MenuButton}
          style={{ textDecoration: 'none' }}
        >
          Utilisateurs Bannies
        </Link>
      </div>

      <PageTitle title='Signalements' button='' />
      {data.length < 1 ? (
        <div
          style={{ justifyContent: 'center', display: 'flex', marginTop: 100 }}
        >
          <Typography
            style={{ fontFamily: 'SF-Pro-Display-Regular', fontSize: 20 }}
          >
            Aucun signalement
          </Typography>
        </div>
      ) : (
        data.map((item) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                display: 'flex',
                flex: 1,
                backgroundColor: 'white',
              }}
            >
              <div className={classes.avatarContainer}>
                <Typography style={{ fontFamily: 'SF-Pro-Display-Medium' }}>
                  Utilisateur :
                </Typography>
                {item.id?.avatar === null ? (
                  <Avatar
                    src={item.id?.gender === 'mal' ? AvatarHomme : AvatarFemme}
                    className={classes.avatar}
                  />
                ) : (
                  <Avatar
                    src={
                      item.id?.avatar.substring(0, 8) === 'https://'
                        ? item.id?.avatar
                        : `${process.env.REACT_APP_API_URL}${item.id?.avatar}`
                    }
                    className={classes.avatar}
                  />
                )}
                <Typography
                  style={{ fontFamily: 'SF-Pro-Display-Regular', marginTop: 5 }}
                >
                  {item?.username}, {item.id?.age} ans
                </Typography>
                <Typography style={{ fontFamily: 'SF-Pro-Display-Regular' }}>
                  {item.id?.cityName}, {item.id?.countryName}
                </Typography>
              </div>
              <div className={classes.avatarContainer}>
                <Typography style={{ fontFamily: 'SF-Pro-Display-Medium' }}>
                  Signalé par :
                </Typography>
                {item.reportedBy?.avatar === null ? (
                  <Avatar
                    alt='Abonnements'
                    src={
                      item.reportedBy?.gender === 'mal'
                        ? AvatarHomme
                        : AvatarFemme
                    }
                    className={classes.avatar}
                  />
                ) : (
                  <Avatar
                    alt='Abonnements'
                    src={
                      item.reportedBy?.avatar.substring(0, 8) === 'https://'
                        ? item.reportedBy?.avatar
                        : `${process.env.REACT_APP_API_URL}${item.reportedBy?.avatar}`
                    }
                    className={classes.avatar}
                  />
                )}
                <Typography
                  style={{ fontFamily: 'SF-Pro-Display-Regular', marginTop: 5 }}
                >
                  {item?.reportedByUsername}, {item.reportedBy?.age} ans
                </Typography>
                <Typography style={{ fontFamily: 'SF-Pro-Display-Regular' }}>
                  {item.reportedBy?.cityName}, {item.reportedBy?.countryName}
                </Typography>
              </div>
              <div className={classes.box}>
                <Typography style={{ fontFamily: 'SF-Pro-Display-Regular' }}>
                  Raison du signalement{' '}
                </Typography>
                <Typography style={{ fontFamily: 'SF-Pro-Display-Light' }}>
                  {item?.motif}
                </Typography>
              </div>
              <div className={classes.actionsBox}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    onClick={() => {
                      Ignore(item?._id);
                    }}
                    variant='outlined'
                    style={{
                      borderColor: '#74b9e6',
                      borderRadius: 10,
                      color: '#74b9e6',
                      margin: 20,
                      width: 150,
                    }}
                  >
                    Ne rien faire
                  </Button>
                  <Button
                    variant='outlined'
                    style={{
                      borderColor: '#9B59B6',
                      borderRadius: 10,
                      color: '#9B59B6',
                      margin: 20,
                      width: 150,
                    }}
                  >
                    Avertissement
                  </Button>
                </div>
                <Button
                  onClick={() => {
                    Bann(item?._id);
                  }}
                  variant='outlined'
                  style={{
                    borderColor: 'red',
                    borderRadius: 10,
                    color: 'red',
                    marginLeft: 50,
                  }}
                >
                  Bannir
                </Button>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}
