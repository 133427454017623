import React, { useState } from 'react';
import SubMenu from '../../components/SubMenu';
import useStyles from '../simulateur/styles';
import { Button, Grid, Typography } from '@material-ui/core/';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-material-ui';
import { MenuItem } from '@mui/material';
import csc from 'country-state-city';
import TextField2 from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export const SimulatorAstral = (props) => {
  const transAstral = {
    Sun: 'Soleil',
    Moon: 'Lune',
    Mercury: 'Mercure',
    Venus: 'Vénus',
    Mars: 'Mars',
    Jupiter: 'Jupiter',
    Saturn: 'Saturne',
    Uranus: 'Uranus',
    Neptune: 'Neptune',
    Pluto: 'Pluton',
    1: 'Maison 1',
    2: 'Maison 2',
    3: 'Maison 3',
    4: 'Maison 4',
    5: 'Maison 5',
    6: 'Maison 6',
    7: 'Maison 7',
    8: 'Maison 8',
    9: 'Maison 9',
    10: 'Maison 10',
    11: 'Maison 11',
    12: 'Maison 12',
    cp: 'Capricorne',
    aq: 'Verseau',
    pi: 'Poissons',
    ar: 'Bélier',
    ta: 'Taureau',
    ge: 'Gémeaux',
    cn: 'Cancer',
    le: 'Leo',
    vi: 'Vierge',
    li: 'Balance',
    sc: 'Scorpion',
    sa: 'Sagittaire',
  };
  var classes = useStyles();
  const [data, setData] = useState({
    houses: '',
    houseDeg: '',
    planets: '',
    planetsDeg: '',
  });

  const [autoCoord, setAutoCoord] = useState({ lat: '', lgt: '' });

  const [option, setOption] = useState('');

  const [sentData, setSentData] = useState();

  const [countries, setcountries] = useState(csc.getAllCountries());
  const [villes, setvilles] = useState([]);

  const getVilles = async (CountryCode) => {
    const url = `${process.env.REACT_APP_API_URL}auth/cities/${CountryCode}`;
    const response = await fetch(url);
    const data = await response.json();
    setvilles(data.data);
  };

  console.log(autoCoord);
  return (
    <>
      <SubMenu active='5' />
      <div className={classes.flex}>
        <div className={classes.fifFlex}>
          <Formik
            initialValues={{
              username: '',
              birthday: '',
              birthTime: '',
              lat: 0,
              lgt: 0,
            }}
            onSubmit={async (values, { setSubmitting }) => {
              let dtoTOTransfer = {};
              dtoTOTransfer.birthday = values.birthday;
              dtoTOTransfer.birthTime = values.birthTime;
              dtoTOTransfer.username = values.username;

              dtoTOTransfer.lat =
                option === 'auto' ? autoCoord.lat : values.lat;
              dtoTOTransfer.lgt =
                option === 'auto' ? autoCoord.lgt : values.lgt;

              console.log(dtoTOTransfer);

              const url = `${process.env.REACT_APP_API_URL}auth/SimulatorAstral`;
              const response = await fetch(url, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(dtoTOTransfer),
              });

              const data = await response.json();
              console.log(data);
              setData({
                houseDeg: data.data.housesDegree,
                houses: data.data.houses,
                planets: data.data.plantes,
                planetsDeg: data.data.plantesDegree,
              });
              setSentData(dtoTOTransfer);
            }}
          >
            {({
              errors,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form className={classes.form}>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <Field
                      component={TextField}
                      name='username'
                      type='username'
                      label="Nom d'utilisateur"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <text style={{ fontWeight: 'bold' }}>
                      Date de naissance
                    </text>
                    <Field
                      component={TextField}
                      name='birthday'
                      type='date'
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      name='birthTime'
                      type='time'
                      label='heure de naissance '
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <text style={{ fontWeight: 'bold' }}>Option</text>
                    <Field
                      component={Select}
                      name='Option'
                      label='Option'
                      fullWidth
                    >
                      <MenuItem value='manu' onClick={() => setOption('manu')}>
                        manual
                      </MenuItem>
                      <MenuItem value='auto' onClick={() => setOption('auto')}>
                        automatique
                      </MenuItem>
                    </Field>
                  </Grid>

                  {option !== '' ? (
                    option === 'manu' ? (
                      <div>
                        <Grid item xs={12}>
                          <Field
                            component={TextField}
                            name='lat'
                            label='latitude'
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Field
                            component={TextField}
                            name='lgt'
                            label='longitude'
                            fullWidth
                          />
                        </Grid>
                      </div>
                    ) : (
                      <>
                        <Grid item xs={12} sm={12}>
                          <text style={{ fontWeight: 'bold' }}>
                            Pays de naissance
                          </text>
                          <Field
                            component={Select}
                            name='nativeCountry'
                            type='nativeCountry'
                            onClick={(e) => {
                              getVilles(e.target.value);
                            }}
                            fullWidth
                          >
                            {countries.map((fbb) => (
                              // console.log(fbb),
                              <MenuItem value={fbb.isoCode}>
                                {fbb.name}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <text style={{ fontWeight: 'bold' }}>
                            Ville de naissance
                          </text>
                          <Autocomplete
                            id='combo-box-ville'
                            options={villes}
                            getOptionLabel={(option) => option.name}
                            onChange={(e, value) => {
                              console.log(value);
                              if (value !== null) {
                                console.log('entered');
                                setAutoCoord({
                                  lgt: value.longitude,
                                  lat: value.latitude,
                                });
                              }
                            }}
                            renderInput={(params) => <TextField2 {...params} />}
                          />
                        </Grid>
                      </>
                    )
                  ) : (
                    <div>
                      <h1>veuillez choisir une option</h1>
                    </div>
                  )}

                  <Grid item xs={12}>
                    <Button
                      className={classes.submit}
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                    >
                      sauvegarder
                    </Button>
                  </Grid>

                  {sentData ? (
                    <>
                      <h3>données envoyées au serveur:</h3>
                      <Grid item xs={12}>
                        <p style={{ fontWeight: 'bold' }}>nom:</p>
                        {sentData.username}
                        <p style={{ fontWeight: 'bold' }}>Date de naissance</p>
                        {sentData.birthday}
                        <p style={{ fontWeight: 'bold' }}>Heure de naissance</p>
                        {sentData.birthTime}
                        <p style={{ fontWeight: 'bold' }}>Latitude</p>
                        {sentData.lat}
                        <p style={{ fontWeight: 'bold' }}>Longitude</p>
                        {sentData.lgt}
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
        <div className={classes.fifFlex}>
          {data.houseDeg === '' ||
          data.houses === '' ||
          data.planets === '' ||
          data.planetsDeg === '' ? (
            <h2>veuillez effectuer un calcul</h2>
          ) : (
            <>
              <Typography className={classes.title}>
                Theme Astral Planètes
              </Typography>
              {Object.entries(data.planets).map(([key, value]) => {
                return (
                  <Typography className={classes.block}>
                    <strong>{transAstral[key]}:</strong>
                    {transAstral[value] + ' '}
                    <strong>
                      {data.planetsDeg[key][0]}°{data.planetsDeg[key][1]}
                    </strong>
                  </Typography>
                );
              })}
              <Typography className={classes.title}>
                Theme Astral Maisons
              </Typography>
              {Object.entries(data.houses).map(([key, value]) => {
                return (
                  <Typography className={classes.block}>
                    <strong>{transAstral[key]}:</strong>
                    {transAstral[value] + ' '}
                    <strong>
                      {data.houseDeg[key][0]}°{data.houseDeg[key][1]}
                    </strong>
                  </Typography>
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
};
