import * as React from 'react';
import useStyles from './styles';

export const LivePrevItem = (props) => {
  const { mois, pourc } = props;
  const classes = useStyles();
  const price = mois === 1 ? '19.99' : mois === 3 ? '47.70' : '77.40';
  return (
    <div>
      <div className={classes.itemInLayout}>
        <h1>{mois}</h1>
        <div>
          <p
            style={{
              color: '#e21067',
            }}
          >
            Premium
          </p>
          <p>mois</p>
          <p
            style={{
              textDecoration: 'line-through',
            }}
          >
            {price}€
          </p>
        </div>
        <p>=</p>
        <div className={classes.priceCont}>
          <p>{(+price - +price * pourc).toFixed(2)}€</p>
        </div>
      </div>
    </div>
  );
};
