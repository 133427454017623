export const Belier_Homme = 'Monsieur Bélier aime l’action directe, les étreintes dynamiques et soutenues. Il est capable d’effectuer ses élans amoureux dans des lieux improvisés, appréciant particulièrement la proximité d’un feu de cheminé. Dans une relation sexuelle, il privilégie son plaisir personnel même s’il prétend le contraire. Mais il sait se montrer généreux dans ses efforts car la sexualité est sa façon de se sentir exister. Il n’est pas le roi des préliminaires. L\'homme Bélier aime aller droit au but';
export const Taureau_Homme = 'Monsieur Taureau recherchera le plaisir et la satisfaction immédiate, tout simplement parce que le sexe est l\'une de ses activités préférées. Il aime que chaque élan amoureux soit comme une nouvelle conquête, privilégiant le charme, la sensualité. Il aime les longs préliminaires. Sa grande énergie sous la couette vous fera oublier le fait qu\'il n\'est pas très imaginatif.  Monsieur Taureau aimera vous faire l’amour en pleine nature.';
export const Gemeaux_Homme = 'Monsieur Gémeaux a une libido non volcanique, ce n\'est pas l\'amant le plus passionné ou le plus laborieux. En tant que double signe, sous la couette, il joue deux rôles - celui d\'un amoureux et celui d\'un observateur. Il préfère les échanges de comportements, le langage du regard, faire l’amour au travers les mots, flirter dans une atmosphère ludique. Il adore que les yeux de sa partenaire se fondent dans les siens avec un léger sourire coquin …Laissant présumer de la suite.';
export const Cancer_Homme = 'Monsieur Cancer possède une libido variable suivant les moments et l’environnement, il doit toujours être encouragé. S\'il est soutenu, il peut être l\'un des plus grands amants du zodiaque. Il n\'est pas comme les autres hommes qui cherchent uniquement à prouver leur masculinité au lit. Adepte du sexe oral, cette technique étant l\'une de ses préférées, il saura vous faire hurler de plaisir.';
export const Vierge_Homme = "Monsieur Vierge a peur de ne pas être à la hauteur et ne s’élance que s'il se sent en situation de confiance. Faire le premier pas lui demande un effort car il a peur d’effectuer une maladresse. Mais lorsque la confiance est là, Monsieur Vierge sait se couper en quatre pour apporter tout le plaisir possible à sa partenaire. Sous la couette, ses préliminaires sont méthodiques comme s'il les avait répétés. Il sait très bien ce qui excite une femme et il utilisera toutes ses connaissances pour la faire vibrer de tous ses atomes.";
export const Capricorne_Homme = "Monsieur Capricorne n’a pas une grosse libido. Il n’aime pas être brusqué et aime connaître à l’avance le lieu des futurs élans amoureux. Il aime particulièrement prendre son temps et s’adonner à de longs préliminaires. C'est le genre d'homme capable de maintenir longtemps le plaisir de sa partenaire. Il se retirera juste avant l'orgasme. Il s'exécutera oralement jusqu'à ce que vous soyez complètement satisfaite. Car votre plaisir passe avant le sien. Une fois lancée, le Capricorne est un spécialiste du marathon !";
export const Poissons_Homme = "Sous la couette, Monsieur Poissons aime mener la danse. Il n’a pas de limites, ni légales ni morales, et il aime faire l’amour avec des femmes plus expérimentées. Il est à la recherche de relations mystérieuses, avec des femmes qu'il connaît à peine et avec lesquelles il peut réaliser tous ses fantasmes secrets. Monsieur Poissons fera tout pour satisfaire sa partenaire. Il n'est jamais égoïste en matière de plaisir sexuel. Sensible à l’eau, ce signe du zodiaque adore partager des élans amoureux sous une douche ou dans une baignoire.";
export const Verseau_Homme = "Monsieur Verseau est un partenaire prévenant qui s’attachera à vous faire apprécier vos moments intimes par de petites attentions. Il n’apprécie pas sexuellement l’action directe, mais se situe très loin d’une vision romantique de la chose. Il ne voit pas les femmes comme des objets sexuels à moins que sa partenaire le veuille. Passionné, Monsieur Verseau enlace sa partenaire de tendresse et sait se donner afin de la satisfaire. Pour lui le sexe est une aventure, une opportunité d'essayer de nouvelles choses.";
export const Scorpion_Homme = "Monsieur Scorpion aime vivre des situations sexuellement extrêmes. Suivant les situations, l’alternance entre les câlins et l’action directe ne le dérange pas. Il possède une forte libido et ne connaît pas de freins à ses pulsions et désirs. Il aime les femmes entreprenantes et qui savent se mettre en avant. La sexualité est le domaine privilégié où il veut montrer à sa partenaire de quoi il est capable. Les préliminaires excessifs ne sont pas son truc. Progressivement, il en veut toujours plus et réclame un assouvissement immédiat.";
export const Sagitaire_Homme = "Monsieur Sagittaire apprécie autant la sensualité que la sexualité. Imaginatif, il est capable d’inventer de nouvelles positions ou caresses afin de stimuler et prolonger les élans amoureux. Ce jouisseur est capable de mélanger humour et sexualité. Il se préoccupe plus de donner du plaisir qu’à en recevoir. Ce signe du zodiaque apprécie la diversité dans la manière de faire l’amour car c’est un observateur et explorateur. Il vous fera des massages avec les mains, mais aussi avec la langue car il sait viser vos zones les plus érogènes qui vous feront apprécier ces moments intenses de plaisirs.";
export const Lion_Homme = "Monsieur Lion est un dominant qui aura besoin de vous faire sentir qu’il est le maître lors de l’acte sexuel. Enjoué, il partira à votre conquête, jouant avec vous et votre anatomie. Une fois dans ses griffes, il saura vous prodiguer des caresses pour vous conduire à un plaisir intense. Comme il déteste la médiocrité et qu’il est avide d’attentions et de compliments, il aimera vous entendre gémir sous ses assauts et adora lorsque vous le féliciterez de ses prouesses. Attention il en attendra autant de vous, car il est un amant passionné qui recherche une relation authentique et partagée.";
export const Balance_Homme = "Monsieur Balance n’apprécie ni la violence, ni les positions difficiles. L'intensité est mise au second plan, il privilégie la sensualité et les tendres préliminaires. Il adore masser et surtout être masser. Monsieur Balance aime ressentir ce sentiment de complicité et de partage dans ses élans amoureux. Si tout est en harmonie et que l'ambiance est agréable, il peut faire hurler sa partenaire de plaisir. Il connaît le fonctionnement du corps féminin et il est très intuitif.";

export const Belier_Femme = 'Madame Bélier a une libido élevée, donc si vous êtes timide et que vous n’avez pas assez d’énergie sexuelle, vous feriez mieux de fuir  cette femme. Elle aime l’action directe, les étreintes dynamiques et soutenues. Dans une relation sexuelle, Madame Bélier saura amener son partenaire à l\'apogée. Elle achètera toutes sortes de jouets et de lingerie sexy pour vous exciter et rendre la vie sous la couette plus intéressante et amusante. Elle n\’est pas la reine des préliminaires. Madame Bélier aime aller droit au but.';
export const Taureau_Femme = 'Madame Taureau est généralement dominante et possessive. Elle aime prendre les devants et avoir le contrôle. Elle aime que chaque élan amoureux soit comme une nouvelle conquête, privilégiant le charme, la sensualité. Elle est exigeante sous la couette, et vous en demandera plus si elle aime la façon dont vous lui faites l\'amour. Elle ne supporte pas les choses faites rapidement, sans charme et sans effort.  Madame Taureau aime les atmosphères douces et sensuelles, et elle se donne avec passion et tendresse.';
export const Gemeaux_Femme = 'Madame Gémeaux est intellectuelle, sensuelle et sexy. Elle sait s\'entourer de mystère et révèle une nouvelle facette d\'elle chaque fois qu\'elle est sous la couette avec vous. Elle aime faire l’amour au travers les mots, flirter dans une atmosphère ludique. Ses fantasmes sexuels sont sauvages et elle peut avoir de nombreux fantasmes. Par exemple, une orgie ou un trio ne lui semblerait pas si effrayant. Elle fera tout pour être satisfaite, car elle est plus receveuse que donneuse. C\'est pourquoi Madame Gémeaux sera très exigeante au lit...';
export const Cancer_Femme = "Sensuelle et romantique, Madame Cancer possède une libido variable suivant les moments et l’environnement. Elle préfère y aller lentement, mais sûrement. Si elle a confiance en son partenaire, sous la couette, elle a une imagination débordante. Ses fantasmes sont parmi les plus fous. Elle aime faire l’amour dans la salle de bain car le contact de l’eau sur sa peau crée en elle des vibrations stimulantes pleines de désir.  Madame Cancer apprécie aussi les grands moments de câlins, materner et se faire materner.";
export const Vierge_Femme = "Madame Vierge a peur de ne pas être à la hauteur et ne s’élance que si elle se sent en situation de confiance. Faire le premier pas lui demande un effort car elle a peur d’effectuer une maladresse. Mais lorsque la confiance est là, Madame Vierge saura se montrer très dévouée pour apporter tout le plaisir possible à l'heureux élu. Pour elle, satisfaire les fantasmes de son homme est un devoir. Elle aimera être ligotée ou fessée. Tout ce qui rendra heureux son partenaire, la rendra heureuse aussi. Elle a besoin d'un homme avec qui elle peut partager ses fantasmes sexuels les plus sombres.";
export const Capricorne_Femme = "Sous ses aspects calmes dans la vie de tous les jours, Madame Capricorne  peut être complètement différente sous la couette. Dans l'intimité, après un long jeu de préliminaires, elle peut faire l'amour avec passion et sera toujours prête à tenter de nouvelles expériences. Si vous savez stimuler sa libido, elle atteindra rapidement l'orgasme. Mais soyez patient, ce n'est que rarement la femme d'un premier soir.";
export const Poissons_Femme = "Sous la couette, Madame Poisson aime danser au rythme de ses sensations. Profondément féminine, elle adore les ambiances feutrées, avec des évanescences de parfums, d’encens. Elle a besoin de tendresse et de caresses pour s’épanouir. Elle n’est pas prête aux relations rapides, elle se sent alors blessée et incomprise. Elle a besoin de s’acclimater avec le lieu et son partenaire avant de s’abandonner totalement. Elle ne ménagera alors ni efforts ni attentions pour vous rendre aux anges. Ce qui la stimulera sexuellement, c’est conjuguer fantasmes et sensations physiques.";
export const Verseau_Femme = "Madame Verseau n'est pas du genre à perdre le contrôle au moindre petit baiser. Elle vous donnera son corps uniquement si vous la fascinez. Elle sera alors prête à expérimenter de nouvelles expériences sexuelles car elle aime prendre des risques. C’est une partenaire prévenante qui s’attachera à vous faire apprécier vos moments d’intimités par de petites attentions. Elle n’apprécie pas sexuellement l’action directe, mais se situe bien loin d’une vision romantique de la sexualité. Sachant se remettre en cause, elle accueillera toutes remarques, prenant ceci comme point de départ pour tenter de s’améliorer. Passionnée, madame Verseau enlacera son partenaire de tendresse et saura se donner à fond, afin de le satisfaire.";
export const Scorpion_Femme = "Madame Scorpion est une véritable déesse du sexe. Elle a une libido élevée et c'est une amante très talentueuse qui ne connaît pas de freins à ses pulsions et à ses désirs. Sous la couette, elle est à la fois une donneuse et une receveuse de plaisir. Elle aime les hommes entreprenants et virils La sexualité est le domaine privilégié où elle veut montrer à son partenaire de quoi elle est capable. Les préliminaires excessifs ne sont pas son truc. Progressivement, il en veut toujours plus et réclame un assouvissement immédiat. Assurez-vous d'avoir beaucoup d'énergie sexuelle car elle ne peut pas être arrêtée une fois qu'elle a commencé.";
export const Sagitaire_Femme = "Comme tous les signes de Feu, Madame Sagittaire est passionnée sous la couette. Elle n'est pas dans l'émotionnel car le sexe est pour elle purement physique. Très aventureuse, elle cherchera de nouveaux défis sexuels et vous devrez être très imaginatif et audacieux. Vous vivrez avec elle des expériences hautement érotiques. Sachez être à la hauteur et garder le rythme car elle a de grandes attentes et elle peut être très bruyante... alors fermez les fenêtres...";
export const Lion_Femme = "Madame Lion est une perfectionniste, exigeante et dominatrice. Elle choisit ses partenaires en visant l’excellence car elle déteste la médiocrité. Elle aura besoin de sentir que son partenaire veut la conquérir comme un joyau que l’on convoite pour sa valeur précieuse. Sexuellement, un brin exhibitionniste elle adore qu’on l’admire. Passionnée, elle saura devenir chatte et s’abandonner à votre domination, par jeu bien entendu.";
export const Balance_Femme = "Pour Madame Balance l'intensité est mise au second plan, elle privilégie la sensualité.  Madame Balance aime ressentir ce sentiment de complicité et de partage dans ses élans amoureux, voire se sentir possédée sexuellement par son partenaire. Elle est sans tabous et elle a ce côté sauvage qui peut surprendre. Elle pourrait vous suggérer de la ligoter nue, car c'est une femme qui n'a aucune inhibition vis-à-vis de son corps. Elle adore les câlins, les baisers et le sexe oral. N'hésitez pas à lui offrir de longs préliminaires.";

//MARS
/* 
export const Mars_Belier_Belier="C'est la rencontre explosive d'un homme viril et d'une femme indépendante. Il saura la soumettre car il est l'incarnation du mâle dominant qu'elle recherche. Leur sexualité est torride et sportive. ";
export const Mars_Belier_Taureau="Elle recherche un homme de pouvoir, un meneur. Lui, par son autorité et sa force, n'entend pas supporter les crises de jalousies de madame. Sa sexualité est puissante, mais manque de sensualité et de préliminaires pour elle.";
export const Mars_Belier_Gemeaux="Elle recherche un homme brillant intellectuellement, c'est comme ça qu'il faut la séduire. Mais lui est plus basique, plus primaire. Seule une sexualité basée sur les jeux de rôles et l'originalité pourra les satisfaire. ";
export const Mars_Belier_Cancer="Lui aime une sexualité un peu agressive qui ne convient pas au désir de sexualité douce et délicate que cette princesse réclame. Elle recherche un homme sentimental et affectueux, un prince charmant. Ce guerrier au sang chaud ne correspond pas du tout à cet archétype. ";
export const Mars_Belier_Lion="La puissance et la passion sont les maîtres mots de leur rencontre. Il incarne la masculinité et la puissance et elle recherche un dominateur.  Leur sexualité sera puissante et débridée, parfois même brutale. ";
export const Mars_Belier_Vierge="Il aime la sexualité agressive et exhibitionniste, elle est plutôt réservée et pudique. Leur sexualité ne pourra pas s'épanouir, car cette ingénue se sentira comme violée par ce hussard. Une sexualité qui risque de s’installer rapidement dans une routine frustrante.";
export const Mars_Belier_Balance="Avec eux ce sera « faites l'amour pas la guerre », malgré l'agressivité de ce guerrier de l’amour, elle réussira à adoucir la brutalité de ce fougueux amant tout en sublimant la passion dévorante qui animera leurs ébats.";
export const Mars_Belier_Scorpion="Rencontre de deux excessifs, qui n'hésiteront pas explorer les fins fonds de leur psyché et  les extrêmes méandres de la sexualité. Leur histoire pourrait se résumer par \"enfer et damnation\". Attention à la violence des actes. ";
export const Mars_Belier_Sagittaire="Plaisir quand tu nous tiens ! Pas de prises de tête juste le bonheur de goûter aux sensations intenses que procure une sexualité spontanée et ardente. Les coups de gueule de ces deux colériques ne seront qu'une excuse pour remettre le couvert.";
export const Mars_Belier_Capricorne="Cet amant impulsif se heurte à une femme dans la maîtrise de tout, dont l’énergie est canalisée, contrôlée et diluée. La sexualité est bridée et l'impatience de ce nerveux ne compte pas s'éterniser sur tant de retenues et de froideurs. C'est la rencontre du feu et de la glace... brrr";
export const Mars_Belier_Verseau="Le désir sexuel offensif et charnel de monsieur, ne pourra que s'entendre avec cette demoiselle si curieuse de tout et ouverte à toute expérience sexuelle, sans tabous aucuns. Mais la nervosité de cet amant impétueux pourrait tout gâcher.";
export const Mars_Belier_Poissons="La sexualité bien trop agressive de monsieur bloquera cette sirène qui recherche dans la sexualité, à transcender son amour très sensuel avec une bonne dose de tendresse et de sentiments. Sex-attitude ne fait pas bon ménage avec romantisme-attitude.";

export const Mars_Tauraeu_Belier="Son charme sensuel ne pourra que la faire craquer. Gourmande, elle sera une partenaire à la hauteur de ses attendes. Relation strictement érotique, cette liaison de courte durée leur laissera un merveilleux souvenir malgré des coups de gueule dévastateurs. ";
export const Mars_Tauraeu_Taureau="Le charme opère immédiatement, ces deux gourmands se délecteront l'un de l'autre, dans des ébats langoureux et de longs préliminaires aiguisant leur appétit. Seules leurs puissantes colères ébranleront cette douce harmonie.";
export const Mars_Tauraeu_Gemeaux="Lui est un boulimique du sexe ce qui exaspère au plus haut point cette cérébrale portée vers les nourritures intellectuelles. L'un se transcende dans le fantasme alors que l'autre a besoin de concrétiser ses envies. Elle adore le faire languir et lui se voit obligé de la harceler. Incompatibilité de désirs.";
export const Mars_Tauraeu_Cancer="Cette boulimique de câlins s'attendrira devant la montagne de douceur que lui prodiguera ce gros nounours, exprimant ses sentiments dans des corps à corps enflammés et gourmands. L'amour s'épanouit dans la sexualité.";
export const Mars_Tauraeu_Lion="Ils pourront être emportés par une passion animale, sur un simple regard coquin, se laissant aller à un moment sensuel. Un instant d'égarement qui leur laissera un souvenir délicieux. Leur nature autoritaire ne leur permettra pas d'espérer plus.";
export const Mars_Tauraeu_Vierge="Sa puissante virilité et sa sensualité enveloppante, décoinceront cette vierge effarouchée à la sexualité timide et pudique. Patient et déployant des trésors d'ingéniosité, il réussira à la désinhiber et à révéler une sexualité débridée.";
export const Mars_Tauraeu_Balance="Elle recherche un homme séduisant, lui faisant une cour effrénée avant de se donner. En gentleman, il saura patienter ne désirant que lui montrer par ses caresses, ô combien il est conquis. Sexualité romantique, sur fond de douceur et volupté. ";
export const Mars_Tauraeu_Scorpion="L'attraction est inévitable. Tels deux aimants, ils ne pourront se décoller l'un de l'autre. Tous deux sensuels et sexuels, ils vivront une passion dévorante dans un désir de possession totale. Leurs crises de jalousies seront aussi excessives que leurs pulsions.";
export const Mars_Tauraeu_Sagittaire="Une insatisfaction totale. Lui est extrêmement possessif et elle adepte de la liberté, refusant toute aliénation et toute contrainte. Leurs désirs trop contradictoires créeront un invivable malaise. Affrontements violents sur fond de désaccords.";
export const Mars_Tauraeu_Capricorne="Lui, plutôt viril et protecteur, rassurera madame. Elle se laissera volontiers aller dans les bras de ce sensuel amant. La constance et le sérieux de monsieur ne feront qu'accentuer la confiance de cette frileuse qui, peut à peut, se réchauffera.";
export const Mars_Tauraeu_Verseau="Elle recherche un homme libre qui sort des sentiers battus, alors que lui se complaît dans le conformisme. Leur sexualité est certes passionnée, mais bien trop classique et possessive pour cette originale qui n'appartient à personne.";
export const Mars_Tauraeu_Poissons="La délicatesse de ses caresses attendrira cette romantique invétérée, l'amour est sublimé par une douce sensualité. Elle sera soumise à sa volonté autoritaire et permettra à cette relation de trouver une vitesse de croisière épanouissante.";

export const Mars_Gemeaux_Belier="On ne peut pas dire qu'il soit l'homme musclé qu'elle recherche, mais son intelligence et ses jeux d'esprit la séduiront tout autant. Les fantasmes de cet intello et la passion de cette sauvageonne sauveront leur sexualité pourtant très discordante.";
export const Mars_Gemeaux_Taureau="Elle saura lui prodiguer de divins massages qui exalteront son imagination et réveilleront ses fantasmes. Lui aime jouer et ne s'enflammera que le temps d'une soirée pour cette voluptueuse amante.";
export const Mars_Gemeaux_Gemeaux="Une rencontre pleine de surprises où la fantaisie et les fantasmes émerveillent ces deux gais lurons. Ils s’amusent, se titillent et testent ensemble de nombreuses façons de faire l'amour. Sexualité joyeuse et imaginative. ";
export const Mars_Gemeaux_Cancer="Lui ne mélange pas sexe et sentiments, il a soif de sensations et de délires cérébraux. Le romantisme est exclu de ses jeux d’adultes et il se lassera vite des mièvreries amoureuses de cette amante capricieuse.";
export const Mars_Gemeaux_Lion="Une relation où les occasions de plaisirs ne manqueront pas. L’habileté de l’un avivera la passion de l’autre. Une mise en scène de leurs ébats leur apportera une touche théâtrale qui aiguisera leur excitation.";
export const Mars_Gemeaux_Vierge="Il lui faudra une bonne dose de patience, qui n’est pas son fort, et d’imagination pour faire décoller cette coincée. Elle, très cérébrale, ne demande pourtant qu’à s’exalter. Une connivence possible dans le fantasme.";
export const Mars_Gemeaux_Balance="Cette coquette sexy saura exciter tous les sens de ce grand gourmet. Il prendra un vif plaisir à l’effeuiller, découvrant des tenues coquines qu’il affectionne tout particulièrement. Entre eux, c’est « jeux interdits »";
export const Mars_Gemeaux_Scorpion="Ces deux provocateurs curieux de pousser toujours plus loin les limites de leur libido, assouviront ensemble tous leurs fantasmes les plus secrets. Mais monsieur plus cérébral et moins trash refusera tout asservissement à madame.";
export const Mars_Gemeaux_Sagittaire="Une attirance irrésistible pour ces deux curieux de la vie. Ils s’enthousiasmeront ensemble, emportés par l’ivresse des sens et la découverte de leurs plaisirs. Pas de temps à perdre, la vie est trop courte pour s’ennuyer.... Sexualité joyeuse.";
export const Mars_Gemeaux_Capricorne="Les élans fantaisistes de cet amant passionné seront stoppés rapidement par cette amante au conservatisme routinier. Trop de normalité et si peu de folie, refroidira l’imaginaire de notre cérébral et ne le motivera pas à la tâche.";
export const Mars_Gemeaux_Verseau="Ensemble ils exploreront toute la littérature érotique et testerons toutes les postures du Kamasutra. La nouveauté et l’originale motiveront leurs ébats, rien de tel pour pimenter une relation. Sans tabous, juste pour le plaisir. Un couple libertin.";
export const Mars_Gemeaux_Poissons="Sensuelle, madame sera une amante parfaite, docile et soumise à tous les désirs et fantasmes de cet imaginatif libertin. Mais le manque de tendresse de monsieur, frustrera les attentes romantiques de cette amoureuse inconditionnelle.";


export const Mars_Cancer_Belier="On ne peut dire pas qu’il soit l’incarnation de la virilité, elle qui recherche les sensations fortes, elle ne devra se contenter que du réconfort de ce grand enfant à la recherche d'une mère. Sexualité coquine mais manquant d’adrénaline.";
export const Mars_Cancer_Taureau="Elle incarne la déesse mère, femme à la peau douce, sensuelle à souhait, elle est son fantasme et l’excitation qu’elle lui procure sera à la haute de la qualité de leurs ébats. Elle, se laissera emporter par l’ivresse des sens. Sexualité épanouie et gourmande.";
export const Mars_Cancer_Gemeaux="Il saura la poursuivre de ses assiduités et elle s’amusera à lui résister uniquement pour l’excitation et le jeu de la séduction. Mais elle succombera tôt ou tard s’abandonnant à ses caresses… le temps d’un instant…Plaisirs éphémères.";
export const Mars_Cancer_Cancer="Une attraction sensorielle quasi mystique, des élans de tendresse et des sensations extrêmes conditionnent une sexualité empreinte d’amour et de douceur. Leur chambre est un lieu protégé, c’est leur nid d’amour.";
export const Mars_Cancer_Lion="Il la traitera comme une reine, SA REINE, attentionné et caressant. Elle se laissera porter dans son tourbillon d'émotions et découvrira avec lui, les joies d'une sexualité délicate. Il lui manquera cependant un peu de virilité dans l'acte car madame aime les vrais durs.";
export const Mars_Cancer_Vierge="Craintive, elle recherche un homme délicat qui saura la mettre en confiance. Lui est un amoureux de la femme et sait la sublimer par des caresses attentives et coquines qu'il lui prodiguera sur tout le corps. Leur sexualité est un révélateur de sentiments.";
export const Mars_Cancer_Balance="Elle recherche un homme entreprenant qui saura la guider et la posséder. Malheureusement, c'est un homme soumis à son amante, attentif à ses désirs et à son plaisir certes, mais manquant de poigne dans l'acte. Il ne la fait pas rêver.";
export const Mars_Cancer_Scorpion="Attraction magique ! Leurs énergies s'entremêlent et leurs corps vibrent au rythme de sensations exaltantes. Ils vivent un érotisme torride dans le secret de leur alcôve. Relation où l’homme est l’esclave de la femme.";
export const Mars_Cancer_Sagittaire="Elle saura user de ses atouts pour emmener cet homme soumis dans des univers qui lui sont étrangers, emplis de fantasmes les plus insolites, un monde érotique illusoire et éphémère où le sexe n'est qu'un exutoire.";
export const Mars_Cancer_Capricorne="Sa douceur permettra cette femme réservée de s'ouvrir en toute confiance à l'amour et aux joies du sexe, sans retenues. Il saura l'épanouir et elle le lui rendra bien, le maternant comme un enfant, mais elle s'essoufflera avec le temps de ce rôle contre nature.";
export const Mars_Cancer_Verseau="Elle, c'est l'originalité qui l'excite, et il ne l'a fait pas fantasmer, trop banal. Trop insaisissable pour lui, ils se passeront à côté sans vraiment s'attarder, leurs univers charnels étant trop différents pour que la magie opère.";
export const Mars_Cancer_Poissons="Ces deux-là sont connectés sur la même longueur d'ondes érotico-sensorielles. Leur libido déborde de fantasmes, plus osés les uns que les autres et l'excitation est à son comble. Ils vivent une passion dévorante et torride.";

export const Mars_Lion_Belier="La puissance et la passion sont les maîtres mots de leur rencontre. Il incarne la masculinité et la puissance et elle recherche un dominateur.  Leur sexualité sera puissante et débridée, parfois même brutale.";
export const Mars_Lion_Taureau="Ils pourront être emportés par une passion animale, sur un simple regard coquin, se laissant aller à un moment sensuel. Un instant d'égarement qui leur laissera un souvenir délicieux. Leur nature autoritaire ne leur permettra pas d'espérer plus. ";
export const Mars_Lion_Gemeaux="Une relation où les occasions de plaisirs ne manqueront pas. L’habileté de l’un avivera la passion de l’autre. Une mise en scène de leurs ébats leur apportera une touche théâtrale qui aiguisera leur excitation.";
export const Mars_Lion_Cancer="Féminine à souhait elle saura se lover dans ses bras et lui laisser l'initiative, il est le maître de leurs ébats et compte bien la mener au 7ième ciel. Si elle sait le flatter et calmer son agressivité, leur histoire ira loin sinon elle se fera remercier.";
export const Mars_Lion_Lion="S'il y a bien un sujet sur lequel ils s'accordent, c'est bien le sexe. La sexualité est noble, élégante et puissante.  Ensemble ils rugissent de plaisirs et s'enflamment tour à tour se prodiguant des caresses brûlantes. De l’action avec panache.";
export const Mars_Lion_Vierge="Elle, à la sexualité pudique, sera totalement emportée par les élans de cet amant viril qui ne conçoit pas l'amour charnel sans la noblesse de l'acte. Rassurée par sa délicatesse, elle se laissera aller à ses fantasmes et se révélera une maîtresse imaginative.";
export const Mars_Lion_Balance="Tous deux amoureux du beau et du clinquant, s'adonneront à une sexualité débridée et passionnée, dans une atmosphère glamoureuse et sexy. Les dessous affriolants feront partie du décorum pour le plus grand plaisir de cet adepte du voyeurisme.";
export const Mars_Lion_Scorpion="Leur sexualité aurait pu être parfaite si l'orgueil et les perversités ne s'en mêlaient pas. Ces deux dominants auront du mal à trouver leur place et aucun des deux, ne se soumettra au désir de l'autre. Sexualité brutale et crue.";
export const Mars_Lion_Sagittaire="Ils s'enflammeront pour cette aventure érotique qui les projettera dans un monde de fantasmes et de plaisirs sans limites, mais toujours dans un cadre doucereux, sans tortuosités. Leur caractère impétueux et explosif excitera de plus belle leur libido.";
export const Mars_Lion_Capricorne="Le manque d'entrain de madame à la tâche, vexera la noblesse de cet homme passionné qui se sentira incompris et méprisé.  Ses besoins affectifs et démonstratifs seront mal perçus par la froideur et l'indifférence de cette amante sexuellement plus mécanique.";
export const Mars_Lion_Verseau="Une bonne connexion intellectuelle et une forte estime entre eux crée une attraction palpitante et passionnée. L'originalité des fantasmes de l'un, stimule la libido de l'autre.  Sexualité frissonnante et dévorante.";
export const Mars_Lion_Poissons="Elle, cherche à prendre dans ses filets un amoureux transi et vulnérable en se donnant corps et âme. Lui est un conquérant, qui saura honorer comme il se doit cette déesse de l'amour, mais se lassera vite de son inaccessibilité et de sa fragilité.";

export const Mars_Vierge_Belier="La cérébralité de monsieur s'accorde peu avec la primarité de madame, plus instinctive dans ses désirs. L'impatience de l'un agacera et refroidira la libido de l'autre.";
export const Mars_Vierge_Taureau="Cette sensuelle et voluptueuse maîtresse excitera tous les sens de cet amant prudent. Mais généreux, il comblera sa déesse d'attentions et de caresses les plus osées. Une sexualité épanouissante et coquine.  ";
export const Mars_Vierge_Gemeaux="Ces deux mercuriens trouveront un terrain d'entente dans des jeux érotiques qui aiguiseront leur excitation cérébrale. Échanges de sextos ou discussions chaudes au téléphone feront monter la température entre eux. Jeux de mots érotiques.";
export const Mars_Vierge_Cancer="Il trouve dans cette amante, une parfaite soumise à ses fantasmes de domination. Son ultra féminité et sa liberté sexuelle vont le mettre dans la peau du héros face à sa fragile princesse. Sexualité de domination et de soumission.";
export const Mars_Vierge_Lion="Gentleman dans l'âme, il saura accueillir en son lit cette ardente maîtresse qu'il admire et qu'il saura honorer à la hauteur de ses attentes. Troublé par sa beauté et son sex-appeal, il saura se plier à ses désirs. Sexualité où le dominant est dominé.";
export const Mars_Vierge_Vierge="Ils comprennent instinctivement leurs besoins érotiques. Sans avoir besoin d'apprendre l'autre, ils savent ce que l'autre désire. La volonté de satisfaire pleinement le plaisir de l'autre stimule les ébats. Plaisirs partagés et intuitifs.";
export const Mars_Vierge_Balance="Elle, délicate, appréciera la finesse et le savoir-vivre de ce gentleman qui ne la brusquera pas et sera attentif à son plaisir, désirant pleinement la satisfaire. Mais qui risque, somme toute, de frustrer monsieur par cette sexualité à sens unique.";
export const Mars_Vierge_Scorpion="Une complicité évidente. Il sera emporté par les pulsions impérieuses de cette amante sulfureuse. Elle saura exacerber ses instincts charnels et tous ses fantasmes inassouvis. Sadomasochisme soft.";
export const Mars_Vierge_Sagittaire="Une curiosité commune à découvrir l'accès au plaisir charnel. Elle amène la dose de fantaisie qui manque à la sexualité plus classique de cet homme pudique. Mais l'inconsistance et la légèreté de l'un déstabilise et refroidit les ardeurs de l'autre.";
export const Mars_Vierge_Capricorne="La méfiance naturelle de madame séduit et excite cet homme qui ne rêve que de la rendre esclave de son plaisir. Toujours dans la maîtrise, il désire qu'elle devienne addict à ses caresses. Sexualité cérébrale et fétichiste.";
export const Mars_Vierge_Verseau="Il se laissera tenter par de nouvelles expériences originales et libertines auxquelles elle désirera le convertir. Lui, adaptable et curieux, ne manquera pas d'assouvir tous les fantasmes de madame. Une sexualité sans tabous et sans engagement amoureux.";
export const Mars_Vierge_Poissons="Ils se laisseront tous deux envahir par des sensations inconnues, testant toutes les expériences érotico-sensorielles au risque de chuter dans la débauche. Tous deux adeptes des paradis artificiels, en useront pour accéder au nirvana.";

export const Mars_Balance_Belier="Avec eux ce sera « faites l'amour pas la guerre ». Malgré la sexualité agressive de madame, il réussira à adoucir la brutalité de cette fougueuse amante tout en sublimant la passion dévorante qui animera leurs ébats.";
export const Mars_Balance_Taureau="Elle recherche un homme séduisant, lui faisant une cour effrénée avant de se donner. En gentleman, il saura patienter ne désirant que lui montrer par ses caresses, ô combien il est conquis. Sexualité romantique, sur fond de douceur et volupté. ";
export const Mars_Balance_Gemeaux="Entre eux, l'acte sexuel prend toute l’apparence d’une mise en scène de théâtre, ambiance feutrée, bougies allumées, parfum sensuel vaporisé. Monsieur prendra un vif plaisir à effeuiller madame, découvrant ses tenues coquines qu’il affectionne tout particulièrement.";
export const Mars_Balance_Cancer="Elle recherche un homme sécurisant qui saura la guider et la protéger. Il cherche une douce compagne pour fonder un foyer, mais ne rêve qu’à la conquête d’autres créatures sexy qui peuplent ses fantasmes. Frontières entre fantasmes et réalités.";
export const Mars_Balance_Lion="Tous deux amoureux du beau et du clinquant, s'adonneront à une sexualité débridée et passionnée, dans une atmosphère glamoureuse et sexy. Les dessous affriolants feront partie du décorum pour le plus grand plaisir de cet adepte du voyeurisme.";
export const Mars_Balance_Vierge="Lui amant délicat, appréciera la finesse de cette vertueuse amante qui sera attentive à son plaisir, désirant pleinement le satisfaire, mais qui, par excès de pudeur, manquera d’élans affectifs et d’audace.";
export const Mars_Balance_Balance="Tous deux boulimiques de démonstrations affectives sauront se cajoler, se caresser et partager ensemble tous leurs fantasmes. Leur sexualité est esthétique avec un érotisme plus artistique que charnel. Osmose sensuelle et tantrique.";
export const Mars_Balance_Scorpion="Cette croqueuse d’homme dominera toujours les ébats amoureux.  La sexualité délicate de monsieur, ne conviendra pas aux besoins agressifs de la sexualité animale de cette femme qui réclame du hard.";
export const Mars_Balance_Sagittaire="Cet homme aux bonnes manières et aux désirs refoulés se laissera tenter par de nouvelles expériences insolites, guidé par l’enthousiasme communicatif de cette amazone fantasque. Fantasmes mondains, sexualité sportive et débridée.";
export const Mars_Balance_Capricorne="Une sexualité en souffrance où les acteurs principaux ont du mal à s’épanouir. Cet amant chaleureux et délicat se verra refroidi dans ses élans par cette maîtresse prudente et détachée. Rencontre du feu et de la glace.";
export const Mars_Balance_Verseau="Deux amants romanesques mus par un idéal amoureux où leurs affinités mentales seront le moteur de leurs fantasmes. Ils vivent leur relation érotique comme une exaltation artistique. Sexualité inspirée d’une muse pour son maestro.";
export const Mars_Balance_Poissons="Ces deux amants recherchent dans leur sexualité à atteindre un état extatique entremêlé de douceur et de souffrance. Ils vivront une sexualité libérée juste pour la magie de l’acte, mais ne se feront pas confiance. Érotisation de leurs angoisses.";

export const Mars_Scorpion_Belier="Rencontre de deux excessifs, qui n'hésiteront pas explorer les fins fonds de leur psyché et  les extrêmes méandres de la sexualité. Leur histoire pourrait se résumer par \"enfer et damnation\". Attention à la violence des actes.";
export const Mars_Scorpion_Taureau="L'attraction est inévitable, tels deux aimants ils ne pourront se décoller l'un de l'autre. Tous deux sensuels et sexuels, ils vivront une passion dévorante dans un désir de possession totale. Leurs crises de jalousies seront aussi excessives que leurs pulsions.";
export const Mars_Scorpion_Gemeaux="Ces deux provocateurs curieux de pousser toujours plus loin les limites de leur libido, assouviront ensemble tous leurs fantasmes les plus secrets. Mais madame plus cérébrale et moins trash, refusera tout asservissement à monsieur.";
export const Mars_Scorpion_Cancer="Attraction magique ! Leurs énergies s'entremêlent et leurs corps vibrent au rythme de sensations exaltantes. Ils vivent un érotisme torride dans le secret de leur alcôve. Relation où la femme est l’esclave de l'homme.";
export const Mars_Scorpion_Lion="Leur sexualité aurait pu être parfaite si l'orgueil et les perversités ne s'en mêlaient pas. Ces deux dominants auront du mal à trouver leur place et aucun des deux, ne se soumettra au désir de l'autre. Sexualité brutale et crue.";
export const Mars_Scorpion_Vierge="Une complicité évidente. Elle sera emportée par les pulsions impérieuses de cet amant sulfureux. Il saura exacerber ses instincts charnels et tous ses fantasmes inassouvis. Sadomasochisme soft.";
export const Mars_Scorpion_Balance="Cette délicate amante ne s’accorde pas du tout à la sexualité tourmentée et agressive de ce drogué du sexe. Sexuellement dominant et agressif, monsieur aime le sexe hard alors qu’elle ne rêve que de caresses suaves et romantiques.";
export const Mars_Scorpion_Scorpion="Une attraction violente sur fond de passion vorace sera la stimulation de leur histoire. Leur tourment existentiel sera le moteur d’une sexualité débridée en quête de sensations extrêmes.  Fascination sensuelle pour du sexe « no limit ».";
export const Mars_Scorpion_Sagittaire="Une passion dévorante anime ces deux amants insatiables. Lui, vicieux, désire emmener cette amazone, qui feint la résistance, vers l’exploration de nouvelles expériences interdites. Elle sera enchantée de découvrir de nouveaux plaisirs. ";
export const Mars_Scorpion_Capricorne="La motivation est grande pour cet homme qui aime la difficulté. Réveiller la libido et stimuler tous les sens de cette maîtresse frileuse relève d’un défi digne de l’ascension de l’Everest. Mais quelle satisfaction, lorsqu’elle s’abandonne. Sexualité éreintante.";
export const Mars_Scorpion_Verseau="Cet amant exigeant, dur et violent de désirs profitera de la sexualité libérée de cette rebelle. Leur imagination et leur fantaisie s’accorderont parfaitement, mais les délires extrêmes et trash de monsieur effrayeront cette amante qui n’a pourtant pas froid aux yeux.";
export const Mars_Scorpion_Poissons="Il va s’instaurer une sorte de danse hypnotique tel un cobra devant sa proie. Lui est un prédateur sexuel qui fascine l’objet de ses désirs pour mieux l’asservir. Elle, sensible aux sensations sexuelles, se soumettra sans réticences au dictât érotique de son amant. ";

export const Mars_Sagittaire_Belier="Plaisir quand tu nous tiens ! Pas de prises de tête juste le bonheur de goûter aux sensations intenses que procure une sexualité spontanée et ardente. Les coups de gueule de ces deux colériques ne seront qu'une excuse pour remettre le couvert.";
export const Mars_Sagittaire_Taureau="Une insatisfaction totale. Elle est extrêmement possessive et lui, adepte de la liberté, refuse toute aliénation et toute contrainte. Leurs désirs trop contradictoires créeront un invivable malaise. Affrontements violents sur fond de désaccords.";
export const Mars_Sagittaire_Gemeaux="Une attirance irrésistible pour ces deux curieux de la vie. Ils s’enthousiasmeront ensemble, emportés par l’ivresse des sens et la découverte de leurs plaisirs. Pas de temps à perdre, la vie est trop courte pour s’ennuyer. Sexualité joyeuse.";
export const Mars_Sagittaire_Cancer="Il saura user de ses atouts pour emporter cette femme soumise, dans des univers érotiques qui lui sont étrangers, emplis de fantasmes les plus insolites, un monde sensuel illusoire et éphémère où le sexe n'est qu'un exutoire.";
export const Mars_Sagittaire_Lion="Ils s'enflammeront pour cette aventure érotique qui les projettera dans un monde de fantasmes et de plaisirs sans limites, mais toujours dans un cadre doucereux, sans tortuosités. Leur caractère impétueux et explosif excitera de plus belle leur libido.";
export const Mars_Sagittaire_Vierge="Une curiosité commune à découvrir l'accès au plaisir charnel.  Il amène la dose de fantaisie qui manque à la sexualité plus classique de cette femme pudique. Mais l'inconsistance et la légèreté de l'un déstabilise et refroidit les ardeurs de l'autre.";
export const Mars_Sagittaire_Balance="Cet aventurier, adepte d’expériences insolites entraînera cette douce et délicate déesse sur un terrain inconnu qu’elle ne refusera pas d’explorer. Séduite par son enthousiasme communicatif, elle s’adonnera aux joies d’une sexualité décomplexée.";
export const Mars_Sagittaire_Scorpion="Une passion dévorante anime ces deux amants insatiables. Lui, ardent, allume moults brasiers dans le cœur et le corps de cette tigresse aux besoins sexuels voraces, qui l’entraînera vers de nouvelles expériences érotiques inédites.";
export const Mars_Sagittaire_Sagittaire="Cet héroïque chevalier emportera sa belle princesse vers de coquines aventures, dans lesquelles, il l’honorera vaillamment chaque jour, dans chaque pièce du château. Passion sexuelle ressemblant à un rodéo du sexe.";
export const Mars_Sagittaire_Capricorne="Ce valeureux amant, ardent et téméraire aura du mal à dégeler cette maîtresse pétrifiante de froideur. Il y mettra pourtant toute sa volonté et son imagination, mais s’épuisera et s’ennuiera devant si peu d’entrain à la tâche.";
export const Mars_Sagittaire_Verseau="Pas de demi-mesures dans cette relation passionnelle où la fantaisie et la liberté sexuelle seront les piments de leurs ébats. Sans tabous, ni jugements, le sexe est un plaisir dont ils usent et abusent allègrement. Carpe diem.";
export const Mars_Sagittaire_Poissons="Rencontre de deux épicuriens qui s’adonneront avec joie aux plaisirs de la chair sans retenues, juste dans le but de se faire du bien. Ils ne se promettent rien, mais jouissent de l’instant présent, faisant vibrer tout leur corps. Sexualité sans frontières.";

export const Mars_Capricorne_Belier="Cette amante impulsive se heurte à un homme dans la maîtrise de tout, dont l'énergie est canalisée, contrôlée et diluée. La sexualité est bridée et l'impatience de cette nerveuse ne compte pas s'éterniser sur tant de retenue et de froideur. C'est la rencontre du feu et de la glace... brrr";
export const Mars_Capricorne_Taureau="Alors que monsieur manque sérieusement d’élans et de passion, cette sensuelle maîtresse ne se découragera pas et réchauffera cet amant réfractaire de ses suaves caresses. La constance et le sérieux de cet homme accentuera sa confiance, elle si suspicieuse, et la motivera d’autant plus.";
export const Mars_Capricorne_Gemeaux="Les élans fantaisistes de cette amoureuse passionnée seront stoppés rapidement par cet amant au conservatisme routinier. Trop de normalité et si peu de folie, refroidira l’imaginaire de notre cérébrale et ne la motivera pas à la tâche.";
export const Mars_Capricorne_Cancer="La douceur et l'extrême sensualité de madame mettra monsieur en confiance et lui permettre de s'adonner aux joies du sexe, sans retenues. Il saura la protéger et elle le lui rendra bien, soumise à son rythme. Mais elle s'essoufflera, frustrée par si peu d’affection.";
export const Mars_Capricorne_Lion="Son manque d'entrain à la tâche, vexera la noblesse de cette femme passionnée qui se sentira incomprise et méprisée.  Ses besoins affectifs et démonstratifs seront mal perçus par la froideur et l'indifférence de cet amant sexuellement plus mécanique.";
export const Mars_Capricorne_Vierge="Cet amant austère et méfiant sera touché par la pudeur de cette amante attentive à son plaisir, désirant le rendre addict à ses caresses. Lui, toujours dans la maîtrise, guidera madame et l’amènera doucement vers une sexualité cérébrale et fétichiste.";
export const Mars_Capricorne_Balance="Une sexualité en souffrance où les acteurs principaux ont du mal à s’épanouir. Cet amant austère et détaché refroidira les élans de cette maîtresse chaleureuse et délicate. Trop d’efforts pour trop peu de plaisirs.";
export const Mars_Capricorne_Scorpion="La motivation est grande pour cette femme qui aime la difficulté. Réveiller la libido et stimuler tous les sens de cet amant frileux relève d’un défi digne de l’ascension de l’Everest. Mais quelle satisfaction, lorsqu’il s’adonnera enfin à une sexualité audacieuse !";
export const Mars_Capricorne_Sagittaire="Entreprenante, madame n’a pas froid aux yeux et usera de toute son expérience érotique et de son imagination pour dégeler cet homme pétrifiant de froideur. Devant si peu d’entrain, elle sombrera dans un ennui profond qui l’emportera vers d’autres amants.";
export const Mars_Capricorne_Capricorne="Leur mode de conquêtes est tellement identique qu’ils devinent l’attente de l’autre. Patients, ils sauront approfondir leur vie intime, dans une recherche de la maîtrise de leur plaisir pour atteindre le Nirvana de la jouissance. Couple d’experts.";
export const Mars_Capricorne_Verseau="Une attirance indéniable où la fantaisie de madame sera le moteur déclencheur des fantasmes de monsieur. Mais pour développer une intimité aussi excitante soit elle, il faudrait y consacrer un minimum de temps. Sexualité à distance.";
export const Mars_Capricorne_Poissons="La capacité à créer une véritable fusion charnelle, va lui permettre insidieusement de rendre addict cet amant coriace. Charmé par cette sirène envoûteuse, il la ligotera patiemment dans un froid désir de possession. ";

export const Mars_Verseau_Belier="Elle a un désir sexuel offensif et charnel, qui ne pourra que s'entendre avec cet amant si curieux de tout et aussi libre de toute expérience sexuelle, sans tabous aucuns. Mais la nervosité de cette maîtresse impétueuse pourrait tout gâcher.";
export const Mars_Verseau_Taureau="Il recherche une femme libre qui sort des sentiers battus, alors qu’elle ne se complaît que dans le conformisme. Leur sexualité est certes passionnée, mais bien trop classique et possessive pour cet original qui n'appartient à personne.";
export const Mars_Verseau_Gemeaux="Ensemble ils exploreront toute la littérature érotique et testerons toutes les postures du Kamasutra. La nouveauté et l’originalité motiveront leurs ébats, rien de tel pour pimenter une relation. Sans tabous juste pour le plaisir, un couple libertin.";
export const Mars_Verseau_Cancer="Elle ne le fait pas fantasmer car il fuit la banalité du foyer.  Lui trop insaisissable et libertin, la déconcerte. Ils se passeront à côté sans vraiment s'attarder, leurs univers charnels étant trop différents pour que la magie opère.";
export const Mars_Verseau_Lion="Une bonne connexion intellectuelle et une forte estime entre eux, créent une attraction palpitante et passionnée. L'originalité des fantasmes de l'un stimule la libido de l'autre.  Un désir frissonnant et dévorant.";
export const Mars_Verseau_Vierge="Il saura la tenter par de nouvelles expériences originales et libertines, et elle cédera à la tentation. Adaptable et curieuse, elle ne manquera pas d'assouvir tous ses fantasmes. Une sexualité sans tabous et sans engagement amoureux.";
export const Mars_Verseau_Balance="Deux amants romanesques mus par un idéal amoureux où leurs affinités mentales seront le moteur de leurs fantasmes. Ils vivent une relation érotique comme une exaltation artistique. Sexualité inspirée, d’une muse pour son maestro !";
export const Mars_Verseau_Scorpion="La fantaisie fait partie intégrante de la sexualité de cet homme, sans tabous et avec un grain non négligeable de curiosité érotique. Mais, malgré la complicité de leurs ébats, les fantasme de madame, la violence et le cuir, ne feront décidément pas partie de ses délires.";
export const Mars_Verseau_Sagittaire="Pas de demi-mesures dans cette relation passionnelle où la fantaisie et la liberté sexuelle seront les piments de leurs ébats. Sans tabous, ni jugements, le sexe est un plaisir dont ils usent et abusent allègrement. Carpe diem.";
export const Mars_Verseau_Capricorne="Une attirance indéniable où la fantaisie de monsieur sera le moteur déclencheur des fantasmes de madame. Mais pour développer une intimité aussi excitante soit-elle, il faudrait y consacrer un minimum de temps. Sexualité à distance.";
export const Mars_Verseau_Verseau="Une attirance cosmique instantanée. Ces deux originaux, se connectent au départ, cérébralement pour mieux accéder à leurs fantasmes, avant de se lancer dans un corps à corps enflammé. Sexe virtuel et fantaisiste.";
export const Mars_Verseau_Poissons="Ils onduleront entre attirance et répulsion sexuelle. Lui en chef d’orchestre, entraînera cette adepte soumise à réaliser ses fantasmes libertins. Mais notre sirène boulimique d’amour s’étiolera face à sa froideur affective.";

export const Mars_Poissons_Belier="Elle aura une sexualité bien trop agressive pour ce ménestrel de l’amour qui recherche dans la sexualité à transcender son amour très sensuel mais avec une bonne dose de tendresse et de sentiments. Sex-attitude ne fait pas bon ménage avec romantisme-attitude.";
export const Mars_Poissons_Taureau="La délicatesse de ses caresses attendrira ce romantique invétéré, l'amour est sublimé par une douce sensualité. Lui, sera soumis à sa volonté autoritaire et permettra à cette relation de trouver une vitesse de croisière épanouissante. ";
export const Mars_Poissons_Gemeaux="Sensuel, monsieur est un amant parfait, attentif et soumis à tous les désirs et fantasmes de cette imaginative libertine. Mais son manque de tendresse frustrera les attentes romantiques de cet amoureux inconditionnel.";
export const Mars_Poissons_Cancer="Ces deux-là sont connectés sur la même longueur d'ondes sensorielles érotiques. Leurs pensées sont emplies de fantasmes, plus osés les uns que les autres et l'excitation est à son comble. Ils vivent une passion dévorante et torride.";
export const Mars_Poissons_Lion="Lui, cherche à prendre dans ses filets cette amoureuse vulnérable, il se donnera corps et âme l’honorant telle une déesse de l’amour, mais cette conquérante de l’amour dédaigne cet amant soumis. Rapport de maître à soumis.";
export const Mars_Poissons_Vierge="Ils se laisseront tous deux envahir par des sensations inconnues, testant toutes les expériences sex-sensorielles au risque de sombrer dans la débauche. Tous deux adeptes des paradis artificiels, ils en useront à souhait pour accéder au nirvana.";
export const Mars_Poissons_Balance="Ces deux amants recherchent dans leur sexualité à atteindre un état extatique entremêlé de douceur et de souffrance. Ils vivront une sexualité libérée juste pour la magie de l’acte, mais ne se feront pas confiance. Erotisation de leurs angoisses. ";
export const Mars_Poissons_Scorpion="Il va s’instaurer une sorte de danse hypnotique telle une mante religieuse devant sa proie. Elle fascine l’objet de ses désirs pour mieux l’asservir. Lui très sensible aux sensations sexuelles, se soumettra sans réticences au dictat érotique de son amante.";
export const Mars_Poissons_Sagittaire="Rencontre de deux épicuriens qui s’adonneront avec joie aux plaisirs de la chair sans retenues, juste dans le but de se faire du bien. Ils ne se promettent rien mais jouissent de l’instant présent, faisant vibrer tout leur être. Sexualité sans frontières.";
export const Mars_Poissons_Capricorne="Sa capacité à créer une véritable fusion charnelle, va lui permettre insidieusement d’addicter cette amante frigide. Charmée par ce maître de l’envoûtement, elle le ligotera patiemment dans un froid désir de possession. ";
export const Mars_Poissons_Verseau="Ils onduleront entre attirance et répulsion sexuelle. Elle, mènera la danse entrainant cet adepte de l’érotisme à réaliser ses fantasmes les plus osés. Mais la froideur affective de cette maîtresse refroidira les ardeurs de ce romantique invétéré.";
export const Mars_Poissons_Poissons=" Attirance irrésistible qui ressemble à une fusion des êtres et des corps projetés dans une dimension spirituelle de la sexualité. Cette relation est une communion parfaite entre sentiments et psychisme. Sexualité intense mais douce.";

 */
//Mars

export const Mars={
   Mars_Belier_Belier:"C'est la rencontre explosive d'un homme viril et d'une femme indépendante. Il saura la soumettre car il est l'incarnation du mâle dominant qu'elle recherche. Leur sexualité est torride et sportive. ",
   Mars_Belier_Taureau:"Elle recherche un homme de pouvoir, un meneur. Lui, par son autorité et sa force, n'entend pas supporter les crises de jalousies de madame. Sa sexualité est puissante, mais manque de sensualité et de préliminaires pour elle.",
   Mars_Belier_Gemeaux:"Elle recherche un homme brillant intellectuellement, c'est comme ça qu'il faut la séduire. Mais lui est plus basique, plus primaire. Seule une sexualité basée sur les jeux de rôles et l'originalité pourra les satisfaire. ",
   Mars_Belier_Cancer:"Lui aime une sexualité un peu agressive qui ne convient pas au désir de sexualité douce et délicate que cette princesse réclame. Elle recherche un homme sentimental et affectueux, un prince charmant. Ce guerrier au sang chaud ne correspond pas du tout à cet archétype. ",
   Mars_Belier_Lion:"La puissance et la passion sont les maîtres mots de leur rencontre. Il incarne la masculinité et la puissance et elle recherche un dominateur.  Leur sexualité sera puissante et débridée, parfois même brutale. ",
   Mars_Belier_Vierge:"Il aime la sexualité agressive et exhibitionniste, elle est plutôt réservée et pudique. Leur sexualité ne pourra pas s'épanouir, car cette ingénue se sentira comme violée par ce hussard. Une sexualité qui risque de s’installer rapidement dans une routine frustrante.",
   Mars_Belier_Balance:"Avec eux ce sera « faites l'amour pas la guerre », malgré l'agressivité de ce guerrier de l’amour, elle réussira à adoucir la brutalité de ce fougueux amant tout en sublimant la passion dévorante qui animera leurs ébats.",
   Mars_Belier_Scorpion:"Rencontre de deux excessifs, qui n'hésiteront pas explorer les fins fonds de leur psyché et  les extrêmes méandres de la sexualité. Leur histoire pourrait se résumer par \"enfer et damnation\". Attention à la violence des actes. ",
   Mars_Belier_Sagittaire:"Plaisir quand tu nous tiens ! Pas de prises de tête juste le bonheur de goûter aux sensations intenses que procure une sexualité spontanée et ardente. Les coups de gueule de ces deux colériques ne seront qu'une excuse pour remettre le couvert.",
   Mars_Belier_Capricorne:"Cet amant impulsif se heurte à une femme dans la maîtrise de tout, dont l’énergie est canalisée, contrôlée et diluée. La sexualité est bridée et l'impatience de ce nerveux ne compte pas s'éterniser sur tant de retenues et de froideurs. C'est la rencontre du feu et de la glace... brrr",
   Mars_Belier_Verseau:"Le désir sexuel offensif et charnel de monsieur, ne pourra que s'entendre avec cette demoiselle si curieuse de tout et ouverte à toute expérience sexuelle, sans tabous aucuns. Mais la nervosité de cet amant impétueux pourrait tout gâcher.",
   Mars_Belier_Poissons:"La sexualité bien trop agressive de monsieur bloquera cette sirène qui recherche dans la sexualité, à transcender son amour très sensuel avec une bonne dose de tendresse et de sentiments. Sex-attitude ne fait pas bon ménage avec romantisme-attitude.",
  
   Mars_Tauraeu_Belier:"Son charme sensuel ne pourra que la faire craquer. Gourmande, elle sera une partenaire à la hauteur de ses attendes. Relation strictement érotique, cette liaison de courte durée leur laissera un merveilleux souvenir malgré des coups de gueule dévastateurs. ",
   Mars_Tauraeu_Taureau:"Le charme opère immédiatement, ces deux gourmands se délecteront l'un de l'autre, dans des ébats langoureux et de longs préliminaires aiguisant leur appétit. Seules leurs puissantes colères ébranleront cette douce harmonie.",
   Mars_Tauraeu_Gemeaux:"Lui est un boulimique du sexe ce qui exaspère au plus haut point cette cérébrale portée vers les nourritures intellectuelles. L'un se transcende dans le fantasme alors que l'autre a besoin de concrétiser ses envies. Elle adore le faire languir et lui se voit obligé de la harceler. Incompatibilité de désirs.",
   Mars_Tauraeu_Cancer:"Cette boulimique de câlins s'attendrira devant la montagne de douceur que lui prodiguera ce gros nounours, exprimant ses sentiments dans des corps à corps enflammés et gourmands. L'amour s'épanouit dans la sexualité.",
   Mars_Tauraeu_Lion:"Ils pourront être emportés par une passion animale, sur un simple regard coquin, se laissant aller à un moment sensuel. Un instant d'égarement qui leur laissera un souvenir délicieux. Leur nature autoritaire ne leur permettra pas d'espérer plus.",
   Mars_Tauraeu_Vierge:"Sa puissante virilité et sa sensualité enveloppante, décoinceront cette vierge effarouchée à la sexualité timide et pudique. Patient et déployant des trésors d'ingéniosité, il réussira à la désinhiber et à révéler une sexualité débridée.",
   Mars_Tauraeu_Balance:"Elle recherche un homme séduisant, lui faisant une cour effrénée avant de se donner. En gentleman, il saura patienter ne désirant que lui montrer par ses caresses, ô combien il est conquis. Sexualité romantique, sur fond de douceur et volupté. ",
   Mars_Tauraeu_Scorpion:"L'attraction est inévitable. Tels deux aimants, ils ne pourront se décoller l'un de l'autre. Tous deux sensuels et sexuels, ils vivront une passion dévorante dans un désir de possession totale. Leurs crises de jalousies seront aussi excessives que leurs pulsions.",
   Mars_Tauraeu_Sagittaire:"Une insatisfaction totale. Lui est extrêmement possessif et elle adepte de la liberté, refusant toute aliénation et toute contrainte. Leurs désirs trop contradictoires créeront un invivable malaise. Affrontements violents sur fond de désaccords.",
   Mars_Tauraeu_Capricorne:"Lui, plutôt viril et protecteur, rassurera madame. Elle se laissera volontiers aller dans les bras de ce sensuel amant. La constance et le sérieux de monsieur ne feront qu'accentuer la confiance de cette frileuse qui, peut à peut, se réchauffera.",
   Mars_Tauraeu_Verseau:"Elle recherche un homme libre qui sort des sentiers battus, alors que lui se complaît dans le conformisme. Leur sexualité est certes passionnée, mais bien trop classique et possessive pour cette originale qui n'appartient à personne.",
   Mars_Tauraeu_Poissons:"La délicatesse de ses caresses attendrira cette romantique invétérée, l'amour est sublimé par une douce sensualité. Elle sera soumise à sa volonté autoritaire et permettra à cette relation de trouver une vitesse de croisière épanouissante.",
  
   Mars_Gemeaux_Belier:"On ne peut pas dire qu'il soit l'homme musclé qu'elle recherche, mais son intelligence et ses jeux d'esprit la séduiront tout autant. Les fantasmes de cet intello et la passion de cette sauvageonne sauveront leur sexualité pourtant très discordante.",
   Mars_Gemeaux_Taureau:"Elle saura lui prodiguer de divins massages qui exalteront son imagination et réveilleront ses fantasmes. Lui aime jouer et ne s'enflammera que le temps d'une soirée pour cette voluptueuse amante.",
   Mars_Gemeaux_Gemeaux:"Une rencontre pleine de surprises où la fantaisie et les fantasmes émerveillent ces deux gais lurons. Ils s’amusent, se titillent et testent ensemble de nombreuses façons de faire l'amour. Sexualité joyeuse et imaginative. ",
   Mars_Gemeaux_Cancer:"Lui ne mélange pas sexe et sentiments, il a soif de sensations et de délires cérébraux. Le romantisme est exclu de ses jeux d’adultes et il se lassera vite des mièvreries amoureuses de cette amante capricieuse.",
   Mars_Gemeaux_Lion:"Une relation où les occasions de plaisirs ne manqueront pas. L’habileté de l’un avivera la passion de l’autre. Une mise en scène de leurs ébats leur apportera une touche théâtrale qui aiguisera leur excitation.",
   Mars_Gemeaux_Vierge:"Il lui faudra une bonne dose de patience, qui n’est pas son fort, et d’imagination pour faire décoller cette coincée. Elle, très cérébrale, ne demande pourtant qu’à s’exalter. Une connivence possible dans le fantasme.",
   Mars_Gemeaux_Balance:"Cette coquette sexy saura exciter tous les sens de ce grand gourmet. Il prendra un vif plaisir à l’effeuiller, découvrant des tenues coquines qu’il affectionne tout particulièrement. Entre eux, c’est « jeux interdits »",
   Mars_Gemeaux_Scorpion:"Ces deux provocateurs curieux de pousser toujours plus loin les limites de leur libido, assouviront ensemble tous leurs fantasmes les plus secrets. Mais monsieur plus cérébral et moins trash refusera tout asservissement à madame.",
   Mars_Gemeaux_Sagittaire:"Une attirance irrésistible pour ces deux curieux de la vie. Ils s’enthousiasmeront ensemble, emportés par l’ivresse des sens et la découverte de leurs plaisirs. Pas de temps à perdre, la vie est trop courte pour s’ennuyer.... Sexualité joyeuse.",
   Mars_Gemeaux_Capricorne:"Les élans fantaisistes de cet amant passionné seront stoppés rapidement par cette amante au conservatisme routinier. Trop de normalité et si peu de folie, refroidira l’imaginaire de notre cérébral et ne le motivera pas à la tâche.",
   Mars_Gemeaux_Verseau:"Ensemble ils exploreront toute la littérature érotique et testerons toutes les postures du Kamasutra. La nouveauté et l’originale motiveront leurs ébats, rien de tel pour pimenter une relation. Sans tabous, juste pour le plaisir. Un couple libertin.",
   Mars_Gemeaux_Poissons:"Sensuelle, madame sera une amante parfaite, docile et soumise à tous les désirs et fantasmes de cet imaginatif libertin. Mais le manque de tendresse de monsieur, frustrera les attentes romantiques de cette amoureuse inconditionnelle.",
  
  
   Mars_Cancer_Belier:"On ne peut dire pas qu’il soit l’incarnation de la virilité, elle qui recherche les sensations fortes, elle ne devra se contenter que du réconfort de ce grand enfant à la recherche d'une mère. Sexualité coquine mais manquant d’adrénaline.",
   Mars_Cancer_Taureau:"Elle incarne la déesse mère, femme à la peau douce, sensuelle à souhait, elle est son fantasme et l’excitation qu’elle lui procure sera à la haute de la qualité de leurs ébats. Elle, se laissera emporter par l’ivresse des sens. Sexualité épanouie et gourmande.",
   Mars_Cancer_Gemeaux:"Il saura la poursuivre de ses assiduités et elle s’amusera à lui résister uniquement pour l’excitation et le jeu de la séduction. Mais elle succombera tôt ou tard s’abandonnant à ses caresses… le temps d’un instant…Plaisirs éphémères.",
   Mars_Cancer_Cancer:"Une attraction sensorielle quasi mystique, des élans de tendresse et des sensations extrêmes conditionnent une sexualité empreinte d’amour et de douceur. Leur chambre est un lieu protégé, c’est leur nid d’amour.",
   Mars_Cancer_Lion:"Il la traitera comme une reine, SA REINE, attentionné et caressant. Elle se laissera porter dans son tourbillon d'émotions et découvrira avec lui, les joies d'une sexualité délicate. Il lui manquera cependant un peu de virilité dans l'acte car madame aime les vrais durs.",
   Mars_Cancer_Vierge:"Craintive, elle recherche un homme délicat qui saura la mettre en confiance. Lui est un amoureux de la femme et sait la sublimer par des caresses attentives et coquines qu'il lui prodiguera sur tout le corps. Leur sexualité est un révélateur de sentiments.",
   Mars_Cancer_Balance:"Elle recherche un homme entreprenant qui saura la guider et la posséder. Malheureusement, c'est un homme soumis à son amante, attentif à ses désirs et à son plaisir certes, mais manquant de poigne dans l'acte. Il ne la fait pas rêver.",
   Mars_Cancer_Scorpion:"Attraction magique ! Leurs énergies s'entremêlent et leurs corps vibrent au rythme de sensations exaltantes. Ils vivent un érotisme torride dans le secret de leur alcôve. Relation où l’homme est l’esclave de la femme.",
   Mars_Cancer_Sagittaire:"Elle saura user de ses atouts pour emmener cet homme soumis dans des univers qui lui sont étrangers, emplis de fantasmes les plus insolites, un monde érotique illusoire et éphémère où le sexe n'est qu'un exutoire.",
   Mars_Cancer_Capricorne:"Sa douceur permettra cette femme réservée de s'ouvrir en toute confiance à l'amour et aux joies du sexe, sans retenues. Il saura l'épanouir et elle le lui rendra bien, le maternant comme un enfant, mais elle s'essoufflera avec le temps de ce rôle contre nature.",
   Mars_Cancer_Verseau:"Elle, c'est l'originalité qui l'excite, et il ne l'a fait pas fantasmer, trop banal. Trop insaisissable pour lui, ils se passeront à côté sans vraiment s'attarder, leurs univers charnels étant trop différents pour que la magie opère.",
   Mars_Cancer_Poissons:"Ces deux-là sont connectés sur la même longueur d'ondes érotico-sensorielles. Leur libido déborde de fantasmes, plus osés les uns que les autres et l'excitation est à son comble. Ils vivent une passion dévorante et torride.",
  
   Mars_Lion_Belier:"La puissance et la passion sont les maîtres mots de leur rencontre. Il incarne la masculinité et la puissance et elle recherche un dominateur.  Leur sexualité sera puissante et débridée, parfois même brutale.",
   Mars_Lion_Taureau:"Ils pourront être emportés par une passion animale, sur un simple regard coquin, se laissant aller à un moment sensuel. Un instant d'égarement qui leur laissera un souvenir délicieux. Leur nature autoritaire ne leur permettra pas d'espérer plus. ",
   Mars_Lion_Gemeaux:"Une relation où les occasions de plaisirs ne manqueront pas. L’habileté de l’un avivera la passion de l’autre. Une mise en scène de leurs ébats leur apportera une touche théâtrale qui aiguisera leur excitation.",
   Mars_Lion_Cancer:"Féminine à souhait elle saura se lover dans ses bras et lui laisser l'initiative, il est le maître de leurs ébats et compte bien la mener au 7ième ciel. Si elle sait le flatter et calmer son agressivité, leur histoire ira loin sinon elle se fera remercier.",
   Mars_Lion_Lion:"S'il y a bien un sujet sur lequel ils s'accordent, c'est bien le sexe. La sexualité est noble, élégante et puissante.  Ensemble ils rugissent de plaisirs et s'enflamment tour à tour se prodiguant des caresses brûlantes. De l’action avec panache.",
   Mars_Lion_Vierge:"Elle, à la sexualité pudique, sera totalement emportée par les élans de cet amant viril qui ne conçoit pas l'amour charnel sans la noblesse de l'acte. Rassurée par sa délicatesse, elle se laissera aller à ses fantasmes et se révélera une maîtresse imaginative.",
   Mars_Lion_Balance:"Tous deux amoureux du beau et du clinquant, s'adonneront à une sexualité débridée et passionnée, dans une atmosphère glamoureuse et sexy. Les dessous affriolants feront partie du décorum pour le plus grand plaisir de cet adepte du voyeurisme.",
   Mars_Lion_Scorpion:"Leur sexualité aurait pu être parfaite si l'orgueil et les perversités ne s'en mêlaient pas. Ces deux dominants auront du mal à trouver leur place et aucun des deux, ne se soumettra au désir de l'autre. Sexualité brutale et crue.",
   Mars_Lion_Sagittaire:"Ils s'enflammeront pour cette aventure érotique qui les projettera dans un monde de fantasmes et de plaisirs sans limites, mais toujours dans un cadre doucereux, sans tortuosités. Leur caractère impétueux et explosif excitera de plus belle leur libido.",
   Mars_Lion_Capricorne:"Le manque d'entrain de madame à la tâche, vexera la noblesse de cet homme passionné qui se sentira incompris et méprisé.  Ses besoins affectifs et démonstratifs seront mal perçus par la froideur et l'indifférence de cette amante sexuellement plus mécanique.",
   Mars_Lion_Verseau:"Une bonne connexion intellectuelle et une forte estime entre eux crée une attraction palpitante et passionnée. L'originalité des fantasmes de l'un, stimule la libido de l'autre.  Sexualité frissonnante et dévorante.",
   Mars_Lion_Poissons:"Elle, cherche à prendre dans ses filets un amoureux transi et vulnérable en se donnant corps et âme. Lui est un conquérant, qui saura honorer comme il se doit cette déesse de l'amour, mais se lassera vite de son inaccessibilité et de sa fragilité.",
  
   Mars_Vierge_Belier:"La cérébralité de monsieur s'accorde peu avec la primarité de madame, plus instinctive dans ses désirs. L'impatience de l'un agacera et refroidira la libido de l'autre.",
   Mars_Vierge_Taureau:"Cette sensuelle et voluptueuse maîtresse excitera tous les sens de cet amant prudent. Mais généreux, il comblera sa déesse d'attentions et de caresses les plus osées. Une sexualité épanouissante et coquine.  ",
   Mars_Vierge_Gemeaux:"Ces deux mercuriens trouveront un terrain d'entente dans des jeux érotiques qui aiguiseront leur excitation cérébrale. Échanges de sextos ou discussions chaudes au téléphone feront monter la température entre eux. Jeux de mots érotiques.",
   Mars_Vierge_Cancer:"Il trouve dans cette amante, une parfaite soumise à ses fantasmes de domination. Son ultra féminité et sa liberté sexuelle vont le mettre dans la peau du héros face à sa fragile princesse. Sexualité de domination et de soumission.",
   Mars_Vierge_Lion:"Gentleman dans l'âme, il saura accueillir en son lit cette ardente maîtresse qu'il admire et qu'il saura honorer à la hauteur de ses attentes. Troublé par sa beauté et son sex-appeal, il saura se plier à ses désirs. Sexualité où le dominant est dominé.",
   Mars_Vierge_Vierge:"Ils comprennent instinctivement leurs besoins érotiques. Sans avoir besoin d'apprendre l'autre, ils savent ce que l'autre désire. La volonté de satisfaire pleinement le plaisir de l'autre stimule les ébats. Plaisirs partagés et intuitifs.",
   Mars_Vierge_Balance:"Elle, délicate, appréciera la finesse et le savoir-vivre de ce gentleman qui ne la brusquera pas et sera attentif à son plaisir, désirant pleinement la satisfaire. Mais qui risque, somme toute, de frustrer monsieur par cette sexualité à sens unique.",
   Mars_Vierge_Scorpion:"Une complicité évidente. Il sera emporté par les pulsions impérieuses de cette amante sulfureuse. Elle saura exacerber ses instincts charnels et tous ses fantasmes inassouvis. Sadomasochisme soft.",
   Mars_Vierge_Sagittaire:"Une curiosité commune à découvrir l'accès au plaisir charnel. Elle amène la dose de fantaisie qui manque à la sexualité plus classique de cet homme pudique. Mais l'inconsistance et la légèreté de l'un déstabilise et refroidit les ardeurs de l'autre.",
   Mars_Vierge_Capricorne:"La méfiance naturelle de madame séduit et excite cet homme qui ne rêve que de la rendre esclave de son plaisir. Toujours dans la maîtrise, il désire qu'elle devienne addict à ses caresses. Sexualité cérébrale et fétichiste.",
   Mars_Vierge_Verseau:"Il se laissera tenter par de nouvelles expériences originales et libertines auxquelles elle désirera le convertir. Lui, adaptable et curieux, ne manquera pas d'assouvir tous les fantasmes de madame. Une sexualité sans tabous et sans engagement amoureux.",
   Mars_Vierge_Poissons:"Ils se laisseront tous deux envahir par des sensations inconnues, testant toutes les expériences érotico-sensorielles au risque de chuter dans la débauche. Tous deux adeptes des paradis artificiels, en useront pour accéder au nirvana.",
  
   Mars_Balance_Belier:"Avec eux ce sera « faites l'amour pas la guerre ». Malgré la sexualité agressive de madame, il réussira à adoucir la brutalité de cette fougueuse amante tout en sublimant la passion dévorante qui animera leurs ébats.",
   Mars_Balance_Taureau:"Elle recherche un homme séduisant, lui faisant une cour effrénée avant de se donner. En gentleman, il saura patienter ne désirant que lui montrer par ses caresses, ô combien il est conquis. Sexualité romantique, sur fond de douceur et volupté. ",
   Mars_Balance_Gemeaux:"Entre eux, l'acte sexuel prend toute l’apparence d’une mise en scène de théâtre, ambiance feutrée, bougies allumées, parfum sensuel vaporisé. Monsieur prendra un vif plaisir à effeuiller madame, découvrant ses tenues coquines qu’il affectionne tout particulièrement.",
   Mars_Balance_Cancer:"Elle recherche un homme sécurisant qui saura la guider et la protéger. Il cherche une douce compagne pour fonder un foyer, mais ne rêve qu’à la conquête d’autres créatures sexy qui peuplent ses fantasmes. Frontières entre fantasmes et réalités.",
   Mars_Balance_Lion:"Tous deux amoureux du beau et du clinquant, s'adonneront à une sexualité débridée et passionnée, dans une atmosphère glamoureuse et sexy. Les dessous affriolants feront partie du décorum pour le plus grand plaisir de cet adepte du voyeurisme.",
   Mars_Balance_Vierge:"Lui amant délicat, appréciera la finesse de cette vertueuse amante qui sera attentive à son plaisir, désirant pleinement le satisfaire, mais qui, par excès de pudeur, manquera d’élans affectifs et d’audace.",
   Mars_Balance_Balance:"Tous deux boulimiques de démonstrations affectives sauront se cajoler, se caresser et partager ensemble tous leurs fantasmes. Leur sexualité est esthétique avec un érotisme plus artistique que charnel. Osmose sensuelle et tantrique.",
   Mars_Balance_Scorpion:"Cette croqueuse d’homme dominera toujours les ébats amoureux.  La sexualité délicate de monsieur, ne conviendra pas aux besoins agressifs de la sexualité animale de cette femme qui réclame du hard.",
   Mars_Balance_Sagittaire:"Cet homme aux bonnes manières et aux désirs refoulés se laissera tenter par de nouvelles expériences insolites, guidé par l’enthousiasme communicatif de cette amazone fantasque. Fantasmes mondains, sexualité sportive et débridée.",
   Mars_Balance_Capricorne:"Une sexualité en souffrance où les acteurs principaux ont du mal à s’épanouir. Cet amant chaleureux et délicat se verra refroidi dans ses élans par cette maîtresse prudente et détachée. Rencontre du feu et de la glace.",
   Mars_Balance_Verseau:"Deux amants romanesques mus par un idéal amoureux où leurs affinités mentales seront le moteur de leurs fantasmes. Ils vivent leur relation érotique comme une exaltation artistique. Sexualité inspirée d’une muse pour son maestro.",
   Mars_Balance_Poissons:"Ces deux amants recherchent dans leur sexualité à atteindre un état extatique entremêlé de douceur et de souffrance. Ils vivront une sexualité libérée juste pour la magie de l’acte, mais ne se feront pas confiance. Érotisation de leurs angoisses.",
  
   Mars_Scorpion_Belier:"Rencontre de deux excessifs, qui n'hésiteront pas explorer les fins fonds de leur psyché et  les extrêmes méandres de la sexualité. Leur histoire pourrait se résumer par \"enfer et damnation\". Attention à la violence des actes.",
   Mars_Scorpion_Taureau:"L'attraction est inévitable, tels deux aimants ils ne pourront se décoller l'un de l'autre. Tous deux sensuels et sexuels, ils vivront une passion dévorante dans un désir de possession totale. Leurs crises de jalousies seront aussi excessives que leurs pulsions.",
   Mars_Scorpion_Gemeaux:"Ces deux provocateurs curieux de pousser toujours plus loin les limites de leur libido, assouviront ensemble tous leurs fantasmes les plus secrets. Mais madame plus cérébrale et moins trash, refusera tout asservissement à monsieur.",
   Mars_Scorpion_Cancer:"Attraction magique ! Leurs énergies s'entremêlent et leurs corps vibrent au rythme de sensations exaltantes. Ils vivent un érotisme torride dans le secret de leur alcôve. Relation où la femme est l’esclave de l'homme.",
   Mars_Scorpion_Lion:"Leur sexualité aurait pu être parfaite si l'orgueil et les perversités ne s'en mêlaient pas. Ces deux dominants auront du mal à trouver leur place et aucun des deux, ne se soumettra au désir de l'autre. Sexualité brutale et crue.",
   Mars_Scorpion_Vierge:"Une complicité évidente. Elle sera emportée par les pulsions impérieuses de cet amant sulfureux. Il saura exacerber ses instincts charnels et tous ses fantasmes inassouvis. Sadomasochisme soft.",
   Mars_Scorpion_Balance:"Cette délicate amante ne s’accorde pas du tout à la sexualité tourmentée et agressive de ce drogué du sexe. Sexuellement dominant et agressif, monsieur aime le sexe hard alors qu’elle ne rêve que de caresses suaves et romantiques.",
   Mars_Scorpion_Scorpion:"Une attraction violente sur fond de passion vorace sera la stimulation de leur histoire. Leur tourment existentiel sera le moteur d’une sexualité débridée en quête de sensations extrêmes.  Fascination sensuelle pour du sexe « no limit ».",
   Mars_Scorpion_Sagittaire:"Une passion dévorante anime ces deux amants insatiables. Lui, vicieux, désire emmener cette amazone, qui feint la résistance, vers l’exploration de nouvelles expériences interdites. Elle sera enchantée de découvrir de nouveaux plaisirs. ",
   Mars_Scorpion_Capricorne:"La motivation est grande pour cet homme qui aime la difficulté. Réveiller la libido et stimuler tous les sens de cette maîtresse frileuse relève d’un défi digne de l’ascension de l’Everest. Mais quelle satisfaction, lorsqu’elle s’abandonne. Sexualité éreintante.",
   Mars_Scorpion_Verseau:"Cet amant exigeant, dur et violent de désirs profitera de la sexualité libérée de cette rebelle. Leur imagination et leur fantaisie s’accorderont parfaitement, mais les délires extrêmes et trash de monsieur effrayeront cette amante qui n’a pourtant pas froid aux yeux.",
   Mars_Scorpion_Poissons:"Il va s’instaurer une sorte de danse hypnotique tel un cobra devant sa proie. Lui est un prédateur sexuel qui fascine l’objet de ses désirs pour mieux l’asservir. Elle, sensible aux sensations sexuelles, se soumettra sans réticences au dictât érotique de son amant. ",
  
   Mars_Sagittaire_Belier:"Plaisir quand tu nous tiens ! Pas de prises de tête juste le bonheur de goûter aux sensations intenses que procure une sexualité spontanée et ardente. Les coups de gueule de ces deux colériques ne seront qu'une excuse pour remettre le couvert.",
   Mars_Sagittaire_Taureau:"Une insatisfaction totale. Elle est extrêmement possessive et lui, adepte de la liberté, refuse toute aliénation et toute contrainte. Leurs désirs trop contradictoires créeront un invivable malaise. Affrontements violents sur fond de désaccords.",
   Mars_Sagittaire_Gemeaux:"Une attirance irrésistible pour ces deux curieux de la vie. Ils s’enthousiasmeront ensemble, emportés par l’ivresse des sens et la découverte de leurs plaisirs. Pas de temps à perdre, la vie est trop courte pour s’ennuyer. Sexualité joyeuse.",
   Mars_Sagittaire_Cancer:"Il saura user de ses atouts pour emporter cette femme soumise, dans des univers érotiques qui lui sont étrangers, emplis de fantasmes les plus insolites, un monde sensuel illusoire et éphémère où le sexe n'est qu'un exutoire.",
   Mars_Sagittaire_Lion:"Ils s'enflammeront pour cette aventure érotique qui les projettera dans un monde de fantasmes et de plaisirs sans limites, mais toujours dans un cadre doucereux, sans tortuosités. Leur caractère impétueux et explosif excitera de plus belle leur libido.",
   Mars_Sagittaire_Vierge:"Une curiosité commune à découvrir l'accès au plaisir charnel.  Il amène la dose de fantaisie qui manque à la sexualité plus classique de cette femme pudique. Mais l'inconsistance et la légèreté de l'un déstabilise et refroidit les ardeurs de l'autre.",
   Mars_Sagittaire_Balance:"Cet aventurier, adepte d’expériences insolites entraînera cette douce et délicate déesse sur un terrain inconnu qu’elle ne refusera pas d’explorer. Séduite par son enthousiasme communicatif, elle s’adonnera aux joies d’une sexualité décomplexée.",
   Mars_Sagittaire_Scorpion:"Une passion dévorante anime ces deux amants insatiables. Lui, ardent, allume moults brasiers dans le cœur et le corps de cette tigresse aux besoins sexuels voraces, qui l’entraînera vers de nouvelles expériences érotiques inédites.",
   Mars_Sagittaire_Sagittaire:"Cet héroïque chevalier emportera sa belle princesse vers de coquines aventures, dans lesquelles, il l’honorera vaillamment chaque jour, dans chaque pièce du château. Passion sexuelle ressemblant à un rodéo du sexe.",
   Mars_Sagittaire_Capricorne:"Ce valeureux amant, ardent et téméraire aura du mal à dégeler cette maîtresse pétrifiante de froideur. Il y mettra pourtant toute sa volonté et son imagination, mais s’épuisera et s’ennuiera devant si peu d’entrain à la tâche.",
   Mars_Sagittaire_Verseau:"Pas de demi-mesures dans cette relation passionnelle où la fantaisie et la liberté sexuelle seront les piments de leurs ébats. Sans tabous, ni jugements, le sexe est un plaisir dont ils usent et abusent allègrement. Carpe diem.",
   Mars_Sagittaire_Poissons:"Rencontre de deux épicuriens qui s’adonneront avec joie aux plaisirs de la chair sans retenues, juste dans le but de se faire du bien. Ils ne se promettent rien, mais jouissent de l’instant présent, faisant vibrer tout leur corps. Sexualité sans frontières.",
  
   Mars_Capricorne_Belier:"Cette amante impulsive se heurte à un homme dans la maîtrise de tout, dont l'énergie est canalisée, contrôlée et diluée. La sexualité est bridée et l'impatience de cette nerveuse ne compte pas s'éterniser sur tant de retenue et de froideur. C'est la rencontre du feu et de la glace... brrr",
   Mars_Capricorne_Taureau:"Alors que monsieur manque sérieusement d’élans et de passion, cette sensuelle maîtresse ne se découragera pas et réchauffera cet amant réfractaire de ses suaves caresses. La constance et le sérieux de cet homme accentuera sa confiance, elle si suspicieuse, et la motivera d’autant plus.",
   Mars_Capricorne_Gemeaux:"Les élans fantaisistes de cette amoureuse passionnée seront stoppés rapidement par cet amant au conservatisme routinier. Trop de normalité et si peu de folie, refroidira l’imaginaire de notre cérébrale et ne la motivera pas à la tâche.",
   Mars_Capricorne_Cancer:"La douceur et l'extrême sensualité de madame mettra monsieur en confiance et lui permettre de s'adonner aux joies du sexe, sans retenues. Il saura la protéger et elle le lui rendra bien, soumise à son rythme. Mais elle s'essoufflera, frustrée par si peu d’affection.",
   Mars_Capricorne_Lion:"Son manque d'entrain à la tâche, vexera la noblesse de cette femme passionnée qui se sentira incomprise et méprisée.  Ses besoins affectifs et démonstratifs seront mal perçus par la froideur et l'indifférence de cet amant sexuellement plus mécanique.",
   Mars_Capricorne_Vierge:"Cet amant austère et méfiant sera touché par la pudeur de cette amante attentive à son plaisir, désirant le rendre addict à ses caresses. Lui, toujours dans la maîtrise, guidera madame et l’amènera doucement vers une sexualité cérébrale et fétichiste.",
   Mars_Capricorne_Balance:"Une sexualité en souffrance où les acteurs principaux ont du mal à s’épanouir. Cet amant austère et détaché refroidira les élans de cette maîtresse chaleureuse et délicate. Trop d’efforts pour trop peu de plaisirs.",
   Mars_Capricorne_Scorpion:"La motivation est grande pour cette femme qui aime la difficulté. Réveiller la libido et stimuler tous les sens de cet amant frileux relève d’un défi digne de l’ascension de l’Everest. Mais quelle satisfaction, lorsqu’il s’adonnera enfin à une sexualité audacieuse !",
   Mars_Capricorne_Sagittaire:"Entreprenante, madame n’a pas froid aux yeux et usera de toute son expérience érotique et de son imagination pour dégeler cet homme pétrifiant de froideur. Devant si peu d’entrain, elle sombrera dans un ennui profond qui l’emportera vers d’autres amants.",
   Mars_Capricorne_Capricorne:"Leur mode de conquêtes est tellement identique qu’ils devinent l’attente de l’autre. Patients, ils sauront approfondir leur vie intime, dans une recherche de la maîtrise de leur plaisir pour atteindre le Nirvana de la jouissance. Couple d’experts.",
   Mars_Capricorne_Verseau:"Une attirance indéniable où la fantaisie de madame sera le moteur déclencheur des fantasmes de monsieur. Mais pour développer une intimité aussi excitante soit elle, il faudrait y consacrer un minimum de temps. Sexualité à distance.",
   Mars_Capricorne_Poissons:"La capacité à créer une véritable fusion charnelle, va lui permettre insidieusement de rendre addict cet amant coriace. Charmé par cette sirène envoûteuse, il la ligotera patiemment dans un froid désir de possession. ",
  
   Mars_Verseau_Belier:"Elle a un désir sexuel offensif et charnel, qui ne pourra que s'entendre avec cet amant si curieux de tout et aussi libre de toute expérience sexuelle, sans tabous aucuns. Mais la nervosité de cette maîtresse impétueuse pourrait tout gâcher.",
   Mars_Verseau_Taureau:"Il recherche une femme libre qui sort des sentiers battus, alors qu’elle ne se complaît que dans le conformisme. Leur sexualité est certes passionnée, mais bien trop classique et possessive pour cet original qui n'appartient à personne.",
   Mars_Verseau_Gemeaux:"Ensemble ils exploreront toute la littérature érotique et testerons toutes les postures du Kamasutra. La nouveauté et l’originalité motiveront leurs ébats, rien de tel pour pimenter une relation. Sans tabous juste pour le plaisir, un couple libertin.",
   Mars_Verseau_Cancer:"Elle ne le fait pas fantasmer car il fuit la banalité du foyer.  Lui trop insaisissable et libertin, la déconcerte. Ils se passeront à côté sans vraiment s'attarder, leurs univers charnels étant trop différents pour que la magie opère.",
   Mars_Verseau_Lion:"Une bonne connexion intellectuelle et une forte estime entre eux, créent une attraction palpitante et passionnée. L'originalité des fantasmes de l'un stimule la libido de l'autre.  Un désir frissonnant et dévorant.",
   Mars_Verseau_Vierge:"Il saura la tenter par de nouvelles expériences originales et libertines, et elle cédera à la tentation. Adaptable et curieuse, elle ne manquera pas d'assouvir tous ses fantasmes. Une sexualité sans tabous et sans engagement amoureux.",
   Mars_Verseau_Balance:"Deux amants romanesques mus par un idéal amoureux où leurs affinités mentales seront le moteur de leurs fantasmes. Ils vivent une relation érotique comme une exaltation artistique. Sexualité inspirée, d’une muse pour son maestro !",
   Mars_Verseau_Scorpion:"La fantaisie fait partie intégrante de la sexualité de cet homme, sans tabous et avec un grain non négligeable de curiosité érotique. Mais, malgré la complicité de leurs ébats, les fantasme de madame, la violence et le cuir, ne feront décidément pas partie de ses délires.",
   Mars_Verseau_Sagittaire:"Pas de demi-mesures dans cette relation passionnelle où la fantaisie et la liberté sexuelle seront les piments de leurs ébats. Sans tabous, ni jugements, le sexe est un plaisir dont ils usent et abusent allègrement. Carpe diem.",
   Mars_Verseau_Capricorne:"Une attirance indéniable où la fantaisie de monsieur sera le moteur déclencheur des fantasmes de madame. Mais pour développer une intimité aussi excitante soit-elle, il faudrait y consacrer un minimum de temps. Sexualité à distance.",
   Mars_Verseau_Verseau:"Une attirance cosmique instantanée. Ces deux originaux, se connectent au départ, cérébralement pour mieux accéder à leurs fantasmes, avant de se lancer dans un corps à corps enflammé. Sexe virtuel et fantaisiste.",
   Mars_Verseau_Poissons:"Ils onduleront entre attirance et répulsion sexuelle. Lui en chef d’orchestre, entraînera cette adepte soumise à réaliser ses fantasmes libertins. Mais notre sirène boulimique d’amour s’étiolera face à sa froideur affective.",
  
   Mars_Poissons_Belier:"Elle aura une sexualité bien trop agressive pour ce ménestrel de l’amour qui recherche dans la sexualité à transcender son amour très sensuel mais avec une bonne dose de tendresse et de sentiments. Sex-attitude ne fait pas bon ménage avec romantisme-attitude.",
   Mars_Poissons_Taureau:"La délicatesse de ses caresses attendrira ce romantique invétéré, l'amour est sublimé par une douce sensualité. Lui, sera soumis à sa volonté autoritaire et permettra à cette relation de trouver une vitesse de croisière épanouissante. ",
   Mars_Poissons_Gemeaux:"Sensuel, monsieur est un amant parfait, attentif et soumis à tous les désirs et fantasmes de cette imaginative libertine. Mais son manque de tendresse frustrera les attentes romantiques de cet amoureux inconditionnel.",
   Mars_Poissons_Cancer:"Ces deux-là sont connectés sur la même longueur d'ondes sensorielles érotiques. Leurs pensées sont emplies de fantasmes, plus osés les uns que les autres et l'excitation est à son comble. Ils vivent une passion dévorante et torride.",
   Mars_Poissons_Lion:"Lui, cherche à prendre dans ses filets cette amoureuse vulnérable, il se donnera corps et âme l’honorant telle une déesse de l’amour, mais cette conquérante de l’amour dédaigne cet amant soumis. Rapport de maître à soumis.",
   Mars_Poissons_Vierge:"Ils se laisseront tous deux envahir par des sensations inconnues, testant toutes les expériences sex-sensorielles au risque de sombrer dans la débauche. Tous deux adeptes des paradis artificiels, ils en useront à souhait pour accéder au nirvana.",
   Mars_Poissons_Balance:"Ces deux amants recherchent dans leur sexualité à atteindre un état extatique entremêlé de douceur et de souffrance. Ils vivront une sexualité libérée juste pour la magie de l’acte, mais ne se feront pas confiance. Erotisation de leurs angoisses. ",
   Mars_Poissons_Scorpion:"Il va s’instaurer une sorte de danse hypnotique telle une mante religieuse devant sa proie. Elle fascine l’objet de ses désirs pour mieux l’asservir. Lui très sensible aux sensations sexuelles, se soumettra sans réticences au dictat érotique de son amante.",
   Mars_Poissons_Sagittaire:"Rencontre de deux épicuriens qui s’adonneront avec joie aux plaisirs de la chair sans retenues, juste dans le but de se faire du bien. Ils ne se promettent rien mais jouissent de l’instant présent, faisant vibrer tout leur être. Sexualité sans frontières.",
   Mars_Poissons_Capricorne:"Sa capacité à créer une véritable fusion charnelle, va lui permettre insidieusement d’addicter cette amante frigide. Charmée par ce maître de l’envoûtement, elle le ligotera patiemment dans un froid désir de possession. ",
   Mars_Poissons_Verseau:"Ils onduleront entre attirance et répulsion sexuelle. Elle, mènera la danse entrainant cet adepte de l’érotisme à réaliser ses fantasmes les plus osés. Mais la froideur affective de cette maîtresse refroidira les ardeurs de ce romantique invétéré.",
   Mars_Poissons_Poissons:" Attirance irrésistible qui ressemble à une fusion des êtres et des corps projetés dans une dimension spirituelle de la sexualité. Cette relation est une communion parfaite entre sentiments et psychisme. Sexualité intense mais douce.",
  
}
//Venus


export const Venus ={
    Venus_Belier_Belier:"Vous aurez un coup de foudre au premier regard et vous vous laisserez emporter par  la même fougue. Vous ne jouez pas, vous vibrez de tout votre être et vous aimez  ça. Vous vivez une relation addictive qui vous dévore et devient obsessionnelle.",
    Venus_Belier_Taureau:"Lui instinctif, la séduira par son empressement et sa fougue amoureuse, mais elle se méfiera de son désintérêt qui peut être tout aussi soudain. Elle craint ses éparpillements et son infidélité car elle aspire à la stabilité des sentiments.",
    Venus_Belier_Gemeaux:"Sujet aux coups de foudre, il n’hésitera pas une minute à se lancer dans l’aventure, ce qui compte pour lui, ce sont les sensations de l’instant présent. Mais elle, très cérébrale, aura du mal à s’épanouir avec un personnage si basique.",
    Venus_Belier_Cancer:"Ici l’amour n’a pas les mêmes objectifs. Lui recherche la sensation instinctive sans trop se poser de questions sur l’après, alors qu’elle ne s’épanouit que dans une vision d'avenir et déteste perdre son temps dans les badineries de l’amour.",
    Venus_Belier_Lion:"L’amour est sublimé avec ces deux conquérants, entiers et passionnés, qui se donnent corps et âme à la réussite de leur union. Tous les deux voient l’amour comme le moteur de leur existence. Un peu comme une quête du  Saint Graal.",
    Venus_Belier_Vierge:"Ses emballements amoureux, certes la tentent mais la rendent méfiance, elle a peur de tout ce qui est précipité et de perdre le contrôle, pourtant elle rêve de se laisser aller aux affres de l’amour, mais craint de souffrir. Découragé par si peu d’ardeur, il s’en ira laissant cette peureuse de l’amour derrière lui. ",
    Venus_Belier_Balance:"Madame a une approche amoureuse très délicate alors que lui est plus direct. Elle appréciera pourtant ses assauts qui la rassureront sur ses sentiments. De son coté, monsieur adorera la voir céder à ses avances insistances. Entre eux c’est vraiment la Love Attraction.",
    Venus_Belier_Scorpion:"Quand les feux de l’amour emporte ces deux excessifs, entiers et sans demi-mesures, ils ne ménagent pas leur cœur, ni leur monture car entre ces deux-là, on se donne corps et âme. Une passion sexuelle.",
    Venus_Belier_Sagittaire:"Entre vous l’amour est une explosion de joie et de plaisirs à l’état pur. C’est une aventure sentimentale où seules vos satisfactions réciproques sont en jeu, sans contraintes ni tabous. C’est l’amour pour le plaisir de l’amour.",
    Venus_Belier_Capricorne:"Comment ces deux amours peuvent ils s’épanouir ? Monsieur, rempli de bonnes vibrations amoureuses se heurte à un mur de craintes des sensations de l’amour. Il sera freiné en plein élan par une amoureuse frileuse, recroquevillée dans sa tour d’ivoire.",
    Venus_Belier_Verseau:"Lui, brûlant de désir, sera emporté par ses pulsions amoureuses, oubliant toute raison. Il saura décoincer cette amoureuse craintive, aux sentiments pourtant bouillonnants. Il ne s’épanouira qu’en la révélant. Un vent de bonheur souffle sur les feux de l’amour.",
    Venus_Belier_Poissons:"Pour lui, l’amour est un moyen d’accéder à des sensations fortes, et de se noyer dans les flux et reflux des vibrations du désir. Elle, cette romantique invétérée, recherche à sublimer l’amour, dans une quête d’absolu et de divin. Ils ne sont pas vraiment pas, sur la même longueur d’ondes.",
   
    Venus_Tauraeu_Belier:"La nature instinctive et l’empressement à l’amour de cette fougueuse amoureuse ne sont pas pour lui déplaire mais il se méfie d’elle, craignant qu’elle puisse se désintéresser de lui aussi soudainement. Il redoute ses éparpillements et sa frivolité, lui qui aspire à la stabilité des sentiments. ",
    Venus_Tauraeu_Taureau:"Leur histoire d’amour est la rencontre d’Adam et Eve, dans le jardin d’Eden. Ces deux gourmands étancheront leur soif d’amour en cédant à la tentation de plaisirs dionysiaques.  Exclusifs, ils s’aimeront dans leur nid douillet, à l’abri des regards.",
    Venus_Tauraeu_Gemeaux:"On ne peut pas dire qu’il soit sensible à ses pirouettes amoureuses, déconcerté il ne sait plus si elle est sincère ou s’il n’est qu’un instrument de plaisir. On ne plaisante pas avec l’amour !  Badineries amoureuses.",
    Venus_Tauraeu_Cancer:"Son côté petit fille fragile l’émeut et il a  envie de la protéger. Elle, docile se laisse guider dans son paradis de l’amour. Elle sera une mère parfaite pour ses enfants, lui se voit déjà avec elle,  main dans la main, tournés vers l’avenir.",
    Venus_Tauraeu_Lion:"Lui est un adepte des plaisirs simples, et elle ce qu’elle veut c’est du clinquant. Il espérera quelques démonstrations amoureuses alors qu’elle est une diva qui aime être adulée. Difficile de se laisser aller à l’amour dans un tel rapport de force.",
    Venus_Tauraeu_Vierge:"Sa pudeur lui donne envie de l’éveiller aux plaisirs sensuels et qu’elle s’ouvre à l’amour. Sa nature dévouée l’émerveille et elle est rassurée par son côté pragmatique et protecteur. Votre amour c’est du solide !",
    Venus_Tauraeu_Balance:"Vénus la planète de l’amour exacerbe l’attirance entre  ces deux signes, où se  confondent amour et désir. Mais dépassée la magie des premiers frissons, leurs attentes amoureuses auront du mal à s’accorder.",
    Venus_Tauraeu_Scorpion:"Une puissante attraction va les propulser dans une histoire amoureuse intense sur fond de jalousie et de possessivité. Un amour passionnel ravagé par les crises  d’hystérie de madame et l’autorité de monsieur.  Amour dévastateur.",
    Venus_Tauraeu_Sagittaire:"Une attirance physique qui s’étiolera entre les 4 murs de leur maison de campagne. Elle, vise un amour sans frontières alors que lui, possessif, veut cloîtrer l’objet de ses désirs dans un enclos bien fermé. Amour étouffant.",
    Venus_Tauraeu_Capricorne:"Avec beaucoup de gourmandise sensuelle et amoureuse, il réussira à apprivoiser cette prude demoiselle. Sa réserve le pousse à déployer des trésors d’ingéniosité pour réchauffer ses réticences et la rassurer sur ses intentions. Amour respectueux.",
    Venus_Tauraeu_Verseau:"Malgré une attirance évidente pour les plaisirs de la chair, lui aura du mal à convertir cette originale à un amour exclusif. Chez lui, l'amour suit des codes amoureux classiques qui se heurteront violemment à la légèreté de cette adepte de l’amour libre.",
    Venus_Tauraeu_Poissons:"La sensualité et les désirs amoureux de cet épicurien vont nourrir les rêves romantiques de cette douce sirène qui se soumet à son rythme. Ils tissent un lien solide au fil du temps malgré les zones d’ombres de cette amoureuse au cœur d’artichaut.",
   
    Venus_Gemeaux_Belier:"Son attirance passe avant tout par son imaginaire, il aime fantasmer et flirter mais attention à ne pas trop jouer avec le feu car vous aurez du mal à maîtriser cette fougueuse amoureuse qui n’attendra pas votre feu vert pour vous violenter. ",
    Venus_Gemeaux_Taureau:"Il aime jouer de ses atouts pour séduire cette belle odalisque qui l’allume avec ses formes avantageuses, mais elle n’a pas l’air de s’amuser autant que lui. Elle, bien trop sérieuse et possessive, s’agace de ses dérobades. Jeux de dupes.",
    Venus_Gemeaux_Gemeaux:"Tous leurs sens sont affûtés, prêts à rentrer dans une danse virevoltante de jeux d’esprits et de mots qui les exciteront au plus haut point et attiseront l’amour et les fantasmes. Deux espiègles amoureux fuyant le sérieux de la vie.",
    Venus_Gemeaux_Cancer:"Le ton est donné à cette histoire, légère, joyeuse et coquine. Mais pas question de faire le grand saut et de s’interdire de butiner çà et là de jolies fleurs prêtes à s’épanouir dans ses bras.  Amours d’adolescents.",
    Venus_Gemeaux_Lion:"Coup de foudre inévitable et prévisible. Leur histoire ressemble à une comédie romantique où notre talentueux orateur déclamera pompeusement son amour à sa belle, émerveillée par son brillant prince charmant. Amour théâtral.",
    Venus_Gemeaux_Vierge:"L’attirance est avant tout intellectuelle, une séduction par l’esprit, lui est un habile orateur qui l’éblouira de son talent, mais cette cérébrale, se méfiera de ce beau parleur. Lui est fasciné par son aura mystérieuse. Des atomes crochus.",
    Venus_Gemeaux_Balance:"La manière délicate d’aimer de cette bella donna, séduit cet esprit fin qui recherche la perfection. Élégance, raffinement et créativité sont les qualités qui transcendent cette divine relation amoureuse. Amour  sublimé. ",
    Venus_Gemeaux_Scorpion:"Malin et espiègle, il saura attiser sa curiosité, elle, exerce une véritable fascination. Attention, cette relation sent le soufre mais ils ne peuvent s’empêcher de s’y frotter, pensant qu’ils en tirent les ficelles. Ils se fourvoient. Manipulations amoureuses.",
    Venus_Gemeaux_Sagittaire:"Il lui contera fleurette, lui murmurant des mots d’amour et la faisant rire aux larmes de ses pitreries infantiles. Elle adore son humour, il égaye sa vie et lui donne un air de béatitude. Voyage au bout de l’amour.",
    Venus_Gemeaux_Capricorne:"Il lui sortira le grand jeu, des tirades dignes de Cyrano, ses meilleures blagues et étalera sa culture. Mais rien ne déridera cette amoureuse frigide. Méfiante, elle est totalement insensible à son charme et il la fatigue. Fiasco amoureux.",
    Venus_Gemeaux_Verseau:"Pour la séduire rien de plus facile, être soi-même, car ils sont connectés sur la même longueur d’ondes amoureuses. Leur humour et leur fantaisie cimentent  cette relation complice. Amour inspiré et idéal. ",
    Venus_Gemeaux_Poissons:"Il comprendra très vite le mode amoureux de cette romantique qui rêve de son prince charmant. Il se glissera le temps d’un instant dans la peau de celui qui la fait rêver, mais cela ne le distraira pas longtemps. Amour caméléon.",
   
   
    Venus_Cancer_Belier:"Ici l’amour n’a pas les mêmes objectifs. Elle recherche la sensation instinctive sans trop se poser de questions sur l’après, alors que lui ne s’épanouira que dans une vision d'avenir et ne perdra pas son temps dans les badineries de l’amour. Amour éphémère.",
    Venus_Cancer_Taureau:"Son côté femme voluptueuse et maternelle l’émeut et lui donne envie de se laisser dorloter.  Elle, sensuelle, se laisse guider dans son paradis de l’amour, il faut dire qu’il devine le désir des femmes. Amour maternel et paternel",
    Venus_Cancer_Gemeaux:"Le ton est donné à cette histoire, légère, joyeuse et coquine, mais où il n’y aura pas la place pour un amour fusionnel. Elle, très peu affectueuse, ne peut répondre aux demandes d’amour de ce grand enfant. Incompréhension d’amour.",
    Venus_Cancer_Cancer:"Vous jouez sur le même terrain de l’amour, l’envie d’un conte de fée et la construction d’un foyer rempli d’enfants, se protégeant l’un l’autre des agressions extérieures dans votre cocon d’amour. Amour douceur et fusionnel.",
    Venus_Cancer_Lion:"Il sera subjugué par sa beauté. Très féminine, elle pousse à l’admiration, mais n’est pas du genre à se soumettre à ses désirs. Il devra composer avec ses envies, mais ses démonstrations d’amour, trop mielleuses,  la lasseront très vite. ",
    Venus_Cancer_Vierge:"Malgré sa pudeur et sa réserve amoureuse, elle le rassurera par ses petites attentions au quotidien. Romantique et tendre, il saura l’épanouir à l’amour et elle le chouchoutera comme son enfant. Il sera aux anges. Amour serviable.",
    Venus_Cancer_Balance:"Même si vous êtes tous deux romantiques et que vous ne vivez que pour l’amour, votre conception du couple est bien trop divergente. Lui recherche une amante, une mère et une amie alors qu’elle, a besoin d’être au centre de toutes les attentions.",
    Venus_Cancer_Scorpion:"Une attirance irrésistible les poussera tous les deux à se noyer dans les eaux profondes de l’amour, submergés par des émotions fortes. Elle imposera des règles d’amour torturé et mènera la danse dans son univers tortueux. Liaison fatale.",
    Venus_Cancer_Sagittaire:"Une attirance sensuelle va lier leur destin qui n’était pourtant pas voué à se croiser. Cette amazone toujours enthousiaste pour les plaisirs de la vie, étouffera vite,  privée de liberté par cet incorrigible nounours aux besoins d’être materné. ",
    Venus_Cancer_Capricorne:"Une attirance irrésistible sur fond de défi, les besoins amoureux du Cancer font face à un mur défensif, craignant la désillusion amoureuse. Délicatement,  il tentera de prendre son cœur, mais l’aridité de ses sentiments aura raison de ses motivations.",
    Venus_Cancer_Verseau:"Deux fantaisistes attirés par des chimères auxquelles ils auront envie de croire, mais que la triste réalité de la vie rattrapera. Les attentes amoureuses de l’un seront frustrées par le détachement de l’autre. Amour qui fait illusion.",
    Venus_Cancer_Poissons:"Vous vivrez une connexion instinctive pouvant vous conduire à une véritable extase amoureuse. Tous deux romantiques, vous vivrez votre amour dans un univers fantastique où les émotions s’entrelacent. Amour romantique et sublimé.",
   
    Venus_Lion_Belier:"L’amour est sublimé avec ces deux conquérants, entiers et passionnés, qui se donnent corps et âme à la réussite de leur union. Tous les deux voient l’amour comme le moteur de leur existence. Un peu comme une quête du  Saint Graal.",
    Venus_Lion_Taureau:"Ce conquérant de l’amour sera subjugué par cette odalisque sensuelle à laquelle il fera une cour élégante et passionnée. Elle se soumettra volontiers à ses désirs et lui vouera une admiration sans bornes. Mais un conflit d’autorité gâchera cette belle histoire.",
    Venus_Lion_Gemeaux:"Un coup de foudre inévitable et prévisible. Leur histoire ressemble à une comédie romantique où notre  majestueux amoureux déploiera toute sa verve pour déclarer sa flamme à sa belle,  émerveillée par ce si  brillant prince charmant. Amour théâtral.",
    Venus_Lion_Cancer:"Entre eux, s’allumera une étincelle magique. Il sera subjugué par sa féminité, elle incarnera la femme idéale. Romantique,  elle sera en admiration devant ce noble chevalier fou d’amour pour elle.  Amour autoritaire et soumis.",
    Venus_Lion_Lion:"Miroir, mon beau miroir dis-moi qui est le plus rayonnant ? Une attirance irrésistible pour son double au féminin, une admiration pour l’excellence que tous deux convoitent. Un amour narcissique où la limite sera le partage du trône.",
    Venus_Lion_Vierge:"Il sera touché par son dévouement et sa générosité à lui rendre la vie meilleure, très attentionnée, elle saura prendre soin de son « bien aimé » et il saura la récompenser dignement de son précieux amour. Amour raffiné.",
    Venus_Lion_Balance:"Elle sera impressionnée par l’assurance et le charisme qui s’émane de cet amoureux passionné et chevaleresque. Elle, cette Vénus raffinée et élégante, saura lui faire honneur et flatter son ego de mâle. Amour mythique.",
    Venus_Lion_Scorpion:"Le Lion conquérant s’engage dans un corps à cœur sulfureux où l’amoureuse n’est autre qu’une mante religieuse. L’atmosphère de cette liaison est érotique et passionnée, mais tourmentée et dangereuse. Amour, affrontements et provocations.",
    Venus_Lion_Sagittaire:"Entre eux, c’est un feu d’artifice. Notre noble conquérant séduit sa belle avec panache et elle l’encourage de ses pirouettes et autres facéties amoureuses. Ils s’estiment et se vouent une grande admiration. Amour enflammé.",
    Venus_Lion_Capricorne:"Ce vaillant conquérant n’hésitera pas à s’attaquer à cette imprenable forteresse qu’il sait vulnérable émotionnellement. Grâce à ses élans affectifs, il saura rassurer cette prudente amoureuse, mais ne trouvera pas le réconfort qu’il souhaite.",
    Venus_Lion_Verseau:"L’apparence désintéressée et froide de cette amoureuse quelque peu méfiante sera une motivation supplémentaire à conquérir son cœur. Le feu qui anime ce conquérant de l’amour, embrasera tout sur son passage et les emportera dans une folle passion. Amour téméraire.",
    Venus_Lion_Poissons:"Elle l’aguichera d’un regard suggestif et le séduira le temps d’un instant par ses élans d’amour. Une relation éphémère qui peut être passionnée, mais l’égocentrisme de ce royal amoureux laissera un goût amer à cette incurable romantique.",
   
    Venus_Vierge_Belier:"Ses emballements amoureux, certes le tentent mais le rendent méfiant, il a peur de tout ce qui est précipité et de perdre le contrôle. Pourtant, il rêve de se laisser aller aux affres de l’amour, mais craint de souffrir. Elle, c’est de la passion qu’elle désire, découragée par tant de tiédeur, elle s’en ira, sans se retourner.",
    Venus_Vierge_Taureau:"Sa réserve lui donne envie de l’éveiller aux plaisirs sensuels et qu’il s’ouvre à l’amour. Sa nature dévouée l’émerveille et elle est rassurée par son côté pragmatique et protecteur. Votre amour c’est du solide !",
    Venus_Vierge_Gemeaux:"L’attirance est avant tout intellectuelle, une séduction par l’esprit. Lui est cultivé, brillant, il l’éblouira par son talent, c’est un cérébral, mais il se méfiera de l’instabilité de cette éternelle gamine. Elle, est troublée par sa réserve et son aura mystérieuse la fascine. Des atomes crochus.",
    Venus_Vierge_Cancer:"Malgré sa pudeur et sa réserve amoureuse, il la rassurera par des petites attentions au quotidien. Romantique et tendre, elle saura l’épanouir à l’amour et en retour, il la protégera comme son enfant. Un compromis amoureux satisfaisant.",
    Venus_Vierge_Lion:"Il la touchera par son dévouement et sa générosité à lui rendre la vie meilleure, très attentionné, il saura prendre soin de sa « bien aimée ». Elle,  saura le récompenser dignement de son précieux amour. Amour raffiné, respect mutuel.",
    Venus_Vierge_Vierge:"Un amour pudique où ces deux timides doseront leurs élans. La passion n’appartient pas à cette histoire, mais l’entente est réelle et sincère. Une réaliste prudence les anime pour la construction d’un foyer préparé à toute éventualité. Amour raisonné.",
    Venus_Vierge_Balance:"Ils se tournent autour avant de s’engager dans l’histoire, tous deux craignent de se tromper. Lui a peur des sentiments et elle sombre dans l’indécision, j’y vais ou j’y vais pas ?  Amour improbable.",
    Venus_Vierge_Scorpion:"Captivé par le charme magnétique de cette Vénus envoûtante, ce cérébral va baisser sa garde et se laisser emporter dans une passion où son corps et son esprit seront sous l’emprise de cette machiavélique amoureuse. Amour obscur.",
    Venus_Vierge_Sagittaire:"Entre eux c’est l’attirance des différences, lui consommateur averti de l’amour craint les emballements, alors qu’elle dévore à pleines dents les plaisirs qui lui sont offerts. Attraction contrariée par leur vision de l’amour.",
    Venus_Vierge_Capricorne:"L’amour ici se calcule, on évalue l’autre avant de se laisser aller aux sentiments. Une même quête pour du sérieux, une même exigence du cœur. L’estime et le respect seront les premiers sentiments avant-coureurs de l’amour.",
    Venus_Vierge_Verseau:"L’insouciance de l’amour est malmenée par cet amoureux pragmatique et prudent, alors qu’elle aime l’imprévu et aspire à la légèreté. Seul l’engouement intellectuel qui les anime peut faire illusion d’amour.",
    Venus_Vierge_Poissons:"Sa douce tendresse pourrait bien inciter cet amoureux réticent aux émotions, à s’abandonner sans retenues aux sensations de l’amour. Ses délicates attentions la combleront, mais elle manquera cruellement d’affection.",
   
    Venus_Balance_Belier:"Même si l’approche amoureuse de ce dandy est plus délicate que cette impétueuse amoureuse, plus directe, il saura apprécier ses assauts qui le rassureront, et elle, aimera le voir céder à ses avances insistantes. Entre eux c’est la Love Attraction.",
    Venus_Balance_Taureau:"Vénus la planète de l’amour exacerbe l’attirance entre ces deux signes, où se confondent amour et désir. Mais dépassé la magie des premiers frissons, leurs attentes amoureuses auront du mal à s’accorder. ",
    Venus_Balance_Gemeaux:"La manière délicate d’aimer de ce dandy séduit cette intellectuelle à l’ esprit fin qui recherche la perfection. Élégance, raffinement, créativité sont les qualités qui transcendent cette divine relation amoureuse. Amour  sublimé. ",
    Venus_Balance_Cancer:"Même si vous êtes tous deux romantiques et que ne vivez que pour l’amour, votre conception du couple est bien trop divergente. Elle recherche un amant, un père alors que lui  a besoin d’être au centre de toutes les attentions. Amour égocentrique.",
    Venus_Balance_Lion:"Cet homme raffiné et élégant impressionnera cette amoureuse passionnée et orgueilleuse. Sensible à la beauté, elle sera fière de ce compagnon délicat, attentionné qui lui fait honneur et flatte son ego. Amour mythique.",
    Venus_Balance_Vierge:"Ils se tournent autour avant de s’engager dans l’histoire, tous deux craignent de se tromper. Elle a peur des sentiments, alors que lui sombre dans l’indécision, j’y vais ou j’y vais pas? Amour improbable.",
    Venus_Balance_Balance:"Entre eux ça balance pas mal, le charme opère simultanément, Vénus astre de l’amour et du plaisir, veille sur eux. Délicatesse et idéalisme se mêlent dans un jeu de séduction élégant pour accéder à un amour paradisiaque. Amour doucereux. ",
    Venus_Balance_Scorpion:"Attention danger ! Une attirance incontrôlée pour un univers troublant où dominent  passion des sens et  sentiments extrêmes. Une énergie amoureuse très éloignée de l’harmonie romantique qui est la quête de cet amoureux distingué. La tentation du diable.",
    Venus_Balance_Sagittaire:"Elle manque certes de raffinement pour lui, mais ses élans amoureux le font chavirer. Malgré son côté rebelle, cultivée et spirituelle, elle sait s’adapter à tous les milieux. Ils vibreront à l’unisson le temps d’un instant magique.",
    Venus_Balance_Capricorne:"La délicatesse et l’élégance de ses sentiments susciteront, chez cette amoureuse distante, un vif intérêt et un emballement certes léger, mais réel, pour cet amour. Mais lui, amoureux de l’amour, aura du mal à se contenter de si peu. ",
    Venus_Balance_Verseau:"Une rencontre détonante et passionnelle placée à un haut niveau d’idéalisme. L’admiration est le déclencheur d’un amour fusionnel tant au niveau cérébral que physique. Spiritual Love.",
    Venus_Balance_Poissons:"Ils seront emportés par un tourbillon d’émotions mêlant amour et sensualité. Cette amoureuse romanesque, lui clamera son amour, créant un univers magique pour conquérir son prince. Un rêve d’amour illusoire où la déception sera cuisante.",
   
    Venus_Scorpion_Belier:"Quand le feu de l’amour emporte ces deux excessifs, entiers et sans demi-mesures, ils ne ménagent pas leur cœur, ni leur monture car entre ces deux-là, on se donne corps et âme. Une passion sexuelle.",
    Venus_Scorpion_Taureau:"Une puissante attraction va les propulser dans une histoire amoureuse intense sur fond de jalousie et de possessivité. Un amour passionnel ravagé par les crises  d’hystérie de madame et l’autorité de monsieur.  Amour dévastateur.",
    Venus_Scorpion_Gemeaux:"Maligne avec un esprit vif, elle saura attiser sa curiosité et lui, saura la fasciner. Attention, cette relation sent le soufre, mais ils ne peuvent s’empêcher de s’y frotter pensant qu’ils en tirent les ficelles. Ils se fourvoient. Liaison fatale sur fond de manipulation.",
    Venus_Scorpion_Cancer:"Une attirance irrésistible les poussera tous les deux à se noyer dans les eaux profondes de l’amour, submergés par des émotions fortes. Il imposera des règles d’amour torturé et mènera la danse dans un univers tortueux. Liaison fatale.",
    Venus_Scorpion_Lion:"Cette amoureuse flamboyante s’engage dans un corps à cœur sulfureux où l’amoureux n’est autre qu’un prédateur. L’atmosphère de cette liaison est érotique et passionnée, mais tourmentée et dangereuse. Amour, affrontements et provocations.",
    Venus_Scorpion_Vierge:"Captivée par le charme magnétique de ce dangereux amant, cette belle cérébrale va baisser sa garde et se laisser emporter dans une passion où son corps et son esprit seront sous l’emprise de ce machiavélique amoureux. Amour obscur.",
    Venus_Scorpion_Balance:"Attention danger ! Une attirance incontrôlée pour un univers troublant où dominent passion des sens et sentiments extrêmes. Une énergie amoureuse éloignée de l’harmonie romantique qui est la quête de cette amoureuse distinguée. La tentation du diable.",
    Venus_Scorpion_Scorpion:"Un coup de foudre fulgurant, déclencheur d’une euphorie sensuelle, va propulser ces deux excessifs dans une relation d’amour teinté de haine. Une exploration décapante de leur intimité amoureuse profonde. Amour dévastateur.",
    Venus_Scorpion_Sagittaire:"Rencontre d’un amoureux excessif, mais torturé, avec une amoureuse candide et joyeuse. Elle sera envoûtée et emprisonnée dans cet amour, supplicié par un amant sado masochiste. ",
    Venus_Scorpion_Capricorne:"L’attirance de cet amant sulfureux pour cette amoureuse frigide est un véritable challenge qui l’excite. Sa résistance ne durera pas longtemps et elle cédera  aux assauts de cet acharné qui lui offrira une nouvelle prison, l’addiction. Amour de domination.",
    Venus_Scorpion_Verseau:"Cet amoureux tortueux défie cette donzelle qui snobe l’amour et qui fuit toute aliénation émotionnelle, trop ordinaire pour elle. Elle est cependant projetée dans l’univers magique de ce pro du sexe, qui la fait fantasmer. Amour insolite.",
    Venus_Scorpion_Poissons:"Entre eux, ce sera une attirance instinctive et magique où cette amoureuse romantique et sensuelle sera en proie aux désirs torturés d’un adepte de l’amour extrême, vibrant au gré des fluctuations de ses émotions, alternant extase et tourments. Amour sadomaso.",
   
    Venus_Sagittaire_Belier:"Entre eux, l’amour est une explosion de joie et de plaisirs à l’état pur. C’est une aventure sentimentale où seule la satisfaction commune est en jeu, sans contraintes ni tabous. C’est l’amour pour le plaisir de l’amour.",
    Venus_Sagittaire_Taureau:"Une attirance physique qui s’étiolera entre les 4 murs de leur maison de campagne. Cet aventurier de l’amour vise une relation sans frontières, alors qu’elle aime de façon possessive et désire l’enfermer dans un enclos bien fermé. Amour étouffant.",
    Venus_Sagittaire_Gemeaux:"Il lui contera fleurette, lui murmurant des mots d’amour en langues étrangères et la fera rire aux larmes de ses pitreries. Elle adore son humour, il égaye sa vie et lui souffle un air vivifiant et joyeux. Voyage au bout de l’amour.",
    Venus_Sagittaire_Cancer:"Une attirance sensuelle va lier leur destin alors qu’ils n’étaient pourtant pas voués à se croiser. Cet explorateur de l’amour toujours enthousiaste pour les plaisirs de la vie, étouffera vite, privé de liberté par cette incorrigible capricieuse aux besoins d’être maternée. ",
    Venus_Sagittaire_Lion:"Entre eux c’est un feu d’artifice. Notre aventurier flamboyant séduit sa belle avec panache et elle l’encourage d’une passion ardente et sincère. Ils s’estiment et se vouent une grande admiration. Amour enflammé.",
    Venus_Sagittaire_Vierge:"Entre eux c’est l’attirance des différences. Elle, plutôt réservée, consommatrice averti de l’amour, craint les emballements, alors que lui dévore à pleines dents les plaisirs qui lui sont offerts. Attraction contrariée par leur vision de l’amour.",
    Venus_Sagittaire_Balance:"Il manque certes de raffinement pour elle, mais ses élans amoureux la font chavirer et ses réticences s’envolent. Élégante et féminine,  elle aime aussi profiter des plaisirs de la vie.  Ils vibreront à l’unisson le temps d’un instant magique.",
    Venus_Sagittaire_Scorpion:"Rencontre d’une amoureuse excessive et torturée avec un amoureux candide et joyeux. Il est envoûté et emprisonné dans cet amour, supplicié par cette dévoreuse d’hommes sado masochiste. Obstinations amoureuses.",
    Venus_Sagittaire_Sagittaire:"Un feeling spontané et enthousiaste va les emporter dans  une passion entraînante. Cette aventure amoureuse exalte leurs sensations de bien-être, proche d’une extase spirituelle où leurs âmes sont en fusion. Amour mystique.",
    Venus_Sagittaire_Capricorne:"Un manque d’intérêt certain risque de saboter toute possibilité de relation amoureuse entre eux. La froideur des sentiments de l’un sera à la hauteur de l’enthousiasme amoureux de l’autre. Amour refroidissant.",
    Venus_Sagittaire_Verseau:"Ces deux électrons libres auront un plaisir non dissimulé à vivre pleinement une relation amoureuse, passionnément atypique. Leur soif d’aventure sera étanchée grâce au rythme insolite de leurs émotions et de leurs envies. Amour libertin. ",
    Venus_Sagittaire_Poissons:"Un véritable coup de foudre va les propulser dans une aventure passionnante où le maître mot sera « Plaisirs ». La sensualité de cette sirène enflammera ce fougueux amoureux. Mais passé l’euphorie des prémices, l’ennui  balayera cette histoire.",
   
    Venus_Capricorne_Belier:"Comment ces deux amoureux pourraient-ils s’épanouir ? Elle est rempli de bonnes vibrations amoureuses, mais se heurte à un mur de craintes des sensations de l’amour. Cette amoureuse spontanée est freinée en plein élan par ce peureux de l’amour, recroquevillé dans sa forteresse.",
    Venus_Capricorne_Taureau:"Avec beaucoup de gourmandise sensuelle et amoureuse, elle réussira à apprivoiser cet austère amoureux. Sa réserve la poussera à déployer des trésors d’ingéniosité pour réchauffer ses réticences et le rassurer sur ses intentions. Amour respectueux.",
    Venus_Capricorne_Gemeaux:"Cette légère ingénue déploiera des trésors d’ingéniosité pour lui plaire, étalant sa culture et faisant des mots d’esprit, mais rien ne déridera ce triste sire. Elle se heurtera à un mur de froideur. Méfiant, il sera totalement insensible à son charme et elle l’agacera de ses facéties. Fiasco amoureux.",
    Venus_Capricorne_Cancer:"Une attirance irrésistible sur fond de défi. Les besoins d'affection amoureuse de cette douce aimante se heurteront sur le mur défensif de cet homme qui craint la désillusion amoureuse. Délicatement,  elle tentera de conquerir son cœur, mais l’aridité de ses sentiments aura raison de ses motivations.",
    Venus_Capricorne_Lion:"Cette vaillante conquérante n’hésitera pas à s’attaquer à cette imprenable forteresse qu’elle sait vulnérable émotionnellement. Grâce à ses élans affectifs elle saura réchauffer ce prudent amoureux, mais ne trouvera pas le réconfort qu’elle souhaite.",
    Venus_Capricorne_Vierge:"Ici, l'amour se calcule. On évalue l’autre avant de se laisser aller aux sentiments. Une même quête pour du sérieux, une même exigence du cœur. L’estime et le respect seront les premiers sentiments avant-coureurs de l’amour.",
    Venus_Capricorne_Balance:"La délicatesse et l’élégance des sentiments de cette amoureuse de l’amour, susciteront chez cet amoureux distant, un vif intérêt et un léger, mais réel emballement. Sauf que cette douce vénusienne aura du mal à se contenter de si peu.",
    Venus_Capricorne_Scorpion:"L’attirance de cette croqueuse d’hommes pour cet amoureux frigide est un challenge qui l’excite. Sa résistance ne durera pas longtemps et il cédera aux assauts de cette acharnée qui lui offrira une nouvelle prison, l’addiction. Amour de domination.",
    Venus_Capricorne_Sagittaire:"Un manque d’intérêt certain risque de saboter toute possibilité de relation amoureuse entre eux. La froideur des sentiments de l’un sera à la hauteur de l’enthousiasme amoureux de l’autre. Amour refroidissant.",
    Venus_Capricorne_Capricorne:"Attirance miroir entre ces deux réfractaires à l’amour. Leur sensibilité piégée par leurs craintes, apprendra à s’apprivoiser par des actes rassurants sur leurs intentions. Leur amour est sincère et sérieux, mais manque cruellement de passion.",
    Venus_Capricorne_Verseau:"Une étincelle insoupçonnable va s’allumer entre eux, mais pour combien de temps ? La sévérité de ce frileux de l’amour ne déplaira pas à cette amoureuse légère qui déteste les minauderies amoureuses. Un amour distant, mais intense.",
    Venus_Capricorne_Poissons:"Intuitivement, elle devinera les trésors cachés derrière son masque de froideur. Sa douceur ne sera pas une qualité superflue pour avoir raison de ses réticences. Ce qui l’intéresse, c’est trouver le chemin de son cœur. Amour persévérant et soumis.",
   
    Venus_Verseau_Belier:"La pulsion amoureuse cette passionnée l’emporte sur la raison et décoince ce cérébral, dont les sentiments retenus bouillonnent, mais ne s’expriment pas. Elle s’épanouira en sublimant leur amour. Un vent de bonheur souffle sur les feux de l’amour.",
    Venus_Verseau_Taureau:"Malgré une attirance évidente pour les plaisirs de la chair, cette vénusienne aura du mal à convertir cet amoureux de la liberté à l'amour exclusif. Les codes amoureux classiques de cette amoureuse possessive se heurteront violemment à la légèreté de cet amant libertin.",
    Venus_Verseau_Gemeaux:"Pour la séduire rien de plus facile, être soi-même, car ils sont connectés sur la même longueur d’ondes amoureuses. Leur humour et leur fantaisie cimentent  cette relation complice. Amour inspiré et idéal. ",
    Venus_Verseau_Cancer:"Deux fantaisistes attirés par des chimères auxquelles ils auront envie de croire, mais que la triste réalité de la vie rattrapera. Les attentes amoureuses de l’un seront frustrées par le détachement de l’autre. Illusion d'amour.",
    Venus_Verseau_Lion:"Son apparence désintéressée et froide sera pour cette passionnée, une motivation supplémentaire à conquérir son cœur. Le feu qui anime cette amante flamboyante embrasera cet intellectuel et l’emportera dans une folle passion. Amour téméraire.",
    Venus_Verseau_Vierge:"L’insouciance de l’amour est malmenée par cette amoureuse pragmatique et prudente, alors que lui n’aspire qu’à la légèreté et l’imprévu. Seul l’engouement intellectuel qui les anime peut faire illusion d’amour.",
    Venus_Verseau_Balance:"Une rencontre détonante et passionnelle placée à un haut niveau d’idéalisme. L’admiration est le déclencheur d’un amour fusionnel tant au niveau cérébral que physique. Spiritual Love.",
    Venus_Verseau_Scorpion:"Cette dévoreuse d’hommes défie ce cérébral qui snobe l’amour et qui fuit toute aliénation émotionnelle, trop ordinaire pour lui. Il sera cependant projeté dans l’univers magique de cette mystérieuse amante qui le fait fantasmer. Amour insolite.",
    Venus_Verseau_Sagittaire:"Ces deux électrons libres auront un plaisir non dissimulé à vivre pleinement une relation amoureuse, passionnément atypique. Leur soif d’aventure sera étanchée grâce au rythme insolite de leurs émotions et de leurs envies. Amour libertin. ",
    Venus_Verseau_Capricorne:"Une étincelle insoupçonnable va s’allumer entre eux mais pour combien de temps ? La sévérité de cette amoureuse méfiante ne déplaît pas cet intello qui déteste les minauderies amoureuses. Un amour distant, mais intense.",
    Venus_Verseau_Verseau:"La désinvolture est l’essence même de cette histoire d’amour. Aucune promesse n’est faite, seul le plaisir de l’instant les motivera sans jamais s’aliéner à de foutues règles de bonne conduite. Amour émancipé.",
    Venus_Verseau_Poissons:"Il sera attiré par sa magie amoureuse qui le propulsera dans un monde fantaisiste et éthéré. L’expérience le tente et il se laissera porter par le rythme sensuel de ses vibrations sans vraiment s’impliquer au grand dam de cette sirène. Amour illusoire.",
   
    Venus_Poissons_Belier:"Pour elle, l’amour est un moyen d’accéder à des sensations fortes, et de vous noyer dans ses flux et reflux de vibrations. Mais cet amant romantique recherche à sublimer l’amour, dans une quête d’absolu et de divin. Ils ne sont pas sur la même longueur d’ondes.",
    Venus_Poissons_Taureau:"La sensualité et les désirs amoureux de cette vénusienne nourrissent les rêves romantiques de cet amoureux transi qui se soumet à son rythme. Ils tissent un lien solide au fil du temps malgré les zones d’ombres de cet incorrigible séducteur.",
    Venus_Poissons_Gemeaux:"Elle comprendra très vite le fonctionnement amoureux de ce romantique qui rêve de conquérir sa jolie princesse. Elle se glissera, le temps d’un instant, dans la peau de celle qui le fait rêver, mais cela ne la distraira pas longtemps. Amour caméléon.",
    Venus_Poissons_Cancer:"Vous vivrez une connexion instinctive pouvant vous conduire à une véritable extase amoureuse. Tous deux romantiques, vous vivrez votre amour dans un univers fantastique où les émotions s’entrelacent. Amour romantique.",
    Venus_Poissons_Lion:"Il l’aguichera d’un regard suggestif et la séduira le temps d’un instant par ses élans d’amour. Une relation éphémère qui peut être passionnée, mais l’égocentrisme de cette dominatrice laissera un goût amer à cet incurable romantique.",
    Venus_Poissons_Vierge:"Sa douce tendresse pourrait bien inciter cette amoureuse réticente aux émotions, à s’abandonner dans ses bras  aux sensations de l’amour. Ses délicates attentions le combleront, mais il manquera cruellement d’affection.",
    Venus_Poissons_Balance:"Ils seront emportés par un tourbillon d’émotions mêlant amour et sensualité. Ce ménestrel de l’amour lui clamera son amour, créant un univers magique pour conquérir sa belle. Le rêve d’amour ne sera qu’illusion et la déception cuisante.",
    Venus_Poissons_Scorpion:"Entre eux, ce sera une attirance instinctive et magique où cette sirène romantique et sensuelle sera en proie aux désirs torturés de cet amant névrosé, vibrant au gré des fluctuations de ses émotions, alternant extase et tourments. Amour sadomaso.",
    Venus_Poissons_Sagittaire:"Un véritable coup de foudre va les propulser dans une aventure passionnante où le maître mot sera « Plaisirs ». Cet amant sensuel s’enflammera pour cette fougueuse amoureuse. Mais passé l’euphorie des prémices, l’ennui  balayera cette histoire.",
    Venus_Poissons_Capricorne:"Intuitivement, il devinera les trésors cachés derrière son masque de froideur. Sa douceur ne sera pas une qualité superflue pour avoir raison de ses réticences, ce qui l’intéresse, c’est trouver le chemin de son cœur. Amour persévérant et soumis.",
    Venus_Poissons_Verseau:"Elle sera attirée par sa magie amoureuse qui la propulsera dans un monde fantaisiste et éthéré, l’expérience la tente et elle se laissera porter par le rythme sensuel de ses vibrations sans vraiment s’impliquer au grand dam de ce lover invétéré. Amour illusoire.",
    Venus_Poissons_Poissons:"Attirance intense et fusion des âmes. Ils vivent leur amour comme dans un rêve, au rythme des vibrations de leurs émotions, transportés dans un monde mystique et intemporel où leur corps et leur esprit ne font qu’un. Amour tantrique.",
   }