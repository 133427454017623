/* eslint-disable no-unused-expressions */
import * as React from 'react';

export const EditStatusForm = (props) => {
  const { mStatus, setStatus } = props;

  return (
    <>
      {mStatus === 'Banni' ? (
        <select
          onClick={(e) => {
            e.target.value === 'noth'
              ? setStatus({
                  ban: false,
                  activ: false,
                  desact: false,
                  unban: false,
                })
              : setStatus({
                  ban: false,
                  activ: false,
                  desact: false,
                  unban: true,
                });
          }}
          defaultValue={mStatus}
        >
          <option disabled value={mStatus}>
            {mStatus}
          </option>
          <option value={'noth'}>{"Laisser L'ancien etat"}</option>
          <option value={'unban'}>{'Débannir Cet Utilisateur'}</option>
        </select>
      ) : mStatus === 'Inactif' ? (
        <select
          onClick={(e) => {
            e.target.value === 'noth'
              ? setStatus({
                  ban: false,
                  activ: false,
                  desact: false,
                  unban: false,
                })
              : e.target.value === 'ban'
              ? setStatus({
                  activ: false,
                  desact: false,
                  unban: false,
                  ban: true,
                })
              : setStatus({
                  desact: false,
                  ban: false,
                  unban: false,
                  activ: true,
                });
          }}
          defaultValue={mStatus}
        >
          <option disabled value={mStatus}>
            {mStatus}
          </option>
          <option value={'noth'}>{"Laisser L'ancien etat"}</option>
          <option value={'activ'}>{'Rendre Actif Cet Utilisateur'}</option>
          <option value={'ban'}>{'Bannir Cet Utilisateur'}</option>
        </select>
      ) : (
        <select
          onClick={(e) => {
            e.target.value === 'noth'
              ? setStatus({
                  ban: false,
                  activ: false,
                  desact: false,
                  unban: false,
                })
              : e.target.value === 'ban'
              ? setStatus({
                  activ: false,
                  unban: false,
                  desact: false,
                  ban: true,
                })
              : setStatus({
                  activ: false,
                  unban: false,
                  ban: false,
                  desact: true,
                });
          }}
          defaultValue={mStatus}
        >
          <option disabled value={mStatus}>
            {mStatus}
          </option>
          <option value={'noth'}>{"Laisser L'ancien etat"}</option>
          <option value={'desact'}>{'Rendre Inactif Cet Utilisateur'}</option>
          <option value={'ban'}>{'Bannir Cet Utilisateur'}</option>
        </select>
      )}
    </>
  );
};
