import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  pageTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(5),
    marginRight:theme.spacing(5),
    marginLeft:theme.spacing(5)
  },
  typo: {
    //color: theme.palette.text.hint,
    fontFamily: 'SF-Pro-Display-Medium',
  },
  button: {
    borderColor: '#1ABC9C',
    border:'1px solid',
    height: 35,
    width: 35,
    borderRadius: 18,
    textTransform: "none",
    "&:active": {
      boxShadow: theme.customShadows.widgetWide,
    },
    
  },
}));
