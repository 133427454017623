/* eslint-disable no-unused-expressions */

import React from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, Provider } from 'react-redux';
import { userSignInSelector } from './slices/userSignIn';
import SignIn from './containers/SignIn/SignIn';
import Layout from './components/Layout';
import { LayoutProvider } from './context/LayoutContext';
import { UserProvider } from './context/UserContext';
import Themes from './themes';
import { LinearProgress } from '@material-ui/core';
import rootReducer from './slices';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import logo from './logo.svg';
import './App.css';

function View() {
  return <h1>connected</h1>;
}

//Function to load
function App() {
  const { isAuthenticated } = useSelector((state) => {
    let token = localStorage.getItem('jwtToken');
    console.log(token);
    const { isAuthenticated } =
      !token || token === '' ? state.userSignIn : { isAuthenticated: true };
    //console.log(isAuthenticated)
    return {
      isAuthenticated,
    };
  });

  return (
    <HashRouter>
      <Switch>
        <Route
          exact
          path='/'
          render={() => <Redirect to='/loveattraction/dashboard' />}
        />
        <Route
          exact
          path='/app'
          render={() => <Redirect to='loveattraction/dashboard' />}
        />
        <PrivateRoute path='/loveattraction' component={Layout} />
        <PublicRoute path='/login' component={SignIn} />
        <Route component={Error} />
      </Switch>
    </HashRouter>
  );

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}

//configuration for redux persist

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['userSignIn'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

//  confugre redux persist
function AdminPanelLoveAttraction() {
  const [load, setLoad] = React.useState(false);
  React.useEffect(() => {
    const pic = {
      home: true,
      adr: false,
      user: false,
      even: false,
      promo: false,
      abb: false,
    };

    !JSON.parse(localStorage.getItem('pictosState')) ||
    window.location.href.split('#')[1] === undefined ||
    window.location.href.split('#')[1] === '/loveattraction/dashboard'
      ? localStorage.setItem('pictosState', JSON.stringify(pic))
      : '';
  }, []);

  React.useEffect(() => {
    const bootstrapAsync = async () => {
      persistStore(store, {}, () => {
        console.log(load);
        console.log(store.getState());
        setLoad(true);
        console.log(load);
      });
    };

    bootstrapAsync();
  }, []);

  return (
    <>
      {load ? (
        <Provider store={store}>
          <LayoutProvider>
            <UserProvider>
              <ThemeProvider theme={Themes.default}>
                <CssBaseline />
                {<App />}
              </ThemeProvider>
            </UserProvider>
          </LayoutProvider>
        </Provider>
      ) : (
        <LinearProgress />
      )}
    </>
  );
}
export default AdminPanelLoveAttraction;
