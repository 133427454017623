import * as React from "react";
import useStyles from "./styles";

export default function PromoMenu(props) {
  const { opend, setOpend, data, setSlctdPromo, setMsg } = props;
  let classes = useStyles();
  const handleClose = (p) => {
    setOpend(null);
    setSlctdPromo(p);
    setMsg(p.Message);
  };

  return (
    <div style={{ position: "relative" }}>
      {opend ? (
        <div className={classes.menu}>
          {data.length !== 0 ? (
            data.map((p, i) => {
              return (
                <div
                  className={classes.menuItem}
                  onClick={() => {
                    handleClose(p);
                  }}
                  key={i}
                >
                  {p.name}
                </div>
              );
            })
          ) : (
            <div
              className={classes.menuItem}
              onClick={() => {
                setOpend(null);
              }}
            >
              Pas De Code Promo !!!
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
}
