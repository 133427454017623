import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Typography, Button } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import { TextField, Select } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import { Formik, Form, Field } from 'formik';
import useStyles from './styles';
import csc from 'country-state-city';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import TextField2 from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

// Dialog title
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function UserForm(props) {
  var classes = useStyles();
  const autoC = React.useRef(null);
  const autoC2 = React.useRef(null);
  const { onClose, open, setUserInfo } = props;
  const [canSave, setCanSave] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingModal, setLoadingModal] = React.useState(false);
  const [countries, setcountries] = React.useState({
    countries: csc.getAllCountries(),
    loading: true,
  });
  const [birthCity, setbirthCity] = React.useState('');
  const [resCity, setresCity] = React.useState('');
  const [villes, setvilles] = React.useState([]);
  const [villesR, setvillesR] = React.useState([]);
  const [pbirth, setpbirth] = React.useState({ long: null, lat: null });
  const [pos, setpos] = React.useState({ long: null, lat: null });
  const getVilles = async (CountryCode) => {
    setLoadingModal(true);
    const url = `${process.env.REACT_APP_API_URL}auth/cities/${CountryCode}`;
    const response = await fetch(url);
    const data = await response.json();
    setvilles(data.data);
    setLoadingModal(false);
  };
  const getVillesR = async (CountryCode) => {
    /* const villes = csc.getCitiesOfCountry(CountryCode)
    setvillesR(villes) */
    setLoadingModal(true);
    const url = `${process.env.REACT_APP_API_URL}auth/cities/${CountryCode}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setvillesR(data.data);
        setLoadingModal(false);
      });
  };
  useEffect(() => {
    const options = countries.countries.map((v) => ({
      label: v.name,
      value: v.isoCode,
    }));
    setcountries({ countries: options, loading: false });
  }, []);
  const isNotEmpty = (currentValue) => currentValue != '';
  if (!loading) {
    return (
      <>
        <Dialog
          onClose={() => onClose()}
          aria-labelledby='customized-dialog-title'
          open={open}
        >
          <Modal
            open={loadingModal}
            aria-labelledby='simple-modal-title'
            aria-describedby='simple-modal-description'
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <div
              style={{
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress size={100} />
            </div>
          </Modal>
          <DialogTitle id='customized-dialog-title' onClose={() => onClose()}>
            Ajouter un nouvel utilisateur
          </DialogTitle>
          <DialogContent dividers>
            <Formik
              initialValues={{
                username: '',
                email: '',
                gender: 'mal',
                password: '',
                surname: '',
                birthday: '',
                nativeCountry: '',
                birthCity: '',
                CountryName: '',
                CityName: '',
                birthTime: '',
                age: 0,
              }}
              validate={(values) => {
                //  if (birthCity!="" && resCity!="") {
                setCanSave(
                  [
                    values.username,
                    values.email,
                    values.gender,
                    values.password,
                    values.surname,
                    values.birthday,
                    values.nativeCountry,
                    //birthCity,
                    values.birthTime,
                    //resCity,
                    values.CountryName,
                    values.age,
                  ].every(isNotEmpty)
                );
                //}
                //console.log([values.email, values.firstname, values.lastname,values.password,values.phonenumber,values.role,values.username].every(isNotEmpty));
                const errors = {};
                if (!values.email) {
                  errors.email = 'Required';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Invalid email address';
                }
                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                let dtoTOTransfer = {};
                let position = {
                  type: 'Point',
                  coordinates: [pbirth.lat, pbirth.long],
                };
                let position2 = {
                  type: 'Point',
                  coordinates: [pos.long, pos.lat],
                };
                dtoTOTransfer.avatar = null;
                dtoTOTransfer.surname = values.surname;
                let privateProfile = {
                  birthday: values.birthday,
                  nativeCountry: csc.getCountryByCode(values.nativeCountry)
                    .name,
                  birthCity: birthCity,
                  birthTime: values.birthTime,
                  birthplace: position,
                };
                dtoTOTransfer.username = values.username;
                dtoTOTransfer.age = values.age;
                dtoTOTransfer.email = values.email;
                dtoTOTransfer.gender = values.gender;
                dtoTOTransfer.password = values.password;
                dtoTOTransfer.countryName = csc.getCountryByCode(
                  values.CountryName
                ).name;
                dtoTOTransfer.cityName = resCity;
                dtoTOTransfer.position = position2;
                dtoTOTransfer.residenceplace = position2;
                dtoTOTransfer.private = privateProfile;
                setLoadingModal(true);
                const url = `${process.env.REACT_APP_API_URL}auth/register`;
                const response = await fetch(url, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(dtoTOTransfer),
                });

                const data = await response.json();
                console.log(data);
                setUserInfo(data);
                setLoadingModal(false);
                onClose();
              }}
            >
              {({
                errors,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form className={classes.form}>
                  <Grid container>
                    <Grid item xs={12} sm={12} spacing={4}>
                      <Field
                        component={TextField}
                        name='email'
                        type='email'
                        label='Email'
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Field
                        component={TextField}
                        name='username'
                        type='username'
                        label="Nom d'utilisateur"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Field
                        component={Select}
                        name='gender'
                        type='gender'
                        label='Sexe'
                        fullWidth
                      >
                        <MenuItem value='mal'>Male</MenuItem>
                        <MenuItem value='female'>Femelle</MenuItem>
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name='password'
                        type='password'
                        label='Mot de passe'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Field
                        component={TextField}
                        name='surname'
                        type='surname'
                        label='Prenom'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <text style={{ fontWeight: 'bold' }}>
                        Date de naissance
                      </text>
                      <Field
                        component={TextField}
                        name='birthday'
                        type='date'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <text style={{ fontWeight: 'bold' }}>
                        Pays de naissance
                      </text>
                      <Field
                        component={Select}
                        name='nativeCountry'
                        type='nativeCountry'
                        onClick={(e) => {
                          const ele = autoC.current.getElementsByClassName(
                            'MuiAutocomplete-clearIndicator'
                          )[0];
                          if (ele) ele.click();
                          getVilles(e.target.value);
                          setbirthCity('');
                        }}
                        fullWidth
                      >
                        {countries.countries.map((fbb) => (
                          <MenuItem value={fbb.value}>{fbb.label}</MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <text style={{ fontWeight: 'bold' }}>
                        Ville de naissance
                      </text>
                      {/*  <Field
                        component={Select}
                        name="birthCity"
                        type="birthCity"
                        fullWidth
                      >
                        {villes?.map((fbb) => (
                          <MenuItem
                            value={fbb.name}
                            onClick={() =>
                              setpbirth({
                                long: fbb.longitude,
                                lat: fbb.latitude,
                              })
                            }
                          >
                            {fbb.name}
                          </MenuItem>
                        ))}
                      </Field> */}
                      <Autocomplete
                        ref={autoC}
                        id='combo-box-ville'
                        options={villes}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, value) => {
                          console.log(value);
                          if (value !== null) {
                            console.log('entered');
                            setpbirth({
                              long: value.longitude,
                              lat: value.latitude,
                            });
                            setbirthCity(value.name);
                          }
                        }}
                        renderInput={(params) => <TextField2 {...params} />}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name='birthTime'
                        type='time'
                        label='heure de naissance '
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name='age'
                        type='number'
                        label='Age '
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <text style={{ fontWeight: 'bold' }}>
                        Pays de residence
                      </text>
                      <Field
                        component={Select}
                        name='CountryName'
                        type='CountryName'
                        onClick={(e) => {
                          const ele = autoC2.current.getElementsByClassName(
                            'MuiAutocomplete-clearIndicator'
                          )[0];
                          if (ele) ele.click();
                          getVillesR(e.target.value);
                          setresCity('');
                        }}
                        fullWidth
                      >
                        {countries.countries.map((fbb) => (
                          <MenuItem value={fbb.value}>{fbb.label}</MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <text style={{ fontWeight: 'bold' }}>
                        Ville de residence
                      </text>
                      {/* <Field
                        component={Select}
                        name="CityName"
                        type="CityName"
                        fullWidth
                      >
                        {villesR?.map((fbb) => (
                          <MenuItem
                            value={fbb.name}
                            onClick={() =>
                              setpos({ long: fbb.longitude, lat: fbb.latitude })
                            }
                          >
                            {fbb.name}
                          </MenuItem>
                        ))}
                      </Field> */}
                      <Autocomplete
                        ref={autoC2}
                        id='combo-box-ville'
                        options={villesR}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, value) => {
                          if (value !== null) {
                            setpos({
                              long: value.longitude,
                              lat: value.latitude,
                            });
                            setresCity(value.name);
                          }
                        }}
                        renderInput={(params) => <TextField2 {...params} />}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        className={classes.submit}
                        type='submit'
                        disabled={
                          birthCity != '' && resCity != '' ? !canSave : true
                        }
                        fullWidth
                        variant='contained'
                        color='primary'
                      >
                        {loading ? 'patientez svp' : 'sauvegarder'}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}
