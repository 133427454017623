import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
//import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Typography, Button } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import { TextField, Select } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import { Formik, Form, Field } from 'formik';
import useStyles from './styles';
import PromoMenu from './Menu';
import { MessageZone } from './MessageZone';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    borderRadius: 50,
  },
}))(MuiDialogContent);

export default function PromotionFormCode(props) {
  var classes = useStyles();
  const { onClose, open, setUserInfo } = props;
  const [canSave, setCanSave] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [PromoData, setPromoData] = React.useState(null);

  useEffect(() => {
    /* if (props.id !=null){
    (async () => {
      const url =
        `${process.env.REACT_APP_API_URL}admin-user/memberProfile/` + props.id;
      const response = await fetch(url);
      const data = await response.json();
      setPromoData(data);
    })();
  } */
  }, [props.id]);

  //fetch promo data to put in dropdown menu
  useEffect(() => {
    (async () => {
      const url = `${process.env.REACT_APP_API_URL}admin-user/GetPromo/Code`;
      const response = await fetch(url);
      const data = await response.json();
      setPromoData(data.data);
    })();
  }, []);

  const isNotEmpty = (currentValue) =>
    currentValue !== '' && msg !== '<p><br></p>';
  const [opend, setOpend] = useState(false);
  const [slctdPromo, setSlctdPromo] = useState();
  const [msg, setMsg] = useState('');

  if (!loading) {
    return (
      <>
        <Dialog
          onClose={() => onClose()}
          aria-labelledby='customized-dialog-title'
          open={open}
        >
          <Typography
            style={{
              alignSelf: 'center',
              paddingTop: 30,
              padding: 20,
              fontFamily: 'SF-Pro-Display-Bold',
            }}
          >
            Ajouter une promotion
          </Typography>
          <DialogContent>
            <Formik
              initialValues={{
                name: slctdPromo ? slctdPromo.name : '',
                code: slctdPromo ? slctdPromo.CouponCode : '',
                start_date: slctdPromo
                  ? slctdPromo.start_date.split('T')[0]
                  : '',
                end_date: slctdPromo ? slctdPromo.end_date.split('T')[0] : '',
                /*Message: slctdPromo ? slctdPromo.Message : "",*/
                Promo24: slctdPromo ? slctdPromo.Promo24 : '',
                Promo1: slctdPromo ? slctdPromo.Prom01 : '',
                Promo3: slctdPromo ? slctdPromo.Promo3 : '',
                Promo6: slctdPromo ? slctdPromo.Promo6 : '',
              }}
              enableReinitialize
              validate={(values) => {
                setCanSave(
                  [
                    values.name,
                    values.code,
                    values.start_date,
                    values.end_date,
                    msg,
                    values.Promo24,
                    values.Promo1,
                    values.Promo3,
                    values.Promo6,
                  ].every(isNotEmpty)
                );

                const errors = {};
                /* if (!values.email) {
                  errors.email = "Required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                    values.email
                  )
                ) {
                  errors.email = "Invalid email address";
                } */
                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                let dtoTOTransfer = {};
                dtoTOTransfer.name = values.name;
                dtoTOTransfer.CouponCode = values.code;
                dtoTOTransfer.start_date = values.start_date;
                dtoTOTransfer.end_date = values.end_date;
                dtoTOTransfer.Message = msg;
                dtoTOTransfer.Promo24 = values.Promo24;
                dtoTOTransfer.Prom01 = values.Promo1;
                dtoTOTransfer.Promo3 = values.Promo3;
                dtoTOTransfer.Promo6 = values.Promo6;
                dtoTOTransfer.Type = 'Code';

                console.log(dtoTOTransfer);

                //setLoading(true);
                const url = `${process.env.REACT_APP_API_URL}admin-user/createPromo`;
                const response = await fetch(url, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(dtoTOTransfer),
                });
                setSlctdPromo(null);
                setCanSave(false);
                setMsg('');
                onClose();
              }}
            >
              {({
                errors,
                isSubmitting,
                setValues,
                /* and other goodies */
              }) => (
                <Form style={{ width: 400 }}>
                  <Grid container>
                    <Grid item xs={12} sm={12} spacing={4}>
                      <text className={classes.title}>Nom de la promotion</text>
                      <Field
                        component={TextField}
                        name='name'
                        type='name'
                        fullWidth
                        onClick={() => {
                          setOpend(!opend);
                        }}
                      />
                      <PromoMenu
                        opend={opend}
                        setOpend={setOpend}
                        data={PromoData}
                        setMsg={setMsg}
                        setSlctdPromo={setSlctdPromo}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} spacing={4}>
                      <text className={classes.title}>Code promo</text>
                      <Field
                        component={TextField}
                        name='code'
                        type='code'
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 15,
                      }}
                    >
                      <text className={classes.title}>Du</text>
                      <div style={{ width: 20 }} />
                      <Field
                        component={TextField}
                        name='start_date'
                        type='date'
                        fullWidth
                      />
                      <text className={classes.title}>Au</text>
                      <div style={{ width: 20 }} />
                      <Field
                        component={TextField}
                        name='end_date'
                        type='date'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ marginTop: 15 }}>
                      <text className={classes.title}>Message</text>
                      <MessageZone msg={msg} setMsg={setMsg} />
                      {/* <Field
                        component={MessageZone}
                        name="Message"
                        type="Message"
                        fullWidth
                      /> */}
                    </Grid>

                    <Typography className={classes.title}>
                      Regles de reduction
                    </Typography>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      spacing={4}
                      style={{ display: 'flex', flexDirection: 'row' }}
                    >
                      <div style={{ display: 'flex', flex: 1 }}>
                        <text className={classes.title}>24 h =</text>
                      </div>
                      <div style={{ display: 'flex', flex: 3 }}>
                        <Field
                          component={TextField}
                          name='Promo24'
                          type='number'
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      spacing={4}
                      style={{ display: 'flex', flexDirection: 'row' }}
                    >
                      <div style={{ display: 'flex', flex: 1 }}>
                        <text className={classes.title}>1 mois =</text>
                      </div>
                      <div style={{ display: 'flex', flex: 3 }}>
                        <Field
                          component={TextField}
                          name='Promo1'
                          type='number'
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      spacing={4}
                      style={{ display: 'flex', flexDirection: 'row' }}
                    >
                      <div style={{ display: 'flex', flex: 1 }}>
                        <text className={classes.title}>3 mois =</text>
                      </div>
                      <div style={{ display: 'flex', flex: 3 }}>
                        <Field
                          component={TextField}
                          name='Promo3'
                          type='number'
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      spacing={4}
                      style={{ display: 'flex', flexDirection: 'row' }}
                    >
                      <div style={{ display: 'flex', flex: 1 }}>
                        <text className={classes.title}>6 mois =</text>
                      </div>
                      <div style={{ display: 'flex', flex: 3 }}>
                        <Field
                          component={TextField}
                          name='Promo6'
                          type='number'
                        />
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 30,
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        variant='outlined'
                        color='error'
                        onClick={() => {
                          setSlctdPromo(null);
                          setCanSave(false);
                          setMsg('');
                          onClose();
                        }}
                      >
                        Annuler
                      </Button>
                      <div style={{ width: 20 }} />
                      <Button
                        className={classes.submit}
                        type='submit'
                        disabled={!canSave}
                        variant='outlined'
                        color='primary'
                      >
                        {loading ? 'patientez svp' : 'Valider'}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}
