import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import classnames from 'classnames';

// styles
import useStyles from './styles';

// components
import Header from '../Header/Header';
import Sidebar from '../Sidebar';

// pages
import Dashboard from '../../pages/dashboard';
import KingdomQuestion from '../../pages/kingdomQuestion';
import Tables from '../../pages/tables';
import Icons from '../../pages/icons';
import Admins from '../../pages/admins';
import Charts from '../../pages/charts';
import AdminForm from '../../pages/admins/AdminForm';
import Users from '../../pages/users';
import Simulateur from '../../pages/simulateur';
import Signalements from '../../pages/signalements';
import Banned from '../../pages/signalements/banned';
import confirmationsByPic from '../../pages/confirmationsByPic';
import Promotions from '../../pages/promotions';
import Events from '../../pages/events';
import CodePromotions from '../../pages/promotions/CodePromotions';
import Influencer from '../../pages/promotions/Influencer';
import EventPromo from '../../pages/promotions/EventPromo';
import Photogalerie from '../../pages/confirmationsByPic/photogalerie';
import PhotoAvatar from '../../pages/confirmationsByPic/photoAvatar';
import Videogalerie from '../../pages/confirmationsByPic/videogalerie';

// context
import { useLayoutState } from '../../context/LayoutContext';
import { SimulatorAstral } from '../../pages/simulatorAstral/SimulatorAstral';

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        {/* <Sidebar /> */}
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
          style={{ backgroundColor: 'white' }}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route path='/loveattraction/dashboard' component={Dashboard} />
            <Route
              path='/loveattraction/kingdomQuestion'
              component={KingdomQuestion}
            />
            <Route path='/loveattraction/tables' component={Tables} />
            <Route
              exact
              path='/loveattraction/users'
              render={() => (
                <Redirect to='/loveattraction/users/compatibility' />
              )}
            />
            <Route
              path='/loveattraction/users/compatibility'
              component={Simulateur}
            />
            <Route path='/loveattraction/users/list' component={Users} />
            <Route
              path='/loveattraction/users/signalements'
              component={Signalements}
            />
            <Route
              path='/loveattraction/users/simulator'
              component={SimulatorAstral}
            />
            <Route path='/loveattraction/users/banned' component={Banned} />
            <Route
              path='/loveattraction/users/confirmations'
              component={confirmationsByPic}
            />
            <Route path='/loveattraction/promotions' component={Promotions} />
            <Route
              path='/loveattraction/CodePromotions'
              component={CodePromotions}
            />
            <Route path='/loveattraction/EventPromo' component={EventPromo} />
            <Route path='/loveattraction/Influenceur' component={Influencer} />
            <Route
              path='/loveattraction/users/photogalerie'
              component={Photogalerie}
            />
            <Route
              path='/loveattraction/users/photoAvatar'
              component={PhotoAvatar}
            />
            <Route
              path='/loveattraction/users/videogalerie'
              component={Videogalerie}
            />
            <Route path='/loveattraction/admin/list' component={Admins} />
            <Route path='/loveattraction/admin/add' component={AdminForm} />
            <Route path='/loveattraction/events' component={Events} />
          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
