import { createSlice } from '@reduxjs/toolkit';

// Initial state for user API
export const initialState = {
  user: null,
  jwToken: '',
  loading: false,
  error: null,
  isAuthenticated: false,
};

const userSignInSlice = createSlice({
  name: 'userSignIn',
  initialState,
  reducers: {
    getuserSignIn: (state) => {
      state.loading = true;
    },
    getuserSignInSuccess: (state, { payload }) => {
      state.user = payload.user;
      state.jwToken = payload.access_token;
      state.loading = false;
      state.error = null;
      state.isAuthenticated = true;
    },
    getuserSignInFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.data;
    },
    getuserSignOut: (state) => {
      state.isAuthenticated = false;
    },
  },
});

export default userSignInSlice.reducer;
export const {
  getuserSignIn,
  getuserSignInSuccess,
  getuserSignInFailure,
  getuserSignOut,
} = userSignInSlice.actions;

// A selector
export const userSignInSelector = (state) => state;

export function SignInUser(email, password) {
  return async (dispatch) => {
    dispatch(getuserSignIn());
    const credential = { email: email, password: password };
    console.log(JSON.stringify(credential));
    console.log(process.env.NODE_ENV);
    const proxyurl = 'https://cors-anywhere.herokuapp.com/';
    const url = `${process.env.REACT_APP_API_URL}admin-auth/signin`;
    console.log(url);
    fetch(url, {
      method: 'POST',
      headers: {
        mode: 'no-cors', // no-cors,
        'Access-Control-Allow-Origin': 'http://127.0.0.1:3000',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        credentials: 'same-origin',
        'Cross-Origin-Resource-Policy': 'same-site same-origin',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credential),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.success) {
          localStorage.setItem('jwtToken', data.data.access_token);
          localStorage.setItem('adminData', data.data);
          dispatch(getuserSignInSuccess(data.data));
        } else {
          dispatch(getuserSignInFailure(data));
        }
      })
      .catch((error) => {
        dispatch(getuserSignInFailure(error));
        console.log('Can’t access ' + url + ' response. Blocked by browser?');
      });
  };
}
export function SignOutUser(history) {
  return async (dispatch) => {
    localStorage.removeItem('jwtToken');
    dispatch(getuserSignOut());
    history.push('/login');
  };
}
