import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userSignInSelector, SignInUser } from '../../slices/userSignIn';
import { withRouter } from "react-router-dom";

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="#">
        Love Attraction
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    //justifyContent:'center',


  },
  title:{
   marginBottom:100
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  alert:{
    width:"100%",
    //marginTop:100,
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
 function SignIn() {
  const classes = useStyles();
  const  {loading,error}  =useSelector(state=>{
    const {loading,error}= state.userSignIn;
    return {
      loading
      ,error
    }
  });
  const dispatch = useDispatch()
  let [loginValue, setLoginValue] = React.useState("");
  let [passwordValue, setPasswordValue] = React.useState("");
  
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
      
       
        <Typography component="h1" variant="h5" className={classes.title}>
        Love Attraction Admin Panel 
        </Typography>
        {error&&<Alert severity="error" className={classes.alert}>Veerifieer vos infos!</Alert>}
        <form className={classes.form} >
          <TextField
            value={loginValue}
            onChange={e=>setLoginValue(e.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            value={passwordValue}
            onChange={e => setPasswordValue(e.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          
          <Button
           disabled={
            loginValue.length === 0 || passwordValue.length === 0
          }
            onClick={(e)=>{
              e.preventDefault()
              //console.log("data")
             // console.log(loginValue,passwordValue)

              dispatch(SignInUser(loginValue,passwordValue))}}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {loading ? <CircularProgress/>: 'sign in'}
          </Button>
          
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default withRouter(SignIn)