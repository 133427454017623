import React, { useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { Typography, Button } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { Formik, Form, Field } from 'formik';
import useStyles from './styles';
import { MessageZone } from './MessageZone';
import axios from 'axios';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    borderRadius: 50,
  },
}))(MuiDialogContent);

export default function PromotionForm(props) {
  var classes = useStyles();
  const { onClose, open, setData, data } = props;
  const [canSave, setCanSave] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const fileRef = useRef();
  const [msg, setMsg] = React.useState('');

  const isNotEmpty = (currentValue) => currentValue != '';

  return (
    <>
      <Dialog
        onClose={() => onClose()}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <Typography
          style={{
            alignSelf: 'center',
            paddingTop: 30,
            padding: 20,
            fontFamily: 'SF-Pro-Display-Bold',
          }}
        >
          Ajouter une promotion
        </Typography>
        <DialogContent>
          <Formik
            initialValues={{
              titre: '',
              name: '',
              start_date: '',
              end_date: '',
              Message: '',
              TauxRed: '',
            }}
            enableReinitialize
            validate={(values) => {
              setCanSave(
                [
                  values.titre,
                  values.name,
                  values.start_date,
                  values.end_date,
                  msg,
                  values.TauxRed,
                  values.Tous,
                ].some(isNotEmpty)
              );
              const errors = {};

              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              let dtoTOTransfer = {};

              dtoTOTransfer.title = values.titre;
              dtoTOTransfer.name = values.name;
              dtoTOTransfer.start_date = values.start_date;
              dtoTOTransfer.end_date = values.end_date;
              dtoTOTransfer.Message = msg;
              dtoTOTransfer.Percentage = values.TauxRed;
              dtoTOTransfer.Population = values.Tous;
              dtoTOTransfer.image = fileRef.current.value.split('\\')[2];
              dtoTOTransfer.Type = 'Simple';

              const formData = new FormData();

              formData.append('image', fileRef.current.files[0]);

              setLoading(true);
              const url = `${process.env.REACT_APP_API_URL}admin-user/createPromo`;
              const urlImg = `${process.env.REACT_APP_API_URL}admin-user/upload/image`;

              axios.post(urlImg, formData).then((res) => {
                fetch(url, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(dtoTOTransfer),
                }).then((res) => {
                  setData([...data, dtoTOTransfer]);
                  setMsg('');
                  setLoading(false);
                });
              });

              onClose();
            }}
          >
            {({
              errors,
              isSubmitting,
              setValues,
              /* and other goodies */
            }) => (
              <Form style={{ width: 400 }}>
                <Grid container>
                  <Grid item xs={12} sm={12} spacing={4}>
                    <text className={classes.title}>Titre</text>

                    <Field
                      component={TextField}
                      name='titre'
                      type='name'
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} spacing={4}>
                    <text className={classes.title}>Nom de la promotion</text>

                    <Field
                      component={TextField}
                      name='name'
                      type='name'
                      fullWidth
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: 15,
                    }}
                  >
                    <text className={classes.title}>Du</text>
                    <div style={{ width: 20 }} />
                    <Field
                      component={TextField}
                      name='start_date'
                      type='date'
                      fullWidth
                    />
                    <div style={{ width: 20 }} />
                    <text className={classes.title}>Au</text>
                    <div style={{ width: 20 }} />
                    <Field
                      component={TextField}
                      name='end_date'
                      type='date'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ marginTop: 15 }}>
                    <text className={classes.title}>Message</text>
                    <MessageZone msg={msg} setMsg={setMsg} />
                  </Grid>

                  <Grid item xs={12} sm={12} spacing={4}>
                    <div style={{ display: 'flex' }}>
                      <text className={classes.title}>Taux de remise=</text>
                      <Field
                        component={TextField}
                        name='TauxRed'
                        type='number'
                      />
                      <span style={{ alignSelf: 'center' }}>%</span>
                    </div>
                  </Grid>

                  <div>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      spacing={4}
                      style={{ marginTop: 15 }}
                    >
                      <text
                        className={classes.title}
                        style={{
                          display: 'block',
                          marginBottom: 10,
                          textDecoration: 'underline',
                        }}
                      >
                        CHOIX POPULATION
                      </text>

                      <div>
                        <Field name='Tous' type='radio' value='both' />
                        <label>Tous</label>
                      </div>
                      <div>
                        <Field name='Tous' type='radio' value='female' />
                        <label>Femme uniquement</label>
                      </div>
                      <div>
                        <Field name='Tous' type='radio' value='mal' />
                        <label>Homme uniquement</label>
                      </div>
                      <span>* Choix unique</span>
                    </Grid>
                  </div>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    spacing={4}
                    style={{ marginTop: 15 }}
                  >
                    <text
                      className={classes.title}
                      style={{
                        textDecoration: 'underline',
                        display: 'block',
                        marginBottom: 10,
                      }}
                    >
                      CONE DE REPRÉSENTATION
                    </text>
                    <input
                      name='Icone'
                      type='file'
                      accept='image/*'
                      ref={fileRef}
                      style={{ display: 'none' }}
                    />

                    <Button
                      variant='outlined'
                      onClick={() => fileRef.current.click()}
                      style={{ borderRadius: 15 }}
                    >
                      Parcourir
                    </Button>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: 30,
                      justifyContent: 'center',
                    }}
                  >
                    <div style={{ width: 20 }} />
                    <Button
                      style={{
                        marginRight: 10,
                        borderColor: '#69d3be',
                        color: '#69d3be',
                        borderRadius: 10,
                      }}
                      type='submit'
                      disabled={!canSave}
                      variant='outlined'
                      color='primary'
                    >
                      {loading ? 'patientez svp' : 'Valider'}
                    </Button>

                    <Button
                      variant='outlined'
                      onClick={() => {
                        onClose();
                      }}
                      style={{
                        color: 'red',
                        borderColor: 'red',
                        borderRadius: 10,
                      }}
                    >
                      Annuler
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}
