import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  pic: {
    height: '80%',
    width: '40%',
    borderRadius: 5,
  },
  close: {
    position: 'absolute',
    top: '5rem',
    right: '2rem',
    cursor: 'pointer',
  },
}));

export default function ImagesBackdrop(props) {
  const { open, setOpen, url } = props;
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CloseIcon
          onClick={handleClose}
          className={classes.close}
          fontSize='large'
        />
        <img src={url} className={classes.pic} />
      </Backdrop>
    </div>
  );
}
