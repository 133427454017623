import { Check } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
    //maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    width: 230,
    height: 230,
    borderRadius: 125,
    marginTop: 20,
    marginBottom: 20,
  },
  userInfo: {
    // backgroundColor:"#18191a",
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& > p > strong': {
      // backgroundColor:'red',
      marginRight: 20,
    },
  },
  strong: {
    background: 'red',
  },
  block: {
    //color: "#e4e6eb",
    //backgroundColor: "#242526",
    //marginBottom: 12,
    marginLeft: 12,
    marginRight: 12,
    borderRadius: 5,
  },

  checkContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& > label': {
      cursor: 'pointer',
    },
  },
  form: {
    width: '100%',
  },
  title: {
    fontFamily: 'SF-Pro-Display-Regular',
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #949494',
  },
  button: {
    '&:hover': {
      background: '#fff',
    },
    marginBottom: 20,
  },
  signe: {
    height: 65,
    width: 65,
  },
  signeText: {
    fontFamily: 'SF-Pro-Display-Medium',
    fontSize: 16,
  },

  flex: {
    display: 'flex',
    gap: '2rem',
  },

  fifFlex: {
    flex: 0.5,
  },
}));
