import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { Typography, Button } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import InputField from '@material-ui/core/TextField';
import { TextField, Select } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Avatar from '@material-ui/core/Avatar';
import useStyles from "./styles";
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit'
import { addNewAdmin } from '../../slices/admins'
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

// Dialog title 
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function AdminForm(props) {

  var classes = useStyles();
  const [image, setImage] = React.useState(null);
  const { onClose, open } = props;
  const [canSave, setCanSave] = React.useState(false);

  const isNotEmpty = (currentValue) => currentValue != '';
  // ading info for add or update request
  const [addRequestStatus, setAddRequestStatus] = React.useState('idle')

  const fileToDataUri = (image) => {
    return new Promise((res) => {
      const reader = new FileReader();
      const { type, name, size } = image;
      reader.addEventListener('load', () => {
        res({
          base64: reader.result,
          name: name,
          type,
          size: size,
        })
      });
      reader.readAsDataURL(image);
    })
  }

  const uploadImage = async (e) => {
    const image = e.target.files[0];

    const base64 = await fileToDataUri(image);
    setImage(base64.base64)


  }
  const dispatch = useDispatch()
  // function for add to user 
  const onSaveAdminClicked = async (values) => {
    if (canSave) {
      try {
        setAddRequestStatus('pending');
        const resultAction = await dispatch(addNewAdmin(values));
        unwrapResult(resultAction);


      } catch (err) {
        console.error('Failed to save the admin: ', err)
      } finally {
        setAddRequestStatus('idle')
      }
    }
  }
  return (
    <>


      <Dialog onClose={() => onClose()} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={() => onClose()}>
          Ajouter Un Adminstrateur
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.addImage}>

            {image ? <Avatar alt='' src={image} className={classes.avatar} /> :
              <Avatar className={classes.avatar}>
                +
              </Avatar>
            }


            <input accept="image/*" className={classes.input} id="icon-button-file" type="file" onChange={uploadImage} />
            <label htmlFor="icon-button-file" className={classes.label}>
              <IconButton color="primary" aria-label="upload picture" component="span">
                <PhotoCameraIcon />
              </IconButton>
            </label>
          </div>
          <Formik
            initialValues={{ email: '', password: '', name: '', surname: '', role: 'Admin', phoneNumber: '' }}
            validate={values => {
              setCanSave([values.email, values.name, values.surname, values.password, values.phoneNumber, values.role].every(isNotEmpty) && addRequestStatus === 'idle')
              //console.log([values.email, values.firstname, values.lastname,values.password,values.phonenumber,values.role,values.username].every(isNotEmpty));
              const errors = {};
              if (!values.email) {
                errors.email = 'Required';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = 'Invalid email address';
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              // {...values,avatar:image}
              await onSaveAdminClicked({ ...values, avatar: image });
            }}
          >
            {({
              errors,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form className={classes.form}>
                <Grid container spacing={1}>

                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      name="email"
                      type="email"
                      label="Email"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      name="name"
                      type="name"
                      label="Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      name="surname"
                      type="surname"
                      label="Surname"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      name="password"
                      type="password"
                      label="Password"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      name="phoneNumber"
                      type="phoneNumber"
                      label="PhoneNumber"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={Select}
                      name="role"
                      type="role"
                      label="role"
                    >
                      <MenuItem value="Admin">Admin</MenuItem>
                      <MenuItem value='SuperAdmin'>SuperAdmin</MenuItem>
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Button className={classes.submit} type="submit"
                      disabled={!canSave}
                      fullWidth
                      variant="contained"
                      color="primary">
                      save
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>

        </DialogContent>

      </Dialog>


    </>
  );
}