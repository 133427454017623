import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    header: {
        fontFamily: 'SF-Pro-Display-Bold',
    },
    rule: {
        fontFamily: 'SF-Pro-Display-Regular',
        fontSize: 15
    },
    title: {
        fontFamily: 'SF-Pro-Display-Semibold',
        //marginTop:15
    },
    text: {
        fontFamily: 'SF-Pro-Display-Regular',
        fontSize: 17
        // marginTop:3
    },
    button: {
        height: 35,
        width: 35,
        borderRadius: 18,
        textTransform: "none",
        "&:active": {
            boxShadow: theme.customShadows.widgetWide,
        },
    },
    NotifBtn: {
        width: 270,
        borderColor: "#69d3be",
        color: '#69d3be',
        borderRadius: 10,
        marginTop: 20,
        fontFamily: 'SF-Pro-Display-Regular',
        fontSize: 13
    },
    box: {
        boxShadow: '0px 3px 6px #00000040',
        backgroundColor: 'white',
        borderRadius: 20,
        elevation: 5,
        marginTop: theme.spacing(20),
        marginBottom: theme.spacing(15),
        margin: 30,
        padding: 20,
        flexDirection: 'row',
        display: 'flex',
    },
    actionsBox: {
        backgroundColor: 'transparent',
        marginTop: theme.spacing(15),
        marginBottom: theme.spacing(15),
        margin: 30,
        padding: 20,
        flex: 1.5,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    MenuButton: {
        color: '#e21067',
        borderWidth: 0,
        textTransform: 'none',
        textDecoration: 'none',
        fontFamily: "SF-Pro-Display-Light",
        padding: 10,
        fontSize: 17
    }
}));
