import React from 'react';
import useStyles from './styles';
import PageTitle from '../../components/PageTitle/PageTitle';
import { User, arryQuestion } from './User';
import { Homme_Femme_Astro } from '../../constants/ComptaAstroTexts';
import { Femme_Homme_Astro } from '../../constants/ComptaAstroTexts';
import { Grid, Avatar } from '@material-ui/core';
import { IdsContext } from './IdsContext';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SubMenu from '../../components/SubMenu';
import UserForm from './UserForm';
import { Belier_Homme } from '../../constants/ComptaSexoTexts';
import { Taureau_Homme } from '../../constants/ComptaSexoTexts';
import { Gemeaux_Homme } from '../../constants/ComptaSexoTexts';
import { Cancer_Homme } from '../../constants/ComptaSexoTexts';
import { Vierge_Homme } from '../../constants/ComptaSexoTexts';
import { Capricorne_Homme } from '../../constants/ComptaSexoTexts';
import { Verseau_Homme } from '../../constants/ComptaSexoTexts';
import { Poissons_Homme } from '../../constants/ComptaSexoTexts';
import { Lion_Homme } from '../../constants/ComptaSexoTexts';
import { Scorpion_Homme } from '../../constants/ComptaSexoTexts';
import { Sagitaire_Homme } from '../../constants/ComptaSexoTexts';
import { Balance_Homme } from '../../constants/ComptaSexoTexts';

import { Belier_Femme } from '../../constants/ComptaSexoTexts';
import { Taureau_Femme } from '../../constants/ComptaSexoTexts';
import { Gemeaux_Femme } from '../../constants/ComptaSexoTexts';
import { Cancer_Femme } from '../../constants/ComptaSexoTexts';
import { Vierge_Femme } from '../../constants/ComptaSexoTexts';
import { Capricorne_Femme } from '../../constants/ComptaSexoTexts';
import { Verseau_Femme } from '../../constants/ComptaSexoTexts';
import { Poissons_Femme } from '../../constants/ComptaSexoTexts';
import { Lion_Femme } from '../../constants/ComptaSexoTexts';
import { Scorpion_Femme } from '../../constants/ComptaSexoTexts';
import { Sagitaire_Femme } from '../../constants/ComptaSexoTexts';
import { Balance_Femme } from '../../constants/ComptaSexoTexts';
import { Mars } from '../../constants/ComptaSexoTexts';
import { Venus } from '../../constants/ComptaSexoTexts';
import Divider from '@material-ui/core/Divider';

function LinearProgressWithLabel(props) {
  return (
    <Box display='flex' alignItems='center'>
      <Box width='100%' mr={1}>
        <LinearProgress variant='determinate' {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant='body2' color='textSecondary'>{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function Simulateur() {
  var classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [open, setOpen] = React.useState(false);
  const [signeU1, setSigneU1] = React.useState('');
  const [signeU2, setSigneU2] = React.useState('');
  const [slogan, setSlogan] = React.useState('');
  const [Lva, setLva] = React.useState('');
  const [Plus, setPlus] = React.useState('');
  const [Moins, setMoins] = React.useState('');
  const [craquer, setCraquer] = React.useState('');
  const [coach, setCoach] = React.useState('');

  const [context, setContext] = React.useState({
    id1: '',
    id2: '',
    user1: {},
    user2: {},
  });
  const [compa, setCompa] = React.useState({});
  const [fantPer, setFantPer] = React.useState(0);
  const [VenusMember, setVenusMember] = React.useState('');
  const [MarsMember, setMarsMember] = React.useState('');

  const transAstral = {
    Sun: 'Soleil',
    Moon: 'Lune',
    Mercury: 'Mercure',
    Venus: 'Vénus',
    Mars: 'Mars',
    Jupiter: 'Jupiter',
    Saturn: 'Saturne',
    Uranus: 'Uranus',
    Neptune: 'Neptune',
    Pluto: 'Pluton',
    1: 'Ascendant',
    2: 'Maison 2',
    3: 'Maison 3',
    4: 'Maison 4',
    5: 'Maison 5',
    6: 'Maison 6',
    7: 'Maison 7',
    8: 'Maison 8',
    9: 'Maison 9',
    10: 'MC',
    11: 'Maison 11',
    12: 'Maison 12',
    cp: 'Capricorne',
    aq: 'Verseau',
    pi: 'Poissons',
    ar: 'Bélier',
    ta: 'Taureau',
    ge: 'Gémeaux',
    cn: 'Cancer',
    le: 'Leo',
    vi: 'Vierge',
    li: 'Balance',
    sc: 'Scorpion',
    sa: 'Sagittaire',
  };
  React.useEffect(() => {
    if (context.id1 !== '' && context.id2 !== '') {
      const url = `${process.env.REACT_APP_API_URL}auth/filter/compa?id1=${context.id1}&id2=${context.id2}`;
      console.log(url);
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          setCompa(data);
        })
        .catch((error) => {
          alert(error);
        });
      var imageSigne1 =
        context.user1.astralSexe.signe === 'Bélier'
          ? require('../../assets/signes/Belier.png')
          : context.user1.astralSexe.signe === 'Taureau'
          ? require('../../assets/signes/Taureau.png')
          : context.user1.astralSexe.signe === 'Gémeaux'
          ? require('../../assets/signes/Gemeaux.png')
          : context.user1.astralSexe.signe === 'Cancer'
          ? require('../../assets/signes/Cancer.png')
          : context.user1.astralSexe.signe === 'Lion'
          ? require('../../assets/signes/Lion.png')
          : context.user1.astralSexe.signe === 'Vierge'
          ? require('../../assets/signes/Vierge.png')
          : context.user1.astralSexe.signe === 'Balance'
          ? require('../../assets/signes/Balance.png')
          : context.user1.astralSexe.signe === 'Scorpion'
          ? require('../../assets/signes/Scorpion.png')
          : context.user1.astralSexe.signe === 'Sagittaire'
          ? require('../../assets/signes/Sagittaire.png')
          : context.user1.astralSexe.signe === 'Capricorne'
          ? require('../../assets/signes/Capricorne.png')
          : context.user1.astralSexe.signe === 'Verseau'
          ? require('../../assets/signes/Verseau.png')
          : require('../../assets/signes/Poissons.png');
      setSigneU1(imageSigne1);
      var imageSigne2 =
        context.user2.astralSexe.signe === 'Bélier'
          ? require('../../assets/signes/Belier.png')
          : context.user2.astralSexe.signe === 'Taureau'
          ? require('../../assets/signes/Taureau.png')
          : context.user2.astralSexe.signe === 'Gémeaux'
          ? require('../../assets/signes/Gemeaux.png')
          : context.user2.astralSexe.signe === 'Cancer'
          ? require('../../assets/signes/Cancer.png')
          : context.user2.astralSexe.signe === 'Lion'
          ? require('../../assets/signes/Lion.png')
          : context.user2.astralSexe.signe === 'Vierge'
          ? require('../../assets/signes/Vierge.png')
          : context.user2.astralSexe.signe === 'Balance'
          ? require('../../assets/signes/Balance.png')
          : context.user2.astralSexe.signe === 'Scorpion'
          ? require('../../assets/signes/Scorpion.png')
          : context.user2.astralSexe.signe === 'Sagittaire'
          ? require('../../assets/signes/Sagittaire.png')
          : context.user2.astralSexe.signe === 'Capricorne'
          ? require('../../assets/signes/Capricorne.png')
          : context.user2.astralSexe.signe === 'Verseau'
          ? require('../../assets/signes/Verseau.png')
          : require('../../assets/signes/Poissons.png');
      setSigneU2(imageSigne2);
      var comptaTexts =
        context.user1.gender === 'mal' ? Homme_Femme_Astro : Femme_Homme_Astro;
      var slogan = '';
      var Lva = '';
      var Plus = '';
      var Moins = '';
      var craquer = '';
      var coach = '';
      switch (context.user1.astralSexe.signe) {
        case 'Bélier':
          switch (context.user2.astralSexe.signe) {
            case 'Bélier':
              slogan = comptaTexts.Belier_Belier.Slogan;
              Lva = comptaTexts.Belier_Belier.LVA;
              Plus = comptaTexts.Belier_Belier.Plus;
              Moins = comptaTexts.Belier_Belier.Moins;
              craquer = comptaTexts.Belier_Belier.Craquer;
              coach = comptaTexts.Belier_Belier.Coach;
              break;
            case 'Taureau':
              slogan = comptaTexts.Belier_Taureau.Slogan;
              Lva = comptaTexts.Belier_Taureau.LVA;
              Plus = comptaTexts.Belier_Taureau.Plus;
              Moins = comptaTexts.Belier_Taureau.Moins;
              craquer = comptaTexts.Belier_Taureau.Craquer;
              coach = comptaTexts.Belier_Taureau.Coach;
              break;
            case 'Gémeaux':
              slogan = comptaTexts.Belier_Gemeaux.Slogan;
              Lva = comptaTexts.Belier_Gemeaux.LVA;
              Plus = comptaTexts.Belier_Gemeaux.Plus;
              Moins = comptaTexts.Belier_Gemeaux.Moins;
              craquer = comptaTexts.Belier_Gemeaux.Craquer;
              coach = comptaTexts.Belier_Gemeaux.Coach;
              break;
            case 'Cancer':
              slogan = comptaTexts.Belier_Cancer.Slogan;
              Lva = comptaTexts.Belier_Cancer.LVA;
              Plus = comptaTexts.Belier_Cancer.Plus;
              Moins = comptaTexts.Belier_Cancer.Moins;
              craquer = comptaTexts.Belier_Cancer.Craquer;
              coach = comptaTexts.Belier_Cancer.Coach;
              break;
            case 'Lion':
              slogan = comptaTexts.Belier_Lion.Slogan;
              Lva = comptaTexts.Belier_Lion.LVA;
              Plus = comptaTexts.Belier_Lion.Plus;
              Moins = comptaTexts.Belier_Lion.Moins;
              craquer = comptaTexts.Belier_Lion.Craquer;
              coach = comptaTexts.Belier_Lion.Coach;
              break;
            case 'Vierge':
              slogan = comptaTexts.Belier_Vierge.Slogan;
              Lva = comptaTexts.Belier_Vierge.LVA;
              Plus = comptaTexts.Belier_Vierge.Plus;
              Moins = comptaTexts.Belier_Vierge.Moins;
              craquer = comptaTexts.Belier_Vierge.Craquer;
              coach = comptaTexts.Belier_Vierge.Coach;
              break;
            case 'Balance':
              slogan = comptaTexts.Belier_Balance.Slogan;
              Lva = comptaTexts.Belier_Balance.LVA;
              Plus = comptaTexts.Belier_Balance.Plus;
              Moins = comptaTexts.Belier_Balance.Moins;
              craquer = comptaTexts.Belier_Balance.Craquer;
              coach = comptaTexts.Belier_Balance.Coach;
              break;
            case 'Scorpion':
              slogan = comptaTexts.Belier_Scorpion.Slogan;
              Lva = comptaTexts.Belier_Scorpion.LVA;
              Plus = comptaTexts.Belier_Scorpion.Plus;
              Moins = comptaTexts.Belier_Scorpion.Moins;
              craquer = comptaTexts.Belier_Scorpion.Craquer;
              coach = comptaTexts.Belier_Scorpion.Coach;
              break;
            case 'Sagittaire':
              slogan = comptaTexts.Belier_Sagittaire.Slogan;
              Lva = comptaTexts.Belier_Sagittaire.LVA;
              Plus = comptaTexts.Belier_Sagittaire.Plus;
              Moins = comptaTexts.Belier_Sagittaire.Moins;
              craquer = comptaTexts.Belier_Sagittaire.Craquer;
              coach = comptaTexts.Belier_Sagittaire.Coach;
              break;
            case 'Capricorne':
              slogan = comptaTexts.Belier_Capricorne.Slogan;
              Lva = comptaTexts.Belier_Capricorne.LVA;
              Plus = comptaTexts.Belier_Capricorne.Plus;
              Moins = comptaTexts.Belier_Capricorne.Moins;
              craquer = comptaTexts.Belier_Capricorne.Craquer;
              coach = comptaTexts.Belier_Capricorne.Coach;
              break;
            case 'Verseau':
              slogan = comptaTexts.Belier_Verseau.Slogan;
              Lva = comptaTexts.Belier_Verseau.LVA;
              Plus = comptaTexts.Belier_Verseau.Plus;
              Moins = comptaTexts.Belier_Verseau.Moins;
              craquer = comptaTexts.Belier_Verseau.Craquer;
              coach = comptaTexts.Belier_Verseau.Coachbreak;
            case 'Poissons':
              slogan = comptaTexts.Belier_Poissons.Slogan;
              Lva = comptaTexts.Belier_Poissons.LVA;
              Plus = comptaTexts.Belier_Poissons.Plus;
              Moins = comptaTexts.Belier_Poissons.Moins;
              craquer = comptaTexts.Belier_Poissons.Craquer;
              coach = comptaTexts.Belier_Poissons.Coach;
              break;
            default:
              break;
          }
          break;
        case 'Taureau':
          switch (context.user2.astralSexe.signe) {
            case 'Bélier':
              slogan = comptaTexts.Taureau_Belier.Slogan;
              Lva = comptaTexts.Taureau_Belier.LVA;
              Plus = comptaTexts.Taureau_Belier.Plus;
              Moins = comptaTexts.Taureau_Belier.Moins;
              craquer = comptaTexts.Taureau_Belier.Craquer;
              coach = comptaTexts.Taureau_Belier.Coach;
              break;
            case 'Taureau':
              slogan = comptaTexts.Taureau_Taureau.Slogan;
              Lva = comptaTexts.Taureau_Taureau.LVA;
              Plus = comptaTexts.Taureau_Taureau.Plus;
              Moins = comptaTexts.Taureau_Taureau.Moins;
              craquer = comptaTexts.Taureau_Taureau.Craquer;
              coach = comptaTexts.Taureau_Taureau.Coach;
              break;
            case 'Gémeaux':
              slogan = comptaTexts.Taureau_Gemeaux.Slogan;
              Lva = comptaTexts.Taureau_Gemeaux.LVA;
              Plus = comptaTexts.Taureau_Gemeaux.Plus;
              Moins = comptaTexts.Taureau_Gemeaux.Moins;
              craquer = comptaTexts.Taureau_Gemeaux.Craquer;
              coach = comptaTexts.Taureau_Gemeaux.Coach;
              break;
            case 'Cancer':
              slogan = comptaTexts.Taureau_Cancer.Slogan;
              Lva = comptaTexts.Taureau_Cancer.LVA;
              Plus = comptaTexts.Taureau_Cancer.Plus;
              Moins = comptaTexts.Taureau_Cancer.Moins;
              craquer = comptaTexts.Taureau_Cancer.Craquer;
              coach = comptaTexts.Taureau_Cancer.Coach;
              break;
            case 'Lion':
              slogan = comptaTexts.Taureau_Lion.Slogan;
              Lva = comptaTexts.Taureau_Lion.LVA;
              Plus = comptaTexts.Taureau_Lion.Plus;
              Moins = comptaTexts.Taureau_Lion.Moins;
              craquer = comptaTexts.Taureau_Lion.Craquer;
              coach = comptaTexts.Taureau_Lion.Coach;
              break;
            case 'Vierge':
              slogan = comptaTexts.Taureau_Vierge.Slogan;
              Lva = comptaTexts.Taureau_Vierge.LVA;
              Plus = comptaTexts.Taureau_Vierge.Plus;
              Moins = comptaTexts.Taureau_Vierge.Moins;
              craquer = comptaTexts.Taureau_Vierge.Craquer;
              coach = comptaTexts.Taureau_Vierge.Coach;
              break;
            case 'Balance':
              slogan = comptaTexts.Taureau_Balance.Slogan;
              Lva = comptaTexts.Taureau_Balance.LVA;
              Plus = comptaTexts.Taureau_Balance.Plus;
              Moins = comptaTexts.Taureau_Balance.Moins;
              craquer = comptaTexts.Taureau_Balance.Craquer;
              coach = comptaTexts.Taureau_Balance.Coach;
              break;
            case 'Scorpion':
              slogan = comptaTexts.Taureau_Scorpion.Slogan;
              Lva = comptaTexts.Taureau_Scorpion.LVA;
              Plus = comptaTexts.Taureau_Scorpion.Plus;
              Moins = comptaTexts.Taureau_Scorpion.Moins;
              craquer = comptaTexts.Taureau_Scorpion.Craquer;
              coach = comptaTexts.Taureau_Scorpion.Coach;
              break;
            case 'Sagittaire':
              slogan = comptaTexts.Taureau_Sagittaire.Slogan;
              Lva = comptaTexts.Taureau_Sagittaire.LVA;
              Plus = comptaTexts.Taureau_Sagittaire.Plus;
              Moins = comptaTexts.Taureau_Sagittaire.Moins;
              craquer = comptaTexts.Taureau_Sagittaire.Craquer;
              coach = comptaTexts.Taureau_Sagittaire.Coach;
              break;
            case 'Capricorne':
              slogan = comptaTexts.Taureau_Capricorne.Slogan;
              Lva = comptaTexts.Taureau_Capricorne.LVA;
              Plus = comptaTexts.Taureau_Capricorne.Plus;
              Moins = comptaTexts.Taureau_Capricorne.Moins;
              craquer = comptaTexts.Taureau_Capricorne.Craquer;
              coach = comptaTexts.Taureau_Capricorne.Coach;
              break;
            case 'Verseau':
              slogan = comptaTexts.Taureau_Verseau.Slogan;
              Lva = comptaTexts.Taureau_Verseau.LVA;
              Plus = comptaTexts.Taureau_Verseau.Plus;
              Moins = comptaTexts.Taureau_Verseau.Moins;
              craquer = comptaTexts.Taureau_Verseau.Craquer;
              coach = comptaTexts.Taureau_Verseau.Coachbreak;
            case 'Poissons':
              slogan = comptaTexts.Taureau_Poissons.Slogan;
              Lva = comptaTexts.Taureau_Poissons.LVA;
              Plus = comptaTexts.Taureau_Poissons.Plus;
              Moins = comptaTexts.Taureau_Poissons.Moins;
              craquer = comptaTexts.Taureau_Poissons.Craquer;
              coach = comptaTexts.Taureau_Poissons.Coach;
              break;
            default:
              break;
          }
          break;
        case 'Gémeaux':
          switch (context.user2.astralSexe.signe) {
            case 'Bélier':
              slogan = comptaTexts.Gemeaux_Belier.Slogan;
              Lva = comptaTexts.Gemeaux_Belier.LVA;
              Plus = comptaTexts.Gemeaux_Belier.Plus;
              Moins = comptaTexts.Gemeaux_Belier.Moins;
              craquer = comptaTexts.Gemeaux_Belier.Craquer;
              coach = comptaTexts.Gemeaux_Belier.Coach;
              break;
            case 'Taureau':
              slogan = comptaTexts.Gemeaux_Taureau.Slogan;
              Lva = comptaTexts.Gemeaux_Taureau.LVA;
              Plus = comptaTexts.Gemeaux_Taureau.Plus;
              Moins = comptaTexts.Gemeaux_Taureau.Moins;
              craquer = comptaTexts.Gemeaux_Taureau.Craquer;
              coach = comptaTexts.Gemeaux_Taureau.Coach;
              break;
            case 'Gémeaux':
              slogan = comptaTexts.Gemeaux_Gemeaux.Slogan;
              Lva = comptaTexts.Gemeaux_Gemeaux.LVA;
              Plus = comptaTexts.Gemeaux_Gemeaux.Plus;
              Moins = comptaTexts.Gemeaux_Gemeaux.Moins;
              craquer = comptaTexts.Gemeaux_Gemeaux.Craquer;
              coach = comptaTexts.Gemeaux_Gemeaux.Coach;
              break;
            case 'Cancer':
              slogan = comptaTexts.Gemeaux_Cancer.Slogan;
              Lva = comptaTexts.Gemeaux_Cancer.LVA;
              Plus = comptaTexts.Gemeaux_Cancer.Plus;
              Moins = comptaTexts.Gemeaux_Cancer.Moins;
              craquer = comptaTexts.Gemeaux_Cancer.Craquer;
              coach = comptaTexts.Gemeaux_Cancer.Coach;
              break;
            case 'Lion':
              slogan = comptaTexts.Gemeaux_Lion.Slogan;
              Lva = comptaTexts.Gemeaux_Lion.LVA;
              Plus = comptaTexts.Gemeaux_Lion.Plus;
              Moins = comptaTexts.Gemeaux_Lion.Moins;
              craquer = comptaTexts.Gemeaux_Lion.Craquer;
              coach = comptaTexts.Gemeaux_Lion.Coach;
              break;
            case 'Vierge':
              slogan = comptaTexts.Gemeaux_Vierge.Slogan;
              Lva = comptaTexts.Gemeaux_Vierge.LVA;
              Plus = comptaTexts.Gemeaux_Vierge.Plus;
              Moins = comptaTexts.Gemeaux_Vierge.Moins;
              craquer = comptaTexts.Gemeaux_Vierge.Craquer;
              coach = comptaTexts.Gemeaux_Vierge.Coach;
              break;
            case 'Balance':
              slogan = comptaTexts.Gemeaux_Balance.Slogan;
              Lva = comptaTexts.Gemeaux_Balance.LVA;
              Plus = comptaTexts.Gemeaux_Balance.Plus;
              Moins = comptaTexts.Gemeaux_Balance.Moins;
              craquer = comptaTexts.Gemeaux_Balance.Craquer;
              coach = comptaTexts.Gemeaux_Balance.Coach;
              break;
            case 'Scorpion':
              slogan = comptaTexts.Gemeaux_Scorpion.Slogan;
              Lva = comptaTexts.Gemeaux_Scorpion.LVA;
              Plus = comptaTexts.Gemeaux_Scorpion.Plus;
              Moins = comptaTexts.Gemeaux_Scorpion.Moins;
              craquer = comptaTexts.Gemeaux_Scorpion.Craquer;
              coach = comptaTexts.Gemeaux_Scorpion.Coach;
              break;
            case 'Sagittaire':
              slogan = comptaTexts.Gemeaux_Sagittaire.Slogan;
              Lva = comptaTexts.Gemeaux_Sagittaire.LVA;
              Plus = comptaTexts.Gemeaux_Sagittaire.Plus;
              Moins = comptaTexts.Gemeaux_Sagittaire.Moins;
              craquer = comptaTexts.Gemeaux_Sagittaire.Craquer;
              coach = comptaTexts.Gemeaux_Sagittaire.Coach;
              break;
            case 'Capricorne':
              slogan = comptaTexts.Gemeaux_Capricorne.Slogan;
              Lva = comptaTexts.Gemeaux_Capricorne.LVA;
              Plus = comptaTexts.Gemeaux_Capricorne.Plus;
              Moins = comptaTexts.Gemeaux_Capricorne.Moins;
              craquer = comptaTexts.Gemeaux_Capricorne.Craquer;
              coach = comptaTexts.Gemeaux_Capricorne.Coach;
              break;
            case 'Verseau':
              slogan = comptaTexts.Gemeaux_Verseau.Slogan;
              Lva = comptaTexts.Gemeaux_Verseau.LVA;
              Plus = comptaTexts.Gemeaux_Verseau.Plus;
              Moins = comptaTexts.Gemeaux_Verseau.Moins;
              craquer = comptaTexts.Gemeaux_Verseau.Craquer;
              coach = comptaTexts.Gemeaux_Verseau.Coachbreak;
            case 'Poissons':
              slogan = comptaTexts.Gemeaux_Poissons.Slogan;
              Lva = comptaTexts.Gemeaux_Poissons.LVA;
              Plus = comptaTexts.Gemeaux_Poissons.Plus;
              Moins = comptaTexts.Gemeaux_Poissons.Moins;
              craquer = comptaTexts.Gemeaux_Poissons.Craquer;
              coach = comptaTexts.Gemeaux_Poissons.Coach;
              break;
            default:
              break;
          }
          break;
        case 'Cancer':
          switch (context.user2.astralSexe.signe) {
            case 'Bélier':
              slogan = comptaTexts.Cancer_Belier.Slogan;
              Lva = comptaTexts.Cancer_Belier.LVA;
              Plus = comptaTexts.Cancer_Belier.Plus;
              Moins = comptaTexts.Cancer_Belier.Moins;
              craquer = comptaTexts.Cancer_Belier.Craquer;
              coach = comptaTexts.Cancer_Belier.Coach;
              break;
            case 'Taureau':
              slogan = comptaTexts.Cancer_Taureau.Slogan;
              Lva = comptaTexts.Cancer_Taureau.LVA;
              Plus = comptaTexts.Cancer_Taureau.Plus;
              Moins = comptaTexts.Cancer_Taureau.Moins;
              craquer = comptaTexts.Cancer_Taureau.Craquer;
              coach = comptaTexts.Cancer_Taureau.Coach;
              break;
            case 'Gémeaux':
              slogan = comptaTexts.Cancer_Gemeaux.Slogan;
              Lva = comptaTexts.Cancer_Gemeaux.LVA;
              Plus = comptaTexts.Cancer_Gemeaux.Plus;
              Moins = comptaTexts.Cancer_Gemeaux.Moins;
              craquer = comptaTexts.Cancer_Gemeaux.Craquer;
              coach = comptaTexts.Cancer_Gemeaux.Coach;
              break;
            case 'Cancer':
              slogan = comptaTexts.Cancer_Cancer.Slogan;
              Lva = comptaTexts.Cancer_Cancer.LVA;
              Plus = comptaTexts.Cancer_Cancer.Plus;
              Moins = comptaTexts.Cancer_Cancer.Moins;
              craquer = comptaTexts.Cancer_Cancer.Craquer;
              coach = comptaTexts.Cancer_Cancer.Coach;
              break;
            case 'Lion':
              slogan = comptaTexts.Cancer_Lion.Slogan;
              Lva = comptaTexts.Cancer_Lion.LVA;
              Plus = comptaTexts.Cancer_Lion.Plus;
              Moins = comptaTexts.Cancer_Lion.Moins;
              craquer = comptaTexts.Cancer_Lion.Craquer;
              coach = comptaTexts.Cancer_Lion.Coach;
              break;
            case 'Vierge':
              slogan = comptaTexts.Cancer_Vierge.Slogan;
              Lva = comptaTexts.Cancer_Vierge.LVA;
              Plus = comptaTexts.Cancer_Vierge.Plus;
              Moins = comptaTexts.Cancer_Vierge.Moins;
              craquer = comptaTexts.Cancer_Vierge.Craquer;
              coach = comptaTexts.Cancer_Vierge.Coach;
              break;
            case 'Balance':
              slogan = comptaTexts.Cancer_Balance.Slogan;
              Lva = comptaTexts.Cancer_Balance.LVA;
              Plus = comptaTexts.Cancer_Balance.Plus;
              Moins = comptaTexts.Cancer_Balance.Moins;
              craquer = comptaTexts.Cancer_Balance.Craquer;
              coach = comptaTexts.Cancer_Balance.Coach;
              break;
            case 'Scorpion':
              slogan = comptaTexts.Cancer_Scorpion.Slogan;
              Lva = comptaTexts.Cancer_Scorpion.LVA;
              Plus = comptaTexts.Cancer_Scorpion.Plus;
              Moins = comptaTexts.Cancer_Scorpion.Moins;
              craquer = comptaTexts.Cancer_Scorpion.Craquer;
              coach = comptaTexts.Cancer_Scorpion.Coach;
              break;
            case 'Sagittaire':
              slogan = comptaTexts.Cancer_Sagittaire.Slogan;
              Lva = comptaTexts.Cancer_Sagittaire.LVA;
              Plus = comptaTexts.Cancer_Sagittaire.Plus;
              Moins = comptaTexts.Cancer_Sagittaire.Moins;
              craquer = comptaTexts.Cancer_Sagittaire.Craquer;
              coach = comptaTexts.Cancer_Sagittaire.Coach;
              break;
            case 'Capricorne':
              slogan = comptaTexts.Cancer_Capricorne.Slogan;
              Lva = comptaTexts.Cancer_Capricorne.LVA;
              Plus = comptaTexts.Cancer_Capricorne.Plus;
              Moins = comptaTexts.Cancer_Capricorne.Moins;
              craquer = comptaTexts.Cancer_Capricorne.Craquer;
              coach = comptaTexts.Cancer_Capricorne.Coach;
              break;
            case 'Verseau':
              slogan = comptaTexts.Cancer_Verseau.Slogan;
              Lva = comptaTexts.Cancer_Verseau.LVA;
              Plus = comptaTexts.Cancer_Verseau.Plus;
              Moins = comptaTexts.Cancer_Verseau.Moins;
              craquer = comptaTexts.Cancer_Verseau.Craquer;
              coach = comptaTexts.Cancer_Verseau.Coachbreak;
            case 'Poissons':
              slogan = comptaTexts.Cancer_Poissons.Slogan;
              Lva = comptaTexts.Cancer_Poissons.LVA;
              Plus = comptaTexts.Cancer_Poissons.Plus;
              Moins = comptaTexts.Cancer_Poissons.Moins;
              craquer = comptaTexts.Cancer_Poissons.Craquer;
              coach = comptaTexts.Cancer_Poissons.Coach;
              break;
            default:
              break;
          }
          break;
        case 'Lion':
          switch (context.user2.astralSexe.signe) {
            case 'Bélier':
              slogan = comptaTexts.Lion_Belier.Slogan;
              Lva = comptaTexts.Lion_Belier.LVA;
              Plus = comptaTexts.Lion_Belier.Plus;
              Moins = comptaTexts.Lion_Belier.Moins;
              craquer = comptaTexts.Lion_Belier.Craquer;
              coach = comptaTexts.Lion_Belier.Coach;
              break;
            case 'Taureau':
              slogan = comptaTexts.Lion_Taureau.Slogan;
              Lva = comptaTexts.Lion_Taureau.LVA;
              Plus = comptaTexts.Lion_Taureau.Plus;
              Moins = comptaTexts.Lion_Taureau.Moins;
              craquer = comptaTexts.Lion_Taureau.Craquer;
              coach = comptaTexts.Lion_Taureau.Coach;
              break;
            case 'Gémeaux':
              slogan = comptaTexts.Lion_Gemeaux.Slogan;
              Lva = comptaTexts.Lion_Gemeaux.LVA;
              Plus = comptaTexts.Lion_Gemeaux.Plus;
              Moins = comptaTexts.Lion_Gemeaux.Moins;
              craquer = comptaTexts.Lion_Gemeaux.Craquer;
              coach = comptaTexts.Lion_Gemeaux.Coach;
              break;
            case 'Cancer':
              slogan = comptaTexts.Lion_Cancer.Slogan;
              Lva = comptaTexts.Lion_Cancer.LVA;
              Plus = comptaTexts.Lion_Cancer.Plus;
              Moins = comptaTexts.Lion_Cancer.Moins;
              craquer = comptaTexts.Lion_Cancer.Craquer;
              coach = comptaTexts.Lion_Cancer.Coach;
              break;
            case 'Lion':
              slogan = comptaTexts.Lion_Lion.Slogan;
              Lva = comptaTexts.Lion_Lion.LVA;
              Plus = comptaTexts.Lion_Lion.Plus;
              Moins = comptaTexts.Lion_Lion.Moins;
              craquer = comptaTexts.Lion_Lion.Craquer;
              coach = comptaTexts.Lion_Lion.Coach;
              break;
            case 'Vierge':
              slogan = comptaTexts.Lion_Vierge.Slogan;
              Lva = comptaTexts.Lion_Vierge.LVA;
              Plus = comptaTexts.Lion_Vierge.Plus;
              Moins = comptaTexts.Lion_Vierge.Moins;
              craquer = comptaTexts.Lion_Vierge.Craquer;
              coach = comptaTexts.Lion_Vierge.Coach;
              break;
            case 'Balance':
              slogan = comptaTexts.Lion_Balance.Slogan;
              Lva = comptaTexts.Lion_Balance.LVA;
              Plus = comptaTexts.Lion_Balance.Plus;
              Moins = comptaTexts.Lion_Balance.Moins;
              craquer = comptaTexts.Lion_Balance.Craquer;
              coach = comptaTexts.Lion_Balance.Coach;
              break;
            case 'Scorpion':
              slogan = comptaTexts.Lion_Scorpion.Slogan;
              Lva = comptaTexts.Lion_Scorpion.LVA;
              Plus = comptaTexts.Lion_Scorpion.Plus;
              Moins = comptaTexts.Lion_Scorpion.Moins;
              craquer = comptaTexts.Lion_Scorpion.Craquer;
              coach = comptaTexts.Lion_Scorpion.Coach;
              break;
            case 'Sagittaire':
              slogan = comptaTexts.Lion_Sagittaire.Slogan;
              Lva = comptaTexts.Lion_Sagittaire.LVA;
              Plus = comptaTexts.Lion_Sagittaire.Plus;
              Moins = comptaTexts.Lion_Sagittaire.Moins;
              craquer = comptaTexts.Lion_Sagittaire.Craquer;
              coach = comptaTexts.Lion_Sagittaire.Coach;
              break;
            case 'Capricorne':
              slogan = comptaTexts.Lion_Capricorne.Slogan;
              Lva = comptaTexts.Lion_Capricorne.LVA;
              Plus = comptaTexts.Lion_Capricorne.Plus;
              Moins = comptaTexts.Lion_Capricorne.Moins;
              craquer = comptaTexts.Lion_Capricorne.Craquer;
              coach = comptaTexts.Lion_Capricorne.Coach;
              break;
            case 'Verseau':
              slogan = comptaTexts.Lion_Verseau.Slogan;
              Lva = comptaTexts.Lion_Verseau.LVA;
              Plus = comptaTexts.Lion_Verseau.Plus;
              Moins = comptaTexts.Lion_Verseau.Moins;
              craquer = comptaTexts.Lion_Verseau.Craquer;
              coach = comptaTexts.Lion_Verseau.Coachbreak;
            case 'Poissons':
              slogan = comptaTexts.Lion_Poissons.Slogan;
              Lva = comptaTexts.Lion_Poissons.LVA;
              Plus = comptaTexts.Lion_Poissons.Plus;
              Moins = comptaTexts.Lion_Poissons.Moins;
              craquer = comptaTexts.Lion_Poissons.Craquer;
              coach = comptaTexts.Lion_Poissons.Coach;
              break;
            default:
              break;
          }
          break;
        case 'Vierge':
          switch (context.user2.astralSexe.signe) {
            case 'Bélier':
              slogan = comptaTexts.Vierge_Belier.Slogan;
              Lva = comptaTexts.Vierge_Belier.LVA;
              Plus = comptaTexts.Vierge_Belier.Plus;
              Moins = comptaTexts.Vierge_Belier.Moins;
              craquer = comptaTexts.Vierge_Belier.Craquer;
              coach = comptaTexts.Vierge_Belier.Coach;
              break;
            case 'Taureau':
              slogan = comptaTexts.Vierge_Taureau.Slogan;
              Lva = comptaTexts.Vierge_Taureau.LVA;
              Plus = comptaTexts.Vierge_Taureau.Plus;
              Moins = comptaTexts.Vierge_Taureau.Moins;
              craquer = comptaTexts.Vierge_Taureau.Craquer;
              coach = comptaTexts.Vierge_Taureau.Coach;
              break;
            case 'Gémeaux':
              slogan = comptaTexts.Vierge_Gemeaux.Slogan;
              Lva = comptaTexts.Vierge_Gemeaux.LVA;
              Plus = comptaTexts.Vierge_Gemeaux.Plus;
              Moins = comptaTexts.Vierge_Gemeaux.Moins;
              craquer = comptaTexts.Vierge_Gemeaux.Craquer;
              coach = comptaTexts.Vierge_Gemeaux.Coach;
              break;
            case 'Cancer':
              slogan = comptaTexts.Vierge_Cancer.Slogan;
              Lva = comptaTexts.Vierge_Cancer.LVA;
              Plus = comptaTexts.Vierge_Cancer.Plus;
              Moins = comptaTexts.Vierge_Cancer.Moins;
              craquer = comptaTexts.Vierge_Cancer.Craquer;
              coach = comptaTexts.Vierge_Cancer.Coach;
              break;
            case 'Lion':
              slogan = comptaTexts.Vierge_Lion.Slogan;
              Lva = comptaTexts.Vierge_Lion.LVA;
              Plus = comptaTexts.Vierge_Lion.Plus;
              Moins = comptaTexts.Vierge_Lion.Moins;
              craquer = comptaTexts.Vierge_Lion.Craquer;
              coach = comptaTexts.Vierge_Lion.Coach;
              break;
            case 'Vierge':
              slogan = comptaTexts.Vierge_Vierge.Slogan;
              Lva = comptaTexts.Vierge_Vierge.LVA;
              Plus = comptaTexts.Vierge_Vierge.Plus;
              Moins = comptaTexts.Vierge_Vierge.Moins;
              craquer = comptaTexts.Vierge_Vierge.Craquer;
              coach = comptaTexts.Vierge_Vierge.Coach;
              break;
            case 'Balance':
              slogan = comptaTexts.Vierge_Balance.Slogan;
              Lva = comptaTexts.Vierge_Balance.LVA;
              Plus = comptaTexts.Vierge_Balance.Plus;
              Moins = comptaTexts.Vierge_Balance.Moins;
              craquer = comptaTexts.Vierge_Balance.Craquer;
              coach = comptaTexts.Vierge_Balance.Coach;
              break;
            case 'Scorpion':
              slogan = comptaTexts.Vierge_Scorpion.Slogan;
              Lva = comptaTexts.Vierge_Scorpion.LVA;
              Plus = comptaTexts.Vierge_Scorpion.Plus;
              Moins = comptaTexts.Vierge_Scorpion.Moins;
              craquer = comptaTexts.Vierge_Scorpion.Craquer;
              coach = comptaTexts.Vierge_Scorpion.Coach;
              break;
            case 'Sagittaire':
              slogan = comptaTexts.Vierge_Sagittaire.Slogan;
              Lva = comptaTexts.Vierge_Sagittaire.LVA;
              Plus = comptaTexts.Vierge_Sagittaire.Plus;
              Moins = comptaTexts.Vierge_Sagittaire.Moins;
              craquer = comptaTexts.Vierge_Sagittaire.Craquer;
              coach = comptaTexts.Vierge_Sagittaire.Coach;
              break;
            case 'Capricorne':
              slogan = comptaTexts.Vierge_Capricorne.Slogan;
              Lva = comptaTexts.Vierge_Capricorne.LVA;
              Plus = comptaTexts.Vierge_Capricorne.Plus;
              Moins = comptaTexts.Vierge_Capricorne.Moins;
              craquer = comptaTexts.Vierge_Capricorne.Craquer;
              coach = comptaTexts.Vierge_Capricorne.Coach;
              break;
            case 'Verseau':
              slogan = comptaTexts.Vierge_Verseau.Slogan;
              Lva = comptaTexts.Vierge_Verseau.LVA;
              Plus = comptaTexts.Vierge_Verseau.Plus;
              Moins = comptaTexts.Vierge_Verseau.Moins;
              craquer = comptaTexts.Vierge_Verseau.Craquer;
              coach = comptaTexts.Vierge_Verseau.Coachbreak;
            case 'Poissons':
              slogan = comptaTexts.Vierge_Poissons.Slogan;
              Lva = comptaTexts.Vierge_Poissons.LVA;
              Plus = comptaTexts.Vierge_Poissons.Plus;
              Moins = comptaTexts.Vierge_Poissons.Moins;
              craquer = comptaTexts.Vierge_Poissons.Craquer;
              coach = comptaTexts.Vierge_Poissons.Coach;
              break;
            default:
              break;
          }
          break;
        case 'Balance':
          switch (context.user2.astralSexe.signe) {
            case 'Bélier':
              slogan = comptaTexts.Balance_Belier.Slogan;
              Lva = comptaTexts.Balance_Belier.LVA;
              Plus = comptaTexts.Balance_Belier.Plus;
              Moins = comptaTexts.Balance_Belier.Moins;
              craquer = comptaTexts.Balance_Belier.Craquer;
              coach = comptaTexts.Balance_Belier.Coach;
              break;
            case 'Taureau':
              slogan = comptaTexts.Balance_Taureau.Slogan;
              Lva = comptaTexts.Balance_Taureau.LVA;
              Plus = comptaTexts.Balance_Taureau.Plus;
              Moins = comptaTexts.Balance_Taureau.Moins;
              craquer = comptaTexts.Balance_Taureau.Craquer;
              coach = comptaTexts.Balance_Taureau.Coach;
              break;
            case 'Gémeaux':
              slogan = comptaTexts.Balance_Gemeaux.Slogan;
              Lva = comptaTexts.Balance_Gemeaux.LVA;
              Plus = comptaTexts.Balance_Gemeaux.Plus;
              Moins = comptaTexts.Balance_Gemeaux.Moins;
              craquer = comptaTexts.Balance_Gemeaux.Craquer;
              coach = comptaTexts.Balance_Gemeaux.Coach;
              break;
            case 'Cancer':
              slogan = comptaTexts.Balance_Cancer.Slogan;
              Lva = comptaTexts.Balance_Cancer.LVA;
              Plus = comptaTexts.Balance_Cancer.Plus;
              Moins = comptaTexts.Balance_Cancer.Moins;
              craquer = comptaTexts.Balance_Cancer.Craquer;
              coach = comptaTexts.Balance_Cancer.Coach;
              break;
            case 'Lion':
              slogan = comptaTexts.Balance_Lion.Slogan;
              Lva = comptaTexts.Balance_Lion.LVA;
              Plus = comptaTexts.Balance_Lion.Plus;
              Moins = comptaTexts.Balance_Lion.Moins;
              craquer = comptaTexts.Balance_Lion.Craquer;
              coach = comptaTexts.Balance_Lion.Coach;
              break;
            case 'Vierge':
              slogan = comptaTexts.Balance_Vierge.Slogan;
              Lva = comptaTexts.Balance_Vierge.LVA;
              Plus = comptaTexts.Balance_Vierge.Plus;
              Moins = comptaTexts.Balance_Vierge.Moins;
              craquer = comptaTexts.Balance_Vierge.Craquer;
              coach = comptaTexts.Balance_Vierge.Coach;
              break;
            case 'Balance':
              slogan = comptaTexts.Balance_Balance.Slogan;
              Lva = comptaTexts.Balance_Balance.LVA;
              Plus = comptaTexts.Balance_Balance.Plus;
              Moins = comptaTexts.Balance_Balance.Moins;
              craquer = comptaTexts.Balance_Balance.Craquer;
              coach = comptaTexts.Balance_Balance.Coach;
              break;
            case 'Scorpion':
              slogan = comptaTexts.Balance_Scorpion.Slogan;
              Lva = comptaTexts.Balance_Scorpion.LVA;
              Plus = comptaTexts.Balance_Scorpion.Plus;
              Moins = comptaTexts.Balance_Scorpion.Moins;
              craquer = comptaTexts.Balance_Scorpion.Craquer;
              coach = comptaTexts.Balance_Scorpion.Coach;
              break;
            case 'Sagittaire':
              slogan = comptaTexts.Balance_Sagittaire.Slogan;
              Lva = comptaTexts.Balance_Sagittaire.LVA;
              Plus = comptaTexts.Balance_Sagittaire.Plus;
              Moins = comptaTexts.Balance_Sagittaire.Moins;
              craquer = comptaTexts.Balance_Sagittaire.Craquer;
              coach = comptaTexts.Balance_Sagittaire.Coach;
              break;
            case 'Capricorne':
              slogan = comptaTexts.Balance_Capricorne.Slogan;
              Lva = comptaTexts.Balance_Capricorne.LVA;
              Plus = comptaTexts.Balance_Capricorne.Plus;
              Moins = comptaTexts.Balance_Capricorne.Moins;
              craquer = comptaTexts.Balance_Capricorne.Craquer;
              coach = comptaTexts.Balance_Capricorne.Coach;
              break;
            case 'Verseau':
              slogan = comptaTexts.Balance_Verseau.Slogan;
              Lva = comptaTexts.Balance_Verseau.LVA;
              Plus = comptaTexts.Balance_Verseau.Plus;
              Moins = comptaTexts.Balance_Verseau.Moins;
              craquer = comptaTexts.Balance_Verseau.Craquer;
              coach = comptaTexts.Balance_Verseau.Coachbreak;
            case 'Poissons':
              slogan = comptaTexts.Balance_Poissons.Slogan;
              Lva = comptaTexts.Balance_Poissons.LVA;
              Plus = comptaTexts.Balance_Poissons.Plus;
              Moins = comptaTexts.Balance_Poissons.Moins;
              craquer = comptaTexts.Balance_Poissons.Craquer;
              coach = comptaTexts.Balance_Poissons.Coach;
              break;
            default:
              break;
          }
          break;
        case 'Scorpion':
          switch (context.user2.astralSexe.signe) {
            case 'Bélier':
              slogan = comptaTexts.Scorpion_Belier.Slogan;
              Lva = comptaTexts.Scorpion_Belier.LVA;
              Plus = comptaTexts.Scorpion_Belier.Plus;
              Moins = comptaTexts.Scorpion_Belier.Moins;
              craquer = comptaTexts.Scorpion_Belier.Craquer;
              coach = comptaTexts.Scorpion_Belier.Coach;
              break;
            case 'Taureau':
              slogan = comptaTexts.Scorpion_Taureau.Slogan;
              Lva = comptaTexts.Scorpion_Taureau.LVA;
              Plus = comptaTexts.Scorpion_Taureau.Plus;
              Moins = comptaTexts.Scorpion_Taureau.Moins;
              craquer = comptaTexts.Scorpion_Taureau.Craquer;
              coach = comptaTexts.Scorpion_Taureau.Coach;
              break;
            case 'Gémeaux':
              slogan = comptaTexts.Scorpion_Gemeaux.Slogan;
              Lva = comptaTexts.Scorpion_Gemeaux.LVA;
              Plus = comptaTexts.Scorpion_Gemeaux.Plus;
              Moins = comptaTexts.Scorpion_Gemeaux.Moins;
              craquer = comptaTexts.Scorpion_Gemeaux.Craquer;
              coach = comptaTexts.Scorpion_Gemeaux.Coach;
              break;
            case 'Cancer':
              slogan = comptaTexts.Scorpion_Cancer.Slogan;
              Lva = comptaTexts.Scorpion_Cancer.LVA;
              Plus = comptaTexts.Scorpion_Cancer.Plus;
              Moins = comptaTexts.Scorpion_Cancer.Moins;
              craquer = comptaTexts.Scorpion_Cancer.Craquer;
              coach = comptaTexts.Scorpion_Cancer.Coach;
              break;
            case 'Lion':
              slogan = comptaTexts.Scorpion_Lion.Slogan;
              Lva = comptaTexts.Scorpion_Lion.LVA;
              Plus = comptaTexts.Scorpion_Lion.Plus;
              Moins = comptaTexts.Scorpion_Lion.Moins;
              craquer = comptaTexts.Scorpion_Lion.Craquer;
              coach = comptaTexts.Scorpion_Lion.Coach;
              break;
            case 'Vierge':
              slogan = comptaTexts.Scorpion_Vierge.Slogan;
              Lva = comptaTexts.Scorpion_Vierge.LVA;
              Plus = comptaTexts.Scorpion_Vierge.Plus;
              Moins = comptaTexts.Scorpion_Vierge.Moins;
              craquer = comptaTexts.Scorpion_Vierge.Craquer;
              coach = comptaTexts.Scorpion_Vierge.Coach;
              break;
            case 'Balance':
              slogan = comptaTexts.Scorpion_Balance.Slogan;
              Lva = comptaTexts.Scorpion_Balance.LVA;
              Plus = comptaTexts.Scorpion_Balance.Plus;
              Moins = comptaTexts.Scorpion_Balance.Moins;
              craquer = comptaTexts.Scorpion_Balance.Craquer;
              coach = comptaTexts.Scorpion_Balance.Coach;
              break;
            case 'Scorpion':
              slogan = comptaTexts.Scorpion_Scorpion.Slogan;
              Lva = comptaTexts.Scorpion_Scorpion.LVA;
              Plus = comptaTexts.Scorpion_Scorpion.Plus;
              Moins = comptaTexts.Scorpion_Scorpion.Moins;
              craquer = comptaTexts.Scorpion_Scorpion.Craquer;
              coach = comptaTexts.Scorpion_Scorpion.Coach;
              break;
            case 'Sagittaire':
              slogan = comptaTexts.Scorpion_Sagittaire.Slogan;
              Lva = comptaTexts.Scorpion_Sagittaire.LVA;
              Plus = comptaTexts.Scorpion_Sagittaire.Plus;
              Moins = comptaTexts.Scorpion_Sagittaire.Moins;
              craquer = comptaTexts.Scorpion_Sagittaire.Craquer;
              coach = comptaTexts.Scorpion_Sagittaire.Coach;
              break;
            case 'Capricorne':
              slogan = comptaTexts.Scorpion_Capricorne.Slogan;
              Lva = comptaTexts.Scorpion_Capricorne.LVA;
              Plus = comptaTexts.Scorpion_Capricorne.Plus;
              Moins = comptaTexts.Scorpion_Capricorne.Moins;
              craquer = comptaTexts.Scorpion_Capricorne.Craquer;
              coach = comptaTexts.Scorpion_Capricorne.Coach;
              break;
            case 'Verseau':
              slogan = comptaTexts.Scorpion_Verseau.Slogan;
              Lva = comptaTexts.Scorpion_Verseau.LVA;
              Plus = comptaTexts.Scorpion_Verseau.Plus;
              Moins = comptaTexts.Scorpion_Verseau.Moins;
              craquer = comptaTexts.Scorpion_Verseau.Craquer;
              coach = comptaTexts.Scorpion_Verseau.Coachbreak;
            case 'Poissons':
              slogan = comptaTexts.Scorpion_Poissons.Slogan;
              Lva = comptaTexts.Scorpion_Poissons.LVA;
              Plus = comptaTexts.Scorpion_Poissons.Plus;
              Moins = comptaTexts.Scorpion_Poissons.Moins;
              craquer = comptaTexts.Scorpion_Poissons.Craquer;
              coach = comptaTexts.Scorpion_Poissons.Coach;
              break;
            default:
              break;
          }
          break;
        case 'Sagittaire':
          switch (context.user2.astralSexe.signe) {
            case 'Bélier':
              slogan = comptaTexts.Sagittaire_Belier.Slogan;
              Lva = comptaTexts.Sagittaire_Belier.LVA;
              Plus = comptaTexts.Sagittaire_Belier.Plus;
              Moins = comptaTexts.Sagittaire_Belier.Moins;
              craquer = comptaTexts.Sagittaire_Belier.Craquer;
              coach = comptaTexts.Sagittaire_Belier.Coach;
              break;
            case 'Taureau':
              slogan = comptaTexts.Sagittaire_Taureau.Slogan;
              Lva = comptaTexts.Sagittaire_Taureau.LVA;
              Plus = comptaTexts.Sagittaire_Taureau.Plus;
              Moins = comptaTexts.Sagittaire_Taureau.Moins;
              craquer = comptaTexts.Sagittaire_Taureau.Craquer;
              coach = comptaTexts.Sagittaire_Taureau.Coach;
              break;
            case 'Gémeaux':
              slogan = comptaTexts.Sagittaire_Gemeaux.Slogan;
              Lva = comptaTexts.Sagittaire_Gemeaux.LVA;
              Plus = comptaTexts.Sagittaire_Gemeaux.Plus;
              Moins = comptaTexts.Sagittaire_Gemeaux.Moins;
              craquer = comptaTexts.Sagittaire_Gemeaux.Craquer;
              coach = comptaTexts.Sagittaire_Gemeaux.Coach;
              break;
            case 'Cancer':
              slogan = comptaTexts.Sagittaire_Cancer.Slogan;
              Lva = comptaTexts.Sagittaire_Cancer.LVA;
              Plus = comptaTexts.Sagittaire_Cancer.Plus;
              Moins = comptaTexts.Sagittaire_Cancer.Moins;
              craquer = comptaTexts.Sagittaire_Cancer.Craquer;
              coach = comptaTexts.Sagittaire_Cancer.Coach;
              break;
            case 'Lion':
              slogan = comptaTexts.Sagittaire_Lion.Slogan;
              Lva = comptaTexts.Sagittaire_Lion.LVA;
              Plus = comptaTexts.Sagittaire_Lion.Plus;
              Moins = comptaTexts.Sagittaire_Lion.Moins;
              craquer = comptaTexts.Sagittaire_Lion.Craquer;
              coach = comptaTexts.Sagittaire_Lion.Coach;
              break;
            case 'Vierge':
              slogan = comptaTexts.Sagittaire_Vierge.Slogan;
              Lva = comptaTexts.Sagittaire_Vierge.LVA;
              Plus = comptaTexts.Sagittaire_Vierge.Plus;
              Moins = comptaTexts.Sagittaire_Vierge.Moins;
              craquer = comptaTexts.Sagittaire_Vierge.Craquer;
              coach = comptaTexts.Sagittaire_Vierge.Coach;
              break;
            case 'Balance':
              slogan = comptaTexts.Sagittaire_Balance.Slogan;
              Lva = comptaTexts.Sagittaire_Balance.LVA;
              Plus = comptaTexts.Sagittaire_Balance.Plus;
              Moins = comptaTexts.Sagittaire_Balance.Moins;
              craquer = comptaTexts.Sagittaire_Balance.Craquer;
              coach = comptaTexts.Sagittaire_Balance.Coach;
              break;
            case 'Scorpion':
              slogan = comptaTexts.Sagittaire_Scorpion.Slogan;
              Lva = comptaTexts.Sagittaire_Scorpion.LVA;
              Plus = comptaTexts.Sagittaire_Scorpion.Plus;
              Moins = comptaTexts.Sagittaire_Scorpion.Moins;
              craquer = comptaTexts.Sagittaire_Scorpion.Craquer;
              coach = comptaTexts.Sagittaire_Scorpion.Coach;
              break;
            case 'Sagittaire':
              slogan = comptaTexts.Sagittaire_Sagittaire.Slogan;
              Lva = comptaTexts.Sagittaire_Sagittaire.LVA;
              Plus = comptaTexts.Sagittaire_Sagittaire.Plus;
              Moins = comptaTexts.Sagittaire_Sagittaire.Moins;
              craquer = comptaTexts.Sagittaire_Sagittaire.Craquer;
              coach = comptaTexts.Sagittaire_Sagittaire.Coach;
              break;
            case 'Capricorne':
              slogan = comptaTexts.Sagittaire_Capricorne.Slogan;
              Lva = comptaTexts.Sagittaire_Capricorne.LVA;
              Plus = comptaTexts.Sagittaire_Capricorne.Plus;
              Moins = comptaTexts.Sagittaire_Capricorne.Moins;
              craquer = comptaTexts.Sagittaire_Capricorne.Craquer;
              coach = comptaTexts.Sagittaire_Capricorne.Coach;
              break;
            case 'Verseau':
              slogan = comptaTexts.Sagittaire_Verseau.Slogan;
              Lva = comptaTexts.Sagittaire_Verseau.LVA;
              Plus = comptaTexts.Sagittaire_Verseau.Plus;
              Moins = comptaTexts.Sagittaire_Verseau.Moins;
              craquer = comptaTexts.Sagittaire_Verseau.Craquer;
              coach = comptaTexts.Sagittaire_Verseau.Coachbreak;
            case 'Poissons':
              slogan = comptaTexts.Sagittaire_Poissons.Slogan;
              Lva = comptaTexts.Sagittaire_Poissons.LVA;
              Plus = comptaTexts.Sagittaire_Poissons.Plus;
              Moins = comptaTexts.Sagittaire_Poissons.Moins;
              craquer = comptaTexts.Sagittaire_Poissons.Craquer;
              coach = comptaTexts.Sagittaire_Poissons.Coach;
              break;
            default:
              break;
          }
          break;
        case 'Capricorne':
          switch (context.user2.astralSexe.signe) {
            case 'Bélier':
              slogan = comptaTexts.Capricorne_Belier.Slogan;
              Lva = comptaTexts.Capricorne_Belier.LVA;
              Plus = comptaTexts.Capricorne_Belier.Plus;
              Moins = comptaTexts.Capricorne_Belier.Moins;
              craquer = comptaTexts.Capricorne_Belier.Craquer;
              coach = comptaTexts.Capricorne_Belier.Coach;
              break;
            case 'Taureau':
              slogan = comptaTexts.Capricorne_Taureau.Slogan;
              Lva = comptaTexts.Capricorne_Taureau.LVA;
              Plus = comptaTexts.Capricorne_Taureau.Plus;
              Moins = comptaTexts.Capricorne_Taureau.Moins;
              craquer = comptaTexts.Capricorne_Taureau.Craquer;
              coach = comptaTexts.Capricorne_Taureau.Coach;
              break;
            case 'Gémeaux':
              slogan = comptaTexts.Capricorne_Gemeaux.Slogan;
              Lva = comptaTexts.Capricorne_Gemeaux.LVA;
              Plus = comptaTexts.Capricorne_Gemeaux.Plus;
              Moins = comptaTexts.Capricorne_Gemeaux.Moins;
              craquer = comptaTexts.Capricorne_Gemeaux.Craquer;
              coach = comptaTexts.Capricorne_Gemeaux.Coach;
              break;
            case 'Cancer':
              slogan = comptaTexts.Capricorne_Cancer.Slogan;
              Lva = comptaTexts.Capricorne_Cancer.LVA;
              Plus = comptaTexts.Capricorne_Cancer.Plus;
              Moins = comptaTexts.Capricorne_Cancer.Moins;
              craquer = comptaTexts.Capricorne_Cancer.Craquer;
              coach = comptaTexts.Capricorne_Cancer.Coach;
              break;
            case 'Lion':
              slogan = comptaTexts.Capricorne_Lion.Slogan;
              Lva = comptaTexts.Capricorne_Lion.LVA;
              Plus = comptaTexts.Capricorne_Lion.Plus;
              Moins = comptaTexts.Capricorne_Lion.Moins;
              craquer = comptaTexts.Capricorne_Lion.Craquer;
              coach = comptaTexts.Capricorne_Lion.Coach;
              break;
            case 'Vierge':
              slogan = comptaTexts.Capricorne_Vierge.Slogan;
              Lva = comptaTexts.Capricorne_Vierge.LVA;
              Plus = comptaTexts.Capricorne_Vierge.Plus;
              Moins = comptaTexts.Capricorne_Vierge.Moins;
              craquer = comptaTexts.Capricorne_Vierge.Craquer;
              coach = comptaTexts.Capricorne_Vierge.Coach;
              break;
            case 'Balance':
              slogan = comptaTexts.Capricorne_Balance.Slogan;
              Lva = comptaTexts.Capricorne_Balance.LVA;
              Plus = comptaTexts.Capricorne_Balance.Plus;
              Moins = comptaTexts.Capricorne_Balance.Moins;
              craquer = comptaTexts.Capricorne_Balance.Craquer;
              coach = comptaTexts.Capricorne_Balance.Coach;
              break;
            case 'Scorpion':
              slogan = comptaTexts.Capricorne_Scorpion.Slogan;
              Lva = comptaTexts.Capricorne_Scorpion.LVA;
              Plus = comptaTexts.Capricorne_Scorpion.Plus;
              Moins = comptaTexts.Capricorne_Scorpion.Moins;
              craquer = comptaTexts.Capricorne_Scorpion.Craquer;
              coach = comptaTexts.Capricorne_Scorpion.Coach;
              break;
            case 'Sagittaire':
              slogan = comptaTexts.Capricorne_Sagittaire.Slogan;
              Lva = comptaTexts.Capricorne_Sagittaire.LVA;
              Plus = comptaTexts.Capricorne_Sagittaire.Plus;
              Moins = comptaTexts.Capricorne_Sagittaire.Moins;
              craquer = comptaTexts.Capricorne_Sagittaire.Craquer;
              coach = comptaTexts.Capricorne_Sagittaire.Coach;
              break;
            case 'Capricorne':
              slogan = comptaTexts.Capricorne_Capricorne.Slogan;
              Lva = comptaTexts.Capricorne_Capricorne.LVA;
              Plus = comptaTexts.Capricorne_Capricorne.Plus;
              Moins = comptaTexts.Capricorne_Capricorne.Moins;
              craquer = comptaTexts.Capricorne_Capricorne.Craquer;
              coach = comptaTexts.Capricorne_Capricorne.Coach;
              break;
            case 'Verseau':
              slogan = comptaTexts.Capricorne_Verseau.Slogan;
              Lva = comptaTexts.Capricorne_Verseau.LVA;
              Plus = comptaTexts.Capricorne_Verseau.Plus;
              Moins = comptaTexts.Capricorne_Verseau.Moins;
              craquer = comptaTexts.Capricorne_Verseau.Craquer;
              coach = comptaTexts.Capricorne_Verseau.Coachbreak;
            case 'Poissons':
              slogan = comptaTexts.Capricorne_Poissons.Slogan;
              Lva = comptaTexts.Capricorne_Poissons.LVA;
              Plus = comptaTexts.Capricorne_Poissons.Plus;
              Moins = comptaTexts.Capricorne_Poissons.Moins;
              craquer = comptaTexts.Capricorne_Poissons.Craquer;
              coach = comptaTexts.Capricorne_Poissons.Coach;
              break;
            default:
              break;
          }
          break;
        case 'Verseau':
          switch (context.user2.astralSexe.signe) {
            case 'Bélier':
              slogan = comptaTexts.Verseau_Belier.Slogan;
              Lva = comptaTexts.Verseau_Belier.LVA;
              Plus = comptaTexts.Verseau_Belier.Plus;
              Moins = comptaTexts.Verseau_Belier.Moins;
              craquer = comptaTexts.Verseau_Belier.Craquer;
              coach = comptaTexts.Verseau_Belier.Coach;
              break;
            case 'Taureau':
              slogan = comptaTexts.Verseau_Taureau.Slogan;
              Lva = comptaTexts.Verseau_Taureau.LVA;
              Plus = comptaTexts.Verseau_Taureau.Plus;
              Moins = comptaTexts.Verseau_Taureau.Moins;
              craquer = comptaTexts.Verseau_Taureau.Craquer;
              coach = comptaTexts.Verseau_Taureau.Coach;
              break;
            case 'Gémeaux':
              slogan = comptaTexts.Verseau_Gemeaux.Slogan;
              Lva = comptaTexts.Verseau_Gemeaux.LVA;
              Plus = comptaTexts.Verseau_Gemeaux.Plus;
              Moins = comptaTexts.Verseau_Gemeaux.Moins;
              craquer = comptaTexts.Verseau_Gemeaux.Craquer;
              coach = comptaTexts.Verseau_Gemeaux.Coach;
              break;
            case 'Cancer':
              slogan = comptaTexts.Verseau_Cancer.Slogan;
              Lva = comptaTexts.Verseau_Cancer.LVA;
              Plus = comptaTexts.Verseau_Cancer.Plus;
              Moins = comptaTexts.Verseau_Cancer.Moins;
              craquer = comptaTexts.Verseau_Cancer.Craquer;
              coach = comptaTexts.Verseau_Cancer.Coach;
              break;
            case 'Lion':
              slogan = comptaTexts.Verseau_Lion.Slogan;
              Lva = comptaTexts.Verseau_Lion.LVA;
              Plus = comptaTexts.Verseau_Lion.Plus;
              Moins = comptaTexts.Verseau_Lion.Moins;
              craquer = comptaTexts.Verseau_Lion.Craquer;
              coach = comptaTexts.Verseau_Lion.Coach;
              break;
            case 'Vierge':
              slogan = comptaTexts.Verseau_Vierge.Slogan;
              Lva = comptaTexts.Verseau_Vierge.LVA;
              Plus = comptaTexts.Verseau_Vierge.Plus;
              Moins = comptaTexts.Verseau_Vierge.Moins;
              craquer = comptaTexts.Verseau_Vierge.Craquer;
              coach = comptaTexts.Verseau_Vierge.Coach;
              break;
            case 'Balance':
              slogan = comptaTexts.Verseau_Balance.Slogan;
              Lva = comptaTexts.Verseau_Balance.LVA;
              Plus = comptaTexts.Verseau_Balance.Plus;
              Moins = comptaTexts.Verseau_Balance.Moins;
              craquer = comptaTexts.Verseau_Balance.Craquer;
              coach = comptaTexts.Verseau_Balance.Coach;
              break;
            case 'Scorpion':
              slogan = comptaTexts.Verseau_Scorpion.Slogan;
              Lva = comptaTexts.Verseau_Scorpion.LVA;
              Plus = comptaTexts.Verseau_Scorpion.Plus;
              Moins = comptaTexts.Verseau_Scorpion.Moins;
              craquer = comptaTexts.Verseau_Scorpion.Craquer;
              coach = comptaTexts.Verseau_Scorpion.Coach;
              break;
            case 'Sagittaire':
              slogan = comptaTexts.Verseau_Sagittaire.Slogan;
              Lva = comptaTexts.Verseau_Sagittaire.LVA;
              Plus = comptaTexts.Verseau_Sagittaire.Plus;
              Moins = comptaTexts.Verseau_Sagittaire.Moins;
              craquer = comptaTexts.Verseau_Sagittaire.Craquer;
              coach = comptaTexts.Verseau_Sagittaire.Coach;
              break;
            case 'Capricorne':
              slogan = comptaTexts.Verseau_Capricorne.Slogan;
              Lva = comptaTexts.Verseau_Capricorne.LVA;
              Plus = comptaTexts.Verseau_Capricorne.Plus;
              Moins = comptaTexts.Verseau_Capricorne.Moins;
              craquer = comptaTexts.Verseau_Capricorne.Craquer;
              coach = comptaTexts.Verseau_Capricorne.Coach;
              break;
            case 'Verseau':
              slogan = comptaTexts.Verseau_Verseau.Slogan;
              Lva = comptaTexts.Verseau_Verseau.LVA;
              Plus = comptaTexts.Verseau_Verseau.Plus;
              Moins = comptaTexts.Verseau_Verseau.Moins;
              craquer = comptaTexts.Verseau_Verseau.Craquer;
              coach = comptaTexts.Verseau_Verseau.Coachbreak;
            case 'Poissons':
              slogan = comptaTexts.Verseau_Poissons.Slogan;
              Lva = comptaTexts.Verseau_Poissons.LVA;
              Plus = comptaTexts.Verseau_Poissons.Plus;
              Moins = comptaTexts.Verseau_Poissons.Moins;
              craquer = comptaTexts.Verseau_Poissons.Craquer;
              coach = comptaTexts.Verseau_Poissons.Coach;
              break;
            default:
              break;
          }
          break;
        case 'Poissons':
          switch (context.user2.astralSexe.signe) {
            case 'Bélier':
              slogan = comptaTexts.Poissons_Belier.Slogan;
              Lva = comptaTexts.Poissons_Belier.LVA;
              Plus = comptaTexts.Poissons_Belier.Plus;
              Moins = comptaTexts.Poissons_Belier.Moins;
              craquer = comptaTexts.Poissons_Belier.Craquer;
              coach = comptaTexts.Poissons_Belier.Coach;
              break;
            case 'Taureau':
              slogan = comptaTexts.Poissons_Taureau.Slogan;
              Lva = comptaTexts.Poissons_Taureau.LVA;
              Plus = comptaTexts.Poissons_Taureau.Plus;
              Moins = comptaTexts.Poissons_Taureau.Moins;
              craquer = comptaTexts.Poissons_Taureau.Craquer;
              coach = comptaTexts.Poissons_Taureau.Coach;
              break;
            case 'Gémeaux':
              slogan = comptaTexts.Poissons_Gemeaux.Slogan;
              Lva = comptaTexts.Poissons_Gemeaux.LVA;
              Plus = comptaTexts.Poissons_Gemeaux.Plus;
              Moins = comptaTexts.Poissons_Gemeaux.Moins;
              craquer = comptaTexts.Poissons_Gemeaux.Craquer;
              coach = comptaTexts.Poissons_Gemeaux.Coach;
              break;
            case 'Cancer':
              slogan = comptaTexts.Poissons_Cancer.Slogan;
              Lva = comptaTexts.Poissons_Cancer.LVA;
              Plus = comptaTexts.Poissons_Cancer.Plus;
              Moins = comptaTexts.Poissons_Cancer.Moins;
              craquer = comptaTexts.Poissons_Cancer.Craquer;
              coach = comptaTexts.Poissons_Cancer.Coach;
              break;
            case 'Lion':
              slogan = comptaTexts.Poissons_Lion.Slogan;
              Lva = comptaTexts.Poissons_Lion.LVA;
              Plus = comptaTexts.Poissons_Lion.Plus;
              Moins = comptaTexts.Poissons_Lion.Moins;
              craquer = comptaTexts.Poissons_Lion.Craquer;
              coach = comptaTexts.Poissons_Lion.Coach;
              break;
            case 'Vierge':
              slogan = comptaTexts.Poissons_Vierge.Slogan;
              Lva = comptaTexts.Poissons_Vierge.LVA;
              Plus = comptaTexts.Poissons_Vierge.Plus;
              Moins = comptaTexts.Poissons_Vierge.Moins;
              craquer = comptaTexts.Poissons_Vierge.Craquer;
              coach = comptaTexts.Poissons_Vierge.Coach;
              break;
            case 'Balance':
              slogan = comptaTexts.Poissons_Balance.Slogan;
              Lva = comptaTexts.Poissons_Balance.LVA;
              Plus = comptaTexts.Poissons_Balance.Plus;
              Moins = comptaTexts.Poissons_Balance.Moins;
              craquer = comptaTexts.Poissons_Balance.Craquer;
              coach = comptaTexts.Poissons_Balance.Coach;
              break;
            case 'Scorpion':
              slogan = comptaTexts.Poissons_Scorpion.Slogan;
              Lva = comptaTexts.Poissons_Scorpion.LVA;
              Plus = comptaTexts.Poissons_Scorpion.Plus;
              Moins = comptaTexts.Poissons_Scorpion.Moins;
              craquer = comptaTexts.Poissons_Scorpion.Craquer;
              coach = comptaTexts.Poissons_Scorpion.Coach;
              break;
            case 'Sagittaire':
              slogan = comptaTexts.Poissons_Sagittaire.Slogan;
              Lva = comptaTexts.Poissons_Sagittaire.LVA;
              Plus = comptaTexts.Poissons_Sagittaire.Plus;
              Moins = comptaTexts.Poissons_Sagittaire.Moins;
              craquer = comptaTexts.Poissons_Sagittaire.Craquer;
              coach = comptaTexts.Poissons_Sagittaire.Coach;
              break;
            case 'Capricorne':
              slogan = comptaTexts.Poissons_Capricorne.Slogan;
              Lva = comptaTexts.Poissons_Capricorne.LVA;
              Plus = comptaTexts.Poissons_Capricorne.Plus;
              Moins = comptaTexts.Poissons_Capricorne.Moins;
              craquer = comptaTexts.Poissons_Capricorne.Craquer;
              coach = comptaTexts.Poissons_Capricorne.Coach;
              break;
            case 'Verseau':
              slogan = comptaTexts.Poissons_Verseau.Slogan;
              Lva = comptaTexts.Poissons_Verseau.LVA;
              Plus = comptaTexts.Poissons_Verseau.Plus;
              Moins = comptaTexts.Poissons_Verseau.Moins;
              craquer = comptaTexts.Poissons_Verseau.Craquer;
              coach = comptaTexts.Poissons_Verseau.Coachbreak;
            case 'Poissons':
              slogan = comptaTexts.Poissons_Poissons.Slogan;
              Lva = comptaTexts.Poissons_Poissons.LVA;
              Plus = comptaTexts.Poissons_Poissons.Plus;
              Moins = comptaTexts.Poissons_Poissons.Moins;
              craquer = comptaTexts.Poissons_Poissons.Craquer;
              coach = comptaTexts.Poissons_Poissons.Coach;
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      setSlogan(slogan);
      setLva(Lva);
      setPlus(Plus);
      setMoins(Moins);
      setCraquer(craquer);
      setCoach(coach);
      setVenusMember(transAstral[context.user2.themeAstral.plantes.Venus]);
      setMarsMember(transAstral[context.user2.themeAstral.plantes.Mars]);
    }
  }, [context.id1, context.id2]);

  return (
    <IdsContext.Provider value={[context, setContext]}>
      <SubMenu active='3' />
      <PageTitle
        title='Compatibilité'
        button='ajouter'
        OnOpen={handleClickOpen}
      />
      {/* <p>{JSON.stringify(context.user1)}</p>
      <p>{JSON.stringify(context.user2)}</p> */}
      {Object.keys(compa).length !== 0 && compa.constructor === Object && (
        <>
          <Typography component='h3'>Sexe Attraction</Typography>
          <LinearProgressWithLabel value={compa.sexPer} color='secondary' />
          <Typography component='h3'>Love Attraction</Typography>
          <LinearProgressWithLabel value={compa.lovePer} thickness={20} />
          <Typography component='h3'>Fantasme Attraction</Typography>
          <LinearProgressWithLabel value={fantPer} thickness={20} />
        </>
      )}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <User index={1} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <User index={2} />
        </Grid>
      </Grid>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {context.id1 !== '' && context.id2 !== '' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '70%',
            }}
          >
            <Typography
              style={{
                marginRight: 20,
                marginLeft: 20,
                fontFamily: 'SF-Pro-Display-Bold',
                fontSize: 21,
              }}
            >
              Compatibilités Astro
            </Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: 20,
              }}
            >
              <div
                style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}
              >
                <div style={{ marginRight: 25 }}>
                  <Avatar src={signeU1} className={classes.signe} />
                  <p className={classes.signeText}>
                    {context.user1.astralSexe.signe}
                  </p>
                </div>
                <div style={{ marginLeft: 25 }}>
                  <Avatar src={signeU2} className={classes.signe} />
                  <p className={classes.signeText}>
                    {context.user2.astralSexe.signe}
                  </p>
                </div>
              </div>
              <p
                style={{
                  fontWeight: 'bold',
                  fontFamily: 'SF-Pro-Display-Semibold',
                  fontSize: 17,
                  marginTop: 30,
                  textAlign: 'center',
                }}
              >
                {slogan}
              </p>
              <div
                style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}
              >
                <div
                  style={{
                    backgroundColor: '#000',
                    height: 1,
                    width: 100,
                    alignSelf: 'center',
                  }}
                />
                <Typography
                  style={{
                    marginRight: 20,
                    marginLeft: 20,
                    fontFamily: 'SF-Pro-Display-Medium',
                    fontSize: 17,
                  }}
                >
                  Votre Love Attraction
                </Typography>
                <div
                  style={{
                    backgroundColor: '#000',
                    height: 1,
                    width: 100,
                    alignSelf: 'center',
                  }}
                />
              </div>
              <p
                style={{
                  fontFamily: 'SF-Pro-Display-Light',
                  fontSize: 17,
                  marginTop: 30,
                  textAlign: 'center',
                }}
              >
                {Lva}
              </p>
              <div
                style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}
              >
                <div
                  style={{
                    backgroundColor: '#000',
                    height: 1,
                    width: 100,
                    alignSelf: 'center',
                  }}
                />
                <Typography
                  style={{
                    marginRight: 20,
                    marginLeft: 20,
                    fontFamily: 'SF-Pro-Display-Medium',
                    fontSize: 17,
                  }}
                >
                  Positif-Négatif
                </Typography>
                <div
                  style={{
                    backgroundColor: '#000',
                    height: 1,
                    width: 100,
                    alignSelf: 'center',
                  }}
                />
              </div>
              <div style={{ alignItems: 'center', marginTop: 10 }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography
                    style={{
                      color: '#50b8ed',
                      fontSize: 17,
                      fontFamily: 'SF-Pro-Display-Medium',
                    }}
                  >
                    Le plus :&nbsp;
                  </Typography>
                  <Typography
                    style={{ fontSize: 17, fontFamily: 'SF-Pro-Display-Light' }}
                  >
                    {Plus}
                  </Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography
                    style={{
                      color: '#FE5098',
                      fontSize: 17,
                      fontFamily: 'SF-Pro-Display-Medium',
                    }}
                  >
                    Le moins :&nbsp;
                  </Typography>
                  <Typography
                    style={{ fontSize: 17, fontFamily: 'SF-Pro-Display-Light' }}
                  >
                    {Moins}
                  </Typography>
                </div>
              </div>
              <div
                style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}
              >
                <div
                  style={{
                    backgroundColor: '#000',
                    height: 1,
                    width: 100,
                    alignSelf: 'center',
                  }}
                />
                <Typography
                  style={{
                    marginRight: 20,
                    marginLeft: 20,
                    fontFamily: 'SF-Pro-Display-Medium',
                    fontSize: 17,
                  }}
                >
                  Ce qui vous fait « craquer » !
                </Typography>
                <div
                  style={{
                    backgroundColor: '#000',
                    height: 1,
                    width: 100,
                    alignSelf: 'center',
                  }}
                />
              </div>
              <p
                style={{
                  fontFamily: 'SF-Pro-Display-Light',
                  fontSize: 17,
                  marginTop: 30,
                  textAlign: 'center',
                }}
              >
                {craquer}
              </p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  backgroundColor: '#e12f79',
                  marginTop: 20,
                  borderRadius: 30,
                  width: 300,
                  height: 45,
                }}
              >
                <Avatar
                  src={require('../../assets/coachRose.png')}
                  style={{
                    height: 30,
                    width: 30,
                    alignSelf: 'center',
                    marginRight: 20,
                  }}
                />
                <p
                  style={{
                    color: '#FFFFFF',
                    fontSize: 18,
                    fontFamily: 'SF-Pro-Display-Medium',
                    alignSelf: 'center',
                  }}
                >
                  L’avis du coach !
                </p>
              </div>
              <p
                style={{
                  fontFamily: 'SF-Pro-Display-Light',
                  fontSize: 17,
                  marginTop: 30,
                  textAlign: 'center',
                }}
              >
                {coach}
              </p>
            </div>

            <Typography
              style={{
                marginRight: 20,
                marginLeft: 20,
                fontFamily: 'SF-Pro-Display-Bold',
                fontSize: 22,
                marginTop: 50,
              }}
            >
              Compatibilités Sexe
            </Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: 20,
              }}
            >
              <p
                style={{
                  fontFamily: 'SF-Pro-Display-Light',
                  fontSize: 17,
                  marginTop: 15,
                  textAlign: 'center',
                }}
              >
                {context.user1.gender === 'mal'
                  ? context.user1.astralSexe.signe === 'Bélier'
                    ? Belier_Homme
                    : context.user1.astralSexe.signe === 'Taureau'
                    ? Taureau_Homme
                    : context.user1.astralSexe.signe === 'Gémeaux'
                    ? Gemeaux_Homme
                    : context.user1.astralSexe.signe === 'Cancer'
                    ? Cancer_Homme
                    : context.user1.astralSexe.signe === 'Lion'
                    ? Lion_Homme
                    : context.user1.astralSexe.signe === 'Vierge'
                    ? Vierge_Homme
                    : context.user1.astralSexe.signe === 'Balance'
                    ? Balance_Homme
                    : context.user1.astralSexe.signe === 'Scorpion'
                    ? Scorpion_Homme
                    : context.user1.astralSexe.signe === 'Sagittaire'
                    ? Sagitaire_Homme
                    : context.user1.astralSexe.signe === 'Capricorne'
                    ? Capricorne_Homme
                    : context.user1.astralSexe.signe === 'Verseau'
                    ? Verseau_Homme
                    : Poissons_Homme
                  : context.user1.astralSexe.signe === 'Bélier'
                  ? Belier_Femme
                  : context.user1.astralSexe.signe === 'Taureau'
                  ? Taureau_Femme
                  : context.user1.astralSexe.signe === 'Gémeaux'
                  ? Gemeaux_Femme
                  : context.user1.astralSexe.signe === 'Cancer'
                  ? Cancer_Femme
                  : context.user1.astralSexe.signe === 'Lion'
                  ? Lion_Femme
                  : context.user1.astralSexe.signe === 'Vierge'
                  ? Vierge_Femme
                  : context.user1.astralSexe.signe === 'Balance'
                  ? Balance_Femme
                  : context.user1.astralSexe.signe === 'Scorpion'
                  ? Scorpion_Femme
                  : context.user1.astralSexe.signe === 'Sagittaire'
                  ? Sagitaire_Femme
                  : context.user1.astralSexe.signe === 'Capricorne'
                  ? Capricorne_Femme
                  : context.user1.astralSexe.signe === 'Verseau'
                  ? Verseau_Femme
                  : Poissons_Femme}
              </p>
              <p
                style={{
                  fontFamily: 'SF-Pro-Display-Light',
                  fontSize: 17,
                  marginTop: 15,
                }}
              >
                {context.user2.gender === 'mal'
                  ? context.user2.astralSexe.signe === 'Bélier'
                    ? Belier_Homme
                    : context.user2.astralSexe.signe === 'Taureau'
                    ? Taureau_Homme
                    : context.user2.astralSexe.signe === 'Gémeaux'
                    ? Gemeaux_Homme
                    : context.user2.astralSexe.signe === 'Cancer'
                    ? Cancer_Homme
                    : context.user2.astralSexe.signe === 'Lion'
                    ? Lion_Homme
                    : context.user2.astralSexe.signe === 'Vierge'
                    ? Vierge_Homme
                    : context.user2.astralSexe.signe === 'Balance'
                    ? Balance_Homme
                    : context.user2.astralSexe.signe === 'Scorpion'
                    ? Scorpion_Homme
                    : context.user2.astralSexe.signe === 'Sagittaire'
                    ? Sagitaire_Homme
                    : context.user2.astralSexe.signe === 'Capricorne'
                    ? Capricorne_Homme
                    : context.user2.astralSexe.signe === 'Verseau'
                    ? Verseau_Homme
                    : Poissons_Homme
                  : context.user2.astralSexe.signe === 'Bélier'
                  ? Belier_Femme
                  : context.user2.astralSexe.signe === 'Taureau'
                  ? Taureau_Femme
                  : context.user2.astralSexe.signe === 'Gémeaux'
                  ? Gemeaux_Femme
                  : context.user2.astralSexe.signe === 'Cancer'
                  ? Cancer_Femme
                  : context.user2.astralSexe.signe === 'Lion'
                  ? Lion_Femme
                  : context.user2.astralSexe.signe === 'Vierge'
                  ? Vierge_Femme
                  : context.user2.astralSexe.signe === 'Balance'
                  ? Balance_Femme
                  : context.user2.astralSexe.signe === 'Scorpion'
                  ? Scorpion_Femme
                  : context.user2.astralSexe.signe === 'Sagittaire'
                  ? Sagitaire_Femme
                  : context.user2.astralSexe.signe === 'Capricorne'
                  ? Capricorne_Femme
                  : context.user2.astralSexe.signe === 'Verseau'
                  ? Verseau_Femme
                  : Poissons_Femme}
              </p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  backgroundColor: '#e12f79',
                  marginTop: 20,
                  borderRadius: 30,
                  width: 300,
                  height: 45,
                }}
              >
                <Avatar
                  src={require('../../assets/coachRose.png')}
                  style={{
                    height: 30,
                    width: 30,
                    alignSelf: 'center',
                    marginRight: 20,
                  }}
                />
                <p
                  style={{
                    color: '#FFFFFF',
                    fontSize: 18,
                    fontFamily: 'SF-Pro-Display-Medium',
                    alignSelf: 'center',
                  }}
                >
                  L’avis du coach !
                </p>
              </div>
              <p
                style={{
                  fontFamily: 'SF-Pro-Display-Light',
                  fontStyle: 'italic',
                  fontSize: 16,
                  marginTop: 10,
                }}
              >
                «{' '}
                {context.user1.gender === 'mal'
                  ? transAstral[context.user1.themeAstral.plantes.Mars] ===
                    'Balance'
                    ? MarsMember === 'Bélier'
                      ? Mars.Mars_Balance_Belier
                      : MarsMember === 'Taureau'
                      ? Mars.Mars_Balance_Taureau
                      : MarsMember === 'Gémeaux'
                      ? Mars.Mars_Balance_Gemeaux
                      : MarsMember === 'Cancer'
                      ? Mars.Mars_Balance_Cancer
                      : MarsMember === 'Lion'
                      ? Mars.Mars_Balance_Lion
                      : MarsMember === 'Vierge'
                      ? Mars.Mars_Balance_Vierge
                      : MarsMember === 'Balance'
                      ? Mars.Mars_Balance_Balance
                      : MarsMember === 'Scorpion'
                      ? Mars.Mars_Balance_Scorpion
                      : MarsMember === 'Sagittaire'
                      ? Mars.Mars_Balance_Sagittaire
                      : MarsMember === 'Capricorne'
                      ? Mars.Mars_Balance_Capricorne
                      : MarsMember === 'Verseau'
                      ? Mars.Mars_Balance_Verseau
                      : Mars.Mars_Balance_Poissons
                    : transAstral[context.user1.themeAstral.plantes.Mars] ===
                      'Taureau'
                    ? MarsMember === 'Bélier'
                      ? Mars.Mars_Tauraeu_Belier
                      : MarsMember === 'Taureau'
                      ? Mars.Mars_Tauraeu_Taureau
                      : MarsMember === 'Gémeaux'
                      ? Mars.Mars_Tauraeu_Gemeaux
                      : MarsMember === 'Cancer'
                      ? Mars.Mars_Tauraeu_Cancer
                      : MarsMember === 'Lion'
                      ? Mars.Mars_Tauraeu_Lion
                      : MarsMember === 'Vierge'
                      ? Mars.Mars_Tauraeu_Vierge
                      : MarsMember === 'Balance'
                      ? Mars.Mars_Tauraeu_Balance
                      : MarsMember === 'Scorpion'
                      ? Mars.Mars_Tauraeu_Scorpion
                      : MarsMember === 'Sagittaire'
                      ? Mars.Mars_Tauraeu_Sagittaire
                      : MarsMember === 'Capricorne'
                      ? Mars.Mars_Tauraeu_Capricorne
                      : MarsMember === 'Verseau'
                      ? Mars.Mars_Tauraeu_Verseau
                      : Mars.Mars_Tauraeu_Poissons
                    : transAstral[context.user1.themeAstral.plantes.Mars] ===
                      'Gémeaux'
                    ? MarsMember === 'Bélier'
                      ? Mars.Mars_Gemeaux_Belier
                      : MarsMember === 'Taureau'
                      ? Mars.Mars_Gemeaux_Taureau
                      : MarsMember === 'Gémeaux'
                      ? Mars.Mars_Gemeaux_Gemeaux
                      : MarsMember === 'Cancer'
                      ? Mars.Mars_Gemeaux_Cancer
                      : MarsMember === 'Lion'
                      ? Mars.Mars_Gemeaux_Lion
                      : MarsMember === 'Vierge'
                      ? Mars.Mars_Gemeaux_Vierge
                      : MarsMember === 'Balance'
                      ? Mars.Mars_Gemeaux_Balance
                      : MarsMember === 'Scorpion'
                      ? Mars.Mars_Gemeaux_Scorpion
                      : MarsMember === 'Sagittaire'
                      ? Mars.Mars_Gemeaux_Sagittaire
                      : MarsMember === 'Capricorne'
                      ? Mars.Mars_Gemeaux_Capricorne
                      : MarsMember === 'Verseau'
                      ? Mars.Mars_Gemeaux_Verseau
                      : Mars.Mars_Gemeaux_Poissons
                    : transAstral[context.user1.themeAstral.plantes.Mars] ===
                      'Bélier'
                    ? MarsMember === 'Bélier'
                      ? Mars.Mars_Belier_Belier
                      : MarsMember === 'Taureau'
                      ? Mars.Mars_Belier_Taureau
                      : MarsMember === 'Gémeaux'
                      ? Mars.Mars_Belier_Gemeaux
                      : MarsMember === 'Cancer'
                      ? Mars.Mars_Belier_Cancer
                      : MarsMember === 'Lion'
                      ? Mars.Mars_Belier_Lion
                      : MarsMember === 'Vierge'
                      ? Mars.Mars_Belier_Vierge
                      : MarsMember === 'Balance'
                      ? Mars.Mars_Belier_Balance
                      : MarsMember === 'Scorpion'
                      ? Mars.Mars_Belier_Scorpion
                      : MarsMember === 'Sagittaire'
                      ? Mars.Mars_Belier_Sagittaire
                      : MarsMember === 'Capricorne'
                      ? Mars.Mars_Belier_Capricorne
                      : MarsMember === 'Verseau'
                      ? Mars.Mars_Belier_Verseau
                      : Mars.Mars_Belier_Poissons
                    : transAstral[context.user1.themeAstral.plantes.Mars] ===
                      'Cancer'
                    ? MarsMember === 'Bélier'
                      ? Mars.Mars_Cancer_Belier
                      : MarsMember === 'Taureau'
                      ? Mars.Mars_Cancer_Taureau
                      : MarsMember === 'Gémeaux'
                      ? Mars.Mars_Cancer_Gemeaux
                      : MarsMember === 'Cancer'
                      ? Mars.Mars_Cancer_Cancer
                      : MarsMember === 'Lion'
                      ? Mars.Mars_Cancer_Lion
                      : MarsMember === 'Vierge'
                      ? Mars.Mars_Cancer_Vierge
                      : MarsMember === 'Balance'
                      ? Mars.Mars_Cancer_Balance
                      : MarsMember === 'Scorpion'
                      ? Mars.Mars_Cancer_Scorpion
                      : MarsMember === 'Sagittaire'
                      ? Mars.Mars_Cancer_Sagittaire
                      : MarsMember === 'Capricorne'
                      ? Mars.Mars_Cancer_Capricorne
                      : MarsMember === 'Verseau'
                      ? Mars.Mars_Cancer_Verseau
                      : Mars.Mars_Cancer_Poissons
                    : transAstral[context.user1.themeAstral.plantes.Mars] ===
                      'Lion'
                    ? MarsMember === 'Bélier'
                      ? Mars.Mars_Lion_Belier
                      : MarsMember === 'Taureau'
                      ? Mars.Mars_Lion_Taureau
                      : MarsMember === 'Gémeaux'
                      ? Mars.Mars_Lion_Gemeaux
                      : MarsMember === 'Cancer'
                      ? Mars.Mars_Lion_Cancer
                      : MarsMember === 'Lion'
                      ? Mars.Mars_Lion_Lion
                      : MarsMember === 'Vierge'
                      ? Mars.Mars_Lion_Vierge
                      : MarsMember === 'Balance'
                      ? Mars.Mars_Lion_Balance
                      : MarsMember === 'Scorpion'
                      ? Mars.Mars_Lion_Scorpion
                      : MarsMember === 'Sagittaire'
                      ? Mars.Mars_Lion_Sagittaire
                      : MarsMember === 'Capricorne'
                      ? Mars.Mars_Lion_Capricorne
                      : MarsMember === 'Verseau'
                      ? Mars.Mars_Lion_Verseau
                      : Mars.Mars_Lion_Poissons
                    : transAstral[context.user1.themeAstral.plantes.Mars] ===
                      'Vierge'
                    ? MarsMember === 'Bélier'
                      ? Mars.Mars_Vierge_Belier
                      : MarsMember === 'Taureau'
                      ? Mars.Mars_Vierge_Taureau
                      : MarsMember === 'Gémeaux'
                      ? Mars.Mars_Vierge_Gemeaux
                      : MarsMember === 'Cancer'
                      ? Mars.Mars_Vierge_Cancer
                      : MarsMember === 'Lion'
                      ? Mars.Mars_Vierge_Lion
                      : MarsMember === 'Vierge'
                      ? Mars.Mars_Vierge_Vierge
                      : MarsMember === 'Balance'
                      ? Mars.Mars_Vierge_Balance
                      : MarsMember === 'Scorpion'
                      ? Mars.Mars_Vierge_Scorpion
                      : MarsMember === 'Sagittaire'
                      ? Mars.Mars_Vierge_Sagittaire
                      : MarsMember === 'Capricorne'
                      ? Mars.Mars_Vierge_Capricorne
                      : MarsMember === 'Verseau'
                      ? Mars.Mars_Vierge_Verseau
                      : Mars.Mars_Vierge_Poissons
                    : transAstral[context.user1.themeAstral.plantes.Mars] ===
                      'Scorpion'
                    ? MarsMember === 'Bélier'
                      ? Mars.Mars_Scorpion_Belier
                      : MarsMember === 'Taureau'
                      ? Mars.Mars_Scorpion_Taureau
                      : MarsMember === 'Gémeaux'
                      ? Mars.Mars_Scorpion_Gemeaux
                      : MarsMember === 'Cancer'
                      ? Mars.Mars_Scorpion_Cancer
                      : MarsMember === 'Lion'
                      ? Mars.Mars_Scorpion_Lion
                      : MarsMember === 'Vierge'
                      ? Mars.Mars_Scorpion_Vierge
                      : MarsMember === 'Balance'
                      ? Mars.Mars_Scorpion_Balance
                      : MarsMember === 'Scorpion'
                      ? Mars.Mars_Scorpion_Scorpion
                      : MarsMember === 'Sagittaire'
                      ? Mars.Mars_Scorpion_Sagittaire
                      : MarsMember === 'Capricorne'
                      ? Mars.Mars_Scorpion_Capricorne
                      : MarsMember === 'Verseau'
                      ? Mars.Mars_Scorpion_Verseau
                      : Mars.Mars_Scorpion_Poissons
                    : transAstral[context.user1.themeAstral.plantes.Mars] ===
                      'Sagittaire'
                    ? MarsMember === 'Bélier'
                      ? Mars.Mars_Sagittaire_Belier
                      : MarsMember === 'Taureau'
                      ? Mars.Mars_Sagittaire_Taureau
                      : MarsMember === 'Gémeaux'
                      ? Mars.Mars_Sagittaire_Gemeaux
                      : MarsMember === 'Cancer'
                      ? Mars.Mars_Sagittaire_Cancer
                      : MarsMember === 'Lion'
                      ? Mars.Mars_Sagittaire_Lion
                      : MarsMember === 'Vierge'
                      ? Mars.Mars_Sagittaire_Vierge
                      : MarsMember === 'Balance'
                      ? Mars.Mars_Sagittaire_Balance
                      : MarsMember === 'Scorpion'
                      ? Mars.Mars_Sagittaire_Scorpion
                      : MarsMember === 'Sagittaire'
                      ? Mars.Mars_Sagittaire_Sagittaire
                      : MarsMember === 'Capricorne'
                      ? Mars.Mars_Sagittaire_Capricorne
                      : MarsMember === 'Verseau'
                      ? Mars.Mars_Sagittaire_Verseau
                      : Mars.Mars_Sagittaire_Poissons
                    : transAstral[context.user1.themeAstral.plantes.Mars] ===
                      'Capricorne'
                    ? MarsMember === 'Bélier'
                      ? Mars.Mars_Capricorne_Belier
                      : MarsMember === 'Taureau'
                      ? Mars.Mars_Capricorne_Taureau
                      : MarsMember === 'Gémeaux'
                      ? Mars.Mars_Capricorne_Gemeaux
                      : MarsMember === 'Cancer'
                      ? Mars.Mars_Capricorne_Cancer
                      : MarsMember === 'Lion'
                      ? Mars.Mars_Capricorne_Lion
                      : MarsMember === 'Vierge'
                      ? Mars.Mars_Capricorne_Vierge
                      : MarsMember === 'Balance'
                      ? Mars.Mars_Capricorne_Balance
                      : MarsMember === 'Scorpion'
                      ? Mars.Mars_Capricorne_Scorpion
                      : MarsMember === 'Sagittaire'
                      ? Mars.Mars_Capricorne_Sagittaire
                      : MarsMember === 'Capricorne'
                      ? Mars.Mars_Capricorne_Capricorne
                      : MarsMember === 'Verseau'
                      ? Mars.Mars_Capricorne_Verseau
                      : Mars.Mars_Capricorne_Poissons
                    : transAstral[context.user1.themeAstral.plantes.Mars] ===
                      'Poissons'
                    ? MarsMember === 'Bélier'
                      ? Mars.Mars_Poissons_Belier
                      : MarsMember === 'Taureau'
                      ? Mars.Mars_Poissons_Taureau
                      : MarsMember === 'Gémeaux'
                      ? Mars.Mars_Poissons_Gemeaux
                      : MarsMember === 'Cancer'
                      ? Mars.Mars_Poissons_Cancer
                      : MarsMember === 'Lion'
                      ? Mars.Mars_Poissons_Lion
                      : MarsMember === 'Vierge'
                      ? Mars.Mars_Poissons_Vierge
                      : MarsMember === 'Balance'
                      ? Mars.Mars_Poissons_Balance
                      : MarsMember === 'Scorpion'
                      ? Mars.Mars_Poissons_Scorpion
                      : MarsMember === 'Sagittaire'
                      ? Mars.Mars_Poissons_Sagittaire
                      : MarsMember === 'Capricorne'
                      ? Mars.Mars_Poissons_Capricorne
                      : MarsMember === 'Verseau'
                      ? Mars.Mars_Poissons_Verseau
                      : Mars.Mars_Poissons_Poissons
                    : MarsMember === 'Bélier'
                    ? Mars.Mars_Verseau_Belier
                    : MarsMember === 'Taureau'
                    ? Mars.Mars_Verseau_Taureau
                    : MarsMember === 'Gémeaux'
                    ? Mars.Mars_Verseau_Gemeaux
                    : MarsMember === 'Cancer'
                    ? Mars.Mars_Verseau_Cancer
                    : MarsMember === 'Lion'
                    ? Mars.Mars_Verseau_Lion
                    : MarsMember === 'Vierge'
                    ? Mars.Mars_Verseau_Vierge
                    : MarsMember === 'Balance'
                    ? Mars.Mars_Verseau_Balance
                    : MarsMember === 'Scorpion'
                    ? Mars.Mars_Verseau_Scorpion
                    : MarsMember === 'Sagittaire'
                    ? Mars.Mars_Verseau_Sagittaire
                    : MarsMember === 'Capricorne'
                    ? Mars.Mars_Verseau_Capricorne
                    : MarsMember === 'Verseau'
                    ? Mars.Mars_Verseau_Verseau
                    : Mars.Mars_Verseau_Poissons
                  : transAstral[context.user1.themeAstral.plantes.Mars] ===
                    'Balance'
                  ? MarsMember === 'Bélier'
                    ? Mars.Mars_Belier_Balance
                    : MarsMember === 'Taureau'
                    ? Mars.Mars_Tauraeu_Balance
                    : MarsMember === 'Gémeaux'
                    ? Mars.Mars_Gemeaux_Balance
                    : MarsMember === 'Cancer'
                    ? Mars.Mars_Cancer_Balance
                    : MarsMember === 'Lion'
                    ? Mars.Mars_Lion_Balance
                    : MarsMember === 'Vierge'
                    ? Mars.Mars_Vierge_Balance
                    : MarsMember === 'Balance'
                    ? Mars.Mars_Balance_Balance
                    : MarsMember === 'Scorpion'
                    ? Mars.Mars_Scorpion_Balance
                    : MarsMember === 'Sagittaire'
                    ? Mars.Mars_Sagittaire_Balance
                    : MarsMember === 'Capricorne'
                    ? Mars.Mars_Capricorne_Balance
                    : MarsMember === 'Verseau'
                    ? Mars.Mars_Verseau_Balance
                    : Mars.Mars_Poissons_Balance
                  : transAstral[context.user1.themeAstral.plantes.Mars] ===
                    'Taureau'
                  ? MarsMember === 'Bélier'
                    ? Mars.Mars_Belier_Taureau
                    : MarsMember === 'Taureau'
                    ? Mars.Mars_Tauraeu_Taureau
                    : MarsMember === 'Gémeaux'
                    ? Mars.Mars_Gemeaux_Taureau
                    : MarsMember === 'Cancer'
                    ? Mars.Mars_Cancer_Taureau
                    : MarsMember === 'Lion'
                    ? Mars.Mars_Lion_Taureau
                    : MarsMember === 'Vierge'
                    ? Mars.Mars_Vierge_Taureau
                    : MarsMember === 'Balance'
                    ? Mars.Mars_Balance_Taureau
                    : MarsMember === 'Scorpion'
                    ? Mars.Mars_Scorpion_Taureau
                    : MarsMember === 'Sagittaire'
                    ? Mars.Mars_Sagittaire_Taureau
                    : MarsMember === 'Capricorne'
                    ? Mars.Mars_Capricorne_Taureau
                    : MarsMember === 'Verseau'
                    ? Mars.Mars_Verseau_Taureau
                    : Mars.Mars_Poissons_Taureau
                  : transAstral[context.user1.themeAstral.plantes.Mars] ===
                    'Gémeaux'
                  ? MarsMember === 'Bélier'
                    ? Mars.Mars_Belier_Gemeaux
                    : MarsMember === 'Taureau'
                    ? Mars.Mars_Tauraeu_Gemeaux
                    : MarsMember === 'Gémeaux'
                    ? Mars.Mars_Gemeaux_Gemeaux
                    : MarsMember === 'Cancer'
                    ? Mars.Mars_Cancer_Gemeaux
                    : MarsMember === 'Lion'
                    ? Mars.Mars_Lion_Gemeaux
                    : MarsMember === 'Vierge'
                    ? Mars.Mars_Vierge_Gemeaux
                    : MarsMember === 'Balance'
                    ? Mars.Mars_Balance_Gemeaux
                    : MarsMember === 'Scorpion'
                    ? Mars.Mars_Scorpion_Gemeaux
                    : MarsMember === 'Sagittaire'
                    ? Mars.Mars_Sagittaire_Gemeaux
                    : MarsMember === 'Capricorne'
                    ? Mars.Mars_Capricorne_Gemeaux
                    : MarsMember === 'Verseau'
                    ? Mars.Mars_Verseau_Gemeaux
                    : Mars.Mars_Poissons_Gemeaux
                  : transAstral[context.user1.themeAstral.plantes.Mars] ===
                    'Bélier'
                  ? MarsMember === 'Bélier'
                    ? Mars.Mars_Belier_Belier
                    : MarsMember === 'Taureau'
                    ? Mars.Mars_Tauraeu_Belier
                    : MarsMember === 'Gémeaux'
                    ? Mars.Mars_Gemeaux_Belier
                    : MarsMember === 'Cancer'
                    ? Mars.Mars_Cancer_Belier
                    : MarsMember === 'Lion'
                    ? Mars.Mars_Lion_Belier
                    : MarsMember === 'Vierge'
                    ? Mars.Mars_Vierge_Belier
                    : MarsMember === 'Balance'
                    ? Mars.Mars_Balance_Belier
                    : MarsMember === 'Scorpion'
                    ? Mars.Mars_Scorpion_Belier
                    : MarsMember === 'Sagittaire'
                    ? Mars.Mars_Sagittaire_Belier
                    : MarsMember === 'Capricorne'
                    ? Mars.Mars_Capricorne_Belier
                    : MarsMember === 'Verseau'
                    ? Mars.Mars_Verseau_Belier
                    : Mars.Mars_Poissons_Belier
                  : transAstral[context.user1.themeAstral.plantes.Mars] ===
                    'Cancer'
                  ? MarsMember === 'Bélier'
                    ? Mars.Mars_Belier_Cancer
                    : MarsMember === 'Taureau'
                    ? Mars.Mars_Tauraeu_Cancer
                    : MarsMember === 'Gémeaux'
                    ? Mars.Mars_Gemeaux_Cancer
                    : MarsMember === 'Cancer'
                    ? Mars.Mars_Cancer_Cancer
                    : MarsMember === 'Lion'
                    ? Mars.Mars_Lion_Cancer
                    : MarsMember === 'Vierge'
                    ? Mars.Mars_Vierge_Cancer
                    : MarsMember === 'Balance'
                    ? Mars.Mars_Balance_Cancer
                    : MarsMember === 'Scorpion'
                    ? Mars.Mars_Scorpion_Cancer
                    : MarsMember === 'Sagittaire'
                    ? Mars.Mars_Sagittaire_Cancer
                    : MarsMember === 'Capricorne'
                    ? Mars.Mars_Capricorne_Cancer
                    : MarsMember === 'Verseau'
                    ? Mars.Mars_Verseau_Cancer
                    : Mars.Mars_Poissons_Cancer
                  : transAstral[context.user1.themeAstral.plantes.Mars] ===
                    'Lion'
                  ? MarsMember === 'Bélier'
                    ? Mars.Mars_Belier_Lion
                    : MarsMember === 'Taureau'
                    ? Mars.Mars_Tauraeu_Lion
                    : MarsMember === 'Gémeaux'
                    ? Mars.Mars_Gemeaux_Lion
                    : MarsMember === 'Cancer'
                    ? Mars.Mars_Cancer_Lion
                    : MarsMember === 'Lion'
                    ? Mars.Mars_Lion_Lion
                    : MarsMember === 'Vierge'
                    ? Mars.Mars_Vierge_Lion
                    : MarsMember === 'Balance'
                    ? Mars.Mars_Balance_Lion
                    : MarsMember === 'Scorpion'
                    ? Mars.Mars_Scorpion_Lion
                    : MarsMember === 'Sagittaire'
                    ? Mars.Mars_Sagittaire_Lion
                    : MarsMember === 'Capricorne'
                    ? Mars.Mars_Capricorne_Lion
                    : MarsMember === 'Verseau'
                    ? Mars.Mars_Verseau_Lion
                    : Mars.Mars_Poissons_Lion
                  : transAstral[context.user1.themeAstral.plantes.Mars] ===
                    'Vierge'
                  ? MarsMember === 'Bélier'
                    ? Mars.Mars_Belier_Vierge
                    : MarsMember === 'Taureau'
                    ? Mars.Mars_Tauraeu_Vierge
                    : MarsMember === 'Gémeaux'
                    ? Mars.Mars_Gemeaux_Vierge
                    : MarsMember === 'Cancer'
                    ? Mars.Mars_Canser_Vierge
                    : MarsMember === 'Lion'
                    ? Mars.Mars_Lion_Vierge
                    : MarsMember === 'Vierge'
                    ? Mars.Mars_Vierge_Vierge
                    : MarsMember === 'Balance'
                    ? Mars.Mars_Balance_Vierge
                    : MarsMember === 'Scorpion'
                    ? Mars.Mars_Scorpion_Vierge
                    : MarsMember === 'Sagittaire'
                    ? Mars.Mars_Sagittaire_Vierge
                    : MarsMember === 'Capricorne'
                    ? Mars.Mars_Capricorne_Vierge
                    : MarsMember === 'Verseau'
                    ? Mars.Mars_Verseau_Vierge
                    : Mars.Mars_Poissons_Vierge
                  : transAstral[context.user1.themeAstral.plantes.Mars] ===
                    'Scorpion'
                  ? MarsMember === 'Bélier'
                    ? Mars.Mars_Belier_Scorpion
                    : MarsMember === 'Taureau'
                    ? Mars.Mars_Tauraeu_Scorpion
                    : MarsMember === 'Gémeaux'
                    ? Mars.Mars_Gemeaux_Scorpion
                    : MarsMember === 'Cancer'
                    ? Mars.Mars_Cancer_Scorpion
                    : MarsMember === 'Lion'
                    ? Mars.Mars_Lion_Scorpion
                    : MarsMember === 'Vierge'
                    ? Mars.Mars_Vierge_Scorpion
                    : MarsMember === 'Balance'
                    ? Mars.Mars_Balance_Scorpion
                    : MarsMember === 'Scorpion'
                    ? Mars.Mars_Scorpion_Scorpion
                    : MarsMember === 'Sagittaire'
                    ? Mars.Mars_Sagittaire_Scorpion
                    : MarsMember === 'Capricorne'
                    ? Mars.Mars_Capricorne_Scorpion
                    : MarsMember === 'Verseau'
                    ? Mars.Mars_Verseau_Scorpion
                    : Mars.Mars_Poissons_Scorpion
                  : transAstral[context.user1.themeAstral.plantes.Mars] ===
                    'Sagittaire'
                  ? MarsMember === 'Bélier'
                    ? Mars.Mars_Belier_Sagittaire
                    : MarsMember === 'Taureau'
                    ? Mars.Mars_Tauraeu_Sagittaire
                    : MarsMember === 'Gémeaux'
                    ? Mars.Mars_Gemeaux_Sagittaire
                    : MarsMember === 'Cancer'
                    ? Mars.Mars_Cancer_Sagittaire
                    : MarsMember === 'Lion'
                    ? Mars.Mars_Lion_Sagittaire
                    : MarsMember === 'Vierge'
                    ? Mars.Mars_Vierge_Sagittaire
                    : MarsMember === 'Balance'
                    ? Mars.Mars_Balance_Sagittaire
                    : MarsMember === 'Scorpion'
                    ? Mars.Mars_Scorpion_Sagittaire
                    : MarsMember === 'Sagittaire'
                    ? Mars.Mars_Sagittaire_Sagittaire
                    : MarsMember === 'Capricorne'
                    ? Mars.Mars_Capricorne_Sagittaire
                    : MarsMember === 'Verseau'
                    ? Mars.Mars_Verseau_Sagittaire
                    : Mars.Mars_Poissons_Sagittaire
                  : transAstral[context.user1.themeAstral.plantes.Mars] ===
                    'Capricorne'
                  ? MarsMember === 'Bélier'
                    ? Mars.Mars_Belier_Capricorne
                    : MarsMember === 'Taureau'
                    ? Mars.Mars_Tauraeu_Capricorne
                    : MarsMember === 'Gémeaux'
                    ? Mars.Mars_Gemeaux_Capricorne
                    : MarsMember === 'Cancer'
                    ? Mars.Mars_Cancer_Capricorne
                    : MarsMember === 'Lion'
                    ? Mars.Mars_Lion_Capricorne
                    : MarsMember === 'Vierge'
                    ? Mars.Mars_Vierge_Capricorne
                    : MarsMember === 'Balance'
                    ? Mars.Mars_Balance_Capricorne
                    : MarsMember === 'Scorpion'
                    ? Mars.Mars_Scorpion_Capricorne
                    : MarsMember === 'Sagittaire'
                    ? Mars.Mars_Sagittaire_Capricorne
                    : MarsMember === 'Capricorne'
                    ? Mars.Mars_Capricorne_Capricorne
                    : MarsMember === 'Verseau'
                    ? Mars.Mars_Verseau_Capricorne
                    : Mars.Mars_Poissons_Capricorne
                  : transAstral[context.user1.themeAstral.plantes.Mars] ===
                    'Poissons'
                  ? MarsMember === 'Bélier'
                    ? Mars.Mars_Belier_Poissons
                    : MarsMember === 'Taureau'
                    ? Mars.Mars_Tauraeu_Poissons
                    : MarsMember === 'Gémeaux'
                    ? Mars.Mars_Gemeaux_Poissons
                    : MarsMember === 'Cancer'
                    ? Mars.Mars_Cancer_Poissons
                    : MarsMember === 'Lion'
                    ? Mars.Mars_Lion_Poissons
                    : MarsMember === 'Vierge'
                    ? Mars.Mars_Vierge_Poissons
                    : MarsMember === 'Balance'
                    ? Mars.Mars_Balance_Poissons
                    : MarsMember === 'Scorpion'
                    ? Mars.Mars_Scorpion_Poissons
                    : MarsMember === 'Sagittaire'
                    ? Mars.Mars_Sagittaire_Poissons
                    : MarsMember === 'Capricorne'
                    ? Mars.Mars_Capricorne_Poissons
                    : MarsMember === 'Verseau'
                    ? Mars.Mars_Verseau_Poissons
                    : Mars.Mars_Poissons_Poissons
                  : MarsMember === 'Bélier'
                  ? Mars.Mars_Belier_Verseau
                  : MarsMember === 'Taureau'
                  ? Mars.Mars_Tauraeu_Verseau
                  : MarsMember === 'Gémeaux'
                  ? Mars.Mars_Gemeaux_Verseau
                  : MarsMember === 'Cancer'
                  ? Mars.Mars_Cancer_Verseau
                  : MarsMember === 'Lion'
                  ? Mars.Mars_Lion_Verseau
                  : MarsMember === 'Vierge'
                  ? Mars.Mars_Vierge_Verseau
                  : MarsMember === 'Balance'
                  ? Mars.Mars_Balance_Verseau
                  : MarsMember === 'Scorpion'
                  ? Mars.Mars_Scorpion_Verseau
                  : MarsMember === 'Sagittaire'
                  ? Mars.Mars_Sagittaire_Verseau
                  : MarsMember === 'Capricorne'
                  ? Mars.Mars_Capricorne_Verseau
                  : MarsMember === 'Verseau'
                  ? Mars.Mars_Verseau_Verseau
                  : Mars.Mars_Poissons_Verseau}{' '}
                »
              </p>
              <p
                style={{
                  fontFamily: 'SF-Pro-Display-Light',
                  fontStyle: 'italic',
                  fontSize: 16,
                  marginTop: 10,
                }}
              >
                «{' '}
                {context.user1.gender === 'mal'
                  ? transAstral[context.user1.themeAstral.plantes.Venus] ===
                    'Balance'
                    ? VenusMember === 'Bélier'
                      ? Venus.Venus_Balance_Belier
                      : VenusMember === 'Taureau'
                      ? Venus.Venus_Balance_Taureau
                      : VenusMember === 'Gémeaux'
                      ? Venus.Venus_Balance_Gemeaux
                      : VenusMember === 'Cancer'
                      ? Venus.Venus_Balance_Cancer
                      : VenusMember === 'Lion'
                      ? Venus.Venus_Balance_Lion
                      : VenusMember === 'Vierge'
                      ? Venus.Venus_Balance_Vierge
                      : VenusMember === 'Balance'
                      ? Venus.Venus_Balance_Balance
                      : VenusMember === 'Scorpion'
                      ? Venus.Venus_Balance_Scorpion
                      : VenusMember === 'Sagittaire'
                      ? Venus.Venus_Balance_Sagittaire
                      : VenusMember === 'Capricorne'
                      ? Venus.Venus_Balance_Capricorne
                      : VenusMember === 'Verseau'
                      ? Venus.Venus_Balance_Verseau
                      : Venus.Venus_Balance_Poissons
                    : transAstral[context.user1.themeAstral.plantes.Venus] ===
                      'Taureau'
                    ? VenusMember === 'Bélier'
                      ? Venus.Venus_Tauraeu_Belier
                      : VenusMember === 'Taureau'
                      ? Venus.Venus_Tauraeu_Taureau
                      : VenusMember === 'Gémeaux'
                      ? Venus.Venus_Tauraeu_Gemeaux
                      : VenusMember === 'Cancer'
                      ? Venus.Venus_Tauraeu_Cancer
                      : VenusMember === 'Lion'
                      ? Venus.Venus_Tauraeu_Lion
                      : VenusMember === 'Vierge'
                      ? Venus.Venus_Tauraeu_Vierge
                      : VenusMember === 'Balance'
                      ? Venus.Venus_Tauraeu_Balance
                      : VenusMember === 'Scorpion'
                      ? Venus.Venus_Tauraeu_Scorpion
                      : VenusMember === 'Sagittaire'
                      ? Venus.Venus_Tauraeu_Sagittaire
                      : VenusMember === 'Capricorne'
                      ? Venus.Venus_Tauraeu_Capricorne
                      : VenusMember === 'Verseau'
                      ? Venus.Venus_Tauraeu_Verseau
                      : Venus.Venus_Tauraeu_Poissons
                    : transAstral[context.user1.themeAstral.plantes.Venus] ===
                      'Gémeaux'
                    ? VenusMember === 'Bélier'
                      ? Venus.Venus_Gemeaux_Belier
                      : VenusMember === 'Taureau'
                      ? Venus.Venus_Gemeaux_Taureau
                      : VenusMember === 'Gémeaux'
                      ? Venus.Venus_Gemeaux_Gemeaux
                      : VenusMember === 'Cancer'
                      ? Venus.Venus_Gemeaux_Cancer
                      : VenusMember === 'Lion'
                      ? Venus.Venus_Gemeaux_Lion
                      : VenusMember === 'Vierge'
                      ? Venus.Venus_Gemeaux_Vierge
                      : VenusMember === 'Balance'
                      ? Venus.Venus_Gemeaux_Balance
                      : VenusMember === 'Scorpion'
                      ? Venus.Venus_Gemeaux_Scorpion
                      : VenusMember === 'Sagittaire'
                      ? Venus.Venus_Gemeaux_Sagittaire
                      : VenusMember === 'Capricorne'
                      ? Venus.Venus_Gemeaux_Capricorne
                      : VenusMember === 'Verseau'
                      ? Venus.Venus_Gemeaux_Verseau
                      : Venus.Venus_Gemeaux_Poissons
                    : transAstral[context.user1.themeAstral.plantes.Venus] ===
                      'Bélier'
                    ? VenusMember === 'Bélier'
                      ? Venus.Venus_Belier_Belier
                      : VenusMember === 'Taureau'
                      ? Venus.Venus_Belier_Taureau
                      : VenusMember === 'Gémeaux'
                      ? Venus.Venus_Belier_Gemeaux
                      : VenusMember === 'Cancer'
                      ? Venus.Venus_Belier_Cancer
                      : VenusMember === 'Lion'
                      ? Venus.Venus_Belier_Lion
                      : VenusMember === 'Vierge'
                      ? Venus.Venus_Belier_Vierge
                      : VenusMember === 'Balance'
                      ? Venus.Venus_Belier_Balance
                      : VenusMember === 'Scorpion'
                      ? Venus.Venus_Belier_Scorpion
                      : VenusMember === 'Sagittaire'
                      ? Venus.Venus_Belier_Sagittaire
                      : VenusMember === 'Capricorne'
                      ? Venus.Venus_Belier_Capricorne
                      : VenusMember === 'Verseau'
                      ? Venus.Venus_Belier_Verseau
                      : Venus.Venus_Belier_Poissons
                    : transAstral[context.user1.themeAstral.plantes.Venus] ===
                      'Cancer'
                    ? VenusMember === 'Bélier'
                      ? Venus.Venus_Cancer_Belier
                      : VenusMember === 'Taureau'
                      ? Venus.Venus_Cancer_Taureau
                      : VenusMember === 'Gémeaux'
                      ? Venus.Venus_Cancer_Gemeaux
                      : VenusMember === 'Cancer'
                      ? Venus.Venus_Cancer_Cancer
                      : VenusMember === 'Lion'
                      ? Venus.Venus_Cancer_Lion
                      : VenusMember === 'Vierge'
                      ? Venus.Venus_Cancer_Vierge
                      : VenusMember === 'Balance'
                      ? Venus.Venus_Cancer_Balance
                      : VenusMember === 'Scorpion'
                      ? Venus.Venus_Cancer_Scorpion
                      : VenusMember === 'Sagittaire'
                      ? Venus.Venus_Cancer_Sagittaire
                      : VenusMember === 'Capricorne'
                      ? Venus.Venus_Cancer_Capricorne
                      : VenusMember === 'Verseau'
                      ? Venus.Venus_Cancer_Verseau
                      : Venus.Venus_Cancer_Poissons
                    : transAstral[context.user1.themeAstral.plantes.Venus] ===
                      'Lion'
                    ? VenusMember === 'Bélier'
                      ? Venus.Venus_Lion_Belier
                      : VenusMember === 'Taureau'
                      ? Venus.Venus_Lion_Taureau
                      : VenusMember === 'Gémeaux'
                      ? Venus.Venus_Lion_Gemeaux
                      : VenusMember === 'Cancer'
                      ? Venus.Venus_Lion_Cancer
                      : VenusMember === 'Lion'
                      ? Venus.Venus_Lion_Lion
                      : VenusMember === 'Vierge'
                      ? Venus.Venus_Lion_Vierge
                      : VenusMember === 'Balance'
                      ? Venus.Venus_Lion_Balance
                      : VenusMember === 'Scorpion'
                      ? Venus.Venus_Lion_Scorpion
                      : VenusMember === 'Sagittaire'
                      ? Venus.Venus_Lion_Sagittaire
                      : VenusMember === 'Capricorne'
                      ? Venus.Venus_Lion_Capricorne
                      : VenusMember === 'Verseau'
                      ? Venus.Venus_Lion_Verseau
                      : Venus.Venus_Lion_Poissons
                    : transAstral[context.user1.themeAstral.plantes.Venus] ===
                      'Vierge'
                    ? VenusMember === 'Bélier'
                      ? Venus.Venus_Vierge_Belier
                      : VenusMember === 'Taureau'
                      ? Venus.Venus_Vierge_Taureau
                      : VenusMember === 'Gémeaux'
                      ? Venus.Venus_Vierge_Gemeaux
                      : VenusMember === 'Cancer'
                      ? Venus.Venus_Vierge_Cancer
                      : VenusMember === 'Lion'
                      ? Venus.Venus_Vierge_Lion
                      : VenusMember === 'Vierge'
                      ? Venus.Venus_Vierge_Vierge
                      : VenusMember === 'Balance'
                      ? Venus.Venus_Vierge_Balance
                      : VenusMember === 'Scorpion'
                      ? Venus.Venus_Vierge_Scorpion
                      : VenusMember === 'Sagittaire'
                      ? Venus.Venus_Vierge_Sagittaire
                      : VenusMember === 'Capricorne'
                      ? Venus.Venus_Vierge_Capricorne
                      : VenusMember === 'Verseau'
                      ? Venus.Venus_Vierge_Verseau
                      : Venus.Venus_Vierge_Poissons
                    : transAstral[context.user1.themeAstral.plantes.Venus] ===
                      'Scorpion'
                    ? VenusMember === 'Bélier'
                      ? Venus.Venus_Scorpion_Belier
                      : VenusMember === 'Taureau'
                      ? Venus.Venus_Scorpion_Taureau
                      : VenusMember === 'Gémeaux'
                      ? Venus.Venus_Scorpion_Gemeaux
                      : VenusMember === 'Cancer'
                      ? Venus.Venus_Scorpion_Cancer
                      : VenusMember === 'Lion'
                      ? Venus.Venus_Scorpion_Lion
                      : VenusMember === 'Vierge'
                      ? Venus.Venus_Scorpion_Vierge
                      : VenusMember === 'Balance'
                      ? Venus.Venus_Scorpion_Balance
                      : VenusMember === 'Scorpion'
                      ? Venus.Venus_Scorpion_Scorpion
                      : VenusMember === 'Sagittaire'
                      ? Venus.Venus_Scorpion_Sagittaire
                      : VenusMember === 'Capricorne'
                      ? Venus.Venus_Scorpion_Capricorne
                      : VenusMember === 'Verseau'
                      ? Venus.Venus_Scorpion_Verseau
                      : Venus.Venus_Scorpion_Poissons
                    : transAstral[context.user1.themeAstral.plantes.Venus] ===
                      'Sagittaire'
                    ? VenusMember === 'Bélier'
                      ? Venus.Venus_Sagittaire_Belier
                      : VenusMember === 'Taureau'
                      ? Venus.Venus_Sagittaire_Taureau
                      : VenusMember === 'Gémeaux'
                      ? Venus.Venus_Sagittaire_Gemeaux
                      : VenusMember === 'Cancer'
                      ? Venus.Venus_Sagittaire_Cancer
                      : VenusMember === 'Lion'
                      ? Venus.Venus_Sagittaire_Lion
                      : VenusMember === 'Vierge'
                      ? Venus.Venus_Sagittaire_Vierge
                      : VenusMember === 'Balance'
                      ? Venus.Venus_Sagittaire_Balance
                      : VenusMember === 'Scorpion'
                      ? Venus.Venus_Sagittaire_Scorpion
                      : VenusMember === 'Sagittaire'
                      ? Venus.Venus_Sagittaire_Sagittaire
                      : VenusMember === 'Capricorne'
                      ? Venus.Venus_Sagittaire_Capricorne
                      : VenusMember === 'Verseau'
                      ? Venus.Venus_Sagittaire_Verseau
                      : Venus.Venus_Sagittaire_Poissons
                    : transAstral[context.user1.themeAstral.plantes.Venus] ===
                      'Capricorne'
                    ? VenusMember === 'Bélier'
                      ? Venus.Venus_Capricorne_Belier
                      : VenusMember === 'Taureau'
                      ? Venus.Venus_Capricorne_Taureau
                      : VenusMember === 'Gémeaux'
                      ? Venus.Venus_Capricorne_Gemeaux
                      : VenusMember === 'Cancer'
                      ? Venus.Venus_Capricorne_Cancer
                      : VenusMember === 'Lion'
                      ? Venus.Venus_Capricorne_Lion
                      : VenusMember === 'Vierge'
                      ? Venus.Venus_Capricorne_Vierge
                      : VenusMember === 'Balance'
                      ? Venus.Venus_Capricorne_Balance
                      : VenusMember === 'Scorpion'
                      ? Venus.Venus_Capricorne_Scorpion
                      : VenusMember === 'Sagittaire'
                      ? Venus.Venus_Capricorne_Sagittaire
                      : VenusMember === 'Capricorne'
                      ? Venus.Venus_Capricorne_Capricorne
                      : VenusMember === 'Verseau'
                      ? Venus.Venus_Capricorne_Verseau
                      : Venus.Venus_Capricorne_Poissons
                    : transAstral[context.user1.themeAstral.plantes.Venus] ===
                      'Poissons'
                    ? VenusMember === 'Bélier'
                      ? Venus.Venus_Poissons_Belier
                      : VenusMember === 'Taureau'
                      ? Venus.Venus_Poissons_Taureau
                      : VenusMember === 'Gémeaux'
                      ? Venus.Venus_Poissons_Gemeaux
                      : VenusMember === 'Cancer'
                      ? Venus.Venus_Poissons_Cancer
                      : VenusMember === 'Lion'
                      ? Venus.Venus_Poissons_Lion
                      : VenusMember === 'Vierge'
                      ? Venus.Venus_Poissons_Vierge
                      : VenusMember === 'Balance'
                      ? Venus.Venus_Poissons_Balance
                      : VenusMember === 'Scorpion'
                      ? Venus.Venus_Poissons_Scorpion
                      : VenusMember === 'Sagittaire'
                      ? Venus.Venus_Poissons_Sagittaire
                      : VenusMember === 'Capricorne'
                      ? Venus.Venus_Poissons_Capricorne
                      : VenusMember === 'Verseau'
                      ? Venus.Venus_Poissons_Verseau
                      : Venus.Venus_Poissons_Poissons
                    : VenusMember === 'Bélier'
                    ? Venus.Venus_Verseau_Belier
                    : VenusMember === 'Taureau'
                    ? Venus.Venus_Verseau_Taureau
                    : VenusMember === 'Gémeaux'
                    ? Venus.Venus_Verseau_Gemeaux
                    : VenusMember === 'Cancer'
                    ? Venus.Venus_Verseau_Cancer
                    : VenusMember === 'Lion'
                    ? Venus.Venus_Verseau_Lion
                    : VenusMember === 'Vierge'
                    ? Venus.Venus_Verseau_Vierge
                    : VenusMember === 'Balance'
                    ? Venus.Venus_Verseau_Balance
                    : VenusMember === 'Scorpion'
                    ? Venus.Venus_Verseau_Scorpion
                    : VenusMember === 'Sagittaire'
                    ? Venus.Venus_Verseau_Sagittaire
                    : VenusMember === 'Capricorne'
                    ? Venus.Venus_Verseau_Capricorne
                    : VenusMember === 'Verseau'
                    ? Venus.Venus_Verseau_Verseau
                    : Venus.Venus_Verseau_Poissons
                  : transAstral[context.user1.themeAstral.plantes.Venus] ===
                    'Balance'
                  ? VenusMember === 'Bélier'
                    ? Venus.Venus_Belier_Balance
                    : VenusMember === 'Taureau'
                    ? Venus.Venus_Tauraeu_Balance
                    : VenusMember === 'Gémeaux'
                    ? Venus.Venus_Gemeaux_Balance
                    : VenusMember === 'Cancer'
                    ? Venus.Venus_Cancer_Balance
                    : VenusMember === 'Lion'
                    ? Venus.Venus_Lion_Balance
                    : VenusMember === 'Vierge'
                    ? Venus.Venus_Vierge_Balance
                    : VenusMember === 'Balance'
                    ? Venus.Venus_Balance_Balance
                    : VenusMember === 'Scorpion'
                    ? Venus.Venus_Scorpion_Balance
                    : VenusMember === 'Sagittaire'
                    ? Venus.Venus_Sagittaire_Balance
                    : VenusMember === 'Capricorne'
                    ? Venus.Venus_Capricorne_Balance
                    : VenusMember === 'Verseau'
                    ? Venus.Venus_Verseau_Balance
                    : Venus.Venus_Poissons_Balance
                  : transAstral[context.user1.themeAstral.plantes.Venus] ===
                    'Taureau'
                  ? VenusMember === 'Bélier'
                    ? Venus.Venus_Belier_Taureau
                    : VenusMember === 'Taureau'
                    ? Venus.Venus_Tauraeu_Taureau
                    : VenusMember === 'Gémeaux'
                    ? Venus.Venus_Gemeaux_Taureau
                    : VenusMember === 'Cancer'
                    ? Venus.Venus_Cancer_Taureau
                    : VenusMember === 'Lion'
                    ? Venus.Venus_Lion_Taureau
                    : VenusMember === 'Vierge'
                    ? Venus.Venus_Vierge_Taureau
                    : VenusMember === 'Balance'
                    ? Venus.Venus_Balance_Taureau
                    : VenusMember === 'Scorpion'
                    ? Venus.Venus_Scorpion_Taureau
                    : VenusMember === 'Sagittaire'
                    ? Venus.Venus_Sagittaire_Taureau
                    : VenusMember === 'Capricorne'
                    ? Venus.Venus_Capricorne_Taureau
                    : VenusMember === 'Verseau'
                    ? Venus.Venus_Verseau_Taureau
                    : Venus.Venus_Poissons_Taureau
                  : transAstral[context.user1.themeAstral.plantes.Venus] ===
                    'Gémeaux'
                  ? VenusMember === 'Bélier'
                    ? Venus.Venus_Belier_Gemeaux
                    : VenusMember === 'Taureau'
                    ? Venus.Venus_Tauraeu_Gemeaux
                    : VenusMember === 'Gémeaux'
                    ? Venus.Venus_Gemeaux_Gemeaux
                    : VenusMember === 'Cancer'
                    ? Venus.Venus_Cancer_Gemeaux
                    : VenusMember === 'Lion'
                    ? Venus.Venus_Lion_Gemeaux
                    : VenusMember === 'Vierge'
                    ? Venus.Venus_Vierge_Gemeaux
                    : VenusMember === 'Balance'
                    ? Venus.Venus_Balance_Gemeaux
                    : VenusMember === 'Scorpion'
                    ? Venus.Venus_Scorpion_Gemeaux
                    : VenusMember === 'Sagittaire'
                    ? Venus.Venus_Sagittaire_Gemeaux
                    : VenusMember === 'Capricorne'
                    ? Venus.Venus_Capricorne_Gemeaux
                    : VenusMember === 'Verseau'
                    ? Venus.Venus_Verseau_Gemeaux
                    : Venus.Venus_Poissons_Gemeaux
                  : transAstral[context.user1.themeAstral.plantes.Venus] ===
                    'Bélier'
                  ? VenusMember === 'Bélier'
                    ? Venus.Venus_Belier_Belier
                    : VenusMember === 'Taureau'
                    ? Venus.Venus_Tauraeu_Belier
                    : VenusMember === 'Gémeaux'
                    ? Venus.Venus_Gemeaux_Belier
                    : VenusMember === 'Cancer'
                    ? Venus.Venus_Cancer_Belier
                    : VenusMember === 'Lion'
                    ? Venus.Venus_Lion_Belier
                    : VenusMember === 'Vierge'
                    ? Venus.Venus_Vierge_Belier
                    : VenusMember === 'Balance'
                    ? Venus.Venus_Balance_Belier
                    : VenusMember === 'Scorpion'
                    ? Venus.Venus_Scorpion_Belier
                    : VenusMember === 'Sagittaire'
                    ? Venus.Venus_Sagittaire_Belier
                    : VenusMember === 'Capricorne'
                    ? Venus.Venus_Capricorne_Belier
                    : VenusMember === 'Verseau'
                    ? Venus.Venus_Verseau_Belier
                    : Venus.Venus_Poissons_Belier
                  : transAstral[context.user1.themeAstral.plantes.Venus] ===
                    'Cancer'
                  ? VenusMember === 'Bélier'
                    ? Venus.Venus_Belier_Cancer
                    : VenusMember === 'Taureau'
                    ? Venus.Venus_Tauraeu_Cancer
                    : VenusMember === 'Gémeaux'
                    ? Venus.Venus_Gemeaux_Cancer
                    : VenusMember === 'Cancer'
                    ? Venus.Venus_Cancer_Cancer
                    : VenusMember === 'Lion'
                    ? Venus.Venus_Lion_Cancer
                    : VenusMember === 'Vierge'
                    ? Venus.Venus_Vierge_Cancer
                    : VenusMember === 'Balance'
                    ? Venus.Venus_Balance_Cancer
                    : VenusMember === 'Scorpion'
                    ? Venus.Venus_Scorpion_Cancer
                    : VenusMember === 'Sagittaire'
                    ? Venus.Venus_Sagittaire_Cancer
                    : VenusMember === 'Capricorne'
                    ? Venus.Venus_Capricorne_Cancer
                    : VenusMember === 'Verseau'
                    ? Venus.Venus_Verseau_Cancer
                    : Venus.Venus_Poissons_Cancer
                  : transAstral[context.user1.themeAstral.plantes.Venus] ===
                    'Lion'
                  ? VenusMember === 'Bélier'
                    ? Venus.Venus_Belier_Lion
                    : VenusMember === 'Taureau'
                    ? Venus.Venus_Tauraeu_Lion
                    : VenusMember === 'Gémeaux'
                    ? Venus.Venus_Gemeaux_Lion
                    : VenusMember === 'Cancer'
                    ? Venus.Venus_Cancer_Lion
                    : VenusMember === 'Lion'
                    ? Venus.Venus_Lion_Lion
                    : VenusMember === 'Vierge'
                    ? Venus.Venus_Vierge_Lion
                    : VenusMember === 'Balance'
                    ? Venus.Venus_Balance_Lion
                    : VenusMember === 'Scorpion'
                    ? Venus.Venus_Scorpion_Lion
                    : VenusMember === 'Sagittaire'
                    ? Venus.Venus_Sagittaire_Lion
                    : VenusMember === 'Capricorne'
                    ? Venus.Venus_Capricorne_Lion
                    : VenusMember === 'Verseau'
                    ? Venus.Venus_Verseau_Lion
                    : Venus.Venus_Poissons_Lion
                  : transAstral[context.user1.themeAstral.plantes.Venus] ===
                    'Vierge'
                  ? VenusMember === 'Bélier'
                    ? Venus.Venus_Belier_Vierge
                    : VenusMember === 'Taureau'
                    ? Venus.Venus_Tauraeu_Vierge
                    : VenusMember === 'Gémeaux'
                    ? Venus.Venus_Gemeaux_Vierge
                    : VenusMember === 'Cancer'
                    ? Venus.Venus_Canser_Vierge
                    : VenusMember === 'Lion'
                    ? Venus.Venus_Lion_Vierge
                    : VenusMember === 'Vierge'
                    ? Venus.Venus_Vierge_Vierge
                    : VenusMember === 'Balance'
                    ? Venus.Venus_Balance_Vierge
                    : VenusMember === 'Scorpion'
                    ? Venus.Venus_Scorpion_Vierge
                    : VenusMember === 'Sagittaire'
                    ? Venus.Venus_Sagittaire_Vierge
                    : VenusMember === 'Capricorne'
                    ? Venus.Venus_Capricorne_Vierge
                    : VenusMember === 'Verseau'
                    ? Venus.Venus_Verseau_Vierge
                    : Venus.Venus_Poissons_Vierge
                  : transAstral[context.user1.themeAstral.plantes.Venus] ===
                    'Scorpion'
                  ? VenusMember === 'Bélier'
                    ? Venus.Venus_Belier_Scorpion
                    : VenusMember === 'Taureau'
                    ? Venus.Venus_Tauraeu_Scorpion
                    : VenusMember === 'Gémeaux'
                    ? Venus.Venus_Gemeaux_Scorpion
                    : VenusMember === 'Cancer'
                    ? Venus.Venus_Cancer_Scorpion
                    : VenusMember === 'Lion'
                    ? Venus.Venus_Lion_Scorpion
                    : VenusMember === 'Vierge'
                    ? Venus.Venus_Vierge_Scorpion
                    : VenusMember === 'Balance'
                    ? Venus.Venus_Balance_Scorpion
                    : VenusMember === 'Scorpion'
                    ? Venus.Venus_Scorpion_Scorpion
                    : VenusMember === 'Sagittaire'
                    ? Venus.Venus_Sagittaire_Scorpion
                    : VenusMember === 'Capricorne'
                    ? Venus.Venus_Capricorne_Scorpion
                    : VenusMember === 'Verseau'
                    ? Venus.Venus_Verseau_Scorpion
                    : Venus.Venus_Poissons_Scorpion
                  : transAstral[context.user1.themeAstral.plantes.Venus] ===
                    'Sagittaire'
                  ? VenusMember === 'Bélier'
                    ? Venus.Venus_Belier_Sagittaire
                    : VenusMember === 'Taureau'
                    ? Venus.Venus_Tauraeu_Sagittaire
                    : VenusMember === 'Gémeaux'
                    ? Venus.Venus_Gemeaux_Sagittaire
                    : VenusMember === 'Cancer'
                    ? Venus.Venus_Cancer_Sagittaire
                    : VenusMember === 'Lion'
                    ? Venus.Venus_Lion_Sagittaire
                    : VenusMember === 'Vierge'
                    ? Venus.Venus_Vierge_Sagittaire
                    : VenusMember === 'Balance'
                    ? Venus.Venus_Balance_Sagittaire
                    : VenusMember === 'Scorpion'
                    ? Venus.Venus_Scorpion_Sagittaire
                    : VenusMember === 'Sagittaire'
                    ? Venus.Venus_Sagittaire_Sagittaire
                    : VenusMember === 'Capricorne'
                    ? Venus.Venus_Capricorne_Sagittaire
                    : VenusMember === 'Verseau'
                    ? Venus.Venus_Verseau_Sagittaire
                    : Venus.Venus_Poissons_Sagittaire
                  : transAstral[context.user1.themeAstral.plantes.Venus] ===
                    'Capricorne'
                  ? VenusMember === 'Bélier'
                    ? Venus.Venus_Belier_Capricorne
                    : VenusMember === 'Taureau'
                    ? Venus.Venus_Tauraeu_Capricorne
                    : VenusMember === 'Gémeaux'
                    ? Venus.Venus_Gemeaux_Capricorne
                    : VenusMember === 'Cancer'
                    ? Venus.Venus_Cancer_Capricorne
                    : VenusMember === 'Lion'
                    ? Venus.Venus_Lion_Capricorne
                    : VenusMember === 'Vierge'
                    ? Venus.Venus_Vierge_Capricorne
                    : VenusMember === 'Balance'
                    ? Venus.Venus_Balance_Capricorne
                    : VenusMember === 'Scorpion'
                    ? Venus.Venus_Scorpion_Capricorne
                    : VenusMember === 'Sagittaire'
                    ? Venus.Venus_Sagittaire_Capricorne
                    : VenusMember === 'Capricorne'
                    ? Venus.Venus_Capricorne_Capricorne
                    : VenusMember === 'Verseau'
                    ? Venus.Venus_Verseau_Capricorne
                    : Venus.Venus_Poissons_Capricorne
                  : transAstral[context.user1.themeAstral.plantes.Venus] ===
                    'Poissons'
                  ? VenusMember === 'Bélier'
                    ? Venus.Venus_Belier_Poissons
                    : VenusMember === 'Taureau'
                    ? Venus.Venus_Tauraeu_Poissons
                    : VenusMember === 'Gémeaux'
                    ? Venus.Venus_Gemeaux_Poissons
                    : VenusMember === 'Cancer'
                    ? Venus.Venus_Cancer_Poissons
                    : VenusMember === 'Lion'
                    ? Venus.Venus_Lion_Poissons
                    : VenusMember === 'Vierge'
                    ? Venus.Venus_Vierge_Poissons
                    : VenusMember === 'Balance'
                    ? Venus.Venus_Balance_Poissons
                    : VenusMember === 'Scorpion'
                    ? Venus.Venus_Scorpion_Poissons
                    : VenusMember === 'Sagittaire'
                    ? Venus.Venus_Sagittaire_Poissons
                    : VenusMember === 'Capricorne'
                    ? Venus.Venus_Capricorne_Poissons
                    : VenusMember === 'Verseau'
                    ? Venus.Venus_Verseau_Poissons
                    : Venus.Venus_Poissons_Poissons
                  : VenusMember === 'Bélier'
                  ? Venus.Venus_Belier_Verseau
                  : VenusMember === 'Taureau'
                  ? Venus.Venus_Tauraeu_Verseau
                  : VenusMember === 'Gémeaux'
                  ? Venus.Venus_Gemeaux_Verseau
                  : VenusMember === 'Cancer'
                  ? Venus.Venus_Cancer_Verseau
                  : VenusMember === 'Lion'
                  ? Venus.Venus_Lion_Verseau
                  : VenusMember === 'Vierge'
                  ? Venus.Venus_Vierge_Verseau
                  : VenusMember === 'Balance'
                  ? Venus.Venus_Balance_Verseau
                  : VenusMember === 'Scorpion'
                  ? Venus.Venus_Scorpion_Verseau
                  : VenusMember === 'Sagittaire'
                  ? Venus.Venus_Sagittaire_Verseau
                  : VenusMember === 'Capricorne'
                  ? Venus.Venus_Capricorne_Verseau
                  : VenusMember === 'Verseau'
                  ? Venus.Venus_Verseau_Verseau
                  : Venus.Venus_Poissons_Verseau}{' '}
                »
              </p>
            </div>
          </div>
        )}
      </div>
      <UserForm
        open={open}
        onClose={handleClose}
        index={1}
        //setUserInfo={setUserInfo}
      />
    </IdsContext.Provider>
  );
}
