import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import { Button } from '@material-ui/core/';
import { LivePrevItem } from './LivePrevItem';
import useStyles from './styles';

export const LivePrevPromo = (props) => {
  const { onClose, open, data } = props;
  const classes = useStyles();
  console.log(data);
  const debDate = data?.start_date.split('T')[0];
  const endDate = data?.end_date.split('T')[0];

  const debDateAff = `${debDate?.split('-')[2]}/${debDate?.split('-')[1]}/${
    debDate?.split('-')[0]
  }`;
  const endDateAff = `${endDate?.split('-')[2]}/${endDate?.split('-')[1]}/${
    endDate?.split('-')[0]
  }`;

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={onClose}
      >
        <div className={classes.globContainer}>
          <div className={classes.mainContainer}>
            <div className={classes.mainMainContainer}>
              <div className={classes.mainLayout}>
                <p className={classes.promoText}>Promotion en cours !</p>
                <div style={{ padding: '1rem' }}>
                  <div className={classes.titleLayout}>
                    <img
                      src={`${process.env.REACT_APP_IMG}${data?.image}`}
                      style={{ width: '25%' }}
                      alt='icone'
                    />
                    <h1 className={classes.pinkText}>{data?.title}</h1>
                    <img
                      src={`${process.env.REACT_APP_IMG}${data?.image}`}
                      style={{
                        width: '25%',
                        transform: 'scale(-1)',
                      }}
                      alt='icone'
                    />
                  </div>
                  <p
                    className={classes.pinkText}
                  >{`Du ${debDateAff} Au ${endDateAff}`}</p>
                  <div className={classes.itemLayout}>
                    <LivePrevItem mois={1} pourc={data?.Percentage / 100} />
                    <LivePrevItem mois={3} pourc={data?.Percentage / 100} />
                    <LivePrevItem mois={6} pourc={data?.Percentage / 100} />
                  </div>
                </div>
                <div className={classes.buttLayout}>
                  <Button
                    className={classes.leftButt}
                    disabled={true}
                    variant='outlined'
                    color='primary'
                  >
                    J'en profite
                  </Button>

                  <Button
                    variant='outlined'
                    disabled={true}
                    className={classes.rghtButt}
                  >
                    Fermer
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Backdrop>
    </div>
  );
};
