import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    avatar: {
        width: 250,
        height: 250,
        borderRadius: 125,
        marginTop: 10
    },
    avatarContainer: {
        margin: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center'
    },
    box: {
        boxShadow: '0px 3px 6px #00000029',
        backgroundColor: 'white',
        borderRadius: 20,
        elevation: 5,
        marginTop: theme.spacing(20),
        marginBottom: theme.spacing(15),
        margin: 30,
        padding: 20,
        flex: 1.2,
    },
    actionsBox: {
        backgroundColor: 'transparent',
        marginTop: theme.spacing(15),
        marginBottom: theme.spacing(15),
        margin: 30,
        padding: 20,
        flex: 1.5,
        display: 'flex',
        flexDirection:'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    MenuButton: {
        color: '#e21067',
        borderWidth: 0,
        textTransform: 'none',
        textDecoration: 'none',
        fontFamily: "SF-Pro-Display-Light",
        padding: 10,
        fontSize:17
    }
}));
