import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
//import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Typography, Button } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import { TextField, Select } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import { Formik, Form, Field } from 'formik';
import useStyles from './styles';
import { MessageZone } from './MessageZone';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    borderRadius: 50,
  },
}))(MuiDialogContent);

export default function PromotionFormEvent(props) {
  var classes = useStyles();
  const { onClose, open, setUserInfo } = props;
  const [canSave, setCanSave] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [PromoData, setPromoData] = React.useState(null);
  const [msg, setMsg] = React.useState('');

  useEffect(() => {
    /* if (props.id !=null){
    (async () => {
      const url =
        `${process.env.REACT_APP_API_URL}admin-user/memberProfile/` + props.id;
      const response = await fetch(url);
      const data = await response.json();
      setPromoData(data);
    })();
  } */
  }, [props.id]);
  const isNotEmpty = (currentValue) => currentValue != '';
  if (!loading) {
    return (
      <>
        <Dialog
          onClose={() => onClose()}
          aria-labelledby='customized-dialog-title'
          open={open}
        >
          <Typography
            style={{
              alignSelf: 'center',
              paddingTop: 30,
              padding: 20,
              fontFamily: 'SF-Pro-Display-Bold',
            }}
          >
            Ajouter une promotion
          </Typography>
          <DialogContent>
            <Formik
              initialValues={{
                name: '',
                start_date: '',
                end_date: '',
                Message: '',
                Promo24: 0,
                Promo1: 0,
                Promo3: 0,
                Promo6: 0,
              }}
              enableReinitialize
              validate={(values) => {
                setCanSave([values.name, msg].some(isNotEmpty));
                const errors = {};
                /* if (!values.email) {
                  errors.email = "Required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                    values.email
                  )
                ) {
                  errors.email = "Invalid email address";
                } */
                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                let dtoTOTransfer = {};
                dtoTOTransfer.name = values.name;
                dtoTOTransfer.Message = msg;
                dtoTOTransfer.Type = 'Event';

                console.log(dtoTOTransfer);

                //setLoading(true);
                const url = `${process.env.REACT_APP_API_URL}admin-user/createPromo`;
                const response = await fetch(url, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(dtoTOTransfer),
                });
                onClose();
              }}
            >
              {({
                errors,
                isSubmitting,
                setValues,
                /* and other goodies */
              }) => (
                <Form style={{ width: 400 }}>
                  <Grid container>
                    <Grid item xs={12} sm={12} spacing={4}>
                      <text className={classes.title}>Nom de la promotion</text>
                      <Field
                        component={TextField}
                        name='name'
                        type='name'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ marginTop: 15 }}>
                      <text className={classes.title}>Message</text>
                      <MessageZone msg={msg} setMsg={setMsg} />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 30,
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        variant='outlined'
                        color='error'
                        onClick={() => {
                          onClose();
                        }}
                      >
                        Annuler
                      </Button>
                      <div style={{ width: 20 }} />
                      <Button
                        className={classes.submit}
                        type='submit'
                        disabled={!canSave}
                        variant='outlined'
                        color='primary'
                      >
                        {loading ? 'patientez svp' : 'Valider'}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}
