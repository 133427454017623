import React from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { TextField } from 'formik-material-ui';

export let MessageZone = (props) => {
  let { msg, setMsg } = props;
  return <ReactQuill value={msg} onChange={setMsg} theme='snow' id={'id'} />;
};
