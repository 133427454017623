/* eslint-disable  no-unused-expressions */

import * as React from 'react';
import bcrypt from 'bcryptjs';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';
import { useState } from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useDispatch } from 'react-redux';
import { SignOutUser } from '../../slices/userSignIn';

const useStyle = makeStyles(() => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '1rem',
  },
  algBut: {
    alignSelf: 'flex-end',
  },
  redTxt: {
    color: 'red',
  },
}));

export default function UpadateAdminPswForm(props) {
  const { open, setOpen, user, history } = props;
  const classes = useStyle();
  const [error, setError] = useState();
  const [showPsw, setShowPsw] = useState({
    showPrvPsw: false,
    showCrntPsw: false,
    showConfCrntPsw: false,
  });

  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      PrvPsw: '',
      CrntPsw: '',
      ConfCrntPsw: '',
    },
  });
  const handleClose = () => {
    setOpen(false);
  };

  const data = [
    { name: 'PrvPsw', placeholder: 'Ancien Mot De Passe ' },
    { name: 'CrntPsw', placeholder: 'Nouveau Mot De Passe' },
    { name: 'ConfCrntPsw', placeholder: 'Confirmer Nouveau Mot De Passe' },
  ];

  let onSubmit = (data) => {
    let updatedData = {};
    let chkPsw = bcrypt.compareSync(data.PrvPsw, user.password);
    let chkPswSame = data.CrntPsw === data.ConfCrntPsw;
    let chkLng = data.ConfCrntPsw.length < 8;
    const url = `${process.env.REACT_APP_API_URL}admin-user`;

    !chkPsw
      ? setError(
          'ancien mot de passe invalid,merci de tapez le bon mot de passe'
        )
      : !chkPswSame
      ? setError('mot de passe incompatible')
      : chkLng
      ? setError('mot de passe trop court')
      : (setError(''),
        (updatedData = {
          email: user.email,
          role: user.role,
          password: data.CrntPsw,
        }),
        console.log(updatedData),
        fetch(url, {
          method: 'PUT',
          headers: {
            mode: 'no-cors', // no-cors,
            'Access-Control-Allow-Origin': 'http://127.0.0.1:3000',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            credentials: 'same-origin',
            'Cross-Origin-Resource-Policy': 'same-site same-origin',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedData),
        })
          .then((response) => response.json())
          .then((data) => {
            handleClose();
            dispatch(SignOutUser(history));
          })
          .catch((error) => {
            setError('Problem de serveur !!!');
          }));
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <div className={classes.formContainer}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {data.map((d, i) => (
              <Controller
                name={d.name}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'ce champ est obligatoire',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={JSON.stringify(errors[d.name]) !== undefined}
                    helperText={
                      JSON.stringify(errors[d.name]) !== undefined
                        ? errors[d.name].message
                        : ''
                    }
                    key={i}
                    margin='dense'
                    label={d.placeholder}
                    type={showPsw[`show${d.name}`] ? 'text' : 'password'}
                    fullWidth
                    variant='standard'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() =>
                              setShowPsw({
                                ...showPsw,
                                [`show${d.name}`]: !showPsw[`show${d.name}`],
                              })
                            }
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                          >
                            {!showPsw[`show${d.name}`] ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            ))}
            <Button type='submit'>Modifier</Button>
          </form>
          {error ? <p className={classes.redTxt}>{error}</p> : ''}
          <Button className={classes.algBut} onClick={handleClose}>
            Annuler
          </Button>
        </div>
      </Backdrop>
    </div>
  );
}
