import React from "react";
import { Link } from "react-router-dom";
// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";

export default function SubMenu(props) {
    var classes = useStyles();

    return (
        <div style={{ justifyContent: "center", display: 'flex', marginTop: 10 }}>
            <Link to="/loveattraction/promotions" className={classes.MenuButton} style={{ textDecoration: props.active === '1' ? 'underline' : 'none' }}>
                Promotions
            </Link>
            <Link to="/loveattraction/EventPromo" className={classes.MenuButton} style={{ textDecoration: props.active === '2' ? 'underline' : 'none' }}>
                Promotions d'événement
            </Link>
            <Link to="/loveattraction/CodePromotions" className={classes.MenuButton} style={{ textDecoration: props.active === '3' ? 'underline' : 'none' }}>
                Code promotionnel
            </Link>
            <Link to="/loveattraction/Influencer" className={classes.MenuButton} style={{ textDecoration: props.active === '4' ? 'underline' : 'none' }}>
                Code influenceurs
            </Link>
        </div>
    );
}