export const Homme_Femme_Astro = {
    Belier_Belier : {
        Slogan:"« Relation passionnelle, mais houleuse, avec réconciliation sur l’oreiller. »",
        LVA :"L’attraction est spontanée, particulièrement basée sur une forte attirance physique. Relation explosive, passionnée dans tous les sens du terme. La monotonie ne fait pas partie de votre vie de couple, mais un peu de calme ne vous ferait pas de mal car vos caractères vifs et impulsifs créent régulièrement des conflits dévastateurs. Le problème entre vous deux :  L’autorité. Pour que cette relation fonctionne, il faut que l’un accepte la domination de l’autre. ",
        Plus:"Une passion ardente – Sexualité fougueuse – Enthousiasme – Absence de routine – Dynamisme – L’amour du sport – Complicité de potes.",
        Moins:"Amour qui ne dure pas – Passion feu de paille – Instabilité des sentiments – Deux personnes très colériques et impulsives.",
        Craquer:"Sa sensualité et ses courbes féminines vont titiller votre instinct de chasseur, elle vous plaît et vous la désirez. Mais rendre des comptes et être scotché l’un à l’autre, très peu pour vous ! Trop exigeante, elle vous étouffera vite et vous agacera avec ses crises de jalousies pour un oui et pour un non.",
        Coach:"Vous aurez un coup de foudre au premier regard et vous vous laisserez emporter par  la même fougue. Vous vibrerez de tout votre être et vous aimerez  ça. Vous vivrez une relation addictive qui vous dévorera et deviendra obsessionnelle."
    } ,
    Belier_Taureau :{
        Slogan:"« Ils ne vivent pas au même rythme et se perdent rapidement de vue. »",
        LVA :"Bonne compatibilité pour une association professionnelle.  Le Bélier est créateur d’idées et le Taureau saura organiser et gérer les projets. Au niveau affectif, vous aimez tous les deux le sexe, mais  vos tempéraments diamétralements opposés, créeront des relations  de couples impossibles. Le Taureau sensuel, mais possessif, exige une relation de qualité aussi bien physiquement que sentimentalement, stable et fidèle. Le Bélier, lui, est passionné, mais instable, infidèle, libre de toute obligation envers l’autre.",
        Plus:"Le Taureau calme le Bélier – Nuits chaudes sous la couette – Le Taureau limite les dépenses excessives du Bélier – Le Bélier donne de l’élan à la relation.",
        Moins:"Le Bélier n’aime pas la routine et le Taureau est rassuré par la stabilité – L’infidélité du Bélier met le fidèle Taureau dans des rages folles.",
        Coach:"Leur relation ne pourra qu’être purement sensuelle, car les exigences et la possessivité du Taureau seront totalement incompatibles avec l’infidélité notoire du Bélier. L’agressivité gâchera leur complicité érotique. Relation sensuelle, mais éphémère."
    } ,
    Belier_Gemeaux : {
        Slogan:"« Vous vous cherchez, vous vous trouvez, puis vous vous quittez, une passion éphémère. »",
        LVA :"Forte attraction, mais dans une relation « sexfriends ». Vous n’aimez pas la routine, mais vous  aimez le jeu. Attention, à force de vous chercher vous risquent de vous perdre. La passion du Bélier transporte le Gémeau plus cérébral. La relation est diffuse, légère, où le sérieux n’est pas de mise, deux signes faits pour les changements et les relations multiples, mais pas pour un engagement à long terme ou en tout cas avec beaucoup de désaccords et d’infidélité que le Bélier exclusif aura du mal à supporter.",
        Plus:"Une franche partie de rigolade – Une relation jeune et légère – Une relation sur un rythme d’enfer – Relation festive et insouciante – Une sexualité sans tabous.",
        Moins:"Le concours à l’infidélité – Les mensonges – Le Bélier attise la nervosité du Gémeau – Le Bélier est brut, le Gémeau est raffiné.",
        Craquer:"Entre vous, c’est le jeu de la séduction, le jeu du « je t’aime moi non plus », mais qu’est-ce que ça vous émoustille ! Vous adorez ça, mais point trop n’en faut, car vous vous lasserez vite. Vous détestez perdre votre temps avec une allumeuse qui ne sait  pas ce qu’elle veut. Vous, vous jouez pour gagner...",
        Coach:"L’ennui ne fait pas partie de cette histoire, mais le sérieux non plus, c’est leur jeu du chat et de la souris qui les rendra addictifs l’un de l’autre.  Relation immature basée sur la spontanéité."
    },
    Belier_Cancer :{
        Slogan:"« Un guerrier et une pacifiste. Auront du mal à trouver un terrain d’entente. »",
        LVA :"L’entente sera difficile, car vos objectifs amoureux divergent vraiment. Le Bélier est indépendant et supporte mal l’attitude protectrice du Cancer. Le Bélier est insaisissable alors que le Cancer recherche à être rassuré, câliné. Le Bélier est blessant et le Cancer hypersensible. Vos univers amoureux sont beaucoup trop différents. Seule l’attraction physique peut permettre une durée pour cette relation. Le Cancer sera séduit par les insistances du Bélier, mais déstabilisé par son  désintérêt aussi soudain.",
        Plus:"Une sexualité harmonieuse et brûlante remplie de fantasmes – Le Bélier dominant et masculin rassurera le Cancer plus féminisé et soumis.",
        Moins:"Le Bélier aime le sexe pour le sexe, le Cancer aime le sexe pour l’amour – Le Bélier est trop agressif pour la sensibilité du Cancer.",
        Craquer:"Elle vous plaît beaucoup car vous la trouvez ultra-féminine. Vous aimez les femmes sensuelles et aguicheuses comme elle, mais le romantisme, ce n’est  pas votre  truc. Vous, vous allez droit au but, et  vous ne  vous encombrez  pas de fioritures. Voilà un point fondamental  d’incompatibilité entre vous et cette  incompréhension amoureuse vous  séparera.",
        Coach:"Certes, le Cancer a l’art et la manière d’exciter le Bélier grâce à un charme ravageur, mais son immaturité et ses caprices incessants, lasseront rapidement le Bélier, qui n’hésitera pas à aller voir ailleurs, déstabilisant au passage la confiance du Cancer. Relation sensuelle, mais conflictuelle. "
    } ,
    Belier_Lion :{
        Slogan:"« Deux conquérants de l’amour, tous feux tout flamme se disputant l’autorité. »",
        LVA :"C’est l’amour fusionnel, tous deux dans une quête d’un amour idéalisé, où la passion est indissociable des sentiments. De nature généreuse, vous êtes tous les deux très narcissiques et égocentriques, cela pourrait être le seul bémol à votre compatibilité, car vous avez l'un et l'autre besoin de briller en société et d’être le centre d’intérêt. Votre sexualité est débridée et l’attraction bien réelle. Seule vos natures autoritaires peuvent assombrir ce joli tableau ainsi que l’infidélité du Bélier que le Roi Lion ne pardonnerait pas.",
        Plus:"Relation fusionnelle – Relation d’admiration mutuelle – Une volonté et une énergie constructive – Une sexualité passionnée.",
        Moins:"Une rivalité empreinte de jalousie peut s’installer entre ces deux personnages narcissiques – L’infidélité du bélier pourrait tout gâcher.",
        Craquer:"Lorsque vous la voyez, vous avez envie de la conquérir, c’est un si beau trophée, rayonnante et éblouissante, elle vous subjugue. La passion entre vous est ardente et la sexualité épanouie. Tout roule entre vous, mais vous ne supporterez pas qu’elle vous fasse de l’ombre, il ne peut y avoir qu’un « Number one » et c’est vous.",
        Coach:"Vous serez emportés par un tourbillon d’amour fou et de sexualité intense. Il suffira de vous regarder pour voir à quel point vous êtes épanouis. Seuls vos conflits d’autorité feront trembler les murs. « Les feux de l’amour.» "
    } ,
    Belier_Vierge :{
        Slogan:"« La vierge, méfiante, n'est pas convaincue par les démonstrations d'amour du bélier »",
        LVA :"Un couple dans une totale incompréhension du fonctionnement de l’autre. La Vierge farouche et analytique a du mal à comprendre le fougueux Bélier qui part au galop et réfléchit plus tard. La Vierge a besoin d’être rassurée pour se lâcher alors que le Bélier se jette à corps perdu dans l’amour. La Vierge aura tendance à refroidir les élans du Bélier, ce qui risque de le faire fuir rapidement. Cependant le Bélier, par ses insistances, peut réussir à décoincer cette amoureuse prudente qui deviendra \"vierge folle\".",
        Plus:"Les grandes discussions philosophiques – Jouer au chat et à la souris – Dévergonder cette nature réservée et laisser s’exprimer une passion insoupçonnée. ",
        Moins:"L’instabilité du bélier et le besoin de sécurité de la vierge – Le bélier s’ennuie avec la vierge et il ne supporte pas ses critiques.",
        Craquer:"Vous serez attiré par cette beauté discrète, réservée, empreinte de mystère, ce qui  n’est pas pour vous déplaire. Vous l’emporterez dans un tourbillon amoureux auquel elle ne saurait résister. Mais attention, elle est coriace et méfiante la bougresse, et elle pourrait bien calmer vos ardeurs  de ses piques venimeuses et de ses critiques acerbes.",
        Coach:"La séduction tenace et persévérante du Bélier, ne sera pas pour déplaire à la Vierge, réservée et en manque de confiance en elle. Le Bélier aime les défis et les murailles infranchissables, mais se projeter dans un avenir commun, pour la Vierge, il n'en est pas question. "
    } ,
    Belier_Balance :{
        Slogan:"« Une attraction incontrôlable entre ces deux êtres pourtant si différents, mais ça colle. »",
        LVA :"Vous vivrez l’attirance et la répulsion comme deux aimants. Le Bélier est un tentateur qui n’aime pas qu’on lui résiste, la Balance elle, a du mal à résister à la tentation et succombera à ses assauts  insistants. La Balance, raffinée et oisive, séduisante et charmeuse, va envoûter cet incorrigible chasseur. La Balance saura canaliser les emportements du Bélier par sa diplomatie. Leurs étreintes seront explosives et le Bélier saura révéler les désirs profonds de la Balance.",
        Plus:"Une attirance incontrôlée – Une passion dévorante – La balance arrive à attacher le bélier. ",
        Moins:"La Balance prévoyante surveille son infidèle bélier – Deux caractères forts qui s’affrontent dans des colères terribles.",
        Craquer:"Vous serez hypnotisé par sa beauté et serez prêt à tout pour l’enflammer. Elle vous fera languir, car madame n’est pas une femme facile et un peu \"vieille France\", mais vous vous consumerez d’amour pour elle. Elle vous agacera parfois avec ses goûts de luxe et ses caprices de star lorsqu’elle se la joue un peu trop vamp.",
        Coach:"C’est la rencontre de Mars et de Vénus, la communion parfaite de l’homme et de la Femme. C’est l’amour passion dans une quête de la perfection. "
    } ,
    Belier_Scorpion :{
        Slogan:"« Un bras de fer qui gâchera l’attirance instinctive de ces deux guerriers. »",
        LVA :"Attention relation sous haute tension sexuelle, tous deux addicts au sexe, vous vous retrouverez à tester toutes les positions du kamasutra et à essayer sans tabous les expériences les plus insolites. 46%  concentrés dans l’érotisme du couple, car le tandem  Bélier/Scorpion ne s’accorde, ni d’un point de vue psychologique, ni d’un point de vue intellectuel. Le Scorpion plus subtil et sadique désoriente totalement le Bélier franc et direct sans réflexions.",
        Plus:"Le sexe of course – Une passion dévorante à se consumer – Deux excessifs en quête d’absolu.",
        Moins:"Le bélier franc trouvera le scorpion pernicieux et fourbe – Leurs excès déborderont en colères violentes – Avec eux c’est l’amour et la guerre.",
        Craquer:"Vous ne pourrez rester de marbre devant une telle bombe sexuelle, elle est « so sexy », vous vous sentirez littéralement envoûté et esclave de ses moindres désirs. Mais vous ne lui permettrez jamais de vous dominer, l’homme c’est vous et ses crises d’hystéries n’y changeront rien.",
        Coach:"On ne sait quel est celui qui entraîne réellement l’autre dans ses délires excessifs. Elle le provoque de ses tenues affriolantes et lui démarre au quart de tour, prêt à toutes les folies, sa libido en éveil. Mais leurs ébats terminés, c’est la guerre.  "
    } ,
    Belier_Sagittaire :{
        Slogan:"« Une complicité évidente pour ces deux jouisseurs. Le plaisir avant tout ! »",
        LVA :"Une osmose et des goûts communs pour les découvertes, le sport et les voyages. Tous deux très généreux, vous vous accorderez sur votre vision du couple, plutôt libre et sans contraintes. Partager de bons moments de plaisir et de joie de vivre correspondent à vos attentes communes. D’un point de vue sexe, l’objectif est de partager un moment délicieux, ardent et sans artifices, juste pour le plaisir, sans attendre plus de l’autre.",
        Plus:"Une relation sous le signe de la bonne humeur – Une passion tempérée – Une relation libre – Des loisirs communs comme le sport.",
        Moins:"Une relation un peu légère basée sur la complicité et risquant de devenir copain –copine - La fidélité n’est pas au programme",
        Craquer:"Sportive et toujours partante pour une grande aventure, vous aimez partager  des moments de loisirs avec elle, vous êtes sur la même longueur d’ondes. Vous la trouvez agréable, drôle,  enthousiaste et surtout elle vous comprend, ne vous impose aucune contrainte. Sexuellement, sans tabous, c’est l’éclate. Dommage qu’elle ne soit pas plus sexy, plus provocante.",
        Coach:"Vous vous entendez comme deux larrons en foire. Actifs et aventuriers, vous serez toujours prêts à vivre pleinement vos envies, ce qui compte c’est le fun, sans prise de tête. Votre devise est Carpe Diem. Relation basée sur le plaisir. "
    } ,
    Belier_Capricorne :{
        Slogan:"« Difficile de trouver une quelconque affinité, lorsque l’un avance l’autre recule ! »",
        LVA :"Difficile de trouver un terrain d’entente entre ces deux signes à cornes. Vous enfoncez les portes, alors que Madame Capricorne s’efforce de grimper des montagnes escarpées. Vous êtes rapide et fougueux, elle est lente et/ou prudente. Vous aurez bien du mal à vous retrouver sur un point de compatibilité. Au niveau sentimental, le fossé se creuse. Vous êtes plutôt exhibitionniste et extraverti, elle est pudique et introvertie. Vous réussirez cependant à dévoiler quelques fantasmes enfouis de Madame Capricorne.",
        Plus:"La droiture et la franchise – Personnes de principes – Des natures contraires qui peuvent être curieuses de se découvrir.",
        Moins:"Deux personnalités entêtées – Autoritaires et intransigeantes – Peu d’affinités sinon aucune. ",
        Craquer:"Avec elle c’est un vrai challenge, car pour un excité comme vous, il va en falloir de la patience pour la conquérir, et la patience ce n’est pas trop votre fort, surtout dans les histoires de cœur. Très réservée, voir même distante, vous aurez du mal à trouver le mode d’emploi pour la séduire, décidément trop compliquée pour vous.",
        Coach:"Le Bélier soufflera sur les braises de la passion alors que le Capricorne s'évertuera à les éteindre, trop d'emballements le pétrifient. Le Bélier disparaîtra, dépité, en l'espace d'un éclair laissant le Capricorne seul, face à ses craintes. Relation soufflant le chaud et le froid. "
    } ,
        Belier_Verseau :{
        Slogan:"« Un goût commun pour la découverte de sensations insolites. Ils se comprennent. »",
        LVA :"Votre amour commun de liberté peut vous permettre de construire un couple sur une base de complicité et d'union libre. Madame Verseau est originale et curieuse et se laissera volontiers emporter dans votre folle passion. Vous apprécierez qu'elle ne vous étouffe pas et qu'elle ferme les yeux sur vos \"coups de canif\" dans le contrat. Vous partagerez des loisirs, des amitiés si chères à votre équilibre de vie. Votre relation sera surtout basée sur de la tolérance et sur l’espace que l’un concédera à l’autre.",
        Plus:"Le respect de la liberté de chacun - Une sexualité basée sur la découverte de nouvelles expériences – La passion.",
        Moins:"La distance du verseau qui peut sembler de l’indifférence – Les excès de colère du bélier- Ils ont tous deux des natures très insouciantes avec l’argent.",
        Craquer:"Elle vous attirera instantanément, un peu comme un aimant. Aérienne, un peu mystérieuse,  elle déclenchera chez vous l’étincelle qui ne tardera pas à vous embraser. Tout est simple et anti-conventionnel avec elle, vous ne vous ennuyez jamais et vous ne vous sentirez pas étouffer. Vous aimeriez peut être qu’elle soit un peu moins indépendante et plus accroc à vous ?",
        Coach:"Ils ne craindront pas de s'évader  ensemble dans une folle aventure, aussi longtemps que l'excitation demeurera. Ils partageront le goût de la liberté et de l'insolite ainsi que  l'amour sans tabous. Relation fusionnelle dans la compréhension mutuelle. "
    } ,
    Belier_Poissons :{
        Slogan:"« Vous vous effleurez sans jamais vraiment vous toucher. Relation de méfiance. »",
        LVA :"Deux univers incompatibles. Madame Poisson est rêveuse, romantique et vous trouvez que tout ce sentimentalisme n’est que  niaiserie. Vous êtes hypersensible, elle est très susceptible et les rapports seront souvent difficiles,  dans l'incompréhension de ce que l'un attend de l'autre. De temperament belliqueux, vous serez cependant apaisé par sa nature calme, mais qui vous agace fortement aussi. Madame Poisson sera fascinée par votre nature volcanique, mais elle souffrira de votre désintérêt.",
        Plus:"Le poisson désirant plaire absolument au bélier se pliera à tous ses caprices - Relation où chacun trouvera sa place laissant l’autorité au bélier.",
        Moins:"Le poisson  est un romantique né, le bélier exècre l’amour gnangnan – Le bélier entreprend et le poisson attend, quoi ? Il ne le sait même pas lui-même.",
        Craquer:"Douce, attentionnée, vous aurez plaisir à passer des moments intimes avec elle. Comme vous, elle est sensuelle et saura se plier à tous vos fantasmes. Mais très vite, cette histoire manquera de piment. Vous, vous aimez ce qui  vous résiste, la facilité vous désintéresse, et très vite vous irez chercher ailleurs l’adrénaline qui vous nourrit.",
        Coach:"Le Bélier ne cherche qu'à vivre intensément sa passion alors que le Poisson, insidieusement, cherche à l'attacher.  Mais attention cher Bélier, ne l'approchez pas de trop près car , Telle Médusa, le Poisson pourrait bien vous envoûter, mais juste le temps d’un instant.  Relation de méfiance. "
    } ,
    Taureau_Belier : {
        Slogan:" Ils ne vivent pas au même rythme et se perdent rapidement de vue.”",
        LVA :"Bonne compatibilité pour une association professionnelle.  Le Bélier est créateur d’idées et le Taureau saura organiser et gérer les projets. Au niveau affectif, vous aimez tous les deux le sexe, mais  vos tempéraments diamétralements opposés, créeront des relations  de couples impossibles. Le Taureau sensuel, mais possessif, exige une relation de qualité aussi bien physiquement que sentimentalement, stable et fidèle. Le Bélier, lui, est passionné, mais instable, infidèle, libre de toute obligation envers l’autre.",
        Plus:"Le taureau calme le bélier – Nuits chaudes sous la couette - Le taureau limite les dépenses excessives du bélier – Le bélier donne de l’élan à la relation.",
        Moins:"Le bélier n’aime pas la routine et le taureau est rassuré par la stabilité – L’infidélité du bélier met le taureau fidèle dans des rages folles.",
        Craquer:"Il est tout à fait possible que ce ne soit pas vous qui la remarquiez, ne vous inquiétez pas, c’est une chasseuse, et elle saura faire en sorte que vos yeux se posent sur elle. Cependant, même si elle saura vous inonder de messages sulfureux, sa nature trop masculine voir agressive dans son approche amoureuse ne vous donneront pas envie de poursuivre cette relation. Vous comptez tenir les rênes de l'histoire et faire un bout de chemin avec une femme délicieuse et soumise. Elle est ingérable, excessive, trop exigeante, en vérité elle vous rend nerveux et elle gâche votre quiétude.",
        Coach:"Leur relation ne pourra qu’être purement sensuelle, car les exigences et la possessivité du Taureau seront totalement incompatibles avec l’infidélité notoire du Bélier. L’agressivité gâchera leur complicité érotique. Relation sensuelle, mais éphémère. "
    } ,
    Taureau_Taureau :{
        Slogan:"“ Une vitesse de croisière paisible pour une relation solide et fiable.”",
        LVA :"Bonne entente en général, mais voilà un couple qui va vite sombrer dans l’ennui de leur confort pépère.  Leur sensualité et les plaisirs partagés créeront une complicité indéniable, mais la routine de ces deux fainéants va vite détruire un si beau tableau. Ce duo fonctionnera mieux dans une relation \"sexfriend\", basée uniquement sur les plaisirs. L’infidélité de ces deux épicuriens possessifs va vite créer un tsunami de colères  détruisant toute relation harmonieuse.",
        Plus:"Affection partagée – Sensualité à son comble – Relation chaleureuse – Les plaisirs font totalement partie de la base de cette relation.",
        Moins:"Amour vénal  – Infidélité inévitable –Bouderies incessantes – Colères explosives – Esprits revanchards – Radinerie.",
        Craquer:"Sensuelle et attentionnée, véritable femme d’intérieur avec laquelle on aime partager des moments de plaisir, elle représente la femme idéale. Mais très vite le rêve laissera place au cauchemar, votre dulcinée se transformant en  véritable cerbère, autoritaire et exigeante, vous invectivant  de reproches assassins. Vous ne concevez pas la vie de couple comme un combat de boxe,  ne tenant pas à subir ses caprices intempestifs. Pour vous, l'amour ce n'est pas la guerre, et vous tenez à garder ce rythme doux à votre vie amoureuse.",
        Coach:"Dans une histoire pareille, soit vous vous endormez dans une relation pépère, soit vous vous heurtez à vos caractères trop autoritaires et dictatoriaux. Seule une entente  sensuelle  sera possible. "
    } ,
    Taureau_Gemeaux : {
        Slogan:"“ Le taureau susceptible est agacé par l’instabilité et le sarcasme du gémeau. Relation d'incompréhension  ”",
        LVA :"Entente difficile, car le Taureau très terre à terre a du mal à suivre ce trublion de Gémeau. Le calme et la sécurité font partie de l’équilibre du Taureau qui s’enracine dans sa vie, alors que  le Gémeau  en perpétuel mouvement, est curieux de tout et de son contraire. La stabilité rassure l’un et ennuie l’autre. L’un a besoin de références et déteste les changements, alors l’autre  est à l’affût de toutes les nouveautés. Sexuellement,  l’un aime le corps à corps alors que l’autre est dans le  fantasme.",
        Plus:"Le taureau est séduit par le charme juvénile du gémeau – Bonne  association  professionnelle,  alliant créativité et bonne gestion des projets.",
        Moins:"Le gémeau s’ennuie vite avec le taureau – Le taureau est déconcerté par l’instabilité et les mensonges du gémeau –Ils n’ont pas les mêmes valeurs.",
        Craquer:"Intelligente, elle vous séduira par ses qualités artistiques et sa vivacité d’esprit. Légère, elle ne vous distraira pourtant que  le temps d’un flirt  poussé, car très vite, ses doutes et ses tergiversations vous épuiseront. Vous aimez le calme et la stabilité et cette demoiselle est dans l’incapacité de se poser. Elle vous agace, vous déstabilise et vous met dans des états de nerfs à faire peur. Vous ne tiendrez pas longtemps à ce rythme.",
        Coach:"Un feeling mitigé entre ces deux signes. Le Gémeau est aussi inconsistant et léger qu'un papillon et le Taureau possessif et jaloux, essaie de dompter la nature volage du Gémeau. L'ennui précèdera les conflits et laissera la place à la tristesse. Une incompatibilité évidente les séparera. "
    },
    Taureau_Cancer :{
        Slogan:"“ Ils se complètent à merveille. Tendresse et réconfort sont les maîtres mots de cette relation d’amour.”",
        LVA :"L’association idéale pour fonder un foyer. Le Taureau recherche à construire un foyer sur des fondations solides (confort et matériel) et le Cancer ne conçoit pas l’amour sans la procréation et la construction d’une famille. La sécurité et la tendresse seront le leitmotiv de cette relation. Le sexe sera empreint de sensualité et de tendresse. Un seul bémol, l’hypersensibilité du Cancer pourrait bien  se confronter à la tyrannie du Taureau et à son entêtement.",
        Plus:"La volonté commune d’une relation stable dans la construction pour l’avenir – Une relation basée sur des valeurs familiales simples.",
        Moins:"Relation sans fantaisie – Jalousies et caprices peuvent détruire cette histoire – Le Taureau manque de subtilité pour l’hypersensible Cancer.",
        Craquer:"A la fois féminine jusqu’au bout des ongles et maternelle dans l’âme, elle ne pourra qu’être une candidate sérieuse à votre casting de la femme idéale. Vous verrez dans ses yeux le bonheur d’être dans les bras rassurants d'un homme tel que vous, et cela suffira à vous combler. Seuls ses caprices infantiles vous irriteront, mais vous saurez la recadrer par votre autorité de mâle. Une belle complicité pour construire un foyer.",
        Coach:"La volupté et la sensualité seront le ciment de cette histoire. Tous deux, de nature protectrice, sauront se rassurer mutuellement. Ils regarderont dans le même sens, dans l'envie de construire un foyer rempli d'enfants. "
    } ,
    Taureau_Lion :{
        Slogan:"“ Deux fortes têtes incapables de concéder les rênes du couple à l’autre.”",
        LVA :"Les conflits d’intérêts et de pouvoir vont gâcher cette relation. Tous deux, très ambitieux, avec une grande soif de réussite, pourrez trouver un terrain d’entente constructif,  si vous arrivez à modérer votre  tendance à la domination. Vos signes, (Taureau/Lion) très masculins, sont portés sur la volonté de diriger,  et vous aurez bien du mal à communiquer, sauf sur l’oreiller où l’amour du sexe réussira la prouesse de prolonger le sursis de cette relation vouée à l’échec.",
        Plus:"La Franchise – 	Une volonté commune à la réussite –Une sexualité débridée mais peu imaginative. ",
        Moins:"Guerre de pouvoir et d’égo –Rapports de force –Relation en manque de passion mais conflictuelle – Dialogue de sourds sur fond de mauvaise foi.",
        Craquer:"Eblouissante, vous avez tout de suite remarqué cette beauté noble et altière. Un brin cabotin, la draguer façon \"gentilhomme\" vous amuse, et vous la provoquez en espérant l’intriguer. Mais ses manières hautaines auront tôt fait de vous agacer et vous vous désintéresser de cette donzelle bien trop snob à votre goût. Vous qui aimez la vie simple, vous ne comprendrez pas son goût exacerbé pour le luxe et le clinquant. Trop d'incompréhensions sur des sujets brûlants comme l'argent et le rythme de vie, créeront un climat électrique entre vous.",
        Coach:"Ces deux signes passionnés sauront se séduire par une cour enflammée et par des sorties dans des endroits les plus chics.  Une relation où l’égo se nourrit de l’attrait de l’autre, mais où les coups de gueule se transforment en de violents conflits. Relation où la violence et la domination trahiront un idéal superficiel. "
    } ,
    Taureau_Vierge :{
        Slogan:"“ Un accord parfait pour ces deux terriens qui aspirent à la quiétude d’un foyer.”",
        LVA :"Rencontre de deux solides terriens, qui aimeront ensemble, couler des jours heureux sous le signe de la stabilité et de la sécurité. Le Taureau sait gagner l’argent que la Vierge saura thésauriser en vue de l’achat de leur douillette demeure et d’investissements immobiliers pour leur belle retraite. Ces deux-là sauront avancer main dans la main, comme deux bons gestionnaires. Une vie bien orchestrée  avec un  manque évident de piment et de fantaisie. Une sensualité en harmonie sans surprises.",
        Plus:"Goût pour la stabilité – La confiance – La droiture – Tous deux sont des laborieux – Epicuriens – Ils aiment les choses simples et vraies",
        Moins:"Manque de passion évident – Vie ennuyeuse et rigide – Certains taureaux sont infidèles et la vierge est assez constante et droite.",
        Craquer:"Loin de toutes les excentricités, cette femme vous séduit par sa simplicité et surtout par son authenticité. Une femme sans fards ni paillettes, ayant les pieds sur terre. La complicité est harmonieuse, mais sa pudeur amoureuse vous obligera à lui réclamer les gestes tendres et les mots d’amour qui comblent vos besoins affectifs. Sa nature conciliante et serviable saura vous apporter le bien-être dont vous avez besoin, elle réussira à vous choyer comme vous l'espérez par une délicatesse touchante.",
        Coach:"Le Taureau amènera la sécurité financière dont la Vierge a besoin. La Vierge enchantera le Taureau par son dévouement et ses attentions. Une relation de connivence, bien huilée, teintée d'un érotisme lascif. Relation basée sur du solide. "
    } ,
    Taureau_Balance :{
        Slogan:"“ Ces deux vénusiens auront du mal à se supporter bien longtemps malgré leur goût commun pour l’art.”",
        LVA :"Vos signes Vénusiens trouveront à coup sûr une belle entente au niveau sentimental et physique. Ce qui vous réunit, c’est l’amour du beau, de l’esthétique, de l’artistique et de l’oisiveté. Malgré ces affinités, vous abordez la vie de façon radicalement différente et l’harmonie laissera place très rapidement aux malentendus. La Balance raffinée et mondaine aura du mal à supporter la vie trop simple d’un Taureau pépère et grognon.",
        Plus:"L’amour de la beauté – Âmes d’artistes – La  sensualité.",
        Moins:"La légèreté de la balance –Leur matérialisme –L’un aime le foyer l’autre les mondanités – La balance est raffiné et le taureau assez simple parfois grossier.",
        Craquer:"Sa plastique parfaite et son charme subtil vous émoustillera et votre seule pensée sera de conquerir son cœur. Mais avec elle, il y a fort à faire, car elle aime se faire désirer et elle sait faire durer le plaisir. Mais à y regarder de plus près, elle sera trop superficielle pour vous, et ses indécisions vont très rapidement vous agacer. Mais pour qui se prend-elle ? Vous la trouverez snob et futile, elle qui méprise votre vie, la trouvant trop ordinaire à son goût. Vous n'avez pas les mêmes valeurs.",
        Coach:"Ces deux Vénusiens, amoureux de l’amour et aux aspirations artistiques communes, ne réussissent pas à se mettre d’accord, leurs désirs étant trop divergents. Attention aux relations d’intérêts pour ces deux matérialistes. "
    } ,
    Taureau_Scorpion :{
        Slogan:"“ Fascination et défis pour la rencontre de ces deux caractères bien trempés.”",
        LVA :"Relation fusionnelle où l’attraction peut devenir très vite fatale et violente. Dans tous les cas, cette relation sera basée sur un désir impérieux, une sexualité explosive dans une quête unique  de sensations fortes. Insatiables, vous irez toujours plus loin dans les excès en tout genre, ce qui rendra vite l’atmosphère électrique et agressive, car vous manquez tous les deux grandement de souplesse. Obstinés et jaloux vous n'aurez cesse de vous disputer.",
        Plus:"Une attirance incontrôlée par ses bas instincts – Une sexualité débridée – Tous deux recherchent la stabilité – Ils aiment l’argent et le luxe.",
        Moins:"Suspicion – Colères violentes – Rapport de force et de domination – Jalousie – Tyrannie des deux signes – Rancunes – Les ruptures sont empreintes de vengeance.",
        Craquer:"Fascinante et envoûtante, vous ne pourrez pas longtemps résister à l’appel sensuel de cette tigresse. Entre vous, c’est une attirance irrésistible bassement animale. Mais ce trop plein d’émotions, la plongera rapidement dans un climat délétère et dramatique,  empreint d’interrogatoires soupçonneux, de scènes de jalousies violentes et d’écarts de langage assassins, qui vous feront sortir de vos gongs. Vous recherchez fondamentalement une vie paisible et avec elle ce sont les montagnes russes, pas de moments répit, une passion destructrice qui ne peut vous emballer bien longtemps.",
        Coach:"Ils se défieront du regard et le Scorpion attirera le Taureau par son magnétisme envoûteur, mais attention, telle une mante religieuse, le Scorpion veut posséder le Taureau. Vous rentrerez dans un combat de pouvoir. Relation sous haute tension sexuelle. "
    } ,
    Taureau_Sagittaire :{
        Slogan:"“ La liberté de l’un empêchera l’épanouissement de l’autre. Relation manquant de tolérance.”",
        LVA :"Relation difficile, diamétralement opposée dans vos envies et votre façon de voir la vie à deux. Vous avez vos habitudes et vous aimez la routine de la vie de couple, cela vous rassure, alors que Madame Sagittaire ne vit que de changements, de mouvements, c’est une aventurière au sens propre comme au sens figuré. Vous ne vous entendrez que dans la sexualité, car vous êtes tous les deux des jouisseurs  épicuriens qui aiment les plaisirs de la vie. Mais, trop indépendante, Dame Sagittaire fuira votre possessivité, car vous attendez un engagement qu'elle ne sera pas prête à vous garantir.",
        Plus:"Le partage de bons moments, des plaisirs de la chair et de la table – L'amour de la nature,  des plaisirs simples. ",
        Moins:" Le sagittaire est un libre et le taureau veut l’attacher – La jalousie et la possessivité du taureau – L'inconstance du sagittaire  et son infidélité.",
        Craquer:"Son allure sportive vous laisse à penser que vous avez affaire à une personne saine comme vous, aimant la nature, le plein air. En effet, vous aimerez partager ensemble les bons moments de la vie, mais sa sacrosainte liberté va causer quelques heurts avec votre possessivité. Vos objectifs de vie sont si éloignés que la relation ne pourra résister à ces dissensions.",
        Coach:"La possessivité du Taureau va se heurter à l’excès d’indépendance du Sagittaire. Malgré leurs goûts communs pour les plaisirs simples de la vie, le Taureau tentera en vain de freiner l’exubérance du Sagittaire ce qui plongera le Taureau dans un sentiment d’insécurité. "
    } ,
    Taureau_Capricorne :{
        Slogan:"“ Relation basée sur la confiance, avec la patience on arrive à tout.”",
        LVA :"Ces deux-là savent œuvrer pour l’avenir, patients et déterminés vous prendrez le temps de vous connaître et construirez les fondations de votre relation sur une confiance mutuelle. Vous vous comprennez et savez exactement ce que l’un attend  de l’autre. Votre générosité et votre chaleur humaine ravivera la flamme de Madame Capricorne et la mettra en confiance. La base de votre histoire d’amour est le respect de l’autre. Sexuellement, votre passion saura transcender Madame Capricorne, plus réservée.",
        Plus:"Un respect mutuel les  anime – Leur nature posée les rassure – Le hasard ne fait pas partie de cette relation, tout est réfléchi - La solidité terrienne de la relation.",
        Moins:"La chaleur du taureau ne compensera pas toujours la froideur du capricorne – Les ambitions de l’un peut amener la perte de l’autre.",
        Craquer:"Sa nature réservée vous rassure car vous n’aimez ni les vamps ni les hystériques. Vous, vous recherchez votre âme sœur, celle avec qui partager votre cocon douillet et vous la désirez droite, fidèle et sincère. Avec elle, vous avez trouvé cette perle rare, mais vous déplorez son manque d’ardeur et de démonstration amoureuse. Ne vous découragez pas, à force de douceur, elle  saura vous combler de son amour pudique, mais profond, car elle a besoin de temps pour se libérer émotionnellement. Elle saura être une amie-amante soumise à son homme.",
        Coach:"Fondations solides pour ce couple de Terriens qui s’attirent principalement par leur côté pragmatique sécurisant. Le Taureau n’hésite pas à user de tout son charme pour convaincre le Capricorne, très coriace en amour.  Relation équilibrée. "
    } ,
    Taureau_Verseau :{
        Slogan:"“ Dialogue difficile entre ces deux idéalistes divergents. Conservatisme contre démocratie.”",
        LVA :"Madame Verseau aime par-dessus tout sa liberté et ne supporte pas de se justifier ni rendre des comptes, et vous, vous ne supportez pas ce qui vous échappe et avez besoin d’être rassuré en permanence. Vous comprendrez donc que vous aurez bien du mal à trouver un terrain d’entente sur le long terme. De plus, vos scènes de jalousie feront fuir cette solitaire Verseau qui ne supporte pas les éclats inutiles. Un point de divergence encore, vous êtes assez basique alors qu'elle est plutôt intellectuelle.",
        Plus:" La fidélité du taureau séduit le verseau qui lui n’est fidèle qu’à lui-même – 		Le taureau amène une stabilité financière au dépensier verseau.	",
        Moins:"L’indépendance du verseau déstabilise le taureau qui rentre dans des rages folles – L’un est solitaire, l’autre a peur de la solitude.",
        Craquer:"Elle a cette aura de mystère que seules les fées possèdent, ce brin de folie qui vous enchante, mais qui vous déconcerte à vous en faire perdre votre discernement. Son inaccessibilité vous stimule, bien déterminé à la conquérir. Mais vos natures diamétralement opposées, ne vous laissent que peu d’espoir à la victoire. Elle ne sacrifiera pour rien au monde sa liberté-chérie, ignorera vos ultimatums ce qui vous mettra dans des rages folles, puis elle s'éloignera pour retrouver la quiétude qu'elle aime tant.",
        Coach:"L'attraction est éphémère, mais avec un désir d’explorer un univers inconnu, car le Taureau se méfie du style libéré du Verseau, qu’il interprète mal et qui exacerbe sa jalousie, étouffant ainsi le Verseau. Une relation très chaotique, un défi impossible. "
    } ,
    Taureau_Poissons :{
        Slogan:"“ Relation de tendresse où chacun rassure l’autre de ses sentiments.”",
        LVA :"Douce alchimiste entre vos deux signes.  Vous partagez un goût prononcé pour les plaisirs de la chair et de la table, le romantisme au coin du feu, la musique, le cinéma. D’un point de vue sexuel, la magie opère là aussi. Mais cette relation ne sera pas sans faille, vous êtes pragmatique et terre à terre, et les rêveries utopistes et l’indécision de Madame  Poisson  vous irriteront assez rapidement. Elle aura du mal à comprendre toute cette surexcitation.",
        Plus:"Affinités de goûts – Envie d’amour partagé – Besoin d ‘attentions et de tendresse – Sexualité épanouie.",
        Moins:"Le taureau est carré, terre à terre, le  poisson est utopiste, il fuit la réalité – Le poisson se contente de peu alors que le taureau a besoin de son confort.",
        Craquer:"Elle dégage une fragilité qui vous touche et qui  vous donne envie de la protéger. Docile et aimante, elle vous comblera par sa sensibilité amoureuse, ses petits mots doux et ses sentiments délicats.  Mais vous aurez du mal à comprendre certaines de ses incohérences qui déclencheront chez vous de violentes colères. Vous n'avez pas confiance en elle, et vous serez torturé par des doutes et  l’incompréhension de sa personnalité nébuleuse. Vous vous posez des questions sur sa sincérité, sur sa fidélité et cela vous rend dingue. Vous n'êtes plus vous-même, un état psychologique qui exacerbe votre nervosité et amplifie vos colères.",
        Coach:"Une attraction sensuelle qui sera le détonateur d’un embrasement amoureux pour ces deux hédonistes prêts à expérimenter une vaste palette de plaisirs. Le seul bémol, le pragmatisme du Taureau aura du mal à comprendre les rêveries du Poisson. "
    } ,
    Gemeaux_Belier : {
        Slogan:"“ Vous vous cherchez, vous vous trouvez puis vous vous quittez. une passion éphémère.”",
        LVA :"Forte attraction, dans une relation plutôt sexfriend. Vous n’aimez pas la routine, mais vous aimez le jeu. Cependant, attention, à force de vous chercher, vous risquez de vous perdre. La passion du Bélier transporte le Gémeau  plus cérébral. La relation est diffuse, légère où le sérieux n’est pas de mise, deux signes faits pour les changements et les relations multiples, mais pas pour un engagement à long terme ou en tout cas, avec beaucoup de désaccords et d’infidélité que le Bélier, exclusif, aura du mal à supporter.",
        Plus:"Une franche partie de rigolade – Une relation jeune et légère –Une relation sur un rythme d’enfer – Relation festive et insouciante – Une sexualité sans tabous.",
        Moins:"Le concours à l’infidélité – Les mensonges –Le bélier attise la nervosité du gémeau – Le bélier est brut, le gémeau est raffiné.",
        Craquer:"Avec elle, vous n’aurez pas besoin de sortir toute votre panoplie du grand séducteur, elle vous a déjà repéré et vous fera savoir que vous lui plaisez. Vous rentrerez dans son petit jeu,  amusé par tant d’audace, et très vite vous déciderez de donner une dimension plus cérébrale à cette histoire. Mais le raffinement intellectuel n’est pas le fort de madame, plus trash et beaucoup moins dans la finesse érotique. Elle voudra vous attirer dans son univers, mais vous ferez de la résistance, l'ignorant tout simplement.",
        Coach:"L’ennui ne fait pas partie de cette histoire, mais le sérieux non plus, c’est leur jeu du chat et de la souris qui les rendra addictifs l’un de l’autre. Relation immature basée sur la spontanéité. "
    } ,
    Gemeaux_Taureau :{
        Slogan:"“ L’instabilité et le sarcasme du gémeau agacent le taureau susceptible. Relation d’incompréhension.”",
        LVA :"Entente difficile, car le Taureau très terre à terre a du mal à suivre ce trublion de Gémeau. Le calme et la sécurité font partie de l’équilibre du Taureau qui s’enracine dans sa vie alors que  le Gémeau  en perpétuel mouvement, est curieux de tout et de son contraire. La stabilité rassure l’un et ennuie l’autre. L’un a besoin de références et déteste les changements, alors que l’autre  est à l’affût de toutes les nouveautés. Sexuellement, l’un aime le corps à corps alors que l’autre est dans le  fantasme.",
        Plus:"Le taureau est séduit par le charme juvénile du gémeau – Bonne  association  professionnelle,  alliant créativité et bonne gestion des projets	.",
        Moins:"Le gémeau s’ennuie vite avec le taureau – Le taureau est déconcerté par l’instabilité et les mensonges du gémeau –Ils n’ont pas les mêmes valeurs.",
        Craquer:"Vous la trouverez super canon et aguicheuse dans sa robe moulante. Elle est si féminine qu’elle éveillera  chez vous des pensées lubriques et des scénarios coquins. Mais attention madame ne joue pas. Elle veut du sérieux, et vous mettra une telle pression que vous vous sentirez étouffer dans cette histoire. Adieu fantasmes et bonjour les prises de tête. Vous êtes de ceux à qui l'on ne dicte pas sa conduite, vous êtes libre dans votre tête et dans votre cœur et comptez bien choisir, sans subir le joug d'une femme aussi sexy soit-elle.",
        Coach:"Un feeling mitigé entre ces deux signes. Le Gémeau est aussi inconsistant et léger qu'un papillon et le Taureau possessif et jaloux, essaie de dompter la nature volage du Gémeau. L'ennui précèdera les conflits et laissera la place à la tristesse. Une incompatibilité évidente les séparera. "
    } ,
    Gemeaux_Gemeaux : {
        Slogan:"“ Amour et fantaisie, pourrait être le titre de leur histoire très friendly.”",
        LVA :"Complicité exceptionnelle de « jumeaux », voilà un couple qui se comprend parfaitement, on peut dire que vous êtes sur la même longueur d’ondes. Vous passez des heures à discuter et à vous amuser comme des adolescents, espiègles et moqueurs vous n’aurez de cesse de vous taquiner. Attention cependant aux caprices et aux railleries blessantes qui pourraient bien déclencher des éclats entre vous. Votre relation est très érotique, mais votre fidélité n’est pas sans failles.",
        Plus:"Complicité exceptionnelle – Goûts communs – Relation jeune et fraiche – Légèreté – Connexion intellectuelle –Créativité – Relation de partage.",
        Moins:"Jeux de mains jeux de vilains – L’infidélité – Une relation trop fraternelle risque de manquer de passion – Instabilité – Nervosité et crises de nerfs.",
        Craquer:"Quelle femme ! On dirait qu’elle lit dans vos pensées, elle vous devine,  sait ce que vous aimez et comment vous séduire. Vous partagez des moments délicieux où vous ne voyez pas le temps s’écouler. Et même dans l’intimité  vos corps et vos esprits sont à l’unisson. Mais seule sa nature capricieuse et jalouse peut gâcher ces instants de grâce. Il faut dire que vous aimez séduire et qu'elle ne voit pas cela d'un  très bon œil, et ça se comprend. Quel dommage, vous avez tant en commun, vous vous amusez si bien ensemble.",
        Coach:"Amour et fantaisie, sont les deux ingrédients déclencheurs de leur coup de cœur. Ensemble ils s’amusent beaucoup et savent érotiser leurs échanges. Mais à la longue, ces deux joyeux drilles, se lasseront de leurs trop grandes similitudes. "
    },
    Gemeaux_Cancer :{
        Slogan:"“ Relation sur fond d’humour et de fantasmes. Mais quel est le plus menteur des deux ?”",
        LVA :"Deux signes jeunes, qui retomberont facilement en enfance. Vous vivrez votre histoire dans une osmose euphorisante, empreinte de rires et de farces. L’idéal pour vous deux, serait de vivre votre relation en union libre car votre vision du couple est bien trop différente. C’est pour cela que votre compatibilité est tantôt bonne tantôt mauvaise. Le  Gémeau extraverti et imprévisible va heurter  l’hypersensibilité et l'émotivité du Cancer. Le Gémeau s’épanouit totalement dans cette relation, alors que le Cancer risque d’en souffrir.",
        Plus:"Deux rêveurs ensembles – La passion dans la légèreté – Le retour en enfance, l’insouciance d’amour de jeunesse.",
        Moins:"La difficulté sera lorsqu’ils parleront de sérieux -L’un est indépendant l’autre pense à deux – L’instabilité du gémeau perturbe le cancer.",
        Craquer:"Elle jouera les femmes fatales pour attirer votre attention,  vous vous amuserez de ses œillades et du trésor d’imagination qu’elle déploiera  pour vous plaire. Vous serez séduit par sa double nature, ingénue effarouchée le jour et geisha déchainée la nuit, l’idéal pour ne pas vous lasser. Mais lorsqu’elle commencera à pester et à vous mettre la pression pour obtenir plus de vous, vous aurez l’impression d’avoir été piégé par une manipulatrice séductrice qui ne cherche, par ses charmes, qu'à se faire épouser. Mais vous n'êtes pas dupe et bien trop cérébral pour vous rendre esclave de vos sens.",
        Coach:"Entre ces deux signes, c’est l’insouciance, l'amour juvénile, frais et enfantin qui manquera à la longue de stabilité et de consistance. L’instabilité du Gémeau déconcertera la quête permanente de protection du Cancer. "
    } ,
    Gemeaux_Lion :{
        Slogan:"“ Champagne ! Une complicité festive, joyeuse, c’est l’amour paillette.”",
        LVA :"Coup de foudre au premier regard, mais sur la durée vos deux fortes personnalités auront du mal à se supporter. Les railleries et vexations du Gémeau auront raison de l’amour inconditionnel du Lion. Vos deux signes sont assez complémentaires et s’entendent bien, mais trop égoïstes, vous vous disputerez la place de la vedette. Votre relation ne sera pas de tout repos. La sexualité est épanouie, mais le Gémeau est un peu trop cérébral pour le Lion plus instinctif et animal.",
        Plus:"L’amour est fort et passionné – Le lion stabilise le gémeau -  La fantaisie – Le goût commun pour l’art et le spectacle.",
        Moins:"Les colères du lion sont redoutables et le gémeau attise la zizanie – Le sarcasme du gémeau agace et vexe le lion qui a besoin d’admiration.",
        Craquer:"Lorsque vous  la verrez déambuler, vous aurez un véritable coup de foudre pour cette beauté féline, sûre d’elle et de son pouvoir de séduction.  Madame ne sera pas facile à charmer et il faudra une bonne dose d’imagination pour qu’elle vous remarque. Mais de l’imagination, vous en avez à revendre et plus la tâche est ardue, plus elle vous excite. Cependant, il ne faudrait pas qu’elle joue trop longtemps les divas, car vous vous désintéresseriez d’elle. Vous aimez les challenges, mais pas les snobs qui, insensibles à vos talents, vous ignorent de leur superbe.",
        Coach:"L'emballement réciproque entre l’air pétillant du Gémeau et le feu conquérant du Lion promet un amour ardent. Une admiration commune attise l’intensité de leur relation et stimule une passion déraisonnable sur fond de rivalité. "
    } ,
    Gemeaux_Vierge :{
        Slogan:"“ Leur cérébralité sera le piment de cette histoire.  Une relation toute Mercurienne.”",
        LVA :"Tous deux Mercuriens, si la Vierge est dite « folle », vous vous entendrez comme deux larrons en foire, avec des affinités particulièrement intellectuelles, vous aimerez discuter, échanger, jouer ensemble. Sexuellement, vos jeux érotiques seront plutôt cérébraux, scénarisés. Cependant, la relation risque de s’essouffler par manque de piment, le Gémeau, assez dispersé, déstabilise la Vierge organisée et l’agace par son manque de cohésion et de constance.",
        Plus:"Une complicité intellectuelle presque fraternelle – Ils se comprennent et ont les mêmes jeux – La créativité du gémeau est matérialisé par la Vierge.",
        Moins:"La nervosité du gémeau perturbe le calme de la vierge –La vierge risque à la longue d’ennuyer le Gémeau.",
        Craquer:"Cette beauté vous attire et vous serez curieux de découvrir qui se cache derrière cette apparente froideur. Comme vous, elle est très cérébrale et méfiante, pour la séduire vous devrez la rassurer et la surprendre  par votre sagacité. De son côté elle saura vous materner, sera prévenante et attentionnée, mais que cela ne lui donne pas le droit de vous agacer avec ses  réflexions moralisatrices et ses complaintes à propos de vos soi-disant défauts !",
        Coach:"Une attraction érotico-intellectuelle où l’esprit est en effervescence, aiguisée par la curiosité et les joutes verbales. Ils se plaisent, d’abord intrigués par leur vivacité d’esprit et admirent leur talent. Mais attention, leur ironie acerbe et l'irritation que cela peut provoquer risque de tout gâcher. "
    } ,
    Gemeaux_Balance :{
        Slogan:"“ Relation aérienne et délirante, manquant parfois de pragmatisme. Couple intemporel.”",
        LVA :"Vous formez un joli couple qui aime les sorties entre amis et les mondanités, vous passerez des heures à discuter de tout et de rien. Tous deux intelligents, raffinés et charmeurs, votre relation est légère comme l’air, où l’insouciance vous guide. Un peu dans les nuages, vous avancerez dans une relation amoureuse  idéalisée. La triste réalité de la vie et ses contraintes quotidiennes risquent de laisser place à la désillusion et aux grincements de dents. Côté sexe, le plaisir est garanti grâce à votre imagination débordante.",
        Plus:"Ils se comprennent  et s’éclatent ensemble – Ils sont tous deux très artistes et manuels – Ils communiquent beaucoup – Ce sont deux imaginatifs.",
        Moins:"A force de dépenser sans compter on ne paye plus ses factures – Les projets restent à l’état de rêve – A trop vouloir du  plaisir on se brûle les ailes.",
        Craquer:"Fasciné par sa beauté, votre seul objectif sera de la faire tomber dans vos bras. Vous comprendrez très vite qu’avec elle vous devrez sortir votre panoplie de gentleman. Mais n’êtes-vous pas l’homme caméléon ? Cependant, vous devrez faire attention que sa prestance en public ne vous fasse pas de l'ombre, la beauté extérieure a souvent tendance à éclipser la beauté de l’esprit, ce qui vous rendrait fou de jalousie, vous qui avez besoin de mobiliser l'attention de votre auditoire.",
        Coach:"Une attraction irrésistible va les rapprocher, il faut dire que le Gémeau est un habile orateur qui va subjuguer la Balance par le raffinement et la diplomatie, qui sont des qualités chères à son cœur. Amour aérien. "
    } ,
    Gemeaux_Scorpion :{
        Slogan:"“ Jeux cérébraux sur fond de manipulation. Rien de constructif dans ce genre de relation.”",
        LVA :"Vous êtes deux provocateurs qui auront du mal à trouver un terrain d’entente sur le long terme. L’inconstance du Gémeau aura tendance à désorienter et exacerber la jalousie et la possessivité du Scorpion qui, un tantinet  paranoïaque entrera dans des colères monstres. Le Gémeau a du mal à comprendre la torture mentale dans laquelle le Scorpion se complet. Le Scorpion plus charnel, attend de son compagnon Gémeau des ébats plus torrides et plus instinctifs, mais là ça risque de coincer.",
        Plus:"Tous les deux sont très curieux, intelligents avec un esprit vif – Ils aiment  la provocation et la polémique.",
        Moins:"L’instabilité du gémeau déconcerte le scorpion – Tous deux sont manipulateurs  - Deux esprits tourmentés et angoissés – L’infidélité.",
        Craquer:"Cette femme est une vraie mante religieuse, à la fois mystérieuse et magnétique, sulfureuse et ingénue. Vous auriez presque peur de vous faire dévorer tout cru, mais vous aimez le risque et elle vous fascine jusqu’au moment où sa nature hystérique va se réveiller. Alors là, c’est \"Courage fuyons !\" Le jeu n’en vaut pas la chandelle, car même si vous aimez le risque, les histoires excitantes voir même compliquées, vous n'aimez pas être manipulé par une femme perverse.",
        Coach:"Attraction dangereuse pour ces deux provocateurs qui se lancent des défis intellectuels dans lesquels ils se découvrent réciproquement avec beaucoup d’enthousiasme, les plongeant inconsciemment dans une relation obscure. "
    } ,
    Gemeaux_Sagittaire :{
        Slogan:"“ Cours après moi que je t’attrape, rien de tel pour aiguiser les sens et tuer l’ennui.”",
        LVA :"La monotonie n’existe pas dans votre relation, car vous avez en commun le goût des mouvements, des déplacements et des voyages. Votre complémentarité crée votre attirance. Tous deux avides de connaissances, vous tirerez pas mal de satisfactions à vos échanges  d’idées. La liberté et la compromission faisant partie de vos personnalités, la fidélité ne sera pas l’ingrédient principal de votre histoire. Votre relation sera plutôt basée sur une amitié amoureuse plus que sur le grand amour.",
        Plus:"Leur amour de la liberté – L’appel des grands espaces – La curiosité intellectuelle – La tolérance et le respect de la liberté de chacun.",
        Moins:"Un manque certain de passion  amoureuse – L’infidélité – Le refus d’engagement –L’hyper-indépendance du sagittaire.",
        Craquer:"Avec elle, pas de faux-fuyants, les choses sont claires et nettes dès  le départ, vous pourrez vivre une belle histoire sans histoires. La tolérance faisant partie de sa philosophie de vie et avec vous il en faut une dose, vous serez heureux de partager, échanger, voyager avec elle et passer des moments de complicité à deux. Un brin de féminité en plus ne serait pas du luxe pour passer au stade de l'excitation, car vous aimez cérébralement imaginer votre compagne dans des dessous affriolants et Madame est un peu garçon manqué, ce qui gâche un peu votre plaisir.",
        Coach:"Une truculente aventure entre ces deux curieux et jouisseurs de la vie qui s’entraîneront dans une relation légère et parfois court vêtue… où les tabous seront absents, l’ennui exclus et la liberté chérie. Mais qu’en est-il de la vraie vie ? "
    } ,
    Gemeaux_Capricorne :{
    Slogan:"“ Trop de lucidité tue le rêve, Trop de fantaisie fait perdre le réalisme. Personnalités en  déséquilibre.”",
        LVA :"Vous n'avez pas grand-chose en commun. On pourrait même dire que vous êtes diamétralement opposés. Vous êtes léger, versatile, bavard et vous aurez du mal à supporter la compagnie de Madame Capricorne trop sérieuse, réservée, peu communicative et de surcroit ennuyeuse, en tout cas pour vous. Elle vous le rendra bien, Madame Capricorne, solitaire amoureuse du calme aura bien du mal à vous supporter, vous considérant  comme un agité du bocal, qui s’excite pour un oui ou pour un non. Côté sexe, ce n’est pas la joie non plus.",
        Plus:"Seule la créativité du gémeau s’accorderait au pragmatisme du capricorne pour une association professionnelle. ",
        Moins:"L’un sérieux, l’autre fantaisiste et inconscient – L'éternelle jeune contre la vieillesse avant l’âge – la liste des dissonances est trop longue à énumérer.",
        Craquer:"Son côté femme réservée, un peu inaccessible et mystérieuse à souhait, vous fascine, et vous userez de tous les subterfuges pour faire sourire cette beauté froide. Mais très vite son manque d’entrain et de passion vous lassera. Vous avez besoin de répondant et ne vous exaltez pas en pratiquant un monologue. Vous avez besoin d'un auditoire et elle semble totalement hermétique à votre humour et ne semble pas fascinée par votre grande culture générale. Entre vous cela n'a pas l'air de coller.",
        Coach:"Rencontre de l’insouciance et de la sagesse, une mayonnaise qui ne prendra probablement pas ou qui ne dépassera pas la période d’essai. Malgré la bouffée d’air frais qui allègera leur relation, le Capricorne s’agacera de tant de frivolités."
        } ,
        Gemeaux_Verseau :{
        Slogan:"“ Personnalités atypiques qui s’imbriquent comme des pièces d’un puzzle.”",
        LVA :"Que de belles perspectives d’avenir pour ce couple, chacun trouvant une stimulation d’idées novatrices chez l’autre, se faisant progresser et sublimant leur créativité. Tous deux signes d’air et donc cérébraux, vous avez en commun la recherche de la complicité intellectuelle. Seul hic, une harmonie très importante au niveau de l’esprit mais un gros bémol au niveau du physique ce qui vous éloignera à terme. Une passion qui deviendra vite une complicité amicale platonique si vous ni mettez pas plus de motivation.",
        Plus:"Une attirance et une osmose intellectuelle – La fusion des idées – Un lien fraternel et complice. ",
        Moins:"Le gémeau est un communiquant loquace alors que  le verseau a besoin de moments à lui, de recueillements – Le sexe est ensommeillé – Relation trop amicale.",
        Craquer:"Mais quelle femme intéressante ! Je ne sais si l’on peut la qualifier de muse, mais  elle vous stimule au niveau de vos pensées et de  votre créativité. Vous ne vous lasserez pas d’échanger avec elle. Avant-gardiste et originale, vous aimeriez la garder auprès de vous, car elle vous fait progresser et vous sublime. Mais elle vous déstabilisera dans vos certitudes, car elle a un  besoin vital de  liberté et refuse  radicalement toute aliénation amoureuse et lorsque vous aimez, vous êtes possessif.",
        Coach:"Une attraction instinctive lie ses deux esprits libres, brillants et originaux. Ils échangeront leurs idées et vivront une histoire d’amour atypique sans tabous où l’imprévu les préservera de la routine."
    } ,
    Gemeaux_Poissons :{
        Slogan:"“ L’agitation de l’un perturbe la quiétude de l’autre.  Incompréhension totale.”",
        LVA :"Entre vous, c'est l'incompréhension totale. Vous jouerez au chat et à la souris,  vous vous tournerez autour, vous vous chercherez sans réellement vous trouver. Votre relation pourrait bien rester à l’état du flirt sans jamais réussir à se concrétiser ou au mieux se transformer en relation purement érotique. Il faut dire qu’une bonne dose d’immaturité jette le trouble entre vous que tout oppose. Attraction et répulsion s’alternent, car votre vision de l’amour est bien trop différente.",
        Plus:"Le flirt -L’illusion d’amour – La rêverie amoureuse  - Une sensualité envoûtante.",
        Moins:"Le rêve ne se transformera pas en réalité – Le gémeau a du mal à comprendre le romantisme mièvre du poisson – Le poisson souffre dans cette relation",
        Craquer:"Vous apprécierez particulièrement toutes les petites attentions qu’elle vous prodiguera, toujours à votre écoute, câline et douce, buvant vos paroles et riant de toutes vos blagues, même les plus nulles. Mais elle est si accommodante pour vous plaire que vous finirez par vous ennuyer, jusqu'à vous demander si elle ne serait pas une dissimulatrice. Vous avez parfois du mal à la cerner, et sa passivité vous agace. Au final vous aimez que l'on vous adule, mais avec une bonne dose de résistance aussi. Un peu compliqué, non? Elle vous est toute acquise et son abnégation dissimule pour vous un manque de personnalité. Vous ne l'admirez pas.",
        Coach:"Relation extrêmement fluctuante et capricieuse, où ces natures très différentes se heurtent dans une incompréhension réciproque. Le Gémeau est très indifférent aux états d’âme du Poisson qui ne comprend pas cette méfiance à son égard. "
    } ,
    Cancer_Belier : {
        Slogan:"« Un pacifiste et une guerrière  auront du mal à trouver un terrain d’entente. ",
        LVA :"L’entente sera difficile car vos objectifs amoureux divergent vraiment. Le Bélier est indépendant et supporte mal l’attitude protectrice du Cancer. Le Bélier est insaisissable alors que le Cancer recherche à être rassuré, câliné. Le Bélier est blessant et le Cancer hypersensible. Vos univers amoureux sont trop différents, seule l’attraction physique peut permettre une durée à cette relation. Le Cancer sera séduit par les insistances du Bélier, mais déstabilisé par son  désintérêt aussi soudain.",
        Plus:"Une sexualité harmonieuse et brûlante remplie de fantasmes – Le bélier dominant et masculin rassurera le cancer plus féminisé et soumis.",
        Moins:"Le bélier aime le sexe pour le sexe, le cancer aime le sexe pour l’amour - Le bélier est trop agressif pour la sensibilité du cancer.",
        Craquer:"Vous avez un besoin constant d’affection et recherchez quelque part une femme qui ressemble à votre mère, cette femme là, avec son côté un tantinet autoritaire vous séduit, une main ferme dans un gant de velours, mais elle manque terriblement de féminité, son allure et ses manières de garçon manqué vous déplaisent et son agressivité refroidit vos ardeurs. Ses réflexions blessantes  ne vous aident pas à vous épanouir et vous vous renfermez dans votre coquille, refusant tout dialogue ce qui l'agace encore plus.",
        Coach:"Certes, le Cancer a l’art et la manière d’exciter le Bélier grâce à un charme ravageur, mais son immaturité et ses caprices incessants, lasseront rapidement le Bélier, qui n’hésitera pas à aller voir ailleurs, déstabilisant au passage la confiance du Cancer. Relation sensuelle mais conflictuelle. "
    } ,
    Cancer_Taureau :{
        Slogan:"« Ils se complètent à merveille. Tendresse et réconfort sont les maîtres mots de cette relation d’amour. »",
        LVA :"L’association idéale pour fonder un foyer. Le Taureau recherche à construire un foyer sur des fondations solides (confort et matériel) et le Cancer ne conçoit pas l’amour sans la procréation et la construction d’une famille. La sécurité et la tendresse seront le leitmotiv de cette relation. Le sexe sera empreint de sensualité et de tendresse. Un seul bémol, l’hypersensibilité du Cancer pourrait bien  se confronter à la tyrannie du Taureau et à son entêtement.",
        Plus:"La volonté commune d’une relation stable dans la construction pour l’avenir – Une relation basée sur des valeurs familiales simples.",
        Moins:"Relation sans fantaisie – Jalousies et caprices peuvent détruire cette histoire – Le Taureau manque de subtilité pour l’hypersensible cancer.",
        Craquer:"Vous serez subjugué par sa grâce naturelle et  par la féminité qu'elle dégage, elle vous trouble. Vous ressentez qu’elle sera  parfaite dans le rôle de la femme au foyer et  une bonne mère pour vos enfants. Elle sait vous prodiguer de douces attentions, vous pensez avoir trouvé votre âme sœur.  Mais ses caprices et son manque de tact, vous font parfois douter de sa sincérité et vous la trouvez particulièrement vénale. Elle s'intéresse un peu de trop près à votre compte en banque, vous questionnant sans vergogne sur vos revenus et réclamant sans cesse des cadeaux.. coûteux....",
        Coach:"La volupté et la sensualité seront le ciment de cette histoire. Tous deux de nature protectrice, sauront se rassurer mutuellement. Ils regarderont dans le même sens, dans l'envie de construire un foyer rempli d'enfants. "
    } ,
    Cancer_Gemeaux : {
        Slogan:"« Relation sur fond d’humour et de fantasmes. Mais quel est le plus menteur des deux ? »",
        LVA :"Deux signes jeunes, qui retomberont facilement en enfance. Vous vivrez votre histoire dans une osmose euphorisante, empreinte de rires et de farces. L’idéal pour vous deux, serait de vivre votre relation en union libre car votre vision du couple est bien trop différente. C’est pour cela que votre compatibilité est tantôt bonne tantôt mauvaise. Le  Gémeau extraverti et imprévisible va heurter  l’hypersensibilité et l'émotivité du Cancer. Le Gémeau s’épanouit totalement dans cette relation, alors que le Cancer risque d’en souffrir.",
        Plus:" Deux rêveurs ensembles – La passion dans la légèreté – Le retour en enfance, l’insouciance d’amour de jeunesse.",
        Moins:"La difficulté sera lorsqu’ils parleront de sérieux -L’un est indépendant l’autre pense à deux – L’instabilité du gémeau perturbe le cancer.",
        Craquer:"Légère et court vêtue, elle vous fera sourire avec ses allures de jeune fille en fleur, mutine et malicieuse, vous aimerez rire et partager ensemble des jeux d’adultes coquins et sensuels. Mais lorsque vous désirerez aborder votre avenir sous un angle plus sérieux, votre belle vous glissera entre les doigts ou fera mine de ne pas comprendre. Cela vous déstabilisera, car vous avez besoin de vous projeter dans un futur amoureux constructif, créer votre nid d'amour pour faire des enfants. Elle, telle un papillon de nuit, vit légèrement, au jour le jour, fuyant les planifications d'avenir. Ne la mettez pas au pied du mur, car vous la perdriez.",
        Coach:"Entre ces deux signes, c’est l’insouciance, un amour juvénile, frais et enfantin qui manquera à la longue de stabilité et de consistance. L’instabilité du Gémeau déconcertera la quête permanente de protection du Cancer."
    },
    Cancer_Cancer :{
        Slogan:"« Connivence sensorielle, vous n’avez pas besoin de parler pour vous comprendre. »",
        LVA :"Tous deux, sensibles et romantiques avez une envie commune de  construire un nid douillet dans une perspective d’un bonheur parfait.  Et pourtant, votre  hypersensibilité se transformera vite en hyper-susceptibilité, les rancœurs prendront vite la place du bonheur idyllique.  Chacun aura l’impression d’être le seul à se dévouer à l’autre, décortiquant  et analysant chaque acte ou parole. Le précieux temps de votre relation sera gâcher à ressasser des griefs futiles.",
        Plus:"Une relation douce et romantique – Une sexualité épanouie – Une volonté de construction affective.",
        Moins:" Une hyper-susceptibilité qui créera des conflits – Une immaturité qui desservira leurs projets d’avenir – Deux êtres capricieux.",
        Craquer:"Cette femme caméléon saura réaliser vos fantasmes les plus secrets. Aujourd’hui, elle se mettra dans la peau d’une femme fatale qui vous subjuguera et demain, dans la confidente qui saura vous rassurer et apaiser vos angoisses. Elle sera la femme idéale jusqu’à ce que ses caprices gâchent votre rêve et que la pression de ses assauts  vous oppresse et vous plonge dans une déprime destructrice. Vous aurez l'impression d'avoir été manipulée par une illusionniste fort habile. La prise de conscience de votre naïveté à croire à des chimères vous propulsera dans une désarroi dont vous aurez du mal à vous soustraire.",
        Coach:"Une attraction magique dans une émotion à son paroxysme. Tel Narcisse, ces deux signes jumeaux aiment leur double et se plongent dans un monde merveilleux où l’amour romantique est roi. La triste réalité de la vie mettra fin à leur rêve illusoire."
    } ,
    Cancer_Lion :{
        Slogan:"« Relation de dominant et dominé. Si le cancer se soumet tout sera possible. »",
        LVA :"Dans ce genre de relation, le Cancer devra s’attendre à se soumettre totalement à la volonté et l’autorité de son seigneur et maître le Lion. Le Cancer, assez passif, peut supporter une telle situation de dominant-dominé et acceptera de s’effacer sous le joug du Lion au bénéfice de la vie de famille et des tâches quotidiennes du foyer, pendant que le Lion prendra du bon temps. Cependant, l’hypersensibilité du Cancer sera mise à rude épreuve face aux excès colériques de cet animal autoritaire.",
        Plus:"La docilité du cancer – La protection du lion –L’admiration sans failles du cancer. ",
        Moins:"La fragilité nerveuse et émotionnelle du cancer – La brusquerie du lion – Les colères du lion –Les bouderies et autres vexations.",
        Craquer:"Vous serez ébloui par cette majestueuse diva, féminine jusqu’au bout des ongles qui vous envoûtera de son aura de séductrice. Flatté qu’une telle beauté s’intéresse à vous, vous emploierez les grands moyens pour la faire vôtre. Passionnée, elle répondra à vos attentes et s’engagera totalement dans votre relation, mais pour la garder, vous devrez affirmer  votre identité de mâle dominant car Madame est autoritaire et pourrait bien vous asservir. Dans tous les cas, votre rébellion ou votre soumission n'y changeront rien, car vous n'êtes pas à la hauteur d'une telle personnalité.",
        Coach:"Rencontre sensuelle toute relative tant que les sujets sérieux ne seront pas abordés. L’autorité du Lion ne pourra convenir que momentanément au caractère capricieux du Cancer qui suffoquera sous ce despotisme blessant. "
    } ,
    Cancer_Vierge :{
        Slogan:"« Un attachement quasi maternel. Difficile de les décoller l’un de l’autre »",
        LVA :"Ces deux signes ne pourront que s’entendre. Vous êtes tous les deux sensibles, réservés, pudiques, simples dans votre vision du couple et de l’amour désireux d’un bon confort et de la quiétude dans le foyer. Votre passion n’est pas débordante, mais sincère et rassurante, entre la réserve et le lâcher-prise. Seul bémol à ce charmant duo, les mensonges et autres divagations du Cancer que la Vierge pragmatique et réaliste aura du mal à supporter.",
        Plus:"La confiance est de mise entre ces deux signes méfiants – Ils regardent dans la même direction – Une envie de calme et de stabilité les anime.",
        Moins:"Les rêves fantasmagoriques du cancer se heurtent au réalisme de la vierge – Les mensonges du cancer – Les critiques et la rigueur de la vierge.",
        Craquer:"Ses mots et ses gestes attentionnés vous apporteront un réel réconfort dans vos moments de doute et de désarroi. Fine psychologue  et organisée, elle saura dénouer les imbroglios de votre esprit complexe qui a tendance à tout compliquer. Mais écorché vif, vous serez blessé par ses critiques acerbes. Son manque de tendresse et d’affection, vous plongeront dans  une détresse amoureuse. Vous vous sentirez alors jugé et non soutenu, lâché par celle que vous pensiez conforme à vos attentes.",
        Coach:"Une attraction toute relative au départ, avec une bonne dose de méfiance qui s’estompera au fil du temps pour laisser place à un amour profond. Les attentions de la Vierge seront récompensées par la dévotion amoureuse et sensuelle du Cancer."
    } ,
    Cancer_Balance :{
        Slogan:"« Une love attraction spontanée mais fragile. Deux séducteurs savent se reconnaître !  »",
        LVA :"Pas de répulsions entre ces deux signes, mais de là à faire votre vie ensemble, il ne faudrait pas exagérer non plus. Diamétralement opposés vous aurez du mal à vivre autre chose qu’une relation purement érotique et éphémère. Mais vous apprécierez les moments de douceur que vous partagerez ensemble. Cependant, la Balance éternelle séductrice aime la vie mondaine et rechigne aux travaux ménagers à l’instar du Cancer qui aime sa maison et a plaisir à s’y enfermer.",
        Plus:"Des moments de grâce sensuels – Le goût pour l’amour – Relation calme sans éclats.",
        Moins:"Une incompatibilité essentielle sur la vie de couple – La balance est mondaine et le cancer aime la famille.",
        Craquer:"Vous ne pourrez qu’être emballé par l’extrême féminité qu’émane Madame Balance. Elégante et gracieuse vous serez sous le charme. Mais très vite, vous vous rendrez compte qu’elle ne correspond absolument pas à vos critères de recherche de la femme idéale, trop superficielle et nombriliste, vous ne la visualiserez pas en bonne mère de famille. Madame est frivole et rechigne à la vie conventionnelle d’une femme au foyer. Elle aime se pavaner dans les dîners mondains et méprise les femmes au foyer esclaves de leurs familles qu'elle compare à des Cendrillons. Une vision bien restrictive du couple !",
        Coach:"Attraction de ces deux romantiques qui cultivent le désir du grand amour. Des débuts enflammés ou chacun pensera avoir trouvé son âme-sœur, mais tôt ou tard, le conte de fées se transformera en sordide règlement de compte. Amour illusoire. "
    } ,
    Cancer_Scorpion :{
        Slogan:"« Une fascination envoûtante, une fusion des sens. Relation magique. »",
        LVA :"Vous êtes tous les deux animés et dirigés par vos intuitions et de fortes émotions. Vous vous accordez aussi bien sur le plan amoureux que sur le plan sexuel. Le Scorpion aime intensément, jalousement et exige une fidélité sans faille, ce que le Cancer n’aura pas de mal à lui consacrer, car cela fait partie de ses principes moraux. Le caractère malléable et soumis du Cancer s’adaptera parfaitement aux exigences et à la tyrannie du Scorpion. Seules les paroles blessantes du Scorpion pourraient ébrécher cette union magique.",
        Plus:"Des émotions transcendées – Une relation fusionnelle– Deux caractères qui se complètent – Une énergie sexuelle tantrique –Chacun trouve sa place. ",
        Moins:"La tyrannie et la cruauté du scorpion risque de déstabiliser cette relation idyllique – La passivité du cancer agace le  dynamisme du scorpion.",
        Craquer:"L’attraction est immédiate avec cette beauté sulfureuse, son magnétisme opère immédiatement sur vous, elle vous envoûte et vous emporte dans une folle passion. Vous pourrez explorer avec elle les profondeurs de vos fantasmes les plus inavoués et découvrir des sensations qui vous transporteront au 7ème ciel. Seules ses crises d’hystérie dans des moments de doutes déséquilibreront cette harmonieuse histoire d’amour. De plus, il est à craindre que sa nature cyclothymique risque de vous  plonger dans une profonde dépression.",
        Coach:"Une attraction irrésistible mue par de violentes émotions. Un lien intense où se mêlent fascination et tourments. Une relation ou le Cancer sera la  victime consentie et le Scorpion son tortionnaire. Un amour torturé où l’érotisme joue un rôle important. "
    } ,
    Cancer_Sagittaire :{
        Slogan:"« Des débuts prometteurs mais des envies trop divergentes pour que ça colle. »",
        LVA :"Le Sagittaire, aventureux et voyageur,  alimente l’imaginaire du Cancer, doux rêveur, qui voit en cette relation l’ouverture vers un amour exotique et hors norme. Tout sera possible entre vous, à partir du moment où aucune discussion sérieuse ne sera entamée. Dans le cas contraire, notre aventurier des temps modernes n’hésitera pas 5 minutes à prendre la poudre d’escampettes. Le plaisir avant tout, mais pourquoi voudrait-il donc échanger sa vie de liberté pour une vie remplie d’obligations ?",
        Plus:"Ils sont tous deux épicuriens- Ont leur tête remplie de rêves- L’imagination est au centre de leur vie – 	Ils aiment faire la fête.",
        Moins:"Le sagittaire aime être libre sans obligations alors que le cancer a besoin d’avoir une vie d’obligations – L’infidélité.",
        Craquer:"Vous qui êtes souvent triste et nostalgique, elle vous apportera sa bonne humeur et vous bercera de précieux conseils optimistes et philosophiques sur la vie. Mais cette sportive, dynamique, par son instabilité, vous épuisera rapidement, vous qui ne rêvez que de calme et de cocooning. Insaisissable et indomptable, vous ne  comprenez pas cette amazone qui n’a pas besoin d’un homme et qui vous le fait ressentir. Malgré tous vos efforts à l'attacher et à la convertir à votre cause, vous sentirez qu'elle vous échappe inexorablement.",
        Coach:"Une rencontre qui ne peut être que le fruit d’un heureux hasard galant. Leur peu d’affinités ne peut espérer beaucoup plus qu’un moment d’égarement. Le pétillant Sagittaire sera vite refroidi par les fluctuations émotionnelles du très angoissé Cancer. "
    } ,
    Cancer_Capricorne :{
    Slogan:"« Des différences qui ne nuiront pas à leur bien-être. La chaleur rassurante de l’amour cimentera la relation. »",
        LVA :"Malgré vos discordances, vous vous complètez parfaitement. Madame Capricorne méfiante de nature, masque sous son air austère un  manque affectif  qui la paralyse dans ses histoires de cœur. Grâce à votre sensibilité exacerbée, vous devinerez les attentes de tendresse de cette grande frustrée et vous saurez la combler par votre douceur et vos attentions. De votre coté,  vous vous sentirez protégé et rassuré par son honnêteté inébranlable. Cette relation s’inscrit dans la durée, seule leur nature pessimiste peut faire des dégâts.",
        Plus:"Ils se sont trouvés et comblent leurs manques respectifs – Ont tous deux besoin d’être rassurés. ",
        Moins:"Une sexualité timide – Le manque de tendresse et de romantisme du capricorne – L’hypersensibilité du cancer.",
        Craquer:"Sa réserve et sa discrétion vous rassureront. Vous n’aimez pas les excentriques, car vous recherchez une femme équilibrée pour vous accompagner sur le chemin de l’amour. Bien sûr, son manque de démonstration affective vous tourmenteront, mais vous redoublerez d’attentions en espérant que votre belle se dégèlera sous vos assauts de tendresse. Après tout, ses nombreuses qualités compenseront largement cette lacune et en feront une candidate sérieuse au mariage.",
        Coach:"L’alchimie entre ces deux signes n’est pas toujours évidente. Seule la persévérance du Cancer à percer la cuirasse défensive du Capricorne par de tendres attentions et une sensualité débordante, réussiront à développer un amour profond entre eux. "
        } ,
    Cancer_Verseau :{
        Slogan:"« Lorsque la passé s’affronte au futur, difficile de trouver un terrain d’entente. »",
        LVA :"Vos recherches amoureuses sont bien trop différentes pour que l’amour s’installe entre vous. Vous êtes un incurable romantique, qui désespère devant l’indifférence de Madame Verseau qui fuit le sentimentalisme \"marshmallow\". Intellectuelle, cérébralisant ses sentiments, elle a du mal à les verbaliser, alors que vous, très émotionnel, avez besoin de vous épancher sur le sujet, ce qui ne tardera pas à étouffer l’indépendante Madame Verseau. Bien sûr votre magnétisme la séduira, mais pour si peu de temps...",
        Plus:"Le besoin de se rendre utile – L’humanisme.",
        Moins:"L’un est passéiste et l’autre avant-gardiste, l’un romantique l’autre réaliste –Tout les sépare.",
        Craquer:"Sa nature légère et rêveuse vous amusera, vous partagerez ensemble des moments exquis d’égarements. Mais très vite vous vous rendrez compte que tout vous sépare. Assez indisponible pour vos têtes à têtes en amoureux, vous aurez du mal à la coincer et devrez prendre un rdv au gré de son agenda surbooké d’occupations, que vous jugerez futiles au regard de l’être exceptionnel que vous êtes. Vous ne supportez pas qu’elle vous néglige. Vous avez tant besoin de la présence de votre compagne pour votre équilibre moral et amoureux, que ce genre de relation vous met mal à l'aise.",
        Coach:"Improbable compatibilité pour ces deux signes, dont les orientations de vie divergent à 180°, le Cancer est passéiste alors que le Verseau avant-gardiste. Seule leur fantaisie érotique peut à un moment donné les rapprocher, mais pour si peu de temps… "
    } ,
    Cancer_Poissons :{
        Slogan:"« Ils ondulent dans un monde fantasmagorique proche de l’extase. »",
        LVA :"Une attraction magnétique et sensorielle relie ces deux signes d’eau.  Intuitifs et affectueux, vous êtes sur la même longueur d’onde. Votre union sera basée sur un fort besoin de sécurité affective et sur une sexualité imaginative, avec une soif de sensations et d’abandon de soi. L’amour sera au centre de cette alchimie. Mais vous serez toujours à l’affût des réelles motivations Madame Poisson,  fuyant et refusant de se dévoiler totalement. Ce sera la seule ombre au tableau de cette fusion charnelle.",
        Plus:"Une alchimie des énergies – Un amour romantique dans la sublimation des sens et des énergies.",
        Moins:"Le manque de réalisme – La passivité – Ils vivent d’amour et d’eau fraîche.",
        Craquer:"Attentive à vos moindres désirs, vous aurez tendance à vous laisser porter par cette douce amoureuse, qui vous comblera de gestes tendres, de câlins savoureux, à qui vous pourrez confier vos craintes et avec laquelle vous partagerez des moments de pur bonheur érotiques. Mais sa nonchalance, son abnégation et sa fuite des responsabilités risque de faire ressortir votre mauvaise nature dictatoriale et agressive envers cette passive contemplatrice. Et puis qu'a-t-elle à vous harceler de questions sur vos plannings et vos moindres faits et gestes ? Trop pleurnicheuse à votre goût, elle vous agace par ses plaintes et trop jalouse, elle vous étouffe de ses crises.",
        Coach:"Des émotions exaltantes sont à l’origine de cette rencontre magique des âmes. Relation douce et poétique où les plaisirs et l’appel des sens jouent un rôle important dans la symbiose de cette union du corps et de l’esprit. Extase amoureuse guidée par leur sixième sens. "
    } ,
    Lion_Belier : {
        Slogan:"« Deux conquérants de l’amour, tous feux tout flamme se disputant l’autorité. »",
        LVA :"C’est l’amour fusionnel. Vous êtes dans une quête d’un amour idéalisé, où la passion est indissociable des sentiments. De nature généreuse, vous êtes deux narcissiques égocentriques et cela pourrait être le seul bémol à votre compatibilité, car vous avez besoin de briller en société et d’être le centre d’intérêt. Votre sexualité est débridée et l’attraction bien réelle. Cependant, vos natures autoritaires peuvent assombrir ce joli tableau ainsi que l’infidélité du Bélier que le Lion ne pardonnerait pas.",
        Plus:"Relation fusionnelle – Relation d’admiration mutuelle – Une volonté et une énergie constructive – Une sexualité passionnée.",
        Moins:"Une rivalité empreinte de jalousie peut s’installer entre ces deux personnages narcissiques – l’infidélité du bélier pourrait tout gâcher.",
        Craquer:"Il n’aura suffi que d’un seul regard pour qu’une étincelle  allume le feu de la passion entre vous. Vous ne pouviez que la remarquer. Aguicheuse, elle a su attirer votre attention par une danse sensuelle envoûtante à votre intention. Seuls ses comportements dirigistes vous agaceront et vous obligeront à affirmer votre suprématie dans le couple, attention alors à des rapports de force cinglants. Réconciliation assurée sur l’oreiller...",
        Coach:"Vous serez emportés par un tourbillon d’amour fou et de sexualité intense. Il suffira de vous regarder pour voir à quel point vous êtes épanouis. Seuls vos conflits d’autorité feront trembler les murs. « Les feux de l’amour ». "
    } ,
    Lion_Taureau :{
        Slogan:"« Deux fortes têtes incapables de concéder les rênes du couple à l’autre.",
        LVA :"Les conflits d’intérêts et de pouvoir vont gâcher cette relation. Tous deux, très ambitieux, avec une grande soif de réussite, pourrez trouver un terrain d’entente constructif,  si vous arrivez à modérer votre  tendance à la domination. Vos signes, (Taureau/Lion) très masculins, sont portés sur la volonté de diriger,  et vous aurez bien du mal à communiquer, sauf sur l’oreiller où l’amour du sexe réussira la prouesse de prolonger le sursis de cette relation vouée à l’échec.",
        Plus:"La Franchise - Une volonté commune à la réussite – Une sexualité débridée mais peu imaginative.",
        Moins:"Guerre de pouvoir et d’ego – Rapports de force – Relation en manque de passion mais conflictuelle – Dialogue de sourds sur fond de mauvaise foi.",
        Craquer:"Sensuelle, très chatte, elle saura attirer votre attention et vous prodiguer les bonnes caresses pour flatter votre ego, mais très vite vous vous rendrez compte que tout ceci n’est que stratégie pour vous asservir à ses desseins pour le moins ambitieux. De plus, son caractère obstiné et inflexible s’affrontera à votre autorité naturelle et vous devrez user d’allégeance pour imposer votre souveraineté et la soumettre. Mais la tâche sera fastidieuse, ardue et parfois violente. Un affrontement pour le pouvoir qui détruira cette histoire.",
        Coach:"Ces deux signes passionnés sauront se séduire par une cour enflammée et par des sorties dans les endroits les plus chics.  Une relation où l’égo se nourrit de l’attrait de l’autre, mais où les coups de gueule se transforment en de violents conflits. Relation où la violence et la domination trahiront un idéal superficiel. "
    } ,
    Lion_Gemeaux : {
        Slogan:"« Champagne ! Une complicité festive, joyeuse, c’est l’amour paillette. »",
        LVA :"Coup de foudre au premier regard, mais sur la durée, vos deux fortes personnalités auront du mal à se supporter. Les railleries et vexations du Gémeau auront raison de l’amour inconditionnel du Lion. Pourtant vos signes sont assez complémentaires  et s’entendent bien, mais trop égoïstes, vous vous disputerez la place de la vedette. Une relation qui ne sera pas de tout repos. La sexualité est épanouie, mais le Gémeau est un peu trop cérébral pour le Lion plus instinctif et animal.",
        Plus:"L’amour est fort et passionné – Le lion stabilise le gémeau -  La fantaisie – Le goût commun pour l’art et le spectacle.",
        Moins:"Les colères du lion sont redoutables et le gémeau attise la zizanie – Le sarcasme du gémeau agace et vexe le lion qui a besoin d’admiration.",
        Craquer:"Avec elle tout n'est qu'un jeu, elle saura vous flatter, jouer les dames fragiles, implorant son preux Chevalier de la sauver des griffes de ses affreux parents tortionnaires. Vous vous sentirez l'âme d'un conquérant, aimé et adulé. Mais attention à cette gentille manipulatrice qui pourrait vous faire tourner en bourrique, capricieuse et excessive, elle n'hésitera pas à vous lancer des défis farfelus et parfois même à vous tourner en ridicule. Combien de temps jouerez-vous ce jeu de dupe qui vous laissera un goût amer de défaite ? ",
        Coach:"L'emballement réciproque entre l’air pétillant du Gémeau et le feu conquérant du Lion promet un amour ardent. Une admiration commune attise l’intensité de leur relation et stimule une passion déraisonnable sur fond de rivalité. "
    },
    Lion_Cancer :{
        Slogan:"« Relation de dominant et dominé. Si le cancer se soumet tout sera possible. »",
        LVA :"Dans ce genre de relation, le Cancer devra s’attendre à se soumettre totalement à la volonté et l’autorité de son seigneur et maître le Lion. Le Cancer, assez passif, peut supporter une telle situation de dominant-dominé et acceptera de s’effacer sous le joug du Lion au bénéfice de la vie de famille et des tâches quotidiennes du foyer, pendant que le Lion prendra du bon temps. Cependant, l’hypersensibilité du Cancer sera mise à rude épreuve face aux excès colériques de cet animal autoritaire.",
        Plus:"La docilité du cancer – La protection du lion – L’admiration sans failles du cancer. ",
        Moins:"La fragilité nerveuse et émotionnelle du cancer – La brusquerie du lion – Les colères du lion – Les bouderies et autres vexations.",
        Craquer:"Elle vous mettra sur un piédestal. Avec elle vous avez trouvé une groupie inconditionnelle, à la hauteur de votre auguste personne. Très féminine et élégante, elle saura vous faire honneur, vous serez le couple très envié que vous avez toujours désiré. Mais ses incessantes lamentations, ses pleurnicheries et reproches sur votre manque de sollicitude vous éloigneront de cette caractérielle cyclothymique. Vous ne comprenez pas son insatisfaction alors qu'elle a une place de choix à vos côtés.",
        Coach:"Rencontre sensuelle toute relative tant que les sujets sérieux ne seront pas abordés. L’autorité du Lion ne pourra convenir que momentanément au caractère capricieux du Cancer qui suffoquera sous ce despotisme blessant. "
    } ,
    Lion_Lion :{
        Slogan:"« Mariage glamour sous les feux de la rampe. Ces amoureux du faste vont en faire des envieux ! »",
        LVA :"Les conflits d’intérêts et de pouvoir vont gâcher cette relation. Tous deux, très ambitieux, avec une grande soif de réussite, pourrez trouver un terrain d’entente constructif,  si vous arrivez à modérer votre  tendance à la domination. Vos signes, très masculins, sont portés sur la volonté de diriger,  et vous aurez bien du mal à communiquer, sauf sur l’oreiller où l’amour du sexe réussira la prouesse de prolonger le sursis de cette relation vouée à l’échec.",
        Plus:"La Franchise – Une volonté commune à la réussite – Une sexualité débridée mais peu imaginative.",
        Moins:"Guerre de pouvoir et d’ego – Rapports de force – Relation en manque de passion mais conflictuelle – Dialogue de sourds sur fond de mauvaise foi.",
        Craquer:"Avec sa crinière de feu, et sa manière de vous snober vous n'aurez qu'une seule envie : La posséder tel un précieux joyau convoité par un collectionneur d'art. Sa nature passionnelle et fidèle vous enchantera, mais son attitude dominatrice, en perpétuelle contestation de votre autorité de maître, vous mettra dans des rages terribles. Très vite, une guerre de pouvoir va s'enclencher et détériorer cette belle histoire d'amour.",
        Coach:"Leur attraction excessive et passionnelle est portée à contempler leur propre reflet dans une admiration réciproque. Mais attention, chacun voudra prendre le pouvoir et il ne peut y avoir qu'un seul roi, ce qui fixera les limites à cette histoire. Choc de deux fortes personnalités autoritaires. "
    } ,
    Lion_Vierge :{
        Slogan:"« La rencontre de l’extravagance  et de la sagesse ne font pas bon ménage. »",
        LVA :"Vos différences de tempéraments seront un réel handicap pour l'épanouissement d'une telle union. Le Lion a une personnalité excessive et extravagante avec le goût du faste alors que la Vierge est plus simple, calculant ses dépenses avec parcimonie. Elle sera d'autant plus dérangée voir agacée  par ces comportements déraisonnables. Dans ce couple le Lion aura bien entendu le beau rôle, alors que  la Vierge endossera le costume de la servante soumise à son dictât. Une histoire qui manque cruellement de passion.",
        Plus:"La vierge sait se rendre utile et le lion apprécie cette disponibilité - Le lion aime le perfectionnisme et la soumission de la vierge - La vierge aime la puissance et le caractère fort du lion.",
        Moins:"Les critiques acerbes de la vierge - Les extravagances du lion - Le manque de passion de la vierge frustre l'ardent lion -La vierge est inhibée, le lion est très sexuel.",
        Craquer:"Vous la remarquerez assurément, car elle ressemble à un petit oiseau blessé, fragile et sans défenses, que votre âme de conquérant, défenseur de la veuve et de l'orphelin aura envie de protéger. Elle vous le rendra bien, reconnaissante et serviable elle vous chouchoutera et saura s'effacer, se contentant d'exécuter les tâches les plus ingrates en vous laissant le beau rôle. Cependant son manque d'enthousiasme et d'élan amoureux, ne pourra compenser sa serviabilité, car l'amour doit aussi s'exprimer par des démonstrations câlines et par des déclarations enflammées, vous ne pourrez vous contenter d'un à peu près et n'hésiterez pas à vous détourner d'elle si elle ne cède pas à vos exigences amoureuses.",
        Coach:"Un couple que l’on ne rencontre pas couramment, où la méfiance est instinctive. La Vierge prudente, n’a guère confiance en ce présomptueux personnage. Le Lion souffre de la discrétion affective de la Vierge. Relation mi-figue mi-raisin. "
    } ,
    Lion_Balance :{
        Slogan:"« Alliance du luxe et de la beauté. Un amour cliquant qui brille de mille feux. »",
        LVA :"Votre histoire c'est un peu la rencontre d'Apollon, Dieu de la musique et de la poésie, réputé pour son physique avantageux  et de Vénus, déesse de l'amour et de la beauté. L'attraction est immédiate et intense, prometteuse d'une entente parfaite, basée sur l'élan d'un amour enthousiaste et de goûts communs pour le milieu artiste et les joies de l'existence. Vous vous aimez d'une passion ardente et romantique. Votre sexualité sera harmonieuse, même si le Lion manquera parfois de finesse et de délicatesse.",
        Plus:"Goûts communs pour les arts, le luxe et les mondanités - L'envie d'aimer intensément en vue d'une relation sérieuse - Une sexualité épanouie.",
        Moins:" La légèreté de la balance rend méfiant le lion fidèle - L'oisiveté de la balance - Les colères noires du lion se heurtent à la sensibilité de la balance.",
        Craquer:"Elégante et renversante, vous serez subjugué par cette beauté parfaite. Femme d'esprit, s'intéressant à l'art, vous partagerez avec elle des moments divins et sensuels. Elle représente la Déesse qui s'accorde en tous points à votre idéal féminin et qui sera à la hauteur de vos prétentions. Seules ses dépenses outrancières vous dérangeront et déclencheront des conflits violents entre vous, surtout si c'est avec votre Carte Bleue. Car Madame aime le luxe et ne supporte pas de faire des comptes d'apothicaires, elle aime la grande vie. Attention ne lui avez-vous pas fait miroiter que vous la choierez comme une reine ? Eh bien elle se comportera comme telle, ne vous en déplaise.",
        Coach:"L'attraction est immédiate et dès les premiers instants partagés, ils découvriront leur sensibilité commune pour les mondanités et l’art.  Amour harmonieux. "
    } ,
    Lion_Scorpion :{
        Slogan:"« Difficile de concéder à l’autre la place du chef. Rapports de force passionnels. »",
        LVA :"Rencontre de deux forces de la nature, l'une diurne, avec un feu intérieur bouillonnant, et l'autre solaire, ardent et exubérant, c'est le choc des titans. Ces deux tyrans à la personnalité inflexible et au  manque d'adaptabilité vont se rendre une vie impossible.  Seule l'attraction sexuelle pourra vous mettre d'accord un certain temps et faire perdurer malgré tout  cette relation basée sur le rapport de force, sans concessions.",
        Plus:"Une  potentielle réussite pour une association professionnelle - Une sexualité intense assumée mais manquant de tendresse.",
        Moins:"Le scorpion pique le lion au vif - La violence de ces deux excessifs - Le scorpion est plus pervers et le lion trop direct - Rapports difficiles au pouvoir.",
        Craquer:"C'est son sex-appeal qui va réveiller vos sens, un appel au sexe que vous ne refuserez pas. Sûr de vous et de votre pouvoir de séduction, vous l'aborderez franc et sans détours. Mais attention, vous avez une adversaire de taille, certes assumant sa libido, mais comptant bien vous dicter sa loi, ce qui risque de déclencher des hostilités sanglantes de part et d’autre.",
        Coach:"Attraction animale entre deux personnalités dominatrices. Leur relation est surtout passionnellement érotique sur fond de torture mentale. L’intensité sexuelle n’est qu’un instrument pour prendre le pouvoir de l’un sur l’autre. Relation destructrice. "
    } ,
    Lion_Sagittaire :{
        Slogan:"« Relation ardente et conquérante, une aventure passionnante et grisante. »",
        LVA :"Rencontre de deux natures enthousiastes et généreuses, enclines à jouir pleinement de la vie. Votre relation sera basée sur la spontanéité, la sincérité et la franchise. Ce duo de feu, aux goûts de luxe et aux dérives excessives auront une complicité à toute épreuve, basée sur les plaisirs à deux. Votre sexualité est gourmande et fougueuse. Seule divergence, le Lion se complet à créer un nid confortable et fastueux alors que le Sagittaire rêve de parcourir le monde ne s’attachant pas à un lieu fixe.",
        Plus:"Leur nature passionnée et sincère – Épicuriens et jouisseurs ils partageront le plaisir des fêtes et des soirées bien arrosées.",
        Moins:"Leurs colères sont dévastatrices – Ils sont tous deux très orgueilleux – L’autorité du lion se heurte à l’indépendance du sagittaire.",
        Craquer:"Entre vous, la magie opère immédiatement et vous emportera dans une passion folle et débridée. Vous aurez compris immédiatement qu’avec elle il faut sortir le grand jeu et l’effet de surprise. Hôtels de luxe, week-ends improvisés au Casino de Deauville, en somme la Dolce Vita. Seule sa liberté chérie vous fera grincer des dents et vous désorientera parfois sur vos envies de vie à deux, car vous aurez l'impression de ne pas avoir de prise sur elle malgré la passion dévorante qui vous lie.",
        Coach:"Joli couple de signes de feu, formé dans des élans enthousiastes d’une passion ardente et joyeuse. Une complicité motivée par des sentiments nobles et entiers dont il faudra soustraire toute compétition pour éviter de tout gâcher. "
    } ,
    Lion_Capricorne :{
        Slogan:"« Les emballements du Lion se heurteront à un mur froid d’indifférence. Dur, dur ! »",
        LVA :"Difficile de trouver un terrain d’entente. Vos personnalités sont diamétralement opposées. C’est la rencontre du feu et de la glace. Vous êtes exubérant, optimiste et théâtral, vous adorez la provocation et les excès en tout genre, alors que le Madame Capricorne n’aspire qu’au calme, à la solitude et déteste le clinquant, les mondanités qu’elle qualifie de frivolités inutiles. Seules vos ambitions et votre goût du pouvoir pourront vous relier, mais pourront aussi vous confondre.",
        Plus:"L’amour de l’argent et du pouvoir – le capricorne canalisera les dépenses excessives et superflues du lion – le lion dégèlera son partenaire capricorne et lui apportera une dose non négligeable d’optimisme.",
        Moins:"Tout les oppose – l’un extraverti l’autre introverti – l’un mondain  l’autre solitaire – l’un dans l’excès et l’autre dans la restriction – la froideur du capricorne désintéresse le passionné lion.",
        Craquer:"Vous remarquerez certainement cette jolie bourgeoise élégante, mais sobre. Vous êtes faciné par cette certaine noblesse qui s’émane de cette beauté évanescente. Mais malgré tous les trésors d’imagination que vous déploierez pour lui plaire, son manque d’enthousiasme à la limite du dédain, vous découragera. Déçu, vous vous désintéresserez de cette muraille trop difficile à conquérir, et tel un Chevalier au grand cœur, vous chevaucherez alors vers d'autres cieux.",
        Coach:"Étant l’un et l’autre en recherche d’une personne au-dessus de la moyenne, ils s’attireront grâce à une admiration réciproque surtout s'ils ont un statut social élevé, mais la difficulté sera de trouver leur place au sein du couple, le pouvoir ne pouvant être partagé. "
        } ,
    Lion_Verseau :{
        Slogan:"« Une Complicité évidente, tous deux aiment les êtres intelligents et brillants. »",
        LVA :"Entre vous, c’est la loi de l’attraction et de la répulsion , c’est parfois la dure règle pour les signes astrologiques opposés qui peuvent vivre leur histoire soit dans la complémentarité soit dans l’antagonisme. Dans tous les cas, vous ne vivrez pas une relation édulcorée, ni banale. Placée sous le signe de l’impétuosité, vous partagerez le goût pour le monde, les relations amicales et les échanges animés. Mais vos divergences de caractères froisseront votre orgueil et votre possessivité et vous aurez bien du mal à attacher la libertine Madame Verseau.",
        Plus:"Leur esprit est brillant et tous deux aiment se cultiver et polémiquer - La passion amoureuse - Ils ont une vie sociale bien remplie.",
        Moins:"Ils sont très individualistes voir égoïstes – Le lion ne supporte pas l’indépendance du verseau – Le verseau exècre l’autoritarisme du lion.",
        Craquer:"Séduisante, assez à l’aise en société, vous serez emballé par cette jolie femme, au caractère bien trempé, ayant un avis sur tout, assez réactionnaire dans ses propos. Vous aimez les rebelles à condition de les soumettre, mais  avec elle vous aurez fort à faire car elle s’opposera systématiquement à votre autorité et ne manquera pas d’ironiser sur votre besoin de flatteries. Blessé dans votre amour-propre vous rugirez de mécontentement.",
        Coach:"Une attraction physique irrésistible motivée par une fascination réciproque. Une passion aveugle qui se soldera par un échec cuisant en raison de leurs caractères diamétralement contradictoires et un manque de souplesse. Attirance-Répulsion. "
    } ,
    Lion_Poissons :{
        Slogan:"« Un idéal amoureux qui aurait pu s’accorder si leurs ambitions n’étaient pas si divergentes. »",
        LVA :"Entre vous, difficile de trouver une alchimie. Vous évoluez dans des mondes parallèles où l’incompréhension rendra impossible toute relation harmonieuse. Bien sûr il peut y avoir un coup de foudre, mais vous qui êtes si impétueux, franc et direct, serez déconcerté par le coté doux rêveur et insaisissable de Madame Poisson. Même si elle ne contestera jamais votre autorité, ses non-dits et ses faux fuyants vous mettent mal à l’aise dans la confiance qui vous pourriez accorder à cette nébuleuse sirène.",
        Plus:"Le poisson soumis ne sait que faire pour plaire au lion majestueux et autoritaire - Le lion apprécie l’amour inconditionnel que lui porte le poisson.",
        Moins:"La passivité et l’abnégation du poisson – Les colères agressives du lion et ses paroles blessantes.",
        Craquer:"Vous verrez en cette beauté éthérée presque irréelle, une créature fragile à protéger. Avec votre belle allure de Chevalier, vous penserez avoir trouvé votre Princesse, débordante de volonté pour vous plaire et esclave de votre amour. Docile et aux petits soins, elle vous comblera de bonheur.  Tout serait parfait si son ambiguïté et ses mensonges ne vous faisaient pas douter de sa sincérité, vous mettant dans des rages folles qui tôt ou tard,  auront raison de toute compassion envers cette sirène qui au prime abord, vous semblait si fragile.",
        Coach:"Une attraction romanesque et sensuelle qui ne durera que le temps d’une étincelle. Une alchimie difficile pour ces deux personnalités évoluant dans des univers très différents. La nébulosité du Poisson met le Lion dans des rages folles. "
    } ,
    Vierge_Belier : {
        Slogan:"« La Vierge, méfiante, n'est pas convaincue par les démonstrations d'amour du Bélier  »",
        LVA :"Un couple dans une totale incompréhension du fonctionnement de l’autre. La Vierge farouche et analytique a du mal à comprendre le fougueux Bélier qui part au galop et réfléchit plus tard. La Vierge a besoin d’être rassurée pour se lâcher alors que le Bélier se jette à corps perdu dans l’amour. La Vierge aura tendance à refroidir les élans du Bélier, ce qui risque de le faire fuir rapidement. Cependant le Bélier, par ses insistances, peut réussir à décoincer cette amoureuse prudente qui deviendra  \"vierge folle\".",
        Plus:"Les grandes discussions philosophiques – Jouer au chat et à la souris – Dévergonder cette nature réservée et laisser s’exprimer une passion insoupçonnée.  ",
        Moins:"L’instabilité du bélier et le besoin de sécurité de la vierge – Le bélier s’ennuie avec la Vierge et il ne supporte pas ses critiques. ",
        Craquer:"Elle vous accostera sans détours, vous draguant honteusement, elle est comme ça, impulsive et directe, sexy et libérée. Mais votre bonne éducation, et votre goût pour les bonnes manières auront du mal à adhérer à ces méthodes de séduction bien trop triviales et grossières pour une femme. Vous apprécierez cependant son engouement à l’amour même si son impatience et ses exigences vous déplairont. Alors ne serait-ce que pour un instant de folie, vous vous prêterez volontiers aux jeux de l'amour avec elle.",
        Coach:"La séduction tenace et persévérante du Bélier, ne sera pas pour déplaire à la Vierge, réservée et en manque de confiance en elle. Le Bélier aime les défis et les murailles infranchissables, mais se projeter dans un avenir commun, pour la Vierge il n'en est pas question. "
    } ,
    Vierge_Taureau :{
        Slogan:"« Un accord parfait pour ces deux terriens qui aspirent à la quiétude d’un foyer. »",
        LVA :"Rencontre de deux solides Terriens. Vous aimerez couler des jours heureux sous le signe de la stabilité et de la sécurité. Le Taureau sait gagner l’argent que la Vierge saura thésauriser en vue de l’achat de leur douillette demeure et d’investissements immobiliers pour leur belle retraite. Vous saurez avancer mains dans la main, comme deux bons gestionnaires. Une vie bien orchestrée  avec un  manque évident de piment et de fantaisie. Une sensualité en harmonie sans surprises.",
        Plus:"Goût pour la stabilité – La confiance – La droiture – Tous deux sont des laborieux – Épicuriens – Ils aiment les choses simples et vraies",
        Moins:"Manque de passion évident – Vie ennuyeuse et rigide – Certains taureaux sont infidèles et la vierge est assez constante et droite.",
        Craquer:"Inséparables et complices, vous découvrirez avec elle les joies de la vie de couple, vous partagerez  des moments délicieux, parfaite femme d’intérieur, elle saura vous combler par ses petits plats et ses attentions au quotidien. Seules ses exigences pécuniaires vous feront douter du bien-fondé de ses intentions amoureuses. Son obsession matérialiste et ses indiscrètes questions sur votre niveau de vie remettrons en question cette histoire qui commençait pourtant bien. Vous la trouverez trop calculatrice et pas assez naturelle, un amour au conditionnel qui vous dérange, vous êtes un amoureux idéaliste.",
        Coach:"Le Taureau amènera la sécurité financière dont la Vierge a besoin. La Vierge enchantera le Taureau par son dévouement et ses attentions. Une relation de connivence, bien huilée teintée d'un érotisme lascif. Relation basée sur du solide. "
    } ,
    Vierge_Gemeaux : {
        Slogan:"« Leur cérébralité sera le piment de cette histoire . Une relation toute Mercurienne. »",
        LVA :"Tous deux Mercuriens, si la Vierge est dite « folle », vous vous entendrez comme deux larrons en foire, avec des affinités particulièrement intellectuelles, vous aimerez discuter, échanger, jouer ensemble. Sexuellement, vos jeux érotiques seront plutôt cérébraux, scénarisés. Cependant, la relation risque de s’essouffler par manque de piment, le Gémeau, assez dispersé, déstabilise la Vierge organisée et l’agace par son manque de cohésion et de constance.",
        Plus:"Une complicité intellectuelle presque fraternelle – Ils se comprennent et ont les mêmes jeux – La créativité du gémeau est matérialisé par la Vierge.",
        Moins:"a nervosité du gémeau perturbe le calme de la vierge – La vierge risque à la longue d’ennuyer le gémeau.",
        Craquer:"Elle réussira à vous accrocher par ses provocations verbales subtiles. Cette jolie femme intemporelle, vous divertira par sa légèreté qui la caractérise tant. Ses mots et ses gestes bien choisis apaiseront vos doutes et  vous obligeront à vivre plus intensément, à vous abandonner à l’amour. Mais sa nature instable, éparpillée et peu fidèle, risque de déstabiliser cet équilibre trop fragile et vous enfermer de nouveau dans votre hermétisme amoureux.",
        Coach:"Une attraction érotico-intellectuelle où l’esprit est en effervescence, aiguisée par la curiosité et les joutes verbales. Ils se plaisent, d’abord intrigués par leur vivacité d’esprit et admirent leur talent. Mais attention, leur ironie acerbe et l'irritation que cela peut provoquer risque de tout gâcher."
    },
    Vierge_Cancer :{
        Slogan:"« Un attachement quasi paternel. Difficile de les décoller l’un de l’autre.»",
        LVA :"Ces deux signes ne pourront que s’entendre. Vous êtes tous les deux sensibles, réservés, pudiques, simples dans votre vision du couple et de l’amour désireux d’un bon confort et de la quiétude dans le foyer. Votre passion n’est pas débordante, mais sincère et rassurante, entre la réserve et le lâcher-prise. Seul bémol à ce charmant duo, les mensonges et autres divagations du Cancer que la Vierge pragmatique et réaliste aura du mal à supporter.",
        Plus:"La confiance est de mise entre ces deux signes méfiants – Ils regardent dans la même direction – Une envie de calme et de stabilité les anime.",
        Moins:"Les rêves fantasmagoriques du cancer se heurtent au réalisme de la vierge – Les mensonges du cancer – Les critiques et la rigueur de la vierge.",
        Craquer:"Vous serez subjugué par tant de féminité et de douceur. Elle saura vous faire fondre et fera tomber toutes vos barrières de résistance à l’amour. Mais dès qu’elle commencera à envahir votre espace personnel et à y semer le désordre par ses divagations, vous aurez tendance à vous assombrir et à recadrer durement  cette fantasmeuse immature. Vous vous sentirez agressé dans votre intimité par cette amoureuse intrusive qui réclame beaucoup trop d'attentions.",
        Coach:"Une attraction toute relative au départ, avec une bonne dose de méfiance qui s’estompera au fil du temps pour laisser place à un amour profond. Les attentions de la Vierge seront récompensées par la dévotion amoureuse et sensuelle du Cancer. "
    } ,
    Vierge_Lion :{
        Slogan:"« La rencontre de la sagesse et de l’extravagance  ne font pas bon ménage. »",
        LVA :"Vos différences de tempéraments seront un réel handicap pour l'épanouissement d'une telle union. Le Lion a une personnalité excessive et extravagante avec le goût du faste alors que la Vierge est plus simple, calculant ses dépenses avec parcimonie. Elle sera d'autant plus dérangée voir agacée  par ces comportements déraisonnables. Dans ce couple le Lion aura bien entendu le beau rôle, alors que  la Vierge endossera le costume de la servante soumise à son dictât. Une histoire qui manque cruellement de passion.",
        Plus:"La vierge sait se rendre utile et le lion apprécie cette disponibilité - Le lion aime le perfectionnisme et la soumission de la vierge - La vierge aime la puissance et le caractère fort du lion.",
        Moins:"Les critiques acerbes de la vierge - Les extravagances du lion - Le manque de passion de la vierge frustre l'ardent lion - La vierge est inhibée, le lion est très sexuel.",
        Craquer:"Vous ne pouviez que remarquer cette femme. Vous serez impressionné par sa superbe prestance, très sûre d’elle et de son pouvoir de séduction. Mais ses éclats théâtraux et ses exubérances vous rendront méfiant et mitigé à l’égard de cette dominatrice autoritaire dont le mental est bien trop basique pour un esprit aussi évolué que le vôtre. Vous vous heurterez à son ego surdimensionné et, pour la remettre à la place qu'elle doit occuper et non celle à laquelle elle pense devoir accéder, vous n'hésiterez pas à la recadrer de quelques réflexions bien choisies, égratignant sa superbe.",
        Coach:"Un couple que l’on ne rencontre pas couramment, où la méfiance est instinctive. La Vierge prudente, n’a guère confiance en ce présomptueux personnage. Le Lion souffre de la discrétion affective de la Vierge. Relation mi-figue mi-raisin. "
    } ,
    Vierge_Vierge :{
        Slogan:"« Pas de mauvaises surprises avec son jumeau astral, tout est carré, sans  faux-semblants. »",
        LVA :"L’attirance de ces deux Vierges sera basée surtout sur leur conception identique de la vie.  Ne laissant pas la place au hasard afin de minimiser vos angoisses de l’imprévu, vous saurez vous épauler et planifier au mieux les tâches du quotidien. Mais vous vous enfermerez très vite dans une relation insipide et prévisible où la passion sera totalement absente. La Vierge a besoin d’un partenaire  enflammé pour se lâcher et lorsque vous aurez épuisé toutes vos réserves de curiosité intellectuelle, cette relation  ne perdurera pas.",
        Plus:"Ils se comprennent et savent ce dont l’autre a besoin – Les loisirs culturels et intellectuels – Les longues discussions au coin du feu - Leur bonne gestion des finances. L’un rassure l’autre et vice-versa.",
        Moins:"Tous deux réservés, ils auront du mal à faire le premier pas – Le manque de passion – Une sexualité Inhibée et prude.",
        Craquer:"Femme de qualités, de bonne éducation, parfaite dans la maîtrise des contraintes budgétaires du couple et dotée d'un dévouement sans failles, vous ne pourrez que saluer la chance que vous aurez d’avoir une telle femme à vos côtés. Respectueux et conscient de sa nature prude, vous vous sentirez cependant frustré en tant qu’homme dans vos désirs érotiques, voulant éviter de la choquer pour ne pas la perdre. Vous risquez donc de vous enfermer dans un couple poussiéreux ou entamer une double vie pour assouvir vos fantasmes.",
        Coach:"Une attraction timide pour ces jumeaux astraux, réservés dans les domaines de l’amour. Leur entente sera bonne, mais manquera cruellement de piment, la passion sera bannie et la maîtrise régnera en maître. Une histoire qui risque de vite devenir ennuyeuse. "
    } ,
    Vierge_Balance :{
        Slogan:"« Comment rigueur et oisiveté peuvent-elles cohabiter. En fait, vous ne vous supportez pas. »",
        LVA :"La magie de l’attraction entre ces deux signes ne durera pas bien longtemps, juste  le temps que la Vierge se rende compte des travers  cachés de cette charmeuse, aimable, mais dissimulatrice  et superficielle Vénusienne. La Balance, elle  se sentira offensée par l’analyse critique, mais non moins réaliste de sa personnalité par cette Vierge froide qu’elle qualifiera d’intolérante et d’insensible.",
        Plus:"Les échanges culturels – Le raffinement – La vierge organisée et bonne gestionnaire tempère les dépenses excessives de la balance.",
        Moins:"Le manque de passion – La superficialité et l’infidélité de la balance éloigne la vierge – La rigueur de la vierge et sa retenue amoureuse déplaisent à la chaleureuse balance.",
        Craquer:"Son élégance et sa classe ne pourront que vous interpeller, sa discrétion et sa douceur vous donneront envie d’aller plus loin. Mais très  vite vous vous rendrez compte qu’elle ne s'épanouit que grâce et par le regard des hommes auprès desquels, elle recherche une approbation à sa beauté. Vos principes et votre nature droite et fidèle ne pourront s’accommoder d’une séductrice aussi peu respectueuse de son partenaire. Vous douterez de sa fidélité et ne pourrez plus lui accorder la confiance dont vous avez besoin pour pouvoir vous épanouir dans votre couple.",
        Coach:"Une attraction progressive pour ces deux signes discrets qui recherchent le bien-être amoureux. Ils prendront leur temps pour se laisser aller à l’amour, désirant approfondir la connaissance de l’autre. Une amitié amoureuse tiède, mais sincère. "
    } ,
    Vierge_Scorpion :{
        Slogan:"« Couple très harmonieux où l’efficacité et la sincérité sont de rigueur. ",
        LVA :"Il semblerait que le Scorpion soit né pour charmer la méfiante Vierge et la faire tomber dans son piège amoureux. Comme si le Scorpion possédait de façon innée, le mode d’emploi pour percer votre cuirasse. Il faut dire qu’elle usera et abusera de son magnétisme envoûtant pour vous libérer de vos tabous intellectuels et physiques, faire tomber toutes vos barrières de résistance pour vous rendre foux d’amour, esclave de vos sens et vous asservir.",
        Plus:"Leur acuité intellectuelle – Ils se comprennent et savent s’apprivoiser – Le scorpion révèle la nature cachée de la vierge – La vierge est soumise au dominant scorpion.",
        Moins:"	Les excès destructeurs du scorpion révoltent la vierge soumise – Le sadomasochisme.",
        Craquer:"Malgré le malin plaisir que vous prendrez à la toiser et à faire fi de ne pas la remarquer, elle ne cessera de vouloir vous charmer sans tenir compte de votre indifférence. Vous serez amusé par ce petit jeu et sans vous en rendre compte, vous vous attacherez à cette amoureuse passionnelle et vous en deviendrez raide dingue. Par la suite, vous devrez user d’un psychisme d’acier pour pouvoir encore réussir à lui dire non. Le défi intellectuel sera la base même de votre couple et vous vivrez votre histoire d'amour au gré de vos envies et de vos jeux sensuels. Vous réussirez à adoucir ses idées noires en la rassurant.",
        Coach:"Le Scorpion a incontestablement les qualités requises pour neutraliser les défenses de la méfiante Vierge qui sera subjuguée par son charme magnétique. Leur vivacité d’esprit et la pertinence de leurs observations les attireront comme un aimant."
    } ,
    Vierge_Sagittaire :{
        Slogan:"« Désordre et rigueur n’ont jamais fait bon ménage ! L’un range l’autre dérange. »",
        LVA :"Vos différences de nature sont tellement évidentes qu’il sera difficile pour vous de trouver un terrain d’entente. La méticulosité et l’ordre de la Vierge agacent le désordonné Sagittaire. L’esprit d’aventure et d’improvisation du Sagittaire déstabilise la prudente Vierge qui planifie tout à l’avance, évitant toutes surprises qui pourraient l’angoisser. Cependant, la bonne nature enthousiaste du Sagittaire pourrait bien vouloir faire découvrir des horizons insoupçonnés à cette frileuse peureuse Vierge, mais pas moins curieuse et spirituelle.",
        Plus:"Relation dans la curiosité intellectuelle et philosophique de la vie – Tous deux aiment se cultiver et apprendre - Ils sont tous deux épicuriens.",
        Moins:"Des natures diamétralement opposées – Leurs défauts les dérangent et peuvent les amener à se détester.",
        Craquer:"Sa bonne nature, optimiste et enjouée vous séduira. Intéressante et curieuse de tout, vous aimerez échanger sur des sujets divers et variés. Votre relation ressemble plus à une amitié amoureuse qu’à une grande passion. De toute façon, son inconstance vous déstabilise et sa pagaille vous incommode trop pour pouvoir vous engager sérieusement avec elle. Vous ne vous projetterez pas dans une histoire d'avenir avec elle, mais profiterez de bons moments, car vous la trouvez très agréable à vivre.. une relation d'amitié amoureuse.",
        Coach:"Il faudra toute l’exubérance et l’enthousiasme du Sagittaire pour conquérir notre prudente Vierge. Mais leurs caractères antinomiques risquent fort de vouer très rapidement à l’échec cette alliance de l’ordre et de l’éparpillement. Passion éphémère."
    } ,
    Vierge_Capricorne :{
        Slogan:"« Voilà du solide ! Entente et respect mutuel pour une vie paisible sans surprises. »",
        LVA :"Rencontre de deux signes de Terre stables. Vous recherchez tous les deux un partenaire sérieux et fiable, capable de satisfaire vos exigences amoureuses complexes et les contingences de la vie quotidienne. Les emportements aveugles ne font pas partie de vos personnalités, car  vous avez besoin tous deux d’évaluer minutieusement votre prétendant avant de vous laisser aller aux tourments de l’amour. Un sentiment d’estime et de respect vous rapprochera avant de vaincre vos retenues et de laisser transparaître vos sentiments.",
        Plus:"Ils s’apportent mutuellement des garanties de fiabilité qui les rassurent – Ils sont de bons partenaires pour créer un couple ou monter des affaires.",
        Moins:"Le manque de passion – L’absence de surprises.",
        Craquer:"Élégante et sobre, vous serez touché par sa réserve qui lui donne un côté fragile  et qui éveillera en vous des élans protecteurs. Femme organisée et pragmatique, elle privilégiera les actes plutôt que les mots pour exprimer toute son affection, ce qui vous comblera de bonheur. Mais elle en attendra tout autant de vous, car sa pudeur ne l’exempt pas de sensibilité. C'est là que le bas risque de blesser, car vous avez du mal à vivre l'enthousiasme amoureux et on ne peut pas dire que vous soyez un joyeux drille. Vous compenserez plutôt par votre nature protectrice.",
        Coach:"Leur aspiration à trouver un partenaire sérieux attireront spontanément ces deux signes de Terre l’un vers l’autre. Les joies de l’amour ne se libéreront qu’une fois les exigences du cœur et de la vie pratique satisfaites. Sentiments d’estime et d’amour profond. Cependant, cette relation manquera de Passion. "
        } ,
    Vierge_Verseau :{
        Slogan:"« Absence rime parfois avec indifférence. Difficile de créer une connivence avec un tel handicap.  »",
        LVA :"Vous formerez un couple tout à fait improbable. L’alliance d’une Vierge méthodique et conventionnelle et d’un Verseau rebelle et anticonformiste ne pourra que se confronter  à une incompréhension de sourd et muet. Vous trouverez cependant un terrain de jeu sur des sujets intellectuels qui stimulera une belle amitié amoureuse, mais vos manies à tout prévoir et votre  extrême prudence s'opposant à l’insouciance et à la désinvolture de Madame Verseau auront raison de votre patience et du peu d’intérêt que vous vous accordez.",
        Plus:"L’engouement intellectuel – Le verseau admire l’intelligence de la vierge et la vierge est subjuguée par ce bel orateur.",
        Moins:"Les absences du verseau blessent la sensibilité vierge qui attend de l’attention de son partenaire – Conformisme s’affronte à Anticonformisme. Le verseau est un rebelle alors que la vierge est très disciplinée.",
        Craquer:"Vive d’esprit et intelligente, vous serez captivé par cette jolie jeune femme, rebelle à souhait, ayant un avis sur tout et contestataire de toute autorité. Passé la magie des premiers instants, vous vous épuiserez à essayer de comprendre le bon  fonctionnement de cette inconstante et déconcertante caractérielle. Préférant la paix et la sérénité, vous ne tarderez pas à vous désintéresser d’elle, la laissant à ses contestations et à ses idéaux utopiques de la société.",
        Coach:"Une association tout à fait improbable. Seule une curiosité intellectuelle pimentera leurs échanges et pourra donner une illusion d’amour, mais leur différence de fonctionnement créera rapidement des tensions irrémédiables. "
    } ,
    Vierge_Poissons :{
        Slogan:"« Une relation timide mais où l’on s’apporte mutuellement du bonheur. »",
        LVA :"Rencontre de deux signes opposés par vos natures ambivalentes et complexes,  prometteuse d’une relation fascinante ou d’un fiasco complet. Vos mondes sont totalement différents, vous avez les deux pieds bien ancrés dans la terre et refusez de croire à tout ce qui échappe à votre compréhension analytique, alors que Madame Poisson navigue dans un monde irrationnel, transportée par le flux de ses émotions. Cependant, vous êtes en quête d’amour absolu et pourrez vous faire envoûter par les mots doux de cette charmeuse et irrésistible sirène et perdre tout contrôle sur votre vie.",
        Plus:"La douceur du poisson attendrit la vierge méfiante – Le poisson met du rêve dans la vie trop pratique de la vierge – La vierge apporte son réalisme pragmatique au rêveur poisson.",
        Moins:"Le poisson est parfois menteur et infidèle car il a un cœur d’artichaut – La froideur et les sarcasmes de la vierge blessent le sensible poisson – La désillusion.",
        Craquer:"De cette sirène sans défense, il ne se dégage que douceur et gentillesse. Vous serez désarmé devant tant de tendresse et de sensibilité. Vous qui ne laissez transparaître aucune de vos émotions, elle réussira à bouleverser vos défenses et vous fera entrevoir un monde émotionnel qui vous est inconnu. De cette expérience vous n’en sortirez pas indemne, flirtant avec le danger de l’amour et ses vicissitudes, mais vous ne pourrez lui échapper car vous avez trouvé votre tentatrice.",
        Coach:"Une attraction irrésistible avec une complémentarité où le monde fantasmagorique rencontre le monde de la réalité. Leur relation est à la fois fascinante et déstabilisante. Un enchantement des sens qui s’évaporera telle la magie de l’illusion. "
    } ,
    Balance_Belier : {
        Slogan:"« Une attraction incontrôlable entre ces deux êtres pourtant si différents, mais ça colle. »",
        LVA :"Vous vivrez l’attirance et la répulsion comme deux aimants. Le Bélier est un tentateur qui n’aime pas qu’on lui résiste, la Balance elle, a du mal à résister à la tentation et succombera à ses assauts  insistants. La Balance, raffinée et oisive, séduisante et charmeuse, va envoûter cet incorrigible chasseur. La Balance saura canaliser les emportements du Bélier par sa diplomatie. Leurs étreintes seront explosives et le Bélier saura révéler les désirs profonds de la Balance.",
        Plus:" Une attirance incontrôlée – Une passion dévorante – La balance arrive à attacher le bélier. ",
        Moins:"La Balance prévoyante surveille son infidèle bélier – Deux caractères forts qui s’affrontent dans des colères terribles.",
        Craquer:"Avec elle, pas de place à la demi-mesure, c’est l’amour fou ou la haine et la rancune tenace. Vous serez emporté par sa passion et ses ardentes déclarations d’amour qui vous combleront de bonheur tant l’amour a une place importante dans votre vie. Seules ses crises d’hystéries pour des futilités vous décourageront, mais attention, elle se transcende dans les conflits car elle adore les réconciliations. Pourrez-vous tenir ce rythme impitoyable alternant ruptures et réconciliations, vous le pacifiste ? En tout cas pour faire perdurer cet amour vous devrez vous atteler à l'emmener dans votre propre univers, celui de la paix et non de la guerre.",
        Coach:"C’est la rencontre de Mars et de Vénus, la communion parfaite de l’homme et de la Femme. C’est l’amour passion dans une quête de la perfection. "
    } ,
    Balance_Taureau :{
        Slogan:"«Ces deux vénusiens auront du mal à se supporter bien longtemps malgré leur goût commun pour l’art. »",
        LVA :"TVos signes Vénusiens trouveront à coup sûr une belle entente au niveau sentimental et physique. Ce qui vous réunit, c’est l’amour du beau, de l’esthétique, de l’artistique et de l’oisiveté. Malgré ces affinités, vous abordez la vie de façon radicalement différente et l’harmonie laissera place très rapidement aux malentendus. La Balance raffinée et mondaine aura du mal à supporter la vie trop simple d’un Taureau pépère et grognon.",
        Plus:"L’amour de la beauté – Ames d’artistes – La  sensualité.",
        Moins:"La légèreté de la balance –Leur matérialisme –L’un aime le foyer l’autre les mondanités – La balance est raffinée et le taureau assez simple parfois grossier.",
        Craquer:"Elle vous enchantera par sa sensualité, car elle est l’incarnation de la féminité comme vous l’imaginiez. Prévenante et câline, ne vous fiez pourtant pas à son apparente douceur, car derrière ce masque de femme amoureuse se cache un cerbère dictateur et possessif qui désire vous garder jalousement auprès d’elle. Ses ultimatums vous lasseront très vite, préférant l’amour à la guerre, vous la laisserez à sa folie paranoïaque. Même si vous ne concevez pas une relation ennuyeuse, vous aspirez à un bonheur calme et paisible, sans heurts ni éclats. Elle veut vous posséder alors que, tel un papillon, vous aimez la liberté de votre légèreté.",
        Coach:"Ces deux Vénusiens aux aspirations artistiques communes et amoureux de l’amour ne réussissent pas à se mettre d’accord, leurs désirs étant trop divergents. Attention aux relations d’intérêts pour ces deux matérialistes. "
    } ,
    Balance_Gemeaux : {
        Slogan:"« Relation aérienne et délirante, manquant parfois de pragmatisme. Couple intemporel. »",
        LVA :"Vous formez un joli couple qui aime les sorties entre amis et les mondanités, vous passerez des heures à discuter de tout et de rien. Tous deux intelligents, raffinés et charmeurs, votre relation est légère comme l’air, où l’insouciance vous guide. Un peu dans les nuages, vous avancerez dans une relation amoureuse  idéalisée. La triste réalité de la vie et ses contraintes quotidiennes risquent de laisser place à la désillusion et aux grincements de dents. Côté sexe, le plaisir est garanti grâce à votre imagination débordante.",
        Plus:"Ils se comprennent  et s’éclatent ensemble – Ils sont tous deux très artistes et manuels – Ils communiquent beaucoup – Ce sont deux imaginatifs.",
        Moins:"A force de dépenser sans compter on ne paye plus ses factures – Les projets restent à l’état de rêve – A trop vouloir du  plaisir on se brûle les ailes.",
        Craquer:"Vous l’aurez certainement rencontré lors d’un cocktail et vous aurez été séduit par sa fraîcheur et sa manière voluptueuse et aérienne de se déplacer. Mais plus encore que son apparence, son habileté oratoire et sa culture intellectuelle ne pourront vous laisser de marbre. Elle saura vous charmer par ses mots, délicieusement flatteuse, vous ne pourrez plus vous passer d’elle, totalement envoûté par son charisme. Mais ne vous y trompez pas, son plaisir est de tester son pouvoir de séduction et vous n’en aurez pas l’exclusivité.",
        Coach:"Une attraction irrésistible va les rapprocher, il faut dire que le Gémeau est un habile orateur qui va subjuguer la Balance par le raffinement et la diplomatie, qui sont des qualités chères à son cœur. Amour aérien. "
    },
    Balance_Cancer :{
        Slogan:"« Une love attraction spontanée mais frileuse. Deux séducteurs savent se reconnaître !  »",
        LVA :"Pas de répulsions entre ces deux signes, mais de là à faire votre vie ensemble, il ne faudrait pas exagérer non plus. Diamétralement opposés vous aurez du mal à vivre autre chose qu’une relation purement érotique et éphémère. Mais vous apprécierez les moments de douceur que vous partagerez ensemble. Cependant, la Balance éternelle séductrice aime la vie mondaine et rechigne aux travaux ménagers à l’instar du Cancer qui aime sa maison et a plaisir à s’y enfermer.",
        Plus:"Des moments de grâce sensuels – Le goût pour l’amour – Relation calme sans éclats.",
        Moins:"Une incompatibilité essentielle sur la vie de couple – La balance est mondaine et Le cancer aime la famille.",
        Craquer:"Redoutant et fuyant les relations conflictuelles, vous ne résisterez pas à sa douceur  et à son grand romantisme en quête de l’âme-sœur. Vous vivrez cette histoire comme un véritable conte de fées jusqu’à ce que votre princesse pique sa première colère, tapant du pied et vous affublant de noms d’oiseaux parce que vous ne vous occupez pas assez d’elle. Vous comprendrez que le rêve est terminé et ne tarderez pas à congédier cette furie qui perturbe votre quiétude.",
        Coach:"Attraction de ces deux romantiques qui cultivent le désir du grand amour. Des débuts enflammés ou chacun pensera avoir trouvé son âme-sœur, mais tôt ou tard le conte de fées se transformera en sordide règlement de compte. Amour illusoire. "
    } ,
    Balance_Lion :{
        Slogan:"« Alliance de la beauté et du luxe. Un amour cliquant qui brille de mille feux. »",
        LVA :"Votre histoire c'est un peu la rencontre d'Apollon, Dieu de la musique et de la poésie, réputé pour son physique avantageux  et de Vénus, déesse de l'amour et de la beauté. L'attraction est immédiate et intense, prometteuse d'une entente parfaite, basée sur l'élan d'un amour enthousiaste et de goûts communs pour le milieu artiste et les joies de l'existence. Vous vous aimez d'une passion ardente et romantique. Votre sexualité sera harmonieuse, même si le Lion manquera parfois de finesse et de délicatesse.",
        Plus:"Goûts communs pour les arts, le luxe et les mondanités - L'envie d'aimer intensément en vue d'une relation sérieuse -Une sexualité épanouie.",
        Moins:"La légèreté de la balance rend méfiant le lion fidèle - L'oisiveté de la balance - Les colères noires du lion se heurtent à la sensibilité de la balance.",
        Craquer:"Vous serez subjugué par cette beauté rayonnante, déambulant avec grâce, qui n’hésitera pas à vous faire comprendre le vif intérêt qu’elle vous porte. Madame Lion est entreprenante car passionnée, mais tout en finesse, car trop orgueilleuse pour essuyer un refus. Vos centres d’intérêts seront nombreux et vos ébats passionnés. Vous aurez trouvé une complicité évidente même si parfois vos égos se disputeront la vedette, car l'un et l'autre avez besoin de rayonner et vous ne supporterez pas qu'elle puisse vous faire de l'ombre.",
        Coach:"L'attraction est immédiate et dès les premiers instants partagés, ils découvriront leur sensibilité commune pour les mondanités et l’art.  Amour harmonieux. "
    } ,
    Balance_Vierge :{
        Slogan:"« Comment oisiveté et  rigueur peuvent-elles cohabiter. En fait vous ne vous supportez pas. »",
        LVA :"La magie de l’attraction entre ces deux signes ne durera pas bien longtemps, juste  le temps que la Vierge se rende compte des travers  cachés de cette charmeuse, aimable, mais dissimulatrice  et superficielle Vénusienne. La Balance se sentira alors offensée par l’analyse critique, mais non moins réaliste de sa personnalité par cette Vierge froide qu’elle qualifiera d’intolérante et d’insensible.",
        Plus:"Les échanges culturels – Le raffinement – La vierge organisée et bonne gestionnaire tempère les dépenses excessives de la balance.",
        Moins:"Le manque de passion – La superficialité et l’infidélité de la balance éloigne la vierge – La rigueur de la vierge et sa retenue amoureuse déplaisent à la chaleureuse balance.",
        Craquer:"Vous serez fasciné par cette beauté froide et réservée, sage amoureuse, mais non moins attachante et dévouée. Avec elle, vous vous sentirez tellement adulé que vous ne saurez que faire pour la rendre heureuse. En fait c’est elle qui mènera la danse. Mais très vite, elle détectera vos travers de séducteur invétéré et votre frivolité, et vous congédiera comme un vulgaire valet. Votre orgueil malmené, vous n’insisterez pas et irez panser vos plaies auprès d’une autre donzelle plus malléable, car vous n'êtes pas du style à vous attarder avec une ingrate qui ne sait pas la chance qu'elle a de se pavaner à vos côtés.",
        Coach:"Une attraction progressive pour ces deux signes discrets qui recherchent le bien-être amoureux. Ils prendront leur temps pour se laisser aller à l’amour, désirant approfondir la connaissance de l’autre. Une amitié amoureuse tiède, mais sincère. "
    } ,
    Balance_Balance :{
        Slogan:"« L’amour dans sa définition la plus juste : Harmonie, Tolérance, Respect mutuel. »",
        LVA :"Rencontre de deux Vénusiens en quête de la personne idéale dans le but ultime de créer un monde parfait où règnera une harmonie paradisiaque. Certes, l’envie est là, mais vos natures discrètes et délicates vous feront manquer d’audace et de spontanéité. Votre relation est fusionnelle au niveau de vos goûts communs pour l’art et l’esthétique, mais beaucoup plus fragile quant à l’extase des sens. Le désir de vous plaire et vos natures indécises risquent de paralyser votre harmonie à long terme. La Balance a besoin d’un partenaire très différent d’elle, pouvant gérer avec combativité les situations de crise.",
        Plus:"Ils se ressemblent – Ont le même goût pour l’art, les plaisirs  -Ils ont une vie sociale très riche –Savent créer un foyer confortable et luxueux.",
        Moins:"Ils ont les mêmes défauts, dépensiers, superficiels, infidèles, séducteurs – Ils sont oisifs et peu courageux.",
        Craquer:"Elle ressemble en tout point à la déesse de vos rêves, belle, gracieuse et joyeuse. Son sens de l’humour et sa joie de vivre vous enchantent. Toujours partante pour vous accompagner dans vos soirées mondaines, elle vous fera honneur et vous serez l’homme le plus heureux sur terre. Mais vous apprécierez beaucoup moins ses manières enjôleuses et ses œillades pour d'autres hommes, quelle n’aura pas de mal d’ailleurs à subjuguer. Vous vous sentirez un peu le dindon de la farce et ne tarderez pas à déchanter sur cette idyllique histoire.",
        Coach:"Enfermés dans leur protocole de la bienséance, ils s’aborderont avec tact et délicatesse au risque de freiner leurs élans. Séducteurs dans l’âme, ils ne pourront s’empêcher de plaire au risque de déplaire à leur partenaire.  Relation narcissique. "
    } ,
    Balance_Scorpion :{
        Slogan:"« Difficile de faire comprendre que l’amour c’est le bonheur à un esprit autant torturé. »",
        LVA :"La nature conciliante et sociable de la Balance, lui permet de s’adapter à la compagnie  de la plupart des signes du zodiaque, mais sa relation avec le Scorpion va lui poser pas mal de problèmes, car vous avez surtout besoin de paix et de sérénité au sein du couple. En effet, vous supporterez mal les scènes de violence et les invectives blessantes et cruelles du Scorpion dans ses crises de jalousies maladives. Or, en bonne Balance, vous êtes charmeuse à souhait, ce qui exacerbera la nature excessive et soupçonneuse du Scorpion.",
        Plus:"Une attraction entre la beauté irréelle de la balance et le magnétisme envoûtant du scorpion – La curiosité intellectuelle",
        Moins:"Leur nature est diamétralement opposée, le scorpion est excessif  et la balance recherche l’équilibre – L’un désire la paix et l’autre est toujours en guerre – La jalousie et la possessivité du scorpion est mise à mal par l’infidélité de la balance.",
        Craquer:"Vous serez attiré comme un aimant par cette beauté sulfureuse, elle est la tentation et vous n’arriverez pas à résister à son appel. Vous penserez contrôler cette relation et pourtant vous sentirez de la résistance à vos désirs d’harmonie, car elle ne sait vivre l’amour que dans une atmosphère conflictuelle où le jeu de la séduction est compliqué et torturé. Epuisé, vous fuirez cette furie. Un conseil : hésitez longuement avant de vous engager dans ce genre de relation trop violente pour vous, car elle est coriace et résistance à la douleur ce qui n'est pas votre cas.",
        Coach:"Une attraction troublante et insidieuse va envahir la Balance, emportée dans les remous émotionnels du Scorpion, aux sentiments violents et impétueux. Un jeu de séduction tortueux, aux antipodes des rêves romantiques de la Balance. "
    } ,
    Balance_Sagittaire :{
        Slogan:"« Une belle aventure au rythme de soirées mondaines, de plaisirs sans obligations ennuyeuses. »",
        LVA :"La rencontre de la Balance et du Sagittaire ne pourra être qu’agréable, car ce qui vous importe, c’est de vivre intensément en profitant de tout ce que l’existence peut offrir de plus attrayant. La bonne humeur communicative du Sagittaire vous séduira et vous trouverez en cette femme ouverte et franche, une nature joviale qui aime les plaisirs, la fête et les soirées mondaines. Elle, appréciera votre nature accommodante qui s’adaptera à sa nature aventureuse et atypique.",
        Plus:"Leurs goûts pour tous les plaisirs de la vie – Ces deux êtres aiment afficher leur culture – L’adaptabilité de la balance – La tolérance du sagittaire ",
        Moins:"Leur nature infidèle – Les emportements colériques et autres excès de certains sagittaires – Leur inconscience financière – La grossièreté du sagittaire heurte la délicatesse de la balance.",
        Craquer:"Vous ne pourrez que remarquer cette belle amazone qui accapare toute l’attention. Amusante et joyeuse, elle répand sa bonne humeur comme un délicat nectar de bonheur. Elle vous emportera dans une passion endiablée, car elle vit à 100 à l’heure et se nourrit de l’intensité des émotions. Mais ses manières masculines, parfois vulgaires, heurteront votre sensibilité raffinée qui ne supportera pas d'être aux côtés d'une femme si peu délicate. Vous ne recherchez pas une copine de jeu, mais une ravissante compagne qui saura flatter votre ego et partager votre quotidien.",
        Coach:"Le côté bourgeois et snob de la Balance sera séduit par la nature aventurière, spontanée et joyeuse du Sagittaire, qui lui fera découvrir de nouvelles expériences amoureuses inconnues jusqu’alors. Mais leur passion ne sera que superficielle et éphémère. "
    } ,
    Balance_Capricorne :{
        Slogan:"« Une conciliation impossible entre l’oisiveté de l’un et les ambitions de l’autre.  »",
        LVA :"Difficile de comprendre ce qui a pu vous attirer l’un vers l’autre, vous êtes de nature si différente. On ne peut imaginer un couple aussi mal assorti, c’est une des pires  configurations astrales. Certainement vous êtes-vous rapprochés à la suite d’une forte déception amoureuse qui aurait affligé vos vies. Désabusé, vous vous serez retourné vers Madame Capricorne pour rechercher une sécurité morale et financière. Quant à Madame Capricorne, votre chaleur humaine et votre gaieté n’auront été là que pour égayer sa morne vie.",
        Plus:"Le capricorne ambitieux procure un niveau social à la hauteur des rêves de la balance – La balance est une belle hôtesse sachant représenter fort honorablement  le rang du capricorne",
        Moins:"La froideur du capricorne glace la chaleureuse balance – Peu de gestes d’affection et peu de démonstration amoureuse, frustre l’amoureuse balance - L’avare capricorne freine les excès dépensiers de la balance pour des futilités.",
        Craquer:"Son charme éthéré, réservé, lui donne un petit côté irréel et fragile qui vous donne envie de mieux la connaître. Mais ne vous fiez pas à son apparente sensibilité à fleur de peau, car derrière ce petit oiseau sans défenses, se cache une ambitieuse et une acharnée de travail, exigeante avec elle-même, mais aussi avec les autres. Alors votre nature artiste bohème, sans obligations, se laissant porter par le vent de l’inspiration, ne fera pas bon ménage avec sa rigueur de vie. Très vite, se sentant en danger,  elle reprendra le contrôle du couple vous imposant un rythme qui épuisera vos dernières ressources.",
        Coach:"Rencontre de l’insouciance et de l’austérité qui laisse présager une relation amoureuse basée sur l’amour du pouvoir et du décorum où l’émotion et les sentiments sont exclus. Complicité difficile. "
        } ,
    Balance_Verseau :{
        Slogan:"« Vous savez vous apprécier à votre juste valeur sans jugement aucun. »",
        LVA :"Votre relation sera très harmonieuse, car elle alliera l’influence Vénusienne de la Balance à l’ingéniosité et l’originalité du Verseau. Votre attraction ressemble à une amitié-amoureuse basée sur la curiosité et le contact humain. Vous vous harmoniserez dans les domaines de la musique et des arts, vous entourant d’objets rares. Sociable et conciliant, vous accepterez les excentricités de Madame Verseau sans jugements et saurez être un hôte accueillant pour ses nombreux ami(e)s.",
        Plus:"Leur nature aérienne  - Le plaisir de la rencontre et le goût de la vie sociale – L’amour de l’art et des antiquités –L’acceptation de leur légèreté amoureuse.",
        Moins:"Le verseau manque de chaleur amoureuse et d’attention – La solitude du verseau – La frivolité de la balance et ses extravagances financières.",
        Craquer:"Son look un peu bohème et atypique vous séduira immédiatement, car vous sentirez au-delà des apparences, que vous avez trouvé celle qui ne jugera pas vos us et coutumes et votre manière de vivre un peu décalée. Sa nature libertine n’est pas sans vous émoustiller et créera des remous émotionnels qui vous transporteront. Vous aimerez sa finesse d’esprit et passerez des heures durant à converser sur divers sujets d’actualités. Vous avez trouvé votre complice de tous les instants.",
        Coach:"Une attraction intellectuelle et spirituelle va motiver cette rencontre hors du commun. L’élégance de la Balance va subjuguer notre rebelle Verseau qui brillera par ses éclairs de génie. Harmonie des âmes et spiritualisation de l’amour. "
    } ,
    Balance_Poissons :{
        Slogan:"« Un manque d’affection comblé mais des caractères trop divergents. Manque d’admiration. »",
        LVA :"Vos natures calmes, refusant tout conflit, ne pouvaient que s’attirer. Votre amour pour la musique, la poésie et bien d’autres sujets artistiques, dénotent deux personnes un peu bohèmes et insouciantes. Mais votre compatibilité s’arrête là, car même si Madame Poisson peut vous impressionner par son charme hypnotique, vous susurrant des mots doux, vous vous rendrez vite compte que ses belles paroles ne sont que des artifices n’ayant pas de consistances amoureuses. Cette sirène vous trouvera alors très superficiel et snob, pas assez spirituel ni même affectueux.",
        Plus:" Leur goût commun pour l’art, la musique – Ils sont tous deux amoureux de l’amour assez superficiellement.",
        Moins:"L’inconséquence du poisson  et son instabilité – Leur vision de l’amour est trop différente, le poisson sublime l’amour en général alors que la balance cherche le grand Amour.",
        Craquer:"Elle est si irréelle que vous êtes littéralement fasciné. Vous avez l’impression qu’elle ondule et attiré par le chant de cette sirène, vous vous sentez pris au piège de son charme envoûtant qui vous transporte dans des zones inexplorées de l’amour. Mais vos alertes sensorielles vous mettront en garde contre cette amoureuse illusoire qui n’aime qu’en surface pour le plaisir de la sensation et qui s’en retournera à ses crises  de dépression quasi  mystiques.",
        Coach:"Le charme hypnotique du Poisson fascine la Balance qui pressent une relation romantique et idyllique. Mais très vite le manque de compréhension et les quiproquos sonneront le glas final de cette illusoire histoire d’amour. "
    } ,
    Scorpion_Belier : {
        Slogan:"\" Un bras de fer qui gâchera l’attirance instinctive de ces deux guerriers. \"",
        LVA :"Attention relation sous haute tension sexuelle, tous deux addicts au sexe, vous vous retrouverez à tester toutes les positions du kamasutra et à essayer sans tabous les expériences les plus insolites. 46%  concentrés dans l’érotisme du couple, car le tandem  Bélier/Scorpion ne s’accorde, ni d’un point de vue psychologique, ni d’un point de vue intellectuel. Le Scorpion plus subtil et sadique désoriente totalement le Bélier franc et direct sans réflexions.",
        Plus:"Le sexe of course – Une passion dévorante à se consumer – Deux excessifs en quête d’absolu.",
        Moins:"Le bélier franc trouvera le scorpion pernicieux et fourbe – Leurs excès déborderont en colères violentes – Avec eux c’est l’amour et la guerre.",
        Craquer:"On ne sait pas vraiment quel est celui qui a repéré l'autre en premier, car Madame Bélier est une chasseuse, un peu dévoreuse d'homme, mais ce ne sera pas pour vous déplaire. Tous vos sens seront en alerte, vous pressentez une rencontre placée sous haute tension sexuelle. Mais attention à ne pas perdre la tête, car vous devrez contrôler les débordements de domination de Madame.",
        Coach:"On ne sait quel est celui qui entraîne réellement l’autre dans ses délires excessifs. Elle le provoque de ses tenues affriolantes et lui démarre au quart de tour, prêt à toutes les folies, sa libido en éveil. Mais leurs ébats terminés, c’est la guerre. "
    } ,
    Scorpion_Taureau :{
        Slogan:"\" Fascination et défis passionnés pour ces deux caractères bien trempés. \"",
        LVA :"Relation fusionnelle où l’attraction peut devenir très vite fatale et violente. Dans tous les cas, cette relation sera basée sur un désir impérieux, une sexualité explosive dans une quête unique  de sensations fortes. Insatiables, vous n'aurez de cesse d’aller toujours plus loin dans les excès en tout genre, ce qui rendra vite l’atmosphère électrique et agressive, car vous manquez, tous deux, grandement de souplesse. Vous êtes deux têtus, obstinés et jaloux et les disputes rythmeront cette relation.",
        Plus:"Une attirance incontrôlée par ses bas instincts – Une sexualité débridée – Tous deux recherchent la stabilité – Ils aiment l’argent et le luxe.",
        Moins:"Suspicion – Colères violentes – Rapport de force et de domination – Jalousie – Tyrannie des deux signes – Rancunes – Les ruptures sont empreintes de vengeance.",
        Craquer:"Elle vous séduira par ses formes voluptueuses. Son charme sensuel et charnel vous donnera envie de la posséder, cette femme-là est faite pour l'amour ! Sa nature constante en fera une candidate sérieuse pour un avenir commun, mais vous devrez corriger sa fâcheuse manie à vouloir vous dicter sa loi, vous n'êtes pas homme à être dirigé par une femme. Alors attention aux grincements de dents.",
        Coach:"Ils se défieront du regard et le Scorpion attirera le Taureau par son magnétisme envoûteur, mais attention, telle une mante religieuse, le Scorpion veut posséder le Taureau. Vous rentrerez dans un combat pour le pouvoir. Relation sous haute tension sexuelle."
    } ,
    Scorpion_Gemeaux : {
        Slogan:"\" Jeux cérébraux sur fond de manipulation. Rien de constructif dans ce genre de relation. \"",
        LVA :"Vous êtes deux provocateurs et vous aurez du mal à trouver un terrain d’entente sur le long terme. L’inconstance du Madame Gémeau aura tendance à désorienter et exacerber votre jalousie et vous possessivité. Un tantinet paranoïaque, vous entrerez dans des colères monstres. Le Gémeau a du mal à comprendre la torture mentale dans laquelle vous vous complaisez. Très charnel, vous attendez de votre compagne des ébats plus torrides et plus instinctifs, mais là ça risque de coincer.",
        Plus:"Tous les deux sont très curieux, intelligents avec un esprit vif – Ils aiment  la provocation et la polémique.",
        Moins:"L’instabilité du gémeau déconcerte le scorpion – Tous deux sont manipulateurs  - Deux esprits tourmentés et angoissés – L’infidélité.",
        Craquer:"Dans un premier temps, vous  l'observerez tel un prédateur tapi dans l'ombre, prêt à bondir sur sa proie. Vous la trouverez fraîche et légère, intelligente, ses mots d'esprits vous feront sourire. Pourtant jolie fille, vous ne la trouverez pas assez sexy. Dès vos premiers assauts provocateurs, vous vous heurterez à un refus outragé et un caractère bien trempé qui ne compte pas se laisser embarquer dans une relation primaire. Sa résistance peut vous plaire, mais vous comprendrez assez vite que vos envies respectives sont trop éloignées.",
        Coach:"Attraction dangereuse pour ces deux provocateurs qui se lancent des défis intellectuels dans lesquels ils se découvrent réciproquement avec beaucoup d’enthousiasme, les plongeant inconsciemment dans une relation obscure. "
    },
    Scorpion_Cancer :{
        Slogan:"\" Une fascination envoûtante, une fusion des sens. Relation magique. \"",
        LVA :"Vous êtes tous les deux animés et dirigés par vos intuitions et de fortes émotions. Vous vous accordez aussi bien sur le plan amoureux que sur le plan sexuel. Le Scorpion aime intensément, jalousement et exige une fidélité sans faille, ce que le Cancer n’aura pas de mal à lui consacrer, car cela fait partie de ses principes moraux. Le caractère malléable et soumis du Cancer s’adaptera parfaitement aux exigences et à la tyrannie du Scorpion. Seules les paroles blessantes du Scorpion pourraient ébrécher cette union magique.",
        Plus:"Des émotions transcendées – Une relation fusionnelle– Deux caractères qui se complètent – Une énergie sexuelle tantrique –Chacun trouve sa place. ",
        Moins:" La tyrannie et la cruauté du scorpion risque de déstabiliser cette relation idyllique – La passivité du cancer agace le  dynamisme du scorpion.",
        Craquer:"Vous aimerez son ultra féminité, qui réveille vos sens et émoustille vos désirs passionnés. Entre vous, nul besoin de mots, vous vous ressentez, c'est inné. Une connexion quasi magique. Son caractère malléable s'adaptera parfaitement à votre nature exclusive et tyrannique. Son amour est inconditionnel, mû par une admiration sans bornes. Vous devrez cependant tempérer la dureté de vos paroles lorsque vous piquez une colère afin de ménager sa sensibilité et sa fragilité.",
        Coach:"Une attraction irrésistible mue par de violentes émotions. Un lien intense où se mêlent fascination et tourments. Une relation ou le Cancer sera la  victime consentie et le Scorpion son tortionnaire. Un amour torturé où l’érotisme joue un rôle important. "
    } ,
    Scorpion_Lion :{
        Slogan:"\" Difficile de concéder à l’autre la place du chef. Rapports de force passionnels. \"",
        LVA :"Rencontre de deux forces de la nature, l'une diurne, avec un feu intérieur bouillonnant, et l'autre solaire, ardent et exubérant, c'est le choc des titans. Ces deux tyrans à la personnalité inflexible et au  manque d'adaptabilité vont se rendre une vie impossible.  Seule l'attraction sexuelle pourra vous mettre d'accord un certain temps et faire perdurer malgré tout cette relation basée sur le rapport de force, sans concessions.",
        Plus:"Une  potentielle réussite pour une association professionnelle - Une sexualité intense assumée mais manquant de tendresse.",
        Moins:"Le scorpion pique le lion au vif - La violence de ces deux excessifs - Le scorpion est plus pervers et le lion trop direct - Rapports difficiles au pouvoir.",
        Craquer:"Vous ne serez pas insensible à son charme éblouissant. Son allure altière et orgueilleuse vous donne envie de l'asservir à vos désirs. Vous aimerez particulièrement la titiller et la provoquer avec des remarques sarcastiques sur ses manières hautaines afin de la faire redescendre de son piédestal. Mais vous aurez affaire à une forte tête qui ne comptera pas se laisser humilier de la sorte et qui saura rétorquer faisant mouche elle aussi. Cette relation sera un véritable bras de fer entre deux orgueilleux dominateurs.",
        Coach:"Attraction animale entre deux personnalités dominatrices. Leur relation est surtout passionnellement érotique sur fond de torture mentale. L’intensité sexuelle n’est qu’un instrument pour prendre le pouvoir de l’un sur l’autre. Relation destructrice. "
    } ,
    Scorpion_Vierge :{
        Slogan:"\" Couple très harmonieux où l’efficacité et la sincérité sont de rigueur. \"",
        LVA :"Il semblerait que le Scorpion soit né pour charmer la méfiante Vierge et la faire tomber dans son piège amoureux. Comme si le Scorpion possédait de façon innée, le mode d’emploi pour percer sa cuirasse. Il faut dire que vous userez et abuserez de votre magnétisme envoûtant pour libérer cette femme, Vierge sage, de ses tabous intellectuels et physiques, faire tomber toutes ses barrières de résistance pour la rendre folle d’amour, esclave de ses sens et l’asservir",
        Plus:"Leur acuité intellectuelle – Ils se comprennent et savent s’apprivoiser – Le scorpion révèle la nature cachée de la vierge – La vierge est soumise au dominant scorpion.",
        Moins:"Les excès destructeurs du scorpion révoltent la vierge soumise – Le sadomasochisme.",
        Craquer:"Sa personnalité inhibée peut être une cause de désintérêt, et pourtant votre instinct ne vous trompe jamais et vous sentirez que sous ce voile de pudeur se cache un désir refoulé d'aller au-delà de ses propres limites. Avoir le rôle d'initiateur est un costume qui vous plaira d'endosser, lui faisant découvrir des pulsions insoupçonnées. Elle se prêtera sans résistance à vos jeux,  fascinée par votre charme irrésistible et pénétrant qu'elle apprécie tant. Elle, grâce à son intelligence analytique, réussira à dompter les vieux démons qui vous tourmentent.",
        Coach:"Le Scorpion a incontestablement les qualités requises pour neutraliser les défenses de la méfiante Vierge qui sera subjuguée par son charme magnétique. Leur vivacité d’esprit et la pertinence de leurs observations les attireront comme un aimant. "
    } ,
    Scorpion_Balance :{
        Slogan:"\" Difficile de faire comprendre que l’amour c’est le bonheur à un esprit autant torturé. \"",
        LVA :"La nature conciliante et sociable de la Balance, lui permet de s’adapter à la compagnie  de la plupart des signes du zodiaque, mais sa relation avec le Scorpion va lui poser pas mal de problèmes, car la Balance a surtout besoin de paix et de sérénité au sein du couple. En effet, elle supportera mal vos scènes de violence et vos invectives blessantes et cruelles dans vos crises de jalousies maladives. Or, en bonne Balance, elle est charmeuse à souhait, ce qui exacerbera votre nature excessive et soupçonneuse.",
        Plus:"Une attraction entre la beauté irréelle de la balance et le magnétisme envoûtant du scorpion – La curiosité intellectuelle ",
        Moins:"Leur nature est diamétralement opposée, le scorpion est excessif  et la balance recherche l’équilibre – L’un désire la paix et l’autre est toujours en guerre – La jalousie et la possessivité du scorpion est mise à mal par l’infidélité de la balance.",
        Craquer:"Nul besoin qu’elle se trémousse langoureusement pour vous titiller, vous l'avez déjà repéré depuis un moment. C'est une déesse,  et vous prendrez tant de plaisir à la rendre folle de vous. Cette aguicheuse ne pourra pas résister à votre magnétisme envoûtant. Pour commencer, vous l’ignorerez, et cela l’agacera au plus haut point, car elle pense que personne ne peut lui résister. Mais vous, vous n’êtes pas tout le monde. Cependant prenez garde, elle a un besoin viscéral de séduire, d’allumer et vous n’aimez pas les filles faciles. ",
        Coach:"Une attraction troublante et insidieuse va envahir la Balance, emportée dans les remous émotionnels d’un Scorpion, aux sentiments violents et impétueux. Un jeu de séduction tortueux, aux antipodes des rêves romantiques de la Balance."
    } ,
    Scorpion_Scorpion :{
        Slogan:"\" Attirance magnétique, incontrôlable et dévorante.\"",
        LVA :"C’est une attraction irrésistible entre le sulfureux et ténébreux Scorpion et la bienheureuse Sagittaire, grande jouisseuse devant l’éternel. Madame Sagittaire va s’emballer pour cet amoureux avide de sensations fortes, mais se rendra compte bien trop tard qu’elle s’est fait prendre au piège par un partenaire torturé. Mais amoureuse et enthousiaste, Madame Sagittaire pensera, certainement à tort, qu’elle réussira à apaiser les tourments de son partenaire. Quant au Scorpion destructeur, il ne pourra que continuer à distiller son venin dans les certitudes de cette grande naïve dans le but ultime de la posséder. L’indépendance de Madame Sagittaire sera fatale à cette histoire. ",
        Plus:"Leur nature passionnée et entière – La sincérité – Leur curiosité intellectuelle.",
        Moins:"Le besoin d ‘aller toujours plus loin-  Leur perversité – La violence des conflits.",
        Craquer:"Au premier coup d'oeil vous avez reconnue une adversaire enfin à votre taille. Elle vous inspire, elle dégage un tel magnétisme sexuel que vous vous sentez envoûté et ce sentiment vous transporte. Vous savez que vous allez aimer explorer et dépasser avec elle, vos limites érotiques, descendant au plus profond de votre psyché. Cette relation vous consume et vous épuise, alors qui de vous deux va jeter l’éponge le premier ??",
        Coach:"69% de haute tension sexuelle entre ces deux passionnés où l’exaltation des sentiments est intense et violente. Relation qui n’est pas de tout repos sur fond de jalousie et de possessivité. Une passion qui alterne rupture et réconciliation. "
    } ,
    Scorpion_Sagittaire :{
        Slogan:"\" Echanges sulfureux, mais le sagittaire se tiendra toujours sur ses gardes. \"",
        LVA :"C’est une attraction irrésistible entre le sulfureux et ténébreux scorpion et le bienheureux sagittaire grand jouisseur devant l’éternel. Le sagittaire va s’emballer pour cet amoureux avide de sensations fortes mais se rendra compte bien trop tard qu’il s’est fait prendre au piège par un partenaire torturé. Mais amoureux et enthousiaste, il pensera certainement à tort qu’il réussira à apaiser les tourments de son partenaire. Quant au scorpion destructeur, il ne pourra que continuer à distiller son venin dans les certitudes de ce grand naïf dans le but ultime de le posséder. L’indépendance du sagittaire sera fatale à cette histoire.",
        Plus:"Leur quête du plaisir – Tous deux sont passionnés.",
        Moins:"Le pessimisme du scorpion s’oppose à l’optimisme du sagittaire – Le possessif scorpion ne supporte pas l’indépendance du sagittaire.",
        Craquer:"Vous apprécierez cette nature généreuse et enthousiaste, vous qui êtes si pessimiste, vous prendrez votre dose de bonheur avec cet être vivifiant.  Ensemble vous passez des moments de plaisirs non dissimulés mais vous ne pouvez vous empêcher de vouloir l’emprisonner ce qui à terme, pourrait tout gâcher car Madame ne permet à quiconque de lui dicter sa conduite. Elle ne pourra se soumettre que provisoirement et par amour.",
        Coach:"Le Scorpion séduit sa proie en l’hypnotisant par son charme magnétique et notre candide Sagittaire, toujours prêt pour une nouvelle aventure, s’engage naïvement dans une histoire d’amour aux odeurs de souffre, où il risque d’y perdre son âme. Amour torturé et tortueux. "
    } ,
    Scorpion_Capricorne :{
        Slogan:"\" Quoi de plus jouissif que de déceler la faille de l’autre. Relation d’emprise réciproque. \"",
        LVA :"Ces deux signes sont les plus résistants et coriaces du zodiaque, tous deux ne rechignent pas à la difficulté, bien au contraire. Vous serez stimulés par les contraintes et les montagnes escarpées. La froideur sévère de Madame Capricorne vous attire, sûr de pouvoir faire faiblir ce cœur de pierre. Madame Capricorne, quant à elle, ne sera pas insensible à votre charme ravageur et se laissera volontiers emporter dans une passion folle, mais contrôlée. Vous aimez tous les deux le pouvoir et n'êtes pas de ceux qui se soumettent. Vous vous respectez mutuellement, reconnaissant vos valeurs de dominant. ",
        Plus:"Leur résistance à la souffrance – Leur curiosité intellectuelle – Leur soif de pouvoir –Leur respect mutuel – Des valeurs morales qui leur sont chères.",
        Moins:"Tous deux sont des dominants – Le capricorne manque de passion pour le scorpion – La rivalité dans la quête à la réussite.",
        Craquer:"Son apparence froide et distante excite vos sens car vous aimeriez découvrir ce qu’il se cache derrière ce voile glacial. Vous êtes sûr un volcan endormi que vous saurez réveiller pour votre plus grand plaisir. Vous saurez user de patience et de persévérance pour qu’elle se libère et que vous puissiez enfin la posséder. Docile, elle ne résistera pas, mais attention, sa fragilité apparente n’est qu’un leurre, car derrière cette nature éthérée se cache une personnalité affirmée.",
        Coach:"Attiré par l’inaccessible, le Scorpion mettra un point d’honneur à faire céder à ses avances pressantes ce Capricorne froid et austère. Tous deux ont le goût du pouvoir et le culte du travail et se respectent pour ces qualités, mais auront du mal, à terme, à se supporter. "
        } ,
    Scorpion_Verseau :{
        Slogan:"\" Relation de curiosité parfois malsaine. Aucun des deux ne cède réellement à l’autre. \"",
        LVA :"Pour ces deux rebelles, la relation ne sera pas en demi-teinte, ni de tout repos, ce sera tout ou rien, car vous n'avez pour règles que celles que vous dictez.  Votre devise commune « ni Dieu ni Maître » tout un programme. Vous passerez très facilement de l’amour passionné à la haine, à cause de vos natures obstinées et votre goût pour les histoires compliquées. La routine et les relations insipides, très peu pour vous. Vous avez besoin de vous défier dans un duel où les limites seront  toujours repoussées jusqu’à ce que l’un des deux obtempère. Cela risque d’être cinglant !",
        Plus:"Leur besoin d’originalité – Leur atypisme – Leur esprit éveillé –La sexualité sans tabous.",
        Moins:"La possessivité du scorpion et l’ultra-indépendance du verseau – Les colères du scorpion font fuir le verseau qui est anti-conflits.",
        Craquer:"Avec elle, vous subirez une attraction un peu particulière, car elle aime votre fougue et a besoin de sentir la passion l’envahir, surtout durant vos ébats. Elle rejette toute relation ennuyeuse et fade, mais avec vous, c’est l’extase !  Lorsque vous penserez l’avoir soumise à vos désirs, elle disparaîtra, se manifestant peu, dans une indifférence totale ce qui vous déstabilisera et attisera votre jalousie, car vous ne comprendrez pas sa distance, suspectant un rival. Mais vous perdez votre temps, car elle ne sacrifiera jamais sa liberté pour vos chaînes.",
        Coach:"Une relation sous haute tension où ces deux rebelles se défieront dans un combat sans merci, alternant passion et haine. Leur goût prononcé pour les histoires compliquées expliquera leur persévérance  dans cette relation sans issue."
    } ,
    Scorpion_Poissons :{
        Slogan:"\" Attraction fatale, ils peuvent aussi bien faire leur malheur que leur bonheur. \"",
        LVA :"Il semblerait que ces deux-là, aient été destinés l’un à l’autre, le scorpion dominateur trouve dans la nature soumise du poisson, l’esclave idéal à ses tyrannies. On parle de relation sado-maso consentie, le tempérament fort du scorpion profite de la docilité et du dévouement du poisson qui aime se détruire dans l’amour. Cette relation génère une  forte intensité d’émotions et de sensations sublimées dans leur érotisme passionné. Seule la lassitude du scorpion de s’amuser avec « ce jouet qui ne dit jamais non» pourra le détourner cette relation mais le poisson peut aussi décider de mettre fin à cette histoire, las des multiples tromperies et humiliations du scorpion.",
        Plus:"Des émotions puissantes et exaltantes les animent –une sexualité débridée.",
        Moins:"Le poisson manque d’affection et de tendresse face à l’agressivité du scorpion – La cruauté du scorpion blesse le poisson – la passivité du poisson agace le scorpion.",
        Craquer:"Du premier coup d’œil, vous serez attiré par cette sensuelle beauté un brin irréelle. Le mystère vous attire et vous excite, elle est l’incarnation de la femme idéale, dévouée à vos moindres désirs, celle que vous pourrez éduquer sexuellement et asservir. Elle subira sans se plaindre et vous donnera même l’impression d’y prendre plaisir. Mais sa nature contemplative et son manque de résistance à vos caprices pourraient vous détourner de son amour inconditionnel, lassé de tant de facilité.",
        Coach:"Une relation sous haute tension où ces deux rebelles se défieront dans un combat sans merci, alternant passion et haine. Leur goût prononcé pour les histoires compliquées expliquera leur persévérance  dans cette relation sans issue. "
    } ,
    Sagittaire_Belier : {
        Slogan:"« Une complicité évidente pour ces deux jouisseurs. Le plaisir avant tout ! »",
        LVA :"Une osmose et des goûts communs pour les découvertes, le sport et les voyages. Tous deux très généreux, vous vous accorderez sur votre vision du couple, plutôt libre et sans contraintes. Partager de bons moments de plaisir et de joie de vivre correspondent à vos attentes communes. D’un point de vue sexuel, l’objectif est de partager un moment délicieux, ardent et sans artifices, juste pour le plaisir, sans attendre plus de l’autre.",
        Plus:"Une relation sous le signe de la bonne humeur – Une passion tempérée – Une relation libre – Des loisirs communs comme le sport.",
        Moins:"Une relation un peu légère basée sur la complicité et risquant de devenir copain copine - La fidélité n’est pas au programme.",
        Craquer:"Elle est l’amie-amante que vous avez toujours voulu rencontrer, vous pourrez lui proposer n’importe quel plan, elle sera toujours partante parce que tant qu’il y a du fun il y a de la vie. Votre histoire est une franche rigolade où la sensualité n’est pas en reste, car vous vivrez le sexe dans une spontanéité enivrante. Seules ses crises de jalousie et ses états d’âme amoureux trop fréquents pourraient refroidir vos ardeurs, car il ne faut pas toucher à votre liberté chérie, ni gâcher votre plaisir par des futilités,  vous seriez intransigeant et n’hésiteriez pas à trancher dans le vif, par une rupture brutale et sans appel.",
        Coach:"Vous vous entendez comme deux larrons en foire. Actifs et aventuriers, vous serez toujours prêts à vivre pleinement vos envies, ce qui compte c’est le fun, sans prise de tête. Votre devise est Carpe Diem. Relation basée sur le plaisir. "
    } ,
    Sagittaire_Taureau :{
        Slogan:"« La liberté de l’un empêchera l’épanouissement de l’autre. Relation manquant de tolérance. »",
        LVA :"Relation difficile, diamétralement opposée dans vos envies et votre façon de voir la vie à deux. Madame Taureau a ses habitudes et aime la routine de la vie de couple, cela la rassure, alors que vous ne vivez que de changements, de mouvements, vous êtes un aventurier au sens propre comme au sens figuré. Vous ne vous entendrez que dans la sexualité, car vous êtes tous les deux des jouisseurs  épicuriens qui aiment les plaisirs de la vie. Mais, trop indépendant,  vous fuirez la possessivité de Dame Taureau qui attend un engagement que vous ne serez pas prêt à lui garantir.",
        Plus:"Le partage de bons moments, des plaisirs de la chair et de la table – L’amour de la nature,  des plaisirs simples. ",
        Moins:"Le sagittaire est un libre et le taureau veut l’attacher – La jalousie et la possessivité du taureau – L’inconstance du sagittaire  et son infidélité.",
        Craquer:"Très féminine, sensuelle et aguicheuse, vous ne pouviez que la repérer, après tout, n’a-t-elle pas tout fait pour ça ? Elle est si appétissante que vous passeriez bien quelques instants coquins avec elle. Si elle ne semble pas farouche, elle ne sait pas vivre des moments de plaisirs enivrants sans parler d’amour. Vous, vous ne mélangez pas tout. Alors pourquoi se prendre la tête dans des moments de pur bonheur ? Cette  femme est bien  trop compliquée dans sa tête et ne sait vraiment pas se laisser aller au plaisir de l’instant présent.",
        Coach:"La possessivité du Taureau va se heurter à l’excès d’indépendance du Sagittaire. Malgré leurs goûts communs pour les plaisirs simples de la vie, le Taureau tentera en vain de freiner l’exubérance du Sagittaire ce qui plongera le Taureau dans un sentiment d’insécurité. "
    } ,
    Sagittaire_Gemeaux : {
        Slogan:"« Cours après moi que je t’attrape, rien de tel pour aiguiser les sens et tuer l’ennui. »",
        LVA :"La monotonie n’existe pas dans votre relation, car vous avez en commun le goût des mouvements, des déplacements et des voyages. Votre complémentarité crée votre attirance. Tous deux avides de connaissances, vous tirerez pas mal de satisfactions à vos échanges  d’idées. La liberté et la compromission faisant partie de vos personnalités, la fidélité ne sera pas l’ingrédient principal de votre histoire. Votre relation sera plutôt basée sur une amitié amoureuse plus que sur le grand amour.",
        Plus:"Leur amour de la liberté – L’appel des grands espaces – La curiosité intellectuelle – La tolérance et le respect de la liberté de chacun.",
        Moins:"Un manque certain de passion  amoureuse – L’infidélité – Le refus d’engagement – L’hyper-indépendance du sagittaire.",
        Craquer:"Vous serez emballé par sa légèreté et sa fraîcheur juvénile. Elle est franchement drôle, vive d'esprit, ce qui vous amuse au plus haut point. Elle s'enthousiasmera comme vous à chaque proposition de sorties ou de voyage. Elle aime s’amuser, bouger  et rire. Elle serait parfaite si dans vos moments intimes elle était un peu plus délurée. Elle est bien trop intellectuelle et chichiteuse. Elle gâchera vos soirées par ses manières directives de désapprouver vos petits débordements. Débordements qui ne sont pourtant que quelques petits flirts insignifiants ! Pas de quoi en faire toute une histoire ! ",
        Coach:"Une truculente aventure entre ces deux curieux et jouisseurs de la vie qui s’entraîneront dans une relation légère et parfois court vêtue… où les tabous seront absents, l’ennui exclus et la liberté chérie. Mais qu’en est-il de la vraie vie ? "
    },
    Sagittaire_Cancer :{
        Slogan:"« Des débuts prometteurs mais des envies trop divergentes pour que ça colle. »",
        LVA :"Le Sagittaire, aventureux et voyageur,  alimente l’imaginaire de Madame Cancer, douce rêveuse, qui voit en cette relation l’ouverture vers un amour exotique et hors norme. Tout sera possible entre vous, à partir du moment où aucune discussion sérieuse ne sera entamée. Dans le cas contraire, notre aventurier des temps modernes n’hésitera pas 5 minutes à prendre la poudre d’escampettes. Le plaisir avant tout, mais pourquoi voudrait-il donc échanger sa vie de liberté pour une vie remplie d’obligations ? ",
        Plus:"Ils sont tous deux épicuriens - Ont leur tête remplie de rêves - L’imagination est au centre de leur vie – Ils aiment faire la fête.",
        Moins:"Le sagittaire aime être libre sans obligations alors que le cancer a besoin d’avoir une vie d’obligations – L’infidélité.",
        Craquer:"Elle a su vous attirer grâce à des atouts de charme irrésistibles, il est vrai que vous ne vous posez même pas de questions, vous foncez. Elle vous inspire des moments voluptueux et vous n’êtes pas de ceux qui laissent  échapper un instant de plaisir quel qu’il soit. Vous la trouverez même douée pour vos jeux interdits, câline et coquine à la fois, quel délice ! Mais sa fâcheuse manie de vous harceler de messages, de vous parler de la maison de ses rêves, du chien et des trois marmots que vous aurez ensemble, vous font regretter vos égarements avec elle. Vous ne pensiez pourtant pas lui avoir fait de fausses promesses ? ",
        Coach:"Une rencontre qui ne peut être que le fruit d’un heureux hasard galant. Leur peu d’affinités ne peut espérer plus qu’un moment d’égarement. Le pétillant Sagittaire sera vite refroidi par les fluctuations émotionnelles du très angoissé Cancer. "
    } ,
    Sagittaire_Lion :{
        Slogan:"« Relation ardente et conquérante, une aventure passionnante et grisante. »",
        LVA :"Rencontre de deux natures enthousiastes et généreuses, enclines à jouir pleinement de la vie. Votre relation sera basée sur la spontanéité, la sincérité et la franchise. Ce duo de feu, aux goûts de luxe et aux dérives excessives auront une complicité à toute épreuve, basée sur les plaisirs à deux. Votre sexualité est gourmande et fougueuse. Seule divergence, le Lion se complet à créer un nid confortable et fastueux alors que le Sagittaire rêve de parcourir le monde ne s’attachant pas à un lieu fixe.",
        Plus:"Leur nature passionnée et sincère – Épicuriens et jouisseurs ils partageront le plaisir des fêtes et des soirées bien arrosées.",
        Moins:"Leurs colères sont dévastatrices – Ils sont tous deux très orgueilleux – L’autorité du lion se heurte à l’indépendance du sagittaire.",
        Craquer:"Vous l’aurez certainement rencontré à une soirée mondaine avec des peoples, ces soirées où vos amis branchés vous invitent toujours. Elle vous éblouit de sa beauté hautaine, et les prétentieuses ça vous excite, vous aimez les défis et votre bagout sait comment les séduire. Vous êtes un  caméléon, un joueur qui s’adapte aux personnages qui vous intéressent. Cette femme est une grande passionnée avec laquelle vous vivrez des moments intenses de plaisirs. Seuls les moments où elle croira pouvoir user d’autorité sur vous, créeront des conflits où vos deux natures excessives surenchériront dans la colère, mais vous saurez vous réconcilier… sur l’oreiller...",
        Coach:"Joli couple de signes de Feu, formé dans des élans enthousiastes d’une passion ardente et joyeuse. Une complicité motivée par des sentiments nobles et entiers dont il faudra soustraire toute compétition pour éviter de tout gâcher. "
    } ,
    Sagittaire_Vierge :{
        Slogan:"« Désordre et rigueur n’ont jamais fait bon ménage ! l’un range l’autre dérange. »",
        LVA :"Vos différences de nature sont tellement évidentes qu’il sera difficile pour vous de trouver un terrain d’entente. La méticulosité et l’ordre de la Vierge agacent le désordonné Sagittaire. L’esprit d’aventure et d’improvisation du Sagittaire déstabilise la prudente Vierge qui planifie tout à l’avance, évitant toutes surprises qui pourraient l’angoisser. Cependant, la bonne nature enthousiaste du Sagittaire pourrait bien vouloir faire découvrir des horizons insoupçonnés à cette frileuse peureuse Vierge, mais pas moins curieuse et spirituelle.",
        Plus:"Relation dans la curiosité intellectuelle et philosophique de la vie – Tous deux aiment se cultiver et apprendre - Ils sont tous deux épicuriens.",
        Moins:"Des natures diamétralement opposées – Leurs défauts les dérangent et peuvent les amener à se détester.",
        Craquer:"Vous la surprendrez à s’émerveiller en vous écoutant narrer votre dernier voyage en Inde, elle aimerait tellement avoir autant d’audace que vous, mais elle n’ose pas et puis tous ces pays où existent pauvreté et saleté la rebutent, ne mettons-nous pas notre santé en danger dans des coins pareils ? Vous aurez envie de la décoincer un peu et vous vous rendrez compte qu’elle n’est pas aussi timorée qu’elle n’y parait, surtout dans l’intimité, cette femme, a des trésors de volupté cachés derrière un voile de timidité. Sa crainte de l’imprévu qui l’angoisse, va vite donner une tournure pépère à votre histoire. Trop d’organisation pour un adepte de l’improvisation vous fera tomber très vite dans l’ennui.",
        Coach:"Il faudra toute l’exubérance et l’enthousiasme du Sagittaire pour conquérir notre prudente Vierge. Mais leurs caractères antinomiques risquent fort de vouer très rapidement à l’échec cette alliance de l’ordre et de l’éparpillement. Passion éphémère. "
    } ,
    Sagittaire_Balance :{
        Slogan:"« Une belle aventure au rythme de soirées mondaines, de plaisirs sans obligations ennuyeuses.  »",
        LVA :"La rencontre de la Balance et du Sagittaire ne pourra être qu’agréable, car ce qui vous importe, c’est de vivre intensément en profitant de tout ce que l’existence peut offrir de plus attrayant. Votre bonne humeur communicative séduira Madame Balance et elle trouvera en vous un homme ouvert et franc, de nature joviale qui aime les plaisirs, la fête et les soirées mondaines. Vous, vous apprécierez sa nature accommodante qui s’adaptera à votre nature aventureuse et atypique. ",
        Plus:"Leurs goûts pour tous les plaisirs de la vie – Ces deux êtres aiment afficher leur culture – L’adaptabilité de la balance – La tolérance du sagittaire  ",
        Moins:"Leur nature infidèle – Les emportements colériques et autres excès de certains sagittaires – Leur inconscience financière – La grossièreté du sagittaire.",
        Craquer:"Vous vous croisez dans un lieu branché et vous l’avez tout de suite remarqué. Sa beauté presque irréelle, insaisissable, la différencie des autres, elle vous fascine et vous enflamme. Comme vous, elle aime les soirées mondaines et faire la fête. Vous serez un couple très en vue et partagerez le meilleur passionnément. En ce qui concerne le pire, elle vous fera dépenser beaucoup d’argent, car elle aime le beau, le faste, les grands restaurants, madame est une Princesse qui attend que son Prince soit grand seigneur et la gâte à la hauteur de ses attentes. Mais elle sera intransigeante et ne vous passera aucune de vos faiblesses, car Madame attend la perfection. Serez-vous à la hauteur de ses attentes ..?",
        Coach:"Le côté bourgeois et snob de la Balance sera séduit par la nature aventurière, spontanée et joyeuse du Sagittaire, qui lui fera découvrir de nouvelles expériences amoureuses inconnues jusqu’alors. Mais leur passion ne sera que superficielle et éphémère. "
    } ,
    Sagittaire_Scorpion :{
        Slogan:"« Quoi de plus jouissif que de déceler la faille de l’autre. Relation d’emprise réciproque. »",
        LVA :"C’est une attraction irrésistible entre la sulfureuse et ténébreuse Scorpion et le bienheureux Sagittaire grand jouisseur devant l’éternel. Vous allez vous emballer pour cette amoureuse avide de sensations, mais vous vous rendrez compte bien trop tard que vous êtes tombé dans le piège d'une femme torturée. Mais amoureux et enthousiaste, vous penserez certainement à tort que vous réussirez à apaiser les tourments de votre partenaire. Quant à Madame Scorpion destructrice, elle ne pourra que continuer à distiller son venin dans vos naïves certitudes dans le but ultime de vous posséder. Votre indépendance sera fatale à cette histoire.",
        Plus:"Leur quête du plaisir – Tous deux sont passionnés.",
        Moins:"Le pessimisme du scorpion s’oppose à l’optimisme du sagittaire – Le possessif scorpion ne supporte pas l’indépendance du sagittaire",
        Craquer:"Comment ne pas être subjugué par cette beauté mystérieuse et magnétique, vous ne pourrez résister longtemps à l’appel de ses sens. Vous vous emballerez, fougueux et fasciné,  par cette Messaline qui vous attirera dans ses griffes et vous plongera dans une relation placée sous haute tension sexuelle. Mais prenez garde, vous ne jouez pas sur le même registre. Elle veut vous posséder et vous asservir lentement à ses désirs, votre liberté est en danger et c’est à ce prix que vous devrez sacrifier cet amour tortueux.",
        Coach:"Le Scorpion séduit sa proie en l’hypnotisant par son charme magnétique et notre candide Sagittaire, toujours prêt pour une nouvelle aventure, s’engage naïvement dans une histoire d’amour aux odeurs de souffre, où il risque d’y perdre son âme. Amour torturé et tortueux. "
    } ,
    Sagittaire_Sagittaire :{
        Slogan:"« Rencontre de deux  boulimiques de plaisirs. Ils brûlent la chandelle par les deux bouts.»",
        LVA :"Excellente combinaison pour ces deux joyeux drilles dont le leitmotiv premier  est de vivre une vie sans contraintes, juste pour le fun, juste pour la découverte. Vos vies sont un grand laboratoire de multiples expériences où vous aimez goûter ensemble à toutes les joies et tous les  plaisirs que les rencontres peuvent vous offrir. Vous êtes des boulimiques de la vie et désirez vous nourrir d’aventures exaltantes. Mais malgré votre feeling exceptionnel, vous êtes deux instables adeptes de la liberté dans l’amour et vos vie se séparont pour aller découvrir de nouveaux horizons chacun de votre côté.",
        Plus:"Ils voient la vie de la même façon, libre et sans obligations contraignantes – Ils s ‘intéressent aux mêmes sujets, philosophie, voyages, tous les plaisirs de la chair",
        Moins:"Leur infidélité – Leur inconséquence – A force de jouer et de dépenser sans compter, ils se retrouvent souvent dans des situations financières déplorables.",
        Craquer:"Belle, élancée et racée, cette belle pouliche vous aura séduit par sa prestance et sa sympathie. Agréable, elle aime converser et apprendre à connaître l’autre. Vous vous ressemblez beaucoup et vous sentez que vous vous comprenez sans mots dires. Vous aimerez profiter ensemble de loisirs communs et partez souvent en vadrouille aux quatre coins du monde. Mais est-ce vraiment de l’amour ou une très belle complicité amis-amants ? C’est ce qui risque de vous éloigner, partir vivre ailleurs une vraie passion dévorante même si cela ne dure pas, pourvu que vous ayez l’ivresse des sens.",
        Coach:"Un vrai feeling s’instaure entre eux, l’attraction est spontanée, joyeuse et pétillante, débordante de sensations exaltantes. Une entente fondée sur les plaisirs simples et une sexualité impétueuse, mais qui ne s’inscrit pas automatiquement dans la durée. "
    } ,
    Sagittaire_Capricorne :{
        Slogan:"« Trop d’incompréhensions sur leur vision de la vie. L’insouciance se heurte à la responsabilisation.»",
        LVA :"Vous n'avez rien en commun et vous êtes campés sur deux pôles opposés, une entente durable est fortement compromise. En effet, votre nature joviale et enthousiaste peut séduire Madame Capricorne vous trouvant vivifiant. De votre coté, votre insouciance souvent sujette aux achats compulsifs, sera impressionnée par la prudence et la prévoyance de Madame Capricorne. Mais le coup de cœur passé, vos différences irriteront l’un et l’autre, car vous ne vous comprenez pas. La plupart du temps, le Sagittaire sera le premier à fuir cette relation stérile, pour s’enrichir de nouvelles aventures plus chaleureuses et plus passionnantes.",
        Plus:"La sagesse du capricorne rééquilibre la frivolité du sagittaire - La bonne gestion du capricorne renfloue les caisses vides du dépensier sagittaire – L’optimisme du sagittaire rend le sourire au triste capricorne qui entrevoit la vie sous un nouvel angle.",
        Moins:"Leur nature trop différente, la rigidité du capricorne ne convient pas au libertaire sagittaire – Le sagittaire n’aime pas les règles rigoureuses du capricorne.",
        Craquer:"Sa douceur angélique peut vous attirer. Après tout, ce qui vous motive c’est la découverte de l’autre. Dans ce cas précis, on va parler d’effeuillage avec plusieurs couches protectrices cachant la nature profonde de cette ingénue avec des pièges anti-dragueur parsemés çà et là. Malgré les trésors d’ingéniosité que vous déploierez pour décoincer cette donzelle, vous vous rendrez vite compte que ses principes moraux lui interdisent toutes les libertés que vous vous octroyez. Mais c'est que cette histoire va devenir bougrement ennuyeuse ! Et comme vous ne vous attardez jamais très longtemps sur des situations barbantes vous n’hésiterez pas à oublier son numéro de téléphone.",
        Coach:"Une attraction toute relative, plutôt physique, car leurs caractères sont en totale opposition, l’enthousiasme et l’optimisme du Sagittaire sont gâchés par la retenue et le pessimisme du Capricorne. Agacement réciproque, relation impossible. "
        } ,
    Sagittaire_Verseau :{
        Slogan:"« Deux anticonformistes qui se comprennent et jouissent de la vie ensemble. »",
        LVA :"\"Esprit de liberté\" est le maître mot de ce qui relie votre rencontre. Curieux de la vie et empreints d’idéaux élevés, vous partagerez cet amour pour une vie riche humainement et spirituellement et n’hésiterez pas à vous engager pour une noble cause même hasardeuse sans redouter quelconque risque. Votre histoire ressemble plus à une franche amitié qu’à une dévorante passion, mais votre sincérité et votre admiration réciproque n’en font pas moins une relation d’amour profond qui s’inscrit sur la durée.",
        Plus:"Des idéaux élevés – La jalousie est exclue de cette histoire – Respect mutuel de leur indépendance – Entente intellectuelle – Aucune convention rigoureuse ne les lie. ",
        Moins:"L’infidélité du sagittaire et ses excès en tout genre –La sexualité sans tabous et libertine du verseau.  ",
        Craquer:"Avec elle vous ne vous ennuyez jamais, intelligente et cultivée vous partagez le goût des sujets philosophiques et refaites le monde des heures durant. La passion n’est pas exclue, car sa sexualité libérée vous permet d’explorer ensemble de nouvelles expériences sans jugements aucuns. Aussi indépendante que vous, elle ne vous harcèle pas de questions, ne vous met aucun ultimatum, ne vous encombre d’aucune contrainte, vous vous sentez libre d’aimer, pour vous c’est l’amour idéal.",
        Coach:"Rencontre de deux aventuriers idéalistes unis par une soif commune de liberté et d’anticonformisme. Leur relation est peu banale, basée sur un respect mutuel et sur l’originalité de leur personnalité. Une grande aventure amoureuse extraordinaire. "
    } ,
    Sagittaire_Poissons :{
        Slogan:"« Une relation de liberté et de partage des plaisirs qui ne s’arrêtera que lorsque le rêve disparaîtra. »",
        LVA :"Une attraction instinctive déclenchée par la fusion d’énergies amoureuses et sexuelles quasi cosmiques voir spirituelles. Tous deux, empreints de religion, vous verrez dans votre attirance un signe du divin. Le Sagittaire et le Poissons sont des signes mystiques intéressés par les grands courants philosophiques et religieux et partagent le goût pour les plaisirs simples de la vie dont vous saurez vous enivrer ensemble. Mais très vite, la nature dépressive de Madame Poisson vous éloignera. De son coté, elle ne supportera plus votre exubérance fatigante, elle qui aime tant le silence méditatif et le calme.",
        Plus:"Leur nature jupitérienne, ils aiment les plaisirs de la vie et les voyages – Leur nature neptunienne, ils aiment les hautes aspirations religieuses et philosophiques.",
        Moins:"Leur nature infidèle – Leur vision de l’amour est trop différente – L’un est passif et lymphatique,  l’autre est actif et  hyper dynamique – Attentions aux paradis artificiels, ces deux-là aiment l’alcool et la fête.",
        Craquer:"Cette belle Demoiselle à l’allure aérienne, un peu perdue, ne vous laissera pas insensible. La fragilité qui s’émane d’elle vous donnera envie de la prendre dans vos bras. Offerte et soumise au plaisir de l’autre, vos ébats seront sensuels et intenses. Mais sa nature profondément pessimiste et dépressive deviendra vite pesante et ni  votre enthousiasme ni votre joie de vivre n'y changeront rien. Vous n’aurez d'autre choix que de l’abandonner à son triste sort afin de ne pas sombrer avec elle dans son désespoir.",
        Coach:"Une attraction spontanée et passionnelle pour ces deux hédonistes en quête de sensations et de plaisirs charnels excessifs. Mais leurs différences de sensibilité auront du mal à les accorder à long terme."
    } ,
    Capricorne_Belier : {
        Slogan:"« Difficile de trouver quelconque affinité, lorsque l’un avance l’autre recule ! »",
        LVA :"Difficile de trouver un terrain d’entente entre ces deux signes à cornes . Vous vous efforcez de grimper des montagnes escarpées, alors que  Madame Bélier enfonce les portes. Vous êtes lent et/ou prudent, elle est rapide et fougueuse. vous aurez bien du mal à vous retrouver sur un point de compatibilité. Au niveau sentimental, le fossé se creuse. Vous êtes pudique et introverti, alors Madame Bélier est exhibitionniste et extravertie.  Elle réussira cependant à dévoiler quelques uns de vos fantasmes enfouis.",
        Plus:"La droiture et la franchise – Personnes de principes – Des natures contraires qui peuvent être curieuses de se découvrir.",
        Moins:"Deux personnalités entêtées – Autoritaires et intransigeantes – Peu d’affinités sinon aucune.",
        Craquer:"Vous remarquerez rapidement son manège, une danse de la séduction que vous qualifierez comme une sordide approche de drague.  Elle essaie en vain d’accaparer votre attention, mais vous ne supportez pas les femmes exubérantes et trop voyantes qui frisent la vulgarité. Elle parle trop fort et ses rires à gorge déployée vous dérangent. Il vous faudra avoir avaler quelques coupes de Champagne pour vous laisser aller à une soirée coquine avec elle, mais son approche trop directe vous refroidira instantanément. Non, décidément elle est \"too much\" pour vous. ",
        Coach:"Le Bélier soufflera sur les braises de la passion alors que le Capricorne s'évertuera à les éteindre, trop d'emballements le pétrifient. Le Bélier disparaîtra, dépité, en l'espace d'un éclair laissant le Capricorne, seul face à ses craintes. Relation soufflant le chaud et le froid. "
    } ,
    Capricorne_Taureau :{
        Slogan:"« Relation basée sur la confiance, avec la patience on arrive à tout. »",
        LVA :"Ces deux-là savent œuvrer pour l’avenir, patients et déterminés vous prendrez le temps de vous connaître et construirez les fondations de votre relation sur une confiance mutuelle. Vous vous comprennez et savez exactement ce que l’un attend  de l’autre. La générosité et la chaleur humaine de Madame Taureau ravivera votre flamme et vous mettra en confiance. La base de votre histoire d’amour est le respect de l’autre. Sexuellement, Madame Taureau, plus passionnée, saura vous transcender et vous faire sortir de votre réserve.",
        Plus:"Un respect mutuel les  anime – Leur nature posée les rassure – Le hasard ne fait pas partie de cette relation, tout est réfléchi - La solidité terrienne de la relation.",
        Moins:"La chaleur du taureau ne compensera pas toujours la froideur du capricorne – Les ambitions de l’un peuvent amener la perte de l’autre.",
        Craquer:"Une femme pareille c’est de la bombe ! Sensuelle, attentionnée et véritable fée du logis, elle ne sait que faire pour vous plaire et vous rassure par ses mots d’amour. Sérieuse, elle désire une relation stable sur le long terme comme vous. Bien sûr vous serez emballé par tant d’affinités, si vous ne la trouviez un peu trop matérialiste, car elle s’intéresse de très près à vos opérations boursières, de trop près  même. Votre méfiance naturelle testera cette femme d’argent, pour sonder sa sincérité avant de vous engager plus en avant dans cette relation.",
        Coach:"Fondations solides pour ce couple de terriens qui s’attirent principalement par leur côté pragmatique sécurisant. Le Taureau n’hésite pas à user de tout son charme pour convaincre le Capricorne, ce coriace amoureux.  Relation équilibrée. "
    } ,
    Capricorne_Gemaux : {
        Slogan:"« Trop de lucidité tue le rêve, trop de fantaisie fait perdre le réalisme. Personnalités en déséquilibre. »",
        LVA :"Vous n'avez pas grand-chose en commun. On pourrait même dire que vous êtes diamétralement opposés. Madame Gémeau, légere, versatile et bavarde aura du mal à supporter votre compagnie. Elle vous trouve trop sérieux, réservé, peu communicatif et de surcroit ennuyeux, en tout cas pour elle. Vous, solitaire amoureux du calme, aurez bien du mal à vous encombrer de cette agitée du bocal, qui s’excite pour un oui ou pour un non. Côté sexe, ce n’est pas la joie non plus.",
        Plus:"Seule la créativité du gémeau s’accorderait au pragmatisme du capricorne pour une association professionnelle. ",
        Moins:"L’un sérieux, l’autre fantaisiste et inconscient – L’éternelle jeunesse contre la vieillesse avant l’âge – La liste des dissonances est trop longue à énumérer.",
        Craquer:"Vous échangerez quelques joutes verbales avec elle, histoire de vous échauffer un peu et  voir ce qu’elle a dans le cerveau. Vous serez surpris par sa dextérité oratoire et sa finesse d’esprit, vous la trouverez brillante, intelligente. Pleine d’humour, elle saura même vous voler un sourire, il faut dire que son air mutin vous ravit. Mais très vite, vous comprendrez que cette femme est ingérable, légère et sans consistance, elle virevolte, séduit à tous vents, et s’agace vite à la moindre remarque. Vous recherchez une femme stable, mais elle, c’est l’instabilité personnifiée, à qui l’on ne peut donner sa confiance. Non décidément, vous ne pouvez vous engager dans une relation durable avec elle, tout au plus, passer un moment,  le temps d’une soirée, juste pour le plaisir !",
        Coach:"Rencontre de l’insouciance et de la sagesse, une mayonnaise qui ne prendra probablement pas ou qui ne dépassera pas la période d’essai. Malgré la bouffée d’air frais qui allégera leur relation, le Capricorne s’agacera de tant de frivolités. "
    },
    Capricorne_Cancer :{
        Slogan:"« Des différences qui ne nuiront pas à leur bien-être. La chaleur rassurante de l’amour cimentera la relation. »",
        LVA :"Malgré vos discordances, vous vous complètez parfaitement. Votre nature méfiante, masque sous votre air austère un  manque affectif  qui vous paralyse dans vos histoires de cœur. Grâce à sa sensibilité exacerbée, Madame Cancer devine vos attentes de tendresse et saura vous combler par sa douceur et ses attentions. De son coté, elle se sentira protégée et rassurée par votre honnêteté inébranlable. Cette relation s’inscrit dans la durée, seules vos natures pessimistes peuvent faire des dégâts.",
        Plus:"Ils se sont trouvés et comblent leurs manques respectifs – Ont tous deux besoin d’être rassurés. ",
        Moins:"Une sexualité timide – Le manque de tendresse et de romantisme du capricorne – L’hypersensibilité du cancer.",
        Craquer:"Très féminine et sensuelle, elle incarne la femme avec un grand F. Avec elle, vous vous sentez aimé, elle ne sait que faire pour vous faire plaisir. Toujours aux petits soins et parfaite maîtresse de maison, elle est la femme idéale pour vous accompagner tout au long de votre vie. Seuls ses caprices de petite fille gâtée vous exaspèrent, mais vous savez sévir et la remettre sur le bon chemin, après tout c’est vous qui menez la danse et au final elle aime ça, se soumettre à son homme.",
        Coach:"L’alchimie entre ces deux signes n’est pas toujours évidente. Seule la persévérance du Cancer à percer la cuirasse défensive du Capricorne par de tendres attentions et une sensualité débordante, réussiront à développer un amour profond entre eux. "
    } ,
    Capricorne_Lion :{
        Slogan:"« Les emballements du Lion se heurteront à un mur froid d’indifférence. Dur, dur ! »",
        LVA :"Difficile de trouver un terrain d’entente. Vos personnalités sont diamétralement opposées. C’est la rencontre du feu et de la glace. Madame Lion exubérante, optimiste et théâtrale, adore la provocation et les excès en tout genre, alors que vous n’aspirez qu’au calme, à la solitude et détestez le clinquant, les mondanités, que vous qualifiez de frivolités inutiles. Seules vos ambitions et votre goût du pouvoir pourront vous relier, mais pourront aussi vous confondre.",
        Plus:"L’amour de l’argent et du pouvoir – Le capricorne canalisera les dépenses excessives et superflues du lion – Le lion dégèlera son partenaire capricorne et lui apportera une dose non négligeable d’optimisme.",
        Moins:"Tout les oppose – L’un extraverti l’autre introverti – L’un mondain  l’autre solitaire – L’un dans l’excès et l’autre dans la restriction – La froideur du capricorne désintéresse le passionné lion.",
        Craquer:"C’est une belle femme altière qui pourrait bien vous séduire, elle sait se mettre en valeur et dégage un je ne sais quoi qui pousse à l’admiration. Mais avec ses dehors ultra féminins, elle dégage pourtant une puissance très masculine, autoritaire et intransigeante. Quelque part vous vous ressemblez et désirez tout deux le pouvoir et l’argent. Ce qui pourrait vous unir risque aussi de vous désunir, car qui de vous deux va décider, mener le couple ? Vous bien sûr, mais en avez-vous discuté avec l’intéressée ? Cela ne se discute pas,  cela s’impose. Certes, voilà qui va corser votre histoire, de rapports de force et de conflits de pouvoir, on en connaît l’issue...",
        Coach:"Étant l’un et l’autre en recherche d’une personne au-dessus de la moyenne, ils s’attireront grâce à une admiration réciproque, surtout s'ils ont un statut social élevé, mais la difficulté sera de trouver leur place au sein du couple, le pouvoir ne pouvant être partagé. "
    } ,
    Capricorne_Vierge :{
        Slogan:"« Voilà du solide ! Entente et respect mutuel pour une vie paisible, mais sans surprise. »",
        LVA :"Rencontre de deux signes de Terre stables. Vous recherchez tous les deux un partenaire sérieux et fiable, capable de satisfaire vos exigences amoureuses complexes et les contingences de la vie quotidienne. Les emportements aveugles ne font pas partie de vos personnalités, car  vous avez besoin tous deux d’évaluer minutieusement votre prétendant avant de vous laisser aller aux tourments de l’amour. Un sentiment d’estime et de respect vous rapprochera avant de vaincre vos retenues et de laisser transparaître vos sentiments.",
        Plus:"Ils s’apportent mutuellement des garanties de fiabilité qui les rassurent – Ils sont de bons partenaires pour créer un couple ou monter des affaires.",
        Moins:"Le manque de passion – L’absence de surprises.",
        Craquer:"Cette femme réservée vous intéresse et correspond tout à fait au style de femme que vous recherchez. Point d’excentricités vestimentaires, ni dans ses comportements, ni dans ses propos. Intelligente, on sent qu’elle aime se cultiver, son discours est posé, cartésien. Elle est vraie tout simplement. Attentionnée et prévenante, vous l’imaginez comme la femme idéale pour vous seconder dans la construction de votre foyer. La garantie de stabilité qu’elle vous apportera,  vous encouragera à lui exprimer un amour profond, certes sans débordements, mais sincères.",
        Coach:"Leur aspiration à trouver un partenaire sérieux attireront spontanément ces deux signes de terre l’un vers l’autre. Les joies de l’amour ne se libéreront qu’une fois les exigences du cœur et de la vie pratique satisfaites. Sentiments d’estime et d’amour profond. Cependant, cette relation manquera de Passion. "
    } ,
    Capricorne_Balance :{
        Slogan:"« Une conciliation impossible entre l’oisiveté de l’un et les ambitions de l’autre.  »",
        LVA :"Difficile de comprendre ce qui a pu vous attirer l’un vers l’autre, vous êtes de nature si différente. On ne peut imaginer un couple aussi mal assorti, c’est une des pires configurations astrales. Certainement vous êtes-vous rapprochés à la suite d’une forte déception amoureuse qui aurait affligé vos vies. Madame Balance, désabusée, se sera retournée vers vous pour rechercher une sécurité morale et financière. Quant à vous, la chaleur humaine et la gaieté de cette femme n’auront été là que pour égayer votre morne vie.",
        Plus:"Le capricorne ambitieux procure un niveau social à la hauteur des rêves de la balance – La balance est une belle hôtesse sachant représenter fort honorablement   le rang du capricorne",
        Moins:"La froideur du capricorne glace la chaleureuse balance – Peu de gestes d’affection et peu de démonstration amoureuse, frustre l’amoureuse balance -L’avare capricorne freine les excès dépensiers de la balance, pour des futilités.",
        Craquer:"Comment ne pas être attiré par une telle beauté ? Élégante et vaporeuse, elle ne peut vous laisser indifférent. Sa nature gaie et chaleureuse ne pourra qu’ajouter le petit plus à son charme qui vous fera succomber inexorablement, mais pour combien de temps ? Quelle déception, lorsque vous comprendrez que derrière cette apparence affable, se cache une oisive en quête d’un mécène qui lui permettra de s’adonner sereinement et sans culpabilités à ses activités préférées, le shopping et les mondanités. Vous n'êtes pas de ceux qui se font berner par un si joli minois, vous recherchez de l'authentique et non du superficiel.",
        Coach:"Rencontre de l’insouciance et de l’austérité qui laisse présager une relation amoureuse basée sur l’amour du pouvoir et du décorum où l’émotion et les sentiments sont exclus. Complicité difficile."
    } ,
    Capricorne_Scorpion :{
        Slogan:"« Quoi de plus jouissif que de déceler la faille de l’autre.Relation d’emprise réciproque.»",
        LVA :"Ces deux signes sont les plus résistants et coriaces du zodiaque, tous deux ne rechignent pas à la difficulté bien au contraire, ils sont stimulés par les contraintes et les montagnes escarpées. Vous n'êtes pas insensible au charme ravageur de Madame Scorpion qui est sûre de pouvoir faire faiblir votre cœur de pierre et votre sévère froideur.  Vous vous laisserez volontiers emporter dans une passion folle mais contrôlée. Vous aimez tous les deux le pouvoir et vous n'êtes pas de ceux qui se soumettent. Vous vous respectez mutuellement, reconnaissant vos valeurs de dominant.",
        Plus:"Leur résistance à la souffrance – Leur curiosité intellectuelle – Leur soif de pouvoir –Leur respect mutuel – Des valeurs morales qui leur sont chères.",
        Moins:"Tous deux sont des dominants – Le capricorne manque de passion pour le scorpion – La rivalité dans la quête à la réussite.",
        Craquer:"On ne peut pas dire qu’elle soit insignifiante, bien sûr elle n’est pas le style de femme qui vous attire habituellement, mais elle dégage un tel magnétisme que vous ne pourrez résister longtemps à son appel. Intelligente, elle brille par son esprit vif qui capte immédiatement la subtilité de vos propos. Vous qui êtes sentimentalement réservé, sa passion et ses débordements amoureux vous rassurent sur la solidité de ses sentiments. Elle saura intuitivement comment vous ouvrir et vous permettre de  vous laisser aller à vos émotions. Vous devrez juste la recadrer par moment, car chez elle, tout est dans la démesure.",
        Coach:"Attiré par l’inaccessible, le Scorpion mettra un point d’honneur à faire céder à ses avances pressantes ce Capricorne froid et austère. Tous deux ont le goût du pouvoir et le culte du travail et se respectent pour ces qualités, mais auront du mal, à terme, à se supporter. "
    } ,
    Capricorne_Sagittaire :{
        Slogan:"« Difficile de trouver quelconque affinité, lorsque l’un avance l’autre recule ! »",
        LVA :"Vous n'avez rien en commun et vous êtes campés sur deux pôles opposés, une entente durable est fortement compromise. En effet, la nature joviale et enthousiaste de Madame Sagittaire peut vous séduire, la trouvant vivifiante. Madame Sagittaire, insouciante, souvent sujette aux achats compulsifs, sera impressionnée par votre prudence et votre prévoyance. Mais le coup de cœur passé, vos différences irriteront l’un et l’autre, car vous ne vous comprendrez pas. La plupart du temps, Madame Sagittaire sera la première à fuir cette relation stérile, pour s’enrichir de nouvelles aventures plus chaleureuses et plus passionnantes.",
        Plus:"La sagesse du capricorne rééquilibre la frivolité du sagittaire - La bonne gestion du capricorne renfloue les caisses vides du dépensier sagittaire – L’optimisme du sagittaire rend le sourire au triste capricorne qui entrevoit la vie sous un nouvel angle.",
        Moins:"Leur nature trop différente, la rigidité du capricorne ne convient pas au libertaire sagittaire – Le sagittaire n’aime pas les règles rigoureuses du capricorne.",
        Craquer:"Vous l’aurez certainement rencontré lors d’une soirée mondaine, déambulant de convives en convives, un verre à la main, le sourire aux lèvres, un petit mot agréable pour tous, on la prendrait presque pour la maîtresse de maison, et pourtant elle n’est qu’une invitée comme une autre. Son aisance en société la rend populaire et elle adore ça. Petit bémol, elle sera d’un abord un peu hautain, on pourrait même dire snob. Mais cela ne vous dérange pas, bien au contraire, vous aimez les femmes intelligentes, fines d’esprit et distantes. Cependant, ses propos féministes auront raison de votre intérêt pour elle, les rebelles qui revendiquent haut et fort leur indépendance au sein du couple dénigrant la gente masculine, très peu pour vous.",
        Coach:"Une attraction toute relative, plutôt physique, car leurs caractères sont en totale opposition, l’enthousiasme et l’optimisme du Sagittaire sont gâchés par la retenue et le pessimisme du Capricorne. Agacement réciproque, relation impossible. "
    } ,
    Capricorne_Capricorne :{
        Slogan:"« Deux bourreaux de travail qui devront apprendre à prendre du temps pour s’aimer. »",
        LVA :"Vous avez été créés sur le même moule de rigidité et de valeurs de sérieux. Ambitieux, vous favoriserez pourtant vos carrières à votre foyer et un certain esprit de compétition risque de gâcher cette harmonieuse relation sans heurts, qui ressemble plus à une association qu’à un couple. Mais le modèle fonctionne bien, chacun connaissant exactement sa place et ses contingences et les acceptants. Bien sûr tout ceci manque cruellement de chaleur affective, mais en avez-vous réellement besoin ? ",
        Plus:"Des valeurs de vie commune – La même rigidité et le même sérieux – La Fidélité – La même vision de la vie.",
        Moins:"Le manque de chaleur – La sécheresse affective et sexuelle – Ils ont besoin pour se désinhiber d’un partenaire plus gai et rafraîchissant –La compétition à la réussite.",
        Craquer:"D’une rare beauté, elle vous subjuguera par la classe naturelle, sans artifices qu’elle dégage. Sa réserve accentue son aura de mystère, ce qui vous enchantera, et à la voir ainsi, vous auriez envie de la prendre dans vos bras, elle semble si fragile… Mais derrière ce masque de timidité, se cache un caractère entier et bien trempé qui sait mener de main de maître sa vie, ce qui n’est pas pour vous déplaire non plus. Elle sait ce qu’elle ne veut et ce qu’elle attend d’un homme et ne supporte pas de perdre son temps avec les indécis ou les irresponsables. Pour vous c’est la femme idéale, celle que vous avez toujours espéré.",
        Coach:"Une attirance miroir, pour une histoire sans histoires manquant terriblement de piment. Une relation modérée dans le contrôle où la passion n’a pas sa place. L’ennui les motivera à trouver un partenaire plus fantaisiste. "
        } ,
    Capricorne_Verseau :{
        Slogan:"« Ils auront du mal à s’apprivoiser, leur méfiance sera plus forte que leur curiosité. »",
        LVA :"Bien sûr il peut y avoir attirance spontanée entre vos deux signes qui sont pourtant  incompatibles. En effet, Madame Verseau peut vous séduire grâce à sa nature compatissante et sympathique, surtout si vous êtes dans une de vos périodes de déprime chronique. De son coté, elle appréciera votre sérieux et votre pragmatisme. Mais cette relation ne durera pas très longtemps, orpheline de tout sentiment affectif, votre vision de la vie est bien trop divergente, Madame Verseau aime l’imprévu alors que vous, planificateur, paniquez devant l’inconnu. Que vous vous contentiez d’une relation intellectuelle serait plus réaliste.",
        Plus:"Leur curiosité intellectuelle. ",
        Moins:"Ils manquent de chaleur affective – Le verseau anticonformiste se heurte au conservatisme du capricorne.",
        Craquer:"Cette jeune femme truculente vous surprendra par ses comportements pour le moins imprévisibles. Impulsive et spontanée, elle oublie parfois de mettre les  formes dans ses propos et vous choque par ses discours féministes. Vous aurez l’impression qu’elle n’a besoin de personne et aurez du mal à trouver votre place de mâle. Ses projets amoureux n’intègrent pas la vie de couple trop rébarbative et trop conventionnelle pour elle. Passé le coup de cœur, vous ne persisterez pas dans cette relation que vous jugerez bien trop superficielle et sans intérêts.",
        Coach:"Une relation plus amicale qu’amoureuse, perdue dans le refus de l’un et de l’autre à l'engagement amoureux. Le Capricorne craint de souffrir et se replie sur lui-même donnant avec parcimonie, le Verseau protège sa liberté et brille par son absence. "
    } ,
    Capricorne_Poissons :{
        Slogan:"« Ils  sauront prendre soin l’un de l’autre. Relation pansement.»",
        LVA :"L’union entre un signe de Terre et d’Eau est harmonieuse, mais dès que l’on approfondira vos deux natures fondamentalement différentes, on se rendra vite compte que cette histoire n’est viable que grâce aux prodiges de dévouement de Madame Poisson envers cet homme peu fantaisiste et peu compatissant que vous êtes. Mais Dame Poisson se sentira protégée par vos qualités de sérieux et votre ambition, elle qui est si peu courageuse à la tâche ! Malgrés votre égoïsme et votre insensibilité, elle s’entêtera à sauvegarder cette histoire comme un précieux cadeau du ciel malgré vos reproches blessants.",
        Plus:"L’amour inconditionnel du poisson réchauffe la froideur du capricorne – Le capricorne apporte une sécurité financière au bohème poisson - Le poisson par sa sensuelle générosité désinhibe le capricorne.",
        Moins:"Les sarcasmes, la froideur et le manque de sensibilité du capricorne heurte l’émotif poisson qui le trouve inhumain - Le capricorne se lasse de cet être qu’il trouve trop utopiste et sans volonté.",
        Craquer:"Cette beauté éthérée vous touche par la fragilité qui s’émane de tout son être. Elle semble perdue, une âme égarée en quête de l’amour au sens le plus noble. Elle attend de vous que vous la protégiez, se sentant agressée par le monde extérieur qu’elle ressent si cruel. Dévouée, elle saura se donner corps et âme à votre cause, sans rechigner, attendant juste un geste d’affection de votre part, pour se nourrir de votre amour. Son manque de volonté aura tendance à vous exaspérer, attendant d’elle un peu plus d’entrain. Mais son abnégation vous fera oublier tout grief, laissant la place à votre compassion.",
        Coach:"Une attraction quasi curative, car ils savent combler instinctivement leurs besoins affectifs. Le Capricorne sécurise le Poisson et calme ses craintes, qui en retour lui prodiguera une tendresse et un dévouement sans bornes qui adoucira la rigidité du Capricorne. "
    } ,
    Verseau_Belier : {
        Slogan:"« Un goût commun pour la découverte de sensations insolites. Ils se comprennent.»",
        LVA :"Votre amour commun de liberté peut vous permettre de construire un couple sur une base de complicité et d'union libre. Vous êtes original et curieux et vous vous laisserez volontiers emporter dans la folle passion de Madame Bélier. Elle appréciera que vous ne l’étouffiez pas et que vous fermiez les yeux sur ses coups de canif dans le contrat. Vous partagerez des loisirs, des amitiés si chères à votre équilibre de vie. Votre relation sera surtout basée sur de la tolérance et sur l’espace que l’un concédera à l’autre.",
        Plus:"Le respect de la liberté de chacun- Une sexualité basée sur la découverte de nouvelles expériences –La passion.",
        Moins:"La distance du verseau qui peut sembler de l’indifférence – Les excès de colère du bélier- Ils ont tous deux des natures très insouciantes avec l’argent.",
        Craquer:"Tant que l'approche ne sera que dans la séduction et que vous vous  titillerez, exacerbant l'excitation afin de déclencher l'étincelle qui va sonner l'ouverture des jeux de l'amour, tout ira bien... Elle vous emportera dans une folle passion à la fois sentimentale et sexuelle, le Nirvana ! Mais tôt fait qu'elle empiètera sur votre espace vital, les hostilités commenceront. Car elle est comme ça, elle grignote jour après jour un peu plus, telle une boulimique de l'amour, elle en veut toujours plus. Se mettant dans des rages folles si vous ne satisfaites pas ses moindres désirs. Mais vous n'êtes ni conflictuel, ni soumis à la volonté de Madame, il faudra bien qu'elle s'y fasse ou partir.",
        Coach:"Ils ne craindront pas de s'évader  ensemble dans une folle aventure, aussi longtemps que l'excitation demeurera. Ils partageront le goût de la liberté et de l'insolite ainsi que l'amour sans tabous. Relation fusionnelle dans la compréhension mutuelle. "
    } ,
    Verseau_Taureau :{
        Slogan:"« Dialogue difficile entre ces deux idéalistes divergents. Conservatisme contre démocratie.»",
        LVA :"Vous aimez par-dessus tout votre liberté et ne supportez pas de vous  justifier ni rendre des comptes. Madame Taureau ne supporte pas ce qui lui échappe et a besoin d’être rassuré en permanence. Vous comprendrez donc que vous aurez bien du mal à trouver un terrain d’entente sur le long terme. De plus, ses scènes de jalousie vous feront fuir, car vous ne supportez pas les éclats inutiles. Un point de divergence encore, vous êtes plutôt intellectuel, alors qu'elle est assez basique.",
        Plus:"La fidélité du taureau séduit le verseau qui lui n’est fidèle qu’à lui-même – Le taureau amène une stabilité financière au dépensier verseau.",
        Moins:"L’indépendance du verseau déstabilise le taureau qui rentre dans des rages folles – L’un est solitaire, l’autre a peur de la solitude.",
        Craquer:"L'attraction physique est souvent le révélateur d'une relation... Lorsque vous la croiserez, vous ne pourrez que succomber à ses courbes voluptueuses, elle est si féminine, appétissante et enjôleuse que vous ferez preuve de faiblesse, cédant à la tentation de la chair. De plus, attentive à votre bien-être, vous serez comblé de petites attentions qui  ne feront que s'ajouter à son charme naturelle. Mais ce dévouement a un prix, qui  refroidira totalement votre engouement des premiers instants, véritable pot de colle, elle exigera de vous une présence permanente auprès d'elle, ne supportant pas votre nature solitaire. N'obtenant pas satisfaction, elle rentrera dans des colères noires qui auront raison de votre patience et de votre penchant.",
        Coach:"L'attraction est éphémère, mais avec un désir d’explorer un univers inconnu, car le Taureau se méfie du style libéré du Verseau, qu’il interprète mal et qui exacerbe sa jalousie, étouffant ainsi le Verseau. Une relation très chaotique, un défi impossible. "
    } ,
    Verseau_Gemeaux : {
        Slogan:"« Personnalités atypiques qui s’imbriquent comme des pièces d’un puzzle.»",
        LVA :"Que de belles perspectives d’avenir pour ce couple, chacun trouvant une stimulation d’idées novatrices chez l’autre, se faisant progresser et sublimant leur créativité. Tous deux signes d’air et donc cérébraux, vous avez en commun la recherche de la complicité intellectuelle. Seul hic, une harmonie très importante au niveau de l’esprit, mais un gros bémol au niveau du physique ce qui vous éloignera à terme. Une passion qui deviendra vite une complicité amicale platonique si vous ni mettez pas plus de motivation.",
        Plus:"Une attirance et une osmose intellectuelle – La fusion des idées – Un lien fraternel et complice.",
        Moins:" Le gémeau est un communiquant loquace alors que  le verseau a besoin de moments à lui, de recueillements – Le sexe est ensommeillé – Relation trop amicale.",
        Craquer:"Que dire de cette jeune femme pétillante au tempérament de feu, elle vous transporte de sa bonne humeur, de son charme, jouant avec les mots avec humour et intelligence. Vous êtes tout simplement séduit par cette intellectuelle avec laquelle vous pourrez échanger des heures durant sur n'importe quel sujet. Curieuse, elle peut aborder aussi bien des faits politiques qu'argumenter sur une thèse philosophique. Vous avez trouvé votre alter ego, celle qui vous grisera par l'esprit. Mais qu'en est-il du physique ? Vous saurez réveiller vos sens par une oralité sensuelle et partagerez le goût pour l'imprévu. L'ennui n'est pas prévu au programme des réjouissances, même si parfois elle peut vous assommer de paroles.",
        Coach:"Une attraction instinctive lie ses deux esprits libres, brillants et originaux. Ils échangeront leurs idées et vivront une histoire d’amour atypique sans tabous où l’imprévu les préservera de la routine.\""
    },
    Verseau_Cancer :{
        Slogan:"« Lorsque la passé s’affronte au futur, difficile de trouver un terrain d’entente.»",
        LVA :"Vos recherches amoureuses sont bien trop différentes pour que l’amour s’installe entre vous. Madame Cancer est une incurable romantique et désespère devant votre indifférence qui fuit le sentimentalisme \"marshmallow\". Intellectuel, cérébralisant vos sentiments, vous avez du mal à les verbaliser, alors qu'elle, très émotionnelle, a besoin de s'épancher sur le sujet, ce qui ne tardera pas à vous étouffer. Bien sûr son magnétisme peut vous séduire, mais pour si peu de temps...",
        Plus:"Le besoin de se rendre utile – L’humanisme.",
        Moins:"L’un est passéiste et l’autre avant-gardiste, l’un romantique l’autre réaliste – Tout les sépare.",
        Craquer:"Vous qui êtes plutôt réservé sur les questions amoureuses, sa spontanéité vous touche. Rencontrer une femme obsédée par l'amour ne pouvait pas vous laisser indifférent, mais plutôt dubitatif, voir même curieux. Vous faire dorloter, sentir autant d'intérêt dans les yeux de votre partenaire, ne peut que satisfaire votre égo... déjà bien surdimensionné. Mais devoir répondre à cette boulimique d'amour, par une présence inconditionnelle et par la même démonstration affective, c'est trop vous demander. Vous êtes bien trop attaché à votre liberté et n'adhérez pas aux mièvreries et autres étalages de sentiments.",
        Coach:"Improbable compatibilité pour ces deux signes, dont les orientations de vie divergent à 180°, le Cancer est passéiste alors que le Verseau avant-gardiste. Seule leur fantaisie érotique peut à un moment donné les rapprocher, mais pour si peu de temps… "
    } ,
    Verseau_Lion :{
        Slogan:"« Une complicité évidente, tous deux aiment les êtres intelligents et brillants.»",
        LVA :"Entre vous, c’est la loi de l’attraction et de la répulsion , c’est parfois la dure règle pour les signes astrologiques opposés qui peuvent vivre leur histoire, soit dans la complémentarité, soit dans l’antagonisme. Dans tous les cas, vous ne vivrez pas une relation édulcorée, ni banale. Placée sous le signe de l’impétuosité, vous partagerez le goût pour le monde, les relations amicales et les échanges animés. Mais vos divergences de caractères froisseront l’orgueil de la possessive Madame Lion qui aura bien du mal à vous attacher et vous détourner de votre nature libertine.",
        Plus:"Leur esprit est brillant et tous deux aiment se cultiver et polémiquer- La passion amoureuse - Ils ont une vie sociale bien remplie.",
        Moins:"Ils sont très individualistes voir égoïstes – Le lion ne supporte pas l’indépendance du verseau – Le verseau exècre l’autoritarisme du lion.",
        Craquer:"Une réelle attraction va se produire entre vous. Vous serez séduit par cette lumineuse et  jolie jeune femme. Elégante et extravertie, elle sait se mettre en valeur, ce qui n'est pas pour vous déplaire, vous qui êtes plutôt réservé dans les apparences, vous aimez être flatté par une ravissante compagne. Une véritable passion peut réellement s'installer entre vous avec quelques heurts sur vos différences fondamentales d'aborder l'amour. Vous, si indépendant, aurez du mal à supporter l'autorité et les exigences de Madame, et votre froideur vexera cette orgueilleuse qui a besoin de se sentir admirée et qui attend des  témoignages d'amour avant de se lancer dans l'aventure.",
        Coach:"Une attraction physique irrésistible motivée par une fascination réciproque. Une passion aveugle qui se soldera par un échec cuisant en raison de leurs caractères diamétralement contradictoires et un manque de souplesse. Attirance-Répulsion."
    } ,
    Verseau_Vierge :{
        Slogan:"« Absence rime parfois avec indifférence. Difficile de créer une connivence avec un tel handicap. »",
        LVA :"Vous formerez un couple tout à fait improbable. L’alliance d'un Verseau rebelle et anticonformiste et d’une Vierge méthodique et conventionnelle ne pourra que se confronter  à une incompréhension de sourd et muet. Vous trouverez cependant un terrain de jeu sur des sujets intellectuels qui stimulera une belle amitié amoureuse, mais les manies de Madame Vierge à tout prévoir et son extrême prudence s'opposant à votre insouciance et à votre désinvolture, auront raison de votre patience et du peu d’intérêt que vous vous accordez.",
        Plus:"L’engouement intellectuel – Le verseau admire l’intelligence de la vierge et la vierge est subjuguée par ce bel orateur.",
        Moins:"Les absences du verseau blessent la sensibilité vierge qui attend de l’attention de son partenaire – Conformisme s’affronte à l’anticonformisme. Le verseau est un rebelle alors que la vierge est très disciplinée.",
        Craquer:" Femme à la beauté froide, dégageant une aura quasi mystique, telle une Madonne, elle saura vous attacher par son dévouement inconditionnel. Comme vous, elle a peur de l'engagement et ne rechignera pas à garder un brin d'indépendance, car l'amour lui fait peur. Beaucoup de points communs vous rapprocheront mais beaucoup de divergences vous éloigneront aussi. Vous pourrez installer une relation basée sur une complicité amicale et intellectuelle, mais ses manies de vieilles filles et ses remarques acerbes vous irriteront, vous qui ne supportez pas la critique. Malgré sa fidélité sans failles, vous n'aurez aucun scrupule à chercher du piment avec d'autres conquêtes, au risque de la blesser et de la perdre.",
        Coach:"Une association tout à fait improbable. Seule une curiosité intellectuelle pimentera leurs échanges et pourra donner une illusion d’amour, mais leur différence de fonctionnement créera rapidement des tensions irrémédiables. "
    } ,
    Verseau_Balance :{
        Slogan:"« Vous savez vous apprécier à votre juste valeur sans jugement aucun.»",
        LVA :"Votre relation sera très harmonieuse, car elle alliera l’ingéniosité et l’originalité du Verseau à l’influence Vénusienne de la Balance. Votre attraction ressemble à une amitié-amoureuse basée sur la curiosité et le contact humain. Vous vous harmoniserez dans les domaines de la musique et des arts, vous entourant d’objets rares. Sociable et conciliante , Madame Balance acceptera vos excentricités sans jugements et saura être une hôte accueillante pour vos nombreux ami(e)s.",
        Plus:"Leur nature aérienne  - Le plaisir de la rencontre et le goût de la vie sociale – L’amour de l’art et des antiquités –L’acceptation de leur légèreté amoureuse.",
        Moins:"Le verseau manque de chaleur amoureuse et d’attentions – La solitude du verseau – La frivolité de la balance et ses extravagances financières.",
        Craquer:"Comment ne pas être envoûté par cette nature ultra-féminine, maîtrisant parfaitement l'art de la séduction. Vous trouverez en elle la partenaire idéale, alliant beauté, délicatesse, finesse d'esprit. Vous êtes fait l'un pour l'autre, tolérante, elle respecte votre soif de liberté et sait allier compromis et harmonie, afin de maintenir au quotidien,  une vie sereine au sein du couple. Cependant, vous devrez faire quelques efforts de tendresse afin que cette perle rare ne jette pas l'éponge par manque affectif. Exigeante amoureusement, elle ne pourra se satisfaire d'une amitié amoureuse sans quelques élans passionnels de votre part.",
        Coach:"Une attraction intellectuelle et spirituelle va motiver cette rencontre hors du commun. L’élégance de la Balance va subjuguer notre rebelle Verseau qui brillera par ses éclairs de génie. Harmonie des âmes et spiritualisation de l’amour. "
    } ,
    Verseau_Scorpion :{
        Slogan:"« Relation de curiosité parfois malsaine. Aucun des deux ne cède réellement à l’autre.»",
        LVA :"Pour ces deux rebelles, la relation ne sera pas en demi-teinte, ni de tout repos, ce sera tout ou rien, car vous n'avez pour règles que celles que vous dictez.  Votre devise commune « ni Dieu ni Maître » tout un programme. Vous passerez très facilement de l’amour passionné à la haine, à cause de vos natures obstinées et votre goût pour les histoires compliquées. La routine et les relations insipides, très peu pour vous. Vous avez besoin de vous défier dans un duel où les limites seront  toujours repoussées jusqu’à ce que l’un des deux obtempère. Cela risque d’être cinglant ! ",
        Plus:"Leur besoin d’originalité – Leur atypisme – Leur esprit éveillé –La sexualité sans tabous.",
        Moins:"La possessivité du scorpion et l’ultra-indépendance du verseau – Les colères du scorpion font fuir le verseau qui est anti-conflits.",
        Craquer:"L'attirance est instinctive, car l'un attise la curiosité de l'autre. Un halo de mystère s'émane de cette beauté énigmatique et passionnée car elle n'hésitera pas à vous faire part de son grand intérêt pour vous. Maligne, elle saura vous flatter car elle sera bien décidée à parvenir à ses fins. Vous vous laisserez emporter volontiers, dans son tourbillon amoureux, décidé à goûter à de nouveaux fruits interdits. Mais très vite cette dévoreuse d'hommes va vous lasser de ses excès de jalousies et de possessivité, voulant vous priver de votre liberté chérie. Son agressivité finira par vous faire fuir, et achèvera cette idylle torturée.",
        Coach:"Une relation sous haute tension où ces deux rebelles se défieront dans un combat sans merci, alternant passion et haine. Leur goût prononcé pour les histoires compliquées expliquera leur persévérance dans cette relation sans issue. "
    } ,
    Verseau_Sagittaire :{
        Slogan:"« Deux anticonformistes qui se comprennent et jouissent de la vie ensemble.»",
        LVA :"\"Esprit de liberté\" tels sont les maîtres mots de ce qui relie votre rencontre. Curieux de la vie et empreints d’idéaux élevés, vous partagerez cet amour pour une vie riche humainement et spirituellement et n’hésiterez pas à vous engager pour une noble cause même hasardeuse sans redouter quelconque risque. Votre histoire ressemble plus à une franche amitié qu’à une dévorante passion, mais votre sincérité et votre admiration réciproque n’en font pas moins une relation d’amour profond qui s’inscrit sur la durée. ",
        Plus:"Des idéaux élevés – La jalousie est exclue de cette histoire – Respect mutuel de leur indépendance – Entente intellectuelle – Aucune convention rigoureuse ne les lie.",
        Moins:"L’infidélité du sagittaire et ses excès en tout genre –La sexualité sans tabous et libertine du verseau.",
        Craquer:"Avec elle, vous vous embarquez pour une grande aventure originale sur un rythme effréné, en quête de savoir et de découverte. Vous partagez le goût de la lecture et de l’évasion intellectuelle et saurez rendre agréable vos moments d'intimité. Toujours prêts pour une virée à l’autre bout du monde, l’ennui ne fait pas partie de votre histoire, vous vous amusez, vivez pleinement et sans à priori votre vie sentimentale et sexuelle. Elle, saura pimenter votre vie parfois d’ermite et vous, vous l’emmènerez dans votre monde du libertinage, car avec elle, tant qu’il y a du plaisir il y a l’ivresse, et elle est toujours partante pour explorer de nouvelles expériences. Vous avez trouvé votre alter ego.",
        Coach:"Rencontre de deux aventuriers idéalistes unis par une soif commune de liberté et d’anticonformisme. Leur relation est peu banale, basée sur un respect mutuel et sur l’originalité de leur personnalité. Une grande aventure amoureuse extraordinaire. "
    } ,
    Verseau_Capricorne :{
        Slogan:"« Ils auront du mal à s’apprivoiser, leur méfiance sera plus forte que leur curiosité.»",
        LVA :"Bien sûr il peut y avoir attirance spontanée entre vos deux signes qui sont pourtant  incompatibles. En effet, elle pourrez être séduite par votre nature compatissante et sympathique, surtout si elle se trouve dans une de ses périodes de déprime chronique. De votre coté, vous apprécierez son sérieux et son pragmatisme. Mais cette relation ne durera pas très longtemps, orpheline de tout sentiment affectif, votre vision de la vie est bien trop divergente, vous aimez l’imprévu, alors que Madame Capricorne, planificatrice, panique devant l’inconnu. Que vous vous contentiez d’une relation intellectuelle serait plus réaliste.",
        Plus:"Leur curiosité intellectuelle. ",
        Moins:"Ils manquent de chaleur affective – Le verseau anticonformiste se heurte au conservatisme du capricorne. ",
        Craquer:"Sa réserve peut vous séduire car vous n'aimez pas que l'on empiète sur le terrain de votre popularité, mais n'ayez crainte, les feux de la rampe ne sont pas pour elle, car elle préfère la discrétion à la surexposition. Elégante et brillante, vous serez fier de vous pavaner à son bras, mais les longs silences qui meublent votre quotidien, auront raison de cette histoire sans piment. Sa rigueur vous ennuiera et vous fuirez cette bourgeoise étriquée trop traditionnaliste pour le réac que vous êtes. Vous vous oublierez, occultant la présence de l'autre, sans heurts et sans reproches chacun reprenant le cours de son existence comme si de rien n'était.",
        Coach:"Une relation plus amicale qu’amoureuse, perdue dans le refus de l’un et de l’autre à l'engagement amoureux. Le Capricorne craint de souffrir et se replie sur lui-même donnant avec parcimonie, le Verseau protège sa liberté et brille par son absence. "
        } ,
    Verseau_Verseau :{
        Slogan:"« Ils regarderont dans la même direction, main dans la main. Amitié amoureuse !»",
        LVA :"La compatibilité des signes identiques pêchent la plupart du temps à cause de leur trop grande similitude et engendrent souvent un ennui mortel pour le couple. Mais pas pour les Verseaux qui s’entendent à merveilles et profitent non seulement de leur soif de liberté et du respect mutuel que vous vous accordez, mais également par votre originalité qui donne un piment particulier à votre union. Vous vous enthousiasmez pour les mêmes quêtes et partagez de longues soirées d’hiver à échanger sur divers sujets qui vos passionnent. Le sexe est vécu légèrement et avec désinvolture. L’ennui ne fait pas partie de votre quotidien.",
        Plus:"Leur vision particulière de la vie – Les missions humanitaires qui donne un sens à leur vie – Le respect de leur liberté individuelle.",
        Moins:"L’infidélité – Leur besoin de solitude n’est pas obligatoirement synchronisé.",
        Craquer:"Attiré par l'exceptionnel,  vous ne pourrez qu'être  séduit par cette femme qui se veut anti-conventionnelle et originale. Comme vous, elle déteste l'ordinaire et se refuse à se plier aux règles de la société, car elle aspire à une vie à l'idéal élevé. Vous vous entendrez à merveille, partageant les mêmes courants d'idées, préconisant  un amour libre sans obligations contraignantes ni sentiments excessifs comme la jalousie et la possessivité. Elle vous fera vivre une  existence simple et  heureuse  rythmée par une vie sociale et amicale riche et chaleureuse. Vous aurez trouvé la compagne rêvée.",
        Coach:"Une complicité à toute épreuve, dans une vision identique de la vie et de l’amour. Une relation qui sort des sentiers battus dans le respect de la liberté de chacun et dans une tolérance stimulée par leurs idées avant-gardiste et révolutionnaire. Parfaite symbiose. "
    } ,
    Verseau_Poissons :{
        Slogan:"« Relation avec un esprit ouvert et tolérant en respect de la liberté de chacun et de ses envies.»",
        LVA :"L’entente dépendra de l’évolution psychologique de ces deux personnages un peu perchés, mais dans deux mondes très différents. Vous, hautement intellectuel, survolez ce monde médiocre auquel vous recherchez des solutions philosophiques, vous prenant un peu pour \"Dieu le père\". Madame Poisson, quant à elle,déambule dans un monde émotionnel et mystique à la recherche de Dieu. Relation floue et utopiste, fondée sur un échange de sensations éphémères, mais intenses, que la triste réalité de la vie dissoudra rapidement. ",
        Plus:"Relation mystique et barrée - Leur humanisme",
        Moins:"Leur irréalisme – L’un se prend pour Dieu et l’autre l’invoque – La froideur du verseau déstabilise l’affectueux poisson.",
        Craquer:"Quelle est la personne qui ne rêverait pas d'être adulé surtout lorsqu'on est comme vous, un brin narcissique ?  Elle est une admiratrice aux petits soins, dévouée corps et âme, ne sachant que faire pour vous plaire, docile et patiente, supportant vos réflexions désobligeantes et vos sautes d'humeur, mais toujours là pour vos moindres désirs. Vous êtes tenté, mais vous trouvez quelle n'a pas assez de caractère, qu'elle est trop sentimentale et les mièvreries ça vous agace. Vous aimeriez qu'elle vous tienne tête, qu'elle vous résiste et qu'elle s'affirme un peu plus. Mais vous ne pouvez pas tout avoir, il va falloir choisir : soumise ou rebelle. Alors pourquoi la faire souffrir ? elle est née pour l'amour.",
        Coach:"Une relation romanesque connectée à un univers magique et utopique. Le détachement du Verseau et son manque de tendresse frustrera le Poisson qui ne se nourrit que d’émotions et d’amour. Les limites du rêve amoureux. "
    } ,
    Poissons_Belier : {
        Slogan:"“ Vous vous effleurez sans jamais vraiment vous toucher. Relation de méfiance.”",
        LVA :"Deux univers incompatibles. Vous êtes rêveur, romantique et Madame Bélier trouve que tout ce sentimentalisme n’est que  niaiserie. Elle est très susceptible et vous êtes hypersensible, les rapports seront souvent difficiles,  dans l'incompréhension de ce que l'un attend de l'autre. Madame Bélier belliqueuse, est cependant apaisée par votre nature calme, mais qui l’agace fortement aussi. Vous, vous serez fasciné par cette nature volcanique, mais vous souffrirez de son désintérêt.",
        Plus:"Le poisson désirant plaire absolument au bélier se pliera à tous ses caprices - Relation où chacun trouvera sa place laissant l’autorité au bélier.",
        Moins:"Le poisson  est un romantique né, le bélier exècre l’amour gnangnan – Le bélier entreprend et le poisson attend, quoi ? Il ne le sait même pas lui-même.",
        Craquer:"Vous serez fasciné par cette amazone, dynamique et volontaire qui s'enflammera à votre première rencontre. Elle vous veut et saura vous le faire savoir, merveilleux !  Vous adorez les femmes entreprenantes. Elle vous couvrira d'attentions, vous submergera de sms, vous parlant d'amour sans pudeur. Toujours partante pour n'importe qu'elle aventure, vous ne vous ennuierez pas une minute avec elle.  Boulimique de sexe,  elle ne refusera jamais vos assauts, et en redemandera encore et encore.  Tout serait parfait, mais sa nature volcanique et exigeante s'évertuera à gâcher cette belle complicité. Jalouse et autoritaire, elle vous provoquera à coups de réflexions blessantes et se détournera de vous sans crier gare dans une indifférence brutale si vous ne répondez pas à ses attentes.",
        Coach:"Le Bélier ne cherche qu'à vivre intensément sa passion alors que le Poisson, insidieusement, cherche à l'attacher.  Mais attention cher Bélier, ne l'approchez pas de trop près car , Telle Médusa, le Poisson pourrait bien vous envoûter, mais juste le temps d’un instant.  Relation de méfiance. "
    } ,
    Poissons_Taureau :{
        Slogan:" Relation de tendresse ou chacun rassure l’autre de ses sentiments.”",
        LVA :"Douce alchimiste entre vos deux signes.  Vous partagez un goût prononcé pour les plaisirs de la chair et de la table, le romantisme au coin du feu, la musique, le cinéma. D’un point de vue sexuel, la magie opère là aussi. Mais cette relation ne sera pas sans faille, Madame Taureau pragmatique et terre à terre, s’irritera assez rapidement contre vos rêveries utopistes et votre indécision. Vous aurez du mal à comprendre toute cette surexcitation.",
        Plus:"Affinités de goûts – Envie d’amour partagé – Besoin d ‘attentions et de tendresse – Sexualité épanouie.",
        Moins:"Le taureau est carré, terre à terre, le  poisson est utopiste, il fuit la réalité – Le poisson se contente de peu alors que le taureau a besoin de son confort.",
        Craquer:"Comment pouvoir lui résister, cette Vénusienne voluptueuse et charnelle vous met tout en émoi et réveille tous vos sens. Comme vous, elle est épicurienne et sait s'occuper de son homme, attentive à son confort et son bien-être, presque collante. Mais que demander de plus, vous qui n'aspirez qu'à un amour total et fusionnel, vous êtes aux anges, dorloté et aimé à l'excès. Mais attention, elle attendra de vous un peu plus de clarté, d'engagement et une fidélité à toute épreuve, en êtes-vous capable ? Car elle est intransigeante et exigeante, ce qui peut vous rebuter, car votre nature rêveuse fuie les contraintes et se refuse aux rapports de force.",
        Coach:"Une attraction sensuelle qui sera le détonateur d’un embrasement amoureux pour ces deux hédonistes prêts à expérimenter une vaste palette de plaisirs. Le seul bémol, le pragmatisme du Taureau aura du mal à comprendre les rêveries du Poisson."
    } ,
    Poissons_Gemeaux : {
        Slogan:"“ L’agitation de l’un perturbe la quiétude de l’autre.  Incompréhension totale.”",
        LVA :"Une incompréhension totale entre ces deux êtres, qui vont jouer au chat et à la souris,  se tourner autour, se chercher sans réellement se trouver. Leur relation pourrait bien rester à l’état du flirt sans jamais réussir à se concrétiser ou au mieux se transformer en relation purement érotique. Il faut dire qu’une bonne dose d’immaturité jette le trouble dans ces  deux personnalités que tout oppose. Attraction et répulsion s’alternent car leur vision de l’amour est bien trop différente.",
        Plus:"Le flirt -L’illusion d’amour – 	La rêverie amoureuse  - Une sensualité envoûtante.",
        Moins:"Le rêve ne se transformera pas en réalité – Le gémeau a du mal à comprendre le romantisme mièvre du poisson – Le poisson souffre dans cette relation.",
        Craquer:"Sa nature gaie et enjouée vous aura certainement séduit, car votre nature mélancolique a besoin d'un boosteur pour retrouver sa joie de vivre. Elle vous emportera dans ses délires loufoques et vous fera rire de son humour potache. Vous pourrez partager ensemble des moments par ci par là, de pur bonheur infantile, mais rien de bien sérieux, car même si vous n'aimez pas vous engager sur du long terme malgré votre besoin maladif d'amour, avec elle, ce sera d'autant plus dur. Elle virevolte, s'amuse, butine, mais ne se pose pas. Lassé par son trop plein d'énergie, vous préfèrerez retrouver le calme de vos méditations et la laisser à ses monologues éreintants. Vous n'aurez au final pas grand-chose à vous dire.",
        Coach:"Relation extrêmement fluctuante et capricieuse, où ces natures très différentes se heurtent dans une incompréhension réciproque. Le Gémeau est très indifférent aux états d’âme du Poisson qui ne comprend pas cette méfiance à son égard."
    },
    Poissons_Cancer :{
        Slogan:"“ Ils ondulent dans un monde fantasmagorique proche de l’extase.”",
        LVA :"Une attraction magnétique et sensorielle relie ces deux signes d’eau.  Intuitifs et affectueux, vous êtes sur la même longueur d’onde. Votre union sera basée sur un fort besoin de sécurité affective et sur une sexualité imaginative, avec une soif de sensations et d’abandon de soi. L’amour sera au centre de cette alchimie. Mais Madame Cancer sera toujours à l’affût des vos réelles motivations, car vous fuyez et refusez de vous dévoiler totalement. Ce sera la seule ombre au tableau de cette fusion charnelle.",
        Plus:"Une alchimie des énergies – 	Un amour romantique dans la sublimation des sens et des énergies.",
        Moins:"Le manque de réalisme – La passivité – Ils vivent d’amour et d’eau fraîche.",
        Craquer:"Lorsque vous croiserez son chemin, vous ne pourrez résister à son ultra féminité, une véritable sirène qui va affoler vos antennes sensorielles. Cette tentatrice aura raison de vos doutes et vous vous jetterez sans réfléchir dans cette fusion des âmes et des corps. Votre relation sera basée sur l'alchimie de vos émotions et la sensualité de vos corps. Mais attention,  Mademoiselle est capricieuse et exigeante, et risque de vous faire tourner en bourrique, car elle a sans cesse besoin d'être rassurée sur vos intentions et vos sentiments. Alors que vous, vous vivez dans le plaisir de l'instant présent, elle ne peut s'épanouir que si elle se projette dans une construction d'avenir. Cela risque de corser vos relations.",
        Coach:"Des émotions exaltantes sont à l’origine de cette rencontre magique des âmes. Relation douce et poétique où les plaisirs et l’appel des sens jouent un rôle important dans la symbiose de cette union du corps et de l’esprit. Extase amoureuse guidée par leur sixième sens. "
    } ,
    Poissons_Lion :{
        Slogan:"“ Un idéal amoureux qui aurait pu s’accorder si leurs ambitions n’étaient pas si divergentes.”",
        LVA :"Entre vous, difficile de trouver une alchimie. Vous évoluez dans des mondes parallèles où l’incompréhension rendra impossible toute relation harmonieuse. Bien sûr il peut y avoir un coup de foudre, mais Madame Lion, si  impétueuse, franche et directe, sera déconcertée par votre coté doux rêveur et insaisissable.  Même si vous ne contesterez jamais son autorité, vos non-dits et vos faux fuyants la mettront mal à l’aise dans la confiance qu'elle pourrez vous accorder.",
        Plus:" Le poisson soumis ne sait que faire pour plaire au lion majestueux et autoritaire - Le lion apprécie l’amour inconditionnel que lui porte le poisson.",
        Moins:"La passivité et l’abnégation du poisson – Les colères agressives du lion et ses paroles blessantes.",
        Craquer:"Vous ne pourrez qu'être fasciné par la beauté de cette diva sensuelle et théâtrale qui déambule devant vous d'un air provocateur et hautain. Mystérieuse, elle aiguise votre curiosité et vous aimeriez bien approfondir votre attirance dans l'intimité. Elle vous inspire tant de scénarii coquins que vous saurez  la faire succomber à votre charme romanesque par des tirades enflammées. Votre idylle sera passionnément ardente. Mais Madame Lion est castratrice et dominatrice et malgré votre docilité, vous aurez du mal à vous soumettre à sa dureté et à ses exigences. Blessé, vous vous recroquevillerez dans une profonde dépression morale qui la fera fuir à grandes enjambées.",
        Coach:"Une attraction romanesque et sensuelle qui ne durera que le temps d’une étincelle. Une alchimie difficile pour ces deux personnalités évoluant dans des univers très différents. La nébulosité du Poisson met le Lion dans des rages folles. "
    } ,
    Poissons_Vierge :{
        Slogan:"“ Une relation timide mais où l’on s’apporte mutuellement du bonheur.”",
        LVA :"Rencontre de deux signes opposés par vos natures ambivalentes et complexes,  prometteuse d’une relation fascinante ou d’un fiasco complet. Vos mondes sont totalement différents, Madame Vierge a les deux pieds bien ancrés dans la terre et refuse de croire à tout ce qui échappe à sa compréhension analytique, alors que vous naviguez dans un monde irrationnel, transporté par le flux de vos émotions. Cependant, Madame Vierge, en quête d’amour absolu, pourrez être ensorcelée par vos mots doux, et perdre tout contrôle sur sa vie, envoutée par votre irrestitible charme.",
        Plus:"La douceur du poisson attendrit la vierge méfiante – Le poisson met du rêve dans la vie trop pratique de la vierge – La vierge apporte son réalisme pragmatique au rêveur poisson.",
        Moins:"Le poisson est parfois menteur et infidèle car il a un cœur d’artichaut – La froideur et les sarcasmes de la vierge blessent le sensible poisson – La désillusion.",
        Craquer:"Son allure angélique vous a subjugué. Il est vrai qu'elle ressemble à une Madone et dégage une aura de bonté qui ne vous a pas échappé. Votre attirance pour le mystique sera comblée par cette virginale beauté. Mais passée la contemplation biblique, vous n'aurez de cesse d'essayer de réchauffer par vos déclarations enflammées, cette frigide donzelle. Votre intuition ne vous trompe jamais et vous sentez  que derrière cette carapace se cache une ardente maîtresse. Mais est-ce suffisant ? Car ses critiques acerbes sur votre nature bohème, un brin désordonné, et son manque de sensibilité, refroidiront rapidement votre enthousiasme des premiers instants. Son inflexibilité vous ennuie et malgré vos efforts, vous ne trouverez pas grâce à ses yeux.",
        Coach:"Une attraction irrésistible avec une complémentarité où le monde fantasmagorique rencontre le monde de la réalité. Leur relation est à la fois fascinante et déstabilisante. Un enchantement des sens qui s’évaporera telle la magie de l’illusion. "
    } ,
    Poissons_Balance :{
        Slogan:"“ Un manque d’affection comblé mais des caractères trop divergents. Manque d’admiration.”",
        LVA :"Vos natures calmes, refusant tout conflit, ne pouvaient que s’attirer. Votre amour pour la musique, la poésie et bien d’autres sujets artistiques, dénotent deux personnes un peu bohèmes et insouciantes. Mais votre compatibilité s’arrête là, car même si vous pouvez l'impressionner par votre charme hypnotique, lui susurrant des mots doux, Madame Balance se rendra vite compte que vos belles paroles ne sont que des artifices n’ayant pas de consistances amoureuses. Vous la trouverez alors très superficielle et snob, pas assez spirituelle ni même affectueuse.",
        Plus:"Leur goût commun pour l’art, la musique – Ils sont tous deux amoureux de l’amour assez superficiellement.",
        Moins:"L’inconséquence du poisson  et son instabilité – Leur vision de l’amour est trop différente, le poisson sublime l’amour en général alors que la balance cherche le grand Amour.",
        Craquer:"Tel un magnifique objet de convoitise, vous la désirez et vous ne vous lasserez jamais de la contempler tant elle est belle. Vous serez impatient d'émouvoir ce sublime corps. Prévenant et délicat, vous ne rêverez que d'embraser tout son être. Fasciné, vous accepterez ses doutes et ses hésitations à plonger avec vous dans le tourbillon des sensations de l'amour fou et attendrez impatiemment qu'elle s'abandonne. Mais son goûts pour la vie sociale, les mondanités et son besoin de paraître, se pavanant de manière aguicheuse, oubliant votre présence au dépend de sa popularité, vous laissera un goût amer d'abandon, de désintérêt, et refroidiront vos ardeurs. Sa superficialité et son manque de spiritualité achèveront de vous détourner de cette belle créature, trop diva pour vous.",
        Coach:"Le charme hypnotique du Poisson fascine la Balance qui pressent une relation romantique et idyllique. Mais très vite le manque de compréhension et les quiproquos sonneront le glas final de cette illusoire histoire d’amour. "
    } ,
    Poissons_Scorpion :{
        Slogan:"“ Attraction fatale, ils peuvent aussi bien faire leur malheur que leur bonheur. ”",
        LVA :"Il semblerait que vous ayez été destinés l’un pour l’autre. Vous êtes de nature soumise, et la dominatrice Madame Scorpion trouvera en vous l'esclave idéal à ses tyrannies. On parle de relation sado-maso consentie, le fort tempérament de Madame Scorpion profitera de votre docilité et de votre dévouement, car vous aimez vous détruire dans l'amour. Cette relation génère une  forte intensité d’émotions et de sensations sublimées par l'érotisme passionné de Dame Scorpion. Seule sa lassitude à s'amuser avec «ce jouet qui ne dit jamais non» pourra la détourner de cette relation, mais attention, vous pourrez aussi décider de mettre fin à cette histoire, las des ses multiples tromperies et humiliations.",
        Plus:"Des émotions puissantes et exaltantes les animent –Une sexualité débridée.",
        Moins:"Le poisson est en manque d’affection et de tendresse face à l’agressivité du scorpion – La cruauté du scorpion blesse le poisson – La passivité du poisson agace le scorpion.",
        Craquer:"Si vous deviez lui donner un nom ce serait \" Tentation \". Mystérieuse et sexy, elle vous fait tourner la tête et ne pouvez résister à ses appels sensoriels érotiques. Il faut dire que vous êtes connectés. Elle vous envoie des signaux sensuels et vous réceptionnez les messages, fantasmant déjà sur vos corps à corps que vous imaginez brûlants. Une sorte de code amoureux va s'instaurer entre vous, un brin sado-maso où vous aurez du mal à la maîtriser tant elle a du tempérament. Mais touché par ses crises de désespoir, vous supporterez bien volontiers son agressivité qui sera pour vous l'expression de sa souffrance, vous comprenez tant ses états d'âme... Mais attention, que vos névroses ne deviennent pas une excuse pour abuser de paradis artificiels comme remède à vos angoisses communes.",
        Coach:"Une danse macabre d’énergies intenses et envoûtantes instaure une relation sadomasochiste où le Poisson sera la victime et le Scorpion son tortionnaire. Un érotisme torride cimente cette histoire tourmentée. "
    } ,
    Poissons_Sagittaire :{
        Slogan:"“ Une relation de liberté et de partage des plaisirs qui ne s’arrêtera que lorsque le rêve disparaîtra.”",
        LVA :"Une attraction instinctive déclenchée par la fusion d’énergies amoureuses et sexuelles quasi cosmiques voir spirituelles. Tous deux, empreints de religion, vous verrez dans votre attirance un signe du divin. Le Poissons et le Sagittaire sont des signes mystiques intéressés par les grands courants philosophiques et religieux et partagent le goût pour les plaisirs simples de la vie dont vous saurez vous enivrer ensemble. Mais très vite, votre nature dépressive éloignera Madame Sagittaire. De votre coté, vous ne supporterez plus son exubérance fatigante, vous qui aimez tant le silence méditatif et le calme.",
        Plus:"Vos natures Jupitériennes, vous aimez les plaisirs de la vie et les voyages – Vos natures Neptuniennes, vous aimez les hautes aspirations religieuses et philosophiques. ",
        Moins:"Vos natures infidèles – Votre vision de l’amour est trop différente – Le poissons est passif et lymphatique,  tandis que le Sagittaire est actif et hyper dynamique – Attentions aux paradis artificiels, vous aimez bien l’alcool et la fête.",
        Coach:"Une attraction spontanée et passionnelle pour ces deux hédonistes en quête de sensations et de plaisirs charnels excessifs. Mais leurs différences de sensibilité auront du mal à les accorder à long terme. "
    } ,
    Poissons_Capricorne :{
        Slogan:"“ Ils  sauront prendre soin l’un de l’autre. Relation pansement.”",
        LVA :"L’union entre un signe de Terre et d’Eau est harmonieuse, mais dès que l’on approfondira vos deux natures fondamentalement différentes, on se rendra vite compte que cette histoire n’est viable que grâce à vos prodiges de dévouement envers cette Capricorne peu fantaisiste et peu compatissante. Mais vous vous sentirez protégé par les qualités de sérieux et l'ambition de Madame Capricorne, vous qui êtes si peu courageux à la tâche ! Malgrés son égoïsme et son insensibilité, vous vous entêterez à sauvegarder cette histoire comme un précieux cadeau du ciel malgré ses reproches blessants.",
        Plus:"L’amour inconditionnel du poisson réchauffe la froideur du capricorne – Le capricorne apporte une sécurité financière au bohème poisson- Le poisson par sa sensuelle générosité désinhibe le capricorne. ",
        Moins:"Les sarcasmes, la froideur et le manque de sensibilité du capricorne heurte l’émotif poisson qui le trouve inhumain - Le capricorne se lasse de cet être qu’il trouve trop utopiste et sans volonté.",
        Craquer:"Vous avez sans aucun doute l'art et la manière pour séduire cette beauté froide, réfractaire à toute émotion qu'elle ne peut maîtriser. Votre patience et votre douceur va peu à peu la mettre en confiance et libérer ses sentiments, prisonniers de ses craintes. Ce qui vous intéresse, c'est qu'elle vous ouvre son cœur. Mais attention, Madame est critique et elle pourrait vous blesser de ses réflexions acerbes. Ses résistances à l'amour et son manque de générosité affective risquent de décevoir les espoirs que vous aurez fondé en cet amour improbable.",
        Coach:"Une attraction quasi curative, car ils savent combler instinctivement leurs besoins affectifs. Le Capricorne sécurise le Poisson et calme ses craintes, qui en retour lui prodiguera une tendresse et un dévouement sans bornes qui adoucira la rigidité du Capricorne. "
        },
    Poissons_Verseau :{
        Slogan:"“ Relation avec un esprit ouvert et tolérant en respect de la liberté de chacun et de ses envies.”",
        LVA :"L’entente dépendra de l’évolution psychologique de ces deux personnages un peu perchés, mais dans deux mondes très différents. Vous déambulez dans un monde émotionnel et mystique à la recherche de Dieu, alors que Madame Verseau, hautement intellectuelle, survole ce monde médiocre auquel elle recherche des solutions philosophiques, se prenant un peu pour \"Dieu le père\". Relation floue et utopiste, fondée sur un échange de sensations éphémères, mais intenses, que la triste réalité de la vie dissoudra rapidement.",
        Plus:"Relation mystique et barrée - Leur humanisme.",
        Moins:"Leur irréalisme – L’un se prend pour Dieu et l’autre l’invoque – La froideur du verseau déstabilise l’affectueux poisson.",
        Craquer:"Pour susciter un quelconque intérêt, vous comprendrez très vite qu'il faut la flatter et la mettre sur un piédestal, mais cela ne vous dérange en aucun cas, car lorsque vous aimez, vous adorez. Son côté artiste vous convient très bien, vous aimerez lui chanter tout votre amour et converser avec elle de métaphysique et de spiritualité. Mais sa distance amoureuse vous frustre. Vous avez besoin de démonstrations affectives et même si vous possédez de la tendresse pour deux, vous avez besoin d'un minimum d'attention. Elle est distante et ne comprend pas votre romantisme qu'elle juge ringard et démesuré. Une relation qui risque d’être compromise sur la durée.",
        Coach:"Une relation romanesque connectée à un univers magique et utopique. Le détachement du Verseau et son manque de tendresse frustrera le Poisson qui ne se nourrit que d’émotions et d’amour. Les limites du rêve amoureux. "
    } ,
    Poissons_Poissons :{
        Slogan:"“ Une attirance quasi mystique qui vous transcendera et vous portera aux nues.”",
        LVA :"Ce duo fonctionne grâce à une attraction quasi surnaturelle, où leurs natures sentimentales et émotionnelles se fondent l’une dans l’autre. Vous vivrez alors une fusion des sens qui exaltera une passion dévorante à la fois amoureuse et sensuelle. Mais, couple bohème, inconsistant et incapable d’affronter les réalités matérielles de l’existence, vous vous perdrez bientôt dans les méandres angoissants de la vraie vie. Vous vous débattrez ensemble dans une situation sans issue où vos peurs vous déstabiliseront et vous obligeront à renoncer à vos rêves. Vous repartirez, chacun de votre côté, en quête d’un partenaire plus sécurisant et plus réaliste que vous.",
        Plus:"Vos émotions fusionnent – Vous vivez ensemble un rêve éveillé – Vous transcendez vos énergie sexuelles",
        Moins:"Le manque de réalisme, vous vivez d’amour et d’eau fraîche – Vous êtes dépensiers – Vous vous perdez dans des paradis artificiels et faites des excès d’alcool – Vos natures dépressives. ",
        Craquer:"Sensuelle et mystérieuse, vous avez trouvé la nymphe qui chamboule tous vos sens et vous donne envie de vous jeter à ses pieds. Sensible à vos appels amoureux, elle vibre au rythme de vos mots d’amour et s’abandonne sans résistance à vos désirs. Vous vivrez un envoûtement consenti de vos deux âmes en fusion et  de vos deux corps enflammés. Seul bémol à votre idylle, vos natures dépressives risquent de vous entrainer mutuellement vers des gouffres d’idées noires causés par vos crises existentielles. Incapables de rebooster le moral de l’autre, vous n’aurez d’autres choix que de compatir à sa souffrance, glissant vous-même vers une dépression incontrôlable.",
        Coach:"Une attraction mystique dont le lien amoureux peut les conduire à une extase magique, reliant le corps et l’esprit, un amour les transportant dans un univers fantasmagorique où les émotions mêlent vibrations du cœur et plaisirs sensuels. "
    } ,
}


export const Femme_Homme_Astro = {
    Belier_Belier : {
        Slogan:"« Relation passionnelle mais houleuse avec réconciliation sur l’oreiller. » ",
        LVA :"L’attraction est spontanée, particulièrement basée sur une forte attirance physique. Relation explosive, passionnée dans tous les sens du terme. La monotonie ne fait pas partie de votre vie de couple, mais un peu de calme ne vous ferait pas de mal, car vos caractères vifs et impulsifs créent régulièrement des conflits dévastateurs. Le problème entre vous deux : L’autorité. Pour que cette relation fonctionne, il faut que l’un accepte la domination de l’autre.",
        Plus:"Une passion ardente – Sexualité fougueuse – Enthousiasme – Absence de routine – Dynamisme – L’amour du sport – Complicité de potes.",
        Moins:"Amour qui ne dure pas – Passion feu de paille – Instabilité des sentiments – Deux personnes très colériques et impulsives.",
        Craquer:"Avec son marcel, ses muscles bandés et son  torse bombé, vous le trouvez viril et terriblement sexy, il  vous fait littéralement craquer. Vous l’avez repéré du premier coup d’œil, et avec lui c’est la folle passion. Mais son côté « C’est moi qui commande ! » et ses emportements vous insupportent, car son immaturité le rend peu crédible dans son rôle de macho.",
        Coach:"Vous aurez un coup de foudre au premier regard et vous vous laisserez emporter par  la même fougue. Vous vibrerez de tout votre être et vous aimerez  ça. Vous vivrez une relation addictive qui vous dévorera et deviendra obsessionnelle. "
    } ,
    Belier_Taureau :{
        Slogan:"« Ils ne vivent pas au même rythme et se perdent rapidement de vue. »",
        LVA :"Bonne compatibilité pour une association professionnelle. Le Bélier est créateur d’idées et le Taureau saura organiser et gérer les projets. Au niveau affectif, vous aimez tous les deux le sexe, mais  vos tempéraments diamétralements opposés, créeront des relations de couples impossibles. Le Taureau sensuel mais possessif, exige une relation de qualité aussi bien physiquement que sentimentalement, stable et fidèle. Le Bélier, lui, est passionné, mais instable, infidèle, libre de toute obligation envers l’autre.",
        Plus:"Le taureau calme le bélier – Nuits chaudes sous la couette - Le taureau limite les dépenses excessives du bélier – Le bélier donne de l’élan à la relation.",
        Moins:"Le bélier n’aime pas la routine et le taureau est rassuré par la stabilité – L’infidélité du bélier met le taureau fidèle dans des rages folles.",
        Craquer:"Sa sensualité vous fait fantasmer et quelle déception lorsque vous vous rendrez compte que cet épicurien aime prendre son temps pour déguster sa partenaire. De quoi vous endormir pendant l’acte. Vous, ce que vous aimez, ce sont les emportements enflammés, l’amour à l’excès, alors que chez lui tout est dosé, calculé.  Pas assez généreux pour à votre goût, vous le trouvez pingre, près de ses sous, alors que vous rêvez d'un généreux partenaire enflammé. Vos colères seront à la hauteur de votre déception de cette médiocre histoire d'amour.",
        Coach:"Leur relation ne pourra qu’être purement sensuelle, car les exigences et la possessivité du Taureau seront totalement incompatibles avec l’infidélité notoire du Bélier. L’agressivité gâchera leur complicité érotique. Relation sensuelle, mais éphémère. "
        } ,
    Belier_Gemeaux : {
        Slogan:"« Vous vous cherchez, vous vous trouvez puis vous vous quittez, une passion éphémère. »",
        LVA :"Forte attraction, mais dans une relation « sexfriends ». Tous deux, vous n’aimez pas la routine et aimez le jeu. Mais attention à force de vous chercher vous risquent de vous perdre. La passion du Bélier transporte le Gémeau  plus cérébral. La relation est diffuse, légère, où le sérieux n’est pas de mise, deux signes faits pour les changements et les relations multiples, mais pas pour un engagement à long terme ou en tout cas avec beaucoup de désaccords et d’infidélité que le Bélier exclusif aura du mal à supporter.",
        Plus:"Une franche partie de rigolade – Une relation jeune et légère – Une relation sur un rythme d’enfer – Relation festive et insouciante – Une sexualité sans tabous.",
        Moins:"Le concours à l’infidélité – Les mensonges – Le bélier attise la nervosité du gémeau – Le bélier est brut, le gémeau est raffiné.",
        Craquer:"Il vous intrigue et vous exaspère à la fois, avec ses fausses allures d'intello, il monopolise toute l’assistance... des femmes bien sûr. Ça a fait TILT entre vous dès le premier coup d’œil et ce beau gosse vous subjugue. Vous le voulez et vous l’aurez, mais attention ce Casanova n’est pas exclusif, il aime flirter et butiner de fleurs en fleurs ce qui aiguisera votre jalousie et vous mettra dans des rages folles. Son côté désinvolte, limite méprisant face à vos crises de nerfs, vous décourageront, vous qui avez tant besoin d'exclusivité et d'attentions, vous aurez l'impression d'avoir été manipulée. Deception !",
        Coach:"L’ennui ne fait pas partie de cette histoire, mais le sérieux non plus, c’est leur jeu du chat et de la souris qui les rendra addictifs l’un de l’autre. Relation immature basée sur la spontanéité. "
        },
    Belier_Cancer :{
        Slogan:"« Un guerrier et une pacifiste et auront du mal à trouver un terrain d’entente. »",
        LVA :"L’entente sera difficile, car vos objectifs amoureux divergent vraiment. Le Bélier est indépendant et supporte mal l’attitude protectrice du Cancer. Le Bélier est insaisissable alors que le Cancer recherche à être rassuré, câliné. Le Bélier est blessant et le Cancer hypersensible. Vos univers amoureux sont beaucoup trop différents. Seule l’attraction physique peut permettre une durée pour cette relation. Le Cancer sera séduit par les insistances du Bélier, mais déstabilisé par son  désintérêt aussi soudain.",
        Plus:"Une sexualité harmonieuse et brûlante remplie de fantasmes – Le bélier dominant et masculin rassurera le cancer plus féminisé et soumis.",
        Moins:"Le bélier aime le sexe pour le sexe, le cancer aime le sexe pour l’amour - Le bélier est trop agressif pour la sensibilité du cancer.",
        Craquer:"Il est l’homme idéal pour construire un foyer, avec lui pas de surprises. Il désire une histoire sérieuse basée sur la complicité et il s’évertuera à créer un nid autour de votre histoire. Mais son manque de poigne, sa soumission totale à votre volonté, et sa nonchalance vous exaspère, vous qui êtes si dynamique. Pour vous, un homme doit avoir du dynamisme et du répondant. Faire la nounou ne vous convient qu'un moment. Vous le trouvez trop mou pour vous. Mais attention, si vous le poussez à bout,  sa nature caractérielle pourrait bien se réveiller et rentrer en affrontement avec votre nature volcanique, vous risquez d'être surprise par ses colères violentes.",
        Coach:"Certes, le Cancer a l’art et la manière d’exciter le Bélier grâce à un charme ravageur, mais son immaturité et ses caprices incessants, lasseront rapidement le Bélier, qui n’hésitera pas à aller voir ailleurs, déstabilisant au passage la confiance du Cancer. Relation sensuelle, mais conflictuelle. "
    } ,
    Belier_Lion :{
        Slogan:"« Deux conquérants de l’amour, tous feux tout flamme se disputant l’autorité. »",
        LVA :"C’est l’amour fusionnel, tous deux dans une quête d’un amour idéalisé, où la passion est indissociable des sentiments. De nature généreuse, vous êtes tous les deux très narcissiques et égocentriques, cela pourrait être le seul bémol à votre compatibilité, car vous avez l'un et l'autre besoin de briller en société et d’être le centre d’intérêt. Votre sexualité est débridée et l’attraction bien réelle. Seule vos natures autoritaires peuvent assombrir ce joli tableau ainsi que l’infidélité du Bélier que le Roi Lion ne pardonnerait pas.",
        Plus:"Relation fusionnelle – Relation d’admiration mutuelle – Une volonté et une énergie constructive – Une sexualité passionnée.",
        Moins:"Une rivalité empreinte de jalousie peut s’installer entre ces deux personnages narcissiques – L’infidélité du bélier pourrait tout gâcher.",
        Craquer:"Vous serez totalement sous le charme de ce bel homme lumineux, altier, mais attention vous ne serez pas la seule. Le succès qu’il remporte auprès de la gente féminine représentera un défi supplémentaire qui vous motivera d’autant plus pour le conquérir. Vous le voulez et c’est vous qui l’aurez ! Mais vos caractères forts et dominants vont se heurter, et vous devrez vous soumettre à sa souveraineté masculine si vous désirez que votre histoire s’inscrive dans la durée. Vos rapports passionnels sont certes excitants, mais éreintants, il faudra alors que l'un de vous deux modère le rythme.",
        Coach:"Vous serez emportés par un tourbillon d’amour fou et de sexualité intense. Il suffira de vous regarder pour voir à quel point vous êtes épanouis. Seuls vos conflits d’autorité feront trembler les murs. « Les feux de l’amour » "
    } ,
    Belier_Vierge :{
        Slogan:"« La vierge, méfiante, n'est pas convaincue par les démonstrations d'amour du bélier »",
        LVA :"Un couple dans une totale incompréhension du fonctionnement de l’autre. La vierge farouche et analytique a du mal à comprendre le fougueux bélier qui part au galop et réfléchit plus tard. La vierge a besoin d’être rassurée pour se lâcher alors que le bélier se jette à corps perdu dans l’amour. La vierge aura tendance à refroidir les élans du bélier ce qui risque de le faire fuir rapidement. Cependant le bélier par ses insistances peut réussir à décoincer cette amoureuse prudente qui deviendra \"vierge folle\".",
        Plus:"Les grandes discussions philosophiques – Jouer au chat et à la souris – Dévergonder cette nature réservée et laisser s’exprimer une passion insoupçonnée. ",
        Moins:"L’instabilité du bélier et le besoin de sécurité de la vierge – Le bélier s’ennuie avec la vierge et il ne supporte pas ses critiques. ",
        Craquer:"Comme tous opposés qui s’attirent, vous serez séduite par cet intello, réservé et distant. L’inaccessible c’est ce qui vous motive, vous adorez les défis, mais très vite vous risquez de vous heurter à un mur infranchissable. Son manque de chaleur et d’enthousiasme refroidiront vos ardeurs, vous  sentant rejetée voir méprisée. Est-ce à tort ou à raison, mais vous ne persisterez pas dans cette histoire frustrante et vous vous détournerez de ce triste sire sans regrets.",
        Coach:"La séduction tenace et persévérante de madame Bélier, ne sera pas pour déplaire à monsieur Vierge, réservé et en manque de confiance en lui. Le Bélier aime les défis et les murailles infranchissables, mais se projeter dans un avenir commun, pour la Vierge, il n'en est pas question. "
    } ,
    Belier_Balance :{
        Slogan:"« Une attraction incontrôlable entre ces deux êtres pourtant si différents, mais ça colle. »",
        LVA :"Vous vivrez l’attirance et la répulsion comme deux aimants. Le Bélier est un tentateur qui n’aime pas qu’on lui résiste, la Balance elle, a du mal à résister à la tentation et succombera à ses assauts  insistants. La Balance, raffinée et oisive, séduisante et charmeuse, va envoûter cet incorrigible chasseur. La Balance saura canaliser les emportements du Bélier par sa diplomatie. Leurs étreintes seront explosives et le Bélier saura révéler les désirs profonds de la Balance.",
        Plus:"Une attirance incontrôlée – Une passion dévorante – La balance arrive à attacher le bélier.  ",
        Moins:" La Balance prévoyante surveille son infidèle bélier – Deux caractères forts qui s’affrontent dans des colères terribles.",
        Craquer:"Entre vous c’est l'attraction fatale. Élégant et bel homme, vous serez fascinée par son charme et son charisme. Il vous inspirera les plus folles exubérances et vous ferez tout pour qu’il tombe amoureux fou de vous. Mais vous ne supporterez pas une quelconque infidélité de sa part, même celle d’un regard furtif en direction d’une autre sans que cela déclenche une violente dispute de jalousie. Monsieur est un charmeur que vous devrez surveiller comme le lait sur le feu, mais cela n'en vaut-il pas  le coup, car il sait aussi vous apaiser de sa douceur et  de sa tolérance. C'est à vous de voir...",
        Coach:"C’est la rencontre de Venus et de Mars, la communion parfaite de la femme et de l'homme. C’est l’amour passion dans une quête de la perfection. "
    } ,
    Belier_Scorpion :{
        Slogan:"« Un bras de fer qui gâchera l’attirance instinctive de ces deux guerriers. »",
        LVA :"Attention relation sous haute tension sexuelle, tous deux addicts au sexe, vous vous retrouverez à tester toutes les positions du kamasutra et à essayer sans tabous les expériences les plus insolites. 46%  concentrés dans l’érotisme du couple, car le tandem  Bélier/Scorpion ne s’accorde, ni d’un point de vue psychologique, ni d’un point de vue intellectuel. Le Scorpion plus subtil et sadique désoriente totalement le Bélier franc et direct sans réflexions.",
        Plus:"Le sexe of course – Une passion dévorante à se consumer – Deux excessifs en quête d’absolu.",
        Moins:"Le bélier franc trouvera le scorpion pernicieux et fourbe – Leurs excès déborderont en colères violentes – Avec eux c’est l’amour et la guerre.",
        Craquer:"Vous êtes littéralement envoûtée par son magnétisme et son aura sensuel, vous reconnaissez en lui le partenaire et l'adversaire idéal à la  hauteur de vos désirs et de vos fantasmes. Mais ne vous y trompez pas, il est plus pervers et manipulateur qu'il n'y paraît et il risque de vous briser si vous n'y prenez gare.",
        Coach:"On ne sait quel est celui qui entraîne réellement l’autre dans ses délires excessifs. Vous le provoquez avec vos tenues affriolantes et il démarre au quart de tour, prêt à toutes les folies, sa libido en éveil. Mais vos ébats terminés, c’est la guerre.  "
    } ,
    Belier_Sagittaire :{
        Slogan:"« Une complicité évidente pour ces deux jouisseurs. Le plaisir avant tout ! »",
        LVA :"Une osmose et des goûts communs pour les découvertes, le sport et les voyages. Tous deux très généreux, vous vous accorderez sur votre vision du couple, plutôt libre et sans contraintes. Partager de bons moments de plaisir et de joie de vivre correspondent à vos attentes communes. D’un point de vue sexe, l’objectif est de partager un moment délicieux, ardent et sans artifices, juste pour le plaisir, sans attendre plus de l’autre.",
        Plus:"Une relation sous le signe de la bonne humeur – Une passion tempérée – Une relation libre – Des loisirs communs comme le sport.",
        Moins:"Une relation un peu légère basée sur la complicité et risquant de devenir copain –copine - La fidélité n’est pas au programme",
        Craquer:"Il est  l’homme idéal avec lequel vous vous sentez d’égal à égal, avec lui, vous partagez le goût pour le sport et les grands espaces. La nuit tombant, vous vous éclaterez dans de  grande fiesta bien arrosée entre amis. Ce serait le bonheur total s’il savait se tenir dans ces beuveries, un petit coup dans l’aile et il draguerait une chèvre. Vous ne concevez pas l’amour sans exclusivité et ses écarts de conduite gâchent cette belle complicité. Vos crises de jalousies peuvent le faire fuir, mais il est comme ça, il prend le plaisir où il se trouve. Vous êtes prévenue !",
        Coach:"Vous vous entendez comme deux larrons en foire. Actifs et aventuriers, vous serez toujours prêts à vivre pleinement vos envies, ce qui compte c’est le fun, sans prise de tête. Votre devise est Carpe Diem. Relation basée sur le plaisir. "
    } ,
    Belier_Capricorne :{
        Slogan:"« Difficile de trouver une quelconque affinité, lorsque l’un avance l’autre recule ! »",
        LVA :"Difficile de trouver un terrain d’entente entre ces deux signes à cornes. Vous enfoncez les portes, alors que Monsieur Capricorne s’efforce de grimper des montagnes escarpées. Vous êtes rapide et fougueuse, lui est lent et/ou prudent. Vous aurez bien du mal à vous retrouver sur un point de compatibilité. Au niveau sentimental, le fossé se creuse. Monsieur Capricorne est pudique et introverti, alors que vous êtes plutôt exhibitionniste et extravertie. Vous réussirez cependant à dévoiler quelques fantasmes enfouis de Monsieur Capricorne.",
        Plus:"La droiture et la franchise – Personnes de principes – Des natures contraires qui peuvent être curieuses de se découvrir.",
        Moins:"Deux personnalités entêtées – Autoritaires et intransigeantes – Peu d’affinités sinon aucune.",
        Craquer:"Avec son air un peu coincé, vous aurez envie de le libérer du carcan de son costume d’homme d’affaire. En général, vous les préférez plus rock and roll, mais il ne faut pas se fier aux apparences et vous comptez bien découvrir ce qui se cache derrière ce masque de froideur. Mais l’animal est coriace, difficile de le dérider et encore moins de l’intéresser. Malgré tous les subterfuges que vous userez pour le séduire, vous lâcherez l’affaire avant la fin de la soirée, épuisée et vexée par son manque d’enthousiasme envers vos charmes.",
        Coach:"Le Bélier soufflera sur les braises de la passion alors que le Capricorne s'évertuera à les éteindre, trop d'emballements le pétrifient. Le Bélier disparaîtra, dépité, en l'espace d'un éclair laissant le Capricorne seul, face à ses craintes. Relation soufflant le chaud et le froid. "
    } ,
    Belier_Verseau :{
        Slogan:"« Un goût commun pour la découverte de sensations insolites. Ils se comprennent. »",
        LVA :"Votre amour commun de liberté peut vous permettre de construire un couple sur une base de complicité et d'union libre. Monsieur Verseau est original et curieux et se laissera volontiers emporter dans votre folle passion. Vous apprécierez qu'il ne vous étouffe pas et qu'il ferme les yeux sur vos \"coups de canif\" dans le contrat. Vous partagerez des loisirs, des amitiés si chères à votre équilibre de vie. Votre relation sera surtout basée sur de la tolérance et sur l’espace que l’un concédera à l’autre.",
        Plus:"Le respect de la liberté de chacun - Une sexualité basée sur la découverte de nouvelles expériences – La passion.",
        Moins:"Vous ne pouviez que le remarquer. Ce n'est pas un homme facile qui se  jette sur la première venue, il est limite distant et on pourrait même se demander si cette attitude n'est pas une stratégie pour mieux attirer l'attention sur lui. Ce côté insaisissable vous excite, car vous adorez les défis et vous déploierez toutes vos armes de séduction pour le faire succomber. Amusé par votre jeu, il se laissera volontiers emporter dans votre passion, découvrant avec vous de nouvelles expériences sensuelles sans tabous, juste pour le fun. Mais difficile pour vous de supporter ses silences, vous avez l'impression de n'avoir aucune prise sur lui et cela vous déconcerte. L’incompréhension de ses mécanismes amoureux peut vous éloigner de lui, vexée par tant d'indifférence.",
        Coach:"Ils ne craindront pas de s'évader  ensemble dans une folle aventure, aussi longtemps que l'excitation demeurera. Ils partageront le goût de la liberté et de l'insolite ainsi que  l'amour sans tabous. Relation fusionnelle dans la compréhension mutuelle. "
        } ,
    Belier_Poissons :{
        Slogan:"« Vous vous effleurez sans jamais vraiment vous toucher. Relation de méfiance. »",
        LVA :"Deux univers incompatibles. Monsieur Poisson est rêveur, romantique et vous trouvez que tout ce sentimentalisme n’est que  niaiserie. Vous êtes hypersensible, il est très susceptible et les rapports seront souvent difficiles,  dans l'incompréhension de ce que l'un attend de l'autre. De temperament belliqueuse, vous serez cependant apaisée par sa nature calme, mais qui vous agace fortement aussi. Monsieur Poisson sera fasciné par votre nature volcanique, mais il souffrira de votre désintérêt.",
        Plus:"Le poisson désirant plaire absolument au bélier se pliera à tous ses caprices - Relation où chacun trouvera sa place laissant l’autorité au bélier.",
        Moins:"Le poisson  est un romantique né, le bélier exècre l’amour gnangnan – Le bélier entreprend et le poisson attend, quoi ? Il ne le sait même pas lui-même.",
        Craquer:"Ne vous fiez pas à son apparence réservée, il n'est pas timide, mais plutôt rêveur. Vous pensez que vous ne l'intéressez pas et pourtant il fantasme déjà sur vos futurs ébats. Cet épicurien aime l'amour, le sexe et tous les plaisirs. Vous vous bercerez de \"Je t'aime et vivrez une folle passion, mais pour combien de temps ? Sa passivité et son romantisme mielleux excitent votre nervosité, car vous avez besoin d'un mâle, un vrai, pas d'un ménestrel de l'amour. Vous aimez les relations musclées, les hommes qui ont du répondant et qui savent vous dompter. Lui, vit dans un monde édulcoré, paisible où la devise serait \"Peace And Love\", un monde qui manque de piment. Vous abandonnerez cet utopiste à ses rêveries, lassée par son manque de caractère. ",
        Coach:"Le Bélier ne cherche qu'à vivre intensément sa passion alors que le Poisson, insidieusement, cherche à l'attacher.  Mais attention cher Bélier, ne l'approchez pas de trop près car , Telle Médusa, le Poisson pourrait bien vous envoûter, mais juste le temps d’un instant.  Relation de méfiance. "
    } ,
    Taureau_Belier : {
        Slogan:"“ Ils ne vivent pas au même rythme et se perdent rapidement de vue.”",
        LVA :"Bonne compatibilité pour une association professionnelle.  Le Bélier est créateur d’idées et le Taureau saura organiser et gérer les projets. Au niveau affectif, vous aimez tous les deux le sexe, mais  vos tempéraments diamétralements opposés, créeront des relations  de couples impossibles. Le Taureau sensuel, mais possessif, exige une relation de qualité aussi bien physiquement que sentimentalement, stable et fidèle. Le Bélier, lui, est passionné, mais instable, infidèle, libre de toute obligation envers l’autre.",
        Plus:"Le taureau calme le bélier – Nuits chaudes sous la couette - Le taureau limite les dépenses excessives du bélier – Le bélier donne de l’élan à la relation.",
        Moins:"Le bélier n’aime pas la routine et le taureau est rassuré par la stabilité – L’infidélité du bélier met le taureau fidèle dans des rages folles.",
        Craquer:"Vous aimez vous laisser emporter dans son tourbillon amoureux. Faite pour l’amour, vous goûterez avec lui les délices voluptueux d’une sexualité passionnée, mais sentirez rapidement  qu’il  n’est pas l’homme d’une seule femme. Suspicieuse et exclusive, les doutes  vous plongeront  alors dans de profondes crises de paranoïa destructrices.",
        Coach:"Leur relation ne pourra qu’être purement sensuelle, car les exigences et la possessivité du Taureau seront totalement incompatibles avec l’infidélité notoire du Bélier. L’agressivité gâchera leur complicité érotique. Relation sensuelle, mais éphémère. "
    } ,
    Taureau_Taureau :{
        Slogan:"“ Une vitesse de croisière paisible pour une relation solide et fiable.”",
        LVA :"Bonne entente en général, mais voilà un couple qui va vite sombrer dans l’ennui de leur confort pépère.  Leur sensualité et les plaisirs partagés créeront une complicité indéniable, mais la routine de ces deux fainéants va vite détruire un si beau tableau. Ce duo fonctionnera mieux dans une relation \"sexfriend\", basée uniquement sur les plaisirs. L’infidélité de ces deux épicuriens possessifs va vite créer un tsunami de colères  détruisant toute relation harmonieuse.",
        Plus:"Affection partagée – Sensualité à son comble – Relation chaleureuse – Les plaisirs font totalement partie de la base de cette relation.",
        Moins:"Amour vénal  – Infidélité inévitable –Bouderies incessantes – Colères explosives – Esprits revanchards – Radinerie.",
        Craquer:"Vous vous ressemblez beaucoup, vous aimez de la même manière, et avez les mêmes objectifs de vie. Mais son indolence vous agace, vous avez l’impression que vous portez le couple à bras le corps et que  monsieur se laisse vivre. Son égoïsme et son inflexibilité vous insupportent et vous mettent dans des rages folles. Votre quotidien devient vite insoutenable. Vous avez l'impression de vous heurter à un mur d'incompréhensions. De plus, il surveille vos dépenses et vous fait des reproches sur la gestion de votre budget. Vos conflits sont violents et l'entente sensuelle qui vous unit ne pourra sauver votre couple.",
        Coach:"Dans une histoire pareille, soit vous vous endormez dans une relation pépère, soit vous vous heurtez à vos caractères trop autoritaires et dictatoriaux. Seule une entente  sensuelle  sera possible. "
    } ,
    Taureau_Gemeaux : {
        Slogan:"“ Le taureau susceptible est agacé par l’instabilité et le sarcasme du gémeau. Relation d'incompréhension  ”",
        LVA :"Entente difficile, car le Taureau très terre à terre a du mal à suivre ce trublion de Gémeau. Le calme et la sécurité font partie de l’équilibre du Taureau qui s’enracine dans sa vie, alors que  le Gémeau  en perpétuel mouvement, est curieux de tout et de son contraire. La stabilité rassure l’un et ennuie l’autre. L’un a besoin de références et déteste les changements, alors l’autre  est à l’affût de toutes les nouveautés. Sexuellement,  l’un aime le corps à corps alors que l’autre est dans le  fantasme.",
        Plus:"Le taureau est séduit par le charme juvénile du gémeau – Bonne  association  professionnelle,  alliant créativité et bonne gestion des projets.",
        Moins:"Le gémeau s’ennuie vite avec le taureau – Le taureau est déconcerté par l’instabilité et les mensonges du gémeau –Ils n’ont pas les mêmes valeurs.",
        Craquer:"Même si vous  trouvez qu’il frime un peu trop avec sa belle voiture et son costume de grand couturier, vous ne pourrez vous empêcher de vous dire que \"ce mec là, il est terrible !\" et  que vous feriez un couple parfait, car  le luxe, vous adorez ! Mais vous serez furieuse de constater que monsieur n’est qu’un vulgaire Don Juan et vous, qu’un simple amusement sexuel qu’il remplacera d’un claquement de doigts. Personne ne vous traitera de la sorte et vous comptez bien le lui faire savoir, il vous respectera de gré ou de force. Ca promet !",
        Coach:"Un feeling mitigé entre ces deux signes. Le Gémeau est aussi inconsistant et léger qu'un papillon et le Taureau possessif et jaloux, essaie de dompter la nature volage du Gémeau. L'ennui précèdera les conflits et laissera la place à la tristesse. Une incompatibilité évidente les séparera."
    },
    Taureau_Cancer :{
        Slogan:"“ Ils se complètent à merveille. Tendresse et réconfort sont les maîtres mots de cette relation d’amour.”",
        LVA :"L’association idéale pour fonder un foyer. Le Taureau recherche à construire un foyer sur des fondations solides (confort et matériel) et le Cancer ne conçoit pas l’amour sans la procréation et la construction d’une famille. La sécurité et la tendresse seront le leitmotiv de cette relation. Le sexe sera empreint de sensualité et de tendresse. Un seul bémol, l’hypersensibilité du Cancer pourrait bien  se confronter à la tyrannie du Taureau et à son entêtement.",
        Plus:"La volonté commune d’une relation stable dans la construction pour l’avenir – Une relation basée sur des valeurs familiales simples.",
        Moins:"Relation sans fantaisie – Jalousies et caprices peuvent détruire cette histoire – Le Taureau manque de subtilité pour l’hypersensible Cancer.",
        Craquer:"Vous aimez son côté homme fort et protecteur qui veille au bien-être de sa femme. Sa volonté de construire un foyer correspond totalement à vos aspirations et sa sensibilité féminine crée des affinités rassurantes et une complicité sensuelle. Mais ses comportements d’enfant gâté et capricieux se heurteront à votre rudesse qui n’acceptera pas de céder à toutes les exigences immatures de cet adolescent attardé et caractériel. Et puis ses réticences à vous inviter dans de beaux restaurants, son manque d'imagination ou de largesse pour le choix de vos cadeaux vous font douter de ses sentiments, car lorsqu'on aime, on ne compte pas et on gâte l'objet de ses désirs. A moins qu'il soit radin ? Ce serait terrible, vous qui aimez tant dépenser !",
        Coach:"La volupté et la sensualité seront le ciment de cette histoire. Tous deux, de nature protectrice, sauront se rassurer mutuellement. Ils regarderont dans le même sens, dans l'envie de construire un foyer rempli d'enfants. "
    } ,
    Taureau_Lion :{
        Slogan:"“ Deux fortes têtes incapables de concéder les rênes du couple à l’autre.”",
        LVA :"Les conflits d’intérêts et de pouvoir vont gâcher cette relation. Tous deux, très ambitieux, avec une grande soif de réussite, pourrez trouver un terrain d’entente constructif,  si vous arrivez à modérer votre  tendance à la domination. Vos signes, (Taureau/Lion) très masculins, sont portés sur la volonté de diriger,  et vous aurez bien du mal à communiquer, sauf sur l’oreiller où l’amour du sexe réussira la prouesse de prolonger le sursis de cette relation vouée à l’échec.",
        Plus:"La Franchise – 	Une volonté commune à la réussite –Une sexualité débridée mais peu imaginative.",
        Moins:"Guerre de pouvoir et d’égo –Rapports de force –Relation en manque de passion mais conflictuelle – Dialogue de sourds sur fond de mauvaise foi.",
        Craquer:"Viril et protecteur, il représente l’idéal masculin qui nourrissait vos rêves de petite-fille. Son goût pour l’art, le luxe, l’argent , le pouvoir et sa  grande générosité  vous confortera dans votre choix de vie commune. Mais vous aurez du mal à jouer très longtemps le rôle de subordonnée, à flatter l’ego surdimensionné de ce dictateur. Vous ne pourrez  pas vous empêcher d’égratigner sa superbe par des critiques venimeuses, déclenchant de violents affrontements. Vexé et surtout rancunier, il vous fera payer au centuple votre insolence.",
        Coach:"Ces deux signes passionnés sauront se séduire par une cour enflammée et par des sorties dans des endroits les plus chics.  Une relation où l’égo se nourrit de l’attrait de l’autre, mais où les coups de gueule se transforment en de violents conflits. Relation où la violence et la domination trahiront un idéal superficiel."
    } ,
    Taureau_Vierge :{
        Slogan:"“ Un accord parfait pour ces deux terriens qui aspirent à la quiétude d’un foyer.”",
        LVA :"Rencontre de deux solides terriens, qui aimeront ensemble, couler des jours heureux sous le signe de la stabilité et de la sécurité. Le Taureau sait gagner l’argent que la Vierge saura thésauriser en vue de l’achat de leur douillette demeure et d’investissements immobiliers pour leur belle retraite. Ces deux-là sauront avancer main dans la main, comme deux bons gestionnaires. Une vie bien orchestrée  avec un  manque évident de piment et de fantaisie. Une sensualité en harmonie sans surprises.",
        Plus:"Goût pour la stabilité – La confiance – La droiture – Tous deux sont des laborieux – Epicuriens – Ils aiment les choses simples et vraies",
        Moins:"Manque de passion évident – Vie ennuyeuse et rigide – Certains taureaux sont infidèles et la vierge est assez constante et droite.",
        Craquer:"Votre besoin de vivre sereinement, sans complications ni tergiversations, se verra rassuré par le fonctionnement de monsieur Vierge, droit et fidèle. Sa fiabilité de bon gestionnaire assurera vos arrières et votre confort matériel. Mais vous devrez redoubler d’ardeur et de sensualité pour réussir à dégeler ce grand timide  pudique, au risque de vous sentir frustrée et de vous ennuyer.",
        Coach:"Le Taureau amènera la sécurité financière dont la Vierge a besoin. La Vierge enchantera le Taureau par son dévouement et ses attentions. Une relation de connivence, bien huilée, teintée d'un érotisme lascif. Relation basée sur du solide. "
    } ,
    Taureau_Balance :{
        Slogan:"“ Ces deux vénusiens auront du mal à se supporter bien longtemps malgré leur goût commun pour l’art.”",
        LVA :"Vos signes Vénusiens trouveront à coup sûr une belle entente au niveau sentimental et physique. Ce qui vous réunit, c’est l’amour du beau, de l’esthétique, de l’artistique et de l’oisiveté. Malgré ces affinités, vous abordez la vie de façon radicalement différente et l’harmonie laissera place très rapidement aux malentendus. La Balance raffinée et mondaine aura du mal à supporter la vie trop simple d’un Taureau pépère et grognon.",
        Plus:"L’amour de la beauté – Âmes d’artistes – La  sensualité.",
        Moins:"La légèreté de la balance –Leur matérialisme –L’un aime le foyer l’autre les mondanités – La balance est raffiné et le taureau assez simple parfois grossier",
        Craquer:"Vous serez subjuguée par ce bellâtre au charme ravageur. Vous mettrez en valeur tous vos attraits pour le faire tomber dans vos filets et  passerez avec lui des moments délicieux de plaisirs à deux. Jusqu’à ce que vous vous rendiez comptiez que  monsieur est un séducteur invétéré qui a besoin d’une cour d’admiratrices. Votre possessivité excessive ne le supportera pas et les crises de jalousies à répétition auront raison de votre histoire d’amour. Car la fidélité est une vertu indispensable à votre équilibre mental, vous serez torturée à la seule pensée que votre Adonis puisse s'intéresser à une autre que vous. Car pour vous, l'amour est exclusif et doit être aveuglé.",
        Coach:"Ces deux Vénusiens, amoureux de l’amour et aux aspirations artistiques communes, ne réussissent pas à se mettre d’accord, leurs désirs étant trop divergents. Attention aux relations d’intérêts pour ces deux matérialistes. "
    } ,
    Taureau_Scorpion :{
        Slogan:"“ Fascination et défis pour la rencontre de ces deux caractères bien trempés.”",
        LVA :"Relation fusionnelle où l’attraction peut devenir très vite fatale et violente. Dans tous les cas, cette relation sera basée sur un désir impérieux, une sexualité explosive dans une quête unique  de sensations fortes. Insatiables, ils n’auront de cesse d’aller toujours plus loin dans les excès en tout genre, ce qui rendra vite l’atmosphère électrique et agressive car ces deux-là manquent grandement de souplesse. Tous deux têtus, obstinés et jaloux n’auront de cesse de se disputer.  ",
        Plus:"Une attirance incontrôlée par ses bas instincts – Une sexualité débridée – Tous deux recherchent la stabilité – Ils aiment l’argent et le luxe.",
        Moins:"Suspicion – Colères violentes – Rapport de force et de domination – Jalousie – Tyrannie des deux signes – Rancunes – Les ruptures sont empreintes de vengeance.",
        Craquer:"Il exercera une véritable fascination sur vous, une attraction où vous sentirez, qu'avec lui, vous flirtez avec le danger. Mais cette sensation sera si grisante que vous ne pourrez y résister. Entre vous c'est une attraction fatale, où l'érotisme est à son apogée avec en sus, des tumultes orageux causés par vos deux esprits torturés et possessifs.",
        Coach:"Ils se défieront du regard et le Scorpion attirera le Taureau par son magnétisme envoûteur, mais attention, telle une mante religieuse, le Scorpion veut posséder le Taureau. Vous rentrerez dans un combat de pouvoir. Relation sous haute tension sexuelle. "
    } ,
    Taureau_Sagittaire :{
        Slogan:"“ La liberté de l’un empêchera l’épanouissement de l’autre. Relation manquant de tolérance.”",
        LVA :"Relation difficile, diamétralement opposée dans vos envies et votre façon de voir la vie à deux. Vous avez vos habitudes et vous aimez la routine de la vie de couple, cela vous rassure, alors que le Sagittaire ne vit que de changements, de mouvements, c’est un aventurier au sens propre comme au sens figuré. Vous ne vous entendrez que dans la sexualité, car vous êtes tous les deux des jouisseurs  épicuriens qui aiment les plaisirs de la vie. Mais, trop indépendant, le Sagittaire fuira votre possessivité, car vous attendez un engagement qu'il ne sera pas prêt à vous garantir.",
        Plus:"Le partage de bons moments, des plaisirs de la chair et de la table – L'amour de la nature,  des plaisirs simples. ",
        Moins:"Le sagittaire est un libre et le taureau veut l’attacher – La jalousie et la possessivité du taureau – L'inconstance du sagittaire  et son infidélité.",
        Craquer:"Bel homme élancé, sportif, avenant et drôle, il saura vous plaire, très à l’aise dans sa conversation et spontané dans ses élans. Il vous embarque dans une folle nuit interminable où vous allez de surprises en surprises, il faut dire qu’il a de l’imagination. Mais au petit jour, il disparaîtra sur la pointe des pieds en vous disant qu’il vous rappellera. Vous venez de rencontrer l’homme qui ne s’engage jamais, l’homme qui aime juste sur le moment, tout un programme qui va vous rendre neurasthénique.",
        Coach:"La possessivité du Taureau va se heurter à l’excès d’indépendance du Sagittaire. Malgré leurs goûts communs pour les plaisirs simples de la vie, le Taureau tentera en vain de freiner l’exubérance du Sagittaire ce qui plongera le Taureau dans un sentiment d’insécurité. "
    } ,
    Taureau_Capricorne :{
        Slogan:"“ Relation basée sur la confiance, avec la patience on arrive à tout.”",
        LVA :"Ces deux-là savent œuvrer pour l’avenir, patients et déterminés vous prendrez le temps de vous connaître et construirez les fondations de votre relation sur une confiance mutuelle. Vous vous comprennez et savez exactement ce que l’un attend  de l’autre. Votre générosité et votre chaleur humaine ravivera la flamme de Monsieur Capricorne et le mettra en confiance. La base de votre histoire d’amour est le respect de l’autre. Sexuellement, votre passion saura transcender le Capricorne, plus réservé.",
        Plus:"Un respect mutuel les  anime – Leur nature posée les rassure – Le hasard ne fait pas partie de cette relation, tout est réfléchi - La solidité terrienne de la relation.",
        Moins:"La chaleur du taureau ne compensera pas toujours la froideur du capricorne – Les ambitions de l’un peut amener la perte de l’autre.",
        Craquer:"Cet homme au look de \"Golden boy\" semble être un bon parti, brillant, intelligent, belle situation. Il a le profil du mari idéal pour une femme telle que vous. Il est en quête d’une femme sérieuse, l’épaulant tout au long de sa vie, comme une épouse fidèle et aimante, votre portrait craché, vous ferez un couple formidable ! Mais son  manque d’affection et de tendresse vous perturbe sérieusement, vous, véritable pot de colle, machine à bisous,  vous vous sentez quelque peu frustrée et délaissée. Pourtant il sait vous rassurer, ambitieux et aimant le luxe, il vous apportera le confort de vie de vos rêves les plus secrets. On ne peut pas tout avoir,  tout est question de choix et peut-être réussirez-vous à le dégeler avec le temps ! ",
        Coach:"Fondations solides pour ce couple de Terriens qui s’attirent principalement par leur côté pragmatique sécurisant. Le Taureau n’hésite pas à user de tout son charme pour convaincre le Capricorne, très coriace en amour.  Relation équilibrée. "
    } ,
    Taureau_Verseau :{
        Slogan:"“ Dialogue difficile entre ces deux idéalistes divergents. Conservatisme contre démocratie.”",
        LVA :"Monsieur Verseau aime par-dessus tout sa liberté et ne supporte pas de se justifier ni rendre des comptes, et vous, vous ne supportez pas ce qui vous échappe et avez besoin d’être rassurée en permanence. Vous comprendrez donc que vous aurez bien du mal à trouver un terrain d’entente sur le long terme. De plus, vos scènes de jalousie feront fuir cet solitaire Verseau qui ne supporte pas les éclats inutiles. Un point de divergence encore, vous êtes assez basique alors qu'il est plutôt intellectuel.",
        Plus:"La fidélité du taureau séduit le verseau qui lui n’est fidèle qu’à lui-même – Le taureau amène une stabilité financière au dépensier verseau.",
        Moins:"L’indépendance du verseau déstabilise le taureau qui rentre dans des rages folles – L’un est solitaire, l’autre a peur de la solitude.",
        Craquer:"Quel homme ! Vif, intelligent, vous passeriez des heures à l'écouter s'exprimant sur des sujets brûlants de l'actualité, il a un avis sur tout... Il est si passionnant qu'il  monopolise toute l'attention de son auditoire, d'ailleurs vous n'êtes pas la seule à être subjuguée et il adore être le centre d'intérêt. Tant sollicitations vous irrite. Il ne doit avoir d'yeux que pour vous, et ne pas se perdre dans des explications avec quelques groupies sans intérêts. Vous ne comprenez pas cet homme, comment peut-il être si brillant et n'avoir aucun respect pour l'amour que vous lui portez. Vous vous sentez bafouée par son indifférence et ses infidélités. Rongée par la rage vous préférerez le quitter que supporter de tels affronts.",
        Coach:"L'attraction est éphémère, mais avec un désir d’explorer un univers inconnu, car le Taureau se méfie du style libéré du Verseau, qu’il interprète mal et qui exacerbe sa jalousie, étouffant ainsi le Verseau. Une relation très chaotique, un défi impossible."
    } ,
    Taureau_Poissons :{
        Slogan:"“ Relation de tendresse où chacun rassure l’autre de ses sentiments.”",
        LVA :"Douce alchimiste entre vos deux signes.  Vous partagez un goût prononcé pour les plaisirs de la chair et de la table, le romantisme au coin du feu, la musique, le cinéma. D’un point de vue sexuel, la magie opère là aussi. Mais cette relation ne sera pas sans faille, vous êtes pragmatique et terre à terre, et les rêveries utopistes et l’indécision de Monsieur  Poisson  vous irriteront assez rapidement. Il aura du mal à comprendre toute cette surexcitation.",
        Plus:"Affinités de goûts – Envie d’amour partagé – Besoin d ‘attentions et de tendresse – Sexualité épanouie.",
        Moins:"Le taureau est carré, terre à terre, le  poisson est utopiste, il fuit la réalité – Le poisson se contente de peu alors que le taureau a besoin de son confort.",
        Craquer:"Cet amoureux de l'amour va vous jouer de la mandoline sous votre fenêtre jusqu'à ce que vous cédiez à ses complaintes enflammées. Vous qui adorez être désirée vous serez comblée, car il aime sans pudeur et a besoin d'exprimer, de crier, de chanter ses sentiments. A l'écoute de vos désirs intimes, il saura intensifier vos émotions et faire vibrer tout votre être, vous serez une femme pleinement épanouie, car c'est par votre plaisir qu'il se satisfera. Vous vivez une relation fusionnelle exceptionnelle chargée de sensations fortes. Le seul bémol est que cet homme hypersensible vit dans un monde irréel où l'indécision règne. Il a tendance à se laisser vivre, oubliant ses obligations d'homme et de père, méprisant l'argent et le confort de son foyer. Une véritable source de conflits entre vous !",
        Coach:"Une attraction sensuelle qui sera le détonateur d’un embrasement amoureux pour ces deux hédonistes prêts à expérimenter une vaste palette de plaisirs. Le seul bémol, le pragmatisme du Taureau aura du mal à comprendre les rêveries du Poisson. "
    } ,
    Gemeaux_Belier : {
        Slogan:"“ Vous vous cherchez, vous vous trouvez puis vous vous quittez, une passion éphémère.”",
        LVA :"Forte attraction, dans une relation plutôt sexfriend. Vous n’aimez pas la routine, mais vous aimez le jeu. Cependant, attention, à force de vous chercher, vous risquez de vous perdre. La passion du Bélier transporte le Gémeau  plus cérébral. La relation est diffuse, légère où le sérieux n’est pas de mise, deux signes faits pour les changements et les relations multiples, mais pas pour un engagement à long terme ou en tout cas, avec beaucoup de désaccords et d’infidélité que le Bélier, exclusif, aura du mal à supporter.",
        Plus:"Une franche partie de rigolade – Une relation jeune et légère –Une relation sur un rythme d’enfer – Relation festive et insouciante – Une sexualité sans tabous.",
        Moins:"Le concours à l’infidélité – Les mensonges –Le bélier attise la nervosité du gémeau – Le bélier est brut, le gémeau est raffiné.",
        Craquer:"Avec vous deux, tout est insouciance, vous vous amusez bien ensemble. Il vous veut et vous le fait sentir, ses empressements amoureux vous ravissent, mais sorti de ça, vous trouvez que le personnage est inconsistant,  peu cultivé, grossier et  manquant de finesse. Il ne vous amusera pas longtemps.",
        Coach:"L’ennui ne fait pas partie de cette histoire, mais le sérieux non plus, c’est leur jeu du chat et de la souris qui les rendra addictifs l’un de l’autre. Relation immature basée sur la spontanéité. "
    } ,
    Gemeaux_Taureau :{
        Slogan:"“ L’instabilité et le sarcasme du gémeau agacent le taureau susceptible. Relation d’incompréhension.”",
        LVA :"Entente difficile, car le Taureau très terre à terre a du mal à suivre ce trublion de Gémeau. Le calme et la sécurité font partie de l’équilibre du Taureau qui s’enracine dans sa vie alors que  le Gémeau  en perpétuel mouvement, est curieux de tout et de son contraire. La stabilité rassure l’un et ennuie l’autre. L’un a besoin de références et déteste les changements, alors que l’autre  est à l’affût de toutes les nouveautés. Sexuellement, l’un aime le corps à corps alors que l’autre est dans le  fantasme.",
        Plus:"Le taureau est séduit par le charme juvénile du gémeau – Bonne  association  professionnelle,  alliant créativité et bonne gestion des projets.",
        Moins:"Le gémeau s’ennuie vite avec le taureau – Le taureau est déconcerté par l’instabilité et les mensonges du gémeau –Ils n’ont pas les mêmes valeurs. ",
        Craquer:"Sa force tranquille pourrait bien vous canaliser et tempérer votre insouciance. Ne serait-il pas celui qui saura vous apporter ce confort matériel  dont vous avez tant  besoin, vous si désinvolte ? Mais mon Dieu, quel ennui, cet homme est bien trop étriqué, poussiéreux et désespérant de simplicité. Il manque d’un grain de folie. Vous avez besoin de plus de légèreté, vous aimez vous amuser, plaisanter, séduire et lui vous traque, vous surveille et vous recadre au moindre faux pas. Vous ne supporterez pas d'être critiquée de la sorte.",
        Coach:"Un feeling mitigé entre ces deux signes. Le Gémeau est aussi inconsistant et léger qu'un papillon et le Taureau possessif et jaloux, essaie de dompter la nature volage du Gémeau. L'ennui précèdera les conflits et laissera la place à la tristesse. Une incompatibilité évidente les séparera. "
    } ,
    Gemeaux_Gemeaux : {
        Slogan:"“ Amour et fantaisie, pourrait être le titre de leur histoire très friendly.”",
        LVA :"Complicité exceptionnelle de « jumeaux », voilà un couple qui se comprend parfaitement, on peut dire que vous êtes sur la même longueur d’ondes. Vous passez des heures à discuter et à vous amuser comme des adolescents, espiègles et moqueurs vous n’aurez de cesse de vous taquiner. Attention cependant aux caprices et aux railleries blessantes qui pourraient bien déclencher des éclats entre vous. Votre relation est très érotique, mais votre fidélité n’est pas sans failles.",
        Plus:"Complicité exceptionnelle – Goûts communs – Relation jeune et fraiche – Légèreté – Connexion intellectuelle –Créativité – Relation de partage.",
        Moins:"eux de mains jeux de vilains – L’infidélité – Une relation trop fraternelle risque de manquer de passion – Instabilité – Nervosité et crises de nerfs.",
        Craquer:"Avec lui, les jours se suivent, mais ne se ressemblent pas. Pas de routine, mais plutôt une symphonie d’occupations et d’échanges ludiques. Vos esprits sont connectés, vous vous reconnaissez en lui et vous vivez une merveilleuse synchronicité. Il vous déclarera son romantisme par un poème digne de Cyrano, mais ne lui demandez pas de vous couvrir de baisers, sa démonstration affective s’arrêtera à l’épistolaire. Vous serez comblée de bonheur et il vous fera rire de ses pitreries et autres jeux de mots dont il est le spécialiste. Vous aimeriez juste qu'il ne soit pas à l'affût sans cesse du regard des autres femmes.",
        Coach:"Amour et fantaisie, sont les deux ingrédients déclencheurs de leur coup de cœur. Ensemble ils s’amusent beaucoup et savent érotiser leurs échanges. Mais à la longue, ces deux joyeux drilles, se lasseront de leurs trop grandes similitudes. "
    },
    Gemeaux_Cancer :{
        Slogan:"“ Relation sur fond d’humour et de fantasmes. Mais quel est le plus menteur des deux ?”",
        LVA :"Deux signes jeunes, qui retomberont facilement en enfance. Vous vivrez votre histoire dans une osmose euphorisante, empreinte de rires et de farces. L’idéal pour vous deux, serait de vivre votre relation en union libre car votre vision du couple est bien trop différente. C’est pour cela que votre compatibilité est tantôt bonne tantôt mauvaise. Le  Gémeau extraverti et imprévisible va heurter  l’hypersensibilité et émotivité du Cancer. Le Gémeau s’épanouit totalement dans cette relation, alors que le Cancer risque d’en souffrir.",
        Plus:"Deux rêveurs ensembles – La passion dans la légèreté – Le retour en enfance, l’insouciance d’amour de jeunesse.",
        Moins:"La difficulté sera lorsqu’ils parleront de sérieux -L’un est indépendant l’autre pense à deux – L’instabilité du gémeau perturbe le cancer.",
        Craquer:"Vous serez touchée par toute l’ingéniosité que déploiera ce grand romantique pour vous déclarer sa flamme, attentionné et prévenant vous vous  sentirez traitée comme une reine et il sera votre chevalier servant. Mais la magie des premiers instants passée, vous commencerez à trouver votre prince trop mielleux, étouffant manquant de virilité et de poigne. Vous serez vite gagnée par l'ennui et vous courrez chercher des sensations plus musclées ailleurs. Vous ne vous encombrez pas de scrupules, car pour vous la vie est un jeu que vous vivez pleinement et lorsque vous commencez à tourner en rond et à trouver le temps long, c'est qu'il est temps de partir. Ce que vous faites sans sourciller.",
        Coach:"Entre ces deux signes, c’est l’insouciance, l'amour juvénile, frais et enfantin qui manquera à la longue de stabilité et de consistance. L’instabilité du Gémeau déconcertera la quête permanente de protection du Cancer. "
    } ,
    Gemeaux_Lion :{
        Slogan:"“ Champagne ! Une complicité festive, joyeuse, c’est l’amour paillette.”",
        LVA :"Coup de foudre au premier regard, mais sur la durée vos deux fortes personnalités auront du mal à se supporter. Les railleries et vexations du Gémeau auront raison de l’amour inconditionnel du Lion. Vos deux signes sont assez complémentaires et s’entendent bien, mais trop égoïstes, vous vous disputerez la place de la vedette. Votre relation ne sera pas de tout repos. La sexualité est épanouie, mais le Gémeau est un peu trop cérébral pour le Lion plus instinctif et animal.",
        Plus:"L’amour est fort et passionné – Le lion stabilise le gémeau -  La fantaisie – Le goût commun pour l’art et le spectacle.",
        Moins:"Les colères du lion sont redoutables et le gémeau attise la zizanie – Le sarcasme du gémeau agace et vexe le lion qui a besoin d’admiration.",
        Craquer:"Il vous fascinera par cette aisance naturelle à captiver toute l'attention, on ne peut l'ignorer tant il dégage un charisme et un magnétisme ravageur. Vous parviendrez à susciter son intérêt grâce à votre finesse d'esprit et saurez satisfaire, par votre habilité oratoire, ses désirs incessants de louanges et d'admirations. Mais se plier à ses exigences sera une mission périlleuse qui mettra vos nerfs à rude épreuve et vous refuserez parfois de vous éclipser devant ce personnage qui prend trop de place, ce qui le fera rugir de colère.",
        Coach:"L'emballement réciproque entre l’air pétillant du Gémeau et le feu conquérant du Lion promet un amour ardent. Une admiration commune attise l’intensité de leur relation et stimule une passion déraisonnable sur fond de rivalité. "
    } ,
    Gemeaux_Vierge :{
        Slogan:"“ Leur cérébralité sera le piment de cette histoire . Une relation toute Mercurienne. ”",
        LVA :"Tous deux Mercuriens, si la Vierge est dite « folle », vous vous entendrez comme deux larrons en foire, avec des affinités particulièrement intellectuelles, vous aimerez discuter, échanger, jouer ensemble. Sexuellement, vos jeux érotiques seront plutôt cérébraux, scénarisés. Cependant, la relation risque de s’essouffler par manque de piment, le Gémeau, assez dispersé, déstabilise la Vierge organisée et l’agace par son manque de cohésion et de constance.",
        Plus:"Une complicité intellectuelle presque fraternelle – Ils se comprennent et ont les mêmes jeux – La créativité du gémeau est matérialisé par la Vierge.",
        Moins:"La nervosité du gémeau perturbe le calme de la vierge –La vierge risque à la longue d’ennuyer le gémeau.",
        Craquer:"Vous aimerez son allure fière tout en retenue, sa froideur vous intriguera et vous donnera envie d’en découvrir un peu plus sur ce beau ténébreux au regard détaché. Dès les premiers mots échangés vous comprendrez que vous avez affaire à un esprit brillant et caustique, que vous défierez de joutes intellectuelles exaltantes. Son manque de passion amoureuse et l’absence d’élans spontanés risquent cependant de ternir cette belle complicité que vous esperiez plus passionnelle.",
        Coach:"Une attraction érotico-intellectuelle où l’esprit est en effervescence, aiguisée par la curiosité et les joutes verbales. Ils se plaisent, d’abord intrigués par leur vivacité d’esprit et admirent leur talent. Mais attention, leur ironie acerbe et l'irritation que cela peut provoquer risque de tout gâcher. "
    } ,
    Gemeaux_Balance :{
        Slogan:"“ Relation aérienne et délirante, manquant parfois de pragmatisme. Couple intemporel.”",
        LVA :"Vous formez un joli couple qui aime les sorties entre amis et les mondanités, vous passerez des heures à discuter de tout et de rien. Tous deux intelligents, raffinés et charmeurs, votre relation est légère comme l’air, où l’insouciance vous guide. Un peu dans les nuages, vous avancerez dans une relation amoureuse  idéalisée. La triste réalité de la vie et ses contraintes quotidiennes risquent de laisser place à la désillusion et aux grincements de dents. Côté sexe, le plaisir est garanti grâce à votre imagination débordante.",
        Plus:"Vous vous comprennez  et passez d'agréables moments – Vous êtes tous deux très artistes et manuels – Vous communiquez beaucoup – Vous êtes deux imaginatifs.",
        Moins:"A force de dépenser sans compter on ne paye plus ses factures – Les projets restent à l’état de rêve – A trop vouloir du  plaisir on se brûle les ailes.",
        Craquer:"Ce dandy élégant est toujours entouré de nombreuses groupies pendues à ses lèvres, espérant un signe de sa part. Et vous mettre en concurrence, ce n’est pas votre genre. Mais vous saurez faire mouche grâce à votre aisance verbale qui saura flatter son égo et accrocher cet homme tant convoité. Tant qu’il vous divertira, l’enjeu vous intéressera, mais son manque de profondeur vous lassera, car vous ne comptez pas vous battre en duel pour prouver votre amour. L'amour ça doit être simple et léger comme l'air...",
        Coach:"Une attraction irrésistible va les rapprocher, il faut dire que le Gémeau est un habile orateur qui va subjuguer la Balance par le raffinement et la diplomatie, qui sont des qualités chères à son cœur. Amour aérien. "
    } ,
    Gemeaux_Scorpion :{
        Slogan:"“ Jeux cérébraux sur fond de manipulation. Rien de constructif dans ce genre de relation.”",
        LVA :"Vous êtes deux provocateurs qui auront du mal à trouver un terrain d’entente sur le long terme. L’inconstance du Gémeau aura tendance à désorienter et exacerber la jalousie et la possessivité du Scorpion qui, un tantinet  paranoïaque entrera dans des colères monstres. Le Gémeau a du mal à comprendre la torture mentale dans laquelle le Scorpion se complet. Le Scorpion plus charnel, attend de son compagnon Gémeau des ébats plus torrides et plus instinctifs, mais là ça risque de coincer.",
        Plus:"Tous les deux sont très curieux, intelligents avec un esprit vif – Ils aiment  la provocation et la polémique.",
        Moins:"L’instabilité du gémeau déconcerte le scorpion – Tous deux sont manipulateurs  - Deux esprits tourmentés et angoissés – L’infidélité.",
        Craquer:"Son magnétisme et son charisme ne pourront que vous attirer, vous n'aimez pas les hommes ordinaires et lui, il titille votre curiosité, il vous intrigue. Vous vous laisserez  agréablement emporter par sa passion, mais vous sentirez très vite qu'il veut vous posséder et vous isoler.  Cette intrusion dans votre liberté sociale et intellectuelle ne saurait être acceptable, vous aimez l'échange et le butinage. Vous ne supporterez pas  longtemps ses crises de susceptibilités et d'autoritarisme et n’hésiterez pas à le laisser en carafe sans explications.",
        Coach:"Attraction dangereuse pour ces deux provocateurs qui se lancent des défis intellectuels dans lesquels ils se découvrent réciproquement avec beaucoup d’enthousiasme, les plongeant inconsciemment dans une relation obscure. "
    } ,
    Gemeaux_Sagittaire :{
        Slogan:" Cours après moi que je t’attrape, rien de tel pour aiguiser les sens et tuer l’ennui.”",
        LVA :"La monotonie n’existe pas dans votre relation, car vous avez en commun le goût des mouvements, des déplacements et des voyages. Votre complémentarité crée votre attirance. Tous deux avides de connaissances, vous tirerez pas mal de satisfactions à vos échanges  d’idées. La liberté et la compromission faisant partie de vos personnalités, la fidélité ne sera pas l’ingrédient principal de votre histoire. Votre relation sera plutôt basée sur une amitié amoureuse plus que sur le grand amour.",
        Plus:"Votre amour de la liberté – L’appel des grands espaces – La curiosité intellectuelle – La tolérance et le respect de la liberté de chacun.",
        Moins:"Un manque certain de passion  amoureuse – L’infidélité – Le refus d’engagement –L’hyper-indépendance du sagittaire.",
        Craquer:"Il a failli vous plaire, vous l’avez découvert tel \"Indiana Jones\" narrant son dernier périple à la découverte de trésors perdus, passionnant et séduisant vous vous êtes laissée aller à l’aventure en  acceptant ses avances. Vous avez partagé des moments délicieux de complicité, et comme vous, il est curieux de tout, mais il est totalement réfractaire à tout engagement et oublie souvent de se manifester,  il disparaît et réapparaît pensant que vous allez supporter longtemps ce petit manège. De plus, il manque terriblement de savoir-vivre et de délicatesse et vous ne supportez pas les grossiers personnages. Cet homme est un leurre !",
        Coach:"Une truculente aventure entre ces deux curieux et jouisseurs de la vie qui s’entraîneront dans une relation légère et parfois court vêtue… où les tabous seront absents, l’ennui exclus et la liberté chérie. Mais qu’en est-il de la vraie vie ? "
    } ,
    Gemeaux_Capricorne :{
        Slogan:"“ Trop de lucidité tue le rêve, Trop de fantaisie fait perdre le réalisme. Personnalités en  déséquilibre.”",
        LVA :"Vous n'avez pas grand-chose en commun. On pourrait même dire que vous êtes diamétralement opposés. Vous êtes légere, versatile, bavarde et vous aurez du mal à supporter la compagnie de Monsieur Capricorne trop sérieux, réservé, peu communicatif et de surcroit ennuyeux, en tout cas pour vous. Il vous le rendra bien, Monsieur Capricorne, solitaire amoureux du calme, aura bien du mal à vous supporter, vous considérant  comme une agitée du bocal, qui s’excite pour un oui ou pour un non. Côté sexe, ce n’est pas la joie non plus.",
        Plus:"Seule la créativité du gémeau s’accorderait au pragmatisme du capricorne pour une association professionnelle. ",
        Moins:"L’un sérieux, l’autre fantaisiste et inconscient – L'éternelle jeune contre la vieillesse avant l’âge – la liste des dissonances est trop longue à énumérer.",
        Craquer:"Malgré sa froideur et sa manière détachée, cet homme dégage de lui une certaine assurance et transpire la réussite, cela vous fascine. Mais vous aimeriez découvrir ce qui se cache derrière ce masque de fer. Vous avez  envie de vous amuser un peu avec lui, comme un chat avec une pelote de laine, réussir à le dérider en le titillant. Bien sûr, vous admirez le personnage pour ses qualités professionnelles et intellectuelles, mais qu’en est-il de l’homme ? Est-ce que la glace cache un volcan de passion ? Non, malheureusement, point de surprises, le cœur est gelé, son humour aussi d’ailleurs. La vie avec lui risque d’être ennuyeuse, bien  trop organisée pour vous qui êtes un électron libre… fuyez tant qu’il en est encore temps.",
        Coach:"Rencontre de l’insouciance et de la sagesse, une mayonnaise qui ne prendra probablement pas ou qui ne dépassera pas la période d’essai. Malgré la bouffée d’air frais qui allègera leur relation, le Capricorne s’agacera de tant de frivolités."
        } ,
    Gemeaux_Verseau :{
        Slogan:"“ Personnalités atypiques qui s’imbriquent comme des pièces d’un puzzle.”",
        LVA :"Que de belles perspectives d’avenir pour ce couple, chacun trouvant une stimulation d’idées novatrices chez l’autre, se faisant progresser et sublimant leur créativité. Tous deux signes d’air et donc cérébraux, vous avez en commun la recherche de la complicité intellectuelle. Seul hic, une harmonie très importante au niveau de l’esprit mais un gros bémol au niveau du physique ce qui vous éloignera à terme. Une passion qui deviendra vite une complicité amicale platonique si vous ni mettez pas plus de motivation.",
        Plus:"Une attirance et une osmose intellectuelle – La fusion des idées – Un lien fraternel et complice. ",
        Moins:"Le gémeau est un communiquant loquace alors que  le verseau a besoin de moments à lui, de recueillements – Le sexe est ensommeillé – Relation trop amicale.",
        Craquer:"Même si parfois vous le trouverez un peu trop suffisant, vous ne pourrez nier que vous avez trouvé votre maître dans l'art de la rhétorique. Vous ne pouviez d'ailleurs être séduite que par un esprit brillantissime. Avant-gardiste et visionnaire, il réveille votre créativité, vous inspire,  ce qui fait de lui votre mentor. Conseiller avisé, il saura vous mettre sur votre voie et vous aurez enfin l'impression qu'avec lui vous évoluez. Il vous apportera en culture, comme en réflexion. Comme vous, il aime côtoyer des relations très hétéroclites, car ce qui l'intéresse ce sont les échanges d'idées  et la convivialité des soirées entre amis. Et même si vous êtes parfois très différents dans la relation humaine, lui a besoin de moments de recueillements alors que vous, vous détestez le silence, vous devrez trouver des compromis satisfaisants pour l'un et l'autre.",
        Coach:"Une attraction instinctive lie ses deux esprits libres, brillants et originaux. Ils échangeront leurs idées et vivront une histoire d’amour atypique sans tabous où l’imprévu les préservera de la routine."
        } ,
    Gemeaux_Poissons :{
        Slogan:"“ L’agitation de l’un perturbe la quiétude de l’autre.  Incompréhension totale.”",
        LVA :"Entre vous, c'est l'incompréhension totale. Vous jouerez au chat et à la souris,  vous vous tournerez autour, vous vous chercherez sans réellement vous trouver. Votre relation pourrait bien rester à l’état du flirt sans jamais réussir à se concrétiser ou au mieux se transformer en relation purement érotique. Il faut dire qu’une bonne dose d’immaturité jette le trouble entre vous que tout oppose. Attraction et répulsion s’alternent, car votre vision de l’amour est bien trop différente.",
        Plus:"Le flirt -L’illusion d’amour – La rêverie amoureuse  - Une sensualité envoûtante.",
        Moins:"Le rêve ne se transformera pas en réalité – Le gémeau a du mal à comprendre le romantisme mièvre du poisson – Le poisson souffre dans cette relation.",
        Craquer:"Il vous touchera certainement par sa fibre artistique, son goût pour le cinéma, la musique, des sujets qui vous passionnent  et vous aurez un réel plaisir à partager avec lui  des moments festifs. Mais vous avez du mal à comprendre ces personnes esclaves de leurs sens qui vivent des replis sur eux-mêmes murés dans le silence lorsqu’ils sont submergés par des émotions trop fortes. Vous êtes une cérébrale qui a besoin d’échanges intellectuels et qui ne supporte pas ce manque de réactivité face aux évènements de la vie. Et puis vous n’avez pas pour vocation d’être sa thérapeute et n’avez ni l’envie ni le goût de régler ses complexes ni écouter ses lamentations.",
        Coach:"Relation extrêmement fluctuante et capricieuse, où ces natures très différentes se heurtent dans une incompréhension réciproque. Le Gémeau est très indifférent aux états d’âme du Poisson qui ne comprend pas cette méfiance à son égard. "
    } ,
    Cancer_Belier : {
        Slogan:"« Un pacifiste et une guerrière  auront du mal à trouver un terrain d’entente. »",
        LVA :"L’entente sera difficile car vos objectifs amoureux divergent vraiment. Le Bélier est indépendant et supporte mal l’attitude protectrice du Cancer. Le Bélier est insaisissable alors que le Cancer recherche à être rassuré, câliné. Le Bélier est blessant et le Cancer hypersensible. Vos univers amoureux sont trop différents, seule l’attraction physique peut permettre une durée à cette relation. Le Cancer sera séduit par les insistances du Bélier, mais déstabilisé par son  désintérêt aussi soudain.",
        Plus:"Une sexualité harmonieuse et brûlante remplie de fantasmes – Le bélier dominant et masculin rassurera le cancer plus féminisé et soumis.",
        Moins:"Le bélier aime le sexe pour le sexe, le cancer aime le sexe pour l’amour - Le bélier est trop agressif pour la sensibilité du cancer.",
        Craquer:"Vous aimez les vrais mecs, limite macho. Avec lui, vous serez servie et ce n’est pas pour vous déplaire. Mais sa brutalité vous dérange, son manque de délicatesse, de tendresse et ses manières de rustre heurtent votre sensibilité. Vous aimez les durs, certes, mais au cœur tendre.",
        Coach:"Certes, le Cancer a l’art et la manière d’exciter le Bélier grâce à un charme ravageur, mais son immaturité et ses caprices incessants, lasseront rapidement le Bélier, qui n’hésitera pas à aller voir ailleurs, déstabilisant au passage la confiance du Cancer. Relation sensuelle mais conflictuelle. "
    } ,
    Cancer_Taureau :{
        Slogan:"« Ils se complètent à merveille. Tendresse et réconfort sont les maîtres mots de cette relation d’amour. »",
        LVA :"L’association idéale pour fonder un foyer. Le Taureau recherche à construire un foyer sur des fondations solides (confort et matériel) et le Cancer ne conçoit pas l’amour sans la procréation et la construction d’une famille. La sécurité et la tendresse seront le leitmotiv de cette relation. Le sexe sera empreint de sensualité et de tendresse. Un seul bémol, l’hypersensibilité du Cancer pourrait bien  se confronter à la tyrannie du Taureau et à son entêtement. ",
        Plus:"La volonté commune d’une relation stable dans la construction pour l’avenir – Une relation basée sur des valeurs familiales simples.",
        Moins:"Relation sans fantaisie – Jalousies et caprices peuvent détruire cette histoire – Le Taureau manque de subtilité pour l’hypersensible cancer.",
        Craquer:"Vous trouverez auprès de lui le père idéal pour vos enfants et l’homme qui apaisera vos angoisses de petite fille. Protecteur, il vous donnera le confort matériel nécessaire à la réalisation d’un foyer heureux comme vous l’imaginiez. Mais ses excès d’autorité vous blesseront dans votre sensibilité et ses manières de plouc vous feront honte, vous si délicate. Mais si vous arrivez à lui inculquer les bonnes manières, il sera un compagnon parfait.",
        Coach:"La volupté et la sensualité seront le ciment de cette histoire. Tous deux de nature protectrice, sauront se rassurer mutuellement. Ils regarderont dans le même sens, dans l'envie de construire un foyer rempli d'enfants. "
    } ,
    Cancer_Gemeaux : {
        Slogan:"« Relation sur fond d’humour et de fantasmes. Mais quel est le plus menteur des deux ? »",
        LVA :"Deux signes jeunes, qui retomberont facilement en enfance. Vous vivrez votre histoire dans une osmose euphorisante, empreinte de rires et de farces. L’idéal pour vous deux, serait de vivre votre relation en union libre car votre vision du couple est bien trop différente. C’est pour cela que votre compatibilité est tantôt bonne tantôt mauvaise. Le  Gémeau extraverti et imprévisible va heurter  l’hypersensibilité et l'émotivité du Cancer. Le Gémeau s’épanouit totalement dans cette relation, alors que le Cancer risque d’en souffrir.",
        Plus:"Deux rêveurs ensembles – La passion dans la légèreté – Le retour en enfance, l’insouciance d’amour de jeunesse.",
        Moins:"La difficulté sera lorsqu’ils parleront de sérieux -L’un est indépendant l’autre pense à deux – L’instabilité du gémeau perturbe le cancer.",
        Craquer:"Vous serez subjuguée par cet esprit vif et brillant. Habile narrateur, il vous contera des histoires auxquelles vous aurez envie de croire, n’êtes-vous pas une Princesse qui cherche son Prince ? Mais très vite vous comprendrez que vous n’êtes pas la seule à qui il accorde ses faveurs et à qui  il compte fleurette.  Cette trahison vous blessera profondément, vous qui lui étiez totalement acquise. Vous vous êtes donnée corps et âme et exigez en retour un engagement total de sa part, après tout vous le valez bien... Mais ce goujat ne voit pas la chose de cet œil là et vous devrez vous rendre à l'évidence, il n'est pas le Prince que vous attendiez... ",
        Coach:"Entre ces deux signes, c’est l’insouciance, un amour juvénile, frais et enfantin qui manquera à la longue de stabilité et de consistance. L’instabilité du Gémeau déconcertera la quête permanente de protection du Cancer. "
    },
    Cancer_Cancer :{
        Slogan:"« Connivence sensorielle, vous n’avez pas besoin de parler pour vous comprendre. »",
        LVA :"Tous deux, sensibles et romantiques avez une envie commune de  construire un nid douillet dans une perspective d’un bonheur parfait.  Et pourtant, votre  hypersensibilité se transformera vite en hyper-susceptibilité, les rancœurs prendront vite la place du bonheur idyllique.  Chacun aura l’impression d’être le seul à se dévouer à l’autre, décortiquant  et analysant chaque acte ou parole. Le précieux temps de votre relation sera gâcher à ressasser des griefs futiles.",
        Plus:"Une relation douce et romantique – Une sexualité épanouie – Une volonté de construction affective.",
        Moins:"Une hyper-susceptibilité qui créera des conflits – Une immaturité qui desservira leurs projets d’avenir – Deux êtres capricieux.",
        Craquer:"Vous serez séduite par ce Chevalier au grand cœur prêt à s’asservir pour vous plaire et à se battre pour défendre votre honneur. La Princesse que vous êtes sera comblée de bonheur, car vous avez besoin de vous sentir protégée, vous qui avez une âme de petite fille fragile, enfin, par moment et quand vous le décidez… Et c’est à ce moment-là que vous ne supporterez pas ses humeurs chagrines, qui le paralysent dans une dépression déconcertante. Vous, vous avez besoin que votre homme soit fort et vaillant prêt à braver tous les dangers, or celui là vous le trouvez trop fragile, trop féminin.",
        Coach:"Une attraction magique dans une émotion à son paroxysme. Tel Narcisse, ces deux signes jumeaux aiment leur double et se plongent dans un monde merveilleux où l’amour romantique est roi. La triste réalité de la vie mettra fin à leur rêve illusoire."
    } ,
    Cancer_Lion :{
        Slogan:"« Relation de dominant et dominé. Si le cancer se soumet tout sera possible. »",
        LVA :"Dans ce genre de relation, le Cancer devra s’attendre à se soumettre totalement à la volonté et l’autorité de son seigneur et maître le Lion. Le Cancer, assez passif, peut supporter une telle situation de dominant-dominé et acceptera de s’effacer sous le joug du Lion au bénéfice de la vie de famille et des tâches quotidiennes du foyer, pendant que le Lion prendra du bon temps. Cependant, l’hypersensibilité du Cancer sera mise à rude épreuve face aux excès colériques de cet animal autoritaire.",
        Plus:"La docilité du cancer – La protection du lion –L’admiration sans failles du cancer.",
        Moins:"La fragilité nerveuse et émotionnelle du cancer – La brusquerie du lion – Les colères du lion –Les bouderies et autres vexations.",
        Craquer:"Vous serez séduite par ce beau mâle fier, à l'allure chevaleresque qui ressemble en tout point au Prince charmant des contes de fées qui ont bercés votre cœur d'enfant. Vous serez totalement soumise à sa volonté. Mais quelle déception de découvrir derrière ce masque de bonté, un tyran colérique qui ne supportera pas que vous puissiez  déroger à son joug, un orgueilleux autoritaire qui, condescendant, négligera votre sensibilité et vos besoins d'amour. Tous ces excès finiront par émousser votre admiration et votre amour.",
        Coach:"Rencontre sensuelle toute relative tant que les sujets sérieux ne seront pas abordés. L’autorité du Lion ne pourra convenir que momentanément au caractère capricieux du Cancer qui suffoquera sous ce despotisme blessant. "
    } ,
    Cancer_Vierge :{
        Slogan:"« Un attachement quasi maternel. Difficile de les décoller l’un de l’autre. »",
        LVA :"Ces deux signes ne pourront que s’entendre. Vous êtes tous les deux sensibles, réservés, pudiques, simples dans votre vision du couple et de l’amour désireux d’un bon confort et de la quiétude dans le foyer. Votre passion n’est pas débordante, mais sincère et rassurante, entre la réserve et le lâcher-prise. Seul bémol à ce charmant duo, les mensonges et autres divagations du Cancer que la Vierge pragmatique et réaliste aura du mal à supporter.",
        Plus:"La confiance est de mise entre ces deux signes méfiants – Ils regardent dans la même direction – Une envie de calme et de stabilité les anime.",
        Moins:"Les rêves fantasmagoriques du cancer se heurtent au réalisme de la vierge – Les mensonges du cancer – Les critiques et la rigueur de la vierge.",
        Craquer:"Il vous abordera avec tact et délicatesse, un vrai gentleman vous direz-vous ! Prévoyant et protecteur il vous gratifiera de petites attentions qui vous rendront heureuses et sera l’homme de toutes les situations,  réglant tous les tracas qui vous angoissent au quotidien. Mais vous lui reprocherez d’être avare de câlins,  de manquer d’imagination et de spiritualité, trop cartésien et pragmatique pour la rêveuse que vous êtes. Attention, à force de vous plaindre et d'en réclamer toujours plus, vous risquez aussi de tout perdre. Cet homme est défensif et ne supportera pas longtemps vos exigences de tous les instants.",
        Coach:"Une attraction toute relative au départ, avec une bonne dose de méfiance qui s’estompera au fil du temps pour laisser place à un amour profond. Les attentions de la Vierge seront récompensées par la dévotion amoureuse et sensuelle du Cancer. "
    } ,
    Cancer_Balance :{
        Slogan:"« Une love attraction spontanée mais fragile. Deux séducteurs savent se reconnaître !  »",
        LVA :"Pas de répulsions entre ces deux signes, mais de là à faire votre vie ensemble, il ne faudrait pas exagérer non plus. Diamétralement opposés vous aurez du mal à vivre autre chose qu’une relation purement érotique et éphémère. Mais vous apprécierez les moments de douceur que vous partagerez ensemble. Cependant, la Balance éternelle séductrice aime la vie mondaine et rechigne aux travaux ménagers à l’instar du Cancer qui aime sa maison et a plaisir à s’y enfermer. ",
        Plus:"Des moments de grâce sensuels – Le goût pour l’amour – Relation calme sans éclats.",
        Moins:"Une incompatibilité essentielle sur la vie de couple – La balance est mondaine et le cancer aime la famille.",
        Craquer:"Lorsque vous le rencontrerez, vous vous emballerez, ayant l’impression d’avoir enfin trouvé votre Prince charmant. Telle une jeune adolescente vous idolâtrerez votre nouvel amoureux avec lequel vous vivrez une véritable romance. Mais sa fâcheuse manie à jouer les jolis cœurs, vous mettra dans des états de désarroi que vous aurez du mal à contrôler, ne supportant pas son manque de respect évident pour votre amour. Refroidie, vous vous détournerez de cet amour illusoire, n'hésitant pas à vous jeter à corps perdu dans une nouvelle histoire afin d'effacer les humiliations subies. Une relative fidélité à l'amour ! ",
        Coach:"Attraction de ces deux romantiques qui cultivent le désir du grand amour. Des débuts enflammés ou chacun pensera avoir trouvé son âme-sœur, mais tôt ou tard, le conte de fées se transformera en sordide règlement de compte. Amour illusoire. "
    } ,
    Cancer_Scorpion :{
        Slogan:"« Une fascination envoûtante, une fusion des sens. Relation magique. »",
        LVA :"Vous êtes tous les deux animés et dirigés par vos intuitions et de fortes émotions. Vous vous accordez aussi bien sur le plan amoureux que sur le plan sexuel. Le Scorpion aime intensément, jalousement et exige une fidélité sans faille, ce que le Cancer n’aura pas de mal à lui consacrer, car cela fait partie de ses principes moraux. Le caractère malléable et soumis du Cancer s’adaptera parfaitement aux exigences et à la tyrannie du Scorpion. Seules les paroles blessantes du Scorpion pourraient ébrécher cette union magique.",
        Plus:"Des émotions transcendées – Une relation fusionnelle– Deux caractères qui se complètent – Une énergie sexuelle tantrique –Chacun trouve sa place.",
        Moins:"La tyrannie et la cruauté du scorpion risque de déstabiliser cette relation idyllique – La passivité du cancer agace le  dynamisme du scorpion.",
        Craquer:"Vous serez comme attirée malgré vous vers cet illustre inconnu au regard magnétique et à l'aura envoûtante. Sa fougue amoureuse vous emportera dans un amour total qui vous rassurera, car vous avez si peur de ne pas être aimée. Vous êtes connectés l'un à l'autre, mais cela n'évite pas les clashs, car vous êtes tous deux des natures jalouses et possessives, parfois même paranoïaques. Votre relation ne sera pas de tout repos dominée par les fluctuations de vos intuitions, mais l'amour sera profond.",
        Coach:"Une attraction irrésistible mue par de violentes émotions. Un lien intense où se mêlent fascination et tourments. Une relation ou le Cancer sera la  victime consentie et le Scorpion son tortionnaire. Un amour torturé où l’érotisme joue un rôle important. "
    } ,
    Cancer_Sagittaire :{
        Slogan:"« Des débuts prometteurs mais des envies trop divergentes pour que ça colle. »",
        LVA :"’Le Sagittaire, aventureux et voyageur,  alimente l’imaginaire du Cancer, doux rêveur, qui voit en cette relation l’ouverture vers un amour exotique et hors norme. Tout sera possible entre ces deux-là, à partir du moment où aucune discussion sérieuse ne sera entamée. Dans le cas contraire, notre aventurier des temps modernes n’hésitera pas 5 minutes à prendre la poudre d’escampettes. Le plaisir avant tout, mais pourquoi voudrait-il donc échanger sa vie de liberté pour une vie remplie d’obligations ? ",
        Plus:"Ils sont tous deux épicuriens- Ont leur tête remplie de rêves- L’imagination est au centre de leur vie – 	Ils aiment faire la fête.",
        Moins:"Le sagittaire aime être libre sans obligations alors que le cancer a besoin d’avoir une vie d’obligations – L’infidélité.",
        Craquer:"Lorsqu’il vous a raconté ses périples autour du monde, vous vous voyiez déjà dans les bras d’Indiana Jones. Le tableau posé, vous vous êtes donnée corps et âme à votre héros, sentant que vous aviez trouvé l’Homme sur lequel vous pourriez vous reposer, il est fort et ne craint personne et saura vous protéger de tout danger. Mais il a une fâcheuse manie d’esquiver les discussions sérieuses, dès que vous haussez le ton il claque la porte, vous n’avez aucune possibilité de discuter avenir avec lui et ça vous déstabilise craignant qu’il disparaisse pour des contrées lointaines.",
        Coach:"Une rencontre qui ne peut être que le fruit d’un heureux hasard galant. Leur peu d’affinités ne peut espérer beaucoup plus qu’un moment d’égarement. Le pétillant Sagittaire sera vite refroidi par les fluctuations émotionnelles du très angoissé Cancer. "
    } ,
    Cancer_Capricorne :{
        Slogan:"« Des différences qui ne nuiront pas à leur bien-être. La chaleur rassurante de l’amour cimentera la relation. »",
        LVA :"Malgré vos discordances, vous vous complètez parfaitement. Monsieur Capricorne méfiant de nature, masque sous son air austère un  manque affectif  qui le paralyse dans ses histoires de cœur. Grâce à votre sensibilité exacerbée, vous devinerez les attentes de tendresse de ce grand frustré et vous saurez le combler par votre douceur et vos attentions. De votre coté, vous vous sentirez protégée et rassurée par son honnêteté inébranlable. Cette relation s’inscrit dans la durée, seule leur nature pessimiste peut faire des dégâts.",
        Plus:"Ils se sont trouvés et comblent leurs manques respectifs – Ont tous deux besoin d’être rassurés. ",
        Moins:"Une sexualité timide – Le manque de tendresse et de romantisme du capricorne – L’hypersensibilité du cancer.",
        Craquer:"Avec lui point de surprises, il est carré, sérieux,  pas d’à peu près, tout est calculé, s’il vous aime et que vous correspondez à ses attentes amoureuses,  il s’engagera et pour longtemps. Il est rassurant et c’est un bosseur qui ne cherche pas une femme d’affaire, mais un bon second pour l’épauler. Un rôle qui vous colle à la peau, que demander de plus. !! S’occuper de sa maison, ses enfants et organiser les repas d’affaires de son petit mari chéri, la vie que vous avez toujours rêvée. Cependant,  si vous ne deviez avoir qu’une seule exigence, ce serait qu’il soit plus affectueux, plus démonstratif, mon Dieu que cet homme manque de chaleur, un vrai glaçon vous qui débordez d’amour. Allons ! Vous en aurez bien pour deux et qui sait, vous l’aurez peut-être à l’usure.",
        Coach:"L’alchimie entre ces deux signes n’est pas toujours évidente. Seule la persévérance du Cancer à percer la cuirasse défensive du Capricorne par de tendres attentions et une sensualité débordante, réussiront à développer un amour profond entre eux. "
        } ,
    Cancer_Verseau :{
        Slogan:"« Lorsque la passé s’affronte au futur, difficile de trouver un terrain d’entente. »",
        LVA :"Vos recherches amoureuses sont bien trop différentes pour que l’amour s’installe entre vous. Vous êtes une incurable romantique, qui désespère devant l’indifférence de Monsieur Verseau qui fuit le sentimentalisme \"marshmallow\". Intellectuel, cérébralisant ses sentiments, il a du mal à les verbaliser, alors que vous, très émotionnelle, avez besoin de vous épancher sur le sujet, ce qui ne tardera pas à étouffer l’indépendant Monsieur Verseau. Bien sûr votre magnétisme le séduira, mais pour si peu de temps...",
        Plus:"Le besoin de se rendre utile – L’humanisme.",
        Moins:"L’un est passéiste et l’autre avant-gardiste, l’un romantique l’autre réaliste –Tout les sépare.",
        Craquer:"Il est certain que lorsque vous avez fait sa connaissance, son assurance n'a pu que vous séduire,  vous qui avez tant besoin d'être réconfortée, d'être dans les bras d'un homme fort. Lui, dégage une telle puissance, subjuguant son assistance, il est passionnant et vous en êtes folle. Mais très vite l'admiration tournera à l'exaspération. Vous aurez l'impression d'avoir été leurrée et pourtant il ne vous a rien promis c'est sûr. Vous vous êtes forgée l'image d'un personnage  très éloigné de la réalité. Pas romantique pour deux sous, il exècre le sentimentalisme. Vous n'obtiendrez rien de lui car vous ne vivez pas dans le même monde... Ne perdez pas votre temps à fomenter quelconque stratégie pour le faire tomber dans vos filets car c'est perdu d'avance.",
        Coach:"Improbable compatibilité pour ces deux signes, dont les orientations de vie divergent à 180°, le Cancer est passéiste alors que le Verseau avant-gardiste. Seule leur fantaisie érotique peut à un moment donné les rapprocher, mais pour si peu de temps… "
        } ,
    Cancer_Poissons :{
        Slogan:"« Ils ondulent dans un monde fantasmagorique proche de l’extase. »",
        LVA :"Une attraction magnétique et sensorielle relie ces deux signes d’eau.  Intuitifs et affectueux, vous êtes sur la même longueur d’onde. Votre union sera basée sur un fort besoin de sécurité affective et sur une sexualité imaginative, avec une soif de sensations et d’abandon de soi. L’amour sera au centre de cette alchimie. Mais vous serez toujours à l’affût des réelles motivations Monsieur Poisson,  fuyant et refusant de se dévoiler totalement. Ce sera la seule ombre au tableau de cette fusion charnelle.",
        Plus:"Une alchimie des énergies – Un amour romantique dans la sublimation des sens et des énergies.",
        Moins:" Le manque de réalisme – La passivité – Ils vivent d’amour et d’eau fraîche.",
        Craquer:"Il a su vous faire succomber par ses mots et ses grandes déclarations. Vous avez l'impression de vivre un rêve de petite fille, serait-il votre prince ? Ce grand romantique sait sublimer une femme en la mettant en valeur,  en la glorifiant. De plus il n'a aucune pudeur à proclamer tout son amour. Et malgré tout cela, vous ne pouvez vous empêcher de suspecter une malhonnêteté, votre intuition ne vous trompe jamais et malgré cette complicité évidente qui vous unit, vous  doutez de sa sincérité. Ne serait-ce pas plutôt parce qu'il ne cède pas à tous vos désirs. Vous vous posez trop de questions et cela pourrait bien être fatal à votre attraction spontanée. Sa manière de louvoyer et d'éviter des réponses à vos questions, ne font qu'accentuer vos incertitudes.",
        Coach:"Des émotions exaltantes sont à l’origine de cette rencontre magique des âmes. Relation douce et poétique où les plaisirs et l’appel des sens jouent un rôle important dans la symbiose de cette union du corps et de l’esprit. Extase amoureuse guidée par leur sixième sens. "
    } ,
    Lion_Belier : {
        Slogan:"« Deux conquérants de l’amour, tous feux tout flamme se disputant l’autorité. »",
        LVA :"C’est l’amour fusionnel. Vous êtes dans une quête d’un amour idéalisé, où la passion est indissociable des sentiments. De nature généreuse, vous êtes deux narcissiques égocentriques et cela pourrait être le seul bémol à votre compatibilité, car vous avez besoin de briller en société et d’être le centre d’intérêt. Votre sexualité est débridée et l’attraction bien réelle. Cependant, vos natures autoritaires peuvent assombrir ce joli tableau ainsi que l’infidélité du Bélier que le Lion ne pardonnerait pas.",
        Plus:"Relation fusionnelle – Relation d’admiration mutuelle – Une volonté et une énergie constructive – Une sexualité passionnée.",
        Moins:"Une rivalité empreinte de jalousie peut s’installer entre ces deux personnages narcissiques – l’infidélité du bélier pourrait tout gâcher.",
        Craquer:"Il incarne pour vous le mâle viril et musclé qui saura gagner votre cœur. Fougueux, il vous fait chavirer dans une passion dévorante. C’est l’homme idéal, parfait sous tous rapports, sauf  lorsqu’il se transforme en coq de basse-cour, bombant le torse et  fanfaronnant au milieu de ses poules. Vous le trouvez alors, minable et ordinaire.",
        Coach:"Vous serez emportés par un tourbillon d’amour fou et de sexualité intense. Il suffira de vous regarder pour voir à quel point vous êtes épanouis. Seuls vos conflits d’autorité feront trembler les murs. « Les feux de l’amour ». "
    } ,
    Lion_Taureau :{
        Slogan:"« Deux fortes têtes incapables de concéder les rênes du couple à l’autre. »",
        LVA :"Les conflits d’intérêts et de pouvoir vont gâcher cette relation. Tous deux, très ambitieux, avec une grande soif de réussite, pourrez trouver un terrain d’entente constructif,  si vous arrivez à modérer votre  tendance à la domination. Vos signes, (Taureau/Lion) très masculins, sont portés sur la volonté de diriger,  et vous aurez bien du mal à communiquer, sauf sur l’oreiller où l’amour du sexe réussira la prouesse de prolonger le sursis de cette relation vouée à l’échec.",
        Plus:"La Franchise - Une volonté commune à la réussite – Une sexualité débridée mais peu imaginative.  ",
        Moins:"Guerre de pouvoir et d’ego – Rapports de force – Relation en manque de passion mais conflictuelle – Dialogue de sourds sur fond de mauvaise foi.",
        Craquer:"Savez-vous que l’ homme qui vous fait les yeux doux n’est autre que l’heureux  propriétaire d’un château dans le bordelais. Voilà qui correspondrait parfaitement aux ambitions que vous nourrissez en secret, châtelaine n’est-ce pas un rôle qui vous siérait à merveille ? Malheureusement votre caractère dominant créera un rapport de force qui  déjouera vos plans et découragera votre prétendant. De toute façon, ses remarques désobligeantes sur vos dépenses, cache un personnage près de ses sous et vous exécrez les radins. Alors sans regrets !",
        Coach:"Ces deux signes passionnés sauront se séduire par une cour enflammée et par des sorties dans les endroits les plus chics.  Une relation où l’égo se nourrit de l’attrait de l’autre, mais où les coups de gueule se transforment en de violents conflits. Relation où la violence et la domination trahiront un idéal superficiel. "
    } ,
    Lion_Gemeaux : {
        Slogan:"« Champagne ! Une complicité festive, joyeuse, c’est l’amour paillette. »",
        LVA :"Coup de foudre au premier regard, mais sur la durée, vos deux fortes personnalités auront du mal à se supporter. Les railleries et vexations du Gémeau auront raison de l’amour inconditionnel du Lion. Pourtant vos signes sont assez complémentaires  et s’entendent bien, mais trop égoïstes, vous vous disputerez la place de la vedette. Une relation qui ne sera pas de tout repos. La sexualité est épanouie, mais le Gémeau est un peu trop cérébral pour le Lion plus instinctif et animal.",
        Plus:"L’amour est fort et passionné – Le lion stabilise le gémeau -  La fantaisie – Le goût commun pour l’art et le spectacle.",
        Moins:"Les colères du lion sont redoutables et le gémeau attise la zizanie – Le sarcasme du gémeau agace et vexe le lion qui a besoin d’admiration.",
        Craquer:"Quelle homme charmant ! non pas tant par sa plastique, mais plutôt par ce qu’il dégage, par sa vivacité d’esprit. Il excelle dans l’art de la rhétorique et saura vous charmer de ses mots doux, louer votre beauté solaire et vous crier toute l’admiration qu’il vous porte. Mais attention, sa langue est acerbe et il pourrait parfois vous égratigner de ses sarcasmes blessants, et cela, vous ne lui pardonnerez pas. Rancunière vous ne supportez pas la critique et encore moins les railleries de ce saltimbanque dont les blagues douteuses ne font rire que lui. Mais pour qui se prend-il ?",
        Coach:"L'emballement réciproque entre l’air pétillant du Gémeau et le feu conquérant du Lion promet un amour ardent. Une admiration commune attise l’intensité de leur relation et stimule une passion déraisonnable sur fond de rivalité. "
    },
    Lion_Cancer :{
        Slogan:"« Relation de dominant et dominé. Si le cancer se soumet tout sera possible. »",
        LVA :"Dans ce genre de relation, le Cancer devra s’attendre à se soumettre totalement à la volonté et l’autorité de son seigneur et maître le Lion. Le Cancer, assez passif, peut supporter une telle situation de dominant-dominé et acceptera de s’effacer sous le joug du Lion au bénéfice de la vie de famille et des tâches quotidiennes du foyer, pendant que le Lion prendra du bon temps. Cependant, l’hypersensibilité du Cancer sera mise à rude épreuve face aux excès colériques de cet animal autoritaire.",
        Plus:"La docilité du cancer – La protection du lion – L’admiration sans failles du cancer.",
        Moins:"La fragilité nerveuse et émotionnelle du cancer – La brusquerie du lion – Les colères du lion – Les bouderies et autres vexations.",
        Craquer:"Vous avez tant besoin que l’on vous admire… et lui vous le fascinez. Il ne sait quoi faire pour vous faire plaisir et saura être l’esclave de vos moindres désirs. Mais son manque de virilité, sa fragilité nerveuse et ses bouderies incessantes vous lasseront rapidement. Ses états d’âmes émousseront l’affection que vous éprouvez pour lui et vous mettront dans des colères noires. Vous aurez l'impression d'avoir adopté un enfant alors que vous rêviez d'un chevalier sans peurs, prêt au combat pour sa belle. Non vraiment cet homme-là n’est pas fait pour vous.",
        Coach:"Rencontre sensuelle toute relative tant que les sujets sérieux ne seront pas abordés. L’autorité du Lion ne pourra convenir que momentanément au caractère capricieux du Cancer qui suffoquera sous ce despotisme blessant. "
    } ,
    Lion_Lion :{
        Slogan:"« Mariage glamour sous les feux de la rampe. Ces amoureux du faste vont en faire des envieux ! »",
        LVA :"Les conflits d’intérêts et de pouvoir vont gâcher cette relation. Tous deux, très ambitieux, avec une grande soif de réussite, pourrez trouver un terrain d’entente constructif,  si vous arrivez à modérer votre  tendance à la domination. Vos signes, très masculins, sont portés sur la volonté de diriger,  et vous aurez bien du mal à communiquer, sauf sur l’oreiller où l’amour du sexe réussira la prouesse de prolonger le sursis de cette relation vouée à l’échec.",
        Plus:"La Franchise – Une volonté commune à la réussite – Une sexualité débridée mais peu imaginative.  ",
        Moins:"Guerre de pouvoir et d’ego – Rapports de force – Relation en manque de passion mais conflictuelle – Dialogue de sourds sur fond de mauvaise foi.",
        Craquer:"Il vous éblouira par sa beauté, son panache et par la puissance virile qu'il dégage. Il est le héros que vous attendiez et ensemble vous vivrez un amour ardent empreint de démonstrations affectives et d'une sexualité effrénée qui vous transportera de bonheur. Mais jamais au grand jamais vous ne  renoncerez à vos ambitions ni à vos droits à l'égalité des pouvoirs au risque de lui déplaire et de provoquer de violents conflits destructeurs. ",
        Coach:"Leur attraction excessive et passionnelle est portée à contempler leur propre reflet dans une admiration réciproque. Mais attention, chacun voudra prendre le pouvoir et il ne peut y avoir qu'un seul roi, ce qui fixera les limites à cette histoire. Choc de deux fortes personnalités autoritaires. "
    } ,
    Lion_Vierge :{
        Slogan:"« La rencontre de l’extravagance  et de la sagesse ne font pas bon ménage. »",
        LVA :"Vos différences de tempéraments seront un réel handicap pour l'épanouissement d'une telle union. Le Lion a une personnalité excessive et extravagante avec le goût du faste alors que la Vierge est plus simple, calculant ses dépenses avec parcimonie. Elle sera d'autant plus dérangée voir agacée  par ces comportements déraisonnables. Dans ce couple le Lion aura bien entendu le beau rôle, alors que  la Vierge endossera le costume de la servante soumise à son dictât. Une histoire qui manque cruellement de passion.",
        Plus:"La vierge sait se rendre utile et le lion apprécie cette disponibilité - Le lion aime le perfectionnisme et la soumission de la vierge - La vierge aime la puissance et le caractère fort du lion.",
        Moins:"Les critiques acerbes de la vierge - Les extravagances du lion - Le manque de passion de la vierge frustre l'ardent lion -La vierge est inhibée, le lion est très sexuel.",
        Craquer:"Dans vos élans ardents et passionnés vous décoincerez cet amoureux timide, craintif de tout emportement non contrôlé. Vous aurez du mal à cerner son fonctionnement amoureux, mais ses nombreuses attentions vous rassureront sur la sincérité de ses sentiments. Vous admirerez  l’acuité de son esprit et sa finesse intellectuel, mais vous le jugerez bien trop restrictif et inhibé pour assumer vos extravagances et vos besoins de  grandeur. Vous vous ennuierez rapidement dans son univers étriqué, où vous aurez l'impression de revivre \"les misérables\". Une vision quelque peu excessive mais un mal-être certain qui ne peut que vous éloigner l'un de l'autre.",
        Coach:"Un couple que l’on ne rencontre pas couramment, où la méfiance est instinctive. La Vierge prudente, n’a guère confiance en ce présomptueux personnage. Le Lion souffre de la discrétion affective de la Vierge. Relation mi-figue mi-raisin. "
    } ,
    Lion_Balance :{
        Slogan:"« Alliance du luxe et de la beauté. Un amour cliquant qui brille de mille feux. »",
        LVA :"Votre histoire c'est un peu la rencontre de Vénus, déesse de l'amour et de la beauté et d'Apollon, Dieu de la musique et de la poésie, réputé pour son physique avantageux. L'attraction est immédiate et intense, prometteuse d'une entente parfaite, basée sur l'élan d'un amour enthousiaste et de goûts communs pour le milieu artiste et les joies de l'existence. Vous vous aimez d'une passion ardente et romantique. Votre sexualité sera harmonieuse, même si le Lion manquera parfois de finesse et de délicatesse.",
        Plus:"Goûts communs pour les arts, le luxe et les mondanités - L'envie d'aimer intensément en vue d'une relation sérieuse - Une sexualité épanouie.",
        Moins:"La légèreté de la balance rend méfiant le lion fidèle - L'oisiveté de la balance - Les colères noires du lion se heurtent à la sensibilité de la balance.",
        Craquer:"Cet homme au look dandy,  vous impressionnera par son élégance et ses gestes raffinés. Entouré d’une foule d’admiratrices, vous devrez vous démarquer et n’hésiterez pas à faire le premier pas. Votre spontanéité et la chaleur que vous déploierez à le séduire, finiront par le faire céder de bonne grâce bien sûr, à vos flatteries. Vous formerez \" le couple \" qui fera jaser dans le soirées branchées, beaux et élégants, vous orchestrerez chaque apparition telle une pièce de théatre.",
        Coach:"L'attraction est immédiate et dès les premiers instants partagés, ils découvriront leur sensibilité commune pour les mondanités et l’art.  Amour harmonieux. "
    } ,
    Lion_Scorpion :{
        Slogan:"« Difficile de concéder à l’autre la place du chef. Rapports de force passionnels. »",
        LVA :"Rencontre de deux forces de la nature, l'une diurne, avec un feu intérieur bouillonnant, et l'autre solaire, ardent et exubérant, c'est le choc des titans. Ces deux tyrans à la personnalité inflexible et au  manque d'adaptabilité vont se rendre une vie impossible.  Seule l'attraction sexuelle pourra vous mettre d'accord un certain temps et faire perdurer malgré tout  cette relation basée sur le rapport de force, sans concessions.",
        Plus:"Une  potentielle réussite pour une association professionnelle - Une sexualité intense assumée mais manquant de tendresse.",
        Moins:"Le scorpion pique le lion au vif - La violence de ces deux excessifs - Le scorpion est plus pervers et le lion trop direct - Rapports difficiles au pouvoir.",
        Craquer:"Vous serez exaltée par la conquête de cet être hautement mystérieux, jouant les indifférents et misant sur la résistance. Mais vous ne vous laisserez pas démotiver pour si peu, vous aimez la conquête difficile,  et avec lui vous aurez trouvé un adversaire coriace à la hauteur du défi. Vos deux caractères forts et dominateurs mettront le ton d'une relation certes captivante mais épuisante à long terme, vous donnant l'impression d'être sur un ring de boxe même dans vos moments les plus intimes.",
        Coach:"Attraction animale entre deux personnalités dominatrices. Leur relation est surtout passionnellement érotique sur fond de torture mentale. L’intensité sexuelle n’est qu’un instrument pour prendre le pouvoir de l’un sur l’autre. Relation destructrice."
    } ,
    Lion_Sagittaire :{
        Slogan:"« Relation ardente et conquérante, une aventure passionnante et grisante. »",
        LVA :"Rencontre de deux natures enthousiastes et généreuses, enclines à jouir pleinement de la vie. Votre relation sera basée sur la spontanéité, la sincérité et la franchise. Ce duo de feu, aux goûts de luxe et aux dérives excessives auront une complicité à toute épreuve, basée sur les plaisirs à deux. Votre sexualité est gourmande et fougueuse. Seule divergence, le Lion se complet à créer un nid confortable et fastueux alors que le Sagittaire rêve de parcourir le monde ne s’attachant pas à un lieu fixe.",
        Plus:"Leur nature passionnée et sincère – Épicuriens et jouisseurs ils partageront le plaisir des fêtes et des soirées bien arrosées.",
        Moins:"Leurs colères sont dévastatrices – Ils sont tous deux très orgueilleux – L’autorité du lion se heurte à l’indépendance du sagittaire.",
        Craquer:"Vous l’avez remarqué car il était entouré de groupies suspendues à ses lèvres, écoutant ses histoires de safari photo en Australie. Vous saurez susciter son intérêt, d’ailleurs qui pourrait résister à votre charme éblouissant ? Vous vivrez avec lui une des plus belles histoires d’amour enflammées que vous n’avez jamais vécu, il vous fera découvrir des lieux insolites et  vous côtoierez la Jet Set. Mais il aura parfois des comportements de rustre et manquera de délicatesse avec vous, pouvant être autant généreux et attentionné qu’égoïste et absent, et cela vous mettra dans des rages folles.",
        Coach:"Joli couple de signes de feu, formé dans des élans enthousiastes d’une passion ardente et joyeuse. Une complicité motivée par des sentiments nobles et entiers dont il faudra soustraire toute compétition pour éviter de tout gâcher. "
    } ,
    Lion_Capricorne :{
        Slogan:"« Les emballements du Lion se heurteront à un mur froid d’indifférence. Dur, dur ! »",
        LVA :"Difficile de trouver un terrain d’entente. Vos personnalités sont diamétralement opposées. C’est la rencontre du feu et de la glace. Vous êtes exubérante, optimiste et théâtrale, vous adorez la provocation et les excès en tout genre, alors que le Monsieur Capricorne n’aspire qu’au calme, à la solitude et déteste le clinquant, les mondanités, qu’il qualifie de frivolités inutiles. Seules vos ambitions et votre goût du pouvoir pourront vous relier, mais pourront aussi vous confondre.",
        Plus:"L’amour de l’argent et du pouvoir – le capricorne canalisera les dépenses excessives et superflues du lion – le lion dégèlera son partenaire capricorne et lui apportera une dose non négligeable d’optimisme.",
        Moins:"L’amour de l’argent et du pouvoir – le capricorne canalisera les dépenses excessives et superflues du lion – le lion dégèlera son partenaire capricorne et lui apportera une dose non négligeable d’optimisme.",
        Craquer:"Cet homme transpire la réussite et le pouvoir et ce n’est pas pour vous déplaire, vous l’aurez certainement rencontré lors d’un dîner de hauts dignitaires et son charisme vous a séduit. Mais difficile de savoir ce que lui attend d’une femme, en tout cas l’épouse soumise cantonnée aux tâches ménagères ce n’est pas pour vous. Vous, vous rêvez de faste, de lumières, vous aimez vivre intensément votre vie alors que lui est étriqué dans son costume trois pièces comme coincé dans ses principes moralisateurs. La restriction n’est pas faite pour vous et personne ne vous dictera de loi, vous avez besoin de vous épanouir et non de vous flétrir.",
        Coach:"Étant l’un et l’autre en recherche d’une personne au-dessus de la moyenne, ils s’attireront grâce à une admiration réciproque surtout s'ils ont un statut social élevé, mais la difficulté sera de trouver leur place au sein du couple, le pouvoir ne pouvant être partagé. "
        } ,
    Lion_Verseau :{
        Slogan:"« Une Complicité évidente, tous deux aiment les êtres intelligents et brillants. »",
        LVA :"Entre vous, c’est la loi de l’attraction et de la répulsion , c’est parfois la dure règle pour les signes astrologiques opposés qui peuvent vivre leur histoire soit dans la complémentarité soit dans l’antagonisme. Dans tous les cas, vous ne vivrez pas une relation édulcorée, ni banale. Placée sous le signe de l’impétuosité, vous partagerez le goût pour le monde, les relations amicales et les échanges animés. Mais vos divergences de caractères froisseront votre orgueil et votre possessivité et vous aurez bien du mal à attacher le libertin Monsieur Verseau.",
        Plus:"Vos esprits sont brillants et tous deux aimez vous cultiver et polémiquer – La passion amoureuse – Vous avez une vie sociale bien remplie.",
        Moins:"Vous êtes tous deux très individualistes voir égoïstes – Le Lion ne supporte pas l’indépendance du Verseau – Le Verseau exècre l’autoritarisme du Lion.",
        Craquer:"Une telle attirance peut être qualifiée de fascination, entre vous c'est magnétique, comme deux aimants vous vous attirez  mais inéluctablement, vous vous repoussez. Vous aimez cette originalité qui le caractérise, intelligent et cultivé il sait captiver l'attention, et on ne peut que le remarquer car il sort de l'ordinaire, d'ailleurs un de ses chevaux de bataille c'est d'être anti-conventionnel et ne pas rentrer dans le moule de la société. Tout pourrait fonctionner parfaitement entre vous, si vous ne le trouviez pas trop égocentrique et pas assez attentionné. Vous ne pouvez pas comprendre qu'il puisse oublier de vous appeler ou arriver en retard à un de vos rdv. Comment peut-on aimer sincèrement et avoir de tels comportements irresponsables et de ne pas en faire état ?",
        Coach:"Une attraction physique irrésistible motivée par une fascination réciproque. Une passion aveugle qui se soldera par un échec cuisant en raison de leurs caractères diamétralement contradictoires et un manque de souplesse. Attirance-Répulsion."
        } ,
    Lion_Poissons :{
        Slogan:"« Un idéal amoureux qui aurait pu s’accorder si leurs ambitions n’étaient pas si divergentes. »",
        LVA :"DEntre vous, difficile de trouver une alchimie. Vous évoluez dans des mondes parallèles où l’incompréhension rendra impossible toute relation harmonieuse. Bien sûr il peut y avoir un coup de foudre, mais vous qui êtes si impétueuse, franche et directe, serez déconcertée par ce Poisson doux rêveur et insaisissable.  Même s'il ne contestera jamais votre autorité, ses non-dits et ses faux fuyants vous mettent mal à l’aise dans la confiance qui vous pourriez accorder à ce nébuleux Poisson.",
        Plus:"Le poisson soumis ne sait que faire pour plaire au lion majestueux et autoritaire - Le lion apprécie l’amour inconditionnel que lui porte le poisson.",
        Moins:"La passivité et l’abnégation du poisson – Les colères agressives du lion et ses paroles blessantes.",
        Craquer:"Ce troubadour des temps modernes vous voue une admiration sans bornes, vous criant son amour. Dévoué corps et âmes, il ne sait que faire pour vous prouver son attachement. Telle une déesse vous adorez vous laisser porter par la fascination que vous suscitez, n'est-ce pas flatteur d'être adulé ? Sa sensualité raffinée n'est pas pour vous déplaire, car généreux de ses caresses, il sait vous épanouir pleinement. Mais le rêve ne durera jusqu'à ce que vous vous rendiez compte que sa profonde nature est faible et pessimiste. Sa trop grande part de féminité frustre vos besoins d'homme viril et vous ne comprenez pas ses états d'âme, son laisser-aller et son manque d'ambitions. Vraiment il n'est pas fait pour vous, vous n'avez pas les mêmes valeurs.",
        Coach:"Une attraction romanesque et sensuelle qui ne durera que le temps d’une étincelle. Une alchimie difficile pour ces deux personnalités évoluant dans des univers très différents. La nébulosité du Poisson met le Lion dans des rages folles. "
    } ,
    Vierge_Belier : {
        Slogan:"« La Vierge, méfiante, n'est pas convaincue par les démonstrations d'amour du Bélier »",
        LVA :"Un couple dans une totale incompréhension du fonctionnement de l’autre. La Vierge farouche et analytique a du mal à comprendre le fougueux Bélier qui part au galop et réfléchit plus tard. La Vierge a besoin d’être rassurée pour se lâcher alors que le Bélier se jette à corps perdu dans l’amour. La Vierge aura tendance à refroidir les élans du Bélier, ce qui risque de le faire fuir rapidement. Cependant le Bélier, par ses insistances, peut réussir à décoincer cette amoureuse prudente qui deviendra \"vierge folle\".",
        Plus:"Les grandes discussions philosophiques – Jouer au chat et à la souris – Dévergonder cette nature réservée et laisser s’exprimer une passion insoupçonnée.",
        Moins:"L’instabilité du bélier et le besoin de sécurité de la vierge – Le bélier s’ennuie avec la Vierge et il ne supporte pas ses critiques. ",
        Craquer:"Ce bellâtre va vous subjuguer par son enthousiasme amoureux, il saura vous réchauffer et vos réticences s’évanouiront laissant s’exprimer la vierge dévergondée qui est en vous. Mais ses sautes d’humeur et son besoin maladif de plaire auront tôt fait de vous refroidir. Vous n'avez décidément pas les mêmes valeurs !",
        Coach:"La séduction tenace et persévérante du Bélier, ne sera pas pour déplaire à la Vierge, réservée et en manque de confiance en elle. Le Bélier aime les défis et les murailles infranchissables, mais se projeter dans un avenir commun, pour la Vierge il n'en est pas question. "
    } ,
    Vierge_Taureau :{
        Slogan:"« Un accord parfait pour ces deux terriens qui aspirent à la quiétude d’un foyer. »",
        LVA :"Rencontre de deux solides Terriens. Vous aimerez couler des jours heureux sous le signe de la stabilité et de la sécurité. Le Taureau sait gagner l’argent que la Vierge saura thésauriser en vue de l’achat de leur douillette demeure et d’investissements immobiliers pour leur belle retraite. Vous saurez avancer mains dans la main, comme deux bons gestionnaires. Une vie bien orchestrée  avec un  manque évident de piment et de fantaisie. Une sensualité en harmonie sans surprises.",
        Plus:"Goût pour la stabilité – La confiance – La droiture – Tous deux sont des laborieux – Épicuriens – Ils aiment les choses simples et vraies",
        Moins:"Manque de passion évident – Vie ennuyeuse et rigide – Certains taureaux sont infidèles et la vierge est assez constante et droite.",
        Craquer:"Ce grand costaud a tout pour vous plaire, solide et travailleur, protecteur et affectueux, bon gestionnaire et réaliste. Il vous comblera de ses attentions, rassurera vos doutes et saura réveiller votre libido. Entre vous c’est le bonheur tranquille et sécurisant malgré ses colères de bougon rancunier dont vous vous accommoderez. Ses qualités d'homme suffiront à vous convaincre de l'harmonie qu'un tel homme pourrait vous apporter.",
        Coach:"Le Taureau amènera la sécurité financière dont la Vierge a besoin. La Vierge enchantera le Taureau par son dévouement et ses attentions. Une relation de connivence, bien huilée teintée d'un érotisme lascif. Relation basée sur du solide. "
    } ,
    Vierge_Gemeaux : {
        Slogan:"« Leur cérébralité sera le piment de cette histoire . Une relation toute Mercurienne. »",
        LVA :"Tous deux Mercuriens, si la Vierge est dite « folle », vous vous entendrez comme deux larrons en foire, avec des affinités particulièrement intellectuelles, vous aimerez discuter, échanger, jouer ensemble. Sexuellement, vos jeux érotiques seront plutôt cérébraux, scénarisés. Cependant, la relation risque de s’essouffler par manque de piment, le Gémeau, assez dispersé, déstabilise la Vierge organisée et l’agace par son manque de cohésion et de constance.",
        Plus:"Une complicité intellectuelle presque fraternelle – Ils se comprennent et ont les mêmes jeux – La créativité du gémeau est matérialisé par la Vierge.",
        Moins:"La nervosité du gémeau perturbe le calme de la vierge – La vierge risque à la longue d’ennuyer le gémeau.",
        Craquer:"Il sortira le grand jeu, et vous l’observerez d’un œil amusé. Pourtant il y a chez ce zigoto, une intelligence hors du commun et une dose importante de psychologie qui lui a permis de vous percer à jour et faire tomber vos barrières de protection. Sa finesse d’esprit et son talent oratoire ont eu raison de toutes vos réticences, vous êtes intriguée, séduite. Mais ce survolté du bocal vous dérange dans votre quiétude et vous avez du mal à le suivre dans ses délires. Vous qui aspirez au calme, avec lui vous aurez du mal à souffler.",
        Coach:"Une attraction érotico-intellectuelle où l’esprit est en effervescence, aiguisée par la curiosité et les joutes verbales. Ils se plaisent, d’abord intrigués par leur vivacité d’esprit et admirent leur talent. Mais attention, leur ironie acerbe et l'irritation que cela peut provoquer risque de tout gâcher. "
    },
    Vierge_Cancer :{
        Slogan:"« Un attachement l’un à l’autre quasi maternel. Difficile de les décoller l’un de l’autre.»",
        LVA :"Ces deux signes ne pourront que s’entendre. Vous êtes tous les deux sensibles, réservés, pudiques, simples dans votre vision du couple et de l’amour désireux d’un bon confort et de la quiétude dans le foyer. Votre passion n’est pas débordante, mais sincère et rassurante, entre la réserve et le lâcher-prise. Seul bémol à ce charmant duo, les mensonges et autres divagations du Cancer que la Vierge pragmatique et réaliste aura du mal à supporter.",
        Plus:"La confiance est de mise entre ces deux signes méfiants – Ils regardent dans la même direction – Une envie de calme et de stabilité les anime.",
        Moins:"Les rêves fantasmagoriques du cancer se heurtent au réalisme de la vierge – Les mensonges du cancer – Les critiques et la rigueur de la vierge.",
        Craquer:"Malgré ses besoins intenses d’être materné, il saura être une épaule solide et vous prendre en main, protecteur il apaisera votre phobie de l’insécurité et  comblera votre manque affectif par sa délicatesse. Mais sa sale manie à s’inventer une vie ou à partir dans des délires fantasmagoriques mettent à mal à votre lucidité et à votre droiture. Ce travers de sa personnalité contrariera la confiance que vous lui accordiez et remettra en question votre histoire. Vous ne pourrez vous projeter avec un homme qui n'a pas le courage de ses défauts, et qui de surcroît, se veut moralisateur alors qu'il affectionne tous les interdits.",
        Coach:"Une attraction toute relative au départ, avec une bonne dose de méfiance qui s’estompera au fil du temps pour laisser place à un amour profond. Les attentions de la Vierge seront récompensées par la dévotion amoureuse et sensuelle du Cancer. "
    } ,
    Vierge_Lion :{
        Slogan:"« La rencontre de la sagesse et de l’extravagance  ne font pas bon ménage. »",
        LVA :"Vos différences de tempéraments seront un réel handicap pour l'épanouissement d'une telle union. Le Lion a une personnalité excessive et extravagante avec le goût du faste alors que la Vierge est plus simple, calculant ses dépenses avec parcimonie. Elle sera d'autant plus dérangée voir agacée  par ces comportements déraisonnables. Dans ce couple le Lion aura bien entendu le beau rôle, alors que  la Vierge endossera le costume de la servante soumise à son dictât. Une histoire qui manque cruellement de passion.",
        Plus:"La vierge sait se rendre utile et le lion apprécie cette disponibilité - Le lion aime le perfectionnisme et la soumission de la vierge - La vierge aime la puissance et le caractère fort du lion.",
        Moins:"Les critiques acerbes de la vierge - Les extravagances du lion - Le manque de passion de la vierge frustre l'ardent lion - La vierge est inhibée, le lion est très sexuel.",
        Craquer:"Son aisance et son aura de gagnant vous donneront envie de rencontrer cet homme hors du commun. Vous qui êtes si réservée et qui manquez d'assurance, vous sentirez quil pourrait vous prendre sous son aile et vous épanouir. Mais c'était sans compter sur les travers, les extravagances, la tyrannie,  les provocations, les coups de gueule et le mépris de cet hurluberlu qui vous rendront la vie infernale et impossible. Difficile de conserver un semblant de calme avec ce volcan qui rugit pour un oui ou pour un non. Il vous perturbe et vous ne comprenez pas ses gesticulations intempestives et son besoin  grandiloquent d'attirer l'attention.",
        Coach:"Un couple que l’on ne rencontre pas couramment, où la méfiance est instinctive. La Vierge prudente, n’a guère confiance en ce présomptueux personnage. Le Lion souffre de la discrétion affective de la Vierge. Relation mi-figue mi-raisin. "
    } ,
    Vierge_Vierge :{
        Slogan:"« Pas de mauvaises surprises avec son jumeau astral, tout est carré, sans  faux-semblants. »",
        LVA :"L’attirance de ces deux Vierges sera basée surtout sur leur conception identique de la vie.  Ne laissant pas la place au hasard afin de minimiser vos angoisses de l’imprévu, vous saurez vous épauler et planifier au mieux les tâches du quotidien. Mais vous vous enfermerez très vite dans une relation insipide et prévisible où la passion sera totalement absente. La Vierge a besoin d’un partenaire  enflammé pour se lâcher et lorsque vous aurez épuisé toutes vos réserves de curiosité intellectuelle, cette relation  ne perdurera pas.",
        Plus:"Ils se comprennent et savent ce dont l’autre a besoin – Les loisirs culturels et intellectuels – Les longues discussions au coin du feu - Leur bonne gestion des finances. L’un rassure l’autre et vice-versa.",
        Moins:"Tous deux réservés, ils auront du mal à faire le premier pas – Le manque de passion – Une sexualité Inhibée et prude.",
        Craquer:"Il comprendra instinctivement vos attentes, détectant vos moindres désirs et sachant apaiser vos peurs. Vous vous sentirez en sécurité auprès de lui et cela calmera vos angoisses sur l’avenir. Mais cet homme austère, aux longs silences évocateurs parfois bien plus blessants que des mots,  et son manque de générosité affective, ne réussira pas à combler votre manque d’amour et heurtera votre sensibilité à fleur de peau. Une incompréhension risque alors d'installer un mur de silence entre vous où ni l'un ni l'autre ne parviendrez à régler cet éloignement inévitable. Cette faille dans le dialogue mettra en péril votre idylle.",
        Coach:"Une attraction timide pour ces jumeaux astraux, réservés dans les domaines de l’amour. Leur entente sera bonne, mais manquera cruellement de piment, la passion sera bannie et la maîtrise régnera en maître. Une histoire qui risque de vite devenir ennuyeuse. "
    } ,
    Vierge_Balance :{
        Slogan:"« Comment rigueur et oisiveté peuvent-elles cohabiter. En fait, vous ne vous supportez pas. »",
        LVA :"La magie de l’attraction entre ces deux signes ne durera pas bien longtemps, juste  le temps que la Vierge se rende compte des travers  cachés de cette charmeuse, aimable, mais dissimulatrice  et superficielle Vénusienne. La Balance, elle  se sentira offensée par l’analyse critique, mais non moins réaliste de sa personnalité par cette Vierge froide qu’elle qualifiera d’intolérante et d’insensible.",
        Plus:"Les échanges culturels – Le raffinement – La vierge organisée et bonne gestionnaire tempère les dépenses excessives de la balance.",
        Moins:"Le manque de passion – La superficialité et l’infidélité de la balance éloigne la vierge – La rigueur de la vierge et sa retenue amoureuse déplaisent à la chaleureuse balance.",
        Craquer:"Vous êtes tous deux en quête de l’idéal amoureux. Perfectionniste mais peu sûre de vous,  vous serez flattée d’intéresser un tel homme, raffiné, galant et séduisant. Par sa chaleur affective il saura vous mettre à l’aise et vous fera prendre conscience de vos richesses  intérieures. Vos serez épanouie dans ses bras, mais vous ne perdrez pas votre lucidité. Vous sentirez que cet homme est un amoureux de l’amour et que son amour ne vous est pas exclusif. Vous avez tant besoin d'être rassurée que sa nature volage met à mal votre sérénité et l'harmonie de votre couple.",
        Coach:"Une attraction progressive pour ces deux signes discrets qui recherchent le bien-être amoureux. Ils prendront leur temps pour se laisser aller à l’amour, désirant approfondir la connaissance de l’autre. Une amitié amoureuse tiède, mais sincère. "
    } ,
    Vierge_Scorpion :{
        Slogan:"« Couple très harmonieux où l’efficacité et la sincérité sont de rigueur. »",
        LVA :"Il semblerait que le Scorpion soit né pour charmer la méfiante Vierge et la faire tomber dans son piège amoureux. Comme si le Scorpion possédait de façon innée, le mode d’emploi pour percer votre cuirasse. Il faut dire qu’il usera et abusera de son magnétisme envoûtant pour vous libérer de vos tabous intellectuels et physiques, faire tomber toutes vos barrières de résistance pour vous rendre folle d’amour, esclave de vos sens et vous asservir.",
        Plus:"Leur acuité intellectuelle – Ils se comprennent et savent s’apprivoiser – Le scorpion révèle la nature cachée de la vierge – La vierge est soumise au dominant scorpion.",
        Moins:"Les excès destructeurs du scorpion révoltent la vierge soumise – Le sadomasochisme.",
        Craquer:"Lorsque vous croiserez son regard, vous ressentirez une alerte de danger imminent. Il vous traquera sans répit, vous deviendrez sa proie. Fin psychologue, il saura vous rendre addictive,  esclave de vos sens et vous soumettra à ses moindres désirs. Il saura vous épanouir à condition de ne pas rentrer dans des excès de perversité qui vous dévasteraient. Votre seule  ambition,  apaiser son âme tourmentée et acquérir sa reconnaissance enflammée.",
        Coach:"Le Scorpion a incontestablement les qualités requises pour neutraliser les défenses de la méfiante Vierge qui sera subjuguée par son charme magnétique. Leur vivacité d’esprit et la pertinence de leurs observations les attireront comme un aimant. "
    } ,
    Vierge_Sagittaire :{
        Slogan:"« Désordre et rigueur n’ont jamais fait bon ménage ! l’un range l’’autre dérange. »",
        LVA :"Vos différences de nature sont tellement évidentes qu’il sera difficile pour vous de trouver un terrain d’entente. La méticulosité et l’ordre de la Vierge agacent le désordonné Sagittaire. L’esprit d’aventure et d’improvisation du Sagittaire déstabilise la prudente Vierge qui planifie tout à l’avance, évitant toutes surprises qui pourraient l’angoisser. Cependant, la bonne nature enthousiaste du Sagittaire pourrait bien vouloir faire découvrir des horizons insoupçonnés à cette frileuse peureuse Vierge, mais pas moins curieuse et spirituelle.",
        Plus:"Relation dans la curiosité intellectuelle et philosophique de la vie – Tous deux aiment se cultiver et apprendre - Ils sont tous deux épicuriens.",
        Moins:"Des natures diamétralement opposées – Leurs défauts les dérangent et peuvent les amener à se détester.",
        Craquer:"Ses récits de voyages autour du monde vous font rêver et suscitent chez vous une excitation à laquelle vous n’êtes pas habituée, ce qui n'est pas pour vous déplaire. Avec lui vous vous redécouvrez et laissez s’exprimer une passion que vous réprimiez pourtant depuis longtemps. Mais ses tergiversations, sa désorganisation et son instabilité, vous perturbent dans votre vie bien rangée, vous avez besoin d’y voir clair pour pouvoir vous épanouir et lui est ingérable. Vous êtes sur le qui-vive sans arrêt et craignez le pire, il ne vous rassure pas et vous n’avez aucune confiance en lui. L’excitation des débuts laisse vite place à une angoisse qui tiraille vos entrailles ce qui  mettra fin à cette histoire improbable.",
        Coach:"Il faudra toute l’exubérance et l’enthousiasme du Sagittaire pour conquérir notre prudente Vierge. Mais leurs caractères antinomiques risquent fort de vouer très rapidement à l’échec cette alliance de l’ordre et de l’éparpillement. Passion éphémère. "
    } ,
    Vierge_Capricorne :{
        Slogan:"« Voilà du solide ! Entente et respect mutuel pour une vie paisible sans surprises. »",
        LVA :"Rencontre de deux signes de Terre stables. Vous recherchez tous les deux un partenaire sérieux et fiable, capable de satisfaire vos exigences amoureuses complexes et les contingences de la vie quotidienne. Les emportements aveugles ne font pas partie de vos personnalités, car  vous avez besoin tous deux d’évaluer minutieusement votre prétendant avant de vous laisser aller aux tourments de l’amour. Un sentiment d’estime et de respect vous rapprochera avant de vaincre vos retenues et de laisser transparaître vos sentiments.",
        Plus:"Vous vous apportez mutuellement des garanties de fiabilité qui vous rassurent – Vous êtes de bons partenaires pour créer un couple ou monter des affaires.",
        Moins:"Le manque de passion – L’absence de surprises.",
        Craquer:"Cet homme austère aux allures de bureaucrate, vous amuse par ses comportements coincés presque gauches, mais il vous touche, et vous vous dites que tant de froideur ne peut cacher qu’une nature bien plus affective qu’elle n’y paraît. C’est tout vous ! Avoir besoin d'aller au-delà des apparences et analyser chaque détail pour vous faire une idée objective et sans préjugés de l’individu qui tente de vous séduire, bien maladroitement je vous l’accorde. Vous auriez pourtant besoin d’un passionné qui vous emporte dans ses élans d’amour, mais la folie ce n’est pas votre truc, vous avez besoin d’un homme solide qui apaise vos doutes qui saura par ses  actes, vous conforter dans vos choix.",
        Coach:"Leur aspiration à trouver un partenaire sérieux attireront spontanément ces deux signes de terre l’un vers l’autre. Les joies de l’amour ne se libéreront qu’une fois les exigences du cœur et de la vie pratique satisfaites. Sentiments d’estime et d’amour profond. Cependant manque de Passion."
        } ,
    Vierge_Verseau :{
        Slogan:"« Absence rime parfois avec indifférence. Difficile de créer une connivence avec un tel handicap.  »",
        LVA :"Vous formerez un couple tout à fait improbable. L’alliance d’une Vierge méthodique et conventionnelle et d’un Verseau rebelle et anticonformiste ne pourra que se confronter  à une incompréhension de sourd et muet. Vous trouverez cependant un terrain de jeu sur des sujets intellectuels qui stimulera une belle amitié amoureuse, mais vos manies à tout prévoir et votre  extrême prudence s'opposant à l’insouciance et à la désinvolture de Monsieur Verseau auront raison de votre patience et du peu d’intérêt que vous vous accordez. ",
        Plus:"L’engouement intellectuel – Le verseau admire l’intelligence de la vierge et la vierge est subjuguée par ce bel orateur.",
        Moins:"Les absences du verseau blessent la sensibilité vierge qui attend de l’attention de son partenaire – Conformisme s’affronte à Anticonformisme. Le verseau est un rebelle alors que la vierge est très disciplinée.",
        Craquer:"Séduite par ce grand intello, aux théories réactionnaires, vous ne vous lasserez pas de l'écouter et d'échanger vos points de vue, certes il a tendance à faire des monologues, mais son intelligence vous a  conquise. Comme vous, il est assez réservé sur l'expression de ses sentiments, mais la complicité qui vous unit, suffit à vous rassurer sur ses intentions. Grande amoureuse, vous rêvez pourtant du grand amour et cachez derrière votre timidité, une passion dévorante. Vous aurez du mal à laisser s'exprimer cet amour qui vous consume car ce cérébral refrène ses pulsions et ne vous permettra pas de passer à l'action. Frustrée, vous ne pourrez vous contenter dans cette histoire que d'une  profonde et réelle connivence.",
        Coach:"Une association tout à fait improbable. Seule une curiosité intellectuelle pimentera leurs échanges et pourra donner une illusion d’amour, mais leur différence de fonctionnement créera rapidement des tensions irrémédiables."
        } ,
    Vierge_Poissons :{
        Slogan:"« Une relation timide mais où l’on s’apporte mutuellement du bonheur. »",
        LVA :"Vous vivrez l’attirance et la répulsion comme deux aimants. Le Bélier est un tentateur qui n’aime pas qu’on lui résiste, la Balance elle, a du mal à résister à la tentation et succombera à ses assauts  insistants. La Balance, raffinée et oisive, séduisante et charmeuse, va envoûter cet incorrigible chasseur. La Balance saura canaliser les emportements du Bélier par sa diplomatie. Leurs étreintes seront explosives et le Bélier saura révéler les désirs profonds de la Balance. ",
        Plus:"La douceur du poisson attendrit la vierge méfiante – Le poisson met du rêve dans la vie trop pratique de la vierge – La vierge apporte son réalisme pragmatique au rêveur poisson.",
        Moins:"Le poisson est parfois menteur et infidèle car il a un cœur d’artichaut – La froideur et les sarcasmes de la vierge blessent le sensible poisson – La désillusion.",
        Craquer:"Cet amoureux saura susciter votre curiosité, vous qui avez du mal à vous livrer, vous serez  impressionnée par son courage impérieux à vous crier tout son amour, sans pudeur ni crainte de rejet, il déposera son cœur  à vos pieds. Sensibilisée par tant de fougue, vous vous laisserez séduire, mais l'emballement passé, vous constaterez des  incompatibilités rédhibitoires. Attaché à tout ce qui est concret, stable et cohérent, vous comprendrez très vite que votre Roméo n'est autre qu'un Don Juan en mal de tendresse, distillant à tous vents, ses faveurs. L'infidélité est un défaut que vous ne pouvez tolérer et vous n'aurez de choix que de balayer de votre vie cet inconstant,  dont les incohérences heurtent votre logique.",
        Coach:"Une attraction irrésistible avec une complémentarité où le monde fantasmagorique rencontre le monde de la réalité. Leur relation est à la fois fascinante et déstabilisante. Un enchantement des sens qui s’évaporera telle la magie de l’illusion."
    } ,
    Balance_Belier : {
        Slogan:"« Une attraction incontrôlable entre ces deux êtres pourtant si différents, mais ça colle. »",
        LVA :"Ils vont vivre l’attirance et la répulsion comme deux aimants. Le bélier est un tentateur qui n’aime pas qu’on lui résiste, la balance elle, a du mal à résister à la tentation et succombera à vos assauts  insistants. La balance, raffinée et oisive, séduisante et charmeuse, va envoûter cet incorrigible chasseur. La balance  saura canaliser les emportements du bélier par sa diplomatie. Leurs étreintes seront explosives et le  bélier saura révéler les désirs profonds de la balance. ",
        Plus:"Une attirance incontrôlée – Une passion dévorante – La balance arrive à attacher le bélier.  ",
        Moins:"La Balance prévoyante surveille son infidèle bélier – Deux caractères forts qui s’affrontent dans des colères terribles.",
        Craquer:"Vous serez d’abord touchée par toute la détermination et la bravoure qu'il déploie pour vous plaire. Son désir brûlant de vous posséder, vous charmera au plus haut point. Il vous veut et est prêt à tout pour conquérir votre cœur tel  le prince charmant de vos rêves. Mais vous déplorerez son côté provocateur, où sa vulgarité rimera avec médiocrité. Tout rebombera comme un soufflet...",
        Coach:"C’est la rencontre de Mars et de Vénus, la communion parfaite de l’homme et de la Femme. C’est l’amour passion dans une quête de la perfection."
    } ,
    Balance_Taureau :{
        Slogan:"«Ces deux vénusiens auront du mal à se supporter bien longtemps malgré leur goût commun pour l’art. »",
        LVA :"Tous deux signes vénusiens trouveront à coup sûr une belle entente au niveau sentimental et physique. Ce qui les réunit c’est l’amour du beau, de l’esthétique, de l’artistique et de l’oisiveté. Malgré ces affinités ils abordent la vie de façon radicalement différente et l’harmonie laissera place très rapidement aux malentendus. La balance raffinée et mondaine aura du mal à supporter la vie trop simple d’un taureau pépère et grognon.",
        Plus:"L’amour de la beauté – Ames d’artistes – La  sensualité.",
        Moins:"La légèreté de la balance –Leur matérialisme –L’un aime le foyer l’autre les mondanités – La balance est raffinée et le taureau assez simple parfois grossier.",
        Craquer:" Avec vous il sortira le grand jeu, restaurants étoilés, hôtels de luxe, cadeaux somptueux, il faut dire que vous le valez bien, n’est-ce pas ? Mais sitôt la relation installée, vous vous retrouverez dans sa maison de campagne au milieu de nulle part. Traire les chèvres et faire la cendrillon le week-end. Très peu pour vous, c’est à mourir d’ennui une vie pareille, vous  n'êtes pas faites pour vivre comme  une campagnarde et vous n'avez pas postulé pour \"L'amour est dans le pré\" ! Une belle plante comme vous doit vivre en représentation, pas en ermite car vous vous faneriez, c'est certains.",
        Coach:"Ces deux vénusiens aux aspirations artistiques communes et amoureux de l’amour ne réussissent pas à se mettre d’accord, leurs désirs étant trop divergents. Attention aux relations d’intérêts pour ces deux matérialistes."
    } ,
    Balance_Gemeaux : {
        Slogan:"« Relation aérienne et délirante, manquant parfois de pragmatisme. Couple intemporel. »",
        LVA :"Vous formez un joli couple qui aime les sorties entre amis et les mondanités, vous passerez des heures à discuter de tout et de rien. Tous deux intelligents, raffinés et charmeurs, votre relation est légère comme l’air, où l’insouciance vous guide. Un peu dans les nuages, vous avancerez dans une relation amoureuse  idéalisée. La triste réalité de la vie et ses contraintes quotidiennes risquent de laisser place à la désillusion et aux grincements de dents. Côté sexe, le plaisir est garanti grâce à votre imagination débordante.",
        Plus:"Vous vous comprennez et passez d'agréables moments – Vous êtes tous deux très artistes et manuels – Vous communiquez beaucoup – Vous êtes deux imaginatifs.",
        Moins:"A force de dépenser sans compter on ne paye plus ses factures – Les projets restent à l’état de rêve – A trop vouloir du  plaisir on se brûle les ailes.",
        Craquer:"Vous qui aimez tout ce qui brille, vous ne pourrez que remarquer ce personnage haut en couleurs  qui expose ses signes extérieurs de richesse outrageusement. Il est vrai qu’il est plaisant et de bonne conversation, vous ne vous ennuyez jamais avec lui mais quel m’as-tu-vu ! Un peu fanfaron, limite provocateur, vous qui aimez la discrétion, avec lui, vous serez  servie. Mais ne dit-on pas que les contraires s'attirent ? En fait, il vous dérangera autant qu'il vous séduira, car il est charismatique. A vous de voir...",
        Coach:"Une attraction irrésistible va les rapprocher, il faut dire que le Gémeau est un habile orateur qui va subjuguer la Balance par le raffinement et la diplomatie, qui sont des qualités chères à son cœur. Amour aérien. "
    },
    Balance_Cancer :{
        Slogan:"« Une Love attraction spontanée mais frileuse. Deux séducteurs savent se reconnaître !  »",
        LVA :"Pas de répulsions entre ces deux signes, mais de là à faire votre vie ensemble, il ne faudrait pas exagérer non plus. Diamétralement opposés vous aurez du mal à vivre autre chose qu’une relation purement érotique et éphémère. Mais vous apprécierez les moments de douceur que vous partagerez ensemble. Cependant, la Balance éternelle séductrice aime la vie mondaine et rechigne aux travaux ménagers à l’instar du Cancer qui aime sa maison et a plaisir à s’y enfermer.",
        Plus:" Des moments de grâce sensuels – Le goût pour l’amour – Relation calme sans éclats.",
        Moins:"Une incompatibilité essentielle sur la vie de couple – La balance est mondaine et Le cancer aime la famille.",
        Craquer:"Vous ce qu’il vous faut, c’est du rêve, du clinquant, un bel homme brillant et généreux, surtout généreux. Certes, il sera galant, attentionné, aux petits soins, mais bien trop pingre et il lui manque ce grain de folie qui vous enthousiasme. Et cette manie de vouloir vous enfermer dans une vie monacale loin des paillettes, lui mijotant de bons petits plats, entourée d’une ribambelle de marmots. Mais quel tableau cauchemardesque ! Sa conception de l'amour est bien trop éloignée de la vôtre et ne vous fait pas du tout rêver, vous vous sentez brimée et frustrée dans son univers étriqué.",
        Coach:"Attraction de ces deux romantiques qui cultivent le désir du grand amour. Des débuts enflammés ou chacun pensera avoir trouvé son âme-sœur, mais tôt ou tard le conte de fées se transformera en sordide règlement de compte. Amour illusoire."
    } ,
    Balance_Lion :{
        Slogan:"« Alliance de la beauté et du luxe. Un amour cliquant qui brille de mille feux. »",
        LVA :"Votre histoire c'est un peu la rencontre de Vénus, déesse de l'amour et de la beauté et d'Apollon, Dieu de la musique et de la poésie, réputé pour son physique avantageux. L'attraction est immédiate et intense, prometteuse d'une entente parfaite, basée sur l'élan d'un amour enthousiaste et de goûts communs pour le milieu artiste et les joies de l'existence. Vous vous aimez d'une passion ardente et romantique. Votre sexualité sera harmonieuse, même si le Lion manquera parfois de finesse et de délicatesse.",
        Plus:"Goûts communs pour les arts, le luxe et les mondanités - L'envie d'aimer intensément en vue d'une relation sérieuse -Une sexualité épanouie.",
        Moins:"La légèreté de la balance rend méfiant le lion fidèle - L'oisiveté de la balance - Les colères noires du lion se heurtent à la sensibilité de la balance.",
        Craquer:"Vous serez charmé par ce mâle puissant et rayonnant, à qui tout semble sourire. Sûr de lui, il brille en société et sait captiver l'attention. Vous admirez son charisme et le faste dans lequel il évolue. Il sera l'homme parfait qui saura satisfaire vos extravagances et vos goûts de luxe. Après tout vous êtes une princesse qui cherche un prince avec des largesses de grand seigneur. Seuls ses emportements et son inflexibilité se heurtera à votre besoin de paix et de tolérance. Des conflits sur des malentendus financiers car monsieur se veut grand seigneur, vous fait des promesses de grande vie et mégote pour quelques toilettes nouvelles qu'il juge superflues.",
        Coach:"L'attraction est immédiate et dès les premiers instants partagés, ils découvriront leur sensibilité commune pour les mondanités et l’art.  Amour harmonieux."
    } ,
    Balance_Vierge :{
        Slogan:"« Comment oisiveté et  rigueur peuvent-elles cohabiter. En fait vous ne vous supportez pas. »",
        LVA :"La magie de l’attraction entre ces deux signes ne durera pas bien longtemps, juste  le temps que la Vierge se rende compte des travers  cachés de cette charmeuse, aimable, mais dissimulatrice  et superficielle Vénusienne. La Balance se sentira alors offensée par l’analyse critique, mais non moins réaliste de sa personnalité par cette Vierge froide qu’elle qualifiera d’intolérante et d’insensible.",
        Plus:"Les échanges culturels – Le raffinement – La vierge organisée et bonne gestionnaire tempère les dépenses excessives de la balance.",
        Moins:"Le manque de passion – La superficialité et l’infidélité de la balance éloigne la vierge – La rigueur de la vierge et sa retenue amoureuse déplaisent à la chaleureuse balance.",
        Craquer:"Cet homme réservé dégage une classe folle et vous séduit par sa finesse d’esprit. Tout en délicatesse il saura vous aborder avec déférence, sachant vous faire une cour digne d’un gentilhomme.  Vous serez aux anges. Mais sa rigueur et ses règles de vie strictes, feront peur à la cigale que vous êtes. Vous ce que vous aimez c’est chanter, danser, dépenser et profiter de l’existence. La privation et les restrictions vous rendraient malheureuses rapidement, et flétriraient la jolie fleur que vous êtes.",
        Coach:"Une attraction progressive pour ces deux signes discrets qui recherchent le bien-être amoureux. Ils prendront leur temps pour se laisser aller à l’amour, désirant approfondir la connaissance de l’autre. Une amitié amoureuse tiède mais sincère."
    } ,
    Balance_Balance :{
        Slogan:"« L’amour dans sa définition la plus juste : Harmonie, Tolérance, Respect mutuel. »",
        LVA :"Rencontre de deux vénusiens en quête de l’être idéal dans le but ultime de créer un monde parfait où règnera une harmonie paradisiaque. Certes, l’envie est là, mais leur nature discrète et délicate leur fait manquer d’audace et de spontanéité. Leur relation est fusionnelle au niveau de leurs goûts communs pour l’art et l’esthétique mais beaucoup plus fragile quant à l’extase des sens. Le désir de plaire à l’autre et leur nature indécise risque de paralyser leur harmonie à long terme. La balance a besoin d’un partenaire très différent d’elle, pouvant gérer avec combativité les situations de crise",
        Plus:" Ils se ressemblent – Ont le même goût pour l’art, les plaisirs  -Ils ont une vie sociale très riche –Savent créer un foyer confortable et luxueux.",
        Moins:"Ils ont les mêmes défauts, dépensiers, superficiels, infidèles, séducteurs – Ils sont oisifs et peu courageux.",
        Craquer:"Vous vous reconnaîtrez en lui, classieux, avec un air charmant mais inabordable ce qui le rendra encore plus mystérieux et attractif. Vous vous émerveillez des mêmes choses et avez plaisir à partager vos loisirs. Seul bémol de cette relation fusionnelle, ce sont justement vos ressemblances. Vous avez besoin d’un homme fort et protecteur, or celui-ci est un parfait compagnon de jeu mais surement pas celui sur lequel vous pourrez vous reposer. Il est bien trop léger, insouciant et fainéant. Aussi dépensier que vous, vous risquez de mettre en péril l'équilibre budgétaire du couple et vous retrouver dans une galère que vous ne lui pardonnerez pas car l'homme doit prendre soin du bien-être de sa compagne, telle est votre devise.",
        Coach:"Enfermés dans leur protocole de la bienséance, ils s’aborderont avec tact et délicatesse au risque de freiner leurs élans. Séducteurs dans l’âme, ils ne pourront s’empêcher de plaire au risque de déplaire à leur partenaire.  Relation narcissique."
    } ,
    Balance_Scorpion :{
        Slogan:"« Difficile de faire comprendre que l’amour c’est le bonheur à un esprit autant torturé. »",
        LVA :"La nature conciliante et sociable de la Balance, lui permet de s’adapter à la compagnie  de la plupart des signes du zodiaque, mais sa relation avec le Scorpion va lui poser pas mal de problèmes, car vous avez surtout besoin de paix et de sérénité au sein du couple. En effet, vous supporterez mal les scènes de violence et les invectives blessantes et cruelles du Scorpion dans ses crises de jalousies maladives. Or, en bonne Balance, vous êtes charmeuse à souhait, ce qui exacerbera la nature excessive et soupçonneuse du Scorpion.",
        Plus:"Une attraction entre la beauté irréelle de la balance et le magnétisme envoûtant du scorpion – La curiosité intellectuelle ",
        Moins:"Vos natures sont diamétralement opposées, le Scorpion est excessif  et la Balance recherche l’équilibre – L’un désire la paix et l’autre est toujours en guerre – La jalousie et la possessivité du Scorpion est mise à mal par l’infidélité de la Balance.",
        Craquer:"Il a une manière de vous déshabiller du regard qui vous met mal à l’aise, mais vous attire inexorablement  dans ses bras. Pourtant son air arrogant et son sourire sarcastique, vous déplaît, il n’est pas le style d’homme à vous séduire mais l’attraction est incontrôlée. Pourtant, vous vous rendrez très vite compte que la vie sera impossible avec ce jaloux maladif, il ne supporte pas que l’on vous regarde et vous reproche votre nature trop affable. Non décidément, il est bien trop grossier et dominateur, et vous si raffinée et aérienne, que vous vous sentez vite étouffée… Au secours ! Fuyez vite cette relation tortueuse et torturée. ",
        Coach:"Une attraction troublante et insidieuse va envahir la Balance, emportée dans les remous émotionnels du Scorpion, aux sentiments violents et impétueux. Un jeu de séduction tortueux, aux antipodes des rêves romantiques de la Balance."
    } ,
    Balance_Sagittaire :{
        Slogan:"« Une belle aventure au rythme de soirées mondaines, de plaisirs sans obligations ennuyeuses. »",
        LVA :"La rencontre de la Balance et du Sagittaire ne pourra être qu’agréable, car ce qui vous importe, c’est de vivre intensément en profitant de tout ce que l’existence peut offrir de plus attrayant. La bonne humeur communicative du Sagittaire vous séduira et vous trouverez en cet homme ouvert et franc, une nature joviale qui aime les plaisirs, la fête et les soirées mondaines. Lui, appréciera votre nature accommodante qui s’adaptera à sa nature aventureuse et atypique.",
        Plus:"Vos goûts communs pour tous les plaisirs de la vie – Vous aimez afficher votre culture – L’adaptabilité de la Balance – La tolérance du Sagittaire",
        Moins:"Vos natures infidèles – Les emportements colériques et autres excès de certains Sagittaires – Vos inconsciences financières – La grossièreté du Sagittaire heurte la délicatesse de la Balance. ",
        Craquer:"Avec ses allures d’homme du monde, vous voyez en lui celui qui vous fera vivre la grande vie. Il va vous sortir dans des endroits \"Hype\" et vous donnera l’impression que vous êtes une star et ça, vous adorez. Mais attention car cet homme à multi-facettes  aime aussi bien les nuits dans un 5 étoiles que sous une tente dans un camping sauvage. Un grand écart qui vous désorientera à coup sûr, vous qui êtes si précieuse et chichi pan-pan. Mais vous serez tellement amoureuse de cet homme atypique que vous serez prête à vous adapter à n’importe quelle situation… ou presque, ne serait-ce par amour et si en contrepartie il ne consente à vous épouser… Gros challenges pour tous les deux. Mais ne dit-on pas que l’amour peut parfois faire des miracles ?",
        Coach:"Le côté bourgeois et snob de la Balance sera séduit par la nature aventurière, spontanée et joyeuse du Sagittaire, qui lui fera découvrir de nouvelles expériences amoureuses inconnues jusqu’alors. Mais leur passion ne sera que superficielle et éphémère.\""
    } ,
    Balance_Capricorne :{
        Slogan:"« Une conciliation impossible entre l’oisiveté de l’un et les ambitions de l’autre.»",
        LVA :"Difficile de comprendre ce qui a pu vous attirer l’un vers l’autre, vous êtes de nature si différente. On ne peut imaginer un couple aussi mal assorti, c’est une des pires  configurations astrales. Certainement vous êtes-vous rapprochés à la suite d’une forte déception amoureuse qui aurait affligé vos vies. Désabusée vous vous serez retournée vers Monsieur Capricorne pour rechercher une sécurité morale et financière. Quant à Monsieur Capricorne, votre chaleur humaine et votre gaieté n’auront été là que pour égayer sa morne vie.",
        Plus:"Le capricorne ambitieux procure un niveau social à la hauteur des rêves de la balance – La balance est une belle hôtesse sachant représenter fort honorablement  le rang du capricorne",
        Moins:"La froideur du capricorne glace la chaleureuse balance – Peu de gestes d’affection et peu de démonstration amoureuse, frustre l’amoureuse balance - L’avare capricorne freine les excès dépensiers de la balance pour des futilités.",
        Craquer:"Il vous a attiré du premier coup d’œil, avec son look de \"Golden Boy\", (rien que le mot vous excite, Golden, veut dire doré et vous, vous adorez tout ce qui est clinquant). Lui, vous en êtes sûre, il doit bosser à la bourse, fréquenter des PDG et autres grandes fortunes, ce monde vous fascine, vous fait rêver. Vous savez user de vos charmes pour obtenir ce que vous désirez, pourtant malgré des trésors d’ingéniosité pour le séduire, vous vous heurterez à une indifférence déconcertante. Cet homme serait-il de glace ?? De toute façon il n’est pas généreux voir même radin et son manque de fantaisie et de chaleur humaine vous découragent.",
        Coach:"Rencontre de l’insouciance et de l’austérité qui laisse présager une relation amoureuse basée sur l’amour du pouvoir et du décorum où l’émotion et les sentiments sont exclus. Complicité difficile. "
        } ,
    Balance_Verseau :{
        Slogan:"« Vous savez vous apprécier à votre juste valeur sans jugement aucun. »",
        LVA :"Votre relation sera très harmonieuse, car elle alliera l’influence Vénusienne de la Balance à l’ingéniosité et l’originalité du Verseau. Votre attraction ressemble à une amitié-amoureuse basée sur la curiosité et le contact humain. Vous vous harmoniserez dans les domaines de la musique et des arts, vous entourant d’objets rares. Sociable et conciliante, vous accepterez les excentricités de Monsieur Verseau sans jugements et saurez être une hôtesse accueillante pour ses nombreux ami(e)s.",
        Plus:"Vos natures aériennes  - Le plaisir de la rencontre et le goût de la vie sociale – L’amour de l’art et des antiquités – L’acceptation de votre légèreté amoureuse.",
        Moins:"Le verseau manque de chaleur amoureuse et d’attention – La solitude du verseau – La frivolité de la balance et ses extravagances financières.",
        Craquer:"Fascinant est le terme qui vous vient à l'esprit lorsque vous pensez à lui, cet homme atypique, vous séduit par son esprit incisif et critique. Passionné de culture et de savoir il a un avis sur tout et sait tenir une conversation enflammée durant des heures si le sujet l'enthousiasme. Il saura vous faire la cour tel un gentleman, car lorsqu'il est charmé, il n'hésite pas à user de tous ses atouts de séduction pour arriver à ses fins. Tous les centres d'intérêts que vous partagez lui donneront envie de passer plus de temps avec vous. Mais attention, il ne sacrifiera jamais ses moments de solitude  chers à son équilibre, même pour vos beaux yeux. Votre parfaite complicité sera le ciment de cette histoire d'amour qui manquera certes d'ardeur, mais dont le potentiel est immense.",
        Coach:"Une attraction intellectuelle et spirituelle va motiver cette rencontre hors du commun. L’élégance de la Balance va subjuguer notre rebelle Verseau qui brillera par ses éclairs de génie. Harmonie des âmes et spiritualisation de l’amour."
        } ,
    Balance_Poissons :{
        Slogan:"« Un manque d’affection comblé mais des caractères trop divergents. Manque d’admiration. »",
        LVA :"Vos natures calmes, refusant tout conflit, ne pouvaient que s’attirer. Votre amour pour la musique, la poésie et bien d’autres sujets artistiques, dénotent deux personnes un peu bohèmes et insouciantes. Mais votre compatibilité s’arrête là, car même si Monsieur Poisson peut vous impressionner par son charme hypnotique, vous susurrant des mots doux, vous vous rendrez vite compte que ses belles paroles ne sont que des artifices n’ayant pas de consistances amoureuses. Cet homme vous trouvera alors très superficielle et snob, pas assez spirituelle ni même affectueuse.",
        Plus:"Votre goût commun pour l’art, la musique – Vous êtes tous deux amoureux de l’amour assez superficiellement. ",
        Moins:"L’inconséquence du poisson  et son instabilité – Leur vision de l’amour est trop différente, le poisson sublime l’amour en général alors que la balance cherche le grand Amour.",
        Craquer:"Vous serez séduite par cet artiste au grand cœur, inspiré par l'expression divine de votre beauté. Vous vous laisserez bercer de ses mots d'amour et de ses caresses douces et aimantes. Vous vivrez une belle histoire romantique qui vous transportera de bonheur. Mais ses vagues à l'âme et ses descentes aux enfers vous déstabiliseront, car vous avez besoin d'un homme aimant certes, mais puissant et protecteur. Avec lui vous vous sentirez en danger, ses hauts et surtout ses bas psychologiques dégraderont l'image idéalisée de cet amoureux transi. Sa folie vous effraiera et vous éloignera de lui comme d'un pestiféré. Vous êtes une adepte du grand amour, mais pas  d'un amour désespéré et résolument dramatique.",
        Coach:"Le charme hypnotique du Poisson fascine la Balance qui pressent une relation romantique et idyllique. Mais très vite le manque de compréhension et les quiproquos sonneront le glas final de cette illusoire histoire d’amour. "
    } ,
    Scorpion_Belier : {
        Slogan:"\" Un bras de fer qui gâchera l’attirance instinctive de ces deux guerriers. \"",
        LVA :"Attention relation sous haute tension sexuelle, tous deux addicts au sexe, vous vous retrouverez à tester toutes les positions du kamasutra et à essayer sans tabous les expériences les plus insolites. 46%  concentrés dans l’érotisme du couple, car le tandem  Bélier/Scorpion ne s’accorde, ni d’un point de vue psychologique, ni d’un point de vue intellectuel. Le Scorpion plus subtil et sadique désoriente totalement le Bélier franc et direct sans réflexions.",
        Plus:"Le sexe of course – Une passion dévorante à se consumer – Deux excessifs en quête d’absolu.",
        Moins:"Le bélier franc trouvera le scorpion pernicieux et fourbe – Leurs excès déborderont en colères violentes – Avec eux c’est l’amour et la guerre.",
        Craquer:"Difficile de résister à cet adonis, il dégage un tel sex-appeal que tous vos sens sont en éveil et  vous donnent envie de vous abandonner à sa fougue. Mais votre jalousie maladive risque d’être mise à mal avec cet incurable Casanova qui vous mettra dans des colères noires et destructrices.",
        Coach:"On ne sait quel est celui qui entraîne réellement l’autre dans ses délires excessifs. Elle le provoque de ses tenues affriolantes et lui démarre au quart de tour, prêt à toutes les folies, sa libido en éveil. Mais leurs ébats terminés, c’est la guerre. "
    } ,
    Scorpion_Taureau :{
        Slogan:"\" Fascination et défis passionnés pour ces deux caractères bien trempés. \"",
        LVA :"Relation fusionnelle où l’attraction peut devenir très vite fatale et violente. Dans tous les cas, cette relation sera basée sur un désir impérieux, une sexualité explosive dans une quête unique  de sensations fortes. Insatiables, vous n'aurez de cesse d’aller toujours plus loin dans les excès en tout genre, ce qui rendra vite l’atmosphère électrique et agressive, car vous manquez, tous deux, grandement de souplesse. Vous êtes deux têtus, obstinés et jaloux et les disputes rythmeront cette relation.",
        Plus:"Une attirance incontrôlée par ses bas instincts – Une sexualité débridée – Tous deux recherchent la stabilité – Ils aiment l’argent et le luxe.",
        Moins:"Suspicion – Colères violentes – Rapport de force et de domination – Jalousie – Tyrannie des deux signes – Rancunes – Les ruptures sont empreintes de vengeance.",
        Craquer:"Vous serez séduite par la force tranquille qui s’émane de cet homme robuste et fort. Il est l’incarnation du vrai mâle et réveille en vous des désirs intenses qui vous submergent et vous font perdre la raison. Mais son manque de souplesse,  ses bouderies incessantes et ses paroles culpabilisantes vous mettront dans des états au bord de la crise de nerfs. Rapidement, vous ne pourrez plus vous supporter et les coups de gueule seront de plus en plus violents. ",
        Coach:"Ils se défieront du regard et le Scorpion attirera le Taureau par son magnétisme envoûteur, mais attention, telle une mante religieuse, le Scorpion veut posséder le Taureau. Vous rentrerez dans un combat pour le pouvoir. Relation sous haute tension sexuelle."
    } ,
    Scorpion_Gemeaux : {
        Slogan:"\" Jeux cérébraux sur fond de manipulation. Rien de constructif dans ce genre de relation. \"",
        LVA :"Vous êtes deux provocateurs et vous aurez du mal à trouver un terrain d’entente sur le long terme. L’inconstance du Monsieur Gémeau aura tendance à désorienter et exacerber votre jalousie et vous possessivité. Un tantinet paranoïaque, vous entrerez dans des colères monstres. Le Gémeau a du mal à comprendre la torture mentale dans laquelle vous vous complaisez. Très charnelle, vous attendez de votre compagnon des ébats plus torrides et plus instinctifs, mais là ça risque de coincer.",
        Plus:"Vous êtes tous les deux très curieux, intelligents et vif d'esprit – Vous aimez la provocation et la polémique.",
        Moins:"L’instabilité du gémeau déconcerte le scorpion – Tous deux sont manipulateurs  - Deux esprits tourmentés et angoissés – L’infidélité.",
        Craquer:"C’est avec son air arrogant et son humour sarcastique qu’il vous a accroché. Un brin frimeur, on peut dire qu’il vous amuse, mais vous vous dites que vous n’en ferez qu’une bouchée. Pourtant vos nerfs seront mis à rude épreuve avec cet homme désinvolte et manipulateur, vous aurez l’impression qu’il se joue de vous et cela vous mettra dans des rages folles. Avec lui vous ne maîtriserez rien. Vous avez trouvé votre maître ? Pas sûr, car il n'hésitera pas à vous laisser en plan, disparaissant comme par enchantement, lassé de vos sautes d'humeurs et de vos réflexions acides.",
        Coach:"Attraction dangereuse pour ces deux provocateurs qui se lancent des défis intellectuels dans lesquels ils se découvrent réciproquement avec beaucoup d’enthousiasme, plongeant inconsciemment dans une relation obscure."
    },
    Scorpion_Cancer :{
        Slogan:"\" Une fascination envoûtante, une fusion des sens. Relation magique. \"",
        LVA :"Vous êtes tous les deux animés et dirigés par vos intuitions et de fortes émotions. Vous vous accordez aussi bien sur le plan amoureux que sur le plan sexuel. Le Scorpion aime intensément, jalousement et exige une fidélité sans faille, ce que le Cancer n’aura pas de mal à lui consacrer, car cela fait partie de ses principes moraux. Le caractère malléable et soumis du Cancer s’adaptera parfaitement aux exigences et à la tyrannie du Scorpion. Seules les paroles blessantes du Scorpion pourraient ébrécher cette union magique.",
        Plus:"Des émotions transcendées – Une relation fusionnelle– Deux caractères qui se complètent – Une énergie sexuelle tantrique –Chacun trouve sa place. ",
        Moins:"La tyrannie et la cruauté du scorpion risque de déstabiliser cette relation idyllique – La passivité du cancer agace le  dynamisme du scorpion.",
        Craquer:"Comme vous, il s’investit corps et âme dans votre histoire, sans tabous ni retenus, vous vivrez ensemble une passion enflammée comme vous les aimez. Aussi possessif que vous, il saura être présent à chaque instant, vous gardant jalousement près de lui. Vous ne pourrez cependant vous empêcher de l’agresser violemment car vos vieux démons dominateurs auront tendance à écraser ce soumis dépressif sans caractère. Mais méfiez-vous de l’eau qui dort, car exténué par votre rythme infernal, il pourrait bien mettre un stop final, à votre grande surprise, à ce jeu amoureux pervers.",
        Coach:"Une attraction irrésistible mue par de violentes émotions. Un lien intense où se mêlent fascination et tourments. Une relation ou le Cancer sera la  victime consenti et le Scorpion son tortionnaire. Un amour torturé où l’érotisme joue un rôle important."
    } ,
    Scorpion_Lion :{
        Slogan:"\" Difficile de concéder à l’autre la place du chef. Rapports de force passionnels. \"",
        LVA :"Rencontre de deux forces de la nature, l'une diurne, avec un feu intérieur bouillonnant, et l'autre solaire, ardent et exubérant, c'est le choc des titans. Ces deux tyrans à la personnalité inflexible et au  manque d'adaptabilité vont se rendre une vie impossible.  Seule l'attraction sexuelle pourra vous mettre d'accord un certain temps et faire perdurer malgré tout  cette relation basée sur le rapport de force, sans concessions.",
        Plus:"Une  potentielle réussite pour une association professionnelle - Une sexualité intense assumée mais manquant de tendresse.",
        Moins:"Le scorpion pique le lion au vif - La violence de ces deux excessifs - Le scorpion est plus pervers et le lion trop direct - Rapports difficiles au pouvoir.",
        Craquer:"Vous le trouverez sexy, et c'est son magnétisme sexuel que vous capterez dès le premier regard, il se dégage de lui une aura très animale à laquelle vous n'êtes pas insensible. Vous vous  jetterez à corps perdu dans cette histoire, passionnément mais  sans jamais vous soumettre à son autorité de mâle dominant. Vos critiques acerbes et votre guérilla finiront par le décourager car il vous aime soumise et non rebelle.",
        Coach:"Attraction animale entre deux personnalités dominatrices. Leur relation est surtout passionnellement érotique sur fond de torture mentale. L’intensité sexuelle n’est qu’un instrument pour prendre le pouvoir de l’un sur l’autre. Relation destructrice."
    } ,
    Scorpion_Vierge :{
        Slogan:"\" Couple très harmonieux où l’efficacité et la sincérité sont de rigueur. \"",
        LVA :"Il semblerait que le Scorpion soit né pour charmer la méfiante Vierge et la faire tomber dans son piège amoureux. Comme si le Scorpion possédait de façon innée, le mode d’emploi pour percer sa cuirasse. Il faut dire que vous userez et abuserez de votre magnétisme envoûtant pour libérer cet homme, Vierge sage, de ses tabous intellectuels et physiques, faire tomber toutes ses barrières de résistance pour le rendre foux d’amour, esclave de ses sens et l’asservir.",
        Plus:"Leur acuité intellectuelle – Ils se comprennent et savent s’apprivoiser – Le scorpion révèle la nature cachée de la vierge – La vierge est soumise au dominant scorpion.",
        Moins:"Les excès destructeurs du scorpion révoltent la vierge soumise – Le sadomasochisme.",
        Craquer:"Avec ses airs hautains et froids, vous serez attirée comme un aimant par cet intello coincé dans ses préjugés et sa morale puritaine. Vous aimez ses principes vertueux même si vous rêvez de le voir se rouler dans la fange avec vous, mais ce qui vous séduira plus encore c’est de le provoquer dans une joute intellectuelle où votre seul objectif sera de briser ses barrières défensives et de le voir se débattre avec ses conflits intimes. Mais attention à bien  appréhender votre adversaire car il pourrait lui aussi vous asservir à ses fantasmes. ",
        Coach:"Le Scorpion a incontestablement les qualités requises pour neutraliser les défenses de la méfiante Vierge qui sera subjuguée par son charme magnétique. Leur vivacité d’esprit et la pertinence de leurs observations les attireront comme un aimant.\""
    } ,
    Scorpion_Balance :{
        Slogan:"\" Difficile de faire comprendre que l’amour c’est le bonheur à un esprit autant torturé. \"",
        LVA :"LLa nature conciliante et sociable de la Balance, lui permet de s’adapter à la compagnie  de la plupart des signes du zodiaque, mais sa relation avec le Scorpion va lui poser pas mal de problèmes, car la Balance a surtout besoin de paix et de sérénité au sein du couple. En effet, Monsieur Balance supportera mal vos scènes de violence et vos invectives blessantes et cruelles dans vos crises de jalousies maladives. Or, en bonne Balance, il est charmeur à souhait, ce qui exacerbera votre nature excessive et soupçonneuse.",
        Plus:"Une attraction entre la beauté irréelle de la balance et le magnétisme envoûtant du scorpion – La curiosité intellectuelle ",
        Moins:"Vos natures sont diamétralement opposées, le Scorpion est excessif  et la Balance recherche l’équilibre – L’un désire la paix et l’autre est toujours en guerre – La jalousie et la possessivité du Scorpion est mise à mal par l’infidélité de la Balance.",
        Craquer:"Vous serez séduite par son apparence calme et sûre de lui. Son abord distant voir même insaisissable, vous motivera d’autant plus pour accaparer son attention. Mais vous déchanterez très vite car vous ne saurez sur quel pied danser avec lui. Lorsque vous croirez l’avoir ferré vous vous apercevrez qu’il compte fleurette à une autre. Sa légèreté et sa superficialité amoureuse ne s’harmonisera pas du tout avec votre engagement total et votre névrose de l’infidélité. Vous n'aurez de cesse de le pister pour déjouer ses écarts de conduite et votre histoire d'amour deviendra bientôt un terrain de guerre pour débusquer l'ennemi, cet homme déloyal.",
        Coach:"Une attraction troublante et insidieuse va envahir la Balance, emportée dans les remous émotionnels d’un Scorpion, aux sentiments violents et impétueux. Un jeu de séduction tortueux, aux antipodes des rêves romantiques de la Balance."
    } ,
    Scorpion_Scorpion :{
        Slogan:"\" Attirance magnétique, incontrôlable et dévorante. \"",
        LVA :"Rencontre de deux personnalités troublantes, mystérieuses et surtout dangereuses, que ce soit pour elles-mêmes ou pour les autres. Vous aurez eu à coup sûr un coup de foudre, le choc de la rencontre donne le ton, intensité et exacerbation des sens qui va vous emporter dans une passion  dévorante menée au  train d'enfer d’émotions d’ivresse mêlées à celles de la souffrance. Il faut être Scorpion pour arriver à supporter une telle pression. ",
        Plus:"Vos natures passionnées et entières – La sincérité – Votre curiosité intellectuelle.",
        Moins:"Le besoin d ‘aller toujours plus loin-  Leur perversité – La violence des conflits.",
        Craquer:"Il dégage une telle sensualité que vous ne pouvez résister à son appel. Vous savez instinctivement que cette relation va être intense et que vous serez éprouvée par tant de puissance, mais il est déjà trop tard, vous ne pourrez plus reculer et vous n’en avez pas envie, c’est ce qu'on appelle la tentation du diable. Oui c’est bien ça, il a cette magie enivrante de celui qui, en vous faisant souffrir, va vous faire du bien. Il faut espérer qu’une part de vous-même aura un instinct de survie pour éviter le pire et pour sublimer cette passion.",
        Coach:"69% de haute tension sexuelle entre ces deux passionnés où l’exaltation des sentiments est intense et violente. Relation qui n’est pas de tout repos sur fond de jalousie et de possessivité. Une passion qui alterne rupture et réconciliation. "
    } ,
    Scorpion_Sagittaire :{
        Slogan:"\" Echanges sulfureux, mais le sagittaire se tiendra toujours sur ses gardes. \"",
        LVA :"C’est une attraction irrésistible entre le sulfureux et ténébreux scorpion et le bienheureux sagittaire grand jouisseur devant l’éternel. Le sagittaire va s’emballer pour cet amoureux avide de sensations fortes mais se rendra compte bien trop tard qu’il s’est fait prendre au piège par un partenaire torturé. Mais amoureux et enthousiaste, il pensera certainement à tort qu’il réussira à apaiser les tourments de son partenaire. Quant au scorpion destructeur, il ne pourra que continuer à distiller son venin dans les certitudes de ce grand naïf dans le but ultime de le posséder. L’indépendance du sagittaire sera fatale à cette histoire.",
        Plus:"Leur quête du plaisir – Tous deux sont passionnés.",
        Moins:"Le pessimisme du scorpion s’oppose à l’optimisme du sagittaire – Le possessif scorpion ne supporte pas l’indépendance du sagittaire.",
        Craquer:"Vous apprécierez cette nature généreuse et enthousiaste, vous qui êtes si pessimiste, vous prendrez votre dose de bonheur avec cet être vivifiant.  Ensemble vous passez des moments de plaisirs non dissimulés mais vous ne pouvez vous empêcher de vouloir l’emprisonner ce qui à terme, pourrait tout gâcher car Madame ne permet à quiconque de lui dicter sa conduite. Elle ne pourra se soumettre que provisoirement et par amour.",
        Coach:"Le Scorpion séduit sa proie en l’hypnotisant par son charme magnétique et notre candide Sagittaire, toujours prêt pour une nouvelle aventure, s’engage naïvement dans une histoire d’amour aux odeurs de souffre, où il risque d’y perdre son âme. Amour torturé et tortueux. "
    } ,
    Scorpion_Capricorne :{
        Slogan:"\" Quoi de plus jouissif que de déceler la faille de l’autre. Relation d’emprise réciproque. \"",
        LVA :"Ces deux signes sont les plus résistants et coriaces du zodiaque, tous deux ne rechignent pas à la difficulté, bien au contraire. Vous serez stimulés par les contraintes et les montagnes escarpées. La froideur sévère de Madame Capricorne vous attire, sûr de pouvoir faire faiblir ce cœur de pierre. Madame Capricorne, quant à elle, ne sera pas insensible à votre charme ravageur et se laissera volontiers emporter dans une passion folle, mais contrôlée. Vous aimez tous les deux le pouvoir et n'êtes pas de ceux qui se soumettent. Vous vous respectez mutuellement, reconnaissant vos valeurs de dominant. ",
        Plus:"Leur résistance à la souffrance – Leur curiosité intellectuelle – Leur soif de pouvoir –Leur respect mutuel – Des valeurs morales qui leur sont chères.",
        Moins:"Tous deux sont des dominants – Le capricorne manque de passion pour le scorpion – La rivalité dans la quête à la réussite.",
        Craquer:"Son apparence froide et distante excite vos sens car vous aimeriez découvrir ce qu’il se cache derrière ce voile glacial. Vous êtes sûr un volcan endormi que vous saurez réveiller pour votre plus grand plaisir. Vous saurez user de patience et de persévérance pour qu’elle se libère et que vous puissiez enfin la posséder. Docile, elle ne résistera pas, mais attention, sa fragilité apparente n’est qu’un leurre, car derrière cette nature éthérée se cache une personnalité affirmée.",
        Coach:"Attiré par l’inaccessible, le Scorpion mettra un point d’honneur à faire céder à ses avances pressantes ce Capricorne froid et austère. Tous deux ont le goût du pouvoir et le culte du travail et se respectent pour ces qualités, mais auront du mal, à terme, à se supporter. "
        } ,
    Scorpion_Verseau :{
        Slogan:"\" Relation de curiosité parfois malsaine. Aucun des deux ne cède réellement à l’autre. \"",
        LVA :"Pour ces deux rebelles, la relation ne sera pas en demi-teinte, ni de tout repos, ce sera tout ou rien, car vous n'avez pour règles que celles que vous dictez.  Votre devise commune « ni Dieu ni Maître » tout un programme. Vous passerez très facilement de l’amour passionné à la haine, à cause de vos natures obstinées et votre goût pour les histoires compliquées. La routine et les relations insipides, très peu pour vous. Vous avez besoin de vous défier dans un duel où les limites seront  toujours repoussées jusqu’à ce que l’un des deux obtempère. Cela risque d’être cinglant !",
        Plus:"Leur besoin d’originalité – Leur atypisme – Leur esprit éveillé –La sexualité sans tabous.",
        Moins:"La possessivité du scorpion et l’ultra-indépendance du verseau – Les colères du scorpion font fuir le verseau qui est anti-conflits.",
        Craquer:"Avec elle, vous subirez une attraction un peu particulière, car elle aime votre fougue et a besoin de sentir la passion l’envahir, surtout durant vos ébats. Elle rejette toute relation ennuyeuse et fade, mais avec vous, c’est l’extase !  Lorsque vous penserez l’avoir soumise à vos désirs, elle disparaîtra, se manifestant peu, dans une indifférence totale ce qui vous déstabilisera et attisera votre jalousie, car vous ne comprendrez pas sa distance, suspectant un rival. Mais vous perdez votre temps, car elle ne sacrifiera jamais sa liberté pour vos chaînes.",
        Coach:"Une relation sous haute tension où ces deux rebelles se défieront dans un combat sans merci, alternant passion et haine. Leur goût prononcé pour les histoires compliquées expliquera leur persévérance  dans cette relation sans issue."
    } ,
    Scorpion_Poissons :{
        Slogan:"\" Attraction fatale, ils peuvent aussi bien faire leur malheur que leur bonheur. \"",
        LVA :"Il semblerait que ces deux-là, aient été destinés l’un à l’autre, le scorpion dominateur trouve dans la nature soumise du poisson, l’esclave idéal à ses tyrannies. On parle de relation sado-maso consentie, le tempérament fort du scorpion profite de la docilité et du dévouement du poisson qui aime se détruire dans l’amour. Cette relation génère une  forte intensité d’émotions et de sensations sublimées dans leur érotisme passionné. Seule la lassitude du scorpion de s’amuser avec « ce jouet qui ne dit jamais non» pourra le détourner cette relation mais le poisson peut aussi décider de mettre fin à cette histoire, las des multiples tromperies et humiliations du scorpion.",
        Plus:"Des émotions puissantes et exaltantes les animent –une sexualité débridée.",
        Moins:"Le poisson manque d’affection et de tendresse face à l’agressivité du scorpion – La cruauté du scorpion blesse le poisson – la passivité du poisson agace le scorpion.",
        Craquer:"Du premier coup d’œil, vous serez attiré par cette sensuelle beauté un brin irréelle. Le mystère vous attire et vous excite, elle est l’incarnation de la femme idéale, dévouée à vos moindres désirs, celle que vous pourrez éduquer sexuellement et asservir. Elle subira sans se plaindre et vous donnera même l’impression d’y prendre plaisir. Mais sa nature contemplative et son manque de résistance à vos caprices pourraient vous détourner de son amour inconditionnel, lassé de tant de facilité.",
        Coach:"Une relation sous haute tension où ces deux rebelles se défieront dans un combat sans merci, alternant passion et haine. Leur goût prononcé pour les histoires compliquées expliquera leur persévérance  dans cette relation sans issue. "
    } ,
    Sagittaire_Belier : {
        Slogan:"« Une complicité évidente pour ces deux jouisseurs. Le plaisir avant tout ! »",
        LVA :"Une osmose et des goûts communs pour les découvertes, le sport et les voyages. Tous deux très généreux, vous vous accorderez sur votre vision du couple, plutôt libre et sans contraintes. Partager de bons moments de plaisir et de joie de vivre correspondent à vos attentes communes. D’un point de vue sexuel, l’objectif est de partager un moment délicieux, ardent et sans artifices, juste pour le plaisir, sans attendre plus de l’autre.",
        Plus:"Une relation sous le signe de la bonne humeur – Une passion tempérée – Une relation libre – Des loisirs communs comme le sport.",
        Moins:"Une relation un peu légère basée sur la complicité et risquant de devenir copain copine - La fidélité n’est pas au programme.",
        Craquer:"Elle est l’amie-amante que vous avez toujours voulu rencontrer, vous pourrez lui proposer n’importe quel plan, elle sera toujours partante parce que tant qu’il y a du fun il y a de la vie. Votre histoire est une franche rigolade où la sensualité n’est pas en reste, car vous vivrez le sexe dans une spontanéité enivrante. Seules ses crises de jalousie et ses états d’âme amoureux trop fréquents pourraient refroidir vos ardeurs, car il ne faut pas toucher à votre liberté chérie, ni gâcher votre plaisir par des futilités,  vous seriez intransigeant et n’hésiteriez pas à trancher dans le vif, par une rupture brutale et sans appel.",
        Coach:"Vous vous entendez comme deux larrons en foire. Actifs et aventuriers, vous serez toujours prêts à vivre pleinement vos envies, ce qui compte c’est le fun, sans prise de tête. Votre devise est Carpe Diem. Relation basée sur le plaisir. "
    } ,
    Sagittaire_Taureau :{
        Slogan:"« La liberté de l’un empêchera l’épanouissement de l’autre. Relation manquant de tolérance. »",
        LVA :"Relation difficile, diamétralement opposée dans vos envies et votre façon de voir la vie à deux. Madame Taureau a ses habitudes et aime la routine de la vie de couple, cela la rassure, alors que vous ne vivez que de changements, de mouvements, vous êtes un aventurier au sens propre comme au sens figuré. Vous ne vous entendrez que dans la sexualité, car vous êtes tous les deux des jouisseurs  épicuriens qui aiment les plaisirs de la vie. Mais, trop indépendant,  vous fuirez la possessivité de Dame Taureau qui attend un engagement que vous ne serez pas prêt à lui garantir.",
        Plus:"Le partage de bons moments, des plaisirs de la chair et de la table – L’amour de la nature,  des plaisirs simples. ",
        Moins:"Le sagittaire est un libre et le taureau veut l’attacher – La jalousie et la possessivité du taureau – L’inconstance du sagittaire  et son infidélité.",
        Craquer:"Très féminine, sensuelle et aguicheuse, vous ne pouviez que la repérer, après tout, n’a-t-elle pas tout fait pour ça ? Elle est si appétissante que vous passeriez bien quelques instants coquins avec elle. Si elle ne semble pas farouche, elle ne sait pas vivre des moments de plaisirs enivrants sans parler d’amour. Vous, vous ne mélangez pas tout. Alors pourquoi se prendre la tête dans des moments de pur bonheur ? Cette  femme est bien  trop compliquée dans sa tête et ne sait vraiment pas se laisser aller au plaisir de l’instant présent.",
        Coach:"La possessivité du Taureau va se heurter à l’excès d’indépendance du Sagittaire. Malgré leurs goûts communs pour les plaisirs simples de la vie, le Taureau tentera en vain de freiner l’exubérance du Sagittaire ce qui plongera le Taureau dans un sentiment d’insécurité. "
    } ,
    Sagittaire_Gemeaux : {
        Slogan:"« Cours après moi que je t’attrape, rien de tel pour aiguiser les sens et tuer l’ennui. »",
        LVA :"La monotonie n’existe pas dans votre relation, car vous avez en commun le goût des mouvements, des déplacements et des voyages. Votre complémentarité crée votre attirance. Tous deux avides de connaissances, vous tirerez pas mal de satisfactions à vos échanges  d’idées. La liberté et la compromission faisant partie de vos personnalités, la fidélité ne sera pas l’ingrédient principal de votre histoire. Votre relation sera plutôt basée sur une amitié amoureuse plus que sur le grand amour.",
        Plus:"Leur amour de la liberté – L’appel des grands espaces – La curiosité intellectuelle – La tolérance et le respect de la liberté de chacun.",
        Moins:"Un manque certain de passion  amoureuse – L’infidélité – Le refus d’engagement – L’hyper-indépendance du sagittaire.",
        Craquer:"Vous serez emballé par sa légèreté et sa fraîcheur juvénile. Elle est franchement drôle, vive d'esprit, ce qui vous amuse au plus haut point. Elle s'enthousiasmera comme vous à chaque proposition de sorties ou de voyage. Elle aime s’amuser, bouger  et rire. Elle serait parfaite si dans vos moments intimes elle était un peu plus délurée. Elle est bien trop intellectuelle et chichiteuse. Elle gâchera vos soirées par ses manières directives de désapprouver vos petits débordements. Débordements qui ne sont pourtant que quelques petits flirts insignifiants ! Pas de quoi en faire toute une histoire ! ",
        Coach:"Une truculente aventure entre ces deux curieux et jouisseurs de la vie qui s’entraîneront dans une relation légère et parfois court vêtue… où les tabous seront absents, l’ennui exclus et la liberté chérie. Mais qu’en est-il de la vraie vie ? "
    },
    Sagittaire_Cancer :{
        Slogan:"« Des débuts prometteurs mais des envies trop divergentes pour que ça colle. »",
        LVA :"Le Sagittaire, aventureux et voyageur,  alimente l’imaginaire de Madame Cancer, douce rêveuse, qui voit en cette relation l’ouverture vers un amour exotique et hors norme. Tout sera possible entre vous, à partir du moment où aucune discussion sérieuse ne sera entamée. Dans le cas contraire, notre aventurier des temps modernes n’hésitera pas 5 minutes à prendre la poudre d’escampettes. Le plaisir avant tout, mais pourquoi voudrait-il donc échanger sa vie de liberté pour une vie remplie d’obligations ? ",
        Plus:"Ils sont tous deux épicuriens - Ont leur tête remplie de rêves - L’imagination est au centre de leur vie – Ils aiment faire la fête.",
        Moins:"Le sagittaire aime être libre sans obligations alors que le cancer a besoin d’avoir une vie d’obligations – L’infidélité.",
        Craquer:"Elle a su vous attirer grâce à des atouts de charme irrésistibles, il est vrai que vous ne vous posez même pas de questions, vous foncez. Elle vous inspire des moments voluptueux et vous n’êtes pas de ceux qui laissent  échapper un instant de plaisir quel qu’il soit. Vous la trouverez même douée pour vos jeux interdits, câline et coquine à la fois, quel délice ! Mais sa fâcheuse manie de vous harceler de messages, de vous parler de la maison de ses rêves, du chien et des trois marmots que vous aurez ensemble, vous font regretter vos égarements avec elle. Vous ne pensiez pourtant pas lui avoir fait de fausses promesses ? ",
        Coach:"Une rencontre qui ne peut être que le fruit d’un heureux hasard galant. Leur peu d’affinités ne peut espérer plus qu’un moment d’égarement. Le pétillant Sagittaire sera vite refroidi par les fluctuations émotionnelles du très angoissé Cancer. "
    } ,
    Sagittaire_Lion :{
        Slogan:"« Relation ardente et conquérante, une aventure passionnante et grisante. »",
        LVA :"Rencontre de deux natures enthousiastes et généreuses, enclines à jouir pleinement de la vie. Votre relation sera basée sur la spontanéité, la sincérité et la franchise. Ce duo de feu, aux goûts de luxe et aux dérives excessives auront une complicité à toute épreuve, basée sur les plaisirs à deux. Votre sexualité est gourmande et fougueuse. Seule divergence, le Lion se complet à créer un nid confortable et fastueux alors que le Sagittaire rêve de parcourir le monde ne s’attachant pas à un lieu fixe.",
        Plus:"Leur nature passionnée et sincère – Épicuriens et jouisseurs ils partageront le plaisir des fêtes et des soirées bien arrosées.",
        Moins:"Leurs colères sont dévastatrices – Ils sont tous deux très orgueilleux – L’autorité du lion se heurte à l’indépendance du sagittaire.",
        Craquer:"Vous l’aurez certainement rencontré à une soirée mondaine avec des peoples, ces soirées où vos amis branchés vous invitent toujours. Elle vous éblouit de sa beauté hautaine, et les prétentieuses ça vous excite, vous aimez les défis et votre bagout sait comment les séduire. Vous êtes un  caméléon, un joueur qui s’adapte aux personnages qui vous intéressent. Cette femme est une grande passionnée avec laquelle vous vivrez des moments intenses de plaisirs. Seuls les moments où elle croira pouvoir user d’autorité sur vous, créeront des conflits où vos deux natures excessives surenchériront dans la colère, mais vous saurez vous réconcilier… sur l’oreiller...",
        Coach:"Joli couple de signes de Feu, formé dans des élans enthousiastes d’une passion ardente et joyeuse. Une complicité motivée par des sentiments nobles et entiers dont il faudra soustraire toute compétition pour éviter de tout gâcher. "
    } ,
    Sagittaire_Vierge :{
        Slogan:"« Désordre et rigueur n’ont jamais fait bon ménage ! l’un range l’autre dérange. »",
        LVA :"Vos différences de nature sont tellement évidentes qu’il sera difficile pour vous de trouver un terrain d’entente. La méticulosité et l’ordre de la Vierge agacent le désordonné Sagittaire. L’esprit d’aventure et d’improvisation du Sagittaire déstabilise la prudente Vierge qui planifie tout à l’avance, évitant toutes surprises qui pourraient l’angoisser. Cependant, la bonne nature enthousiaste du Sagittaire pourrait bien vouloir faire découvrir des horizons insoupçonnés à cette frileuse peureuse Vierge, mais pas moins curieuse et spirituelle.",
        Plus:"Relation dans la curiosité intellectuelle et philosophique de la vie – Tous deux aiment se cultiver et apprendre - Ils sont tous deux épicuriens.",
        Moins:"Des natures diamétralement opposées – Leurs défauts les dérangent et peuvent les amener à se détester.",
        Craquer:"Vous la surprendrez à s’émerveiller en vous écoutant narrer votre dernier voyage en Inde, elle aimerait tellement avoir autant d’audace que vous, mais elle n’ose pas et puis tous ces pays où existent pauvreté et saleté la rebutent, ne mettons-nous pas notre santé en danger dans des coins pareils ? Vous aurez envie de la décoincer un peu et vous vous rendrez compte qu’elle n’est pas aussi timorée qu’elle n’y parait, surtout dans l’intimité, cette femme, a des trésors de volupté cachés derrière un voile de timidité. Sa crainte de l’imprévu qui l’angoisse, va vite donner une tournure pépère à votre histoire. Trop d’organisation pour un adepte de l’improvisation vous fera tomber très vite dans l’ennui.",
        Coach:"Il faudra toute l’exubérance et l’enthousiasme du Sagittaire pour conquérir notre prudente Vierge. Mais leurs caractères antinomiques risquent fort de vouer très rapidement à l’échec cette alliance de l’ordre et de l’éparpillement. Passion éphémère. "
    } ,
    Sagittaire_Balance :{
        Slogan:"« Une belle aventure au rythme de soirées mondaines, de plaisirs sans obligations ennuyeuses.  »",
        LVA :"La rencontre de la Balance et du Sagittaire ne pourra être qu’agréable, car ce qui vous importe, c’est de vivre intensément en profitant de tout ce que l’existence peut offrir de plus attrayant. Votre bonne humeur communicative séduira Madame Balance et elle trouvera en vous un homme ouvert et franc, de nature joviale qui aime les plaisirs, la fête et les soirées mondaines. Vous, vous apprécierez sa nature accommodante qui s’adaptera à votre nature aventureuse et atypique. ",
        Plus:"Leurs goûts pour tous les plaisirs de la vie – Ces deux êtres aiment afficher leur culture – L’adaptabilité de la balance – La tolérance du sagittaire  ",
        Moins:"Leur nature infidèle – Les emportements colériques et autres excès de certains sagittaires – Leur inconscience financière – La grossièreté du sagittaire.",
        Craquer:"Vous vous croisez dans un lieu branché et vous l’avez tout de suite remarqué. Sa beauté presque irréelle, insaisissable, la différencie des autres, elle vous fascine et vous enflamme. Comme vous, elle aime les soirées mondaines et faire la fête. Vous serez un couple très en vue et partagerez le meilleur passionnément. En ce qui concerne le pire, elle vous fera dépenser beaucoup d’argent, car elle aime le beau, le faste, les grands restaurants, madame est une Princesse qui attend que son Prince soit grand seigneur et la gâte à la hauteur de ses attentes. Mais elle sera intransigeante et ne vous passera aucune de vos faiblesses, car Madame attend la perfection. Serez-vous à la hauteur de ses attentes ..?",
        Coach:"Le côté bourgeois et snob de la Balance sera séduit par la nature aventurière, spontanée et joyeuse du Sagittaire, qui lui fera découvrir de nouvelles expériences amoureuses inconnues jusqu’alors. Mais leur passion ne sera que superficielle et éphémère. "
    } ,
    Sagittaire_Scorpion :{
        Slogan:"« Quoi de plus jouissif que de déceler la faille de l’autre. Relation d’emprise réciproque. »",
        LVA :"C’est une attraction irrésistible entre la sulfureuse et ténébreuse Scorpion et le bienheureux Sagittaire grand jouisseur devant l’éternel. Vous allez vous emballer pour cette amoureuse avide de sensations, mais vous vous rendrez compte bien trop tard que vous êtes tombé dans le piège d'une femme torturée. Mais amoureux et enthousiaste, vous penserez certainement à tort que vous réussirez à apaiser les tourments de votre partenaire. Quant à Madame Scorpion destructrice, elle ne pourra que continuer à distiller son venin dans vos naïves certitudes dans le but ultime de vous posséder. Votre indépendance sera fatale à cette histoire.",
        Plus:"Leur quête du plaisir – Tous deux sont passionnés.",
        Moins:"Le pessimisme du scorpion s’oppose à l’optimisme du sagittaire – Le possessif scorpion ne supporte pas l’indépendance du sagittaire",
        Craquer:"Comment ne pas être subjugué par cette beauté mystérieuse et magnétique, vous ne pourrez résister longtemps à l’appel de ses sens. Vous vous emballerez, fougueux et fasciné,  par cette Messaline qui vous attirera dans ses griffes et vous plongera dans une relation placée sous haute tension sexuelle. Mais prenez garde, vous ne jouez pas sur le même registre. Elle veut vous posséder et vous asservir lentement à ses désirs, votre liberté est en danger et c’est à ce prix que vous devrez sacrifier cet amour tortueux.",
        Coach:"Le Scorpion séduit sa proie en l’hypnotisant par son charme magnétique et notre candide Sagittaire, toujours prêt pour une nouvelle aventure, s’engage naïvement dans une histoire d’amour aux odeurs de souffre, où il risque d’y perdre son âme. Amour torturé et tortueux. "
    } ,
    Sagittaire_Sagittaire :{
        Slogan:"« Rencontre de deux  boulimiques de plaisirs. Ils brûlent la chandelle par les deux bouts.»",
        LVA :"Excellente combinaison pour ces deux joyeux drilles dont le leitmotiv premier  est de vivre une vie sans contraintes, juste pour le fun, juste pour la découverte. Vos vies sont un grand laboratoire de multiples expériences où vous aimez goûter ensemble à toutes les joies et tous les  plaisirs que les rencontres peuvent vous offrir. Vous êtes des boulimiques de la vie et désirez vous nourrir d’aventures exaltantes. Mais malgré votre feeling exceptionnel, vous êtes deux instables adeptes de la liberté dans l’amour et vos vie se séparont pour aller découvrir de nouveaux horizons chacun de votre côté.",
        Plus:"Ils voient la vie de la même façon, libre et sans obligations contraignantes – Ils s ‘intéressent aux mêmes sujets, philosophie, voyages, tous les plaisirs de la chair",
        Moins:"Leur infidélité – Leur inconséquence – A force de jouer et de dépenser sans compter, ils se retrouvent souvent dans des situations financières déplorables.",
        Craquer:"Belle, élancée et racée, cette belle pouliche vous aura séduit par sa prestance et sa sympathie. Agréable, elle aime converser et apprendre à connaître l’autre. Vous vous ressemblez beaucoup et vous sentez que vous vous comprenez sans mots dires. Vous aimerez profiter ensemble de loisirs communs et partez souvent en vadrouille aux quatre coins du monde. Mais est-ce vraiment de l’amour ou une très belle complicité amis-amants ? C’est ce qui risque de vous éloigner, partir vivre ailleurs une vraie passion dévorante même si cela ne dure pas, pourvu que vous ayez l’ivresse des sens.",
        Coach:"Un vrai feeling s’instaure entre eux, l’attraction est spontanée, joyeuse et pétillante, débordante de sensations exaltantes. Une entente fondée sur les plaisirs simples et une sexualité impétueuse, mais qui ne s’inscrit pas automatiquement dans la durée. "
    } ,
    Sagittaire_Capricorne :{
        Slogan:"« Trop d’incompréhensions sur leur vision de la vie. L’insouciance se heurte à la responsabilisation.»",
        LVA :"Vous n'avez rien en commun et vous êtes campés sur deux pôles opposés, une entente durable est fortement compromise. En effet, votre nature joviale et enthousiaste peut séduire Madame Capricorne vous trouvant vivifiant. De votre coté, votre insouciance souvent sujette aux achats compulsifs, sera impressionnée par la prudence et la prévoyance de Madame Capricorne. Mais le coup de cœur passé, vos différences irriteront l’un et l’autre, car vous ne vous comprenez pas. La plupart du temps, le Sagittaire sera le premier à fuir cette relation stérile, pour s’enrichir de nouvelles aventures plus chaleureuses et plus passionnantes.",
        Plus:"La sagesse du capricorne rééquilibre la frivolité du sagittaire - La bonne gestion du capricorne renfloue les caisses vides du dépensier sagittaire – L’optimisme du sagittaire rend le sourire au triste capricorne qui entrevoit la vie sous un nouvel angle.",
        Moins:"Leur nature trop différente, la rigidité du capricorne ne convient pas au libertaire sagittaire – Le sagittaire n’aime pas les règles rigoureuses du capricorne.",
        Craquer:"Sa douceur angélique peut vous attirer. Après tout, ce qui vous motive c’est la découverte de l’autre. Dans ce cas précis, on va parler d’effeuillage avec plusieurs couches protectrices cachant la nature profonde de cette ingénue avec des pièges anti-dragueur parsemés çà et là. Malgré les trésors d’ingéniosité que vous déploierez pour décoincer cette donzelle, vous vous rendrez vite compte que ses principes moraux lui interdisent toutes les libertés que vous vous octroyez. Mais c'est que cette histoire va devenir bougrement ennuyeuse ! Et comme vous ne vous attardez jamais très longtemps sur des situations barbantes vous n’hésiterez pas à oublier son numéro de téléphone.",
        Coach:"Une attraction toute relative, plutôt physique, car leurs caractères sont en totale opposition, l’enthousiasme et l’optimisme du Sagittaire sont gâchés par la retenue et le pessimisme du Capricorne. Agacement réciproque, relation impossible. "
        } ,
    Sagittaire_Verseau :{
        Slogan:"« Deux anticonformistes qui se comprennent et jouissent de la vie ensemble. »",
        LVA :"\"Esprit de liberté\" est le maître mot de ce qui relie votre rencontre. Curieux de la vie et empreints d’idéaux élevés, vous partagerez cet amour pour une vie riche humainement et spirituellement et n’hésiterez pas à vous engager pour une noble cause même hasardeuse sans redouter quelconque risque. Votre histoire ressemble plus à une franche amitié qu’à une dévorante passion, mais votre sincérité et votre admiration réciproque n’en font pas moins une relation d’amour profond qui s’inscrit sur la durée.",
        Plus:"Des idéaux élevés – La jalousie est exclue de cette histoire – Respect mutuel de leur indépendance – Entente intellectuelle – Aucune convention rigoureuse ne les lie. ",
        Moins:"L’infidélité du sagittaire et ses excès en tout genre –La sexualité sans tabous et libertine du verseau.  ",
        Craquer:"Avec elle vous ne vous ennuyez jamais, intelligente et cultivée vous partagez le goût des sujets philosophiques et refaites le monde des heures durant. La passion n’est pas exclue, car sa sexualité libérée vous permet d’explorer ensemble de nouvelles expériences sans jugements aucuns. Aussi indépendante que vous, elle ne vous harcèle pas de questions, ne vous met aucun ultimatum, ne vous encombre d’aucune contrainte, vous vous sentez libre d’aimer, pour vous c’est l’amour idéal.",
        Coach:"Rencontre de deux aventuriers idéalistes unis par une soif commune de liberté et d’anticonformisme. Leur relation est peu banale, basée sur un respect mutuel et sur l’originalité de leur personnalité. Une grande aventure amoureuse extraordinaire. "
    } ,
    Sagittaire_Poissons :{
        Slogan:"« Une relation de liberté et de partage des plaisirs qui ne s’arrêtera que lorsque le rêve disparaîtra. »",
        LVA :"Une attraction instinctive déclenchée par la fusion d’énergies amoureuses et sexuelles quasi cosmiques voir spirituelles. Tous deux, empreints de religion, vous verrez dans votre attirance un signe du divin. Le Sagittaire et le Poissons sont des signes mystiques intéressés par les grands courants philosophiques et religieux et partagent le goût pour les plaisirs simples de la vie dont vous saurez vous enivrer ensemble. Mais très vite, la nature dépressive de Madame Poisson vous éloignera. De son coté, elle ne supportera plus votre exubérance fatigante, elle qui aime tant le silence méditatif et le calme.",
        Plus:"Leur nature jupitérienne, ils aiment les plaisirs de la vie et les voyages – Leur nature neptunienne, ils aiment les hautes aspirations religieuses et philosophiques.",
        Moins:"Leur nature infidèle – Leur vision de l’amour est trop différente – L’un est passif et lymphatique,  l’autre est actif et  hyper dynamique – Attentions aux paradis artificiels, ces deux-là aiment l’alcool et la fête.",
        Craquer:"Cette belle Demoiselle à l’allure aérienne, un peu perdue, ne vous laissera pas insensible. La fragilité qui s’émane d’elle vous donnera envie de la prendre dans vos bras. Offerte et soumise au plaisir de l’autre, vos ébats seront sensuels et intenses. Mais sa nature profondément pessimiste et dépressive deviendra vite pesante et ni  votre enthousiasme ni votre joie de vivre n'y changeront rien. Vous n’aurez d'autre choix que de l’abandonner à son triste sort afin de ne pas sombrer avec elle dans son désespoir.",
        Coach:"Une attraction spontanée et passionnelle pour ces deux hédonistes en quête de sensations et de plaisirs charnels excessifs. Mais leurs différences de sensibilité auront du mal à les accorder à long terme."
    } ,
    Capricorne_Belier : {
        Slogan:"« Difficile de trouver quelconque affinité, lorsque l’un avance l’autre recule ! »",
        LVA :"Difficile de trouver un terrain d’entente entre ces deux signes à cornes . Vous vous efforcez de grimper des montagnes escarpées, alors que  Madame Bélier enfonce les portes. Vous êtes lent et/ou prudent, elle est rapide et fougueuse. vous aurez bien du mal à vous retrouver sur un point de compatibilité. Au niveau sentimental, le fossé se creuse. Vous êtes pudique et introverti, alors Madame Bélier est exhibitionniste et extravertie.  Elle réussira cependant à dévoiler quelques uns de vos fantasmes enfouis.",
        Plus:"La droiture et la franchise – Personnes de principes – Des natures contraires qui peuvent être curieuses de se découvrir.",
        Moins:"Deux personnalités entêtées – Autoritaires et intransigeantes – Peu d’affinités sinon aucune.",
        Craquer:"Vous remarquerez rapidement son manège, une danse de la séduction que vous qualifierez comme une sordide approche de drague.  Elle essaie en vain d’accaparer votre attention, mais vous ne supportez pas les femmes exubérantes et trop voyantes qui frisent la vulgarité. Elle parle trop fort et ses rires à gorge déployée vous dérangent. Il vous faudra avoir avaler quelques coupes de Champagne pour vous laisser aller à une soirée coquine avec elle, mais son approche trop directe vous refroidira instantanément. Non, décidément elle est \"too much\" pour vous. ",
        Coach:"Le Bélier soufflera sur les braises de la passion alors que le Capricorne s'évertuera à les éteindre, trop d'emballements le pétrifient. Le Bélier disparaîtra, dépité, en l'espace d'un éclair laissant le Capricorne, seul face à ses craintes. Relation soufflant le chaud et le froid. "
    } ,
    Capricorne_Taureau :{
        Slogan:"« Relation basée sur la confiance, avec la patience on arrive à tout. »",
        LVA :"Ces deux-là savent œuvrer pour l’avenir, patients et déterminés vous prendrez le temps de vous connaître et construirez les fondations de votre relation sur une confiance mutuelle. Vous vous comprennez et savez exactement ce que l’un attend  de l’autre. La générosité et la chaleur humaine de Madame Taureau ravivera votre flamme et vous mettra en confiance. La base de votre histoire d’amour est le respect de l’autre. Sexuellement, Madame Taureau, plus passionnée, saura vous transcender et vous faire sortir de votre réserve.",
        Plus:"Un respect mutuel les  anime – Leur nature posée les rassure – Le hasard ne fait pas partie de cette relation, tout est réfléchi - La solidité terrienne de la relation.",
        Moins:"La chaleur du taureau ne compensera pas toujours la froideur du capricorne – Les ambitions de l’un peuvent amener la perte de l’autre.",
        Craquer:"Une femme pareille c’est de la bombe ! Sensuelle, attentionnée et véritable fée du logis, elle ne sait que faire pour vous plaire et vous rassure par ses mots d’amour. Sérieuse, elle désire une relation stable sur le long terme comme vous. Bien sûr vous serez emballé par tant d’affinités, si vous ne la trouviez un peu trop matérialiste, car elle s’intéresse de très près à vos opérations boursières, de trop près  même. Votre méfiance naturelle testera cette femme d’argent, pour sonder sa sincérité avant de vous engager plus en avant dans cette relation.",
        Coach:"Fondations solides pour ce couple de terriens qui s’attirent principalement par leur côté pragmatique sécurisant. Le Taureau n’hésite pas à user de tout son charme pour convaincre le Capricorne, ce coriace amoureux.  Relation équilibrée. "
    } ,
    Capricorne_Gemaux : {
        Slogan:"« Trop de lucidité tue le rêve, trop de fantaisie fait perdre le réalisme. Personnalités en déséquilibre. »",
        LVA :"Vous n'avez pas grand-chose en commun. On pourrait même dire que vous êtes diamétralement opposés. Madame Gémeau, légere, versatile et bavarde aura du mal à supporter votre compagnie. Elle vous trouve trop sérieux, réservé, peu communicatif et de surcroit ennuyeux, en tout cas pour elle. Vous, solitaire amoureux du calme, aurez bien du mal à vous encombrer de cette agitée du bocal, qui s’excite pour un oui ou pour un non. Côté sexe, ce n’est pas la joie non plus.",
        Plus:"Seule la créativité du gémeau s’accorderait au pragmatisme du capricorne pour une association professionnelle. ",
        Moins:"L’un sérieux, l’autre fantaisiste et inconscient – L’éternelle jeunesse contre la vieillesse avant l’âge – La liste des dissonances est trop longue à énumérer.",
        Craquer:"Vous échangerez quelques joutes verbales avec elle, histoire de vous échauffer un peu et  voir ce qu’elle a dans le cerveau. Vous serez surpris par sa dextérité oratoire et sa finesse d’esprit, vous la trouverez brillante, intelligente. Pleine d’humour, elle saura même vous voler un sourire, il faut dire que son air mutin vous ravit. Mais très vite, vous comprendrez que cette femme est ingérable, légère et sans consistance, elle virevolte, séduit à tous vents, et s’agace vite à la moindre remarque. Vous recherchez une femme stable, mais elle, c’est l’instabilité personnifiée, à qui l’on ne peut donner sa confiance. Non décidément, vous ne pouvez vous engager dans une relation durable avec elle, tout au plus, passer un moment,  le temps d’une soirée, juste pour le plaisir !",
        Coach:"Rencontre de l’insouciance et de la sagesse, une mayonnaise qui ne prendra probablement pas ou qui ne dépassera pas la période d’essai. Malgré la bouffée d’air frais qui allégera leur relation, le Capricorne s’agacera de tant de frivolités. "
    },
    Capricorne_Cancer :{
        Slogan:"« Des différences qui ne nuiront pas à leur bien-être. La chaleur rassurante de l’amour cimentera la relation. »",
        LVA :"Malgré vos discordances, vous vous complètez parfaitement. Votre nature méfiante, masque sous votre air austère un  manque affectif  qui vous paralyse dans vos histoires de cœur. Grâce à sa sensibilité exacerbée, Madame Cancer devine vos attentes de tendresse et saura vous combler par sa douceur et ses attentions. De son coté, elle se sentira protégée et rassurée par votre honnêteté inébranlable. Cette relation s’inscrit dans la durée, seules vos natures pessimistes peuvent faire des dégâts.",
        Plus:"Ils se sont trouvés et comblent leurs manques respectifs – Ont tous deux besoin d’être rassurés. ",
        Moins:"Une sexualité timide – Le manque de tendresse et de romantisme du capricorne – L’hypersensibilité du cancer.",
        Craquer:"Très féminine et sensuelle, elle incarne la femme avec un grand F. Avec elle, vous vous sentez aimé, elle ne sait que faire pour vous faire plaisir. Toujours aux petits soins et parfaite maîtresse de maison, elle est la femme idéale pour vous accompagner tout au long de votre vie. Seuls ses caprices de petite fille gâtée vous exaspèrent, mais vous savez sévir et la remettre sur le bon chemin, après tout c’est vous qui menez la danse et au final elle aime ça, se soumettre à son homme.",
        Coach:"L’alchimie entre ces deux signes n’est pas toujours évidente. Seule la persévérance du Cancer à percer la cuirasse défensive du Capricorne par de tendres attentions et une sensualité débordante, réussiront à développer un amour profond entre eux. "
    } ,
    Capricorne_Lion :{
        Slogan:"« Les emballements du Lion se heurteront à un mur froid d’indifférence. Dur, dur ! »",
        LVA :"Difficile de trouver un terrain d’entente. Vos personnalités sont diamétralement opposées. C’est la rencontre du feu et de la glace. Madame Lion exubérante, optimiste et théâtrale, adore la provocation et les excès en tout genre, alors que vous n’aspirez qu’au calme, à la solitude et détestez le clinquant, les mondanités, que vous qualifiez de frivolités inutiles. Seules vos ambitions et votre goût du pouvoir pourront vous relier, mais pourront aussi vous confondre.",
        Plus:"L’amour de l’argent et du pouvoir – Le capricorne canalisera les dépenses excessives et superflues du lion – Le lion dégèlera son partenaire capricorne et lui apportera une dose non négligeable d’optimisme.",
        Moins:"Tout les oppose – L’un extraverti l’autre introverti – L’un mondain  l’autre solitaire – L’un dans l’excès et l’autre dans la restriction – La froideur du capricorne désintéresse le passionné lion.",
        Craquer:"C’est une belle femme altière qui pourrait bien vous séduire, elle sait se mettre en valeur et dégage un je ne sais quoi qui pousse à l’admiration. Mais avec ses dehors ultra féminins, elle dégage pourtant une puissance très masculine, autoritaire et intransigeante. Quelque part vous vous ressemblez et désirez tout deux le pouvoir et l’argent. Ce qui pourrait vous unir risque aussi de vous désunir, car qui de vous deux va décider, mener le couple ? Vous bien sûr, mais en avez-vous discuté avec l’intéressée ? Cela ne se discute pas,  cela s’impose. Certes, voilà qui va corser votre histoire, de rapports de force et de conflits de pouvoir, on en connaît l’issue...",
        Coach:"Étant l’un et l’autre en recherche d’une personne au-dessus de la moyenne, ils s’attireront grâce à une admiration réciproque, surtout s'ils ont un statut social élevé, mais la difficulté sera de trouver leur place au sein du couple, le pouvoir ne pouvant être partagé. "
    } ,
    Capricorne_Vierge :{
        Slogan:"« Voilà du solide ! Entente et respect mutuel pour une vie paisible, mais sans surprise. »",
        LVA :"Rencontre de deux signes de Terre stables. Vous recherchez tous les deux un partenaire sérieux et fiable, capable de satisfaire vos exigences amoureuses complexes et les contingences de la vie quotidienne. Les emportements aveugles ne font pas partie de vos personnalités, car  vous avez besoin tous deux d’évaluer minutieusement votre prétendant avant de vous laisser aller aux tourments de l’amour. Un sentiment d’estime et de respect vous rapprochera avant de vaincre vos retenues et de laisser transparaître vos sentiments.",
        Plus:"Ils s’apportent mutuellement des garanties de fiabilité qui les rassurent – Ils sont de bons partenaires pour créer un couple ou monter des affaires.",
        Moins:"Le manque de passion – L’absence de surprises.",
        Craquer:"Cette femme réservée vous intéresse et correspond tout à fait au style de femme que vous recherchez. Point d’excentricités vestimentaires, ni dans ses comportements, ni dans ses propos. Intelligente, on sent qu’elle aime se cultiver, son discours est posé, cartésien. Elle est vraie tout simplement. Attentionnée et prévenante, vous l’imaginez comme la femme idéale pour vous seconder dans la construction de votre foyer. La garantie de stabilité qu’elle vous apportera,  vous encouragera à lui exprimer un amour profond, certes sans débordements, mais sincères.",
        Coach:"Leur aspiration à trouver un partenaire sérieux attireront spontanément ces deux signes de terre l’un vers l’autre. Les joies de l’amour ne se libéreront qu’une fois les exigences du cœur et de la vie pratique satisfaites. Sentiments d’estime et d’amour profond. Cependant, cette relation manquera de Passion. "
    } ,
    Capricorne_Balance :{
        Slogan:"« Une conciliation impossible entre l’oisiveté de l’un et les ambitions de l’autre.  »",
        LVA :"Difficile de comprendre ce qui a pu vous attirer l’un vers l’autre, vous êtes de nature si différente. On ne peut imaginer un couple aussi mal assorti, c’est une des pires configurations astrales. Certainement vous êtes-vous rapprochés à la suite d’une forte déception amoureuse qui aurait affligé vos vies. Madame Balance, désabusée, se sera retournée vers vous pour rechercher une sécurité morale et financière. Quant à vous, la chaleur humaine et la gaieté de cette femme n’auront été là que pour égayer votre morne vie.",
        Plus:"Le capricorne ambitieux procure un niveau social à la hauteur des rêves de la balance – La balance est une belle hôtesse sachant représenter fort honorablement   le rang du capricorne",
        Moins:"La froideur du capricorne glace la chaleureuse balance – Peu de gestes d’affection et peu de démonstration amoureuse, frustre l’amoureuse balance -L’avare capricorne freine les excès dépensiers de la balance, pour des futilités.",
        Craquer:"Comment ne pas être attiré par une telle beauté ? Élégante et vaporeuse, elle ne peut vous laisser indifférent. Sa nature gaie et chaleureuse ne pourra qu’ajouter le petit plus à son charme qui vous fera succomber inexorablement, mais pour combien de temps ? Quelle déception, lorsque vous comprendrez que derrière cette apparence affable, se cache une oisive en quête d’un mécène qui lui permettra de s’adonner sereinement et sans culpabilités à ses activités préférées, le shopping et les mondanités. Vous n'êtes pas de ceux qui se font berner par un si joli minois, vous recherchez de l'authentique et non du superficiel.",
        Coach:"Rencontre de l’insouciance et de l’austérité qui laisse présager une relation amoureuse basée sur l’amour du pouvoir et du décorum où l’émotion et les sentiments sont exclus. Complicité difficile."
    } ,
    Capricorne_Scorpion :{
        Slogan:"« Quoi de plus jouissif que de déceler la faille de l’autre.Relation d’emprise réciproque.»",
        LVA :"Ces deux signes sont les plus résistants et coriaces du zodiaque, tous deux ne rechignent pas à la difficulté bien au contraire, ils sont stimulés par les contraintes et les montagnes escarpées. Vous n'êtes pas insensible au charme ravageur de Madame Scorpion qui est sûre de pouvoir faire faiblir votre cœur de pierre et votre sévère froideur.  Vous vous laisserez volontiers emporter dans une passion folle mais contrôlée. Vous aimez tous les deux le pouvoir et vous n'êtes pas de ceux qui se soumettent. Vous vous respectez mutuellement, reconnaissant vos valeurs de dominant.",
        Plus:"Leur résistance à la souffrance – Leur curiosité intellectuelle – Leur soif de pouvoir –Leur respect mutuel – Des valeurs morales qui leur sont chères.",
        Moins:"Tous deux sont des dominants – Le capricorne manque de passion pour le scorpion – La rivalité dans la quête à la réussite.",
        Craquer:"On ne peut pas dire qu’elle soit insignifiante, bien sûr elle n’est pas le style de femme qui vous attire habituellement, mais elle dégage un tel magnétisme que vous ne pourrez résister longtemps à son appel. Intelligente, elle brille par son esprit vif qui capte immédiatement la subtilité de vos propos. Vous qui êtes sentimentalement réservé, sa passion et ses débordements amoureux vous rassurent sur la solidité de ses sentiments. Elle saura intuitivement comment vous ouvrir et vous permettre de  vous laisser aller à vos émotions. Vous devrez juste la recadrer par moment, car chez elle, tout est dans la démesure.",
        Coach:"Attiré par l’inaccessible, le Scorpion mettra un point d’honneur à faire céder à ses avances pressantes ce Capricorne froid et austère. Tous deux ont le goût du pouvoir et le culte du travail et se respectent pour ces qualités, mais auront du mal, à terme, à se supporter. "
    } ,
    Capricorne_Sagittaire :{
        Slogan:"« Difficile de trouver quelconque affinité, lorsque l’un avance l’autre recule ! »",
        LVA :"Vous n'avez rien en commun et vous êtes campés sur deux pôles opposés, une entente durable est fortement compromise. En effet, la nature joviale et enthousiaste de Madame Sagittaire peut vous séduire, la trouvant vivifiante. Madame Sagittaire, insouciante, souvent sujette aux achats compulsifs, sera impressionnée par votre prudence et votre prévoyance. Mais le coup de cœur passé, vos différences irriteront l’un et l’autre, car vous ne vous comprendrez pas. La plupart du temps, Madame Sagittaire sera la première à fuir cette relation stérile, pour s’enrichir de nouvelles aventures plus chaleureuses et plus passionnantes.",
        Plus:"La sagesse du capricorne rééquilibre la frivolité du sagittaire - La bonne gestion du capricorne renfloue les caisses vides du dépensier sagittaire – L’optimisme du sagittaire rend le sourire au triste capricorne qui entrevoit la vie sous un nouvel angle.",
        Moins:"Leur nature trop différente, la rigidité du capricorne ne convient pas au libertaire sagittaire – Le sagittaire n’aime pas les règles rigoureuses du capricorne.",
        Craquer:"Vous l’aurez certainement rencontré lors d’une soirée mondaine, déambulant de convives en convives, un verre à la main, le sourire aux lèvres, un petit mot agréable pour tous, on la prendrait presque pour la maîtresse de maison, et pourtant elle n’est qu’une invitée comme une autre. Son aisance en société la rend populaire et elle adore ça. Petit bémol, elle sera d’un abord un peu hautain, on pourrait même dire snob. Mais cela ne vous dérange pas, bien au contraire, vous aimez les femmes intelligentes, fines d’esprit et distantes. Cependant, ses propos féministes auront raison de votre intérêt pour elle, les rebelles qui revendiquent haut et fort leur indépendance au sein du couple dénigrant la gente masculine, très peu pour vous.",
        Coach:"Une attraction toute relative, plutôt physique, car leurs caractères sont en totale opposition, l’enthousiasme et l’optimisme du Sagittaire sont gâchés par la retenue et le pessimisme du Capricorne. Agacement réciproque, relation impossible. "
    } ,
    Capricorne_Capricorne :{
        Slogan:"« Deux bourreaux de travail qui devront apprendre à prendre du temps pour s’aimer. »",
        LVA :"Vous avez été créés sur le même moule de rigidité et de valeurs de sérieux. Ambitieux, vous favoriserez pourtant vos carrières à votre foyer et un certain esprit de compétition risque de gâcher cette harmonieuse relation sans heurts, qui ressemble plus à une association qu’à un couple. Mais le modèle fonctionne bien, chacun connaissant exactement sa place et ses contingences et les acceptants. Bien sûr tout ceci manque cruellement de chaleur affective, mais en avez-vous réellement besoin ? ",
        Plus:"Des valeurs de vie commune – La même rigidité et le même sérieux – La Fidélité – La même vision de la vie.",
        Moins:"Le manque de chaleur – La sécheresse affective et sexuelle – Ils ont besoin pour se désinhiber d’un partenaire plus gai et rafraîchissant –La compétition à la réussite.",
        Craquer:"D’une rare beauté, elle vous subjuguera par la classe naturelle, sans artifices qu’elle dégage. Sa réserve accentue son aura de mystère, ce qui vous enchantera, et à la voir ainsi, vous auriez envie de la prendre dans vos bras, elle semble si fragile… Mais derrière ce masque de timidité, se cache un caractère entier et bien trempé qui sait mener de main de maître sa vie, ce qui n’est pas pour vous déplaire non plus. Elle sait ce qu’elle ne veut et ce qu’elle attend d’un homme et ne supporte pas de perdre son temps avec les indécis ou les irresponsables. Pour vous c’est la femme idéale, celle que vous avez toujours espéré.",
        Coach:"Une attirance miroir, pour une histoire sans histoires manquant terriblement de piment. Une relation modérée dans le contrôle où la passion n’a pas sa place. L’ennui les motivera à trouver un partenaire plus fantaisiste. "
        } ,
    Capricorne_Verseau :{
        Slogan:"« Ils auront du mal à s’apprivoiser, leur méfiance sera plus forte que leur curiosité. »",
        LVA :"Bien sûr il peut y avoir attirance spontanée entre vos deux signes qui sont pourtant  incompatibles. En effet, Madame Verseau peut vous séduire grâce à sa nature compatissante et sympathique, surtout si vous êtes dans une de vos périodes de déprime chronique. De son coté, elle appréciera votre sérieux et votre pragmatisme. Mais cette relation ne durera pas très longtemps, orpheline de tout sentiment affectif, votre vision de la vie est bien trop divergente, Madame Verseau aime l’imprévu alors que vous, planificateur, paniquez devant l’inconnu. Que vous vous contentiez d’une relation intellectuelle serait plus réaliste.",
        Plus:"Leur curiosité intellectuelle. ",
        Moins:"Ils manquent de chaleur affective – Le verseau anticonformiste se heurte au conservatisme du capricorne.",
        Craquer:"Cette jeune femme truculente vous surprendra par ses comportements pour le moins imprévisibles. Impulsive et spontanée, elle oublie parfois de mettre les  formes dans ses propos et vous choque par ses discours féministes. Vous aurez l’impression qu’elle n’a besoin de personne et aurez du mal à trouver votre place de mâle. Ses projets amoureux n’intègrent pas la vie de couple trop rébarbative et trop conventionnelle pour elle. Passé le coup de cœur, vous ne persisterez pas dans cette relation que vous jugerez bien trop superficielle et sans intérêts.",
        Coach:"Une relation plus amicale qu’amoureuse, perdue dans le refus de l’un et de l’autre à l'engagement amoureux. Le Capricorne craint de souffrir et se replie sur lui-même donnant avec parcimonie, le Verseau protège sa liberté et brille par son absence. "
    } ,
    Capricorne_Poissons :{
        Slogan:"« Ils  sauront prendre soin l’un de l’autre. Relation pansement.»",
        LVA :"L’union entre un signe de Terre et d’Eau est harmonieuse, mais dès que l’on approfondira vos deux natures fondamentalement différentes, on se rendra vite compte que cette histoire n’est viable que grâce aux prodiges de dévouement de Madame Poisson envers cet homme peu fantaisiste et peu compatissant que vous êtes. Mais Dame Poisson se sentira protégée par vos qualités de sérieux et votre ambition, elle qui est si peu courageuse à la tâche ! Malgrés votre égoïsme et votre insensibilité, elle s’entêtera à sauvegarder cette histoire comme un précieux cadeau du ciel malgré vos reproches blessants.",
        Plus:"L’amour inconditionnel du poisson réchauffe la froideur du capricorne – Le capricorne apporte une sécurité financière au bohème poisson - Le poisson par sa sensuelle générosité désinhibe le capricorne.",
        Moins:"Les sarcasmes, la froideur et le manque de sensibilité du capricorne heurte l’émotif poisson qui le trouve inhumain - Le capricorne se lasse de cet être qu’il trouve trop utopiste et sans volonté.",
        Craquer:"Cette beauté éthérée vous touche par la fragilité qui s’émane de tout son être. Elle semble perdue, une âme égarée en quête de l’amour au sens le plus noble. Elle attend de vous que vous la protégiez, se sentant agressée par le monde extérieur qu’elle ressent si cruel. Dévouée, elle saura se donner corps et âme à votre cause, sans rechigner, attendant juste un geste d’affection de votre part, pour se nourrir de votre amour. Son manque de volonté aura tendance à vous exaspérer, attendant d’elle un peu plus d’entrain. Mais son abnégation vous fera oublier tout grief, laissant la place à votre compassion.",
        Coach:"Une attraction quasi curative, car ils savent combler instinctivement leurs besoins affectifs. Le Capricorne sécurise le Poisson et calme ses craintes, qui en retour lui prodiguera une tendresse et un dévouement sans bornes qui adoucira la rigidité du Capricorne. "
    } ,
    Verseau_Belier : {
        Slogan:"« Un goût commun pour la découverte de sensations insolites. Ils se comprennent.»",
        LVA :"Votre amour commun de liberté peut vous permettre de construire un couple sur une base de complicité et d'union libre. Vous êtes original et curieux et vous vous laisserez volontiers emporter dans la folle passion de Madame Bélier. Elle appréciera que vous ne l’étouffiez pas et que vous fermiez les yeux sur ses \"coups de canif\" dans le contrat. Vous partagerez des loisirs, des amitiés si chères à votre équilibre de vie. Votre relation sera surtout basée sur de la tolérance et sur l’espace que l’un concédera à l’autre.",
        Plus:"Le respect de la liberté de chacun- Une sexualité basée sur la découverte de nouvelles expériences –La passion.",
        Moins:"La distance du verseau qui peut sembler de l’indifférence – Les excès de colère du bélier- Ils ont tous deux des natures très insouciantes avec l’argent.",
        Craquer:"Tant que l'approche ne sera que dans la séduction et que vous vous  titillerez, exacerbant l'excitation afin de déclencher l'étincelle qui va sonner l'ouverture des jeux de l'amour, tout ira bien... Elle vous emportera dans une folle passion à la fois sentimentale et sexuelle, le Nirvana ! Mais tôt fait qu'elle empiètera sur votre espace vital, les hostilités commenceront. Car elle est comme ça, elle grignote jour après jour un peu plus, telle une boulimique de l'amour, elle en veut toujours plus. Se mettant dans des rages folles si vous ne satisfaites pas ses moindres désirs. Mais vous n'êtes ni conflictuel, ni soumis à la volonté de Madame, il faudra bien qu'elle s'y fasse ou partir.",
        Coach:"Ils ne craindront pas de s'évader  ensemble dans une folle aventure, aussi longtemps que l'excitation demeurera. Ils partageront le goût de la liberté et de l'insolite ainsi que l'amour sans tabous. Relation fusionnelle dans la compréhension mutuelle. "
    } ,
    Verseau_Taureau :{
        Slogan:"« Dialogue difficile entre ces deux idéalistes divergents. Conservatisme contre démocratie.»",
        LVA :"Vous aimez par-dessus tout votre liberté et ne supportez pas de vous  justifier ni rendre des comptes. Madame Taureau ne supporte pas ce qui lui échappe et a besoin d’être rassuré en permanence. Vous comprendrez donc que vous aurez bien du mal à trouver un terrain d’entente sur le long terme. De plus, ses scènes de jalousie vous feront fuir, car vous ne supportez pas les éclats inutiles. Un point de divergence encore, vous êtes plutôt intellectuel, alors qu'elle est assez basique.",
        Plus:"La fidélité du taureau séduit le verseau qui lui n’est fidèle qu’à lui-même – Le taureau amène une stabilité financière au dépensier verseau.",
        Moins:"L’indépendance du verseau déstabilise le taureau qui rentre dans des rages folles – L’un est solitaire, l’autre a peur de la solitude.",
        Craquer:"L'attraction physique est souvent le révélateur d'une relation... Lorsque vous la croiserez, vous ne pourrez que succomber à ses courbes voluptueuses, elle est si féminine, appétissante et enjôleuse que vous ferez preuve de faiblesse, cédant à la tentation de la chair. De plus, attentive à votre bien-être, vous serez comblé de petites attentions qui  ne feront que s'ajouter à son charme naturelle. Mais ce dévouement a un prix, qui  refroidira totalement votre engouement des premiers instants, véritable pot de colle, elle exigera de vous une présence permanente auprès d'elle, ne supportant pas votre nature solitaire. N'obtenant pas satisfaction, elle rentrera dans des colères noires qui auront raison de votre patience et de votre penchant.",
        Coach:"L'attraction est éphémère, mais avec un désir d’explorer un univers inconnu, car le Taureau se méfie du style libéré du Verseau, qu’il interprète mal et qui exacerbe sa jalousie, étouffant ainsi le Verseau. Une relation très chaotique, un défi impossible. "
    } ,
    Verseau_Gemeaux : {
        Slogan:"« Personnalités atypiques qui s’imbriquent comme des pièces d’un puzzle.»",
        LVA :"Que de belles perspectives d’avenir pour ce couple, chacun trouvant une stimulation d’idées novatrices chez l’autre, se faisant progresser et sublimant leur créativité. Tous deux signes d’air et donc cérébraux, vous avez en commun la recherche de la complicité intellectuelle. Seul hic, une harmonie très importante au niveau de l’esprit, mais un gros bémol au niveau du physique ce qui vous éloignera à terme. Une passion qui deviendra vite une complicité amicale platonique si vous ni mettez pas plus de motivation.",
        Plus:"Une attirance et une osmose intellectuelle – La fusion des idées – Un lien fraternel et complice.",
        Moins:" Le gémeau est un communiquant loquace alors que  le verseau a besoin de moments à lui, de recueillements – Le sexe est ensommeillé – Relation trop amicale.",
        Craquer:"Que dire de cette jeune femme pétillante au tempérament de feu, elle vous transporte de sa bonne humeur, de son charme, jouant avec les mots avec humour et intelligence. Vous êtes tout simplement séduit par cette intellectuelle avec laquelle vous pourrez échanger des heures durant sur n'importe quel sujet. Curieuse, elle peut aborder aussi bien des faits politiques qu'argumenter sur une thèse philosophique. Vous avez trouvé votre alter ego, celle qui vous grisera par l'esprit. Mais qu'en est-il du physique ? Vous saurez réveiller vos sens par une oralité sensuelle et partagerez le goût pour l'imprévu. L'ennui n'est pas prévu au programme des réjouissances, même si parfois elle peut vous assommer de paroles.",
        Coach:"Une attraction instinctive lie ses deux esprits libres, brillants et originaux. Ils échangeront leurs idées et vivront une histoire d’amour atypique sans tabous où l’imprévu les préservera de la routine."
    },
    Verseau_Cancer :{
        Slogan:"« Lorsque la passé s’affronte au futur, difficile de trouver un terrain d’entente.»",
        LVA :"Vos recherches amoureuses sont bien trop différentes pour que l’amour s’installe entre vous. Madame Cancer est une incurable romantique et désespère devant votre indifférence qui fuit le sentimentalisme \"marshmallow\". Intellectuel, cérébralisant vos sentiments, vous avez du mal à les verbaliser, alors qu'elle, très émotionnelle, a besoin de s'épancher sur le sujet, ce qui ne tardera pas à vous étouffer. Bien sûr son magnétisme peut vous séduire, mais pour si peu de temps...",
        Plus:"Le besoin de se rendre utile – L’humanisme.",
        Moins:"L’un est passéiste et l’autre avant-gardiste, l’un romantique l’autre réaliste – Tout les sépare.",
        Craquer:"Vous qui êtes plutôt réservé sur les questions amoureuses, sa spontanéité vous touche. Rencontrer une femme obsédée par l'amour ne pouvait pas vous laisser indifférent, mais plutôt dubitatif, voir même curieux. Vous faire dorloter, sentir autant d'intérêt dans les yeux de votre partenaire, ne peut que satisfaire votre égo... déjà bien surdimensionné. Mais devoir répondre à cette boulimique d'amour, par une présence inconditionnelle et par la même démonstration affective, c'est trop vous demander. Vous êtes bien trop attaché à votre liberté et n'adhérez pas aux mièvreries et autres étalages de sentiments.",
        Coach:"Improbable compatibilité pour ces deux signes, dont les orientations de vie divergent à 180°, le Cancer est passéiste alors que le Verseau avant-gardiste. Seule leur fantaisie érotique peut à un moment donné les rapprocher, mais pour si peu de temps… "
    } ,
    Verseau_Lion :{
        Slogan:"« Une complicité évidente, tous deux aiment les êtres intelligents et brillants.»",
        LVA :"Entre vous, c’est la loi de l’attraction et de la répulsion , c’est parfois la dure règle pour les signes astrologiques opposés qui peuvent vivre leur histoire, soit dans la complémentarité, soit dans l’antagonisme. Dans tous les cas, vous ne vivrez pas une relation édulcorée, ni banale. Placée sous le signe de l’impétuosité, vous partagerez le goût pour le monde, les relations amicales et les échanges animés. Mais vos divergences de caractères froisseront l’orgueil de la possessive Madame Lion qui aura bien du mal à vous attacher et vous détourner de votre nature libertine.",
        Plus:"Leur esprit est brillant et tous deux aiment se cultiver et polémiquer- La passion amoureuse - Ils ont une vie sociale bien remplie.",
        Moins:"Ils sont très individualistes voir égoïstes – Le lion ne supporte pas l’indépendance du verseau – Le verseau exècre l’autoritarisme du lion.",
        Craquer:"Une réelle attraction va se produire entre vous. Vous serez séduit par cette lumineuse et  jolie jeune femme. Elégante et extravertie, elle sait se mettre en valeur, ce qui n'est pas pour vous déplaire, vous qui êtes plutôt réservé dans les apparences, vous aimez être flatté par une ravissante compagne. Une véritable passion peut réellement s'installer entre vous avec quelques heurts sur vos différences fondamentales d'aborder l'amour. Vous, si indépendant, aurez du mal à supporter l'autorité et les exigences de Madame, et votre froideur vexera cette orgueilleuse qui a besoin de se sentir admirée et qui attend des  témoignages d'amour avant de se lancer dans l'aventure.",
        Coach:"Une attraction physique irrésistible motivée par une fascination réciproque. Une passion aveugle qui se soldera par un échec cuisant en raison de leurs caractères diamétralement contradictoires et un manque de souplesse. Attirance-Répulsion."
    } ,
    Verseau_Vierge :{
        Slogan:"« Absence rime parfois avec indifférence. Difficile de créer une connivence avec un tel handicap. »",
        LVA :"Vous formerez un couple tout à fait improbable. L’alliance d'un Verseau rebelle et anticonformiste et d’une Vierge méthodique et conventionnelle ne pourra que se confronter  à une incompréhension de sourd et muet. Vous trouverez cependant un terrain de jeu sur des sujets intellectuels qui stimulera une belle amitié amoureuse, mais les manies de Madame Vierge à tout prévoir et son extrême prudence s'opposant à votre insouciance et à votre désinvolture, auront raison de votre patience et du peu d’intérêt que vous vous accordez.",
        Plus:"L’engouement intellectuel – Le verseau admire l’intelligence de la vierge et la vierge est subjuguée par ce bel orateur.",
        Moins:"Les absences du verseau blessent la sensibilité vierge qui attend de l’attention de son partenaire – Conformisme s’affronte à l’anticonformisme. Le verseau est un rebelle alors que la vierge est très disciplinée.",
        Craquer:" Femme à la beauté froide, dégageant une aura quasi mystique, telle une Madonne, elle saura vous attacher par son dévouement inconditionnel. Comme vous, elle a peur de l'engagement et ne rechignera pas à garder un brin d'indépendance, car l'amour lui fait peur. Beaucoup de points communs vous rapprocheront mais beaucoup de divergences vous éloigneront aussi. Vous pourrez installer une relation basée sur une complicité amicale et intellectuelle, mais ses manies de vieilles filles et ses remarques acerbes vous irriteront, vous qui ne supportez pas la critique. Malgré sa fidélité sans failles, vous n'aurez aucun scrupule à chercher du piment avec d'autres conquêtes, au risque de la blesser et de la perdre.",
        Coach:"Une association tout à fait improbable. Seule une curiosité intellectuelle pimentera leurs échanges et pourra donner une illusion d’amour, mais leur différence de fonctionnement créera rapidement des tensions irrémédiables. "
    } ,
    Verseau_Balance :{
        Slogan:"« Vous savez vous apprécier à votre juste valeur sans jugement aucun.»",
        LVA :"Votre relation sera très harmonieuse, car elle alliera l’ingéniosité et l’originalité du Verseau à l’influence Vénusienne de la Balance. Votre attraction ressemble à une amitié-amoureuse basée sur la curiosité et le contact humain. Vous vous harmoniserez dans les domaines de la musique et des arts, vous entourant d’objets rares. Sociable et conciliante , Madame Balance acceptera vos excentricités sans jugements et saura être une hôte accueillante pour vos nombreux ami(e)s.",
        Plus:"Leur nature aérienne  - Le plaisir de la rencontre et le goût de la vie sociale – L’amour de l’art et des antiquités –L’acceptation de leur légèreté amoureuse.",
        Moins:"Le verseau manque de chaleur amoureuse et d’attentions – La solitude du verseau – La frivolité de la balance et ses extravagances financières.",
        Craquer:"Comment ne pas être envoûté par cette nature ultra-féminine, maîtrisant parfaitement l'art de la séduction. Vous trouverez en elle la partenaire idéale, alliant beauté, délicatesse, finesse d'esprit. Vous êtes fait l'un pour l'autre, tolérante, elle respecte votre soif de liberté et sait allier compromis et harmonie, afin de maintenir au quotidien,  une vie sereine au sein du couple. Cependant, vous devrez faire quelques efforts de tendresse afin que cette perle rare ne jette pas l'éponge par manque affectif. Exigeante amoureusement, elle ne pourra se satisfaire d'une amitié amoureuse sans quelques élans passionnels de votre part.",
        Coach:"Une attraction intellectuelle et spirituelle va motiver cette rencontre hors du commun. L’élégance de la Balance va subjuguer notre rebelle Verseau qui brillera par ses éclairs de génie. Harmonie des âmes et spiritualisation de l’amour. "
    } ,
    Verseau_Scorpion :{
        Slogan:"« Relation de curiosité parfois malsaine. Aucun des deux ne cède réellement à l’autre.»",
        LVA :"Pour ces deux rebelles, la relation ne sera pas en demi-teinte, ni de tout repos, ce sera tout ou rien, car vous n'avez pour règles que celles que vous dictez.  Votre devise commune « ni Dieu ni Maître » tout un programme. Vous passerez très facilement de l’amour passionné à la haine, à cause de vos natures obstinées et votre goût pour les histoires compliquées. La routine et les relations insipides, très peu pour vous. Vous avez besoin de vous défier dans un duel où les limites seront  toujours repoussées jusqu’à ce que l’un des deux obtempère. Cela risque d’être cinglant ! ",
        Plus:"Leur besoin d’originalité – Leur atypisme – Leur esprit éveillé –La sexualité sans tabous.",
        Moins:"La possessivité du scorpion et l’ultra-indépendance du verseau – Les colères du scorpion font fuir le verseau qui est anti-conflits.",
        Craquer:"L'attirance est instinctive, car l'un attise la curiosité de l'autre. Un halo de mystère s'émane de cette beauté énigmatique et passionnée car elle n'hésitera pas à vous faire part de son grand intérêt pour vous. Maligne, elle saura vous flatter car elle sera bien décidée à parvenir à ses fins. Vous vous laisserez emporter volontiers, dans son tourbillon amoureux, décidé à goûter à de nouveaux fruits interdits. Mais très vite cette dévoreuse d'hommes va vous lasser de ses excès de jalousies et de possessivité, voulant vous priver de votre liberté chérie. Son agressivité finira par vous faire fuir, et achèvera cette idylle torturée.",
        Coach:"Une relation sous haute tension où ces deux rebelles se défieront dans un combat sans merci, alternant passion et haine. Leur goût prononcé pour les histoires compliquées expliquera leur persévérance dans cette relation sans issue. "
    } ,
    Verseau_Sagittaire :{
        Slogan:"« Deux anticonformistes qui se comprennent et jouissent de la vie ensemble.»",
        LVA :"\"Esprit de liberté\" tels sont les maîtres mots de ce qui relie votre rencontre. Curieux de la vie et empreints d’idéaux élevés, vous partagerez cet amour pour une vie riche humainement et spirituellement et n’hésiterez pas à vous engager pour une noble cause même hasardeuse sans redouter quelconque risque. Votre histoire ressemble plus à une franche amitié qu’à une dévorante passion, mais votre sincérité et votre admiration réciproque n’en font pas moins une relation d’amour profond qui s’inscrit sur la durée. ",
        Plus:"Des idéaux élevés – La jalousie est exclue de cette histoire – Respect mutuel de leur indépendance – Entente intellectuelle – Aucune convention rigoureuse ne les lie.",
        Moins:"L’infidélité du sagittaire et ses excès en tout genre –La sexualité sans tabous et libertine du verseau.",
        Craquer:"Avec elle, vous vous embarquez pour une grande aventure originale sur un rythme effréné, en quête de savoir et de découverte. Vous partagez le goût de la lecture et de l’évasion intellectuelle et saurez rendre agréable vos moments d'intimité. Toujours prêts pour une virée à l’autre bout du monde, l’ennui ne fait pas partie de votre histoire, vous vous amusez, vivez pleinement et sans à priori votre vie sentimentale et sexuelle. Elle, saura pimenter votre vie parfois d’ermite et vous, vous l’emmènerez dans votre monde du libertinage, car avec elle, tant qu’il y a du plaisir il y a l’ivresse, et elle est toujours partante pour explorer de nouvelles expériences. Vous avez trouvé votre alter ego.",
        Coach:"Rencontre de deux aventuriers idéalistes unis par une soif commune de liberté et d’anticonformisme. Leur relation est peu banale, basée sur un respect mutuel et sur l’originalité de leur personnalité. Une grande aventure amoureuse extraordinaire. "
    } ,
    Verseau_Capricorne :{
        Slogan:"« Ils auront du mal à s’apprivoiser, leur méfiance sera plus forte que leur curiosité.»",
        LVA :"Bien sûr il peut y avoir attirance spontanée entre vos deux signes qui sont pourtant  incompatibles. En effet, elle pourrez être séduite par votre nature compatissante et sympathique, surtout si elle se trouve dans une de ses périodes de déprime chronique. De votre coté, vous apprécierez son sérieux et son pragmatisme. Mais cette relation ne durera pas très longtemps, orpheline de tout sentiment affectif, votre vision de la vie est bien trop divergente, vous aimez l’imprévu, alors que Madame Capricorne, planificatrice, panique devant l’inconnu. Que vous vous contentiez d’une relation intellectuelle serait plus réaliste.",
        Plus:"Leur curiosité intellectuelle. ",
        Moins:"Ils manquent de chaleur affective – Le verseau anticonformiste se heurte au conservatisme du capricorne. ",
        Craquer:"Sa réserve peut vous séduire car vous n'aimez pas que l'on empiète sur le terrain de votre popularité, mais n'ayez crainte, les feux de la rampe ne sont pas pour elle, car elle préfère la discrétion à la surexposition. Elégante et brillante, vous serez fier de vous pavaner à son bras, mais les longs silences qui meublent votre quotidien, auront raison de cette histoire sans piment. Sa rigueur vous ennuiera et vous fuirez cette bourgeoise étriquée trop traditionnaliste pour le réac que vous êtes. Vous vous oublierez, occultant la présence de l'autre, sans heurts et sans reproches chacun reprenant le cours de son existence comme si de rien n'était.",
        Coach:"Une relation plus amicale qu’amoureuse, perdue dans le refus de l’un et de l’autre à l'engagement amoureux. Le Capricorne craint de souffrir et se replie sur lui-même donnant avec parcimonie, le Verseau protège sa liberté et brille par son absence. "
        } ,
    Verseau_Verseau :{
        Slogan:"« Ils regarderont dans la même direction, main dans la main. Amitié amoureuse !»",
        LVA :"La compatibilité des signes identiques pêchent la plupart du temps à cause de leur trop grande similitude et engendrent souvent un ennui mortel pour le couple. Mais pas pour les Verseaux qui s’entendent à merveilles et profitent non seulement de leur soif de liberté et du respect mutuel que vous vous accordez, mais également par votre originalité qui donne un piment particulier à votre union. Vous vous enthousiasmez pour les mêmes quêtes et partagez de longues soirées d’hiver à échanger sur divers sujets qui vos passionnent. Le sexe est vécu légèrement et avec désinvolture. L’ennui ne fait pas partie de votre quotidien.",
        Plus:"Leur vision particulière de la vie – Les missions humanitaires qui donne un sens à leur vie – Le respect de leur liberté individuelle.",
        Moins:"L’infidélité – Leur besoin de solitude n’est pas obligatoirement synchronisé.",
        Craquer:"Attiré par l'exceptionnel,  vous ne pourrez qu'être  séduit par cette femme qui se veut anti-conventionnelle et originale. Comme vous, elle déteste l'ordinaire et se refuse à se plier aux règles de la société, car elle aspire à une vie à l'idéal élevé. Vous vous entendrez à merveille, partageant les mêmes courants d'idées, préconisant  un amour libre sans obligations contraignantes ni sentiments excessifs comme la jalousie et la possessivité. Elle vous fera vivre une  existence simple et  heureuse  rythmée par une vie sociale et amicale riche et chaleureuse. Vous aurez trouvé la compagne rêvée.",
        Coach:"Une complicité à toute épreuve, dans une vision identique de la vie et de l’amour. Une relation qui sort des sentiers battus dans le respect de la liberté de chacun et dans une tolérance stimulée par leurs idées avant-gardiste et révolutionnaire. Parfaite symbiose. "
    } ,
    Verseau_Poissons :{
        Slogan:"« Relation avec un esprit ouvert et tolérant en respect de la liberté de chacun et de ses envies.»",
        LVA :"L’entente dépendra de l’évolution psychologique de ces deux personnages un peu perchés, mais dans deux mondes très différents. Vous, hautement intellectuel, survolez ce monde médiocre auquel vous recherchez des solutions philosophiques, vous prenant un peu pour \"Dieu le père\". Madame Poisson, quant à elle,déambule dans un monde émotionnel et mystique à la recherche de Dieu. Relation floue et utopiste, fondée sur un échange de sensations éphémères, mais intenses, que la triste réalité de la vie dissoudra rapidement. ",
        Plus:"Relation mystique et barrée - Leur humanisme",
        Moins:"Leur irréalisme – L’un se prend pour Dieu et l’autre l’invoque – La froideur du verseau déstabilise l’affectueux poisson.",
        Craquer:"Quelle est la personne qui ne rêverait pas d'être adulé surtout lorsqu'on est comme vous, un brin narcissique ?  Elle est une admiratrice aux petits soins, dévouée corps et âme, ne sachant que faire pour vous plaire, docile et patiente, supportant vos réflexions désobligeantes et vos sautes d'humeur, mais toujours là pour vos moindres désirs. Vous êtes tenté, mais vous trouvez quelle n'a pas assez de caractère, qu'elle est trop sentimentale et les mièvreries ça vous agace. Vous aimeriez qu'elle vous tienne tête, qu'elle vous résiste et qu'elle s'affirme un peu plus. Mais vous ne pouvez pas tout avoir, il va falloir choisir : soumise ou rebelle. Alors pourquoi la faire souffrir ? elle est née pour l'amour.",
        Coach:"Une relation romanesque connectée à un univers magique et utopique. Le détachement du Verseau et son manque de tendresse frustrera le Poisson qui ne se nourrit que d’émotions et d’amour. Les limites du rêve amoureux. "
    } ,
    Poissons_Belier : {
        Slogan:"“ Vous vous effleurez sans jamais vraiment vous toucher. Relation de méfiance.”",
        LVA :"Deux univers incompatibles. Vous êtes rêveur, romantique et Madame Bélier trouve que tout ce sentimentalisme n’est que  niaiserie. Elle est très susceptible et vous êtes hypersensible, les rapports seront souvent difficiles,  dans l'incompréhension de ce que l'un attend de l'autre. Madame Bélier belliqueuse, est cependant apaisée par votre nature calme, mais qui l’agace fortement aussi. Vous, vous serez fasciné par cette nature volcanique, mais vous souffrirez de son désintérêt.",
        Plus:"Le poisson désirant plaire absolument au bélier se pliera à tous ses caprices - Relation où chacun trouvera sa place laissant l’autorité au bélier.",
        Moins:"Le poisson  est un romantique né, le bélier exècre l’amour gnangnan – Le bélier entreprend et le poisson attend, quoi ? Il ne le sait même pas lui-même.",
        Craquer:"Vous serez fasciné par cette amazone, dynamique et volontaire qui s'enflammera à votre première rencontre. Elle vous veut et saura vous le faire savoir, merveilleux !  Vous adorez les femmes entreprenantes. Elle vous couvrira d'attentions, vous submergera de sms, vous parlant d'amour sans pudeur. Toujours partante pour n'importe qu'elle aventure, vous ne vous ennuierez pas une minute avec elle.  Boulimique de sexe,  elle ne refusera jamais vos assauts, et en redemandera encore et encore.  Tout serait parfait, mais sa nature volcanique et exigeante s'évertuera à gâcher cette belle complicité. Jalouse et autoritaire, elle vous provoquera à coups de réflexions blessantes et se détournera de vous sans crier gare dans une indifférence brutale si vous ne répondez pas à ses attentes.",
        Coach:"Le Bélier ne cherche qu'à vivre intensément sa passion alors que le Poisson, insidieusement, cherche à l'attacher.  Mais attention cher Bélier, ne l'approchez pas de trop près car , Telle Médusa, le Poisson pourrait bien vous envoûter, mais juste le temps d’un instant.  Relation de méfiance.\" "
    } ,
    Poissons_Taureau :{
        Slogan:" Relation de tendresse ou chacun rassure l’autre de ses sentiments.”",
        LVA :"Douce alchimiste entre vos deux signes.  Vous partagez un goût prononcé pour les plaisirs de la chair et de la table, le romantisme au coin du feu, la musique, le cinéma. D’un point de vue sexuel, la magie opère là aussi. Mais cette relation ne sera pas sans faille, Madame Taureau pragmatique et terre à terre, s’irritera assez rapidement contre vos rêveries utopistes et votre indécision. Vous aurez du mal à comprendre toute cette surexcitation.",
        Plus:"Affinités de goûts – Envie d’amour partagé – Besoin d ‘attentions et de tendresse – Sexualité épanouie.",
        Moins:"Le taureau est carré, terre à terre, le  poisson est utopiste, il fuit la réalité – Le poisson se contente de peu alors que le taureau a besoin de son confort.",
        Craquer:"Comment pouvoir lui résister, cette Vénusienne voluptueuse et charnelle vous met tout en émoi et réveille tous vos sens. Comme vous, elle est épicurienne et sait s'occuper de son homme, attentive à son confort et son bien-être, presque collante. Mais que demander de plus, vous qui n'aspirez qu'à un amour total et fusionnel, vous êtes aux anges, dorloté et aimé à l'excès. Mais attention, elle attendra de vous un peu plus de clarté, d'engagement et une fidélité à toute épreuve, en êtes-vous capable ? Car elle est intransigeante et exigeante, ce qui peut vous rebuter, car votre nature rêveuse fuie les contraintes et se refuse aux rapports de force.",
        Coach:"Une attraction sensuelle qui sera le détonateur d’un embrasement amoureux pour ces deux hédonistes prêts à expérimenter une vaste palette de plaisirs. Le seul bémol, le pragmatisme du Taureau aura du mal à comprendre les rêveries du Poisson."
    } ,
    Poissons_Gemeaux : {
        Slogan:"“ L’agitation de l’un perturbe la quiétude de l’autre.  Incompréhension totale.”",
        LVA :"Une incompréhension totale entre ces deux êtres, qui vont jouer au chat et à la souris,  se tourner autour, se chercher sans réellement se trouver. Leur relation pourrait bien rester à l’état du flirt sans jamais réussir à se concrétiser ou au mieux se transformer en relation purement érotique. Il faut dire qu’une bonne dose d’immaturité jette le trouble dans ces  deux personnalités que tout oppose. Attraction et répulsion s’alternent car leur vision de l’amour est bien trop différente.",
        Plus:"Le flirt -L’illusion d’amour – 	La rêverie amoureuse  - Une sensualité envoûtante.",
        Moins:"Le rêve ne se transformera pas en réalité – Le gémeau a du mal à comprendre le romantisme mièvre du poisson – Le poisson souffre dans cette relation.",
        Craquer:"Sa nature gaie et enjouée vous aura certainement séduit, car votre nature mélancolique a besoin d'un boosteur pour retrouver sa joie de vivre. Elle vous emportera dans ses délires loufoques et vous fera rire de son humour potache. Vous pourrez partager ensemble des moments par ci par là, de pur bonheur infantile, mais rien de bien sérieux, car même si vous n'aimez pas vous engager sur du long terme malgré votre besoin maladif d'amour, avec elle, ce sera d'autant plus dur. Elle virevolte, s'amuse, butine, mais ne se pose pas. Lassé par son trop plein d'énergie, vous préfèrerez retrouver le calme de vos méditations et la laisser à ses monologues éreintants. Vous n'aurez au final pas grand-chose à vous dire.",
        Coach:"Relation extrêmement fluctuante et capricieuse, où ces natures très différentes se heurtent dans une incompréhension réciproque. Le Gémeau est très indifférent aux états d’âme du Poisson qui ne comprend pas cette méfiance à son égard."
    },
    Poissons_Cancer :{
        Slogan:"“ Ils ondulent dans un monde fantasmagorique proche de l’extase.”",
        LVA :"Une attraction magnétique et sensorielle relie ces deux signes d’eau.  Intuitifs et affectueux, vous êtes sur la même longueur d’onde. Votre union sera basée sur un fort besoin de sécurité affective et sur une sexualité imaginative, avec une soif de sensations et d’abandon de soi. L’amour sera au centre de cette alchimie. Mais Madame Cancer sera toujours à l’affût des vos réelles motivations, car vous fuyez et refusez de vous dévoiler totalement. Ce sera la seule ombre au tableau de cette fusion charnelle.",
        Plus:"Une alchimie des énergies – 	Un amour romantique dans la sublimation des sens et des énergies.",
        Moins:"Le manque de réalisme – La passivité – Ils vivent d’amour et d’eau fraîche.",
        Craquer:"Lorsque vous croiserez son chemin, vous ne pourrez résister à son ultra féminité, une véritable sirène qui va affoler vos antennes sensorielles. Cette tentatrice aura raison de vos doutes et vous vous jetterez sans réfléchir dans cette fusion des âmes et des corps. Votre relation sera basée sur l'alchimie de vos émotions et la sensualité de vos corps. Mais attention,  Mademoiselle est capricieuse et exigeante, et risque de vous faire tourner en bourrique, car elle a sans cesse besoin d'être rassurée sur vos intentions et vos sentiments. Alors que vous, vous vivez dans le plaisir de l'instant présent, elle ne peut s'épanouir que si elle se projette dans une construction d'avenir. Cela risque de corser vos relations.",
        Coach:"Des émotions exaltantes sont à l’origine de cette rencontre magique des âmes. Relation douce et poétique où les plaisirs et l’appel des sens jouent un rôle important dans la symbiose de cette union du corps et de l’esprit. Extase amoureuse guidée par leur sixième sens. "
    } ,
    Poissons_Lion :{
        Slogan:"“ Un idéal amoureux qui aurait pu s’accorder si leurs ambitions n’étaient pas si divergentes.”",
        LVA :"Entre vous, difficile de trouver une alchimie. Vous évoluez dans des mondes parallèles où l’incompréhension rendra impossible toute relation harmonieuse. Bien sûr il peut y avoir un coup de foudre, mais Madame Lion, si  impétueuse, franche et directe, sera déconcertée par votre coté doux rêveur et insaisissable.  Même si vous ne contesterez jamais son autorité, vos non-dits et vos faux fuyants la mettront mal à l’aise dans la confiance qu'elle pourrez vous accorder.",
        Plus:" Le poisson soumis ne sait que faire pour plaire au lion majestueux et autoritaire - Le lion apprécie l’amour inconditionnel que lui porte le poisson.",
        Moins:"La passivité et l’abnégation du poisson – Les colères agressives du lion et ses paroles blessantes.",
        Craquer:"Vous ne pourrez qu'être fasciné par la beauté de cette diva sensuelle et théâtrale qui déambule devant vous d'un air provocateur et hautain. Mystérieuse, elle aiguise votre curiosité et vous aimeriez bien approfondir votre attirance dans l'intimité. Elle vous inspire tant de scénarii coquins que vous saurez  la faire succomber à votre charme romanesque par des tirades enflammées. Votre idylle sera passionnément ardente. Mais Madame Lion est castratrice et dominatrice et malgré votre docilité, vous aurez du mal à vous soumettre à sa dureté et à ses exigences. Blessé, vous vous recroquevillerez dans une profonde dépression morale qui la fera fuir à grandes enjambées.",
        Coach:"Une attraction romanesque et sensuelle qui ne durera que le temps d’une étincelle. Une alchimie difficile pour ces deux personnalités évoluant dans des univers très différents. La nébulosité du Poisson met le Lion dans des rages folles. "
    } ,
    Poissons_Vierge :{
        Slogan:"“ Une relation timide mais où l’on s’apporte mutuellement du bonheur.”",
        LVA :"Rencontre de deux signes opposés par vos natures ambivalentes et complexes,  prometteuse d’une relation fascinante ou d’un fiasco complet. Vos mondes sont totalement différents, Madame Vierge a les deux pieds bien ancrés dans la terre et refuse de croire à tout ce qui échappe à sa compréhension analytique, alors que vous naviguez dans un monde irrationnel, transporté par le flux de vos émotions. Cependant, Madame Vierge, en quête d’amour absolu, pourrez être ensorcelée par vos mots doux, et perdre tout contrôle sur sa vie, envoutée par votre irrestitible charme.",
        Plus:"La douceur du poisson attendrit la vierge méfiante – Le poisson met du rêve dans la vie trop pratique de la vierge – La vierge apporte son réalisme pragmatique au rêveur poisson.",
        Moins:"Le poisson est parfois menteur et infidèle car il a un cœur d’artichaut – La froideur et les sarcasmes de la vierge blessent le sensible poisson – La désillusion.",
        Craquer:"Son allure angélique vous a subjugué. Il est vrai qu'elle ressemble à une Madone et dégage une aura de bonté qui ne vous a pas échappé. Votre attirance pour le mystique sera comblée par cette virginale beauté. Mais passée la contemplation biblique, vous n'aurez de cesse d'essayer de réchauffer par vos déclarations enflammées, cette frigide donzelle. Votre intuition ne vous trompe jamais et vous sentez  que derrière cette carapace se cache une ardente maîtresse. Mais est-ce suffisant ? Car ses critiques acerbes sur votre nature bohème, un brin désordonné, et son manque de sensibilité, refroidiront rapidement votre enthousiasme des premiers instants. Son inflexibilité vous ennuie et malgré vos efforts, vous ne trouverez pas grâce à ses yeux.",
        Coach:"Une attraction irrésistible avec une complémentarité où le monde fantasmagorique rencontre le monde de la réalité. Leur relation est à la fois fascinante et déstabilisante. Un enchantement des sens qui s’évaporera telle la magie de l’illusion. "
    } ,
    Poissons_Balance :{
        Slogan:"“ Un manque d’affection comblé mais des caractères trop divergents. Manque d’admiration.”",
        LVA :"Vos natures calmes, refusant tout conflit, ne pouvaient que s’attirer. Votre amour pour la musique, la poésie et bien d’autres sujets artistiques, dénotent deux personnes un peu bohèmes et insouciantes. Mais votre compatibilité s’arrête là, car même si vous pouvez l'impressionner par votre charme hypnotique, lui susurrant des mots doux, Madame Balance se rendra vite compte que vos belles paroles ne sont que des artifices n’ayant pas de consistances amoureuses. Vous la trouverez alors très superficielle et snob, pas assez spirituelle ni même affectueuse.",
        Plus:"Leur goût commun pour l’art, la musique – Ils sont tous deux amoureux de l’amour assez superficiellement.",
        Moins:"L’inconséquence du poisson  et son instabilité – Leur vision de l’amour est trop différente, le poisson sublime l’amour en général alors que la balance cherche le grand Amour.",
        Craquer:"Tel un magnifique objet de convoitise, vous la désirez et vous ne vous lasserez jamais de la contempler tant elle est belle. Vous serez impatient d'émouvoir ce sublime corps. Prévenant et délicat, vous ne rêverez que d'embraser tout son être. Fasciné, vous accepterez ses doutes et ses hésitations à plonger avec vous dans le tourbillon des sensations de l'amour fou et attendrez impatiemment qu'elle s'abandonne. Mais son goûts pour la vie sociale, les mondanités et son besoin de paraître, se pavanant de manière aguicheuse, oubliant votre présence au dépend de sa popularité, vous laissera un goût amer d'abandon, de désintérêt, et refroidiront vos ardeurs. Sa superficialité et son manque de spiritualité achèveront de vous détourner de cette belle créature, trop diva pour vous.",
        Coach:"Le charme hypnotique du Poisson fascine la Balance qui pressent une relation romantique et idyllique. Mais très vite le manque de compréhension et les quiproquos sonneront le glas final de cette illusoire histoire d’amour. "
    } ,
    Poissons_Scorpion :{
        Slogan:"“ Attraction fatale, ils peuvent aussi bien faire leur malheur que leur bonheur. ”",
        LVA :"Il semblerait que vous ayez été destinés l’un pour l’autre. Vous êtes de nature soumise, et la dominatrice Madame Scorpion trouvera en vous l'esclave idéal à ses tyrannies. On parle de relation sado-maso consentie, le fort tempérament de Madame Scorpion profitera de votre docilité et de votre dévouement, car vous aimez vous détruire dans l'amour. Cette relation génère une  forte intensité d’émotions et de sensations sublimées par l'érotisme passionné de Dame Scorpion. Seule sa lassitude à s'amuser avec «ce jouet qui ne dit jamais non» pourra la détourner de cette relation, mais attention, vous pourrez aussi décider de mettre fin à cette histoire, las des ses multiples tromperies et humiliations.",
        Plus:"Des émotions puissantes et exaltantes les animent –Une sexualité débridée.",
        Moins:"Le poisson est en manque d’affection et de tendresse face à l’agressivité du scorpion – La cruauté du scorpion blesse le poisson – La passivité du poisson agace le scorpion.",
        Craquer:"Si vous deviez lui donner un nom ce serait \" Tentation \". Mystérieuse et sexy, elle vous fait tourner la tête et ne pouvez résister à ses appels sensoriels érotiques. Il faut dire que vous êtes connectés. Elle vous envoie des signaux sensuels et vous réceptionnez les messages, fantasmant déjà sur vos corps à corps que vous imaginez brûlants. Une sorte de code amoureux va s'instaurer entre vous, un brin sado-maso où vous aurez du mal à la maîtriser tant elle a du tempérament. Mais touché par ses crises de désespoir, vous supporterez bien volontiers son agressivité qui sera pour vous l'expression de sa souffrance, vous comprenez tant ses états d'âme... Mais attention, que vos névroses ne deviennent pas une excuse pour abuser de paradis artificiels comme remède à vos angoisses communes.",
        Coach:"Une danse macabre d’énergies intenses et envoûtantes instaure une relation sadomasochiste où le Poisson sera la victime et le Scorpion son tortionnaire. Un érotisme torride cimente cette histoire tourmentée. "
    } ,
    Poissons_Sagittaire :{
        Slogan:"“ Une relation de liberté et de partage des plaisirs qui ne s’arrêtera que lorsque le rêve disparaîtra.”",
        LVA :"Une attraction instinctive déclenchée par la fusion d’énergies amoureuses et sexuelles quasi cosmiques voir spirituelles. Tous deux, empreints de religion, vous verrez dans votre attirance un signe du divin. Le Poissons et le Sagittaire sont des signes mystiques intéressés par les grands courants philosophiques et religieux et partagent le goût pour les plaisirs simples de la vie dont vous saurez vous enivrer ensemble. Mais très vite, votre nature dépressive éloignera Madame Sagittaire. De votre coté, vous ne supporterez plus son exubérance fatigante, vous qui aimez tant le silence méditatif et le calme.",
        Plus:"Vos natures Jupitériennes, vous aimez les plaisirs de la vie et les voyages – Vos natures Neptuniennes, vous aimez les hautes aspirations religieuses et philosophiques. ",
        Moins:"Vos natures infidèles – Votre vision de l’amour est trop différente – Le poissons est passif et lymphatique,  tandis que le Sagittaire est actif et hyper dynamique – Attentions aux paradis artificiels, vous aimez bien l’alcool et la fête.",
        Coach:"Une attraction spontanée et passionnelle pour ces deux hédonistes en quête de sensations et de plaisirs charnels excessifs. Mais leurs différences de sensibilité auront du mal à les accorder à long terme. "
    } ,
    Poissons_Capricorne :{
        Slogan:"“ Ils  sauront prendre soin l’un de l’autre. Relation pansement.”",
        LVA :"L’union entre un signe de Terre et d’Eau est harmonieuse, mais dès que l’on approfondira vos deux natures fondamentalement différentes, on se rendra vite compte que cette histoire n’est viable que grâce à vos prodiges de dévouement envers cette Capricorne peu fantaisiste et peu compatissante. Mais vous vous sentirez protégé par les qualités de sérieux et l'ambition de Madame Capricorne, vous qui êtes si peu courageux à la tâche ! Malgrés son égoïsme et son insensibilité, vous vous entêterez à sauvegarder cette histoire comme un précieux cadeau du ciel malgré ses reproches blessants.",
        Plus:"L’amour inconditionnel du poisson réchauffe la froideur du capricorne – Le capricorne apporte une sécurité financière au bohème poisson- Le poisson par sa sensuelle générosité désinhibe le capricorne. ",
        Moins:"Les sarcasmes, la froideur et le manque de sensibilité du capricorne heurte l’émotif poisson qui le trouve inhumain - Le capricorne se lasse de cet être qu’il trouve trop utopiste et sans volonté.",
        Craquer:"Vous avez sans aucun doute l'art et la manière pour séduire cette beauté froide, réfractaire à toute émotion qu'elle ne peut maîtriser. Votre patience et votre douceur va peu à peu la mettre en confiance et libérer ses sentiments, prisonniers de ses craintes. Ce qui vous intéresse, c'est qu'elle vous ouvre son cœur. Mais attention, Madame est critique et elle pourrait vous blesser de ses réflexions acerbes. Ses résistances à l'amour et son manque de générosité affective risquent de décevoir les espoirs que vous aurez fondé en cet amour improbable.",
        Coach:"Une attraction quasi curative, car ils savent combler instinctivement leurs besoins affectifs. Le Capricorne sécurise le Poisson et calme ses craintes, qui en retour lui prodiguera une tendresse et un dévouement sans bornes qui adoucira la rigidité du Capricorne. "
        },
    Poissons_Verseau :{
        Slogan:"“ Relation avec un esprit ouvert et tolérant en respect de la liberté de chacun et de ses envies.”",
        LVA :"L’entente dépendra de l’évolution psychologique de ces deux personnages un peu perchés, mais dans deux mondes très différents. Vous déambulez dans un monde émotionnel et mystique à la recherche de Dieu, alors que Madame Verseau, hautement intellectuelle, survole ce monde médiocre auquel elle recherche des solutions philosophiques, se prenant un peu pour \"Dieu le père\". Relation floue et utopiste, fondée sur un échange de sensations éphémères, mais intenses, que la triste réalité de la vie dissoudra rapidement.",
        Plus:"Relation mystique et barrée - Leur humanisme.",
        Moins:"Leur irréalisme – L’un se prend pour Dieu et l’autre l’invoque – La froideur du verseau déstabilise l’affectueux poisson.",
        Craquer:"Pour susciter un quelconque intérêt, vous comprendrez très vite qu'il faut la flatter et la mettre sur un piédestal, mais cela ne vous dérange en aucun cas, car lorsque vous aimez, vous adorez. Son côté artiste vous convient très bien, vous aimerez lui chanter tout votre amour et converser avec elle de métaphysique et de spiritualité. Mais sa distance amoureuse vous frustre. Vous avez besoin de démonstrations affectives et même si vous possédez de la tendresse pour deux, vous avez besoin d'un minimum d'attention. Elle est distante et ne comprend pas votre romantisme qu'elle juge ringard et démesuré. Une relation qui risque d’être compromise sur la durée.",
        Coach:"Une relation romanesque connectée à un univers magique et utopique. Le détachement du Verseau et son manque de tendresse frustrera le Poisson qui ne se nourrit que d’émotions et d’amour. Les limites du rêve amoureux. "
    } ,
    Poissons_Poissons :{
        Slogan:"“ Une attirance quasi mystique qui vous transcendera et vous portera aux nues.”",
        LVA :"Ce duo fonctionne grâce à une attraction quasi surnaturelle, où leurs natures sentimentales et émotionnelles se fondent l’une dans l’autre. Vous vivrez alors une fusion des sens qui exaltera une passion dévorante à la fois amoureuse et sensuelle. Mais, couple bohème, inconsistant et incapable d’affronter les réalités matérielles de l’existence, vous vous perdrez bientôt dans les méandres angoissants de la vraie vie. Vous vous débattrez ensemble dans une situation sans issue où vos peurs vous déstabiliseront et vous obligeront à renoncer à vos rêves. Vous repartirez, chacun de votre côté, en quête d’un partenaire plus sécurisant et plus réaliste que vous.",
        Plus:"Vos émotions fusionnent – Vous vivez ensemble un rêve éveillé – Vous transcendez vos énergie sexuelles",
        Moins:"Le manque de réalisme, vous vivez d’amour et d’eau fraîche – Vous êtes dépensiers – Vous vous perdez dans des paradis artificiels et faites des excès d’alcool – Vos natures dépressives. ",
        Craquer:"Sensuelle et mystérieuse, vous avez trouvé la nymphe qui chamboule tous vos sens et vous donne envie de vous jeter à ses pieds. Sensible à vos appels amoureux, elle vibre au rythme de vos mots d’amour et s’abandonne sans résistance à vos désirs. Vous vivrez un envoûtement consenti de vos deux âmes en fusion et  de vos deux corps enflammés. Seul bémol à votre idylle, vos natures dépressives risquent de vous entrainer mutuellement vers des gouffres d’idées noires causés par vos crises existentielles. Incapables de rebooster le moral de l’autre, vous n’aurez d’autres choix que de compatir à sa souffrance, glissant vous-même vers une dépression incontrôlable.",
        Coach:"Une attraction mystique dont le lien amoureux peut les conduire à une extase magique, reliant le corps et l’esprit, un amour les transportant dans un univers fantasmagorique où les émotions mêlent vibrations du cœur et plaisirs sensuels. "
    } ,
}