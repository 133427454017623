import * as React from 'react';
import useStyles from './styles';
import { Button, TextField, Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { IdsContext } from './IdsContext';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import UserForm from './UserForm';
import { Avatar } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import {
  QuestionAnswer as QuestionAnswerIcon,
  People as PeopleIcon,
  PeopleAlt,
} from '@material-ui/icons';
// object simlation
export const arryQuestion = [
  {
    question: ' Mes dessous ',
    answers: [
      'String',
      'Body',
      'Tanga',
      'Culotte',
      'Shorty',
      'Porte-jarretelles',
      'Guêpière',
      'Boxer',
      'Caleçon',
      'Slip',
    ],
  },
  {
    question: " Pour faire l'amour je préfére une ambiance ",
    answers: [
      'En pleine lumière',
      'Dans le noir',
      'Dans la pénombre',
      "Peu m'importe",
      'Shorty',
      'Porte-jarretelles',
      'Guêpière',
      'Boxer',
      'Caleçon',
      'Slip',
    ],
  },
  {
    question: " Pour faire l'amour je préfére une ambiance ",
    answers: [
      'En pleine lumière',
      'Dans le noir',
      'Dans la pénombre',
      "Peu m'importe",
    ],
  },
  {
    question: " J'aime faire l'amour",
    answers: [
      'En pleine lumière',
      'Dans mon lit',
      'Dans la voiture',
      'Dans un ascenseur',
      'Sur une peau de bête',
      'Dans une piscine',
      'Au bureau',
      "Dans une cabine d'essayage",
      'Dans des toilettes publiques',
      'Dans la nature',
    ],
  },
  {
    question: "Il vous est arrivé de faire l'amour avec...",
    answers: [
      'En pleine lumière',
      'Deux partenaires',
      'Trois partenaires',
      'Plus de quatre partenaires',
    ],
  },
  {
    question: "Il vous est arrivé de faire l'amour avec...",
    answers: [
      "Jeux sexuels : J'aime ou j'aimerai...",
      '  Être attaché(e) ou menotté(e).',
      ' Avoir les yeux bandés.',
      'Porter un déguisement.',
      'Dominer',
      'Être dominé(e).',
      'Mordiller.',
      'Être mordillé(e).',
      'Fesser.',
      'Être fessé(e).',
      'Être vu(e) ou filmé(e).',
      "Faire l'amour à plusieurs.",
      'Les jeux de rôles.',
      "Faire l'amour dans un lieu insolite.",
      " Regarder un film X pendant l'acte.",
      'Utiliser des sex-toys.',
    ],
  },
  {
    question:
      'esquelles de ces expériences aimeriez-vous ou avez-vous déjà réalisées ?',
    answers: [
      "Faire l'amour dans un lieu public (cinéma, toilettes d'un resto...)",
      "Faire l'amour à la plage ou dans la mer.",
      "Faire l'amour au bureau.",
      'Regarder un film X en couple.',
      "Se filmer en faisant l'amour.",
      "Faire l'amour avec une personne du même sexe",
      " Faire l'amour au téléphone",
      "aire l'amour par webcam.",
      "S'enfermer 3 jours dans un hôtel",
      "Faire l'amour avec une star.",
      'Se travestir.',
      'Pratiquer la sodomie.',
      'Pratiquer la double pénétration.',
      'Pratiquer une fellation/cunnilingus.',
      "Faire l'amour devant des personnes.",
      'Faire un strip-tease.',
      " Faire l'amour avec un(e) inconnu(e)",
      "Faire l'amour avec quelqu'un de plus âgé",
      'Faire un strip-tease.',
    ],
  },
  {
    question: "Qu'est-ce qui est rédhibitoire et un véritable tue l'amour ?",
    answers: [
      'Garder ses chaussettes au lit',
      "Regarder la télé pendant l'acte",
      "S'éviter du regard",
      "Ne pas s'embrasser",
      "S'endormir desuite après",
    ],
  },
];

const transAstral = {
  Sun: 'Soleil',
  Moon: 'Lune',
  Mercury: 'Mercure',
  Venus: 'Vénus',
  Mars: 'Mars',
  Jupiter: 'Jupiter',
  Saturn: 'Saturne',
  Uranus: 'Uranus',
  Neptune: 'Neptune',
  Pluto: 'Pluton',
  '1': 'Maison 1 / Ascendant',
  '2': 'Maison 2',
  '3': 'Maison 3',
  '4': 'Maison 4',
  '5': 'Maison 5',
  '6': 'Maison 6',
  '7': 'Maison 7',
  '8': 'Maison 8',
  '9': 'Maison 9',
  '10': 'Maison 10 - Milieu du ciel',
  '11': 'Maison 11',
  '12': 'Maison 12',
  cp: 'Capricorne',
  aq: 'Verseau',
  pi: 'Poissons',
  ar: 'Bélier',
  ta: 'Taureau',
  ge: 'Gémeaux',
  cn: 'Cancer',
  le: 'Leo',
  vi: 'Vierge',
  li: 'Balance',
  sc: 'Scorpion',
  sa: 'Sagittaire',
};
export function User(props) {
  var classes = useStyles();
  const [keyword, setKeyword] = React.useState('');
  const [context, setContext] = React.useContext(IdsContext);
  const [userInfo, setUserInfo] = React.useState({});
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleFocus = () => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}auth/filter/search`)
      .then((response) => response.json())
      .then((data) => {
        setData(data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        alert(error);
      });
  };
  React.useEffect(() => {
    console.log(keyword);
    if (keyword !== '' && keyword.length >= 3) {
      setIsLoading(true);
      fetch(
        `${process.env.REACT_APP_API_URL}auth/filter/search?keyword=${keyword}`
      )
        .then((response) => response.json())
        .then((data) => {
          setData(data.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      setData([]);
    }
  }, [keyword]);

  const handleClick = (item) => {
    setUserInfo(item);
    setData([]);
    if (props.index === 1) {
      setContext({ ...context, id1: item._id, user1: item });
    } else {
      setContext({ ...context, id2: item._id, user2: item });
    }
  };

  const handleFormOpen = () => {
    setFormOpen(true);
  };
  const handleFomrClose = () => {
    setFormOpen(false);
  };
  const [formOPen, setFormOpen] = React.useState(false);
  const [privateInfo, setPrivateInfo] = React.useState(false);
  const [planete, setPlanete] = React.useState(false);
  const [house, setHouse] = React.useState(false);

  // console.log(userInfo.private?.birthplace.coordinates);
  return (
    <div>
      <UserForm
        open={formOPen}
        onClose={handleFomrClose}
        index={props.index}
        setUserInfo={setUserInfo}
      />
      <Button
        variant='outlined'
        color='primary'
        startIcon={<PeopleAlt fontSize='40' />}
        onClick={handleFormOpen}
        style={{
          marginTop: 10,
          textTransform: 'none',
        }}
      >
        {`Ajouter Nouvel Utilisateur: ${props.index}`}
      </Button>
      <div
        style={{
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <p
          style={{
            marginRight: 50,
            fontFamily: 'SF-Pro-Display-Medium',
            fontSize: 16,
          }}
        >
          Recherche Utilisateur {props.index}
        </p>
        <div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <TextField
              id='filled-secondary'
              label={`Utilisateur ${props.index}`}
              onFocus={handleFocus}
              placeholder="Entrez un nom d'utilisateur"
              fullWidth
              color='secondary'
              onChange={(e) => setKeyword(e.target.value)}
            />
            <div style={{ display: 'flex' }}>
              <SearchIcon
                style={{
                  alignSelf: 'flex-end',
                  borderBottom: '1px solid #949494',
                }}
              />
            </div>
          </div>
          {data.length > 0 && (
            <div className={classes.root}>
              {isLoading ? (
                <div>Is Loading ...</div>
              ) : (
                <Paper style={{ maxHeight: 200, overflow: 'auto' }}>
                  <List
                    component='nav'
                    className={classes.root}
                    aria-label='contacts'
                  >
                    {data.map((item, index) => (
                      <ListItem
                        button
                        key={index}
                        onClick={() => handleClick(item)}
                      >
                        <ListItemText
                          primary={`${item.username}  ${item.surname}`}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              )}
            </div>
          )}
        </div>
      </div>
      {Object.keys(userInfo).length !== 0 &&
        userInfo.constructor === Object && (
          <div className={classes.userInfo}>
            <div
              style={{
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: 20,
              }}
            >
              {userInfo.avatar === null || userInfo.avatar === '' ? (
                <Avatar className={classes.avatar} />
              ) : (
                <Avatar
                  src={
                    userInfo.avatar.substring(0, 8) === 'https://'
                      ? `${userInfo.avatar}`
                      : `${process.env.REACT_APP_API_URL}media/puplic/photos/${userInfo.avatar}`
                  }
                  className={classes.avatar}
                />
              )}
              <Typography
                style={{ fontFamily: 'SF-Pro-Display-Regular', marginTop: 5 }}
              >
                {userInfo.username}, {userInfo.age} ans
              </Typography>
              <Typography style={{ fontFamily: 'SF-Pro-Display-Regular' }}>
                {userInfo.cityName}, {userInfo.countryName}
              </Typography>
            </div>
            <Button
              className={classes.button}
              onClick={() => setPrivateInfo(!privateInfo)}
            >
              <Typography className={classes.title}>
                Informations Privées
                <div style={{ display: 'flex' }}>
                  {privateInfo ? (
                    <KeyboardArrowDownIcon
                      style={{
                        alignSelf: 'flex-end',
                        marginLeft: 50,
                      }}
                    />
                  ) : (
                    <KeyboardArrowRightIcon
                      style={{
                        alignSelf: 'flex-end',
                        marginLeft: 50,
                      }}
                    />
                  )}
                </div>
              </Typography>
            </Button>

            {privateInfo && (
              <>
                <Typography className={classes.block}>
                  <strong>Prénom:</strong>
                  {JSON.stringify(userInfo.surname)}
                </Typography>
              </>
            )}

            {privateInfo && (
              <>
                <Typography className={classes.block}>
                  <strong>Pseudo:</strong>
                  {JSON.stringify(userInfo.username)}
                </Typography>
              </>
            )}
            {privateInfo && (
              <>
                <Typography className={classes.block}>
                  <strong>Date de Naissance:</strong>
                  {JSON.stringify(userInfo.private.birthday)}
                </Typography>
              </>
            )}

            {privateInfo && (
              <>
                <Typography className={classes.block}>
                  <strong>Pays de Naissance:</strong>
                  {JSON.stringify(userInfo.private.nativeCountry)}
                </Typography>
              </>
            )}

            {privateInfo && (
              <>
                <Typography className={classes.block}>
                  <strong>Ville de Naissance:</strong>
                  {JSON.stringify(userInfo.private.birthCity)}
                </Typography>
              </>
            )}

            {privateInfo && (
              <>
                <Typography className={classes.block}>
                  <strong>Heure de Naissance:</strong>
                  {JSON.stringify(userInfo.private.birthTime)}
                </Typography>
              </>
            )}

            {privateInfo && (
              <>
                <Typography className={classes.block}>
                  <strong>Latitude:</strong>
                  {JSON.stringify(userInfo.private?.birthplace?.coordinates[0])}
                </Typography>
                <Typography className={classes.block}>
                  <strong>Longitude:</strong>
                  {JSON.stringify(userInfo.private?.birthplace?.coordinates[1])}
                </Typography>
              </>
            )}

            {privateInfo && (
              <>
                <Typography className={classes.block}>
                  <strong>Genre:</strong>
                  {JSON.stringify(
                    userInfo.gender === 'mal' ? 'Homme' : 'Femme'
                  )}
                </Typography>
                <Divider />
              </>
            )}
            <Button
              className={classes.button}
              onClick={() => setPlanete(!planete)}
            >
              <Typography className={classes.title}>
                Theme Astral Planètes
                <div style={{ display: 'flex' }}>
                  {planete ? (
                    <KeyboardArrowDownIcon
                      style={{
                        alignSelf: 'flex-end',
                        marginLeft: 50,
                      }}
                    />
                  ) : (
                    <KeyboardArrowRightIcon
                      style={{
                        alignSelf: 'flex-end',
                        marginLeft: 50,
                      }}
                    />
                  )}
                </div>
              </Typography>
            </Button>
            {planete &&
              Object.entries(userInfo.themeAstral.plantes).map(
                ([key, value]) => {
                  return (
                    <Typography className={classes.block}>
                      <strong>{transAstral[key]}:</strong>
                      {transAstral[value] + ' '}
                      {userInfo.themeAstral.hasOwnProperty('plantesDegree') ? (
                        <strong>
                          {userInfo.themeAstral.plantesDegree[key][0]}°
                          {userInfo.themeAstral.plantesDegree[key][1]}
                        </strong>
                      ) : null}
                    </Typography>
                  );
                }
              )}
            {planete && <Divider />}
            <Button className={classes.button} onClick={() => setHouse(!house)}>
              <Typography className={classes.title}>
                Theme Astral Maisons
                <div style={{ display: 'flex' }}>
                  {house ? (
                    <KeyboardArrowDownIcon
                      style={{
                        alignSelf: 'flex-end',
                        marginLeft: 50,
                      }}
                    />
                  ) : (
                    <KeyboardArrowRightIcon
                      style={{
                        alignSelf: 'flex-end',
                        marginLeft: 50,
                      }}
                    />
                  )}
                </div>
              </Typography>
            </Button>
            {house &&
              userInfo.hasOwnProperty('themeAstral') &&
              Object.entries(userInfo.themeAstral.houses).map(
                ([key, value]) => {
                  return (
                    <Typography className={classes.block}>
                      <strong>{transAstral[key]}:</strong>
                      {transAstral[value] + ' '}
                      {userInfo.themeAstral.hasOwnProperty('housesDegree') ? (
                        <strong>
                          {userInfo.themeAstral.housesDegree[key][0]}°
                          {userInfo.themeAstral.housesDegree[key][1]}
                        </strong>
                      ) : null}
                    </Typography>
                  );
                }
              )}

            {/* <Divider />
          <Typography component="h2">Astrale sexe:</Typography>
          {

            userInfo.hasOwnProperty('astralSexe') && Object.entries(userInfo.astralSexe).map(([key, value]) => {
              return <Typography className={classes.block} >
                <strong>{key}:</strong> {value}
              </Typography>
            }
            )
          } */}

            {/* <Divider />
          <Typography component="h2">Astral:</Typography>
          {
            userInfo.hasOwnProperty('astral') && userInfo.astral.map((item, index) =>
              Object.entries(item).map(([key, value]) => {
                return <Typography key={index} className={classes.block}>
                  <strong>{key}:</strong> {value}
                </Typography>
              }
              )
            )

          }
          <Typography className={classes.block} >
            <strong>gender :</strong>{JSON.stringify(userInfo.gender)}
          </Typography> */}
          </div>
        )}
    </div>
  );
}
