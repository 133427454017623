import React from "react";
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import Grid from '@material-ui/core/Grid';
import useStyles from "./styles";
import PageTitle from "../../components/PageTitle/PageTitle";
import MenuItem from '@material-ui/core/MenuItem';
import {Typography, Button} from '@material-ui/core/';
import InputLabel from '@material-ui/core/InputLabel';
import { TextField, Select} from 'formik-material-ui';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import { Height } from "@material-ui/icons";
import Box from '@material-ui/core/Box';
export default function KingdomQuestionPage() {
  var classes = useStyles();
  const [questionType,setquestionType]=React.useState('text')
  const [posImages,setPosImages]=React.useState([])


  // read All images  from uri
  const readURI =(e)=>{
    if (e.target.files) {

        /* Get files in array form */
        const files = Array.from(e.target.files);

        /* Map each file to a promise that resolves to an array of image URI's */ 
        Promise.all(files.map(file => {
            return (new Promise((resolve,reject) => {
                const reader = new FileReader();
                reader.addEventListener('load', (ev) => {
                    resolve(ev.target.result);
                });
                reader.addEventListener('error', reject);
                reader.readAsDataURL(file);
            }));
        }))
        .then(images => {

          //  console.log(images);
          setPosImages({...posImages,images})
      console.log(posImages)
        }, error => {        
            console.error(error);
        });
    }
}

  return (
    <>
      <PageTitle title="Question Royame" />
    
      <Box 
      component={Grid}
      container
      boxShadow={3}
      item xs={12} >
    <Formik
    
      initialValues={{question:'',answers:[{answer:''}],type:'text'}}
      validate={values => {
        setquestionType(values.type);
      }}
      onSubmit={async (values,{ setSubmitting }) => {
        await new Promise((r) => setTimeout(r, 500));
        alert(JSON.stringify(values, null, 2));
      }}
    >
      {({ values }) => (
        <Form className={classes.root}>
              <Grid container spacing={1}>
              
          <Field
            component={TextField}
            name="question"
            type="question"
            label="Ajouter une question"
            fullWidth
            variant="filled"
          />
          </Grid>
          <Grid item xs={12} >
          <Typography
          style={{paddingTop:30}}
          variant="h2" gutterBottom>
         Selection type de Réponce:
      </Typography>
          <Field
            component={Select}
            name="type"
            type="type"
            label="Type de Question Image ou bien text"
            fullWidth
          >
          <MenuItem value="text">text</MenuItem>
          <MenuItem value='image'>image</MenuItem>
          </Field>
          </Grid>
          <Grid item xs={12} >
         {questionType==="text"&& <FieldArray name="answers">
            {({ insert, remove, push }) => (
              <>
                   <IconButton aria-label="add"
                    onClick={() => push({ answer: '' })}

                   >
                 <AddCircleIcon />
                </IconButton>
               
                {values.answers.length > 0 &&
                  values.answers.map((answer, index) => (
                    <>
                    <Grid
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="center"
                    
                          key={index}
                        >
                     <Grid
                     style={{width:"10%"}}
                     >
                      <span
                      style={{
                        fontSize: "-webkit-xxx-large"
                        

                      }}
                      >{index+1}.</span>
                      </Grid>
                      <Grid
                          style={{width:"85%"}}
                      ><Field
                         component={TextField}
                          name={`answers.${index}.answer`}
                          placeholder="Ajouter Un réponce"
                          type="text"
                          fullWidth
                        
                        /></Grid>
                        <Grid
                        style={{width:"5%"}}
                        ><IconButton aria-label="add"
                    onClick={() => remove(index)}

                   >
                 <DeleteIcon />
                </IconButton></Grid>
                    </Grid>
                     
                       </>
                  ))}
                
              </>
            )}
          </FieldArray>
          }</Grid>
          <Button className={classes.submit} type="submit"
           // disabled={!canSave}
            fullWidth
            variant="contained"
            color="primary">
            save
          </Button>
         
        </Form>
      )}
    </Formik>
    {questionType==="image" &&         <input accept="image/*" className={classes.input} id="icon-button-file" type="file" multiple  onChange={readURI}/>}
        <p>{posImages.length>0 && posImages!= undefined && JSON.stringify(posImages)}</p>
    </Box>
    
    </>
  );
}
