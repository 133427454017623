import * as React from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';
import useStyles from './styles';
import "./photogalerie.css";
//import ConfirmationForm from "./ConfirmationForm";
import SubMenu from '../../components/SubMenu';
import { Link } from 'react-router-dom';
import { Typography, Button } from '@material-ui/core';
import ReactPaginate from 'react-paginate';

export default function Videogalerie() {
  var classes = useStyles();
  const [page, setPage] = React.useState(1);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [refresh, setrefresh] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(3);

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  React.useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}admin-user/GalleryVideosToValidate?pageSize=50&pageNumber=${page}`;
      //console.log(url);
      const response = await fetch(url);
      console.log(response);
      const data = await response.json();
      // setPageCount(data.pageCount);
      setData(data.data);
      if (!active) {
        return;
      }
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, refresh]);

  const Validate = async (imgID, userID) => {
    console.log(imgID, userID);
    const req = {
      id: imgID,
      type: 'videos',
    };
    const url = `${process.env.REACT_APP_API_URL}admin-user/gallery/validate/${userID}`;
    console.log(url);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    });
    const data = await response.json();
    setrefresh(!refresh);
  };
  const Refuse = async (imgID, userID) => {
    console.log(imgID, userID);
    const req = {
      id: imgID,
      type: 'videos',
    };
    const url = `${process.env.REACT_APP_API_URL}admin-user/gallery/refuse/${userID}`;
    console.log(url);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    });
    const data = await response.json();
    setrefresh(!refresh);
  };


  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = data.slice(firstPostIndex, lastPostIndex);
  const [pageNumber, setPageNumber] = React.useState(0);
  const PostsPerPage = 3;
  const pagesVisited = pageNumber * PostsPerPage;

  const displayPosts = data
    .slice(pagesVisited, pagesVisited + PostsPerPage)
    .map((item) => {
      return item.gallery.videos.map((itemy) => {
        if (itemy.valid === 'false') {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                display: 'flex',
                flex: 1,
                backgroundColor: 'white',
                paddingRight: 250,
                paddingLeft: 250,
                paddingTop: 20,
              }}
            >
              <div className={classes.avatarContainer}>
                <video width='320' height='240' controls>
                  <source
                    src={`${process.env.REACT_APP_IMG}${itemy.url}`}
                    type='video/mp4'
                  />
                  Your browser does not support the video tag.
                </video>
                <Typography
                  style={{
                    fontFamily: 'SF-Pro-Display-Regular',
                    marginTop: 5,
                  }}
                >
                  {item.username}, {item.age} ans
                </Typography>
                <Typography
                  style={{ fontFamily: 'SF-Pro-Display-Regular' }}
                >
                  {item.cityName}, {item.countryName}
                </Typography>
              </div>
              <div className={classes.actionsBox}>
                <Button
                  onClick={() => {
                    Validate(itemy._id, item._id);
                  }}
                  variant='outlined'
                  style={{
                    borderColor: '#53ccb4',
                    borderRadius: 10,
                    color: '#53ccb4',
                    margin: 20,
                  }}
                >
                  Valider
                </Button>
                <Button
                  onClick={() => {
                    Refuse(itemy._id, item._id);
                  }}
                  variant='outlined'
                  style={{
                    borderColor: 'red',
                    borderRadius: 10,
                    color: 'red',
                    marginLeft: 50,
                  }}
                >
                  Refuser
                </Button>
              </div>
              <div className={classes.box}>
                <Typography
                  style={{ fontFamily: 'SF-Pro-Display-Regular' }}
                >
                  Raison du refus{' '}
                </Typography>
                <input
                  placeholder='Description'
                  className={classes.TextField}
                ></input>
              </div>
            </div>
          );
        }
      });
    });

  const pageCount = Math.ceil(data.length / postsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    console.log('DATA', data),
    (
      <div style={{ height: 500, width: '100%', backgroundColor: 'white' }}>
        <SubMenu active='2' />
        <div style={{ justifyContent: 'center', display: 'flex' }}>
          <Link
            to='/loveattraction/users/confirmations'
            className={classes.MenuButton}
            style={{ textDecoration: 'none' }}
          >
            Photo de validation
          </Link>
          <Link
            to='/loveattraction/users/photoAvatar'
            className={classes.MenuButton}
            style={{ textDecoration: 'none' }}
          >
            Photo de profil
          </Link>
          <Link
            to='/loveattraction/users/photogalerie'
            className={classes.MenuButton}
            style={{ textDecoration: 'none' }}
          >
            Photo de galerie
          </Link>
          <Link
            to='/loveattraction/users/videogalerie'
            className={classes.MenuButton}
            style={{ textDecoration: 'underline' }}
          >
            Video de galerie
          </Link>
        </div>
        <PageTitle title='PHOTO DE GALERIE' button='' />
        {data.length > 0 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingRight: 250,
              paddingLeft: 250,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                flex: 0.7,
                minWidth: 230,
              }}
            >
              <Typography style={{ fontFamily: 'SF-Pro-Display-Medium' }}>
                Vidéo proposé :
              </Typography>
            </div>
            <div
              style={{
                marginRight: 30,
                marginLeft: 30,
                paddingLeft: 20,
                paddingRight: 20,
                flex: 1.5,
                display: 'flex',
              }}
            />
            <div
              style={{
                borderRadius: 20,
                marginRight: 30,
                marginLeft: 30,
                paddingRight: 20,
                paddingLeft: 20,
                flex: 1,
              }}
            />
          </div>
        ) : (
          <text />
        )}
        {data.length < 1 ? (
          <div
            style={{
              justifyContent: 'center',
              display: 'flex',
              marginTop: 100,
            }}
          >
            <Typography
              style={{ fontFamily: 'SF-Pro-Display-Regular', fontSize: 20 }}
            >
              Aucune vidéo a valider
            </Typography>
          </div>
        ) : (
          <div>
          {displayPosts}
          <ReactPaginate
            previousLabel={"<<"}
            nextLabel={">>"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationsBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
        )}
      </div>
    )
  );
}
