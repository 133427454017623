import React from 'react';
import { Link } from 'react-router-dom';
// styles
import useStyles from './styles';

// components
import { Typography } from '../Wrappers';

export default function SubMenu(props) {
  var classes = useStyles();

  return (
    <div style={{ justifyContent: 'center', display: 'flex', marginTop: 10 }}>
      <Link
        to='/loveattraction/users/list'
        className={classes.MenuButton}
        style={{ textDecoration: props.active === '1' ? 'underline' : 'none' }}
      >
        Utilisateurs
      </Link>
      <Link
        to='/loveattraction/users/confirmations'
        className={classes.MenuButton}
        style={{ textDecoration: props.active === '2' ? 'underline' : 'none' }}
      >
        Confirmations
      </Link>
      <Link
        to='/loveattraction/users/compatibility'
        className={classes.MenuButton}
        style={{ textDecoration: props.active === '3' ? 'underline' : 'none' }}
      >
        Compatibilité
      </Link>
      <Link
        to='/loveattraction/users/signalements'
        className={classes.MenuButton}
        style={{ textDecoration: props.active === '4' ? 'underline' : 'none' }}
      >
        Signalements
      </Link>
      <Link
        to='/loveattraction/users/simulator'
        className={classes.MenuButton}
        style={{ textDecoration: props.active === '5' ? 'underline' : 'none' }}
      >
        Simulator Astral
      </Link>
    </div>
  );
}
