import { makeStyles } from '@material-ui/styles';

const bg = require('./bg.png');

export default makeStyles((theme) => ({
  header: {
    fontFamily: 'SF-Pro-Display-Bold',
    fontSize: 18,
  },
  rule: {
    fontFamily: 'SF-Pro-Display-Regular',
    fontSize: 15,
  },
  title: {
    fontFamily: 'SF-Pro-Display-Semibold',
    marginTop: 15,
  },
  text: {
    fontFamily: 'SF-Pro-Display-Regular',
    //marginTop: 10,
  },
  button: {
    height: 35,
    width: 35,
    borderRadius: 18,
    textTransform: 'none',
    '&:active': {
      boxShadow: theme.customShadows.widgetWide,
    },
  },
  NotifBtn: {
    width: 270,
    borderColor: '#69d3be',
    color: '#69d3be',
    borderRadius: 10,
    marginTop: 20,
    fontFamily: 'SF-Pro-Display-Regular',
    fontSize: 13,
  },
  box: {
    boxShadow: '0px 3px 6px #00000040',
    backgroundColor: 'white',
    borderRadius: 20,
    elevation: 5,
    marginTop: theme.spacing(20),
    marginBottom: theme.spacing(15),
    margin: 30,
    padding: 20,
    flexDirection: 'row',
    display: 'flex',
  },
  actionsBox: {
    backgroundColor: 'transparent',
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(15),
    margin: 30,
    padding: 20,
    flex: 1.5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  MenuButton: {
    color: '#e21067',
    borderWidth: 0,
    textTransform: 'none',
    textDecoration: 'none',
    fontFamily: 'SF-Pro-Display-Light',
    padding: 10,
    fontSize: 17,
  },
  menu: {
    background: 'white',
    width: '100%',
    position: 'absolute',
    paddingTop: 0,
    padding: '0.5rem',
    zIndex: '1',
    border: '1px solid lightgray',
  },
  menuItem: {
    padding: '0.5rem',
    borderTop: '1px solid lightgray',
    cursor: 'pointer',
    fontFamily: 'SF-Pro-Display-Bold',
    fontSize: 14,
  },
  globContainer: {
    width: '25%',
    height: '90%',
    backgroundImage: `url(${bg})`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
  },
  mainContainer: {
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'grid',
    placeItems: 'center',
  },
  mainMainContainer: {
    width: '80%',
    height: 'fit-content',
    backgroundColor: 'white',
    borderRadius: 10,
    minWidth: '50%',
  },

  mainLayout: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    padding: '1rem',
  },

  promoText: {
    textAlign: 'center',
    color: 'rgb(12,12,12)',
    borderBottom: '1px solid rgb(12,12,12)',
    padding: '10px',
    fontSize: 18,
    margin: 0,
  },

  titleLayout: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  pinkText: {
    color: '#e21067',
    textAlign: 'center',
  },
  itemLayout: {
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
  },

  buttLayout: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
  },

  leftButt: {
    marginRight: '10px !important',
    borderColor: '#69d3be !important',
    color: '#69d3be !important',
    borderRadius: '10px !important',
  },

  rghtButt: {
    color: 'red !important',
    borderColor: 'red !important',
    borderRadius: '10px !important',
  },

  itemInLayout: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    border: '1px solid ',
    borderRadius: 50,
    boxShadow: '8px 3px 5px 0px rgba(173,167,167,0.75)',

    '& p': {
      color: 'black',
      margin: 0,
      fontWeight: 'bold',
    },

    '& h1': {
      color: 'black',
    },
  },

  priceCont: {
    border: '1px solid',
    borderRadius: 20,
    padding: 10,
    backgroundColor: '#47a1de',

    '& p': {
      color: 'white',
    },
  },
}));
