/* eslint-disable no-unused-expressions */

import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import homeWhite from '../../assets/icone/homeWhite.png';
import home from '../../assets/icone/home.png';

import UserW from '../../assets/icone/UserW.png';
import User from '../../assets/icone/Groupe 1964.png';

import AdminW from '../../assets/icone/AdminW.png';
import Admin from '../../assets/icone/Groupe 1963.png';

import AbbonementsW from '../../assets/icone/AbbonementsW.png';
import Abbonements from '../../assets/icone/Groupe 1967.png';

import EventW from '../../assets/icone/EventW.png';
import Event from '../../assets/icone/Groupe 1965.png';

import PromoW from '../../assets/icone/PromoW.png';
import Promo from '../../assets/icone/Groupe 1966.png';

// styles
import useStyles from './styles';
import { Avatar } from '@material-ui/core';

export let ListEl = () => {
  var classes = useStyles();

  const [tglSt, setTglSt] = useState(
    JSON.parse(localStorage.getItem('pictosState'))
      ? JSON.parse(localStorage.getItem('pictosState'))
      : {
          home: true,
          adr: false,
          user: false,
          even: false,
          promo: false,
          abb: false,
        }
  );

  const data = [
    {
      url: '/loveattraction/admin/list',
      upData: {
        home: false,
        adr: true,
        user: false,
        even: false,
        promo: false,
        abb: false,
      },
      state: tglSt.adr,
      wAv: AdminW,
      av: Admin,
      message: 'Administrateurs',
    },
    {
      url: '/loveattraction/dashboard',
      upData: {
        home: true,
        adr: false,
        user: false,
        even: false,
        promo: false,
        abb: false,
      },
      state: tglSt.home,
      wAv: homeWhite,
      av: home,
      message: 'Dashboard',
    },
    {
      url: '/loveattraction/users/list',
      upData: {
        home: false,
        adr: false,
        user: true,
        even: false,
        promo: false,
        abb: false,
      },
      state: tglSt.user,
      wAv: UserW,
      av: User,
      message: 'Modération',
    },
    {
      url: '/loveattraction/events',
      upData: {
        home: false,
        adr: false,
        user: false,
        even: true,
        promo: false,
        abb: false,
      },
      state: tglSt.even,
      wAv: EventW,
      av: Event,
      message: 'Événements',
    },
    {
      url: '/loveattraction/promotions',
      upData: {
        home: false,
        adr: false,
        user: false,
        even: false,
        promo: true,
        abb: false,
      },
      state: tglSt.promo,
      wAv: PromoW,
      av: Promo,
      message: 'Promotions',
    },
    {
      url: '/loveattraction/users/list',
      upData: {
        home: false,
        adr: false,
        user: false,
        even: false,
        promo: false,
        abb: true,
      },
      state: tglSt.abb,
      wAv: AbbonementsW,
      av: Abbonements,
      message: 'Abonnements',
    },
  ];

  return (
    <>
      {data.map((d) => (
        <div className={classes.IcnTiCont}>
          <Link to={d.url} className={classes.MenuItem}>
            <Avatar
              alt='Admin'
              className={classes.profileMenuIcon}
              src={!d.state ? d.wAv : d.av}
              onClick={() => {
                localStorage.setItem('pictosState', JSON.stringify(d.upData)),
                  setTglSt(d.upData);
              }}
            />
          </Link>
          <span className={!d.state ? '' : classes.Bold}>{d.message}</span>
        </div>
      ))}
    </>
  );
};
