import React from "react";
import { IconButton } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";

export default function PageTitle(props) {
  var classes = useStyles();

  return (
    <div className={classes.pageTitleContainer}>
      <Typography className={classes.typo} variant="h5" size="sm">
        {props.title}
      </Typography>
      {props.button && (
        <IconButton
          className={classes.button}
          onClick={() => props.OnOpen()}
        >
          <AddIcon style={{ fontSize: 25, color: '#1ABC9C' }} />

        </IconButton>
      )}
    </div>
  );
}
