/* eslint-disable no-unused-expressions */

import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useStyles from "./styles";
import csc from "country-state-city";
import { EditStatusForm } from "./EditStatusForm";
import SVGUser from "../../assets/icone/user.svg";
import SVGHome from "../../assets/icone/home.svg";
import SVGInfop from "../../assets/icone/infop.svg";

export const FormEdit = (props) => {
  const { onClose, setAllRows, allRows, row } = props;

  const date = row["DATE DE NAISSANCE"].split("-");

  const defDate = `${date[2]}-${date[1]}-${date[0]}`;

  console.log(date, defDate);

  const [status, setStatus] = React.useState({
    ban: false,
    unban: false,
    desact: false,
    activ: false,
  });

  const { register, handleSubmit } = useForm();

  const classes = useStyles();

  const [countries, setcountries] = useState({
    countries: csc.getAllCountries(),
    loading: true,
  });

  const [villesR, setvillesR] = useState(null);

  const textData = [{ title: "PSEUDO" }, { title: "NOM" }, { title: "PRÉNOM" }];

  const getVillesR = (CountryCode) => {
    const villes = csc.getStatesOfCountry(CountryCode);
    setvillesR(villes);
  };

  useEffect(() => {
    const options = countries.countries.map((v) => ({
      label: v.name,
      value: v.isoCode,
    }));
    setcountries({ countries: options, loading: false });
  }, []);

  const statusChng = (status, anc) => {
    let crntStt = anc;
    const isChngd =
      status.activ !== false ||
      status.ban !== false ||
      status.desact !== false ||
      status.unban !== false;

    isChngd
      ? status.activ
        ? (crntStt = "Actif")
        : status.desact
        ? (crntStt = "Inactif")
        : status.ban
        ? (crntStt = "Banni")
        : status.unban
        ? (crntStt = "Actif")
        : ""
      : "";

    return crntStt;
  };

  const hndlSub = async (data) => {
    let dtoTOTransfer = {};
    dtoTOTransfer.id = row.id;

    if (data["PAYS DE RESIDENCE"] != "" && data["VILLE DE RESIDENCE"] != "") {
      const ps = villesR.find((c) => c.name === data["VILLE DE RESIDENCE"]);
      console.log(ps);
      let position2 = {
        type: "Point",
        coordinates: [ps?.latitude, ps?.longitude],
      };
      dtoTOTransfer.
      dtoTOTransfer.position = position2;
      dtoTOTransfer.residenceplace = position2;
      dtoTOTransfer.cityName = data["VILLE DE RESIDENCE"];
      dtoTOTransfer.countryName = csc.getCountryByCode(
        data["PAYS DE RESIDENCE"]
      ).name;
    }
    dtoTOTransfer.ADRESSEIP = data["ADRESSEIP"];
    dtoTOTransfer.surname = data["NOM"];
    dtoTOTransfer.gender = data["SEXE"];
    dtoTOTransfer.familySituation = data["FAMILYSITUATION"];
    dtoTOTransfer.numberOfChildren = data["NUMBEROFCHILDREN"];
    dtoTOTransfer.username = data["PSEUDO"];
    dtoTOTransfer.age = data["AGE"];
    dtoTOTransfer.email = data["EMAIL"];
    dtoTOTransfer.status = statusChng(status, row.STATUS);
    dtoTOTransfer.private = {
      birthday: data["DATE DE NAISSANCE"] + "T00:00:00.000+00:00",
      birthTime: data["HEURE DE NAISSANCE"],
    };

    // console.log(dtoTOTransfer);
    const url = `${process.env.REACT_APP_API_URL}admin-user/member/update`;
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dtoTOTransfer),
    });

    // const data = await response.json();

    setAllRows(
      allRows.map((R) => {
        return R.id === dtoTOTransfer.id
          ? {
              ...R,
              EMAIL: dtoTOTransfer.email,
              SEXE: dtoTOTransfer.gender,
              FAMILYSITUATION: dtoTOTransfer.familySituation,
              NUMBEROFCHILDREN: dtoTOTransfer.numberOfChildren,
              NOM: dtoTOTransfer.surname,
              PRÉNOM: dtoTOTransfer.surname,
              PSEUDO: dtoTOTransfer.username,
              AGE: dtoTOTransfer.age,
              STATUS: dtoTOTransfer.status,
              "DATE DE NAISSANCE": new Date(dtoTOTransfer.private.birthday)
                .toLocaleDateString()
                .replace(/\//g, "-"),
              "/HEURE": dtoTOTransfer.private.birthTime,
              ADRESSEIP: R.ip,
            }
          : R;
      })
    );

    onClose();
  };
  return (
    <form
      className={classes.flexForm}
      onSubmit={handleSubmit((data) => hndlSub(data))}
    >
      <div className={classes.logotypeContainer}>
        <img src={SVGUser} alt="SVGUser" className={classes.logotypeImage} />
      </div>
      <div className={classes.form_inputs}>
        {textData.map((data) => (
          <div className={classes.inp_lab}>
            <label>{data.title}:</label>
            <input
              type="text"
              placeholder={data.title}
              {...register(`${data.title}`)}
              defaultValue={row[`${data.title}`]}
            />
          </div>
        ))}

        <div className={classes.inp_lab}>
          <label>EMAIL:</label>
          <input
            type="email"
            placeholder="EMAIL"
            {...register("EMAIL")}
            defaultValue={row["EMAIL"]}
          />
        </div>

        <div className={classes.inp_lab}>
          <label>AGE:</label>
          <input
            type="number"
            placeholder="AGE"
            {...register("AGE")}
            defaultValue={row["AGE"]}
          />
        </div>

        <div className={classes.inp_lab}>
          <label>SEXE:</label>
          <select {...register("SEXE")} defaultValue={row["SEXE"]}>
            <option value="female">F</option>
            <option value="mal">M</option>
          </select>
        </div>
      </div>
      <div className={classes.logotypeContainer}>
        <img src={SVGInfop} alt="SVGInfop" className={classes.logotypeImage} />
      </div>
      <div className={classes.form_inputs}>
        <div className={classes.inp_lab}>
          <label>DATE DE NAISSANCE:</label>
          <input
            type="date"
            placeholder="DATE DE NAISSANCE"
            {...register("DATE DE NAISSANCE")}
            defaultValue={defDate}
          />
        </div>

        <div className={classes.inp_lab}>
          <label>HEURE DE NAISSANCE:</label>
          <input
            type="time"
            placeholder="HEURE DE NAISSANCE"
            {...register("HEURE DE NAISSANCE")}
            defaultValue={row["/HEURE"]}
          />
        </div>

        {/*<div className={classes.inp_lab}>
          <label>STATUS:</label>
          <EditStatusForm setStatus={setStatus} mStatus={row.STATUS} />
        </div>*/}
        <div className={classes.inp_lab}>
          <label>SITUATION FAMILIALE:</label>
          <select {...register("FAMILYSITUATION")} defaultValue={row["FAMILYSITUATION"]}>
            <option value="Célibataire">Célibataire</option>
            <option value="Séparé(e)">Séparé(e)</option>
            <option value="Divorcé(e)">Divorcé(e)</option>
            <option value="Marié(e)">Marié(e)</option>
            <option value="En couple">En couple</option>
            <option value="En relation libre">En relation libre</option>
            <option value="Pacsé(e)">Pacsé(e)</option>
            <option value="Veuf(ve)">Veuf(ve)</option>
          </select>
        </div>
        <div className={classes.inp_lab}>
          <label>NOMBRE D'ENFANTS:</label>
          <select {...register("NUMBEROFCHILDREN")} defaultValue={row["NUMBEROFCHILDREN"]}>
            <option value="Aucun">Aucun</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3 ou plus">3 ou plus</option>
          </select>
        </div>
      </div>
      <div className={classes.logotypeContainer}>
        <img src={SVGHome} alt="SVGHome" className={classes.logotypeImage} />
      </div>
      <div className={classes.form_inputs}>
        <div className={classes.inp_lab}>
          <label>PAYS DE RESIDENCE:</label>
          <select
            onClick={(e) => getVillesR(e.target.value)}
            {...register("PAYS DE RESIDENCE")}
            defaultValue={row["PAYS DE RESIDENCE"]}
          >
            {countries.countries.map((fbb) => (
              <option value={fbb.value}>{fbb.label}</option>
            ))}
          </select>
        </div>

        <div className={classes.inp_lab}>
          <label>VILLE DE RESIDENCE:</label>
          <select
            {...register("VILLE DE RESIDENCE")}
            defaultValue={row["VILLE DE RESIDENCE"]}
          >
            {villesR?.map((fbb) => (
              <option value={fbb.name}>{fbb.name}</option>
            ))}
          </select>
        </div>
        <div className={classes.inp_lab}>
          <label>ADRESSE IP/LIEU:</label>
          <input
            type="ADRESSEIP"
            placeholder="ADRESSEIP"
            {...register("ADRESSEIP")}
            defaultValue={row["ADRESSEIP"]}
          />
        </div>
      </div>

      <div className={classes.actionButt}>
        <button type="submit">ENREGISTRER</button>
        <button onClick={onClose}>ANNULER</button>
      </div>
    </form>
  );
};
