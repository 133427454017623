import * as React from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';
import useStyles from './styles';
import { Link } from 'react-router-dom';
import { Typography, Button, Avatar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { IconButton } from '@material-ui/core';
import EventForm from './EventForm';
import EditEventForm from './EditEventForm';
import SnackBarPromo from '../promotions/SnackBarPromo';
import DeletePromoModal from '../promotions/DeletePromoModal';
import parse from 'html-react-parser';

export default function Events() {
  var classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [refresh, setrefresh] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState([]);
  const [openEdit, setEditOpen] = React.useState(false);
  const [idEvent, setIdEvent] = React.useState('');

  const [openSnack, setOpenSnack] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [openDelMod, setOpenDelMod] = React.useState({
    open: false,
    text: '',
    hndlDel: '',
    id: '',
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseEdit = () => {
    setEditOpen(false);
  };
  const DeleteEvent = async (id) => {
    const url =
      `${process.env.REACT_APP_API_URL}admin-user/DeleteEvent?EventId=` + id;
    const response = await fetch(url, { method: 'DELETE' });
    setrefresh(!refresh);
  };
  React.useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}admin-user/GetEvent/Live`;
      const response = await fetch(url);
      const data = await response.json();
      console.log(data);
      setData(data.data);
      setLoading(false);
    })();
  }, [refresh, open, openEdit]);

  const hndlSendEm = async (data) => {
    const url = `${process.env.REACT_APP_API_URL}admin-user/SendPromoEmail`;
    fetch(url, {
      method: 'POST',
      headers: {
        mode: 'no-cors', // no-cors,
        'Access-Control-Allow-Origin': 'http://127.0.0.1:3000',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        credentials: 'same-origin',
        'Cross-Origin-Resource-Policy': 'same-site same-origin',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setOpenSnack(true);
      })
      .catch((error) => {
        // console.log(error);
        setError(true);
      });
  };

  return (
    <div style={{ height: 500, width: '100%', backgroundColor: 'white' }}>
      <PageTitle
        title="MISE EN PLACE D'UN ÉVÉNEMENT"
        button='Add'
        OnOpen={handleClickOpen}
      />
      <SnackBarPromo
        open={error}
        setOpen={setError}
        type={'error'}
        message={
          "Erreur lors de l'envoi des emails veuillez réessayer plus tard"
        }
      />

      <SnackBarPromo
        open={openSnack}
        setOpen={setOpenSnack}
        type={'success'}
        message={'Emails envoyés avec succès'}
      />

      {openDelMod.open ? (
        <DeletePromoModal inf={openDelMod} setInf={setOpenDelMod} />
      ) : (
        ''
      )}
      {data.length > 0 ? (
        data.map((item) => {
          return (
            <div className={classes.box}>
              <div
                style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
              >
                <div style={{ display: 'flex', flex: 1 }}>
                  <Typography className={classes.header}>Promotion</Typography>
                  <div style={{ width: 10 }} />
                  <Typography className={classes.text}>{item.name}</Typography>
                </div>
                <div style={{ display: 'flex', flex: 1 }}>
                  <Typography className={classes.header}>Du</Typography>
                  <div style={{ width: 10 }} />
                  <Typography className={classes.text}>
                    {new Date(item.start_date)
                      .toLocaleDateString()
                      .replace(/\//g, '-')}
                  </Typography>
                  <Typography
                    className={classes.header}
                    style={{ marginLeft: 20 }}
                  >
                    Au
                  </Typography>
                  <div style={{ width: 10 }} />
                  <Typography className={classes.text}>
                    {new Date(item.end_date)
                      .toLocaleDateString()
                      .replace(/\//g, '-')}
                  </Typography>
                </div>
                <div />
              </div>
              <div
                style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
              >
                <div style={{ display: 'flex', flex: 1 }}>
                  <Typography className={classes.header}>Message</Typography>
                </div>
                <div
                  style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
                >
                  <Typography className={classes.text}>
                    {parse(item.Message)}
                  </Typography>
                  <Button
                    className={classes.NotifBtn}
                    type='submit'
                    variant='outlined'
                    onClick={() => hndlSendEm(item)}
                  >
                    Envoyer a tout les utilisateurs
                  </Button>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  paddingRight: 20,
                }}
              >
                <div style={{ display: 'flex', flex: 1 }}>
                  <Typography className={classes.header}>Actions</Typography>
                </div>
                <div style={{ display: 'flex', flex: 1 }}>
                  <IconButton
                    className={classes.button}
                    onClick={() => {
                      setIdEvent(item._id);
                      setEditOpen(true);
                    }}
                  >
                    <EditIcon style={{ color: '#47a1de', fontSize: 30 }} />
                  </IconButton>
                  <IconButton
                    className={classes.button}
                    onClick={() =>
                      setOpenDelMod({
                        open: true,
                        text: 'cet événement',
                        id: item._id,
                        hndlDel: DeleteEvent,
                      })
                    }
                  >
                    <CloseIcon style={{ color: 'red', fontSize: 30 }} />
                  </IconButton>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <text />
      )}
      <EventForm
        open={open}
        onClose={handleClose}
        //setPromotionInfo={setPromotionInfo}
      />
      <EditEventForm open={openEdit} onClose={handleCloseEdit} id={idEvent} />
    </div>
  );
}
