/* eslint-disable no-unused-expressions */

import * as React from 'react';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import PageTitle from '../../components/PageTitle/PageTitle';
import SubMenu from '../../components/SubMenu/index';
import UserForm from './UserForm';

import DeleteUserModal from './DeleteUserModal';
import MUIDataTable from 'mui-datatables';
import { Avatar, Tooltip } from '@mui/material';
import ImagesBackdrop from '../confirmationsByPic/ImagesBackDrop';
import { NewEditFromUser } from './NewFormEditUser';

export default function Users() {
  const [formOPen, setFormOpen] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState();

  const [EditformOPen, setEditFormOpen] = React.useState({
    open: false,
    id: null,
  });
  const [Refresh, setRefresh] = React.useState(false);
  const [rowInf, setRowInf] = React.useState({
    email: '',
    open: false,
    hndlDel: '',
  });

  const [allRows, setAllRows] = React.useState([]);

  const [openImg, setOpenImg] = React.useState(false);

  const [avUrl, setAvUrl] = React.useState();

  const cols = [
    'PSEUDO',
    'NOM',
    'PRÉNOM',
    'EMAIL',
    'AGE',
    'SEXE',
    'DATE DE NAISSANCE',
    '/HEURE',
    '/LIEU',
    'ADRESSE IP',
    "PAYS D'INSCRIPTION",
    'STATUS',
    'AVATAR',
    'SIGNE ASTRO',
    "STATUT D'ABONNEMENT",
    { name: 'SUPPRIMER', label: 'SUPPRIMER', options: { filter: false } },
    { name: 'MODIFIER', label: 'MODIFIER', options: { filter: false } },
    ,
  ];

  const handleFormClose = () => {
    setFormOpen(false);
    setRefresh(!Refresh);
  };
  const EdithandleFormClose = () => {
    setEditFormOpen(false);
  };

  const handleFormOpen = () => {
    setFormOpen(true);
  };

  const EdithandleFormOpen = (id) => {
    setEditFormOpen({ open: true, id: id });
  };

  const onClick = async (id) => {
    const url = `${process.env.REACT_APP_API_URL}admin-user/user?userId=` + id;
    fetch(url, { method: 'DELETE' })
      .then((res) => res.json())
      .then((data) => {
        setRefresh(!Refresh);
      })
      .catch((err) => console.log(err));
  };

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: 'gray',
      },
      children: `${name[0]}`,
    };
  }

  const getAstroSigne = (signe) => {
    return signe === 'Bélier'
      ? require('../../assets/signes/Belier.png')
      : signe === 'Taureau'
      ? require('../../assets/signes/Taureau.png')
      : signe === 'Gémeaux'
      ? require('../../assets/signes/Gemeaux.png')
      : signe === 'Cancer'
      ? require('../../assets/signes/Cancer.png')
      : signe === 'Lion'
      ? require('../../assets/signes/Lion.png')
      : signe === 'Vierge'
      ? require('../../assets/signes/Vierge.png')
      : signe === 'Balance'
      ? require('../../assets/signes/Balance.png')
      : signe === 'Scorpion'
      ? require('../../assets/signes/Scorpion.png')
      : signe === 'Sagittaire'
      ? require('../../assets/signes/Sagittaire.png')
      : signe === 'Capricorne'
      ? require('../../assets/signes/Capricorne.png')
      : signe === 'Verseau'
      ? require('../../assets/signes/Verseau.png')
      : require('../../assets/signes/Poissons.png');
  };

  React.useEffect(() => {
    let active = true;
    localStorage.getItem('pageRows')
      ? ''
      : localStorage.setItem('pageRows', 10);
    if (!active) {
      return;
    } else {
      (async () => {
        const url1 = `${process.env.REACT_APP_API_URL}admin-user/allusers`;

        await fetch(url1)
          .then((res) => res.json())
          .then((data) => {
            console.log();
            return setAllRows(
              data.data.map((element) => ({
                id: element.id,
                PSEUDO: element.username,
                NOM: element.surname,
                PRÉNOM: element.surname,
                EMAIL: element.email,
                AGE: element.age,
                SEXE: element.gender === 'female' ? 'F' : 'M',
                'DATE DE NAISSANCE':
                  element.private.birthday.split('T')[0].split('-')[2] +
                  '-' +
                  element.private.birthday.split('T')[0].split('-')[1] +
                  '-' +
                  element.private.birthday.split('T')[0].split('-')[0],
                '/HEURE': element.private.birthTime,
                '/LIEU': element.private.birthCity,
                'ADRESSE IP': element.ip,
                "PAYS D'INSCRIPTION": element.signInCountry,
                STATUS:
                  element.Disabled && element.Banned
                    ? 'Banni'
                    : element.Disabled
                    ? 'Inactif'
                    : element.Banned
                    ? 'Banni'
                    : 'Actif',
                AVATAR:
                  element.avatar === null ? (
                    <Avatar {...stringAvatar(element.username)} />
                  ) : (
                    <Avatar
                      src={`${process.env.REACT_APP_IMG}${element.avatar}`}
                      onClick={() => {
                        setAvUrl(
                          `${process.env.REACT_APP_IMG}${element.avatar}`
                        ),
                          setOpenImg(true);
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  ),
                'SIGNE ASTRO': (
                  <Tooltip
                    title={`${element.astralSexe.signe}`}
                    placement='bottom-start'
                  >
                    <img
                      src={getAstroSigne(element.astralSexe.signe)}
                      style={{ cursor: 'pointer', width: '100%' }}
                    />
                  </Tooltip>
                ),
                "STATUT D'ABONNEMENT": !element.Premium.hasOwnProperty('offer')
                  ? 'Freemium'
                  : `Premium:${element.Premium.offer} mois`,
                SUPPRIMER: (
                  <Button
                    onClick={() =>
                      setRowInf({
                        email: element.email,
                        open: true,
                        id: element.id,
                        hndlDel: onClick,
                      })
                    }
                  >
                    <CloseIcon style={{ color: 'red' }} />
                  </Button>
                ),
                MODIFIER: (
                  <Button
                    onClick={() => {
                      EdithandleFormOpen(element.id);
                    }}
                  >
                    <EditIcon style={{ color: 'rgb(71, 161, 222)' }} />
                  </Button>
                ),
              }))
            );
          });
      })();
    }

    return () => {
      active = false;
    };
  }, [Refresh]);

  const updatePageStore = (val) => {
    if (localStorage.getItem('pageRows')) {
      localStorage.setItem('pageRows', val);
    }
  };

  // console.log({ allRows });

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        backgroundColor: 'white',
      }}
    >
      {rowInf.open ? <DeleteUserModal inf={rowInf} setInf={setRowInf} /> : null}
      <SubMenu active='1' />
      <PageTitle
        title='LISTE DES UTILISATEURS'
        button='ajouter'
        OnOpen={handleFormOpen}
      />{' '}
      {
        <MUIDataTable
          data={allRows}
          columns={cols}
          options={{
            selectableRows: false,
            rowsPerPageOptions: [10, 20, 50, 100],
            onChangeRowsPerPage: (val) => updatePageStore(val),
            rowsPerPage: localStorage.getItem('pageRows'),
          }}
        />
      }
      {openImg ? (
        <ImagesBackdrop open={openImg} setOpen={setOpenImg} url={avUrl} />
      ) : null}
      {formOPen ? (
        <UserForm
          open={formOPen}
          onClose={handleFormClose}
          index={1}
          setUserInfo={setUserInfo}
        />
      ) : null}
      {/* <EditUserForm
        open={EditformOPen.open}
        onClose={EdithandleFormClose}
        index={1}
        id={EditformOPen.id}
        allRows={allRows}
        setAllRows={setAllRows}
      /> */}
      {EditformOPen.open ? (
        <NewEditFromUser
          open={EditformOPen.open}
          onClose={EdithandleFormClose}
          index={1}
          id={EditformOPen.id}
          allRows={allRows}
          setAllRows={setAllRows}
        />
      ) : null}
    </div>
  );
}
