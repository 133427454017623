import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
//import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Typography, Button } from "@material-ui/core/";
import Grid from "@material-ui/core/Grid";
import { TextField, Select } from "formik-material-ui";
import MenuItem from "@material-ui/core/MenuItem";
import { Formik, Form, Field } from "formik";
import useStyles from "./styles";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});


const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    borderRadius: 50
  },
}))(MuiDialogContent);


export default function Influencer(props) {
  var classes = useStyles();
  const { onClose, open, setUserInfo } = props;
  const [canSave, setCanSave] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [PromoData, setPromoData] = React.useState(null);


  useEffect(() => {
    /* if (props.id !=null){
    (async () => {
      const url =
        `${process.env.REACT_APP_API_URL}admin-user/memberProfile/` + props.id;
      const response = await fetch(url);
      const data = await response.json();
      setPromoData(data);
    })();
  } */
  }, [props.id]);
  const isNotEmpty = (currentValue) => currentValue != "";
  if (!loading) {
    return (
      <>
        <Dialog
          onClose={() => onClose()}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <Typography style={{ alignSelf: 'center', paddingTop: 30, padding: 20, fontFamily: 'SF-Pro-Display-Bold' }}>Ajouter une promotion</Typography>
          <DialogContent >
            <Formik
              initialValues={{
                name: '',
                Code:'',
                start_date: '',
                end_date: "",
                Message: '',
                Promo24: 0,
                Promo1: 0,
                Promo3: 0,
                Promo6: 0,
              }}
              enableReinitialize
              validate={(values) => {
                setCanSave(
                  [
                    values.name,
                    values.code,
                    values.start_date,
                    values.end_date,
                    values.Message,
                    values.Promo24,
                    values.Promo1,
                    values.Promo3,
                    values.Promo6
                  ].some(isNotEmpty)
                );
                const errors = {};
                /* if (!values.email) {
                  errors.email = "Required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                    values.email
                  )
                ) {
                  errors.email = "Invalid email address";
                } */
                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                let dtoTOTransfer = {};
                dtoTOTransfer.name = values.name;
                dtoTOTransfer.CouponCode = values.code;
                dtoTOTransfer.start_date = values.start_date;
                dtoTOTransfer.end_date = values.end_date;
                dtoTOTransfer.Message = values.Message;
                dtoTOTransfer.Promo24 = values.Promo24;
                dtoTOTransfer.Prom01 = values.Promo1;
                dtoTOTransfer.Promo3 = values.Promo3;
                dtoTOTransfer.Promo6 = values.Promo6;

                console.log(dtoTOTransfer);

                //setLoading(true);
                const url = `${process.env.REACT_APP_API_URL}admin-user/createPromo`;
                const response = await fetch(url, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(dtoTOTransfer),
                });
                onClose();

              }}
            >
              {({
                errors,
                isSubmitting,
                setValues,
                /* and other goodies */
              }) => (
                <Form style={{ width: 400 }}>
                  <Grid container>
                    <Grid item xs={12} sm={12} spacing={4}>
                      <text className={classes.title}>Nom de la promotion</text>
                      <Field
                        component={TextField}
                        name="name"
                        type="name"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} spacing={4}>
                      <text className={classes.title}>Code promo</text>
                      <Field
                        component={TextField}
                        name="code"
                        type="code"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ display: 'flex', flexDirection: 'row', marginTop: 15 }}>
                      <text className={classes.title}>
                        Du
                      </text>
                      <div style={{ width: 20 }} />
                      <Field
                        component={TextField}
                        name="start_date"
                        type="date"
                        fullWidth
                      />
                      <text className={classes.title}>
                        Au
                      </text>
                      <div style={{ width: 20 }} />
                      <Field
                        component={TextField}
                        name="end_date"
                        type="date"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ marginTop: 15 }}>
                      <text className={classes.title}>Message</text>
                      <Field
                        component={TextField}
                        name="Message"
                        type="Message"
                        fullWidth
                      />
                    </Grid>

                    <Typography className={classes.title}>Regles de reduction</Typography>
                    <Grid item xs={12} sm={12} spacing={4} style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ display: 'flex', flex: 1 }}>
                        <text className={classes.title}>24 h =</text>
                      </div>
                      <div style={{ display: 'flex', flex: 3 }}>
                        <Field
                          component={TextField}
                          name="Promo24"
                          type="number"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} spacing={4} style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ display: 'flex', flex: 1 }}>
                        <text className={classes.title}>1 mois =</text>
                      </div>
                      <div style={{ display: 'flex', flex: 3 }}>
                        <Field
                          component={TextField}
                          name="Promo1"
                          type="number"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} spacing={4} style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ display: 'flex', flex: 1 }}>
                        <text className={classes.title}>3 mois =</text>
                      </div>
                      <div style={{ display: 'flex', flex: 3 }}>
                        <Field
                          component={TextField}
                          name="Promo3"
                          type="number"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} spacing={4} style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ display: 'flex', flex: 1 }}>
                        <text className={classes.title}>6 mois =</text>
                      </div>
                      <div style={{ display: 'flex', flex: 3 }}>
                        <Field
                          component={TextField}
                          name="Promo6"
                          type="number"
                        />
                      </div>
                    </Grid>


                    <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', marginTop: 30, justifyContent: 'center' }}>
                      <Button
                        className={classes.submit}
                        type="submit"
                        disabled={!canSave}
                        variant="outlined"
                        color="primary"
                      >
                        {loading ? "patientez svp" : "Valider"}
                      </Button>
                      <div style={{ width: 20 }} />
                      <Button variant="outlined" color="error" onClick={() => { onClose() }} >
                        Annuler
                      </Button>
                    </Grid>
                  </Grid>
                </Form>

              )}
            </Formik>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}
