import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  MenuButton: {
    color: '#e21067',
    borderWidth: 0,
    textTransform: 'none',
    textDecoration: 'none',
    fontFamily: 'SF-Pro-Display-Light',
    padding: 10,
    fontSize: 17,
  },
  avatar: {
    width: 230,
    height: 230,
    borderRadius: 125,
    marginTop: 10,
    cursor: 'pointer',
  },
  pic: {
    width: 150,
    height: 150,
    marginTop: 5,
    borderRadius: 300,
    cursor: 'pointer',
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 0.7,
    justifyContent: 'center',
  },
  box: {
    boxShadow: '0px 3px 6px #00000029',
    backgroundColor: 'white',
    borderRadius: 20,
    elevation: 5,
    marginTop: theme.spacing(20),
    marginBottom: theme.spacing(15),
    margin: 30,
    padding: 20,
    flex: 1,
    height: 100,
  },
  actionsBox: {
    backgroundColor: 'white',
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(15),
    margin: 30,
    padding: 20,
    flex: 1.5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  TextField: {
    border: 'none',
    borderBottom: '1px solid #c2c2c2',
    fontFamily: 'SF-Pro-Display-Light',
    marginTop: theme.spacing(5),
    width: '100%',
  },
}));
