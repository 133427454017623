import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import { FormEdit } from './form';
import { useState } from 'react';
import useStyles from './styles';

export const NewEditFromUser = (props) => {
  const { open, onClose, allRows, id, setAllRows } = props;
  const classes = useStyles();

  const [data, setData] = useState('');

  const row = allRows.find((data) => data.id === id);
  return (
    <>
      <Backdrop
        open={open}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <div className={classes.dialogue}>
          <div>
            <p className={classes.header_text}>MODIFICATION D'UN UTILISATEUR</p>
          </div>
          <FormEdit
            onClose={onClose}
            setData={setData}
            allRows={allRows}
            setAllRows={setAllRows}
            row={row}
            id={id}
          />
        </div>
      </Backdrop>
    </>
  );
};
