import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  adminsContainer: {
  //  flex:1,
    boxShadow: theme.customShadows.widget,
    background:'white',
    overflow: "hidden",
    paddingBottom: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
   // marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(0, 0, 0),
    float:"right"
  },
  input: {
    display: 'none',
  },
  avatar:{
    width: theme.spacing(25),
    height: theme.spacing(25),
  },
  label:{
    bottom: 26,
    position: 'relative'
  },
  addImage:{
    display:'flex',
   //flex:1,
   flexDirection:'column',
   alignItems:'center'
  }
}));
