/* eslint-disable no-unused-expressions */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Fab,
  Avatar,
} from '@material-ui/core';

// styles
import useStyles from './styles';

// components
import { Badge, Typography } from '../Wrappers/Wrappers';
//import Notification from "../Notification/Notification";
import UserAvatar from '../UserAvatar/UserAvatar';

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '../../context/LayoutContext';
import { SignOutUser } from '../../slices/userSignIn';
import UpadateAdminPswForm from '../UpdateAdminPswForm/UpdateAdminPswForm';
import { ListEl } from './ListEl';

export default function Header(props) {
  const [openUpForm, setUpForm] = useState(false);

  // global
  const dispatch = useDispatch();

  var classes = useStyles();

  var [profileMenu, setProfileMenu] = useState(null);
  //var [isSearchOpen, setSearchOpen] = useState(false);
  const { user } = useSelector(
    (state) => ({
      user: state.userSignIn.user,
    }),
    shallowEqual
  );

  // console.log(tglSt);

  return (
    <div className={classes.bar}>
      <AppBar position='fixed' className={classes.appBar}>
        <UpadateAdminPswForm
          open={openUpForm}
          setOpen={setUpForm}
          user={user}
          history={props.history}
        />
        <Toolbar className={classes.toolbar}>
          <div className={classes.grow} />
          <div className={classes.row}>
            <ListEl />
          </div>
          <div className={classes.grow} />
          <IconButton
            aria-haspopup='true'
            color='inherit'
            className={classes.headerMenuLink}
            aria-controls='profile-menu'
            onClick={(e) => setProfileMenu(e.currentTarget)}
          >
            {user.avatar ? (
              <Avatar alt={user.name} src={user.avatar} />
            ) : (
              <Avatar>{user.name.slice(1, 1)}</Avatar>
            )}
          </IconButton>
          <Typography
            style={{
              fontSize: '1rem',
              fontFamily: 'SusaBold',
            }}
          >
            Bonjour <strong>{user.surname} </strong>
          </Typography>
          <Menu
            id='profile-menu'
            open={Boolean(profileMenu)}
            anchorEl={profileMenu}
            onClose={() => setProfileMenu(null)}
            className={classes.headerMenu}
            classes={{ paper: classes.profileMenu }}
            disableAutoFocusItem
          >
            <div className={classes.profileMenuUser}>
              <Typography variant='h4' weight='medium'>
                {user.email}
              </Typography>
            </div>
            <div className={classes.profileMenuUser}>
              <Typography
                className={classes.profileMenuLink}
                color='primary'
                // onClick={() => dispatch(SignOutUser(props.history))}
              >
                Mon Profil
              </Typography>
              <Typography
                className={classes.profileMenuLink}
                color='primary'
                onClick={() => {
                  return setUpForm(true), setProfileMenu(false);
                }}
              >
                Changement de mot de passe
              </Typography>
              <Typography
                className={classes.profileMenuLink}
                color='primary'
                onClick={() => dispatch(SignOutUser(props.history))}
              >
                Deconnexion
              </Typography>
            </div>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
}
