import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  
  MenuButton:{
    color:'#e21067',
    borderWidth:0,
    textTransform:'none',
    textDecoration:'none',
    fontFamily:"SF-Pro-Display-Light",
    padding:10,
    fontSize:17
  }
  
}));
