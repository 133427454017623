/* eslint-disable no-unused-expressions */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAdmins } from '../../slices/admins';
import MUIDataTable from 'mui-datatables';
import PageTitle from '../../components/PageTitle/PageTitle';
import AdminForm from './AdminForm';

export default function Admins() {
  const [open, setOpen] = React.useState(false);
  const adminStatus = useSelector((state) => state.admins.status);
  const error = useSelector((state) => state.admins.error);
  const { admins } = useSelector((state) => {
    return {
      admins: state.admins.admins,
    };
  });

  const dispatch = useDispatch();
  React.useEffect(() => {
    localStorage.getItem('pageRowsAd')
      ? ''
      : localStorage.setItem('pageRowsAd', 10);

    if (adminStatus === 'idle') {
      dispatch(fetchAdmins());
    }
  }, [adminStatus, dispatch]);

  const updatePageStore = (val) => {
    if (localStorage.getItem('pageRowsAd')) {
      localStorage.setItem('pageRowsAd', val);
    }
  };

  let content;

  if (adminStatus === 'loading') {
    content = <div className='loader'>Loading...</div>;
  } else if (adminStatus === 'succeeded') {
    // console.log(admins);
    content = (
      <MUIDataTable
        title={'Admin List'}
        data={admins}
        columns={['surname', 'name', 'email', 'phoneNumber', 'role']}
        options={{
          selectableRows: false,
          rowsPerPageOptions: [10, 20, 50, 100],
          onChangeRowsPerPage: (val) => updatePageStore(val),
          rowsPerPage: localStorage.getItem('pageRowsAd'),
        }}
      />
    );
  } else if (adminStatus === 'error') {
    content = <div>{error}</div>;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  console.log(admins);
  return (
    <>
      <PageTitle
        title='Liste des Adminstrateur'
        button='Add'
        OnOpen={handleClickOpen}
      />
      <AdminForm open={open} onClose={handleClose} />
      {content}
    </>
  );
}
