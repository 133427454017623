import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  userTable: {
    backgroundColor: 'white',
  },

  filterCont: {
    padding: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  dialogue: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#fff',
    color: 'black',
    width: '80%',
    height: '80%',
    borderRadius: 20,
  },

  header_text: {
    fontWeight: 900,
    textDecoration: 'underline',
  },

  actionButt: {
    display: 'flex',
    alignItems: 'center',
    gap: '2rem',
    padding: '1rem',
    '& button': {
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
    },
    '& :nth-child(1)': {
      backgroundColor: '#65A7E9',
      color: 'white',
      padding: '1rem 5rem',
      borderRadius: 20,
    },
    '& :nth-child(2)': {
      backgroundColor: 'white',
      color: 'rgb(71, 161, 222)',
    },
  },

  form_inputs: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
  },

  inp_lab: {
    '& label': {
      fontWeight: 600,
    },
    '& input': {
      //border: 'none',
      padding: '0.5rem',
      //outline: 'none',
      //boxShadow: '4px 6px 5px 0px rgba(0,0,0,0.29)',
    },
    '& select': {
      //border: 'none',
      padding: '0.5rem',
      outline: 'none',
      //boxShadow: '4px 6px 5px 0px rgba(0,0,0,0.29)',
      backgroundColor: 'white',
    },
    display: 'inherit',
    padding: '2rem',
    margin: '2rem',
  },

  flexForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto',
  },
  pays_cont: {
    display: 'grid',
  },
}));
